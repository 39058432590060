import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';

const actions = {
  async getParents ({ commit, dispatch }) {

    try {
      const res = await backend.getParents();
      await checkResponseStatus(200, res);
      const parent = await res.json();
      return parent;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async getParent ({ commit, dispatch }, params) {

    try {
      const res = await backend.getParent(params);
      await checkResponseStatus(200, res);
      const parent = await res.json();
      return parent;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async getMeParent ({ commit, dispatch }) {
    try {
      const res = await backend.getMeParent();
      await checkResponseStatus(200, res);
      const parent = await res.json();
      return parent;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async createParent ({ commit, dispatch }, params) {

    try {
      const res = await backend.postParent(params);
      await checkResponseStatus(201, res);
      const account = await res.json();
      return account;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async editParent ({ commit, dispatch }, params) {

    try {
      const id = params._id
      delete params._id;
      const res = await backend.patchParent(id, params);
      if(res.status === 409) {
        return 409;
      }
      const account = await res.json();
      return account;
    }
    catch(err) {
      return err.response.status;
    }
  },
  
  async deleteParent ({commit, dispatch}, id) {
    try {
      const res = await backend.deleteParent(id);
      if(res.status === 204) {
        return 204;
      }
      const account = await res.json();
      return account;
    }
    catch(err) {
      return err.response.status;
    }
  }
}

export default {
  namespaced: true,
  actions,
};
