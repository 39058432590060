import lautsprecherIcon from "../assets/Icons/lautsprecher-13.svg";

const createTextParagraphs = (textSpans) => {
    const paragraphList = [];

    for (let i = 0; i < textSpans.length; i++) {
        // Get current span and top coords
        const span = textSpans[i];
        span.style.display = 'block';
        const spanTopCoord = Number.parseFloat(span.style.top.substring(0, span.style.top.length - 2));
        const spanLeftCoord = Number.parseFloat(span.style.left.substring(0, span.style.left.length - 2));
        const spanFontSize = Number.parseFloat(span.style.fontSize.substring(0, span.style.fontSize.length - 2));

        // find current span in paragraphList
        let currentParagraphListElem = paragraphList.find(item => item.includes(span));
        const currentParagraphListIndex = paragraphList.findIndex(item => item.includes(span));

        // If not found, make new entry in paragraphList
        if (!currentParagraphListElem) {
            currentParagraphListElem = [];
            currentParagraphListElem.push(span);
        }

        // Go through textSpans to find other spans to add to entry according to topcoord + 1.5 * fontsize
        const filterFunc = (otherSpan) => {
            const otherSpanTopCoord = Number.parseFloat(otherSpan.style.top
                .substring(0, otherSpan.style.top.length - 2));
            const otherSpanLeftCoord = Number.parseFloat(otherSpan.style.left
                .substring(0, otherSpan.style.left.length - 2));

            return (otherSpanTopCoord > spanTopCoord && otherSpanTopCoord < (spanTopCoord + 1.5 * spanFontSize))
                && (otherSpanLeftCoord > (spanLeftCoord - 20) && otherSpanLeftCoord < (spanLeftCoord + 20));
        };

        const filteredSpan = textSpans.filter(filterFunc);
        const concattedArray = currentParagraphListElem.concat(filteredSpan);

        // Check text length to make sure the paragraph isnt useless
        let checkText = '';
        for (let i = 0; i < concattedArray.length; i++) {
            checkText += concattedArray[i].innerHTML;
        }
        if (checkText.length > 3 && spanFontSize > 13.5) {
            if (currentParagraphListIndex !== -1) {
                paragraphList.splice(currentParagraphListIndex, 1, concattedArray);
            } else {
                paragraphList.push(concattedArray);
            }
        }
    }

    return paragraphList;

};

const calculateParagraphPositions = (paragraphList) => {
    const displayParagraphsList = [];

    for (let i = 0; i < paragraphList.length; i++) {
        const paragraph = {};
        let filteredParagraphs = [];
        do {
            paragraph.top = Number.parseFloat(paragraphList[i][0].style.top
                .substring(0, paragraphList[i][0].style.top.length - 2));
            paragraph.left = Number.parseFloat(paragraphList[i][0].style.left
                .substring(0, paragraphList[i][0].style.left.length - 2));

            let calculatedHeight = 0;
            let maxWidth = 0;
            paragraphList[i].sort((a, b) => Number.parseFloat(a.style.top
                .substring(0, a.style.top.length - 2)) - Number.parseFloat(b.style.top
                .substring(0, b.style.top.length - 2)));

            for (let j = 0; j < paragraphList[i].length; j++) {
                const spanEl = paragraphList[i][j];
                const spanElLeft = Number.parseFloat(spanEl.style.left
                    .substring(0, spanEl.style.left.length - 2));
                const spanElTop = Number.parseFloat(spanEl.style.top
                    .substring(0, spanEl.style.top.length - 2));

                try {
                    if (spanEl.getBoundingClientRect().width
                        && maxWidth < spanEl.getBoundingClientRect().width) {
                        if (spanElTop > paragraph.top - 5 && spanElTop < paragraph.top + 5 && spanElLeft > paragraph.left) {
                            maxWidth += spanEl.getBoundingClientRect().width;
                        } else {
                        maxWidth = spanEl.getBoundingClientRect().width;
                        }
                    }

                    if (spanEl.getBoundingClientRect().height) {
                        if (!(spanElTop > paragraph.top - 2 && spanElTop < paragraph.top + 2 && spanElLeft > paragraph.left)) {
                            calculatedHeight += spanEl.getBoundingClientRect().height;
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            paragraph.width = maxWidth;
            paragraph.height = calculatedHeight * 1.3;


            // Check if width ends within 20px of left of another paragraph
            const maxWidthToCalc = maxWidth + paragraph.left;
            // Also check if top is within the height of the element +-5px
            const btmCoord = paragraph.height + paragraph.top;
            const filterFunc = (item) => {
                const itemStyleLeft = item[0].style.left.substring(0, item[0].style.left.length - 2);
                const leftEndCheck = itemStyleLeft > maxWidthToCalc - 2 && itemStyleLeft < maxWidthToCalc + 20;

                const itemStyleTop = item[0].style.top.substring(0, item[0].style.top.length - 2);
                const topCheck = itemStyleTop >= paragraph.top - 5 && itemStyleTop < btmCoord + 5;

                const insideCheck = itemStyleLeft >= paragraph.left && itemStyleLeft < maxWidthToCalc -1
                    && itemStyleTop >= paragraph.top + 1 && itemStyleTop < btmCoord - 1;

                return (topCheck && leftEndCheck) || insideCheck;
            };

            filteredParagraphs = paragraphList.filter(filterFunc);
            for (let j = 0; j < filteredParagraphs.length; j++) {
                paragraphList[i] = paragraphList[i].concat(filteredParagraphs[j]);
            }
            paragraphList = paragraphList.filter(item => !filteredParagraphs.includes(item));
            // Now, run the previous part again
        } while (filteredParagraphs.length > 0)

        paragraph.listItem = paragraphList[i];
        paragraph.speakerIcon = lautsprecherIcon;
        paragraph.isActive = false;

        const checkSmth = Number.parseFloat(paragraphList[i][0].style.fontSize
            .substring(0, paragraphList[i][0].style.fontSize.length - 2)) * 2;
        if (paragraph.width >= checkSmth) {
            displayParagraphsList.push(paragraph);
        }
    }

    return displayParagraphsList.sort((a, b) => a.top - b.top);

};

export default {
    createTextParagraphs,
    calculateParagraphPositions
}
