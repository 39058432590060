import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';

const actions = {
  async isLoginIdAvailable ({ commit, dispatch }, loginId) {

    try {
      const res = await backend.postNameAvailable(loginId);
      await checkResponseStatus(200, res);
      const available = await res.json();
      return available;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async getCurrentAccount ({ commit, dispatch }) {

    try {
      const res = await backend.getCurrentAccount();
      await checkResponseStatus(200, res);
      const account = await res.json();
      return account;
    }
    catch(err) {
      return err.response.status;
    }
  },
}

export default {
  namespaced: true,
  actions,
};
