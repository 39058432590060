<template>
    <div style="border-radius: 5px !important; background-color: #F8F8F8">
        <header role="banner">
            <v-row :style="{ backgroundColor: groupColor, height: '80px' }" class="ma-auto mb-1 pa-2" align="center">
                <v-col :cols="windowWidth < 770 ? (windowWidth < 380 ? 10 : 9) : 7">
                    <span v-if="hoeren || sehen" tabindex="1" class="visually-hidden"> Um den Editor mit Screenreader richtig nutzen zu können, bedienen Sie diesen
                        bitte über die Tabulator-Taste. </span>
                    <v-menu :value="stiftSizeMenu" style="border-radius: 5px !important;"
                            :close-on-content-click="false"
                            offset-y nudge-left="54" nudge-bottom="5">
                        <template v-slot:activator="{ on: onMenu, attrs }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: onTooltip }">
                                    <v-btn
                                        tabindex="1"
                                        :class="{ activeTool: currentActiveMethod === 'pen' }"
                                        v-bind="attrs"
                                        :disabled="isTextLayerVisible"
                                        v-on="getHandlers(onMenu, onTooltip)"
                                        x-small class="mr-3 toolSelector"
                                        @click="setTool('pen')"
                                    >
                                        <v-icon v-if="currentActiveMethod === 'marker'" style="font-size: 22px !important;">
                                            fas fa-highlighter
                                        </v-icon>
                                        <img v-else alt="Stift" :src="stiftIcon" style="max-width: 25px; max-height: 25px"/>
                                    </v-btn>
                                </template>
                                <div style="display: flex; flex-direction: column; text-align: center">
                                    <span>Stift</span>
                                    <div>
                                        <img v-if="hoeren" :src="malenMetacom" width="100" style="margin: auto" class="mr-1">
                                        <img v-if="hoeren" :src="aussuchenMetacom" width="100" style="margin: auto">
                                    </div>
                                </div>
                            </v-tooltip>
                        </template>

                        <div style="width: 200px; height: 25px; display: inline; border-radius: 0 !important; background-color: #EAEAEA; ">
                            <v-btn
                                   role="menuitem"
                                   text x-small
                                   tabindex="2"
                                   @click="changeSize(3)"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                    aria-label="Stiftgröße klein"
                            >
                                <v-icon style="font-size: 6px !important;">fas fa-circle</v-icon>
                            </v-btn>
                            <v-btn text x-small
                                   role="menuitem"
                                   tabindex="3"
                                   @click="changeSize(13)"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                   class="pa-0"
                                    aria-label="Stiftgröße mittel">
                                <v-icon style="font-size: 13px !important;">fas fa-circle</v-icon>
                            </v-btn>
                            <v-btn text x-small
                                   role="menuitem"
                                   tabindex="4"
                                   @click="changeSize(22)"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                   class="pa-0"
                                   aria-label="Stiftgröße groß">
                                <v-icon style="font-size: 22px !important;">fas fa-circle</v-icon>
                            </v-btn>
                            <v-btn
                                    role="menuitem"
                                    tabindex="4"
                                    :class="{ markerActive: currentActiveMethod === 'marker' }"
                                    x-small text
                                    style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                    class="pa-0"
                                    aria-label="Stifttyp Textmarker"
                                    @click="setTool('marker')"
                                    @focusout="stiftSizeMenu = false"
                            >
                                <v-icon style="font-size: 22px !important;">fas fa-highlighter</v-icon>
                            </v-btn>
                        </div>
                    </v-menu>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" tabindex="5" x-small class="mr-3 toolSelector" :class="{ activeTool: currentActiveMethod === 'text' }"
                                   :disabled="isTextLayerVisible"
                                   @click="() => { setTool('text'); /*spawnTextOnCanvas();*/ }">
                                <img alt="Text einfügen" :src="textIcon" style="max-width: 28px; max-height: 35px"/>
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span>Text einfügen</span>
                            <div>
                                <img v-if="hoeren" :src="tastaturTippenMetacom" width="100" style="margin: auto">
                            </div>
                        </div>
                    </v-tooltip>

                    <!-- Eraser Button -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" tabindex="7" :class="{ activeTool: currentActiveMethod === 'eraser', 'mr-1': windowWidth < 600, 'mr-3': windowWidth > 600 }"
                                   :disabled="isTextLayerVisible"
                                   x-small class="toolSelector"
                                   @click="setTool('eraser')">
                                <img alt="Radiergummi" :src="radiererIcon" style="max-width: 25px; max-height: 25px"/>
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span>Radiergummi</span>
                            <div>
                                <img v-if="hoeren" :src="loeschenMetacom" width="100" style="margin: auto">
                            </div>
                        </div>
                    </v-tooltip>

                    <!--            -->

                    <v-btn v-if="false" style="width: 49px; height: 49px;"
                           x-small text class="mr-3"
                           @click="() => { displayOtherButtons = !displayOtherButtons }">
                        <p style="font-size: 30px; color: white">...</p>
                    </v-btn>

    <!--            Color palette    -->
                    <v-menu :value="colorMenu" v-if="displayOtherButtons" style="border-radius: 5px !important;" offset-y nudge-left="162" nudge-bottom="5">
                        <template v-slot:activator="{ on: onMenu, attrs }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: onTooltip }">
                                    <v-btn

                                            tabindex="8"
                                            v-on="getHandlers(onMenu, onTooltip)"
                                            v-bind="attrs"
                                            x-small class="mr-3 toolSelector preventColorInvert"
                                            aria-label="Farbe auswählen"
                                            @click="colorMenu = true"

                                    >
                                        <v-icon style="font-size: 22px !important;" :color="color">fas fa-circle</v-icon>
                                    </v-btn>
                                </template>
                                <div style="display: flex; flex-direction: column; text-align: center">
                                    <span>Farbe auswählen</span>
                                    <div>
                                        <img v-if="hoeren" :src="farbenMetacom" width="100" style="margin: auto" class="mr-1">
                                        <img v-if="hoeren" :src="aussuchenMetacom" width="100" style="margin: auto">
                                    </div>
                                </div>
                            </v-tooltip>
                        </template>

                        <div style="width: 200px; height: 25px; display: inline; border-radius: 0 !important; background-color: #EAEAEA; ">
                            <v-btn text x-small
                                   role="menuitem"
                                   tabindex="9"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                   aria-label="Farbe gelb"
                                   class="preventColorInvert">
                                <v-icon @click="changeColor('#fac837')" style="font-size: 22px !important; color: #fac837">fas fa-circle</v-icon>
                            </v-btn>
                            <v-btn text x-small
                                   role="menuitem"
                                   tabindex="10"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                   class="pa-0 preventColorInvert"
                                   aria-label="Farbe orange" >
                                <v-icon @click="changeColor('#f07d32')" style="font-size: 22px !important; color: #f07d32">fas fa-circle</v-icon>
                            </v-btn>
                            <v-btn text x-small
                                   role="menuitem"
                                   tabindex="11"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                   class="pa-0 preventColorInvert"
                                   aria-label="Farbe rot">
                                <v-icon  @click="changeColor('#e6231e')" style="font-size: 22px !important; color: #e6231e">fas fa-circle</v-icon>
                            </v-btn>
                            <v-btn text x-small
                                   role="menuitem"
                                   tabindex="12"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                   class="pa-0 preventColorInvert"
                                   aria-label="Farbe hellblau">
                                <v-icon  @click="changeColor('#6996cd')" style="font-size: 22px !important; color: #6996cd">fas fa-circle</v-icon>
                            </v-btn>
                            <v-btn text x-small
                                   role="menuitem"
                                   tabindex="13"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                   class="pa-0 preventColorInvert"
                                   aria-label="Farbe dunkelblau">
                                <v-icon  @click="changeColor('#505faa')" style="font-size: 22px !important; color: #505faa">fas fa-circle</v-icon>
                            </v-btn>
                            <v-btn text x-small
                                   role="menuitem"
                                   tabindex="14"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                   class="pa-0 preventColorInvert"
                                   aria-label="Farbe grün" >
                                <v-icon @click="changeColor('#8cbe46')" style="font-size: 22px !important; color: #8cbe46">fas fa-circle</v-icon>
                            </v-btn>
                            <v-btn text x-small
                                   role="menuitem"
                                   tabindex="15"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                   class="pa-0 preventColorInvert"
                                   aria-label="Farbe grau"
                                   @focusout="colorMenu = false">
                                <v-icon @click="changeColor('#706f6f')" style="font-size: 22px !important; color: #706f6f">fas fa-circle</v-icon>
                            </v-btn>
                        </div>
                    </v-menu>

                    <v-btn v-if="false" style="background-color: #f8f8f8; width: 49px; height: 49px" x-small class="mr-3" @click="undo">
                        <img :src="undoIcon" style="max-width: 25px; max-height: 25px"/>
                    </v-btn>

                    <v-tooltip bottom v-if="displayOtherButtons">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" tabindex="16"  x-small class="mr-3 toolSelector" @click="rotateEditor">
                                <img alt="Um 90° im Uhrzeigersinn drehen" :src="drehIcon" style="max-width: 25px; max-height: 25px"/>
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span>Arbeitsblatt drehen</span>
                            <img v-if="hoeren" :src="drehenMetacom" width="100" style="margin: auto">
                        </div>
                    </v-tooltip>
                    <v-menu :value="addPageMenu" v-if="displayOtherButtons" style="border-radius: 5px !important;" offset-y  nudge-bottom="5">
                        <template v-slot:activator="{ on: onMenu, attrs }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: onTooltip }">
                                    <v-btn
                                        id="addPagesButton"
                                        tabindex="16"
                                        v-on="getHandlers(onMenu, onTooltip)"
                                        v-bind="attrs"
                                        x-small class="mr-3 toolSelector"
                                        aria-label="Seite hinzufügen"
                                        @click="addPageMenu = true"
                                    >
    <!--                                    <img :src="kariertesBlatt" class="mr-2"-->
    <!--                                         height="25px" width="20px"-->
    <!--                                         style="height: 25px; width: 20px"/>-->
                                        <div>
                                            <v-icon style="position: absolute; bottom: 0px; top: 0px; left: 0px; right: 0px; font-size: 30px !important;">far fa-file</v-icon>
                                            <v-icon style="position: absolute; bottom: 0px; top: 2px; left: 0px; right: 0px;  font-size: 14px !important;">fas fa-plus</v-icon>
                                        </div>
                                    </v-btn>
                                </template>
                                <div style="display: flex; flex-direction: column; text-align: center">
                                    <span>Seite hinzufügen</span>
                                </div>
                            </v-tooltip>
                        </template>
                        <div style="display: flex; flex-direction: column; background-color: white">
                            <div style="display: flex; padding: 14px 0"
                                 class="px-4"
                                v-for="(item, index) in additionalPagesDropdown"
                                :key="index"
                                @click="() => addAdditionalPageClick(item)"
                                 @keypress.enter="() => addAdditionalPageClick(item)"
                                tabindex="16" role="menuitem"
                            >
                                <div style="display: flex">
                                    <div style="display: flex">
                                        <img :src="item.img" class="mr-2"
                                             style="height: 20px;"/>
                                        <p>{{ item.title }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
<!--                        <v-list>-->
<!--                            <v-list-item-->
<!--                                    v-for="(item, index) in additionalPagesDropdown"-->
<!--                                    :key="index"-->
<!--                                    @click="() => addAdditionalPageClick(item)"-->
<!--                                    tabindex="16" role="menuitem"-->
<!--                            >-->
<!--                                <v-list-item-title>-->
<!--                                    <div style="display: flex">-->
<!--                                        <img :src="item.img" class="mr-2"-->
<!--                                             style="height: 20px;"/>-->
<!--                                        <p>{{ item.title }}</p>-->
<!--                                    </div>-->
<!--                                </v-list-item-title>-->
<!--                            </v-list-item>-->
<!--                        </v-list>-->
                    </v-menu>

                    <v-menu offset-y v-if="windowWidth < 600">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn style="width: 49px; height: 49px;"
                                   id="smallDisplayMoreButtonsTrigger"
                                   x-small text class="mr-3" v-bind="attrs"
                                   v-on="on" >
                                <p style="font-size: 30px; color: white">...</p>
                            </v-btn>
                        </template>
                        <div id="smallDisplayMoreButtons">
                            <v-btn x-small class="mr-3 toolSelector" @click="rotateEditor">
                                <img alt="Um 90° im Uhrzeigersinn drehen" :src="drehIcon" style="max-width: 25px; max-height: 25px"/>
                            </v-btn>

                            <!--            Color palette    -->
                            <v-menu style="border-radius: 5px !important;" offset-y nudge-left="162" nudge-bottom="5">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            style="background-color: #f8f8f8; width: 49px; height: 49px"
                                            x-small class="mr-3"
                                            aria-label="Farbe auswählen"
                                    >
                                        <v-icon style="font-size: 22px !important;" :color="color">fas fa-circle</v-icon>
                                    </v-btn>
                                </template>

                                <div class="colorPickerMenu">
                                    <v-btn text x-small
                                           role="menuitem"
                                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px">
                                           aria-label="Farbe gelb"
                                        <v-icon @click="changeColor('#fac837')" style="font-size: 22px !important; color: #fac837">fas fa-circle</v-icon>
                                    </v-btn>
                                    <v-btn text x-small
                                           role="menuitem"
                                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                           class="pa-0"
                                           aria-label="Farbe orange">
                                        <v-icon @click="changeColor('#f07d32')" style="font-size: 22px !important; color: #f07d32">fas fa-circle</v-icon>
                                    </v-btn>
                                    <v-btn text x-small
                                           role="menuitem"
                                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                           class="pa-0"
                                           aria-label="Farbe rot">
                                        <v-icon @click="changeColor('#e6231e')" style="font-size: 22px !important; color: #e6231e">fas fa-circle</v-icon>
                                    </v-btn>
                                    <v-btn text x-small
                                           role="menuitem"
                                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                           class="pa-0"
                                           aria-label="Farbe hellblau">
                                        <v-icon @click="changeColor('#6996cd')" style="font-size: 22px !important; color: #6996cd">fas fa-circle</v-icon>
                                    </v-btn>
                                    <v-btn text x-small
                                           role="menuitem"
                                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                           class="pa-0"
                                           aria-label="Farbe dunkelblau">
                                        <v-icon @click="changeColor('#505faa')" style="font-size: 22px !important; color: #505faa">fas fa-circle</v-icon>
                                    </v-btn>
                                    <v-btn text x-small
                                           role="menuitem"
                                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                           class="pa-0"
                                           aria-label="Farbe grün">
                                        <v-icon @click="changeColor('#8cbe46')" style="font-size: 22px !important; color: #8cbe46">fas fa-circle</v-icon>
                                    </v-btn>
                                    <v-btn text x-small
                                           role="menuitem"
                                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                           class="pa-0"
                                           aria-label="Farbe grau">
                                        <v-icon @click="changeColor('#706f6f')" style="font-size: 22px !important; color: #706f6f">fas fa-circle</v-icon>
                                    </v-btn>
                                </div>
                            </v-menu>
                        </div>
                    </v-menu>
                </v-col>

                <v-col :cols="windowWidth < 770 ? (windowWidth < 380 ? 2 : 3) : 5" style="display: flex; justify-content: flex-end;">

    <!--                <editor-tools-->
    <!--                    @addTextMessage="spawnTextObject"-->
    <!--                />-->

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-if="targetLang !== 'de'" @click="translate = !translate" v-on="on"
                                   x-small elevation="0" class="ml-4"
                                   style="width: 50px; height: 50px; border-radius: 8px;"
                                   :style="translate ? 'background-color: var(--v-gruen-base) !important' : ''">
                                <v-icon style="max-width: 25px; max-height: 25px;"
                                        :alt="translate ? 'Übersetzung deaktivieren' : 'Übersetzung aktivieren'"
                                >mdi-translate</v-icon>
                            </v-btn>

                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span v-if="translate">Übersetzung deaktivieren</span>
                            <span v-else>Übersetzung aktivieren</span>
                            <img v-if="hoeren" :src="uebersetzenMetacom" width="100" style="margin: auto">
                        </div>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                    v-on="on"
                                    @click="toggleMagnifier"
                                    style="width: 50px; height: 50px; border-radius: 8px;"
                                    :style="magnifier ? 'background-color: #8CBD46 !important' : ''"
                                    x-small elevation="0"
                                    class="ml-4"
                                    :tabindex="20 + tabOrderArray.length"
                            >
                                <img :src="lupenIcon" style="max-width: 25px; max-height: 25px;" :alt="magnifier ? 'Lupe ausschalten (Tastaturkürzel: Alt + L)' : 'Lupe einschalten (Tastaturkürzel: Alt + L)'"/>
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span v-if="magnifier">Lupe ausschalten (Alt + L)</span>
                            <span v-else>Lupe einschalten (Alt + L)</span>
                            <img v-if="hoeren" :src="lupeMetacom" width="100" style="margin: auto">
                        </div>
                    </v-tooltip>

                    <v-tooltip bottom v-if="windowWidth > 600">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                    v-on="on"
                                    x-small elevation="0"
                                    class="ml-4"
                                    style="width: 50px; height: 50px; border-radius: 8px;"
                                    :style="keyboard ? 'background-color: #8CBD46 !important' : ''"
                                    @click="toggleKeyboard"
                                    :tabindex="20 + tabOrderArray.length"
                            >
                                <img :src="keyboardIcon" :alt="keyboard ? 'Tastatursteuerung ausschalten (Tastaturkürzel: Alt + T)' : 'Tastatursteuerung einschalten (Tastaturkürzel: Alt + T)'" height="25">
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span v-if="keyboard">Tastatursteuerung ausschalten (Alt + T)</span>
                            <span v-else>Tastatursteuerung einschalten (Alt + T)</span>
                            <img v-if="hoeren" :src="tastaturTippenMetacom" width="100" style="margin: auto">
                        </div>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn id="bearbeitungAbschliessenButton" v-on="on"
                                   aria-label="Bearbeiten abschließen"
                                   :tabindex="20 + tabOrderArray.length"
                                   class="save-image-btn ml-4 mr-3" elevation="3"
                                   dark small
                                   @click="() => { saveDialog = true; setFocusOn('abgabeAccept') }">
                                <img :src="fertigIcon" class="mr-2"/>
                                <p class="mb-0 pa-1">Fertig</p>
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span>Bearbeiten abschließen</span>
                            <img v-if="hoeren" :src="fertigMetacom" width="100" style="margin: auto">
                        </div>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn id="closeBtn" v-on="on"
                                   aria-label="Bearbeitung zwischenspeichern"
                                   :tabindex="20 + tabOrderArray.length"
                                   @click="() => { saveProgressDialog = true; setFocusOn('abgabeStop') }"
                                   x-small class="mr-4" elevation="3">
                                <img :src="closeIcon" style="height: 40px; filter: brightness(1000%)"/>
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span>Bearbeiten zwischenspeichern</span>
                            <img v-if="hoeren" :src="aufhoerenMetacom" width="100" style="margin: auto">
                        </div>
                    </v-tooltip>
                </v-col>
            </v-row>
        </header>
        <main>
            <vuescroll :ops="ops" style="height: calc(100vh - 85px); background-color: #EAEAEA" ref="scrollbar" @handle-scroll="scrolledEvent">

                <!-- Editing row -->
                <v-row class="ma-0" align="center" justify="center">

                    <div id="pageView" v-if="false">
                        <p class="mb-0 mt-4 mx-auto">Seite: 1 / 3</p>
                        <div style="position:relative; display: block">
                            <v-btn x-small text v-for="(page, index) in pages" :key="'pageBtn'+index"
                                @click="jumpToPage(index)" height="60px" class="mx-auto" width="100px"
                            >
                                <img :src="seitenIcon" height="50px"/>
                            </v-btn>
                        </div>
                    </div>
    <!--                Stored for safekeeping-->
    <!--                :class="{'multiplePagesZoomControl': pages.length && windowWidth > 770}"-->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn aria-label="Verkleinern" tabindex="17" elevation="3" x-small v-on="on"
                                   class="mr-3 zoomControl" @click="changeZoom('minus')">
                                <p>-</p>
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span>Verkleinern</span>
                        </div>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn aria-label="Vergrößern" tabindex="17" elevation="3" x-small class="mr-3 zoomControl" style="margin-left: 63px"
                                   @click="changeZoom('plus')" v-on="on">
                                <p>+</p>
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span>Vergrößern</span>
                        </div>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                aria-label="Texte des Dokuments aktivieren"
                                v-on="on"
                                elevation="3"
                                x-small
                                tabindex="17"
                                class="mr-3 zoomControl"
                                id="textLayerButton"
                                :class="{ 'textLayerActive': isTextLayerVisible }"
                                style="margin-left: 126px"
                                @click="changePDFTextLayerVisibility"
                            >
                                <v-icon style="max-width: 28px; max-height: 35px">fas fa-magic</v-icon>
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span>Texte des Dokuments aktivieren</span>
                        </div>
                    </v-tooltip>
                    <div style="position: relative">
                        <EditingCanvas
                            v-for="(page, index) in pages" :key="index"
                            :canvas-height="canvasHeight"
                            :canvas-width="canvasWidth"
                            :loading-function="setLoading"
                            :tab-index="21 + tabOrderArray.length"
                            @placeNewEditorTextMessage="(top, left) => { placeNewEditorTextMessage(index, top, left) }"
                            :ref="`editingcanvas${index}`"
                            @dimensionsChanged="(data) => {
                                canvasWidth = data.width;
                                canvasHeight = data.height;
                            }"
                            @updateZoom="(direction) => { changeZoom(direction); }"
                            :is-text-layer-visible="isTextLayerVisible"
                            @readParagraph="(text, callback) => { readText(text, callback) }"
                            @textDivsEmpty="() => { noTextOnTextLayerSnackbar = true }"
                            style="position: relative">
                        </EditingCanvas>
                        <EditorRectangle
                            v-for="(rect, index) in rectangles"
                            :key="'rectangle' + index"
                            :rect="rect"
                            :canvas-width="canvasWidth"
                            :canvas-height="canvasHeight"
                            @deleteObject="() => { rectangles.splice(index, 1) }"
                            :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                                top: ${audioMessageYAxis(rect)}%; left: ${ rect.x * (1 + currentZoom / 5) }%; transform: scale(${1 + currentZoom / 5}, ${ 1 + currentZoom / 5 }`"
                        />
                        <EditorLine
                            v-for="(line, index) in lines"
                            :key="'line' + index"
                            :line="line"
                            :canvas-width="canvasWidth"
                            :canvas-height="canvasHeight"
                            @deleteObject="() => { lines.splice(index, 1) }"
                            :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                                top: ${audioMessageYAxis(line)}%; left: ${ line.x * (1 + currentZoom / 5) }%; transform: scale(${1 + currentZoom / 5}, ${ 1 + currentZoom / 5 }`"
                        />
                        <!-- Images should probably be the lowest element, so people can place stuff on top of them -->
                        <EditorImageObject
                            v-for="(img, i) in imageList"
                            :key="'imgFile'+i"
                            :tab-index="19 + img.tabIndex"
                            :img="img"
                            :canvas-width="canvasWidth"
                            :canvas-height="canvasHeight"
                            @deleteObject="() => imageList.splice(i, 1)"
                            :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                                top: ${audioMessageYAxis(img)}%; left: ${img.x * (1 + (currentZoom / 5))}%;
                                transform: scale(${1 + (currentZoom / 5)}, ${1 + (currentZoom / 5)}
                                `"
                        >
                        </EditorImageObject>
                        <EditorVideoObject
                            v-for="(video, i) in videoList"
                            :key="'videoFile'+i"
                            :video="video"
                            :canvas-width="canvasWidth"
                            :canvas-height="canvasHeight"
                            :tab-index="19 + video.tabIndex"
                            @deleteObject="() => videoList.splice(i, 1)"
                            :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                                top: ${audioMessageYAxis(video)}%; left: ${video.x * (1 + (currentZoom / 5))}%;
                                transform: scale(${1 + (currentZoom / 5)}, ${1 + (currentZoom / 5)}
                                `"
                        >
                        </EditorVideoObject>
                        <EditorAudioMessage
                            v-for="(message, index) in messageList"
                            :key="'audio'+index"
                            :tab-index="19 + message.tabIndex"
                            :state="'answer'"
                            :style="`position: absolute; top: ${audioMessageYAxis(message)}%; left: ${message.x}%;`"
                            :uploadMessage="message" :index="index"/>
                        <EditorTextMessage
                            v-for="(message, index) in textMessageList"
                            :key="'text'+index"
                            :tab-index="19 + message.tabIndex"
                            :message="message" :index="index"
                            :style="`position: absolute; top: ${audioMessageYAxis(message)}%; left: ${message.x}%;`"
                            :target-lang="targetLang"
                            :translate="translate"/>
                        <EditorTextTask
                            v-for="(task, index) in textTaskList"
                            :key="'texttask'+index"
                            :task="task"
                            :tab-index="19 + task.tabIndex"
                            :canvas-height="canvasHeight"
                            :canvas-width="canvasWidth"
                            :style="`position: absolute; transform-origin: top left;
                                    top: ${audioMessageYAxis(task)}%; left: ${task.x * (1 + (currentZoom / 5))}%;
                                    width: ${canvasWidth * task.width}px; height: ${canvasHeight * task.height}px;
                                    transform: scale(${1 + (currentZoom / 5)}, ${1 + (currentZoom / 5)}
                                    `"
                            :teachers="teacherIdArray"
                            :state="'answer'"
                            :target-lang="targetLang"
                            :translate="translate"
                        />
                        <EditorAnswerField
                            v-for="(answerEntry, index) in textAnswerList"
                            :key="'answerEntry'+index"
                            :tab-index="19 + answerEntry.tabIndex"
                            :answer-entry="answerEntry"
                            :canvas-height="canvasHeight"
                            :canvas-width="canvasWidth"
                            :style="`position: absolute; transform-origin: top left;
                            top: ${audioMessageYAxis(answerEntry)}%; left: ${answerEntry.x * (1 + (currentZoom / 5))}%;
                            transform: scale(${1 + (currentZoom / 5)}, ${1 + (currentZoom / 5)}
                            `"
                        />
                        <EditorMultipleChoice
                            v-for="(mChoices, index) in multipleChoices"
                            :key="'multipleChoice' + index"
                            :m-data="mChoices"
                            :tab-index="19 + mChoices.tabIndex"
                            :canvas-width="canvasWidth"
                            :canvas-height="canvasHeight"
                            @deleteObject="multipleChoices.splice(index, 1);"
                            :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                                top: ${audioMessageYAxis(mChoices)}%; left: ${ mChoices.x * (1 + currentZoom / 5) }%; transform: scale(${1 + currentZoom / 5}, ${ 1 + currentZoom / 5 }`"
                            :target-lang="targetLang"
                            :translate="translate"
                        />
                        <EditorMoveableText
                            v-for="(text, index) in moveableTexts"
                            :key="'moveableText' + index"
                            :text="text"
                            :tab-index="19 + text.tabIndex"
                            :canvas-width="canvasWidth"
                            :canvas-height="canvasHeight"
                            :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                                top: ${audioMessageYAxis(text)}%; left: ${ text.x * (1 + currentZoom / 5) }%; transform: scale(${1 + currentZoom / 5}, ${ 1 + currentZoom / 5 }`"
                        />
                    </div>
                </v-row>
            </vuescroll>
        </main>

        <!-- Popup land -->
        <v-dialog v-model="loadingActive" hide-overlay persistent width="150">
            <v-card :color="groupColor">
                <v-card-text class="pa-2">
                    <v-progress-circular indeterminate class="ma-auto" style="display: block"/>
                    <br/>
                    <p class="ma-auto" style="text-align: center">{{ loadingMessage }}</p>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="saveDialog" max-width="350px">
            <v-card>
                <v-card-title>
                    <p id="abgabeAccept" tabindex="0" style="width: fit-content">Abgabe bestätigen</p>
                </v-card-title>

                <v-card-text>
                    <p tabindex="0" style="width: fit-content">Willst du die Bearbeitung wirklich endgültig abschließen?</p>
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn @click="() => { saveDialog = false; setFocusOn('bearbeitungAbschliessenButton') }" class="mr-2 text-capitalize" color="rot" dark>
                        <img :src="closeIcon" style="height: 20px; filter: brightness(1000%)" class="mr-2">
                        Abbrechen
                    </v-btn>

                    <v-btn @click="() => { saveImage(); saveDialog = false; setFocusBackOnHausi() }" class="text-capitalize" color="gruen" dark>
                        <img :src="fertigIcon" style="height: 20px; filter: brightness(1000%)" class="mr-2">
                        Abgeben
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="saveProgressDialog" max-width="350px">
            <v-card>
                <v-card-title>
                    <p id="abgabeStop" tabindex="0" style="width: fit-content">Abbruch bestätigen</p>
                </v-card-title>

                <v-card-text>
                    <p tabindex="0" style="width: fit-content">Möchtest du deinen Fortschritt zwischenspeichern oder verwerfen?</p>
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn @click="() => { close(); saveProgressDialog = false; setFocusBackOnEditButton() }" class="mr-2 text-capitalize" color="rot" dark>
                        <img :src="closeIcon" style="height: 20px; filter: brightness(1000%)" class="mr-2">
                        Verwerfen
                    </v-btn>

                    <v-btn @click="() => { zwischenspeichern(); saveProgressDialog = false; }" class="text-capitalize" color="gruen" dark>
                        <img :src="fertigIcon" style="height: 20px; filter: brightness(1000%)" class="mr-2">
                        <p v-if="windowWidth > 400" class="mb-0">Zwischenspeichern</p>
                        <p v-else class="mb-0">Speichern</p>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
                v-model="snackbars.fileNotAllowed"
                timeout="5000"
                color="error"
        >
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbars.fileNotAllowed = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
            </v-btn>
          </template>
            Du hast keinen Zugriff auf diese Datei. Bitte schreibe deinem Lehrer.
        </v-snackbar>
        <v-snackbar
                v-model="snackbars.fileNotAvailable"
                timeout="5000"
                color="error"
        >
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbars.fileNotAvailable = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
            </v-btn>
          </template>
          Die Datei konnte nicht gefunden werden. Bitte schreibe deinem Lehrer.
        </v-snackbar>
        <v-snackbar
                v-model="snackbars.dataCouldntBeSaved"
                timeout="5000"
                color="error"
        >
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbars.dataCouldntBeSaved = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
            </v-btn>
          </template>
            Ergebnisse konnten nicht gespeichert werden. Bitte versuche es später nochmal.
        </v-snackbar>
        <v-snackbar
                v-model="snackbars.genericError"
                timeout="5000"
                color="error"
        >
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbars.genericError = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
            </v-btn>
          </template>
          Etwas ist schief gelaufen - Bitte aktualisiere die Seite!
        </v-snackbar>

        <!-- Textlayer activated snackbar -->
        <v-snackbar
                v-model="textLayerActivatedSnackbar"
                timeout="30000"
                :color="noTextOnTextLayerSnackbar ? 'warning' : 'gruen'"
        >
            <template v-slot:action="{ attrs }">
                <v-btn
                        text
                        v-bind="attrs"
                        small
                        @click="textLayerActivatedSnackbar = false"
                >
                    <img style="max-width: 20px" alt="Schließen" :src="closeIcon"/>
                </v-btn>
            </template>
            <span v-if="noTextOnTextLayerSnackbar">Der Textlayer wurde aktiviert, aber es wurde kein Text auf dem PDF gefunden. Wurde das PDF
                vielleicht vorher eingescannt? Mehr Infos finden Sie im FAQ.</span>
            <span v-else>Der Textlayer wurde aktiviert. Nun können Sie Texte auf dem PDF auswählen und mit ihnen interagieren.
                Einige andere Funktionen sind währenddessen deaktiviert.</span>
        </v-snackbar>
    </div>
</template>

<script>
    import EditingCanvas from "../EditingCanvas";
    import {mapActions, mapGetters, mapState} from 'vuex';
    import stiftIcon from '../../assets/Icons/bearbeiten-komplimentär-weiß-88.svg'
    import undoIcon from '../../assets/Editing/rückgänig.svg'
    import closeIcon from '../../assets/Icons/abbrechen-08.svg'
    import drehIcon from '../../assets/Icons/90-grad-drehung.svg'
    import textIcon from '../../assets/Icons/Textfunktion-67.svg'
    import speichernIcon from '../../assets/Icons/speichern-64.svg'
    import radiererIcon from '../../assets/Icons/radierer-73.svg'
    import fertigIcon from '../../assets/Icons/fertig-74.svg'
    import seitenIcon from '../../assets/Icons/kein-dokument-68.svg'
    import keyboardIcon from '../../assets/Icons/keyboard-24px.svg'
    import lupenIcon from '../../assets/Icons/lupe-18.svg'
    import malenMetacom from '../../assets/METACOM/Editor/malen.png'
    import tastaturTippenMetacom from '../../assets/METACOM/Allgemein/tastaturtippen.png'
    import loeschenMetacom from '../../assets/METACOM/Allgemein/loeschendaten.png'
    import farbenMetacom from '../../assets/METACOM/Editor/farbe.png'
    import aussuchenMetacom from '../../assets/METACOM/Editor/aussuchen.png'
    import drehenMetacom from '../../assets/METACOM/Editor/drehen.png'
    import fertigMetacom from '../../assets/METACOM/Editor/beenden.png'
    import aufhoerenMetacom from '../../assets/METACOM/Editor/aufhoeren.png'
    import lupeMetacom from '../../assets/METACOM/Allgemein/suchenlupe.png'
    import uebersetzenMetacom from '../../assets/METACOM/Allgemein/uebersetzen.png'
    import vuescroll from 'vuescroll';
    import scrollbarStyling from '../../util/scrollbarStyling'
    import * as backend from "../../api/backend";
    import EditorAudioMessage from "../Editortools/EditorAudioMessage";
    import EditorTextMessage from "../Editortools/EditorTextMessage";
    import EditorAnswerField from "../Editortools/EditorAnswerField";
    import EditorTextTask from "../Editortools/EditorTextTask";
    import {PDFDocument} from 'pdf-lib';
    import pdfjs from '../../util/pdfjs';
    import EditorImageObject from "@/components/Editortools/EditorImageObject";
    import EditorVideoObject from "@/components/Editortools/EditorVideoObject";
    import EditorMultipleChoice from "@/components/Editortools/EditorMultipleChoice";
    import kariertesBlatt from "@/assets/Icons/kariertes blatt-112.svg";
    import leeresBlatt from "@/assets/Icons/leeres-blatt-110.svg";
    import liniertesBlatt from "@/assets/Icons/liniertes-blatt-111.svg";
    import EditorMoveableText from "@/components/Editortools/EditorMoveableText";
    import EditorRectangle from "@/components/Editortools/EditorRectangle";
    import lautsprecherIcon from "../../assets/Icons/lautsprecher-13.svg";
    import lautsprecherAusIcon from "../../assets/Icons/lautsprecher-aus-89.svg";
    import EditorLine from "@/components/Editortools/EditorLine";

    export default {
        name: 'app',
        components: {
            EditorLine,
            EditorRectangle,
            EditorMoveableText,
            EditorMultipleChoice,
            EditorVideoObject,
            EditorImageObject,
            EditorAudioMessage,
            EditingCanvas,
            vuescroll,
            EditorTextMessage,
            EditorAnswerField,
            // EditorTools,
            EditorTextTask,
        },
        data() {
            return {
                windowWidth: window.innerWidth,
                currentActiveMethod: 'none',
                params: {},
                color: "#706f6f",
                imageUrl: null,
                croppedImage: false,
                canvasWidth: 300,
                canvasHeight: 600,
                componentKey: 0,
                currentFile: null,
                groupName: 'Deutsch',
                groupColor: 'blue',
                assignmentName: 'Aufgabe 1',
                appointmentId: '',
                fileId: '',
                uploadId: '',
                pages: [0],
                emptyPageType: null,
                loadingActive: false,
                saveDialog: false,
                saveProgressDialog: false,
                loadingMessage: 'Dokument wird geladen...',
                snackbars: {
                    dataSaved: false,
                    dataSubmitted: false,
                    fileNotAllowed: false,
                    fileNotAvailable: false,
                    dataCouldntBeSaved: false,
                    genericError: false,
                },
                ops: {
                    rail: {
                        opacity: '0',
                        background: undefined,
                        border: 'none',
                        size: '60px'
                    },
                    bar: {
                        background: '#55555580',
                        keepShow: true,
                        size: '45px',
                        minSize: 0.1
                    },
                    scrollButton: {
                        enable: true,
                        background: '#cecece'
                    },
                    scrollPanel: {
                        easing: 'easeInQuad',
                        speed: 800
                    },
                    vuescroll: {
                        wheelScrollDuration: 0,
                        wheelDirectionReverse: false,
                        sizeStrategy: 'percent',
                        detectResize: true
                    }
                },
                currentZoom: 0,
                displayOtherButtons: false,
                isHorizontalScrollbarStyled: false,
                isVerticalScrollbarStyled: false,

                stiftSizeMenu: false,
                colorMenu: false,
                addPageMenu: false,

                scrollbarStyling,

                // Editor Messages
                messageList: [],
                textMessageList: [],
                textTaskList: [],
                textAnswerList: [],
                imageList: [],
                videoList: [],
                multipleChoices: [],
                moveableTexts: [],
                rectangles: [],
                lines: [],
                additionalPagesDropdown: [ { title: 'Leere Seite', value: 'leer', img: leeresBlatt }, { title: 'Karierte Seite', value: 'kariert', img: kariertesBlatt }, { title: 'Linierte Seite', value: 'liniert', img: liniertesBlatt }, { title: 'Hauslinien Seite', value: 'haus', img: liniertesBlatt }],
                tabOrderArray: [],

                sehen: false,
                hoeren: false,
                babyView: false,

                translate: false,
                currentLang: 'de',

                // Icons
                stiftIcon,
                undoIcon,
                closeIcon,
                textIcon,
                speichernIcon,
                radiererIcon,
                fertigIcon,
                drehIcon,
                seitenIcon,
                keyboardIcon,
                lupenIcon,
                malenMetacom,
                tastaturTippenMetacom,
                loeschenMetacom,
                farbenMetacom,
                aussuchenMetacom,
                drehenMetacom,
                fertigMetacom,
                aufhoerenMetacom,
                lupeMetacom,
                uebersetzenMetacom,
                kariertesBlatt,
                leeresBlatt,
                liniertesBlatt,
                lautsprecherIcon,
                lautsprecherAusIcon,

                // PDF.JS Textlayer
                isTextLayerVisible: false,
                selectedText: null,
                textSelectionInterval: null,
                lastSelectedTextNode: null,
                textSelectionActionButtons: {
                    show: false,
                    top: null,
                    left: null,
                },
                noTextOnTextLayerSnackbar: false,
                textLayerActivatedSnackbar: false,
            }
        },
        props: {},
        watch: {
            selectedText(newVal) {

                if (newVal && this.lastSelectedTextNode) {
                    const topPixels = this.lastSelectedTextNode.style.top.substring(0, this.lastSelectedTextNode.style.top.length-2);
                    // const leftPixels = this.lastSelectedTextNode.style.left.substring(0, this.lastSelectedTextNode.style.left.length-2);
                    if (this.lastSelectedTextNode.parentElement && this.lastSelectedTextNode.parentElement.id) {
                        const lastMarkedPageIndex =
                            Number.parseInt(this.lastSelectedTextNode.parentElement.id.replace('textLayer', ''));

                        this.textSelectionActionButtons.top =
                            (((lastMarkedPageIndex * this.canvasHeight) + Number.parseFloat(topPixels))
                                / this.canvasHeight / this.pages.length) * 100 * (1 + this.currentZoom / 5);
                    } else {
                        this.textSelectionActionButtons.top = (topPixels / this.canvasHeight) * 100 * (1 + this.currentZoom / 5);

                    }
                    this.textSelectionActionButtons.left = 80;
                    this.textSelectionActionButtons.show = true;

                    console.log(this.textSelectionActionButtons);
                } else {
                    this.textSelectionActionButtons.show = false;

                }
            }
        },
        computed: {
            ...mapState("auth", ["account"]),
            ...mapState("magnifier", ["magnifier"]),
            ...mapState("util", ["keyboard", "lastOpenedInEditor"]),
            ...mapGetters('files', ['getCurrentFileUrl', 'getCurrentFile', 'getCurrentFileType', 'getWork']),
            ...mapGetters('appointments', [ 'appointment' ]),
            ...mapGetters('translation', [ 'getTargetLang' ]),
            teacherIdArray() {
                let currentAppointment = this.appointment(this.appointmentId);
                // if (!currentAppointment) {
                //     currentAppointment = this.getAppointments().find(item => item._id === this.appointmentId);
                // }
                const resultArray = [];

                for (let i = 0; i < currentAppointment.teachers.length; i++) {
                    resultArray.push(currentAppointment.teachers[i].account);
                }
                return resultArray;
            },
            targetLang() {
                return this.getTargetLang;
            },
        },
        methods: {
            ...mapActions('files', ['pullCurrentFile', 'safeCurrentFile', 'setCurrentFile', 'safeWork', 'pullSavedWork', 'setSnackbar', 'safeEmptyPage', 'safeWorkFromEmptyPage', 'safeNewPdfFile']),
            ...mapActions('uploadMessage', [ 'getUploadMessages', 'createUploadMessages' ]),
            ...mapActions('appointments', [ 'getAppointments' ]),
            ...mapActions("util", ["toggleKeyboard"]),
            ...mapActions("magnifier", ["toggleMagnifier", "turnOffMagnifier"]),
            ...mapActions("pupils", ["getMePupil"]),
            ...mapActions("translation", ["setTargetLang" , "translateToTargetLang", 'setTranslatedText', 'showTranslation']),

            async setFocusBackOnHausi() {
                setTimeout(() => {
                    document.getElementById("doneTab").focus();
                }, 500)
            },

            async setFocusBackOnEditButton() {
                setTimeout(() => {
                    const id = this.lastOpenedInEditor + '-editButton'
                    document.getElementById(id).focus();
                }, 500)
            },

            async setFocusOn(elementId) {
                await this.$nextTick();
                document.getElementById(elementId).focus();
            },

            // https://stackoverflow.com/questions/58075400/using-v-tooltip-inside-v-menu-activator-in-vuetify-2-0
            getHandlers() {
                return [...arguments].reduce((seed, item) => {
                    const mergedHandlers = { ...item, ...seed };

                    return mergedHandlers;
                }, {});
            },

            async requestBabyViewAndAccessibilityMode() {
                let temp = await this.getMePupil();
                this.babyView = temp.babyView;
                if(temp.accessibility) {
                    this.sehen = temp.accessibility.sehen;
                    this.hoeren = temp.accessibility.hoeren;
                } else {
                    this.sehen = false;
                    this.hoeren = false;
                }
                this.loaded = true; //to check if they are babys
                if (this.babyView) {
                    this.babyViewTut = true;
                }
            },
            changeColor(colorHex) {
                this.color = colorHex;
                for (let i = 0; i < this.pages.length; i++) {
                    this.$refs[`editingcanvas${i}`][0].changeColor(colorHex);
                }
            },
            changePDFTextLayerVisibility() {
                console.log('changePDFTextLayerVisibility called');
                const intervalFunction = () => {
                    let selection = { ...this.selectedText };
                    if (window.getSelection) {
                        selection = window.getSelection();
                    }
                    // document.getSelection
                    else if (document.getSelection) {
                        selection = document.getSelection();
                    }
                    // document.selection
                    else if (document.selection) {
                        selection =
                            document.selection.createRange().text;
                    }
                    if (selection.focusNode && selection.focusNode.parentNode) {
                        this.lastSelectedTextNode = selection.focusNode.parentNode;
                    }

                    if(selection.toString() !== this.selectedText) {
                        this.update = !this.update;
                    }
                    this.selectedText = selection.toString();

                };

                this.isTextLayerVisible = !this.isTextLayerVisible;
                this.textSelectionInterval =
                    this.textSelectionInterval == null
                        ? setInterval(intervalFunction, 100) : clearInterval(this.textSelectionInterval);

                if (!this.isTextLayerVisible) {
                    this.selectedText = null;
                    this.lastSelectedTextNode = null;
                }
                else {
                    setTimeout(() => {
                        for (let i = 0; i < this.pages.length; i++) {
                            this.$refs[`editingcanvas${i}`][0].calculateParagraphPositions();
                        }
                    }, 250);
                    this.textLayerActivatedSnackbar = true;
                }
            },

            async readText(text, callback) {
                console.log(' readText called');
                if ('speechSynthesis' in window) {
                    // Speech Synthesis supported 🎉
                } else {
                    // Speech Synthesis Not Supported 😣
                    alert("Sorry, your browser doesn't support text to speech!");
                }

                let msgText = text;
                if (this.translate) {
                    msgText = await this.translateToTargetLang({
                        targetLang: this.targetLang,
                        textToTranslate: text
                    });

                    this.setTranslatedText(msgText);
                    this.showTranslation(true);
                }

                if(window.speechSynthesis.speaking) {
                    window.speechSynthesis.cancel();
                    this.speakerIcon = this.lautsprecherIcon;
                    callback(this.lautsprecherIcon, false);

                    if (this.currentMessage.text === msgText) {
                        this.currentMessage = null;
                        this.showTranslation(false);
                        return;
                    }
                }

                let msg = new SpeechSynthesisUtterance();
                msg.text = msgText;
                msg.lang = this.translate ? this.targetLang : this.currentLang;
                if (this.isLangPackageAvailable()) {
                    window.speechSynthesis.speak(msg);
                    this.currentMessage = msg;
                    this.speakerIcon = this.lautsprecherAusIcon;
                    callback(this.lautsprecherAusIcon, true);
                }

                msg.onend = () => {
                    this.speakerIcon = this.lautsprecherIcon;
                    this.currentMessage = null;
                    callback(this.lautsprecherIcon, false);
                };
            },
            isLangPackageAvailable() {
                for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                    if (window.speechSynthesis.getVoices()[i].lang.includes(this.currentLang)) {
                        return true;
                    }
                }
                return false;
            },

            async zwischenspeichern() {
                try {

                    this.setLoading(true, 'Warten auf Speichern...');
                    const canvasHistory = [];
                    for (let i = 0; i < this.pages.length; i++) {
                        canvasHistory.push(this.$refs[`editingcanvas${i}`][0].safeCurrentWork());
                    }

                    let response = null;
                    if (!this.emptyPageType) {
                        response = await this.safeWork({
                            appointmentId: this.appointmentId,
                            uploadId: this.uploadId,
                            changeHistory: canvasHistory,
                        });
                    } else {
                        await this.close();
                    }

                    if (response.status === 200 ) {
                        if (canvasHistory.length > 0) {
                            this.setSnackbar({type: 'dataSaved', value: true });
                        }
                        await this.close();
                    } else {
                        this.snackbars.dataCouldntBeSaved = true;
                    }

                    this.setLoading(false);
                } catch (e) {
                    console.log(e);
                    this.snackbars.dataCouldntBeSaved = true;
                    this.setLoading(false);
                }
            },
            loadWork(savedWork) {
                if (savedWork.length) {
                    for (let i = 0; i < this.pages.length; i++) {
                        this.$refs[`editingcanvas${i}`][0].loadWork(savedWork[i]);
                    }
                } else {
                    this.$refs[`editingcanvas0`][0].loadWork(savedWork);
                }
            },
            addAdditionalPageClick(item){
                const index = this.pages.push(this.pages.length) - 1;
                // Wait for the next tick, so the page has been added and a new canvas is created
                this.$nextTick(async () => {
                    await this.$refs[`editingcanvas${index}`][0].setEmptyBackground(item.value, this.canvasWidth, this.canvasHeight);
                    this.$refs[`editingcanvas${index}`][0].changeColor(this.color);
                });
            },
            async saveImage() {
                this.setLoading(true, 'Warten auf Abgabe...');
                if (this.fileId) {
                    const images = [];
                    for (let i = 0; i < this.pages.length; i++) {
                        images.push(this.$refs[`editingcanvas${i}`][0].canvasAsImage);
                    }
                    await this.saveImageAsPDF(images);
                }/* else if (this.fileId && this.getCurrentFileType !== 'pdf') {
                    const image = this.$refs[`editingcanvas0`][0].canvasAsImage
                    await this.saveImageAsFile(image);
                } */else if (this.emptyPageType) {
                    const images = [];
                    for (let i = 0; i < this.pages.length; i++) {
                        images.push(this.$refs[`editingcanvas${i}`][0].canvasAsImage);
                    }
                    await this.saveNewPage(images);
                }
            },
            async createPdfFromImages(images){
                const pdfDoc = await PDFDocument.create();
                for (let i = 0; i < images.length; i++) {
                    const embeddableImage = await fetch(images[i]).then(res => res.arrayBuffer());
                    const page = pdfDoc.addPage([this.canvasWidth, this.canvasHeight]);
                    const pngImage = await pdfDoc.embedPng(embeddableImage);
                    page.drawImage(pngImage, {
                        x: 0,
                        y: 0,
                        width: this.canvasWidth,
                        height: this.canvasHeight,
                    });
                }
                return await pdfDoc.save();
            },
            async saveImageAsPDF(base64strings) {
                try {
                    const pdfBytes = await this.createPdfFromImages(base64strings);
                    const response = await this.safeCurrentFile({
                        userGroup: 'pupil',
                        data: pdfBytes,
                        fileType: 'pdf',
                        appointmentId: this.appointmentId,
                        fileId: this.fileId,
                        fileName: this.assignmentName
                    });

                    if (response.status === 200
                        || response.status === 201
                        || response.status === 202
                        || response.status === 204) {
                        this.setSnackbar({type: 'dataSubmitted', value: true});
                        const responseJson = await response.json();
                        await this.safeAllEditorMessages(responseJson._id);
                    } else {
                        this.snackbars.dataCouldntBeSaved = true;
                        this.setLoading(false);
                    }
                } catch (e) {
                    this.snackbars.dataCouldntBeSaved = true;
                    this.setLoading(false);
                }

            },
            async saveNewPage(base64) {
                try {
                    const pdfData = await this.createPdfFromImages(base64);
                    const response = await this.safeNewPdfFile({
                        data: pdfData,
                        appointmentId: this.appointmentId,
                        fileName: this.assignmentName,
                        userGroup: 'pupil'
                    });

                    response.addEventListener('load', (e) => {
                        if (response.status === 200
                            || response.status === 201
                            || response.status === 202
                            || response.status === 204) {
                            this.setSnackbar({type: 'dataSubmitted', value: true});
                            this.close();
                        } else {
                            this.snackbars.dataCouldntBeSaved = true;
                            this.setLoading(false);
                        }
                    });
                } catch (e) {
                    this.snackbars.dataCouldntBeSaved = true;
                    this.setLoading(false);
                }
            },
            changeSize(pixelCount) {
                for (let i = 0; i < this.pages.length; i++) {
                    this.$refs[`editingcanvas${i}`][0].changeSize(pixelCount);
                }
            },
            changeZoom(direction) {

                for (let i = 0; i < this.pages.length; i++) {
                    this.$refs[`editingcanvas${i}`][0].$el.style.transform = '';
                }

                if (direction === 'minus' && this.currentZoom > 0) {
                  this.currentZoom--;
                } else if (direction === 'plus' && this.currentZoom < 10) {
                  this.currentZoom++;
                }

                setTimeout(() => {

                    for (let i = 0; i < this.pages.length; i++) {
                        this.$refs[`editingcanvas${i}`][0].$el.style.transform = `scale(${1 + (this.currentZoom / 5)}, ${1 + (this.currentZoom / 5)})`;
                        this.$refs[`editingcanvas${i}`][0].$el.style.transformOrigin = `left top`;
                    }

                    this.$nextTick(this.$refs.scrollbar.refresh());
                    this.$nextTick(this.$refs.scrollbar.refreshInternalStatus());
                }, 150);

            },
            setTool(type) {
                this.stiftSizeMenu = true;
                if (this.currentActiveMethod !== type) {
                    // Special Case for Highlighter toggle
                    if (this.currentActiveMethod === 'marker' && type === 'pen') {
                        return;
                    }
                    this.currentActiveMethod = type;
                    for (let i = 0; i < this.pages.length; i++) {
                        this.$refs[`editingcanvas${i}`][0].switchTool(type);
                    }
                } else {
                    this.currentActiveMethod = 'none';
                    for (let i = 0; i < this.pages.length; i++) {
                        this.$refs[`editingcanvas${i}`][0].switchTool('none');
                    }
                }
            },
            undo() {
                if (this.pages.length === 1) {
                    this.$refs[`editingcanvas0`][0].undo();
                } else {
                    const scrolledDistance = this.$refs.scrollbar.getPosition().scrollTop;
                    const canvasIndex = (scrolledDistance - (scrolledDistance % this.canvasHeight)) / this.canvasHeight;
                    this.$refs[`editingcanvas${canvasIndex}`][0].undo();
                }
            },
            async close() {
                await this.$router.replace({
                    query: {
                        appointment: this.appointmentId,
                    }
                });
                this.setCurrentFile(null);
                this.$router.back();
                this.setLoading(false);
            },
            setLoading(status, msg) {
                this.loadingMessage = msg ? msg : 'Dokument wird geladen...';
                this.loadingActive = status;
            },
            spawnTextOnCanvas() {
                if (this.pages.length === 1) {
                    this.$refs[`editingcanvas0`][0].spawnText(null, null);
                } else {
                    const scrolledDistance = this.$refs.scrollbar.getPosition().scrollTop;
                    const canvasIndex = (scrolledDistance - (scrolledDistance % this.canvasHeight)) / this.canvasHeight;
                    this.$refs[`editingcanvas${canvasIndex}`][0].spawnText(null, null);
                }
            },
            checkForScrollbarStylingUpdates(scrollbarParent) {
                if (scrollbarParent.classList.contains('hasVBar') ) {
                    if (!this.isVerticalScrollbarStyled) {
                        this.scrollbarStyling.styleVerticalScrollbar();
                        this.isVerticalScrollbarStyled = true;
                    }
                } else {
                    this.isVerticalScrollbarStyled = false;
                }

                if (scrollbarParent.classList.contains('hasHBar') ) {
                    if (!this.isHorizontalScrollbarStyled) {
                        this.scrollbarStyling.styleHorizontalScrollbar();
                        this.isHorizontalScrollbarStyled = true;
                    }
                } else {
                    this.isHorizontalScrollbarStyled = false;
                }
            },
            async requestFileForEditing(fileType, userGroup) {
                const response = await this.pullCurrentFile({
                    userGroup,
                    fileType,
                    fileName: this.assignmentName,
                    appointmentId: this.appointmentId,
                    fileId: this.fileId
                });

                if (response && (response.status === 200
                    || response.status === 201
                    || response.status === 204)) {
                    if (response.file) {
                        this.currentFile = response.file;
                        if (this.getCurrentFileType === 'pdf') {
                            await this.computeLoadedPDF(this.currentFile);
                        } else {
                            await this.$refs[`editingcanvas0`][0].setBackgroundImg(this.currentFile);
                        }
                    } else {
                        if (response.error) {
                            this.snackbars.genericError = true;
                        }
                        this.setLoading(false);
                    }
                } else {
                    if (response.status === 401 || response.status === 403 ) {
                        this.snackbars.fileNotAllowed = true;
                    } else if (response.status === 404) {
                        this.snackbars.fileNotAvailable = true;
                    } else {
                        this.snackbars.genericError = true;
                    }
                    this.setLoading(false);
                }
                this.changeColor('#505faa');
            },
             async computeLoadedPDF(pdfFile) {
                const pdfDocumentLoadingTask =pdfjs.getDocument({data: pdfFile});
                const pdfDocument = await pdfDocumentLoadingTask.promise;

                const pagePromises = Array.from(Array(pdfDocument.numPages).keys()) // Create array from [0 ... countPages]
                    .map(element => element + 1) // Add 1 to each element to get all page numbers
                    .map(page => pdfDocument.getPage(page));
                console.log(resolvedPages);
                const resolvedPages = await Promise.all(pagePromises)
                this.pages = resolvedPages;

                for (let i = 0; i < resolvedPages.length; i++) {
                    const nameString = `editingcanvas${i}`.toString()
                    await this.$refs[nameString][0].setBackgroundPDF(resolvedPages[i])
                }
                this.changeColor('#505faa');
            },
            rotateEditor() {
                this.messageList.forEach((el) => {
                    let temp = JSON.parse(JSON.stringify(el.x));
                    el.x = el.y;
                    el.y = temp;
                    el.x = 100 - el.x;
                });
                for (let i = 0; i < this.pages.length; i++) {
                    this.$refs[`editingcanvas${i}`][0].rotateEditor();
                }
            },
            scrolledEvent(vertical, horizontal, nativeEvent){

            },
            jumpToPage(index) {
                this.$refs.scrollbar.scrollTo({ y: this.canvasHeight * index + 10 });
            },
            /**
             * Region Editor Messages (Audio, Video, Text)
             */
            audioMessageYAxis(message) {
                if (this.pages.length === 1) {
                    return message.y * (1 + (this.currentZoom / 5));
                } else {
                    return (message.page / this.pages.length) * 100 + (message.y * (1 / this.pages.length));
                }
            },
            spawnTextObject(text) {
                console.log('spawnTextObject called with text', text)
                this.tempTextMessage = text;
                if (this.pages.length === 1) {
                    this.$refs[`editingcanvas0`][0].spawnTextMessage();
                } else {
                    const scrolledDistance = this.$refs.scrollbar.getPosition().scrollTop;
                    const canvasIndex = (scrolledDistance - (scrolledDistance % this.canvasHeight)) / this.canvasHeight;
                    this.$refs[`editingcanvas${canvasIndex}`][0].spawnTextMessage();
                }
            },
            placeNewEditorTextMessage(page, top, left) {
                this.textMessageList.push({
                    x: left * 100,
                    y: top * 100,
                    page,
                    text: this.tempTextMessage,
                    tabIndex: this.tabOrderArray.length,
                });
                this.tabOrderArray.push(this.textMessageList[this.textMessageList.length - 1]);
            },
            async safeAllEditorMessages(newFileId) {
                console.log('Speichern: ', this.tabOrderArray);

                try {
                    const messages = [];
                    for (let i = 0; i < this.messageList.length; i++) {
                        const message = {
                            x: this.messageList[i].x,
                            y: this.messageList[i].y,
                            page: this.messageList[i].page,
                            start: this.messageList[i].start,
                            answer: null,
                            correction: null,
                            tabIndex: this.messageList[i].tabIndex,
                            messageType: 'audio',
                        };

                        if(this.messageList[i].answerFile) {
                            this.messageList[i].answer = await this.uploadRecordedMessage(this.messageList[i].answerFile, newFileId + '.wav');
                            message.answer = this.messageList[i].answer._id;
                        }
                        messages.push(message)
                    }

                    for (let i = 0; i < this.textMessageList.length; i++) {
                        const message = {
                            x: this.textMessageList[i].x,
                            y: this.textMessageList[i].y,
                            page: this.textMessageList[i].page,
                            messageType: 'text',
                            tabIndex: this.textMessageList[i].tabIndex,
                            text: this.textMessageList[i].text
                        }
                        messages.push(message)
                    }

                    for (let i = 0; i < this.textTaskList.length; i++) {
                        const message = {
                            x: this.textTaskList[i].x,
                            y: this.textTaskList[i].y,
                            page: this.textTaskList[i].page,
                            messageType: 'texttask',
                            width: this.textTaskList[i].width,
                            height: this.textTaskList[i].height,
                            startTextTask: this.textTaskList[i].startTextTask,
                            tabIndex: this.textTaskList[i].tabIndex,
                            answersTextTask: this.textTaskList[i].answersTextTask
                        }
                        messages.push(message)
                    }

                    for (let i = 0; i < this.textAnswerList.length; i++) {
                        console.log(this.textAnswerList[i]);
                        const message = {
                            x: this.textAnswerList[i].x,
                            y: this.textAnswerList[i].y,
                            page: this.textAnswerList[i].page,
                            messageType: 'answerField',
                            author: this.textAnswerList[i].author,
                            tabIndex: this.textAnswerList[i].tabIndex,
                            text: this.textAnswerList[i].text,
                        }
                        messages.push(message)
                    }

                    // Add the images
                    for (const img of this.imageList) {
                        const message = {
                            x: img.x,
                            y: img.y,
                            width: img.width,
                            height: img.height,
                            page: img.page,
                            start: img.start,
                            text: img.text,
                            tabIndex: img.tabIndex,
                            messageType: 'imageObject',
                        };
                        // Image is new and needs to be uploaded to the server
                        if (!message.start) {
                            let res = await backend.postUploadMessage(img.fileBlob);
                            res = await res.json();
                            message.start = res._id;
                        }
                        messages.push(message);
                    }

                    // Add the videos
                    for (const video of this.videoList) {
                        const message = {
                            x: video.x,
                            y: video.y,
                            width: video.width,
                            height: video.height,
                            page: video.page,
                            start: video.start,
                            messageType: 'videoObject',
                            tabIndex: video.tabIndex,
                        };
                        // Video is new and needs to be uploaded to the server
                        if (!message.start) {
                            let res = await backend.postUploadMessage(video.fileBlob);
                            res = await res.json();
                            message.start = res._id;
                        }
                        messages.push(message);
                    }
                    // Add the multiple choices
                    for (const mChoice of this.multipleChoices) {
                        const message = {
                            x: mChoice.x,
                            y: mChoice.y,
                            width: mChoice.width,
                            page: mChoice.page,
                            startTextTask: mChoice.startTextTask,
                            multipleChoices: mChoice.multipleChoices,
                            messageType: 'multipleChoice',
                            tabIndex: mChoice.tabIndex,
                        };
                        messages.push(message);
                    }
                    // Add the moveable texts
                    for (const text of this.moveableTexts) {
                        const message = {
                            x: text.x,
                            y: text.y,
                            width: text.width,
                            page: text.page,
                            text: text.text,
                            messageType: 'moveableText',
                            tabIndex: text.tabIndex,
                        };
                        messages.push(message);
                    }
                    // Add the rectangles
                    for (const rect of this.rectangles) {
                        const message = {
                            x: rect.x,
                            y: rect.y,
                            width: rect.width,
                            height: rect.height,
                            page: rect.page,
                            text: rect.text,
                            startTextTask: rect.startTextTask,
                            messageType: 'rectangle',
                        };
                        messages.push(message);
                    }
                    // Add the lines
                    for (const line of this.lines) {
                        const message = {
                            x: line.x,
                            y: line.y,
                            width: line.width,
                            height: line.height,
                            page: line.page,
                            text: line.text,
                            rotation: line.rotation,
                            messageType: 'line',
                        };
                        messages.push(message);
                    }

                    if (messages.length) {
                        this.createUploadMessages({ file: newFileId, points: messages });
                    }
                } catch (e) {
                    console.error(e);
                    this.snackbars.dataCouldntBeSaved = true;
                    this.setLoading(false);
                    return;
                }
                await this.close();
            },
            async uploadRecordedMessage( file, nameForCompleteness) {
                if (file) {

                    let file2 = new File([file], nameForCompleteness, {type: 'audio/wav'});
                    const res = await backend.postUploadMessage(file2);
                    return await res.json();
                }
            },
            sortIncomingMessages(messages) {
                // Fill tab order array
                this.tabOrderArray = [];
                const indexedMessages = messages.filter(item => item.tabIndex);
                for (let i = 0; i < indexedMessages.length; i++) {
                    this.tabOrderArray[indexedMessages[i].tabIndex] = indexedMessages[i];
                }
                console.log(this.tabOrderArray);

                this.messageList = messages.filter(item => item.messageType === 'audio' || !item.messageType);
                // console.log('messageList', this.messageList);
                this.textMessageList = messages.filter(item => item.messageType === 'text');
                // console.log('textMessageList', this.textMessageList);
                this.textTaskList = messages.filter(item => item.messageType === 'texttask');
                // console.log('textTaskList', this.textTaskList);
                this.textAnswerList = messages.filter(item => item.messageType === 'answerField');
                // console.log('textAnswerList', this.textAnswerList);
                this.imageList = messages.filter(item => item.messageType === 'imageObject');
                // console.log('imageList', this.imageList);
                this.videoList = messages.filter(item => item.messageType === 'videoObject');
                // console.log('videoList', this.videoList);
                this.multipleChoices = messages.filter(item => item.messageType === 'multipleChoice');
                // console.log('videoList', this.videoList);
                this.moveableTexts = messages.filter(item => item.messageType === 'moveableText');
                this.rectangles = messages.filter(item => item.messageType === 'rectangle');

                // Bugfix: Checks width and height of old rectangles > ONLY NEEDED DURING TRANSITION
                for (let i = 0; i < this.rectangles.length; i++) {
                    if (this.rectangles[i].width > 2) {
                        this.rectangles[i].width = this.rectangles[i].width / this.canvasWidth;
                    }
                    if (this.rectangles[i].height > 2) {
                        this.rectangles[i].height = this.rectangles[i].height / this.canvasHeight;
                    }
                }

                this.lines = messages.filter(item => item.messageType === 'line');
            },
        },
        async mounted() {

            let fileType = '';
            let localUploadId = '';
            let localAppointmentId = '';
            if (this.$route.query.group) {
                this.groupName = this.$route.query.group;
            }
            if (this.$route.query.color) {
                this.groupColor = this.$route.query.color;
            }
            if (this.$route.query.aId) {
                this.appointmentId = this.$route.query.aId;
                localAppointmentId = this.$route.query.aId;
            }
            if (this.$route.query.fId) {
                this.fileId = this.$route.query.fId;
            }
            if (this.$route.query.title) {
                this.assignmentName = this.$route.query.title;
                const assignmentNameParts = this.assignmentName.split('.')

                fileType = assignmentNameParts[assignmentNameParts.length - 1];
            }
            if (this.$route.query.uploadid) {
                this.uploadId = this.$route.query.uploadid;
                localUploadId = this.$route.query.uploadid;
            }
            if (this.$route.query.pagetype) {
                this.emptyPageType = this.$route.query.pagetype;
            }

            let localUserGroup = 'pupil';
            if (this.$route.query.ug) {
                localUserGroup = this.$route.query.ug;
            }

            this.setLoading(true);

            if (this.emptyPageType) {
                await this.$refs[`editingcanvas0`][0].setEmptyBackground(this.emptyPageType)
            } else {
                await this.requestFileForEditing(fileType, localUserGroup);
            }

            // Request messages
            try {
                const response = await this.getUploadMessages(this.fileId);
                if (Number.isInteger(response)) {
                    // show error
                } else {
                    // this.messageList = response && response.points ? response.points : [];
                    this.sortIncomingMessages(response && response.points ? response.points : []);
                }
            } catch (e) {
                console.log(e);
            }

            // Editor Autosave parts

            const params = {
                appointmentId: localAppointmentId,
                uploadId: localUploadId
            };
            try {

                const bufferResponse = await this.pullSavedWork(params);

                if (bufferResponse) {
                    const responseJson = await bufferResponse.json();
                    this.loadWork(responseJson.changeHistory);
                }
            } catch (e) {
                // Buffer is probably empty, should it be empty tho?
                if (e.name !== 'SyntaxError') {
                    console.error(e);
                }
            }

            this.checkForScrollbarStylingUpdates(document.getElementsByClassName('__vuescroll')[0]);

            const observer = new MutationObserver((mutationsList, observer) => {
                for(const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        this.checkForScrollbarStylingUpdates(document.getElementsByClassName('__vuescroll')[0]);
                    }
                }
            });

            // Start observing the target node for configured mutations
            observer.observe(
                document.getElementsByClassName('__vuescroll')[0],
                {attributes: false, childList: true, subtree: false }
            );

            if (this.windowWidth > 600) {
                this.displayOtherButtons = true;
            }

            this.requestBabyViewAndAccessibilityMode();
        },
    }
</script>

<style scoped lang="scss">
    @import "~@/styles/globals.scss";

    .save-image-btn {
        margin-left: 5px;
        height: 49px !important;
        background-color: #8CBD46 !important;

        @media (max-width: 380px) {
            width: 40px !important;
            min-width: 40px !important;
            height: 40px !important;
        }

        & img {
            @include zwischenspeichernIconSize();
            filter: brightness(1000%);
        }

        & p {
            @include zwischenspeichernFontSize();
        }
    }

    #closeBtn {
        background-color: #e6221e80;
        width: 49px;
        height: 49px;

        @media (max-width: 380px) {
            width: 40px !important;
            height: 40px !important;
            margin-right: 0 !important;
        }
    }

    .toolSelector {
        background-color: #f8f8f8 !important;
        width: 49px !important;
        height: 49px !important;

        img {
            filter: brightness(0%);
        }

        @media (max-width: 380px) {
            width: 40px !important;
            height: 40px !important;
        }
    }

    .activeTool {
        background-color: #d8d8d8 !important;
    }

    .markerActive {
        border: 2px solid rgb(234, 234, 234);
        background-color: #707070 !important;
        color: white;
        padding: 10px !important;
    }

    #pageView {
        position: fixed;
        left: 0;
        top: 81px;
        height: 100vh;
        width: 100px;
        background-color: white;

        p {
            text-align: center;
        }
    }

    .multiplePagesZoomControl {
        left: 150px !important;
    }

    .zoomControl {
        position: fixed;
        z-index: 8 !important;
        top: 95px;
        left: 20px;
        background-color: #f8f8f8 !important;
        width: 49px !important;
        height: 49px !important;
        border-radius: 8px;

        p {
            line-height: 0;
            font-size: 60px;
            margin-bottom: 0;
            font-weight: 300;
            color: #333;
        }

        @media (max-width: 380px) {
            width: 40px !important;
            height: 40px !important;

            p {
                font-size: 50px;
            }
        }
    }

    #smallDisplayMoreButtons {
        display: block;
        width: 50px;

        button {
            border-radius: 0 !important;
        }
    }

    #smallDisplayMoreButtonsTrigger {
        width: 49px !important;
        height: 49px !important;
        padding-left: 2px !important;

        @media (max-width: 380px) {
            width: 40px !important;
            height: 40px !important;
            padding-left: 0 !important;
        }
    }

    .colorPickerMenu {
        width: 216px !important;
        height: 25px;
        display: inline;
        border-radius: 0 !important;
        background-color: #EAEAEA;
    }

    .v-snack.v-snack--top {
        bottom: initial; /* or auto */
    }

    .v-snack.v-snack--bottom {
        top: initial; /* or auto */
    }

</style>
