<template>
  <div
    ref="textTaskOuter"
    id="textTaskOuter"
    :class="{ placingTextTaskOuter: state === 'start' }"
    @click="$emit('focusMe')"
    @mouseup="resizeEnd"
  >
    <v-container
      id="textTaskInner"
      ref="textTaskInner"
      fluid
      :key="isCorrected"
      class="pa-0"
    >
      <!-- Teacher text area -->
      <v-row dense>
        <v-col v-if="state === 'start' && !task.startTextTask && movable" class="pa-0 pt-1 ma-1">
          <img :src="moveIcon" id="moveSymbol" height="25" width="25" ref="moveSymbol" class="icon"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col style="min-width: 250px">
          <div v-if="state === 'start' && !task.startTextTask" style="display: inline-flex; width: 100%">
              <advanced-quill-editor
                placeholder="Hier Aufgabe eingeben"
                style="background-color: #ffffff00; width: 100%; height: 100%"
                v-model="teacherTask"
                :hide-image-btn="true"
                :class="{ 'moveableItemShadow': account.role === 'teacher' }"
              >
              </advanced-quill-editor>

            <div class="textTaskSaveButtons" style="align-content: flex-end">
              <v-btn color="success" class="ma-1 pa-0"
                     id="teacherConfirmExerciseButton" @click="teacherConfirmExercise"
              >
                <img :src="fertigIcon" alt="Fertigsymbol"
                     :ref="`teacherConfirmExerciseButton`" class="icon iconToWhite"
                />
              </v-btn>
              <v-btn class="ma-1 pa-0" id="deleteTaskButton" @click="deleteTask">
                <img :src="papierkorbIcon" alt="Papierkorbsymbol"
                     :ref="`deleteTaskButton`" class="icon"
                />
              </v-btn>
            </div>
          </div>
          <div class="pa-2" style="display: inline-flex; margin-top: 85px" v-else>
            <div class="answerField" style="background-color: #FFFFFF00">
              <h1 :tabindex="tabIndex" class="mb-0 pa-2 ql-editor" v-html="urlify(teacherTask)"></h1>
            </div>
            <v-btn :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                   :tabindex="person && person.accessibility && person.accessibility.screenreader ? '-1' : tabIndex" small @click="readText(teacherTask, 'lehrerEingabeVorlesenBtnIcon')"
                   width="40px" height="40px" class="my-auto ml-2"
            >
              <img :src="lautsprecherIcon" alt="Aufgabe vorlesen" :ref="`lehrerEingabeVorlesenBtnIcon`"
                width="35px" height="35px" style="filter: brightness(0%)"
              />
            </v-btn>
            <v-btn width="40px" height="40px" class="my-auto ml-2" small
                   id="smallDeleteTaskButton" @click="deleteTask"
                   v-if="account.role === 'teacher'"
            >
              <img :src="papierkorbIcon" alt="Papierkorbsymbol"
                   :ref="`deleteTaskButton`" class="icon"
              />
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="false">
        <!-- previous answers -->
        <v-col cols="5" v-if="pupilAnswers.length">
          <div v-for="(answer, index) in pupilAnswers" :key="index">
            <div
              class="answerField pa-2 ma-2"
              :class="{
                correctAnswer: isAnswerCorrect(answer),
                teacherAnswer: teachers.includes(answer.author),
              }"
            >
              <p class="my-auto">{{ answer.element }}</p>
              <v-btn
                :tabindex="tabIndex"
                v-if="isAnswerMine(answer)"
                class="ml-2"
                small
                @click="deleteAnswer(answer)"
                width="40px"
                height="40px"
              >
                <img
                  :src="papierkorbIcon"
                  alt="Antwort löschen"
                  width="35px"
                  height="35px"
                />
              </v-btn>
            </div>
            <v-btn
              :tabindex="tabIndex"
              class="ml-2"
              small
              @click="readText(answer.element, `vorlesenBtnIcon${index}`)"
              width="40px"
              height="40px"
            >
              <img
                :src="lautsprecherIcon"
                alt="Deine Antwort vorlesen"
                :ref="`vorlesenBtnIcon${index}`"
                width="35px"
                height="35px"
              />
            </v-btn>
          </div>
        </v-col>

        <!-- Pupil text entry field -->
        <v-col class="mt-0">
          <v-row dense class="mt-0">
            <v-col style="min-width: 200px" class="pt-0 pl-2">
              <v-textarea
                      :tabindex="tabIndex"
                v-if="state === 'answer' && !task.done"
                no-resize
                outlined
                background-color="#FFFFFF80"
                rows="2"
                label="Antworteingabe"
                v-model="currentPupilAnswer"
              />
            </v-col>

            <!-- Control area -->
            <v-col>
              <div v-if="state === 'answer' && account.role === 'pupil'">
                <v-btn :tabindex="tabIndex" color="success" class="ma-1" @click="userExerciseAnswer">
                  Abgeben
                </v-btn>
              </div>
              <div
                v-else-if="
                  state === 'answer' &&
                  account.role === 'teacher' &&
                  !isCorrected
                "
              >
                <v-btn class="ma-1" @click="userExerciseAnswer">
                  Abgeben
                </v-btn>
                <v-btn
                  color="success"
                  class="ma-1"
                  @click="teacherCorrectedUserAnswer"
                >
                  Richtig
                </v-btn>
              </div>
              <div
                v-else-if="
                  state === 'answer' &&
                  account.role === 'teacher' &&
                  isCorrected
                "
              >
                <v-btn class="ma-1" @click="revertCorrection">
                  Rückgängig
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import lautsprecherIcon from "../../assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "../../assets/Icons/lautsprecher-aus-89.svg";
import papierkorbIcon from "../../assets/Icons/papierkorb-109.svg";
import fertigIcon from "../../assets/Icons/fertig-74.svg";
import moveIcon from '../../assets/Icons/Temp/move.svg';
import { parseHtml } from "../../util/tempFilePreviewUtil";
import AdvancedQuillEditor from "@/components/Utils/AdvancedQuillEditor";

export default {
  name: "EditorTextTask",
    components: { AdvancedQuillEditor },
    props: {
      task: { type: Object, required: true },
      state: { type: String, required: true },
      teachers: { type: Array, required: true },
      tabIndex: { type: Number, required: false, default: 19 },
      canvasWidth: { type: Number, required: true },
      canvasHeight: { type: Number, required: true },

      targetLang: {type: String, required: false, default: 'de'},
      translate: {type: Boolean, required: false, default: false},
  },
  data() {
    return {
      //Textarea models
      teacherTask: null,
      pupilAnswers: [],
      currentPupilAnswer: null,

      // Drag and drop
      dragStartX: null,
      dragStartY: null,

      // UI
      papierkorbIcon,
      lautsprecherIcon,
      lautsprecherAusIcon,
      fertigIcon,
      moveIcon,
      vorlesenBtnIcons: [],
      isContainerDraggable: true,
      activeElement: document.activeElement,

        // Translate
        alreadyTranslated: false, //whether the texts were already translated once
        //store original texts
        untranslatedTask: null,
        untranslatedAnswers: [],
        //store translated texts (so translating is only done once)
        translatedTask: null,
        translatedAnswers: [],

      // Everything else
      isCorrected: null,
      movable: true,
        person: {
          accessibility: {
              screenreader: false,
          }
        }
    };
  },
  computed: {
    ...mapState("auth", ["account"]),
    isAnswerMine() {
      return (answer) => answer.author === this.account._id;
    },
  },
  watch: {
    task(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.fillTaskData(newVal);
      }
    },
    isCorrected(newVal) {
      console.log("is It?", newVal);
      this.dragElement(this.$refs.moveSymbol, this.$refs.textTaskOuter);
    },
      translate() {
          this.toggleTextLanguage();
      },
  },
    mounted() {
        this.fillTaskData(this.task);
        if (this.state === 'start') {
            this.dragElement(this.$refs.moveSymbol, this.$refs.textTaskOuter);
        }
        this.storeOriginalTexts();
        this.requestPerson();
    },
  methods: {
      ...mapActions('translation', [ 'translateToTargetLang' ]),
      ...mapActions('pupils', [ 'getMePupil' ]),

      async requestPerson() {
        if(this.account.role === 'pupil') {
          this.person = await this.getMePupil();
          return this.person;
        }
      },

    // task data handling
    fillTaskData(currentTask) {
      console.log("fillTaskData called with task", currentTask);
      if (currentTask.startTextTask) {
        this.teacherTask = currentTask.startTextTask;
      }

      let vorleseBtnsArrayLength = 1;
      if (currentTask.answersTextTask) {
        this.pupilAnswers = currentTask.answersTextTask;
        vorleseBtnsArrayLength += currentTask.answersTextTask.length;
      }

      for (let i = 0; i < vorleseBtnsArrayLength; i++) {
        this.vorlesenBtnIcons.push(this.lautsprecherIcon);
      }
    },

    // Start Methods
    teacherConfirmExercise() {
      this.teacherTask = this.teacherTask.replaceAll("</p>", " </p>");
      this.teacherTask = this.teacherTask.replaceAll("</span>", " </span>");
      this.teacherTask = this.teacherTask.replaceAll("</li>", " </li>");
      this.teacherTask = this.teacherTask.replaceAll("</em>", " </em>");
      this.teacherTask = this.teacherTask.replaceAll("</strong>", " </strong>");

      this.task.startTextTask = this.teacherTask;
      this.isCorrected = true;
      this.movable = false;
    },
    // Answer Methods
    userExerciseAnswer() {
      this.task.answersTextTask.push({
        element: this.currentPupilAnswer,
        author: this.account._id,
      });
      this.currentPupilAnswer = "";
    },

    teacherCorrectedUserAnswer() {
      this.task.done = true;
      this.isCorrected = true;
    },
    revertCorrection() {
      this.task.done = false;
      this.isCorrected = null;
    },

    // General Actions
    abortAction() {},
    deleteTask() {
      this.$emit("deleteTask");
    },
    deleteAnswer(answer) {

      const answerIndex = this.task.answersTextTask.findIndex(
        (item) => item === answer
      );
      if (answerIndex > -1) {
        this.task.answersTextTask.splice(answerIndex, 1);
      }
    },
    toggleDraggableMode(event) {
      this.isContainerDraggable = true;
      if (document.activeElement && document.activeElement.tagName !== "BODY") {
        console.log(document.activeElement);
        this.isContainerDraggable = false;
      }
    },
    dragElement(element, elementToMove) {
      let _this = this;
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      let lastMove = null;

      if (element) {
        element.onmousedown = dragMouseDown;
        element.ontouchstart = dragMouseDown;
      }

      function dragMouseDown(e) {
        _this.$emit('focusMe');
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        lastMove = e;
        document.onmouseup = closeDragElement;
        document.ontouchend = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
        document.ontouchmove = elementDrag;
      }

      const elementDrag = (e) => {
        e = e || window.event;
        lastMove = e;
        if(e.type !== 'touchmove') {
          e.preventDefault();
        }

        // calculate the new cursor position:
        pos1 = pos3 - (e.clientX ? e.clientX : e.touches[0].clientX);
        pos2 = pos4 - (e.clientY ? e.clientY : e.touches[0].clientY);
        pos3 = (e.clientX ? e.clientX : e.touches[0].clientX);
        pos4 = (e.clientY ? e.clientY : e.touches[0].clientY);
        // set the element's new position:
        const top = (Number.parseFloat(this.task.y)) - ((pos2 / this.canvasHeight) * 100);
        const left = (Number.parseFloat(this.task.x)) - (( pos1 / this.canvasWidth) * 100);

        if ((left > -10 && left < 110) && (top > -10 && top < 110)) {
          this.task.x = left;
          this.task.y = top;
        }
      }

      const closeDragElement = (e) => {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
        document.ontouchend = null;
        document.ontouchmove = null;
        try {
          this.click(e.clientX ? e.clientX : lastMove.touches[0].clientX, e.clientY ? e.clientY : lastMove.touches[0].clientY);
        } catch (e) {
          // console.log(e);
        }
        lastMove = null;
      }
    },

    // Read out Methods
    readText(text, answerRef) {
      if ("speechSynthesis" in window) {
        // Speech Synthesis supported 🎉
      } else {
        // Speech Synthesis Not Supported 😣
        alert("Sorry, your browser doesn't support text to speech!");
      }

      let imageRef = this.$refs[answerRef];
      if (Array.isArray(imageRef)) {
        imageRef = imageRef[0];
      }

      let tmp = document.createElement("div");
      tmp.innerHTML = text;
      let msgText = tmp.textContent || tmp.innerText;

      if (window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
        imageRef.src = this.lautsprecherIcon;
      } else {
        let msg = new SpeechSynthesisUtterance();
        msg.text = msgText;
        if(this.translate) {
            msg.lang = this.targetLang;
        } else {
            msg.lang = 'de';
        }

        window.speechSynthesis.speak(msg);

        imageRef.src = this.lautsprecherAusIcon;

        msg.onend = () => {
          imageRef.src = this.lautsprecherIcon;
        };
      }
    },

    // Styling Methods
    isAnswerCorrect(answer) {
      if (
        this.task.done &&
        this.task.answersTextTask &&
        this.task.answersTextTask.length
      ) {
        const pupilAnswers = this.task.answersTextTask.filter(
          (item) => !this.teachers.includes(item.author)
        );
        console.log(pupilAnswers);
        if (answer === pupilAnswers[pupilAnswers.length - 1]) {
          return true;
        }
      }
      return false;
    },
    urlify(text) {
      // eslint-disable-next-line no-useless-escape
      var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|software|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
      return text.toString().replace(urlRegex, function (url) {
        url = url.includes("http") ? url : "http://" + url;
        return (
          '<a href="' +
          url +
          '" target="_blank" rel="noopener noreferrer">' +
          url +
          "</a>"
        );
      });
      // or alternatively
      // return text.replace(urlRegex, '<a href="$1">$1</a>')
    },
    parseTextToHTML(text) {
      parseHtml(text);
    },

    resizeEnd(event) {
      if (event.dataTransfer) {
        this.dragEnd(event);
      }

      const width = this.$refs.textTaskOuter.style.width.substring(
        0,
        this.$refs.textTaskOuter.style.width.length - 2
      );
      const height = this.$refs.textTaskOuter.style.height.substring(
        0,
        this.$refs.textTaskOuter.style.height.length - 2
      );
      this.$emit("dimensionsChanged", { width, height });
    },

      storeOriginalTexts() {
          this.untranslatedTask = this.task.startTextTask;
      },

      async toggleTextLanguage() {
          //TODO find a way to keep URL clickable after translating
          //translate and replace displayed texts
          if (this.translate && !this.alreadyTranslated) {
              this.translatedTask = await this.translateToTargetLang({
                  targetLang: this.targetLang,
                  textToTranslate: this.task.startTextTask,
                  textFormat: 'html'
              });
              this.teacherTask = this.translatedTask;
              this.alreadyTranslated = true;
          }
          //just replace displayed texts with stored translated texts
          else if (this.translate && this.alreadyTranslated) {
              this.teacherTask = this.translatedTask;
          }
          //set texts to original language
          else {
              this.teacherTask = this.untranslatedTask;
          }
      },
  },
};
</script>

<style scoped lang="scss">
.icon {
  width: 25px;
  height: 25px;
}

#moveSymbol:hover {
  cursor: move;
}

#moveSymbol {
  filter: opacity(0.75);
}

.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

#textTaskOuter {
  resize: both !important;
  overflow: hidden !important;
  width: 430px;
}

.placingTextTaskOuter {
  border-radius: 4px;
  background-color: rgba(100, 100, 100, 0);
}

.textTaskSaveButtons {
  float: right;
}

#textTaskInner {
  width: 100%;
}

.correctAnswer {
  border: 1px solid #8cbd46 !important;
}

.teacherAnswer {
  border: 1px solid #505faa !important;
}

.answerField {
  display: inline-flex;
  border-radius: 8px;
}

#exerciseField {
  display: inline-flex;
  border-radius: 8px;
  padding: 0.5em;

  p {
    margin-bottom: 0;
    p {
      margin-bottom: 0;
    }
  }
}

</style>

<style lang="scss">
// Overriding stuff
.ql-snow {
  border: 1px solid #555 !important;
}
</style>
