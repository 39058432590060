const collection = [
  {
    header: 'Lehrer anlegen',
    text: 'Legen Sie neue Lehrer unter dem Reiter "Lehrer" an. Dort können Sie im unteren Feld\n' +
      'alle Informationen zu Ihrem neuen Lehrer eingeben und bereits\n' +
      'angelegte Lehrer bearbeiten.',
  },{
    header: 'Schüler anlegen',
    text: 'Legen Sie neue Schüler unter dem Reiter "Schüler" an. Dort können Sie im unteren Feld\n' +
      'alle Informationen zu Ihrem neuen Schüler eingeben und bereits\n' +
      'angelegte Schüler bearbeiten. Ein Import aus unserer bestehenden Excel Datei ist\n' +
      'ebenfalls möglich.',
  },{
    header: 'Gruppen anlegen',
    text: 'Legen Sie Gruppen unter dem Reiter "Gruppen" an. Dort können Sie der Gruppe\n' +
      '(welche eine Klasse, eine AG, etc.. sein kann) einen Namen geben und\n' +
      'Lehrer und Schüler hinzufügen.',
  },{
    header: 'Fächer anlegen',
    text: 'Legen Sie Fächer unter dem Reiter "Fächer" an. Dort können Sie dem Fach\n' +
      'einen Namen geben, eine Farbe sowie ein Icon festlegen\n' +
      'und verschiedene Fachlehrer hinzufügen.',
  },{
    header: 'Schulzeiten anlegen',
    text: 'Legen Sie neue Schulzeiten unter dem Reiter "Schulzeiten" an. Dort können Sie im unteren Feld\n' +
      'die Start- und Endzeit einer Schulstunde festlegen.',
  },{
    header: 'Stundenpläne anlegen',
    text: 'Legen Sie neue Stundenpläne unter dem Reiter "Stundenpläne" an. Dort können Sie im Stundenplan\n' +
      'eine Gruppe auswählen, für welche Sie den Stundenplan definieren möchten und fügen\n' +
      'Fächer mit dem entsprechenden Fachlehrer hinzu.',
  },{
    header: 'Das schwarze Brett',
    text: 'Kommunizieren Sie unter dem Reiter "Schwarzes Brett" Neuigkeiten Ihrer Schule.\n' +
      'Dort können Sie Nachrichten verfassen und diese entweder mit allen\n' +
      'oder nur mit ausgewählten Gruppen teilen.',
  },
];

export default { collection };
