<template>
    <div>
        <input
            type="file"
            ref="imgUpload"
            style="display: none"
            hidden
            name="file" accept="image/*"
            @change="imgSelected"
        />
        <input
            type="file"
            ref="videoUpload"
            style="display: none"
            hidden
            name="file" accept="video/mp4,video/mpeg,video/mov,video/*"
            @change="videoSelected"
        />
        <BaseMenu
            :items="items"
            :show-list="showList"
            @close="close"
            @itemClicked="onItemSelected"
        >
            <!-- Add custom data here -->
            <div>
                Custom data
            </div>
        </BaseMenu>
    </div>
</template>

<script>
import BaseMenu from "@/components/Editortools/Toolbar/Menus/BaseMenu";
import bildIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_blid.svg';
import videoIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_video.svg';
import { mediaMenuHelp } from "@/constants/editorHelpCollection";

export default {
    name: 'MediaMenu',
    components: {BaseMenu},
    data() {
        return {
            showList: true,
            items: [
                {icon: bildIcon, text: 'Bilder', help: { type: 'text', content: mediaMenuHelp[0] }},
                {icon: videoIcon, text: 'Video', help: { type: 'text', content: mediaMenuHelp[1] }},
            ],
        }
    },
    methods: {
        onItemSelected(item) {
            console.log("Item selected", item);
            if (item.text === 'Bilder') {
                this.addImage();
            } else if (item.text === 'Video') {
                this.addVideo();
            }
        },
        addImage() {
            this.$refs.imgUpload.click();
            this.close();
        },
        addVideo() {
            this.$refs.videoUpload.click();
            this.close();
        },
        imgSelected() {
            if(this.$refs.imgUpload.files.length > 0) {
                let file = this.$refs.imgUpload.files[0];
                let url = window.URL.createObjectURL(file);
                this.$emit('addImage', url, file);
                this.$refs.imgUpload.value = "";
            }
        },
        videoSelected() {
            if(this.$refs.videoUpload.files.length > 0){
                let file = this.$refs.videoUpload.files[0];
                let url = window.URL.createObjectURL(file);
                this.$emit('addVideo', url, file);
                this.$refs.videoUpload.value = "";
            }
        },

        /**
         * General
         */
        close() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>

</style>
