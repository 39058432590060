<template>
    <div>
        <div :class="{shakeMe: (account.role === 'teacher' || !disableMovePupil)}">
            <v-row no-gutters align="center" justify="center">
                <v-col>
                    <p
                        :tabindex="tabIndex"
                        v-text="text.text"
                        ref="movingIcon"
                        style="cursor: grab; padding: 3px 8px 3px 8px; border: 2px solid blue; border-radius: 4px;"
                        class="mr-2"
                        :style="`
                            font-size: ${text.width === 2 ? 'xxx-large' : text.width === 1 ? 'x-large' : 'large'};
                        `"
                    >
                    </p>
                </v-col>
                <v-col cols="auto" style="padding: 1px 2px 1px 1px">
                    <v-btn :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                           x-small elevation="1" style="height: 35px; width: 35px"
                           @click="read" :tabindex="tabIndex">
                        <img :src="speakerIcon" alt="Text vorlesen"/>
                    </v-btn>
                </v-col>
                <v-col cols="auto" v-if="account.role === 'teacher'" style="padding: 1px 2px 1px 1px" class="mt-2">
                    <img :src="papierkorbIcon" @click="$emit('deleteObject')" style="cursor: pointer" ref="deleteIcon" height="25" width="25" />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>

import moveIcon from '../../assets/Icons/Temp/move.svg';
import papierkorbIcon from "../../assets/Icons/papierkorb-109.svg";
import {mapActions, mapState} from "vuex";
import {textToSpeech} from "@/util/textToSpeech";
import lautsprecherIcon from "@/assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "@/assets/Icons/lautsprecher-aus-89.svg";

export default {
    name: "EditorMoveableText",
    computed: {
        ...mapState("auth", ["account"]),
    },
    props: {
        disableMovePupil: {type: Boolean, required: false, default: false },
        text: {type: Object, required: true},
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
        tabIndex: { type: Number, required: false, default: 19 },
    },
    data: () => ({
        moveIcon,
        papierkorbIcon,
        lautsprecherIcon,
        lautsprecherAusIcon,

        speakerIcon: lautsprecherIcon,

        person: {
            accessibility: {
                screenreader: false,
            }
        }
    }),
    mounted() {
        if(!this.disableMovePupil || this.account.role === 'teacher')
            this.dragElement(this.$refs.movingIcon);
        this.requestPerson();
    },
    methods: {
        ...mapActions('pupils', [ 'getMePupil' ]),

        async requestPerson() {
            this.person = await this.getMePupil();
        },

        resizeEnd(event) {
            console.log("Resize End: ", event);
            if (event.dataTransfer) {
                this.dragEnd(event);
            }

            const width = this.$refs.imgObjectOuter.style.width.substring(
                0,
                this.$refs.imgObjectOuter.style.width.length - 2
            );
            const height = this.$refs.imgObjectOuter.style.height.substring(
                0,
                this.$refs.imgObjectOuter.style.height.length - 2
            );
            this.text.width = width;
            this.text.height = height;
            //this.$emit("dimensionsChanged", { width, height });
        },
        dragElement(element) {
            let _this = this;
            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            let lastMove = null;

            element.onmousedown = dragMouseDown;
            element.ontouchstart = dragMouseDown;
            // element.onpointerdown = dragMouseDown;
            // element.onpointermove = elementDrag;

            function dragMouseDown(e) {
                // _this.$emit('focusMe');
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                lastMove = e;
                document.onmouseup = closeDragElement;
                document.ontouchend = closeDragElement;
                // document.onpointerup = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
                document.ontouchmove = elementDrag;
                // document.onpointermove = elementDrag;
            }

            const elementDrag = (e) => {
                e = e || window.event;
                lastMove = e;
                if (e.type !== 'touchmove' && e.type !== 'pointermove') {
                    e.preventDefault();
                }

                // calculate the new cursor position:
                pos1 = pos3 - (e.clientX ? e.clientX : e.touches[0].clientX);
                pos2 = pos4 - (e.clientY ? e.clientY : e.touches[0].clientY);
                pos3 = (e.clientX ? e.clientX : e.touches[0].clientX);
                pos4 = (e.clientY ? e.clientY : e.touches[0].clientY);
                // set the element's new position:
                const top = (Number.parseFloat(this.text.y)) - ((pos2 / this.canvasHeight) * 100);
                const left = (Number.parseFloat(this.text.x)) - ((pos1 / this.canvasWidth) * 100);

                if ((left > -10 && left < 110) && (top > -10 && top < 110)) {
                    this.text.x = left;
                    this.text.y = top;
                }
            }

            const closeDragElement = (e) => {
                // stop moving when mouse button is released:
                document.onmouseup = null;
                document.onmousemove = null;
                document.ontouchend = null;
                document.onpointerup = null;
                document.ontouchmove = null;
                document.onpointermove = null;
                try {
                    this.click(e.clientX ? e.clientX : lastMove.touches[0].clientX, e.clientY ? e.clientY : lastMove.touches[0].clientY);
                } catch (e) {
                    // console.log(e);
                }
                lastMove = null;
            }
        },
        read() {
            textToSpeech(this.text.text, 'de', () => {
                this.speakerIcon = this.lautsprecherAusIcon;
            }, () => {
                this.speakerIcon = this.lautsprecherIcon;
            });
        }
    }
}
</script>

<style lang="sass">
// Language needs to be sass to be able to use the random() method

// Iterate over the different text-items to have an individual animation-delay between the items (so they don't shake all at the same time)
@for $i from 1 through 30
    .shakeMe:nth-of-type(#{$i})
        animation: shake 5s linear infinite #{random(4000)}ms
    // Pause the animation when the cursor hovers an item
    .shakeMe:nth-of-type(#{$i}):hover
        -webkit-animation-play-state: paused
        -moz-animation-play-state: paused
        -o-animation-play-state: paused
        animation-play-state: paused

@keyframes shake
    0%
        transform: translate(1px, 0px) rotate(-1deg)
    2%
        transform: translate(-1px, 0px) rotate(-1deg)
    5%
        transform: translate(-3px, 0px) rotate(1deg)
    8%
        transform: translate(3px, 0px) rotate(0deg)
    11%
        transform: translate(0px, 0px) rotate(0deg)
    100%
        transform: translate(0px, 0px) rotate(0deg)

</style>
