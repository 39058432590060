var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"absolute","top":"0","bottom":"0","left":"0","right":"0","background-color":"#f8f8f8"}},[_c('div',{staticStyle:{"position":"absolute","display":"flex","justify-content":"flex-end","width":"100%"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4 mt-4",staticStyle:{"width":"50px","height":"50px","border-radius":"8px"},style:(_vm.magnifier ? 'background-color: #8CBD46 !important' : 'background-color: #eeeeee !important'),attrs:{"x-small":"","elevation":"0"},on:{"click":function($event){return _vm.toggleMagnifier()}}},on),[_c('img',{staticStyle:{"max-width":"25px","max-height":"25px"},attrs:{"src":_vm.lupenIcon,"alt":_vm.magnifier ? 'Lupe ausschalten (Tastaturkürzel: Alt + L)' : 'Lupe einschalten (Tastaturkürzel: Alt + L)'}})])]}}])},[(_vm.magnifier)?_c('span',[_vm._v("Lupe ausschalten (Alt + L)")]):_c('span',[_vm._v("Lupe einschalten (Alt + L)")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4 mt-4",staticStyle:{"width":"50px","height":"50px","border-radius":"8px"},style:(_vm.keyboard ? 'background-color: #8CBD46 !important' : 'background-color: #eeeeee !important'),attrs:{"x-small":"","elevation":"0"},on:{"click":function($event){return _vm.toggleKeyboard()}}},on),[_c('img',{attrs:{"src":_vm.keyboardIcon,"alt":_vm.keyboard ? 'Tastatursteuerung ausschalten (Tastaturkürzel: Alt + T)' : 'Tastatursteuerung einschalten (Tastaturkürzel: Alt + T)',"height":"25"}})])]}}])},[(_vm.keyboard)?_c('span',[_vm._v("Tastatursteuerung ausschalten (Alt + T)")]):_c('span',[_vm._v("Tastatursteuerung einschalten (Alt + T)")])])],1),(false)?_c('v-btn',{staticClass:"mt-5 mr-5 helpBtn questionmarkIcon",attrs:{"text":"","fixed":"","right":"","top":""},on:{"click":function($event){return _vm.showLoginTutorial()}}},[_c('v-img',{staticClass:"questionmarkIcon",attrs:{"src":_vm.questionmark,"contain":"","alt":"Fragezeichensymbol"}})],1):_vm._e(),_c('div',{staticClass:"infobar"},[_c('div',{staticClass:"infobarIcons"},[_c('img',{staticClass:"infobarIcon",attrs:{"src":_vm.devices}}),_c('img',{staticClass:"infobarIcon",attrs:{"src":_vm.internet}})]),_vm._m(0)]),_c('div',{staticClass:"loginFormWrapper"},[_c('div',{staticClass:"loginForm positionLoginForm"},[_c('div',[_c('v-img',{staticClass:"center mb-4",staticStyle:{"width":"80%"},attrs:{"src":_vm.logo,"alt":""}})],1),_c('div',{staticClass:"loginFormInputs"},[_c('div',{staticClass:"loginFormRow"},[_c('v-text-field',{staticClass:"font-weight-bold",attrs:{"label":"Name","outlined":"","light":"","hide-details":"","color":"#707070 !important"},on:{"keydown":function (keyEvent) {
                if (keyEvent.key === 'Enter') {
                  _vm.clickLoginUser();
                }
              }},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('img',{staticStyle:{"display":"block","height":"30px"},attrs:{"src":_vm.headIcon,"alt":"Personensymbol"}})]},proxy:true}]),model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}})],1),_c('div',{staticClass:"loginFormRow"},[_c('v-text-field',{staticClass:"font-weight-bold",attrs:{"type":_vm.show1 ? 'text' : 'password',"label":"Passwort","outlined":"","light":"","hide-details":"","color":"#707070 !important"},on:{"keydown":function (keyEvent) {
                if (keyEvent.key === 'Enter') {
                  _vm.clickLoginUser();
                }
              }},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('img',{staticStyle:{"display":"block","height":"30px","margin-top":"-4px","color":"#747474"},attrs:{"tabindex":"0","role":"button","alt":_vm.show1 ? 'Passwort ausblenden' : 'Passwort anzeigen',"src":_vm.show1 ? _vm.eyeIcon : _vm.eyeOffIcon},on:{"click":function($event){_vm.show1 = !_vm.show1}}})]},proxy:true},{key:"append-outer",fn:function(){return [_c('img',{staticStyle:{"height":"30px"},attrs:{"src":_vm.loginIcon,"alt":"Schloßsymbol"}})]},proxy:true}]),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('div',{staticClass:"loginFormRow stayLoggedIn mb-0",staticStyle:{"justify-content":"center"}},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"label":"Angemeldet bleiben"},model:{value:(_vm.stayLoggedIn),callback:function ($$v) {_vm.stayLoggedIn=$$v},expression:"stayLoggedIn"}})],1)]),_c('v-row',{staticClass:"loginBtnWrapper"},[_c('v-col',{staticClass:"pa-0"},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"text-capitalize loginBtn",attrs:{"aria-role":"button","large":"","outlined":"","id":"qrLoginButton"}},on),[_c('img',{staticClass:"mr-2 icon",attrs:{"src":_vm.qrLoginIcon,"alt":""}}),_vm._v(" QR-Code Login ")])]}}]),model:{value:(_vm.qrcodeDialog),callback:function ($$v) {_vm.qrcodeDialog=$$v},expression:"qrcodeDialog"}},[_c('v-card',[_c('q-r-scan-login',{attrs:{"code-scan-active":_vm.qrcodeDialog,"return-function":_vm.qrLoginUser,"close-function":function () { _vm.qrcodeDialog = false; }}})],1)],1)],1),_c('v-col',{staticClass:"pa-0"},[_c('v-btn',{staticClass:"loginBtn text-capitalize",attrs:{"outlined":"","large":"","id":"regularLoginBtn"},on:{"click":_vm.clickLoginUser}},[_c('img',{staticClass:"mr-2 icon",attrs:{"src":_vm.beitretenIcon,"alt":"Beitrittssymbol"}}),_vm._v(" Login ")])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":_vm.$vuetify.breakpoint.width > 500 ? 1 : 0}})],1),_c('v-row',{attrs:{"id":"homescreenTextWrapper"}},[_c('v-col',{attrs:{"id":"homescreenText","cols":_vm.$vuetify.breakpoint.width > 500 ? 11 : 10}},[_vm._v(" Füge die eKlara-App deinem Homescreen hinzu: "),_c('a',{staticClass:"infobarLink",attrs:{"href":"https://www.youtube.com/watch?v=LcvvbcVRI14","aria-label":"Füge die eKlara-App deinem Homescreen hinzu Beispielvideo"}},[_vm._v("So geht's")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":_vm.$vuetify.breakpoint.width > 500 ? 1 : 2}})],1)],1)]),_c('v-snackbar',{attrs:{"bottom":"","color":"error","timeout":"5000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","small":""},on:{"click":function($event){_vm.snackbars.credentialsNotFound = false}}},'v-btn',attrs,false),[_c('img',{staticStyle:{"max-width":"20px"},attrs:{"alt":"Schließen","src":_vm.closeIcon}})])]}}]),model:{value:(_vm.snackbars.credentialsNotFound),callback:function ($$v) {_vm.$set(_vm.snackbars, "credentialsNotFound", $$v)},expression:"snackbars.credentialsNotFound"}},[_vm._v(" Kein Nutzer mit diesen Daten gefunden! ")])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"infobarTexts"},[_c('span',{staticClass:"infobarText"},[_vm._v("Unterstützte Internetbrowser: "),_c('b',[_vm._v("Safari, Chrome, Firefox.")])]),_c('span',{staticClass:"infobarText"},[_vm._v("Eine "),_c('b',[_vm._v("stabile Internetverbindung")]),_vm._v(" ist vorrausgesetzt")])])}]

export { render, staticRenderFns }