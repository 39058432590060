<template>
    <div class="group-chat-wrapper">
        <div class="group-chat-header d-flex align-center">
            <v-tooltip :disabled="!showTooltips" bottom>
                <template v-slot:activator="{ on }">
                    <v-btn id="backButton" v-on="on" @click="() => { clickBack() }" class="ma-2 group-chat-header-btn" color="white" fab text small dark>
                        <img :src="pfeilIcon" alt="Zurück" class="icon iconToWhite" style="transform: rotate(90deg)">
                    </v-btn>
                </template>
                <span>Zurück</span>
            </v-tooltip>
            <h2 tabindex="0" class="group-chat-header-text mb-0" id="chatName">{{ room.name.includes('private Room')? checkRoomName(room.name) : room.name }}</h2>
        </div>
        <div v-if="room" class="chat-body" id="chatBody">
            <ScrollAreaMagnifier :offset-height-chat="offsetHeightChat" :settings="settings" :usage="'chatWidget'" ref="scrollAreaMagnifier" @ps-y-reach-start="loadMore()">
                        <!-- Hello There -->
                        <div class="my-2">
                            <div style="display: flex; flex-direction: column; flex-wrap: nowrap" :key="index" v-for="(event, index) in events">
                                <div v-if="event.getType() === 'm.room.message'">
                                    <div style="display: flex" :class="( isMe(event) ? 'myMessage ' : ' ') + 'chat-message'" :var="teacherPic = getTeacherPic(event)">
                                        <v-row dense>
                                            <v-col>
                                                <v-row dense v-long-press="1000" @long-press-start="(isMe(event) && event.getContent().body)? ((selectedEvent = event) && (deleteDialog = true)) : null">
                                                    <v-col class="pa-0 greyText">
                                                        <div style="display:inline-flex">
                                                            <!-- For Teachers only, display avatar with Profile Picture -->
                                                            <v-avatar v-if="teacherPic!==null" class="mr-2" medium elevation="0" fab color="#6994cc">
                                                                <img alt="Lehrer Profilbild" :src="teacherPic ? teacherPic : lehrerIcon" :style="teacherPic ? 'height: 48px' : 'height: 48px'" :class="teacherPic ? 'roundImage' : 'iconToWhite'">
                                                            </v-avatar>
                                                            <p :aria-hidden="person && person.accessibility && person.accessibility.screenreader" tabindex="0" class="mb-0" style="width: fit-content; height: fit-content">{{ getUsername(event) }}</p>
                                                            <p tabindex="0" class="mb-0 visually-hidden" style="width: fit-content; float: right">
                                                                {{
                                                                    'Nachricht von ' + getUsername(event) + ' am ' + new Date(getTime(event)).toLocaleDateString() + ' ' + getAccessibilityTime(new Date(getTime(event)).toLocaleTimeString().slice(0, new Date(getTime(event)).toLocaleTimeString().length - 3))
                                                                }}
                                                            </p>
                                                        </div>
                                                    </v-col>
                                                    <v-col class="text-right greyText" style="font-size: smaller;">
                                                        <p :aria-hidden="person && person.accessibility && person.accessibility.screenreader" tabindex="0" class="mb-0" style="width: fit-content; float: right">
                                                            {{new Date(getTime(event)).toLocaleDateString() + ' ' + new Date(getTime(event)).toLocaleTimeString().slice(0, new Date(getTime(event)).toLocaleTimeString().length - 3) }}
                                                        </p>
                                                    </v-col>
                                                </v-row>
                                                <v-row
                                                        dense
                                                        v-long-press="1000" @long-press-start="(isMe(event) && event.getContent().body) ? ((selectedEvent = event) && (deleteDialog = true)) : null"
                                                        :class="( isMe(event) ? 'myMessage-content ' : ' ') + 'chat-message-content'"
                                                >
                                                    <!-- //TODO FIX ME
                                                    :class="( isMe(event) ? 'myMessage-content ' : (getGender(event) === 'm' ? 'male-chat ' : (getGender(event) === 'w' ? 'female-chat ' : ''))) + 'chat-message-content'"-->
                                                    <div class="pa-0" style="width: 100%">
                                                        <div v-if="event.getContent().body">
                                                            <!-- Nachricht ist eine Sprachnachricht -->
                                                            <div v-if="event.getContent().body.startsWith('$fileId:')">
                                                                <div class="d-flex">
                                                                    <div @keypress.enter="loadedAudios.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length > 0  ? playPause(event.getContent().body.replace('$fileId:','')) : loadFile(event.getContent().body.replace('$fileId:',''))" @click="loadedAudios.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length > 0  ? playPause(event.getContent().body.replace('$fileId:','')) : loadFile(event.getContent().body.replace('$fileId:',''))" style="line-height: 20px">
                                                                        <div class="d-flex align-center" style="min-height: 24px">
                                                                            <p tabindex="0" class="mb-0 mr-1">Sprachnachricht</p>
                                                                            <v-tooltip v-if="!loadedAudios.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length > 0" :disabled="!showTooltips" top>
                                                                                <template v-slot:activator="{ on }">
                                                                                    <img v-on="on" tabindex="0" role="button" :src="downloadIcon" alt="Sprachnachricht laden" :class="(!isMe(event) ? 'iconToWhite ' : '') + 'icon'">
                                                                                </template>
                                                                                <span>Laden</span>
                                                                            </v-tooltip>
                                                                            <v-tooltip v-else-if="audioSrc !== event.getContent().body.replace('$fileId:','')" :disabled="!showTooltips" top>
                                                                                <template v-slot:activator="{ on }">
                                                                                    <img v-on="on" tabindex="0" role="button" :src="lautsprecherIcon" alt="Sprachnachricht anhören" :class="(!isMe(event) ? 'iconToWhite ' : '') + 'icon'">
                                                                                </template>
                                                                                <span>Vorlesen</span>
                                                                            </v-tooltip>
                                                                            <img v-else tabindex="0" role="button" :src="lautsprecherAusIcon" alt="" :class="(!isMe(event) ? 'iconToWhite ' : '') + 'icon'">
                                                                        </div>
                                                                    </div>
                                                                    <v-spacer></v-spacer>
                                                                    <v-tooltip :disabled="!showTooltips" top>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn v-on="on" v-if="isMe(event) || account.role === 'teacher'" x-small class="pa-0" style="position: relative; float: right; width: 25px !important; min-width: 25px !important; height: 25px" @click="((isMe(event)  || account.role === 'teacher') && event.getContent().body) ? ((selectedEvent = event) && (deleteDialog = true)) : null" @keypress.enter="((isMe(event)  || account.role === 'teacher') && event.getContent().body) ? ((selectedEvent = event) && (deleteDialog = true)) : null">
                                                                                <img :src="papierkorbIcon" alt="Sprachnachricht löschen" height="20px">
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Löschen</span>
                                                                    </v-tooltip>
                                                                </div>
                                                            </div>
                                                            <!-- Nachricht ist eine Textnachricht -->
                                                            <div v-else style="display: inline-flex; width: 100%">
                                                                <div v-if="!isMe(event) && (event.getContent().body && !event.getContent().body.startsWith('$fileId:'))" style="position: absolute; top: 30px; right: -42px" :style="teacherPic!==null ? 'top: 58px !important' : ''">
                                                                    <v-tooltip :disabled="!showTooltips" top>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn :aria-hidden="person && person.accessibility && person.accessibility.screenreader" v-on="on" @click="greet(event.getContent().body, $event)" elevation="0" icon class="ml-2 pa-0 preventColorInvert" style="width: 40px; height: 40px">
                                                                                <img :src="isReading ? lautsprecherAusIcon : lautsprecherIcon" :alt="isReading ? 'Vorlesen stoppen' : 'Nachricht vorlesen'" style="height: 30px;"/>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Nachricht vorlesen</span>
                                                                    </v-tooltip>
                                                                </div>

                                                                <div v-else-if="(event.getContent().body && !event.getContent().body.startsWith('$fileId:'))" style="position: absolute; top: 30px; left: -50px" :style="teacherPic!==null ? 'top: 58px !important' : ''">
                                                                    <v-tooltip :disabled="!showTooltips" top>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn :aria-hidden="person && person.accessibility && person.accessibility.screenreader" v-on="on" @click="greet(event.getContent().body, $event)" elevation="0" icon class="ml-2 pa-0 preventColorInvert" style="width: 40px; height: 40px">
                                                                                <img :src="isReading ? lautsprecherAusIcon : lautsprecherIcon" :alt="isReading ? 'Vorlesen stoppen' : 'Nachricht vorlesen'" style="height: 30px;"/>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Nachricht vorlesen</span>
                                                                    </v-tooltip>
                                                                </div>
                                                                <!-- Nachricht -->
                                                                <p tabindex="0" v-html="urlify(event.getContent().body)" style="min-width: calc(100% - 25px); max-width: 225px; word-wrap: break-word" class="mb-0"></p>
                                                                <v-tooltip :disabled="!showTooltips" top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-btn v-on="on" v-if="isMe(event) || account.role === 'teacher'" x-small class="pa-0" style="position: relative; float: right; width: 25px !important; min-width: 25px !important; height: 25px">
                                                                            <img @click="((isMe(event)  || account.role === 'teacher') && event.getContent().body) ? ((selectedEvent = event) && (deleteDialog = true)) : null" :src="papierkorbIcon" height="20px" alt="Nachricht löschen">
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Löschen</span>
                                                                </v-tooltip>
                                                            </div>
                                                        </div>
                                                        <!-- Nachricht wurde gelöscht -->
                                                        <div v-else style="font-style: italic">
                                                            <p tabindex="0" class="mb-0" style="width: fit-content">
                                                                Diese Nachricht wurde gelöscht.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </v-row>

                                                <!--                                        <div v-if="!isMe(event) && (event.getContent().body && !event.getContent().body.startsWith('$fileId:'))" style="position: absolute; top: 38px; right: -40px">-->
                                                <!--                                            <v-btn @click="greet(event.getContent().body, $event)" elevation="0" icon class="ml-2 pa-0" style="width: 20px; height: 20px">-->
                                                <!--                                                <img :src="isReading ? lautsprecherAusIcon : lautsprecherIcon" :alt="isReading ? 'Lautsprecher Deaktiviert Symbol' : 'Lautsprechersymbol'" style="height: 30px;"/>-->
                                                <!--                                            </v-btn>-->
                                                <!--                                        </div>-->

                                                <!--                                        <div v-else-if="(event.getContent().body && !event.getContent().body.startsWith('$fileId:'))" style="position: absolute; top: 38px; left: -40px">-->
                                                <!--                                            <v-btn @click="greet(event.getContent().body, $event)" elevation="0" icon class="ml-2 pa-0" style="width: 20px; height: 20px">-->
                                                <!--                                                <img :src="isReading ? lautsprecherAusIcon : lautsprecherIcon" :alt="isReading ? 'Lautsprecher Deaktiviert Symbol' : 'Lautsprechersymbol'" style="height: 30px;"/>-->
                                                <!--                                            </v-btn>-->
                                                <!--                                        </div>-->
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <!-- Failure -->
<!--                                    <v-list>-->
<!--                        <v-list-item-group :key="index" v-for="(event, index) in events" class="mb-2">-->
<!--                            <div v-if="event.getType() === 'm.room.message'">-->
<!--                                <v-list-item tabindex="-1" :class="( isMe(event) ? 'myMessage ' : ' ') + 'chat-message'" :var="teacherPic = getTeacherPic(event)">-->
<!--                                    <v-row dense>-->
<!--                                        <v-col>-->
<!--                                            <v-row dense v-long-press="1000" @long-press-start="(isMe(event) && event.getContent().body)? ((selectedEvent = event) && (deleteDialog = true)) : null">-->
<!--                                                <v-col class="pa-0 greyText">-->
<!--                                                  <div style="display:inline-flex">-->
<!--                                                    &lt;!&ndash; For Teachers only, display avatar with Profile Picture &ndash;&gt;-->
<!--                                                    <v-avatar v-if="teacherPic!==null" class="mr-2" medium elevation="0" fab color="#6994cc">-->
<!--                                                        <img alt="LehrerProfil" :src="teacherPic ? teacherPic : lehrerIcon" :style="teacherPic ? 'height: 48px' : 'height: 48px'" :class="teacherPic ? 'roundImage' : 'iconToWhite'">-->
<!--                                                    </v-avatar>-->
<!--                                                    <p tabindex="0" class="mb-0" style="width: fit-content">{{ getUsername(event) }}</p>-->
<!--                                                  </div>-->
<!--                                                </v-col>-->
<!--                                                <v-col class="text-right greyText" style="font-size: smaller;">-->
<!--                                                    <p tabindex="0" class="mb-0" style="width: fit-content; float: right">-->
<!--                                                        {{new Date(getTime(event)).toLocaleDateString() + ' ' + new Date(getTime(event)).toLocaleTimeString().slice(0, new Date(getTime(event)).toLocaleTimeString().length - 3) }}-->
<!--                                                    </p>-->
<!--                                                </v-col>-->
<!--                                            </v-row>-->
<!--                                            <v-row-->
<!--                                                dense-->
<!--                                                v-long-press="1000" @long-press-start="(isMe(event) && event.getContent().body) ? ((selectedEvent = event) && (deleteDialog = true)) : null"-->
<!--                                                :class="( isMe(event) ? 'myMessage-content ' : ' ') + 'chat-message-content'"-->
<!--                                            >-->
<!--                                                &lt;!&ndash; //TODO FIX ME-->
<!--                                                :class="( isMe(event) ? 'myMessage-content ' : (getGender(event) === 'm' ? 'male-chat ' : (getGender(event) === 'w' ? 'female-chat ' : ''))) + 'chat-message-content'"&ndash;&gt;-->
<!--                                                <div class="pa-0" style="width: 100%">-->
<!--                                                    <div v-if="event.getContent().body">-->
<!--                                                        &lt;!&ndash; Nachricht ist eine Sprachnachricht &ndash;&gt;-->
<!--                                                        <div v-if="event.getContent().body.startsWith('$fileId:')">-->
<!--                                                            <div class="d-flex">-->
<!--                                                                <div @keypress.enter="loadedAudios.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length > 0  ? playPause(event.getContent().body.replace('$fileId:','')) : loadFile(event.getContent().body.replace('$fileId:',''))" @click="loadedAudios.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length > 0  ? playPause(event.getContent().body.replace('$fileId:','')) : loadFile(event.getContent().body.replace('$fileId:',''))" style="line-height: 20px">-->
<!--                                                                    <div class="d-flex align-center" style="min-height: 24px">-->
<!--                                                                        <p tabindex="0" class="mb-0 mr-1">Sprachnachricht</p>-->
<!--                                                                        <v-tooltip v-if="!loadedAudios.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length > 0" :disabled="!showTooltips" top>-->
<!--                                                                            <template v-slot:activator="{ on }">-->
<!--                                                                                <img v-on="on" tabindex="0" role="button" :src="downloadIcon" alt="Sprachnachricht laden" :class="(!isMe(event) ? 'iconToWhite ' : '') + 'icon'">-->
<!--                                                                            </template>-->
<!--                                                                            <span>Laden</span>-->
<!--                                                                        </v-tooltip>-->
<!--                                                                        <v-tooltip v-else-if="audioSrc !== event.getContent().body.replace('$fileId:','')" :disabled="!showTooltips" top>-->
<!--                                                                            <template v-slot:activator="{ on }">-->
<!--                                                                                <img v-on="on" tabindex="0" role="button" :src="lautsprecherIcon" alt="Sprachnachricht anhören" :class="(!isMe(event) ? 'iconToWhite ' : '') + 'icon'">-->
<!--                                                                            </template>-->
<!--                                                                            <span>Vorlesen</span>-->
<!--                                                                        </v-tooltip>-->
<!--                                                                        <img v-else tabindex="0" role="button" :src="lautsprecherAusIcon" alt="" :class="(!isMe(event) ? 'iconToWhite ' : '') + 'icon'">-->
<!--                                                                    </div>-->
<!--                                                                </div>-->
<!--                                                                <v-spacer></v-spacer>-->
<!--                                                                <v-tooltip :disabled="!showTooltips" top>-->
<!--                                                                    <template v-slot:activator="{ on }">-->
<!--                                                                        <v-btn v-on="on" v-if="isMe(event) || account.role === 'teacher'" x-small class="pa-0" style="position: relative; float: right; width: 25px !important; min-width: 25px !important; height: 25px" @click="((isMe(event)  || account.role === 'teacher') && event.getContent().body) ? ((selectedEvent = event) && (deleteDialog = true)) : null" @keypress.enter="((isMe(event)  || account.role === 'teacher') && event.getContent().body) ? ((selectedEvent = event) && (deleteDialog = true)) : null">-->
<!--                                                                            <img :src="papierkorbIcon" alt="Sprachnachricht löschen" height="20px">-->
<!--                                                                        </v-btn>-->
<!--                                                                    </template>-->
<!--                                                                    <span>Löschen</span>-->
<!--                                                                </v-tooltip>-->
<!--                                                            </div>-->
<!--                                                        </div>-->
<!--                                                        &lt;!&ndash; Nachricht ist eine Textnachricht &ndash;&gt;-->
<!--                                                        <div v-else style="display: inline-flex; width: 100%">-->
<!--                                                            <div v-if="!isMe(event) && (event.getContent().body && !event.getContent().body.startsWith('$fileId:'))" style="position: absolute; top: 30px; right: -30px" :style="teacherPic!==null ? 'top: 58px !important' : ''">-->
<!--                                                                <v-tooltip :disabled="!showTooltips" top>-->
<!--                                                                    <template v-slot:activator="{ on }">-->
<!--                                                                        <v-btn :aria-hidden="person && person.accessibility && person.accessibility.screenreader" v-on="on" @click="greet(event.getContent().body, $event)" elevation="0" icon class="ml-2 pa-0" style="width: 40px; height: 40px">-->
<!--                                                                            <img :src="isReading ? lautsprecherAusIcon : lautsprecherIcon" :alt="isReading ? 'Vorlesen stoppen' : 'Nachricht vorlesen'" style="height: 30px;"/>-->
<!--                                                                        </v-btn>-->
<!--                                                                    </template>-->
<!--                                                                    <span>Nachricht vorlesen</span>-->
<!--                                                                </v-tooltip>-->
<!--                                                            </div>-->

<!--                                                            <div v-else-if="(event.getContent().body && !event.getContent().body.startsWith('$fileId:'))" style="position: absolute; top: 30px; left: -40px" :style="teacherPic!==null ? 'top: 58px !important' : ''">-->
<!--                                                                <v-tooltip :disabled="!showTooltips" top>-->
<!--                                                                    <template v-slot:activator="{ on }">-->
<!--                                                                        <v-btn :aria-hidden="person && person.accessibility && person.accessibility.screenreader" v-on="on" @click="greet(event.getContent().body, $event)" elevation="0" icon class="ml-2 pa-0" style="width: 40px; height: 40px">-->
<!--                                                                            <img :src="isReading ? lautsprecherAusIcon : lautsprecherIcon" :alt="isReading ? 'Vorlesen stoppen' : 'Nachricht vorlesen'" style="height: 30px;"/>-->
<!--                                                                        </v-btn>-->
<!--                                                                    </template>-->
<!--                                                                    <span>Nachricht vorlesen</span>-->
<!--                                                                </v-tooltip>-->
<!--                                                            </div>-->
<!--                                                            &lt;!&ndash; Nachricht &ndash;&gt;-->
<!--                                                            <p tabindex="0" v-html="urlify(event.getContent().body)" style="min-width: calc(100% - 25px); max-width: 225px; word-wrap: break-word" class="mb-0"></p>-->
<!--                                                            <v-tooltip :disabled="!showTooltips" top>-->
<!--                                                                <template v-slot:activator="{ on }">-->
<!--                                                                    <v-btn v-on="on" v-if="isMe(event) || account.role === 'teacher'" x-small class="pa-0" style="position: relative; float: right; width: 25px !important; min-width: 25px !important; height: 25px">-->
<!--                                                                        <img @click="((isMe(event)  || account.role === 'teacher') && event.getContent().body) ? ((selectedEvent = event) && (deleteDialog = true)) : null" :src="papierkorbIcon" height="20px" alt="Nachricht löschen">-->
<!--                                                                    </v-btn>-->
<!--                                                                </template>-->
<!--                                                                <span>Löschen</span>-->
<!--                                                            </v-tooltip>-->
<!--                                                        </div>-->
<!--                                                    </div>-->
<!--                                                    &lt;!&ndash; Nachricht wurde gelöscht &ndash;&gt;-->
<!--                                                    <div v-else style="font-style: italic">-->
<!--                                                        <p tabindex="0" class="mb-0" style="width: fit-content">-->
<!--                                                            Diese Nachricht wurde gelöscht.-->
<!--                                                        </p>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </v-row>-->

<!--    &lt;!&ndash;                                        <div v-if="!isMe(event) && (event.getContent().body && !event.getContent().body.startsWith('$fileId:'))" style="position: absolute; top: 38px; right: -40px">&ndash;&gt;-->
<!--    &lt;!&ndash;                                            <v-btn @click="greet(event.getContent().body, $event)" elevation="0" icon class="ml-2 pa-0" style="width: 20px; height: 20px">&ndash;&gt;-->
<!--    &lt;!&ndash;                                                <img :src="isReading ? lautsprecherAusIcon : lautsprecherIcon" :alt="isReading ? 'Lautsprecher Deaktiviert Symbol' : 'Lautsprechersymbol'" style="height: 30px;"/>&ndash;&gt;-->
<!--    &lt;!&ndash;                                            </v-btn>&ndash;&gt;-->
<!--    &lt;!&ndash;                                        </div>&ndash;&gt;-->

<!--    &lt;!&ndash;                                        <div v-else-if="(event.getContent().body && !event.getContent().body.startsWith('$fileId:'))" style="position: absolute; top: 38px; left: -40px">&ndash;&gt;-->
<!--    &lt;!&ndash;                                            <v-btn @click="greet(event.getContent().body, $event)" elevation="0" icon class="ml-2 pa-0" style="width: 20px; height: 20px">&ndash;&gt;-->
<!--    &lt;!&ndash;                                                <img :src="isReading ? lautsprecherAusIcon : lautsprecherIcon" :alt="isReading ? 'Lautsprecher Deaktiviert Symbol' : 'Lautsprechersymbol'" style="height: 30px;"/>&ndash;&gt;-->
<!--    &lt;!&ndash;                                            </v-btn>&ndash;&gt;-->
<!--    &lt;!&ndash;                                        </div>&ndash;&gt;-->
<!--                                        </v-col>-->
<!--                                    </v-row>-->
<!--                                </v-list-item>-->
<!--                            </div>-->
<!--                        </v-list-item-group>-->
<!--                    </v-list>-->
                    </ScrollAreaMagnifier>
        </div>
        <div v-if="room" class="chat-send-wrapper align-center">
            <v-textarea id="chatTextArea"
                        label="Nachricht"
                        @keypress="textAreaChatChanged"
                        @keyup.enter="sendMessage"
                        hide-details
                        flat
                        solo
                        auto-grow
                        no-resize
                        v-model="inputMessage"
                        oncontextmenu="return false;"
                        placeholder="Nachricht eingeben..."
                        rows="1"
                        row-height="25"
                        style="max-height: 100px; overflow-x: hidden; overflow-y: auto;"
                        class="chat-send-input px-5 my-3"/>

            <div v-if="!inputMessage && audioCheck" oncontextmenu="return false;">
              <v-tooltip top :open-on-hover="false" v-model="recordingTooltip">
                <template v-slot:activator="{  attrs }">
                  <v-btn v-bind="attrs" fab icon v-long-press="500" @long-press-start="startRecording" @click="keyboard ? (toggle ? stopRecording() : startRecording()) : null"
                         @long-press-stop="stopRecording()" @keypress.enter="startRecording" @keyup.enter="stopRecording()" @keypress.space="startRecording" @keyup.space="stopRecording()" class="ma-0 recordBtn">
                    <div id="micIcon" :class="toggle ? 'iconToRecording' : ''"></div>
                  </v-btn>
                </template>
                <span> {{ recordingTime }}</span>
              </v-tooltip>
            </div>
            <v-tooltip v-else :disabled="!showTooltips" top>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" fab text @click="sendMessage" class="ma-0">
                        <img :src="sendenIcon" alt="Nachricht senden" height="30">
                    </v-btn>
                </template>
                <span>Senden</span>
            </v-tooltip>
        </div>
        <div v-if="!room">Keine Gruppe ausgewählt</div>


        <v-dialog overlay-opacity="0.8" v-model="deleteDialog" persistent max-width="400">
            <v-card>
                <v-card-title class="headline">
                    <p tabindex="0" class="mb-0">Nachricht löschen?</p>
                </v-card-title>
                <v-card-text>
                    <p tabindex="0" class="mb-0" style="width: fit-content">Wollen Sie diese wirklich Nachricht löschen?</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#bdbdbd" style="color: white" @click="deleteDialog = false">Abbrechen</v-btn>
                    <v-btn style="color: white" color="red red-1" @click="() => {this.deleteMessage(); this.deleteDialog = false}">Löschen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
//import * as sdk from "matrix-js-sdk";
import * as backend from "../../api/backend";
import vueCustomScrollbar from "vue-custom-scrollbar";
import AntiScroll from "../AntiScroll";

import lautsprecherIcon from '../../assets/Icons/lautsprecher-13.svg'
import lautsprecherAusIcon from '../../assets/Icons/lautsprecher-aus-89.svg'
import abbrechenIcon from '../../assets/Icons/abbrechen-08.svg'
import papierkorbIcon from '../../assets/Icons/papierkorb-109.svg'
import pfeilIcon from '../../assets/Icons/unten-dropdown-filled-12.svg'
import downloadIcon from '../../assets/Icons/download-116.svg'
import playIcon from '../../assets/Icons/Play-114.svg'
import lehrerIcon from '../../assets/Icons/lehrer-24.svg';
import pauseIcon from '../../assets/Icons/Pause-115.svg'
import mikrofonIcon from '../../assets/Icons/mikrofon-22.svg'
import sendenIcon from '../../assets/Icons/senden-23.svg'
import recordingDot from '../../assets/Icons/recording-dot.svg'

import {mapActions, mapState} from 'vuex';
import Recorder from 'js-audio-recorder';
import ScrollAreaMagnifier from "@/components/ScrollAreaMagnifier";

export default {
    name: "ChatWidget",
    components: {ScrollAreaMagnifier},
    data: () => ({
        subjectXmlHttpRequest: null,
        inputMessage: "",
        url: "http://localhost:8080/img/vollbild-09.a2073d91.svg",
        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: true
        },
        isReading: false,
        offsetHeightChat: 32,

        lautsprecherIcon,
        lautsprecherAusIcon,
        abbrechenIcon,
        papierkorbIcon,
        pfeilIcon,
        downloadIcon,
        lehrerIcon,
        playIcon,
        pauseIcon,
        mikrofonIcon,
        sendenIcon,
        recordingDot,

        recordingTime: '',
        elapsedTimeIntervalRef: '',
        recordingTooltip: false,
        selectedEvent: null,
        deleteDialog: false,
        toggle: false,
        recognition: null,
        showTextTimeout: null,
        audio: new Audio(),
        audioSrc: '',
        matrixUsers: [],
        maintainers: [],
        teachers: [],
        teacherPics: [],
        audioCheck: false,
        loadedAudios: [],
        recorder: new Recorder(),
        person: {
            accessibility: {
                screenreader: false,
            }
        },
    }),
    props: {
        showTooltips: {required: false, default: true},
        room: {required: true},
        matrixClient: {required: true},
        currentChild: {required: false, type: Object}
    },
    watch: {
        currentChild: async function() {
            if(this.account.role === 'parent') {
                const appointments = await this.getAppointmentsParent(this.currentChild._id);
                appointments.appointments.forEach((el) => {
                    el.teachers.forEach((element) => {
                        const index = this.matrixUsers.indexOf(element);
                        if (index === -1) {
                            this.matrixUsers.push(element);
                        }
                    })
                })
            }
        },
        room: function (newVal, oldVal) {
            // watch it
            /*console.log("Prop changed: ", newVal, " | was: ", oldVal);*/
        },
    },
    async created() {
        while (!this.$refs.scroll) {
            await this.sleep(500);
        }
        this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight;
    },

    async mounted() {
        await Promise.all([this.requestAppointments(), this.requestPupil()]);
        this.requestTeachers();
        this.requestMaintainers();
        this.checkPermission();
        this.acc = await this.getCurrentAccount();
        this.requestPerson();
    },
    computed: {
        ...mapState("util", ["keyboard"]),
        ...mapState("auth", ["account"]),
        ...mapState('translation', ['targetLang']),
        ...mapState("auth", [ "token", "account"]),
        ...mapState("magnifier", ["magnifier"]),
        events: function () {
            return this.room.timeline;
        },
    },
    methods: {
        ...mapActions('account', [ 'getCurrentAccount' ]),
        ...mapActions("matrix", ["removeMessage", "getMatrixName"]),
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation', 'translateToTargetLang' ]),
        ...mapActions("appointments", ["getAppointments", "getAppointmentsParent"]),
        ...mapActions("pupils", ["getPupils", "getMePupil"]),
        ...mapActions("teachers", ["getTeachers", "getProfilePicture"]),
        ...mapActions("parent", ["getParents"]),
        ...mapActions("maintainers", ["getMaintainers"]),

        async requestPerson() {
            if(this.acc.role === 'pupil') {
                this.person = await this.getMePupil();
            }
        },

        async setFocusOn(elementId) {
            await this.$nextTick();
            document.getElementById(elementId).focus();
        },

        textAreaChatChanged() {
            let el = document.getElementById("chatTextArea");
            this.offsetHeightChat = el.offsetHeight > 100 ? 100 : el.offsetHeight;
            console.log('Height: ', this.offsetHeightChat);

            // let chatBody = document.getElementById("chatBody");
            // chatBody.scrollTop = chatBody.scrollHeight;
        },

        async loadMore() {
            this.matrixClient.scrollback(
                this.room,
                1,
                (err, res) => {
                    console.log(err);
                });
        },
        urlify(text) {
            // eslint-disable-next-line no-useless-escape
            var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|software|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
            return text.toString().replace(urlRegex, function(url) {
                url = url.includes('http') ? url : 'http://' + url;
                return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
            })
            // or alternatively
            // return text.replace(urlRegex, '<a href="$1">$1</a>')
        },
        async requestAppointments() {
            if(this.account.role === 'pupil') {
                const appointments = await this.getAppointments();
                appointments.forEach((el) => {
                    el.teachers.forEach((element) => {
                        const index = this.matrixUsers.indexOf(element);
                        if (index === -1) {
                            this.matrixUsers.push(element);
                        }
                    })

                })
            }
            if(this.account.role === 'parent') {
                const appointments = await this.getAppointmentsParent(this.currentChild._id);
                appointments.appointments.forEach((el) => {
                    el.teachers.forEach((element) => {
                        const index = this.matrixUsers.indexOf(element);
                        if (index === -1) {
                            this.matrixUsers.push(element);
                        }
                    })
                })
            }
        },
        async requestPupil() {
            if(this.account.role === 'teacher') {
                this.matrixUsers = await this.getPupils();
                this.matrixUsers.forEach((el) => el.account = el.account._id);
                this.matrixUsers = this.matrixUsers.concat(await this.getTeachers());

                let parents = await this.getParents();
                parents.forEach((el) => {
                    el.account = el.account._id;
                    el.lastName = 'Eltern von'
                    el.name = this.matrixUsers.find((element) => element._id === el.pupils[0]).name;
                    }
                );
                this.matrixUsers = this.matrixUsers.concat(parents);
            }
        },
        async requestTeachers() {
            this.teachers = await this.getTeachers();
            if(this.account.role === 'maintainer') {
                this.matrixUsers = this.teachers;
            }
            //trim down the ridiculous size of duplicate matrixUsers, only keep unique entries
            this.matrixUsers = [
                ...new Set(this.matrixUsers.map((el) => JSON.stringify(el)))]
                .map((str) => JSON.parse(str));
            // console.log('Unique matrixUserList: ',this.matrixUsers);
            await this.setTeacherProfilePics();
        },
        async requestMaintainers() {
            if(this.account.role === 'teacher') {
                this.maintainers = await this.getMaintainers();
            }
        },
        async setTeacherProfilePics() {
            // console.log('teachers: ', this.teachers, 'matrixUsers: ', this.matrixUsers);
            // console.log('current room: ', this.room);
            this.teachers.map(async (teacher) => {
                let matchingTeacher = this.matrixUsers.find((matrixUser) => {
                    return teacher.account == matrixUser.account;
                });
                if(matchingTeacher){
                  const matchingTeacherMatrixName = await this.getMatrixName(matchingTeacher.account);
                  if(matchingTeacher.profilePicture) {
                      let profilePictureBlob = await this.getProfilePicture(matchingTeacher._id);
                      const objectURL = URL.createObjectURL(profilePictureBlob);
                      this.teacherPics.push([matchingTeacher.account, matchingTeacherMatrixName, objectURL]);
                  } else {
                    this.teacherPics.push([matchingTeacher.account, matchingTeacherMatrixName, false]);
                  }
                }
            });
        },
        getTeacherPic(event) {
          const matchingTeacherPic = this.teacherPics.find((teacherPic) => {
            return teacherPic[1] == event.sender.userId;
          });
          if(matchingTeacherPic){
            // console.log('returning matching Teacher Pic', matchingTeacherPic);
            return matchingTeacherPic[2];
          }
          // console.log('returned false for ',event.sender.userId ,'on event:', event.getContent());
          return null;
        },
        checkRoomName(name) {
            const id = name.replace(this.account._id, "").replace("private Room", "").replace("<", "").replace(">", "").replace(",", "").replace(" ", "");
            let secondUser = this.matrixUsers.find((el) => {
                return el.account === id;
            });
            if(secondUser) {
                return secondUser.lastName +  ' ' + secondUser.name;
            } else {
                if(this.account.role === 'teacher') {
                    secondUser = this.maintainers.find((el) => {
                        return el.account._id === id;
                    });
                    if(secondUser) {
                        return 'Verwaltung';
                    } else {
                        return 'Unbekannter Nutzer';
                    }
                }
                return 'Unbekannter Nutzer';
            }
        },
        checkPermission() {
            Recorder.getPermission().then(() => {
                this.audioCheck = true;
            }, (error) => {
                console.log(`${error.name} : ${error.message}`);
            });
        },
        async loadFile(file) {
            /*console.log('load')*/
            const res = await backend.getVoiceMessage(
                file
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    /*console.log(file)
                    console.log(blob)*/
                    let url = window.URL.createObjectURL(blob);
                    this.loadedAudios.push({'file' : file, 'url': url})

                });
        },

        playPause(file) {
            /*console.log(this.loadedAudios)*/
            if(this.audio) {
                this.audio.pause();
                this.audio = null;
                if(this.audioSrc === file) {
                    this.audioSrc = '';
                    return
                }
                this.audioSrc = '';
            }
            this.audio = new Audio(this.loadedAudios.find(el => el.file === file).url);
            this.audioSrc = file;
            this.audio.play();
            this.audio.addEventListener("ended", () => {
                this.audioSrc = '';
            });

        },

        startRecording() {
            if(!this.toggle) {
                this.startTimer();
                this.recordingTooltip = true;
                this.toggle = true;
                /*console.log("Start Recording");*/
                this.recorder.startRecord();
                /*console.log(this.recorder);*/
            }
        },
        stopRecording() {
            this.recordingTooltip = false;
            this.toggle = false;
            /*console.log("Stop Recording")*/
            this.recorder.stopRecord();
            this.handleRecording(this.recorder.getWAVBlob());
            setTimeout(() => {
              this.stopTimer();
            }, 500);
        },
        handleRecording(blob) {
            const formData = new FormData();
            formData.append("file", blob, new Date().toLocaleDateString().replaceAll(".", "_") + '.wav');

            this.XmlHttpRequest = backend.postVoiceMessage(
                blob
            );

            this.XmlHttpRequest.onerror = (e) => {
                console.error("Private upload error:", e);
                this.XmlHttpRequest = null;
            };

            this.XmlHttpRequest.onabort = (e) => {
                console.warn("Private upload aborted");
                this.XmlHttpRequest = null;
            };

            this.XmlHttpRequest.addEventListener("load", (e) => {
                if (this.XmlHttpRequest.status !== 201) {
                    console.error(
                        "Private upload failed:",
                        this.XmlHttpRequest.response
                    );
                }
                this.$emit('sendMessage', '$fileId:' + JSON.parse(this.XmlHttpRequest.response)._id);
                this.inputMessage = '';
                setTimeout(() => {
                    this.$refs.scrollAreaMagnifier.sendMessage();
                }, 500)
                this.XmlHttpRequest = null;
            });
            this.XmlHttpRequest.send(formData);
        },

        sleep(milliseconds) {
            return new Promise(resolve => setTimeout(resolve, milliseconds));
        },
        getTime(event) {
            let age = event.getUnsigned().age;
            // TODO: proper format/hide date if message was sent today and always show time
            return new Date(Date.now() - age).toString();
        },
        //name is sliced as it ends with the gender char. eg. Fritzm means name=Fritz, gender=male

        getUsername(event) {
            /*return event.sender.rawDisplayName.slice(0, event.sender.rawDisplayName.length-1);*/ //TODO and me too
            return event.sender.rawDisplayName;
        },
        //return only the gender char [m, w, d]
        getGender(event) {
            return event.sender.rawDisplayName.slice(event.sender.rawDisplayName.length-1, event.sender.rawDisplayName.length);
        },
        isMe(event) {
            return (this.matrixClient.credentials.userId === event.sender.userId);
        },
        getImage(event) {
            //console.log(event.getContent());
            let mxcurl = event.getContent().info.thumbnail_url;
            let w = event.getContent().info.w;
            let h = event.getContent().info.h;
            console.log(mxcurl);
            let imageuri = this.matrixClient.mxcUrlToHttp(mxcurl, w, h, "scale");
            console.log(imageuri);
            return imageuri;
        },
        getVideo(event) {
            /*console.log(event.getContent());*/
            let mxcurl = event.getContent().url;
            let split = mxcurl.split("/");
            let mediaId = split[split.length - 1];
            let size = event.getContent().info.size;
            let imageuri = `https://estundenplan-synapse-development.concept-hero-projects.de/_matrix/media/r0/download/estundenplan-synapse-development/${mediaId}?size=${size}`;
            //let imageuri = this.matrixClient.mxcUrlToHttp(mxcurl, 200, 200, "scale");
            /*console.log(imageuri);*/
            return imageuri;
        },
        sendMessage() {
            if ((this.inputMessage.trim())) {
                this.$emit('sendMessage', this.inputMessage);
                this.inputMessage = '';
                setTimeout(() => {
                    this.$refs.scrollAreaMagnifier.sendMessage();
                }, 500)
            }

        },
        async deleteMessage() {
            if(this.isMe(this.selectedEvent)) {
                this.$emit('deleteMessage', this.selectedEvent);
            } else {
                const params = {
                    eventId: this.selectedEvent.event.event_id,
                    roomId: this.room.roomId,
                    txnId: this.matrixClient.makeTxnId(),
                }
                this.removeMessage(params);

            }

            if(this.selectedEvent.getContent().body.startsWith('$fileId:')) {
                const res = await backend.deleteVoiceMessage(this.selectedEvent.getContent().body.replace('$fileId:',''));
            }
        },
        updateRoom() {
            /*console.log(this.room.timeline);*/
        },
        clickBack() {
            this.$emit("exitRoom");
        },

        async greet(message, event) {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
                return
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel()
                event.target.src = lautsprecherIcon;
            } else {
                if (this.targetLang !== 'de') {
                    message = await this.translateToTargetLang({
                        targetLang: this.targetLang,
                        textToTranslate: message
                    });

                    this.setTranslatedText(message);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000)
                }

                event.target.src = lautsprecherAusIcon;
                let msg = new SpeechSynthesisUtterance();
                msg.text = message;
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                } else {
                    event.target.src = lautsprecherIcon;
                }

                msg.onend = () => {
                    event.target.src = lautsprecherIcon;
                }
            }
        },
        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },
        startTimer() {
          var startTime = new Date();
          this.recordingTime = this.getElapsedTime(startTime);
          this.elapsedTimeIntervalRef = setInterval(() => {
            this.recordingTime = this.getElapsedTime(startTime);
          }, 1000);
        },
        stopTimer() {
          if (typeof this.elapsedTimeIntervalRef !== "undefined") {
            clearInterval(this.elapsedTimeIntervalRef);
            this.elapsedTimeIntervalRef = undefined;
          }
          this.recordingTime = '';
        },
        getElapsedTime(startTime) {
          // Record end time
          let endTime = new Date();
          // Compute time difference in milliseconds
          let timeDiff = endTime.getTime() - startTime.getTime();
          // Convert time difference from milliseconds to seconds
          timeDiff = timeDiff / 1000;
          // Extract integer seconds that dont form a minute using %
          let seconds = Math.floor(timeDiff % 60); //ignoring uncomplete seconds (floor)
          // Pad seconds with a zero if neccessary
          let secondsAsString = seconds < 10 ? "0" + seconds : seconds + "";
          // Convert time difference from seconds to minutes using %
          timeDiff = Math.floor(timeDiff / 60);
          // Extract integer minutes that don't form an hour using %
          let minutes = timeDiff % 60; //no need to floor possible incomplete minutes, becase they've been handled as seconds
          // Pad minutes with a zero if neccessary
          let minutesAsString = minutes < 10 ? "0" + minutes : minutes + "";
          // Convert time difference from minutes to hours
          timeDiff = Math.floor(timeDiff / 60);
          // Extract integer hours that don't form a day using %
          let hours = timeDiff % 24; //no need to floor possible incomplete hours, becase they've been handled as seconds
          // Convert time difference from hours to days
          timeDiff = Math.floor(timeDiff / 24);
          // The rest of timeDiff is number of days
          let days = timeDiff;
          let totalHours = hours + (days * 24); // add days to hours
          let totalHoursAsString = totalHours < 10 ? "0" + totalHours : totalHours + "";
          if (totalHoursAsString === "00") {
            return minutesAsString + ":" + secondsAsString;
          } else {
            return totalHoursAsString + ":" + minutesAsString + ":" + secondsAsString;
          }
        },
        getAccessibilityTime(time) {
            const charArray = time.split('');

            let spelledOutTime = '';
            if(charArray[0] !== '0') {
                spelledOutTime = spelledOutTime + charArray[0];
            }
            spelledOutTime = spelledOutTime + charArray[1];
            spelledOutTime = spelledOutTime + ' Uhr ';
            if(charArray[3] !== '0') {
                spelledOutTime = spelledOutTime + charArray[3];
            }
            spelledOutTime = spelledOutTime + charArray[4];
            return spelledOutTime;
        }
    },
};
</script>

<style lang="scss" scoped>
.chat-send-input {
    background-color: white;
    border-radius: 50px;
    width: 100%;
}

.chat-send-wrapper {
    display: inline-flex;
    background-color: #f2f2f2;
    padding: 0 12px;
    bottom: 0;
    left: 0;
    right: 0;
}

.group-chat-wrapper {
    flex: auto;
    max-height: calc(80vh - 55px);
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: white !important;
    border-radius: 0 0 15px 15px;
}

.chat-body {
    // position: fixed;
    // overflow-y: auto;
    flex: auto;
    //overflow-y: auto;
    //overflow-x: hidden;
  overflow: hidden;
}

.group-chat-header-btn {
    padding: 0 !important;
    min-width: 0 !important;
}

.group-chat-header-text {
    color: white;
}

.group-chat-header {
    background-color: #95c94b; // 3caa68 - 5% Luminosity
}

.chat-header-btn {
    background-color: white !important;
    width: 35px;
    height: 35px !important;
}

.chat-header-img {
    height: 20px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 176px);
    height: calc(80vh - 179px);
    overflow: hidden;
    margin-bottom: 2px;
}

.scroll-area-disabled {
  position: relative;
  margin: auto;
  max-height: calc(80vh - 176px);
  height: calc(80vh - 179px);
  overflow: hidden;
  margin-bottom: 2px;
}

.chat-message {
    position: relative;
    border-radius: 5px;
    max-width: 75%;
    margin: 2px 2px 2px 19px;
}

.myMessage {
    margin-left: auto !important;
    margin-right: 19px !important;
}

/*removes darker background after element is clicked
also works without the specific class in front*/
.myMessage:before {
    opacity: 0 !important;
}
.chat-message:before {
    opacity: 0 !important;
}

.chat-message-content {
    border-radius: 0 5px 5px 5px;
    box-shadow: 1px 2px 3px silver;
    background-color: #505faa;
    /*background-color: #9b91c8;*/ //use me when using chat gender colors again
    color: white;
    padding: 9px;
}
.male-chat {
    background-color: #505faa !important;
}
.female-chat {
    background-color: #e6415a !important;
}

.myMessage-content {
    border-radius: 5px 5px 0 5px !important;
    background-color: #dbdbdb;
    color: black;
}

.progressbar {
    border-radius: 5px;
}

.icon {
    height: 20px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.greyText{
    color: #707070;
}

.iconToRecording {
    background-image: url('../../assets/Icons/recording-dot.svg') !important;
}

#micIcon {
    background-image: url('../../assets/Icons/mikrofon-22.svg');
    width: 30px;
    height: 30px;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
h2 {
  display: inherit;
  font-size: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
  margin-left: inherit;
  margin-right: inherit;
  font-weight: inherit;
}

/* width */
::-webkit-scrollbar {
  width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 6px;
  width: 7px;
}
::-webkit-scrollbar-thumb:hover {
  background: #999;
  border-radius: 6px;
  width: 11px;
}
</style>
