/**
 * Choose a random index from a array
 * Optionally exclude indexes from selection.
 */
const generateRandomIndex = (arrayLength, exludedIndexes = []) => {
  let randomIndex = Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * arrayLength);
  while (exludedIndexes.find(index => index === arrayLength  )) {
    randomIndex = Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * arrayLength);
  }

  return randomIndex;
};

// https://stackoverflow.com/a/1431113
const replaceAt = (stringToManpulate, index, replacingWord) => {
  return stringToManpulate.substring(0, index) + replacingWord + stringToManpulate.substring(index + replacingWord.length);
}

// https://stackoverflow.com/a/51540480
const generateRandomPassword = ({ passwordLength = 8 }) => {
  if (passwordLength <= 2) {
    throw new Error("Password length cannot be less or equal than 2");
  }

  // Create random password
  const randomPassword = Array(passwordLength)
  .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
  .map((x) => 
    x[Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * x.length)]
  )
  .join('');

  // Esure that password has at least one number and one char
  const numbers = "0123456789";
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"
  const randomLetter = letters[Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * letters.length)];
  const randomNumber = numbers[Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * numbers.length)];
  const randomLetterIndex = generateRandomIndex(randomPassword.length, []);
  const randomNumberIndex = generateRandomIndex(randomPassword.length, [randomLetterIndex]);
  const passwordWithRandomLetter = replaceAt(randomPassword, randomLetterIndex, randomLetter);
  const passwordWithRandomNumber = replaceAt(passwordWithRandomLetter, randomNumberIndex, randomNumber);

  return passwordWithRandomNumber;
}

export default generateRandomPassword;
