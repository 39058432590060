<template>
    <div>
        <v-menu :close-on-content-click="false" v-model="menuOpen" min-width="400px"  nudge-right="100">
            <template v-slot:activator="{ on, attrs }">
                <v-btn x-small class="toolBtn text-capitalize mr-4" color="grey"
                       elevation="3" style="opacity: 80%" v-bind="attrs" v-on="on">
                    <img :src="plusIcon" style="height: 40px; filter: brightness(1000%); justify-self: flex-end"/>
                </v-btn>
            </template>
            <v-card>
                <v-card-title>Hinzufügen</v-card-title>
                <v-card-text>
                    <v-container v-if="menuState === 'menu'">
                        <v-row dense>
                            <v-col>
                                <v-btn outlined class="text-capitalize mr-4" @click="spawnAnswer" block style="opacity: 80%">
                                    <p class="mb-0" style="font-size: large">Antwortfeld</p>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <v-btn outlined class="text-capitalize mr-4" @click="spawnTask" block style="opacity: 80%">
                                    <p class="mb-0" style="font-size: large">Aufgabe/Text/Links</p>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <v-btn outlined class="text-capitalize mr-4" @click="$refs.imgUpload.click()" block style="opacity: 80%">
                                    <p class="mb-0" style="font-size: large">Bild</p>
                                    <input
                                        type="file"
                                        ref="imgUpload"
                                        style="display: none"
                                        hidden
                                        name="file" accept="image/*"
                                        @change="imgSelected"
                                    >
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <v-btn outlined class="text-capitalize mr-4" @click="initMultipleChoice" block style="opacity: 80%">
                                    <p class="mb-0" style="font-size: large">Multiple Choice</p>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <v-btn outlined class="text-capitalize mr-4" block
                                       @click="switchMenuState('text')" style="opacity: 80%">
                                    <p class="mb-0" style="font-size: large">Notiz</p>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <v-btn outlined class="text-capitalize mr-4" @click="menuState = 'rectangle'" block style="opacity: 80%">
                                    <p class="mb-0" style="font-size: large">Rechteck</p>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <v-btn outlined class="text-capitalize mr-4" @click="menuState = 'moveableText'" block style="opacity: 80%">
                                    <p class="mb-0" style="font-size: large">Verschiebbaren Text</p>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <v-btn outlined class="text-capitalize mr-4" @click="$refs.videoUpload.click()" block style="opacity: 80%">
                                    <p class="mb-0" style="font-size: large">Video</p>
                                    <input
                                        type="file"
                                        ref="videoUpload"
                                        style="display: none"
                                        hidden
                                        name="file" accept="video/mp4,video/mpeg,video/mov,video/*"
                                        @change="videoSelected"
                                    >
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider class="my-1" />
                        <v-row dense>
                            <v-col>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            class="text-capitalize mr-4"
                                            block
                                            style="opacity: 80%">
                                            <v-spacer />
                                            <p class="mb-0 ml-5" style="font-size: large">Weitere Seite</p>
                                            <v-spacer />
                                            <img :src="arrowDown" class="mr-2"
                                                 style="height: 20px;"/>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(item, index) in additionalPagesDropdown"
                                            :key="index"
                                            @click="() => addAdditionalPageClick(item)"
                                        >
                                            <v-list-item-title>
                                                <img :src="item.img" class="mr-2"
                                                     style="height: 20px;"/>
                                                {{ item.title }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-container>

                    <!-- Text Message Container -->
                    <v-container v-else-if="menuState === 'text'">
                        <v-row>
                            <v-col>
                                <h3>Notiz einfügen</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea outlined label="Dein Text" v-model="textAreaValue" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn @click="spawnText" color="success">Platzieren</v-btn>
                                <v-btn @click="abort" color="error" class="ml-1">Abbrechen</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container v-else-if="menuState === 'moveableText'">
                        <v-row>
                            <v-col>
                                <h3>Verschiebbaren Text einfügen</h3>
                            </v-col>
                        </v-row>
                        <v-row class="mt-1">
                            <v-col class="pb-1">
                                <v-select
                                    label="Text Größe"
                                    hide-details
                                    outlined
                                    dense
                                    v-model="multipleChoiceSelectedSize"
                                    :items="multipleChoiceSizes" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field hide-details outlined label="Text..." v-model="textAreaValue" />
                            </v-col>
                        </v-row>
                        <div class="text-caption">
                            Platzieren Sie einen Text, den die Schüler und Schülerinnen frei bewegen können.
                        </div>
                        <v-row>
                            <v-col>
                                <v-btn @click="spawnMoveableText" color="success">Platzieren</v-btn>
                                <v-btn @click="abort" color="error" class="ml-1">Abbrechen</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container v-else-if="menuState === 'rectangle'">
                        <v-row>
                            <v-col>
                                <h3>Rechteck einfügen</h3>
                            </v-col>
                        </v-row>
                        <div class="text-body-2">
                            Wählen Sie die Farbe des Rechtecks aus:
                        </div>
                        <v-row class="mt-1 mb-0 pb-0" dense>
                            <v-col class="">
                                <v-color-picker
                                    class="ma-2"
                                    id="cPicker"
                                    :swatches="swatches"
                                    v-model="selectedColor"
                                    hide-mode-switch
                                    hide-canvas
                                    hide-inputs
                                    show-swatches
                                ></v-color-picker>
                            </v-col>
                        </v-row>
                        <v-row align="center" dense>
                            <v-col class="" cols="auto">
                                <v-checkbox v-model="rectangleBorderState"></v-checkbox>
                            </v-col>
                            <v-col class="">
                                <div>
                                    Rechteck mit Rahmen
                                </div>
                            </v-col>
                        </v-row>
                        <div class="text-caption">
                            Platzieren Sie ein Rechteck auf dem Arbeitsblatt.
                        </div>
                        <v-row>
                            <v-col>
                                <v-btn @click="spawnRectangle" color="success">Platzieren</v-btn>
                                <v-btn @click="abort" color="error" class="ml-1">Abbrechen</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container v-else-if="menuState === 'multipleChoice'" class="pt-0">
                        <v-row no-gutters>
                            <v-col>
                                <h3>Multiple Choice einfügen</h3>
                            </v-col>
                        </v-row>
                        <div class="caption">Möchten Sie nur eine Checkbox einfügen, lassen Sie die Felder einfach leer.</div>
                        <v-row class="mt-1">
                            <v-col class="pb-1">
                                <v-select
                                    label="Elementgröße"
                                    hide-details
                                    outlined
                                    dense
                                    v-model="multipleChoiceSelectedSize"
                                    :items="multipleChoiceSizes" />
                            </v-col>
                        </v-row>
                        <v-row class="">
                            <v-col>
                                <v-text-field hide-details label="Frage" outlined v-model="mQuestionTitle" ></v-text-field>
                            </v-col>
                        </v-row>
                        <div
                            style="max-height: 35vh; overflow-y: auto; overflow-x: hidden">
                            <div
                                v-for="(txt, i) in mQuestions"
                                :key="'question'+i"
                            >
                                <v-row dense class="" align="center">
                                    <v-col cols="auto">
                                        <v-checkbox v-model="txt.correct"></v-checkbox>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :color="txt.correct ? 'green' : 'error'" hide-details :label="`Antwort ${i + 1}`" outlined v-model="txt.text" ></v-text-field>
                                    </v-col>
                                    <v-col cols="auto" @click="mQuestions.splice(i, 1)" v-if="mQuestions.length > 1">
                                        <v-btn icon>
                                            <v-icon color="red">fas fa-minus</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                        <div class="d-flex justify-end">
                            <v-btn icon right @click="mQuestions.push({ text: '' , correct: false, checked: false })">
                                <v-icon color="green">fas fa-plus</v-icon>
                            </v-btn>
                        </div>
                        <v-row>
                            <v-col>
                                <v-btn @click="spawnMultipleChoice" color="success">Platzieren</v-btn>
                                <v-btn @click="abort" color="error" class="ml-1">Abbrechen</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import plusIcon from '../../../assets/Icons/hinzufuegen-07.svg'
import mikrofonIcon from '../../../assets/Icons/mikrofon-22.svg'
import leeresBlatt from "@/assets/Icons/leeres-blatt-110.svg";
import kariertesBlatt from "@/assets/Icons/kariertes blatt-112.svg";
import liniertesBlatt from "@/assets/Icons/liniertes-blatt-111.svg";
import arrowDown from "@/assets/Icons/unten-dropdown-12.svg";

export default {
    name: 'EditorTools',
    data() {
        return {
            // Icons
            plusIcon,
            mikrofonIcon,
            // Logic
            menuState: 'menu',
            textAreaValue: '',
            menuOpen: false,
            mQuestionTitle: '',
            mQuestions: [],
            leeresBlatt,
            kariertesBlatt,
            liniertesBlatt,
            arrowDown,
            selectedColor: '#FFFFFF',
            swatches: [
                ['#FFFFFF', '#b2b2b2', '#707070'],
                ['#000000', '#1b5f99', '#9bcdc3'],
                ['#fcd20a', '#f0b423', '#ff6941'],
                ['#91c364', '#91c887', '#d7875f'],
                ['#9b91c8', '#ebaabe', '#6ec3dc'],

            ],
            additionalPagesDropdown: [ { title: 'Leere Seite', value: 'leer', img: leeresBlatt }, { title: 'Karierte Seite', value: 'kariert', img: kariertesBlatt }, { title: 'Linierte Seite', value: 'liniert', img: liniertesBlatt }, { title: 'Hauslinien Seite', value: 'haus', img: liniertesBlatt }],
            multipleChoiceSizes: [
                { value: 0, text: 'Normal'},
                { value: 1, text: 'Groß'},
                { value: 2, text: 'Extra groß'},
            ],
            multipleChoiceSelectedSize: 0,
            rectangleBorderState: true,
        };
    },
    methods: {
        /** Menu State Switch */
        switchMenuState(newState) {
            this.menuState = newState;
        },
        abort() {
            this.textAreaValue = '';
            this.switchMenuState('menu');
        },

        /** Event Emitters */
        spawnText() {
            console.log('spawnText with ', this.textAreaValue);
            this.$emit('addTextMessage', this.textAreaValue);

            this.textAreaValue = '';
            this.switchMenuState('menu');
        },
        spawnTask() {
            console.log('spawnTask called');
            this.$emit('addTextTask');
            this.menuOpen = false;
        },
        spawnAnswer() {
            console.log('spawnAnswer called');
            this.$emit('addAnswerField');
            this.menuOpen = false;
        },
        spawnMoveableText() {
            console.log("spawnMoveableText called");
            if(this.textAreaValue.toString().trim().length <= 0)
                return;
            this.$emit('addMoveableText', this.textAreaValue, this.multipleChoiceSelectedSize);
            this.textAreaValue = '';
            this.menuOpen = false;
            this.switchMenuState('menu');
        },
        spawnRectangle() {
            this.$emit('addRectangle', this.selectedColor, (this.rectangleBorderState ? '1px solid black': ''));
            this.switchMenuState('menu');
            this.menuOpen = false;
        },
        imgSelected() {
            let file = this.$refs.imgUpload.files[0];
            let url = window.URL.createObjectURL(file);
            this.$emit('addImageField', url, file);
            this.$refs.imgUpload.value = "";
            this.menuOpen = false;
        },
        videoSelected() {
            if(this.$refs.videoUpload.files.length > 0){
                let file = this.$refs.videoUpload.files[0];
                let url = window.URL.createObjectURL(file);
                this.$emit('addVideoField', url, file);
                this.menuOpen = false;
                this.$refs.videoUpload.value = "";
            }
        },
        initMultipleChoice() {
            this.menuState = 'multipleChoice';
            this.mQuestionTitle = "";
            this.mQuestions = [{ text: '' , correct: false, checked: false }];
        },
        spawnMultipleChoice() {
            // Removed the filter, because some teachers want empty boxes
            let data = this.mQuestions/*.filter(value => value.text.trim().length > 0)*/;
            console.log("M Choices", this.mQuestions.length);
            if(data.length > 0) {
                this.$emit('addMultipleChoiceField', this.mQuestionTitle, data, this.multipleChoiceSelectedSize);
                this.menuState = 'menu';
                this.menuOpen = false;
            }
        },
        addAdditionalPageClick(item) {
            this.$emit('addAdditionalPage', item.value);
            this.menuOpen = false;
        }
    }
}
</script>

<style lang="scss">
.toolBtn {
    background-color: #555555;
    height: 49px !important;

    @media (max-width: 380px) {
        height: 40px !important;
        margin-right: 0 !important;

        p {
            display: none;
        }
    }
}

#cPicker {
    .v-color-picker__controls{
        .v-color-picker__preview--hide-alpha{
            .v-color-picker__dot{
                border-radius: 0% !important;
            }
            .v-color-picker__dot{
                border: 1px solid gray;
                -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.4);
                box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.4);
            }
            .v-color-picker__sliders{
                display: none !important;
            }
        }
    }
}
</style>
