import { createLogger, Store } from "vuex";

// gamepad === navigator.getGamepads()[gamepad.index]
const addConnectecEventListener = () => {
  window.addEventListener("gamepadconnected", (e) => {
    console.log(
      "Gamepad connected at index %d: %s. %d buttons, %d axes.",
      e.gamepad.index,
      e.gamepad.id,
      e.gamepad.buttons.length,
      e.gamepad.axes.length
    );
  });
};

const addDisonnectecEventListener = () => {
  window.addEventListener("gamepaddisconnected", (e) => {
    console.log(
      "Gamepad disconnected from index %d: %s",
      e.gamepad.index,
      e.gamepad.id
    );
  });
};

const defaultState = {
  gamepadAxes: [],
  gamepadButtons: [],
};

const mutations = {
  setGamepadAxes(state, gamepadAxes) {
    state.gamepadAxes = gamepadAxes;
  },
  setGamepadButtons(state, gamepadButtons) {
    state.gamepadButtons = gamepadButtons;
  },
};

const getters = {
  horizontalAxisPosition: state => state.gamepadAxes[0],
  verticalAxisPosition: state => state.gamepadAxes[1],
  aButtonPressed: state => state.gamepadButtons[0],
  bButtonPressed: state => state.gamepadButtons[1],
  xButtonPressed: state => state.gamepadButtons[2],
  yButtonPressed: state => state.gamepadButtons[3],
  l1ButtonPressed: state => state.gamepadButton[4],
  l2ButtonPressed: state => state.gamepadButton[6],
  r1ButtonPressed: state => state.gamepadButton[5],
  r2ButtonPressed: state => state.gamepadButton[7],
}

const actions = {
 initGamepadApi({commit, state }) {
  addConnectecEventListener();
  addDisonnectecEventListener();
  window.setInterval(() => {
    const gamepads = navigator.getGamepads();
    if (gamepads[0]) {
      const mappedButtons = gamepads[0].buttons.map(button => button.pressed);
      const axesEqual = state.gamepadAxes.every(((axis, index) => gamepads[0].axes[index] === axis));
      const buttonsEqual = state.gamepadButtons.every(((buttonPressed, index) => mappedButtons[index] === buttonPressed));
      if(!axesEqual || !state.gamepadAxes.length) {
        commit('setGamepadAxes',gamepads[0].axes);
      }
      if(buttonsEqual) {
        commit('setGamepadButtons',gamepads[0].buttons.map(button => button.pressed));
      }
      // ALT+S keycode 83
      if(gamepads[0].buttons[0].pressed){
        dispatchEvent(new KeyboardEvent('keydown',{keyCode: 83, altKey: true}));
        return;
      }
      // ALT+H keycode 72
      if(gamepads[0].buttons[1].pressed){
        dispatchEvent(new KeyboardEvent('keydown',{keyCode: 72, altKey: true}));
        return;
      }
      // ALT+B keycode 66
      if(gamepads[0].buttons[2].pressed){
        dispatchEvent(new KeyboardEvent('keydown',{keyCode: 66, altKey: true}));
        return;
      }
      // ALT+C keycode 67
      if(gamepads[0].buttons[3].pressed){
        dispatchEvent(new Event('keypress',{keyCode: 67, altKey: true, bubbles: true}));
        // dispatchEvent(new KeyboardEvent('keyup',{keyCode: 67, altKey: true}));
        console.log('pressed ALT+C');
        return;
      }
      // 0 keycode 48
      if(gamepads[0].buttons[4].pressed){
        dispatchEvent(new KeyboardEvent('keydown',{keyCode: 48}));
        return;
      }
      // ALT+T keycode 84
      if(gamepads[0].buttons[6].pressed){
        dispatchEvent(new KeyboardEvent('keydown',{keyCode: 84, altKey: true}));
        return;
      }
      // 1 keycode 49
      if(gamepads[0].buttons[5].pressed){
        dispatchEvent(new KeyboardEvent('keydown',{keyCode: 49}));
        return;
      }
      // Tab keycode 9
      if(gamepads[0].buttons[7].pressed){
        dispatchEvent(new KeyboardEvent('keydown',{keyCode: 9}));
        return;
      }
    } else {
      if(state.gamepadAxes.length !== 0) {
        commit('setGamepadAxes',[]);
      }
      if(state.gamepadButtons.length !== 0) {
        commit('setGamepadButtons',[]);
      }
    }
  }, 100)
 },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
