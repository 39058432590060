<template>
  <div class="text-center">
    <v-bottom-sheet
        v-model="notCookiesAccepted"
        v-click-outside="acceptCookies"
        inset
        persistent
    >
      <v-card>
        <v-card-title>
          Datenschutzeinstellungen
        </v-card-title>
        <v-card-text class="mt-3">
          Wir nutzen auf unserer Website Essentielle Cookies und Cookies von Drittanbietern. Diese Cookies dienen dazu Ihnen eine möglichst angenehme Nutzung zu ermöglichen.
          <br> <br> Durch klicken auf Akzeptieren, oder die weitere Nutzung unserer Website erklären Sie sich einverstanden Cookies speichern zu lassen.
        </v-card-text>
        <v-card-actions class="mx-3">
          <v-btn
              outlined
              large
              @click="acceptCookies"
              class="acceptBtn text-capitalize"
              id="acceptCookies"
          >
            Akzeptieren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import {checkCookieAccepted, getCookie, setCookie} from '@/util/cookies';

export default {
  name: "CookieLaw",
  data() {
    return {
      notCookiesAccepted: true,
  }},
  methods: {
    async checkCookies(){
      console.log("Cookie state: ", this.notCookiesAccepted);
      this.notCookiesAccepted = !checkCookieAccepted();
      console.log("Cookie state: ", this.notCookiesAccepted);
    },
    async acceptCookies(){
      setCookie("eklara_cookielaw_accepted",true,365)
      // setCookie("testCookie",true,365)
      this.notCookiesAccepted = false;
    },
  },
  mounted() {
    this.checkCookies("eklara_cookielaw_accepted");
  }
}
</script>

<style scoped>

.acceptBtn {
  color: white;
  background-color: #8cbd46;
  width: 25%;
}

@media only screen and (max-width: 500px) {

  .acceptBtn {
    width: 210px;
  }

}

</style>