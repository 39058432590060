<template>
    <div>
        <v-row class="mx-0 mt-3 mb-4" >
            <div style="height: auto; width: 90%; margin: auto; border-radius: 15px; box-shadow: 1px 5px 5px silver;">
                <div style=" background-color: #6995CD; border-radius: 15px 15px 0 0; font-size: x-large; padding-top: 20px; padding-bottom: 13px; text-align: center; color: white;">
                    <div style="display: flex; width: 50%">
<!--                        <img :src="suchIcon" style="height: 24px; margin-left: 24px; margin-top: 20px;-->
<!--                        filter: brightness(0) saturate(100%) invert(100%) sepia(5%) saturate(19%) hue-rotate(65deg) brightness(104%) contrast(105%);"/>-->
<!--                        <v-text-field-->
<!--                                v-model="search"-->
<!--                                label="Schulzeit suchen"-->
<!--                                single-line-->
<!--                                dark-->
<!--                                hide-details-->
<!--                                style="width: 50%"-->
<!--                                class="pl-6"-->
<!--                        ></v-text-field>-->
                        <div class="mt-1" style="margin-left: 3%">
                            <p class="ma-0" style="display: inline-block;">Angelegte Schulzeiten</p>
                        </div>
<!--                        Angelegte Schulzeiten-->
                    </div>

                </div>
                <div style="display: flex; font-size: larger; font-family: Roboto; margin-top: 24px; margin-left: 26px" class="">
                    <img :src="schulStundenIcon" style="height: 24px; margin-right: 10px"/>
                    Schulzeiten
                </div>
                <v-data-table
                        :headers="headers"
                        :items="slots"
                        :search="search"
                        sort-by="start"
                        disable-pagination
                        hide-default-footer
                        fixed-header
                        :height="height"
                        class="ma-3"
                        locale="de-DE"
                        no-data-text="Keine Daten verfügbar"
                        no-results-text="Keine übereinstimmenden Aufzeichnungen gefunden"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td>{{("0" + new Date(row.item.start).getHours()).slice(-2) + ":" + ("0" + new Date(row.item.start).getMinutes()).slice(-2)}}</td>
                            <td>{{("0" + new Date(row.item.end).getHours()).slice(-2) + ":" + ("0" + new Date(row.item.end).getMinutes()).slice(-2)}}</td>
                            <td>
                                <v-btn class="mx-2" @click="edit(row.item)" color="#EAEAEA" style="border-radius: 5px; text-transform: inherit" elevation="1" small light>
                                    Slot bearbeiten
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <v-overlay absolute :value="showLoadingOverlay"
                           style="width: 100% !important;">
                    <v-progress-circular v-if="showLoadingOverlay" indeterminate size="100"/>
                </v-overlay>
            </div>

        </v-row>

        <!-- Zeitslots anlegen / bearbeiten -->
        <v-row class="mx-0 my-3" id="edit" >
            <div class="editContainer" style="">
<!--                <div style="background-color: #6995CD; border-radius: 15px 15px 0 0; font-size: larger; padding-top: 10px; padding-bottom: 5px; ">-->
<!--                    <div style="color: white; font-size: larger" class="ma-2 ml-6 ">-->
<!--                        <img class="ml-2" style="width: 24px; color: white" :src="bearbeitenIcon"/> Schulzeiten {{ oldSlot ? 'bearbeiten' : 'anlegen'}}-->
<!--                    </div>-->
<!--                </div>-->
                <v-row class="mx-0" style="background-color: #6995CD; border-radius: 15px 15px 0 0">

                    <v-col style="color: white; font-size: larger;" class="ma-1 ml-5">
                        <v-avatar style="width: 24px; " color="rgba(255,255,255,0.8)">
                            <img :src="schulStundenIcon"  class=""/>
                        </v-avatar>

                        Schulzeiten {{ oldSlot ? 'bearbeiten' : 'anlegen'}}
                    </v-col>
                    <v-col class="mr-6" style="display: flex; justify-content: flex-end;">
                        <v-btn color="#FFFFFF40" x-small class="mainFunctionButtons ml-3 my-auto"
                               :disabled="!oldSlot" @click="deleteDialog = true">
                            <img :src="loeschIcon">
                        </v-btn>
                        <v-tooltip top nudge-right="100" :open-on-click="true" :open-on-hover="false"
                                   content-class="infoTextToolTip">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="#FFFFFF40" x-small class="mainFunctionButtons ml-3 my-auto"
                                       v-bind="attrs" v-on="on">
                                    <img :src="infoIcon">
                                </v-btn>
                            </template>
                            <span>Hier können Sie Schulstunden für Ihre Schule anlegen. Geben Sie der Stunde einen
                                    Namen und legen Sie fest von wann bis wann die Stunde gehen soll. Die Stunde wird
                                    in allen Stundenplänen als festgelegte Uhrzeit angezeigt.</span>
                        </v-tooltip>
                        <v-btn class="mainControlButtons ml-3 my-auto pa-5" color="#BDBDBD50" @click="stopEdit" dark elevation="0">
                            <span>Abbrechen</span>
                        </v-btn>
                        <v-btn class="mainControlButtons ml-3 my-auto pa-5" color="#69CD70" v-if="oldSlot" @click="editThisSlot" dark elevation="4">
                            <span>Speichern</span>
                        </v-btn>
                        <v-btn class="mainControlButtons ml-3 my-auto pa-5" color="#69CD70" v-else @click="saveSlot" dark elevation="4">
                            <span>Speichern</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <div class="mx-2 mb-2">
                    <v-row>
                        <v-col class="mx-5" md="">
                            <v-row>
                                <v-col cols="2">
                                    Von:
                                </v-col>
                                <v-col cols="4">
                                    <input style="margin-left: -12px" v-model="from" type="time" class="input" :class="{ errorInput: fields.from }" @change="validateInput">
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="mx-5">
                            <v-row>
                                <v-col cols="2">
                                    Bis:
                                </v-col>
                                <v-col cols="4">
                                    <input v-model="till" type="time" class="input" :class="{ errorInput: fields.till }" @change="validateInput">
                                </v-col>
                            </v-row>

                        </v-col>

                    </v-row>
<!--                    <v-row>-->
<!--                        <v-col cols="7" >-->
<!--                            <div style="display: flex" class="mx-3">-->
<!--                                <v-btn fab color="#EAEAEA" class="mx-4" small><img :src="infoIcon" style="width: 24px; display: flex" class="" alt="Info Icon"/></v-btn>-->

<!--                                <div>Hier können Sie Schulstunden für Ihre Schule anlegen. Geben Sie der Stunde einen-->
<!--                                    Namen und legen Sie fest von wann bis wann die Stunde gehen soll. Die Stunde wird-->
<!--                                    in allen Stundenplänen als festgelegte Uhrzeit angezeigt.-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </v-col>-->

<!--                        &lt;!&ndash;                            <v-col cols="3"></v-col>&ndash;&gt;-->
<!--                        <v-col cols="2" class="pl-0">-->
<!--                            &lt;!&ndash;                                <v-btn class="" style="max-width: 100%; width: 100%" color="error" v-if="oldGroup" @click="deleteDialog = true" small light>&ndash;&gt;-->
<!--                            &lt;!&ndash;                                    Gruppe löschen&ndash;&gt;-->
<!--                            &lt;!&ndash;                                </v-btn>&ndash;&gt;-->
<!--                        </v-col>-->
<!--                        <div style="min-width: 100px!important;"></div>-->
<!--                        <v-col cols="1" class="pl-0">-->
<!--                            <v-btn block style="color: white"  @click="stopEdit" color="#BDBDBD" small light>-->
<!--                                Abbrechen-->
<!--                            </v-btn>-->
<!--                        </v-col>-->
<!--                        <v-col cols="1" class="pl-0">-->
<!--                            <v-btn block style="color: white" color="#3BAA69" v-if="oldSlot" @click="editThisSlot" small light>-->
<!--                                Speichern-->
<!--                            </v-btn>-->
<!--                            <v-btn block style="color: white" color="#3BAA69" v-else @click="saveSlot" small light>-->
<!--                                Speichern-->
<!--                            </v-btn>-->
<!--                        </v-col>-->

<!--                    </v-row>-->
                </div>



            </div>

        </v-row>

        <!--        Delete dialog-->
        <v-dialog v-model="deleteDialog" persistent max-width="400">
            <v-card>
                <v-card-title class="headline">Zeitslot löschen?</v-card-title>
                <v-card-text>
                    Sind Sie sicher, dass Sie den Zeitslot löschen möchten?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="" text @click="deleteDialog = false">Abbrechen</v-btn>
                    <v-btn color="red red-1" text @click="() => {this.deleteThisSlot(); this.deleteDialog = false}">Löschen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar bottom v-model="snackbar" color="error" timeout="3000">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbar = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="abbrechenicon" />
            </v-btn>
          </template>
            {{snackbarText}}
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarSave" color="success" timeout="3000">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbarSave = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="abbrechenicon" />
            </v-btn>
          </template>
            Gespeichert!
        </v-snackbar>

    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions, mapGetters} from "vuex";
    import suchIcon from "../../assets/Icons/suche-26.svg";
    import bearbeitenIcon from "../../assets/Icons/bearbeiten-normal-weiß-41.svg";
    import abbrechenicon from "../../assets/Icons/abbrechen-08.svg";
    import infoIcon from "../../assets/Icons/ausrufezeichen-62.svg";
    import schulStundenIcon from "../../assets/Icons/uhr-04.svg";
    import loeschIcon from '../../assets/Icons/papierkorb-verwaltung-109.svg';

    export default {
        name: "Zeitslots",
        components: {

        },
        data() {
            return {
                showLoadingOverlay: false,
                dialog: false,
                notifications: false,
                sound: true,
                widgets: false,

                from: '',
                till: '',

                fields: {
                    from: false,
                    till: false,
                },

                snackbar: false,
                snackbarText: '',

                snackbarSave: false,

                deleteDialog: false,
                oldSlot: null,
                height: 0,
                search: '',
                headers: [
                    { text: 'Von', value: 'start' },
                    { text: 'Bis', value: 'end' },
                    { text: '', sortable: false},
                ],


                slots: [
                ],

                sortedSlots: [
                ],

                test: [
                    { start: "2020-10-05T13:55:00.000Z"},
                    {start: "2020-10-06T06:00:00.077Z"},
                ],
                suchIcon,
                bearbeitenIcon,
                infoIcon,
                schulStundenIcon,
                loeschIcon,
                abbrechenicon,

            };
        },
        props: {

        },
        async created() {
            this.height = window.innerHeight * 0.4
        },
        async mounted() {
            this.showLoadingOverlay = true;
            this.requestSlots();
        },
        computed: {
            ...mapGetters("timeslots", ['getTimeslotsInvalid']),
        },
        watch: {
            getTimeslotsInvalid(newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.requestSlots();
                }
            },
        },
        methods: {
            ...mapActions("timeslots", ["getSlots", "createSlot", "editSlot", "deleteSlot", 'setTimeslotsStatus']),

            async requestSlots() {
                this.showLoadingOverlay = true;
                this.slots = await this.getSlots();
                // console.log("Slots: ", this.slots);
                //TODO: Maybe fix this more elegantly ?
                this.slots.forEach(element => {
                    let dateOldStart = new Date(element.start);
                    dateOldStart.setFullYear(2020,11,18);
                    let dateOldEnd = new Date(element.end);
                    dateOldEnd.setFullYear(2020,11,18);
                    element.start = dateOldStart;
                    element.end = dateOldEnd;
                });
                // console.log("What: ",this.slots);

                // let test = Date.parse[this.slots[0].start];
                // console.log("sorted: ", test);
                this.showLoadingOverlay = false;
                this.setTimeslotsStatus(false);
            },

            compare( a, b ) {
                if ( a.start < b.start ){
                    return -1;
                }
                if ( a.start > b.start ){
                    return 1;
                }
                return 0;
            },

            async edit(group) {
                this.$vuetify.goTo('#edit');
                this.oldSlot = group;
                this.from = ("0" + new Date(group.start).getHours()).slice(-2) + ":" + ("0" + new Date(group.start).getMinutes()).slice(-2);
                this.till = ("0" + new Date(group.end).getHours()).slice(-2) + ":" + ("0" + new Date(group.end).getMinutes()).slice(-2);
            },
            stopEdit() {
                this.from = '';
                this.till = '';
                this.oldSlot = null;

                this.fields.from = false;
                this.fields.till = false;
            },

            async deleteThisSlot() {
                try {
                    this.showLoadingOverlay = true;
                    let response = await this.deleteSlot(this.oldSlot._id);
                    if (response) {
                        const index = this.slots.indexOf(this.oldSlot);
                        if (index > -1) {
                            this.slots.splice(index, 1);
                        }
                        this.setTimeslotsStatus(true);
                        this.stopEdit();
                        this.snackbarSave = true;
                    } else {
                        this.snackbarText = 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!';
                        this.snackbar = true;
                    }
                    this.showLoadingOverlay = false;
                } catch (e) {
                    console.log(e);
                    this.showLoadingOverlay = false;
                }
            },

            validateInput() {
                let result = true;

                this.fields.from = false;
                this.fields.till = false;

                if (!this.from) {
                    this.fields.from = true;
                    result = false;
                }

                if (!this.till) {
                    this.fields.till = true;
                    result = false;
                }

                return result;
            },

            async editThisSlot() {
                try {
                    this.showLoadingOverlay = true;
                    if (!this.validateInput()) {
                        this.snackbarText = 'Bitte füllen Sie alle benötigten Felder aus!';
                        this.snackbar = true;
                        this.showLoadingOverlay = false;
                        return;
                    }

                    let fromDate = new Date();
                    fromDate.setHours(Number(this.from.split(':')[0]));
                    fromDate.setMinutes(Number(this.from.split(':')[1]));
                    fromDate.setSeconds(0);

                    let tillDate = new Date();
                    tillDate.setHours(Number(this.till.split(':')[0]));
                    tillDate.setMinutes(Number(this.till.split(':')[1]));
                    tillDate.setSeconds(0);
                    let data = {
                        _id: this.oldSlot._id,
                        start: fromDate,
                        end: tillDate,
                    };


                    let response = await this.editSlot(data);
                    if (Number.isInteger(response)) {
                        this.snackbarText = 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!';
                        this.snackbar = true;
                    } else {
                        const index = this.slots.indexOf(this.oldSlot);
                        if (index > -1) {
                            this.slots.splice(index, 1);
                        }
                        this.setTimeslotsStatus(true);
                        await this.requestSlots();
                        this.stopEdit();
                        this.snackbarSave = true;
                    }
                    this.showLoadingOverlay = false;
                } catch (e) {
                    console.log(e);
                    this.showLoadingOverlay = false;
                }
            },
            async saveSlot() {
                try {
                    this.showLoadingOverlay = true;
                    if (!this.validateInput()) {
                        this.snackbarText = 'Bitte füllen Sie alle benötigten Felder aus!';
                        this.snackbar = true;
                        this.showLoadingOverlay = false;
                        return;
                    }

                    let fromDate = new Date();
                    fromDate.setHours(Number(this.from.split(':')[0]));
                    fromDate.setMinutes(Number(this.from.split(':')[1]));
                    fromDate.setSeconds(0);

                    let tillDate = new Date();
                    tillDate.setHours(Number(this.till.split(':')[0]));
                    tillDate.setMinutes(Number(this.till.split(':')[1]));
                    tillDate.setSeconds(0);
                    let data = {
                        start: fromDate,
                        end: tillDate,
                    };


                    let response = await this.createSlot(data);
                    if (Number.isInteger(response)) {
                        this.snackbarText = 'Etwas ist schief gelaufen - Bitte aktualisieren Sie die Seite!';
                        this.snackbar = true;
                    } else {
                        this.setTimeslotsStatus(true);
                        await this.requestSlots();
                        this.stopEdit();
                        this.snackbarSave = true;
                    }
                    this.showLoadingOverlay = false;
                } catch (e) {
                    console.log(e);
                    this.showLoadingOverlay = false;
                }
            },
        },
    };
</script>


<style lang="scss" scoped>
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    tr {
        height: 40px;
    }

    table tr th:first-child,
    table tr td:first-child {
        width: 10px !important;
    }

    table tr th,
    table tr td {
        width: 9em;
        border: none;
    }
    table, tr, td {
        border: none;
    }

    input[type="date"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        min-height: 1.6rem;
    }

    .input{
        display: block;
        width: 100%;
        max-width: 100%;
        border-radius: 5px;
        border-width:0px;color: black;
        padding: 1px 5px 1px 5px;
        border: none !important;
        background-color: #EAEAEA;
        overflow: hidden;
        appearance:none
    }

    .input[disabled]{
        color: grey;
        background-color: #CACACA;
    }

    .editContainer {
        min-height: 40vh;
        height: fit-content;
        width: 90%;
        border-radius: 15px;
        box-shadow: 1px 5px 5px silver;
        margin: auto;
        margin-bottom: 5em
    }

    .mainControlButtons {
        font-weight: 300;
        font-size: 10px;
        border-radius: 8px;

        & span {
            line-height: 0 !important;
        }
    }

    .mainFunctionButtons {
        width: 38px !important;
        height: 38px !important;
        border-radius: 8px;

        img {
            width: 27px !important;
            height: 27px !important;
            filter: brightness(1000%);
        }
    }

    .errorInput {
        border: 2px solid red !important;
    }

    .infoTextToolTip {
        max-width: 250px;
        background-color: #505FAA;
    }
</style>
