<template>
    <div style="border-radius: 5px !important; background-color: #F8F8F8">
        <v-row no-gutters :style="{ backgroundColor: groupColor, height: '65px' }" class="ma-auto mb-1 pa-1"
               align="center">
            <v-col :cols="windowWidth < 770 ? (windowWidth < 380 ? 10 : 9) : 7">
                <EditorToolbar
                    :is-text-layer-visible="isTextLayerVisible"
                    :change-color="changeColor"
                    :change-size="changeSize"
                    :change-stamp="changeStamp"
                    :spawn-text-on-canvas="spawnTextOnCanvas"
                    :set-tool="setTool"
                    :rotate-editor="rotateEditor"
                    :current-active-method="currentActiveMethod"
                    :display-other-buttons="true"
                    :hoeren="hoeren"
                    :sehen="sehen"
                    :color="color"
                >
                </EditorToolbar>
            </v-col>
            <v-col :cols="windowWidth < 770 ? (windowWidth < 380 ? 2 : 3) : 5"
                   style="display: flex; justify-content: flex-end;">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            aria-label="Texte des Dokuments aktivieren"
                            v-on="on"
                            x-small elevation="0" class="mr-4"
                            style="width: 50px; height: 50px; border-radius: 8px;"
                            id="textLayerButton"
                            :class="{ 'textLayerActive': isTextLayerVisible }"
                            @click="changePDFTextLayerVisibility"
                        >
                            <v-icon style="max-width: 28px; max-height: 35px">fas fa-magic</v-icon>
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span>Texte des Dokuments aktivieren</span>
                    </div>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-if="targetLang !== 'de'" @click="translate = !translate" v-on="on"
                               x-small elevation="0" class="mr-4"
                               style="width: 50px; height: 50px; border-radius: 8px;"
                               :style="translate ? 'background-color: var(--v-gruen-base) !important' : ''">
                            <v-icon style="max-width: 25px; max-height: 25px;"
                                    :alt="translate ? 'Übersetzung deaktivieren' : 'Übersetzung aktivieren'"
                            >mdi-translate
                            </v-icon>
                        </v-btn>

                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span v-if="translate">Übersetzung deaktivieren</span>
                        <span v-else>Übersetzung aktivieren</span>
                        <img v-if="hoeren" :src="uebersetzenMetacom" width="100" style="margin: auto">
                    </div>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-if="mode !== 'creator'"
                            v-on="on"
                            @click="toggleMagnifier"
                            style="width: 50px; height: 50px; border-radius: 8px;"
                            :style="magnifier ? 'background-color: #8CBD46 !important' : ''"
                            x-small elevation="0"
                            class="ml-4"
                            tabindex="20"
                        >
                            <img :src="lupenIcon" style="max-width: 25px; max-height: 25px;"
                                 :alt="magnifier ? 'Lupe ausschalten (Tastaturkürzel: Alt + L)' : 'Lupe einschalten (Tastaturkürzel: Alt + L)'"/>
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span v-if="magnifier">Lupe ausschalten (Alt + L)</span>
                        <span v-else>Lupe einschalten (Alt + L)</span>
                        <img v-if="hoeren" :src="lupeMetacom" width="100" style="margin: auto">
                    </div>
                </v-tooltip>

                <v-tooltip bottom v-if="windowWidth > 600">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            v-if="mode !== 'creator'"
                            x-small elevation="0"
                            class="ml-4"
                            style="width: 50px; height: 50px; border-radius: 8px;"
                            :style="keyboard ? 'background-color: #8CBD46 !important' : ''"
                            @click="toggleKeyboard"
                            tabindex="20"
                        >
                            <img :src="keyboardIcon"
                                 :alt="keyboard ? 'Tastatursteuerung ausschalten (Tastaturkürzel: Alt + T)' : 'Tastatursteuerung einschalten (Tastaturkürzel: Alt + T)'"
                                 height="25">
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span v-if="keyboard">Tastatursteuerung ausschalten (Alt + T)</span>
                        <span v-else>Tastatursteuerung einschalten (Alt + T)</span>
                        <img v-if="hoeren" :src="tastaturTippenMetacom" width="100" style="margin: auto">
                    </div>
                </v-tooltip>

                <v-btn
                    v-if="false"
                    :disabled="isTextLayerVisible"
                    x-small
                    class="toolBtn text-capitalize mr-4"
                    color="grey"
                    elevation="3"
                    @click="spawnAudioObject"
                    style="opacity: 80%"
                >
                    <img :src="mikrofonIcon" style="height: 40px; filter: brightness(1000%); justify-self: flex-end;"/>
                    <p class="mb-0" style="font-size: large; color: white">Aufnehmen</p>
                </v-btn>

                <editor-tools
                    v-if="false"
                    @addTextMessage="spawnTextObject"
                    @addAudioMessage="spawnAudioObject"
                    @addTextTask="placeNewEditorTextTask(0, 0.5, 0.5)"
                    @addAnswerField="placeNewEditorAnswerField(0, 0.25, 0.25)"
                    @addImageField="placeNewImage"
                    @addVideoField="placeNewVideo"
                    @addMultipleChoiceField="placeNewMultipleChoice"
                    @addAdditionalPage="addNewPage"
                    @addMoveableText="addMoveableText"
                    @addRectangle="addRectangle"
                />

                <!--                Previous If for save button-->
                <!--                        v-if="userAccount.role === 'teacher' && !$route.query.correction &&-->
                <!--            (messageList.length ||-->
                <!--              textMessageList.length ||-->
                <!--              textTaskList.length ||-->
                <!--              textAnswerList.length ||-->
                <!--              imageList.length ||-->
                <!--              videoList.length ||-->
                <!--              moveableTexts.length ||-->
                <!--              multipleChoices.length)"-->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            x-small
                            color="gruen"
                            class="toolBtn mr-4"
                            elevation="3"
                            v-on="on"
                            v-bind="attrs"
                            @click="handleSaveClick"
                        >
                            <img
                                :src="speichernIcon"
                                style="
              height: 40px;
              filter: brightness(1000%);
              justify-self: flex-end;
            "
                            />
                        </v-btn>
                    </template>
                    <span>
                        Speichern
                    </span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn id="closeBtn"
                               @click="close"
                               v-on="on"
                               v-bind="attrs"
                               x-small
                               class="mr-4"
                               elevation="3">
                            <img :src="closeIcon" style="
                                      height: 40px;
                                      filter: brightness(1000%);
                                      justify-self: flex-end;"/>
                        </v-btn>

                    </template>
                    <span>
                        Abbrechen
                    </span>
                </v-tooltip>
            </v-col>

        </v-row>
        <vuescroll :ops="ops" style="height: calc(100vh - 70px); background-color: #EAEAEA" ref="scrollbar"
                   @handle-scroll="scrolledEvent">

            <!-- Editing row -->
            <v-row class="ma-0" align="center" justify="center">
                <!--                Stored for safekeeping-->
                <!--                :class="{'multiplePagesZoomControl': pages.length && windowWidth > 770}"-->
                <EditorSideBar
                    :mode="mode"
                    :current-page-index="currentPageIndex"
                    :page-count="pages.length"
                    @zoomIn="() => changeZoom('plus')"
                    @zoomOut="() => changeZoom('minus')"
                    @addAudioMessage="spawnAudioObject"
                    @addTextMessage="spawnTextObject"
                    @addTextTask="placeNewEditorTextTask(0, 0.5, 0.5)"
                    @addAnswerField="placeNewEditorAnswerField(0, 0.25, 0.25)"
                    @addImageField="placeNewImage"
                    @addVideoField="placeNewVideo"
                    @addMultipleChoiceField="placeNewMultipleChoice"
                    @addAdditionalPage="addNewPage"
                    @addMoveableText="addMoveableText"
                    @addRectangle="addRectangle"
                    @addLine="addLine"
                />
                <span v-if="hoeren || sehen" tabindex="17" class="visually-hidden"> Um den vollen Funktionsumfang des Editor nutzen zu können,
                        bitte bedienen Sie diesen über die Tabulator-Taste. </span>

                <div style="position: relative">
                    <EditingCanvas
                        v-for="(page, index) in pages" :key="index"
                        :canvas-height="canvasHeight"
                        :canvas-width="canvasWidth"
                        :loading-function="setLoading"
                        :ref="`editingcanvas${index}`"
                        :tab-index="21 + tabOrderArray.length"
                        @placeNewEditorMessage="(top, left) => { placeNewEditorMessage(index, top, left); }"
                        @placeNewEditorTextMessage="(top, left) => { placeNewEditorTextMessage(index, top, left); }"
                        @dimensionsChanged="(data) => { canvasWidth = data.width; canvasHeight = data.height; }"
                        @updateZoom="(direction) => { changeZoom(direction); }"
                        :is-text-layer-visible="isTextLayerVisible"
                        @readParagraph="(text, callback) => { readText(text, callback) }"
                        @textDivsEmpty="() => { noTextOnTextLayerSnackbar = true }"
                        style="position: relative">
                    </EditingCanvas>
                    <EditorRectangle
                        v-for="(rect, index) in rectangles"
                        :key="'rectangle' + index"
                        :rect="rect"
                        :canvas-width="canvasWidth"
                        :canvas-height="canvasHeight"
                        :class="{ focusMe: focusedElement === ('rectangle' + index) }"
                        @focusMe="() => { focusedElement = ('rectangle' + index) }"
                        @deleteObject="() => { rectangles.splice(index, 1) }"
                        @specsChanged="() => handleFormSpecsChanged('rectangle', rect)"
                        :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                            top: ${htmlObjectYAxis(rect)}%; left: ${ rect.x * (1 + currentZoom / 5) }%; transform: scale(${1 + currentZoom / 5}, ${ 1 + currentZoom / 5 }`"
                    />
                    <EditorLine
                        v-for="(line, index) in lines"
                        :key="'line' + index"
                        :line="line"
                        :canvas-width="canvasWidth"
                        :canvas-height="canvasHeight"
                        :class="{ focusMe: focusedElement === ('line' + index) }"
                        @focusMe="() => { focusedElement = ('line' + index) }"
                        @deleteObject="() => { lines.splice(index, 1) }"
                        @specsChanged="() => handleFormSpecsChanged('line', line)"
                        :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                            top: ${htmlObjectYAxis(line)}%; left: ${ line.x * (1 + currentZoom / 5) }%; transform: scale(${1 + currentZoom / 5}, ${ 1 + currentZoom / 5 }`"
                    />
                    <!-- Images should probably be the lowest element, so people can place stuff on top of them -->
                    <EditorImageObject
                        v-for="(img, i) in imageList"
                        :key="'imgFile'+img.fileUrl"
                        :tab-index="19 + img.tabIndex"
                        :img="img"
                        :canvas-width="canvasWidth"
                        :canvas-height="canvasHeight"
                        :class="{focusMe: focusedElement === ('imgFile'+i)}"
                        @focusMe="() => focusedElement = ('imgFile'+i)"
                        @deleteObject="() => imageList.splice(i, 1)"
                        :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                            top: ${audioMessageYAxis(img)}%; left: ${img.x * (1 + (currentZoom / 5))}%;
                            transform: scale(${1 + (currentZoom / 5)}, ${1 + (currentZoom / 5)}
                            `"
                    >
                    </EditorImageObject>
                    <EditorVideoObject
                        v-for="(video, i) in videoList"
                        :key="'videoFile'+i"
                        :video="video"
                        :canvas-width="canvasWidth"
                        :canvas-height="canvasHeight"
                        :tab-index="19 + video.tabIndex"
                        @deleteObject="() => videoList.splice(i, 1)"
                        :class="{focusMe: focusedElement === ('videoFile' + i)}"
                        @focusMe="focusedElement = ('videoFile' + i)"
                        :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                            top: ${audioMessageYAxis(video)}%; left: ${video.x * (1 + (currentZoom / 5))}%;
                            transform: scale(${1 + (currentZoom / 5)}, ${1 + (currentZoom / 5)}
                            `"
                    >
                    </EditorVideoObject>
                    <EditorAudioMessage
                        v-for="(message, index) in messageList"
                        :key="'audio'+index"
                        :state="mode === 'creator' ? 'start' : 'answer'"
                        :tab-index="19 + message.tabIndex"
                        :class="{focusMe: focusedElement === ('audio' + index)}"
                        @focusMe="() => focusedElement = ('audio' + index)"
                        @deleteMessage="deleteMessage"
                        :style="`position: absolute; top: ${audioMessageYAxis(message)}%; left: ${message.x}%;`"
                        :uploadMessage="message" :index="index"/>
                    <EditorTextMessage
                        v-for="(message, index) in textMessageList"
                        :key="'text'+index"
                        :message="message" :index="index"
                        :tab-index="19 + message.tabIndex"
                        :class="{focusMe: focusedElement === ('text' + index)}"
                        @focusMe="() => focusedElement = ('text' + index)"
                        :style="`position: absolute; top: ${audioMessageYAxis(message)}%; left: ${message.x}%;`"
                        :target-lang="targetLang"
                        @deleteNote="() => deleteTextNote(index)"
                        :translate="translate"/>
                    <EditorTextTask
                        v-for="(task, index) in textTaskList"
                        :key="'texttask'+index"
                        :task="task"
                        :canvas-height="canvasHeight"
                        :canvas-width="canvasWidth"
                        :tab-index="19 + task.tabIndex"
                        :class="{focusMe: focusedElement === ('texttask'+index)}"
                        @focusMe="() => focusedElement = ('texttask'+index)"
                        @coordsChanged="(coords) => updateTextTaskPosition(task, coords)"
                        @dimensionsChanged="(dimensions) => updateTextTaskDimension(task, dimensions)"
                        @deleteTask="deleteTextTask(task)"
                        :style="`position: absolute; transform-origin: top left;
                                top: ${audioMessageYAxis(task)}%; left: ${task.x * (1 + (currentZoom / 5))}%;
                                width: ${canvasWidth * task.width}px; height: ${canvasHeight * task.height}px;
                                transform: scale(${1 + (currentZoom / 5)}, ${1 + (currentZoom / 5)}
                                `"
                        :teachers="teacherIdArray"
                        :state="mode === 'creator' ? 'start' : 'answer'"
                        :target-lang="targetLang"
                        :translate="translate"
                    />
                    <EditorAnswerField
                        v-for="(answerEntry, index) in textAnswerList"
                        :key="'answerEntry'+index"
                        :answer-entry="answerEntry"
                        :canvas-height="canvasHeight"
                        :canvas-width="canvasWidth"
                        :tab-index="19 + answerEntry.tabIndex"
                        :class="{focusMe: focusedElement === ('answerEntry'+index)}"
                        @focusMe="() => focusedElement = ('answerEntry'+index)"
                        @deleteAnswerField="() => deleteAnswerField(index)"
                        @dimensionsChanged="(dimensions) => updateAnswerFieldDimension(answerEntry, dimensions)"
                        @coordsChanged="(coords) => updateAnswerEntryPosition(answerEntry, coords)"
                        :style="`position: absolute; transform-origin: top left;
                        top: ${audioMessageYAxis(answerEntry)}%; left: ${answerEntry.x * (1 + (currentZoom / 5))}%;
                        transform: scale(${1 + (currentZoom / 5)}, ${1 + (currentZoom / 5)}
                        `"
                    />
                    <EditorMultipleChoice
                        v-for="(mChoices, index) in multipleChoices"
                        :key="'multipleChoice' + index"
                        :m-data="mChoices"
                        :canvas-width="canvasWidth"
                        :tab-index="19 + mChoices.tabIndex"
                        :canvas-height="canvasHeight"
                        @deleteObject="multipleChoices.splice(index, 1)"
                        :class="{focusMe: focusedElement === ('multipleChoice' + index)}"
                        @focusMe="() => focusedElement = ('multipleChoice' + index)"
                        :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                            top: ${audioMessageYAxis(mChoices)}%; left: ${ mChoices.x * (1 + currentZoom / 5) }%; transform: scale(${1 + currentZoom / 5}, ${ 1 + currentZoom / 5 }`"
                        :target-lang="targetLang"
                        :translate="translate"
                    />
                    <EditorMoveableText
                        v-for="(text, index) in moveableTexts"
                        :key="'moveableText' + index"
                        :text="text"
                        :canvas-width="canvasWidth"
                        :canvas-height="canvasHeight"
                        :tab-index="19 + text.tabIndex"
                        :class="{focusMe: focusedElement === ('moveableText' + index)}"
                        @focusMe="() => focusedElement = ('moveableText' + index)"
                        @deleteObject="() => { moveableTexts.splice(index, 1) }"
                        :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                            top: ${audioMessageYAxis(text)}%; left: ${ text.x * (1 + currentZoom / 5) }%; transform: scale(${1 + currentZoom / 5}, ${ 1 + currentZoom / 5 }`"
                    />
                </div>

            </v-row>
        </vuescroll>

        <!-- Popup land -->
        <v-dialog v-model="loadingActive" hide-overlay persistent width="150">
            <v-card :color="groupColor">
                <v-card-text class="pa-2">
                    <v-progress-circular
                        indeterminate
                        class="ma-auto"
                        style="display: block"
                    />
                    <br/>
                    <p class="ma-auto" style="text-align: center">{{ loadingMessage }}</p>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbars.audioMessagesSaved"
            timeout="5000"
            color="gruen"
        >
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    small
                    @click="snackbars.audioMessagesSaved = false"
                >
                    <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon"/>
                </v-btn>
            </template>
            Platzierte Elemente wurden gespeichert.
        </v-snackbar>

        <!-- Textlayer activated snackbar -->
        <v-snackbar
            v-model="textLayerActivatedSnackbar"
            timeout="30000"
            :color="noTextOnTextLayerSnackbar ? 'warning' : 'gruen'"
        >
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    small
                    @click="textLayerActivatedSnackbar = false"
                >
                    <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon"/>
                </v-btn>
            </template>
            <span v-if="noTextOnTextLayerSnackbar">Der Textlayer wurde aktiviert, aber es wurde kein Text auf dem PDF gefunden. Wurde das PDF
                vielleicht vorher bemalt oder eingescannt? Mehr Infos finden Sie im FAQ.</span>
            <span v-else>Der Textlayer wurde aktiviert. Nun können Sie Texte auf dem PDF auswählen und mit ihnen interagieren.
                Einige andere Funktionen sind währenddessen deaktiviert.</span>
        </v-snackbar>

        <v-snackbar v-model="snackbars.fileNotAllowed" timeout="5000" color="error">
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    small
                    @click="snackbars.fileNotAllowed = false"
                >
                    <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon"/>
                </v-btn>
            </template>
            Sie haben keinen Zugriff auf diese Datei. Bitte kontaktieren Sie den Autor
            der Datei.
        </v-snackbar>
        <v-snackbar
            v-model="snackbars.fileNotAvailable"
            timeout="5000"
            color="error"
        >
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    small
                    @click="snackbars.fileNotAvailable = false"
                >
                    <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon"/>
                </v-btn>
            </template>
            Die Datei konnte nicht gefunden werden. Bitte laden Sie die Datei erneut
            hoch, oder kontaktieren Sie den Support.
        </v-snackbar>
        <v-snackbar v-model="snackbars.genericError" timeout="5000" color="error">
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    small
                    @click="snackbars.genericError = false"
                >
                    <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon"/>
                </v-btn>
            </template>
            Etwas ist schief gelaufen - Bitte aktualisieren Sie die Seite!
        </v-snackbar>
    </div>
</template>

<script>
import EditorToolbar from "./EditorToolbar";
import EditorAudioMessage from "../Editortools/EditorAudioMessage";
import EditingCanvas from "../EditingCanvas";
import vuescroll from "vuescroll";
import EditorTextMessage from "../Editortools/EditorTextMessage";
import EditorAnswerField from "../Editortools/EditorAnswerField";
import EditorTextTask from "../Editortools/EditorTextTask";
import {PDFDocument} from 'pdf-lib';
import pdfjs from '../../util/pdfjs';
import EditorImageObject from "@/components/Editortools/EditorImageObject";
import EditorVideoObject from "@/components/Editortools/EditorVideoObject";
import EditorMultipleChoice from "@/components/Editortools/EditorMultipleChoice";
import kariertesBlatt from "@/assets/Icons/kariertes blatt-112.svg";
import leeresBlatt from "@/assets/Icons/leeres-blatt-110.svg";
import liniertesBlatt from "@/assets/Icons/liniertes-blatt-111.svg";
import EditorMoveableText from "@/components/Editortools/EditorMoveableText";
import EditorTools from "@/components/Editortools/Toolbar/EditorTools";
import scrollbarStyling from "../../util/scrollbarStyling";
import stiftIcon from "../../assets/Icons/bearbeiten-komplimentär-weiß-88.svg";
import undoIcon from "../../assets/Editing/rückgänig.svg";
import closeIcon from "../../assets/Icons/abbrechen-08.svg";
import abbrechenIcon from "../../assets/Icons/abbrechen-08.svg";
import textIcon from "../../assets/Icons/Textfunktion-67.svg";
import speichernIcon from "../../assets/Icons/speichern-64.svg";
import radiererIcon from "../../assets/Icons/radierer-73.svg";
import fertigIcon from "../../assets/Icons/fertig-74.svg";
import drehIcon from "../../assets/Icons/90-grad-drehung.svg";
import seitenIcon from "../../assets/Icons/kein-dokument-68.svg";
import keyboardIcon from "../../assets/Icons/keyboard-24px.svg";
import lupenIcon from "../../assets/Icons/lupe-18.svg";
import mikrofonIcon from "../../assets/Icons/mikrofon-22.svg";
import plusIcon from "../../assets/Icons/hinzufuegen-07.svg";
import lautsprecherIcon from "../../assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "../../assets/Icons/lautsprecher-aus-89.svg";
import malenMetacom from "../../assets/METACOM/Editor/malen.png";
import tastaturTippenMetacom from "../../assets/METACOM/Allgemein/tastaturtippen.png";
import loeschenMetacom from "../../assets/METACOM/Allgemein/loeschendaten.png";
import farbenMetacom from "../../assets/METACOM/Editor/farbe.png";
import aussuchenMetacom from "../../assets/METACOM/Editor/aussuchen.png";
import drehenMetacom from "../../assets/METACOM/Editor/drehen.png";
import fertigMetacom from "../../assets/METACOM/Editor/beenden.png";
import aufhoerenMetacom from "../../assets/METACOM/Editor/aufhoeren.png";
import lupeMetacom from "../../assets/METACOM/Allgemein/suchenlupe.png";
import uebersetzenMetacom from "../../assets/METACOM/Allgemein/uebersetzen.png";
import {mapActions, mapGetters, mapState} from "vuex";
import * as backend from "../../api/backend";
import EditorSideBar from "../Editortools/Toolbar/EditorSideBar";
import EditorRectangle from "@/components/Editortools/EditorRectangle";
import EditorLine from "@/components/Editortools/EditorLine";

export default {
    name: 'EditorBaseComponent',
    components: {
        EditorLine,
        EditorRectangle,
        EditingCanvas,
        EditorToolbar,
        vuescroll,
        EditorMoveableText,
        EditorMultipleChoice,
        EditorVideoObject,
        EditorImageObject,
        EditorAudioMessage,
        EditorTextMessage,
        EditorAnswerField,
        EditorTextTask,
        EditorTools,
        EditorSideBar,
    },
    props: {
        mode: {required: true, type: String},
        saveFunction: {required: true, type: Function},

        loadingMessage: {required: false, type: String, default: 'Dokument wird geladen...'},
        loadingActive: {required: false, type: Boolean, default: false}
    },
    data: () => ({
        windowWidth: window.innerWidth,
        currentActiveMethod: 'none',
        params: {},
        color: "#706f6f",
        imageUrl: null,
        croppedImage: false,
        canvasWidth: 300,
        canvasHeight: 600,
        componentKey: 0,
        currentFile: null,
        groupName: 'Deutsch',
        groupColor: 'blue',
        assignmentName: 'Aufgabe 1',
        appointmentId: '',
        fileId: '',
        uploadId: '',
        pages: [0],
        emptyPageType: null,
        saveDialog: false,
        saveProgressDialog: false,
        snackbars: {
            dataSaved: false,
            dataSubmitted: false,
            fileNotAllowed: false,
            fileNotAvailable: false,
            dataCouldntBeSaved: false,
            genericError: false,
        },
        ops: {
            rail: {
                opacity: '0',
                background: undefined,
                border: 'none',
                size: '60px'
            },
            bar: {
                background: '#55555580',
                keepShow: true,
                size: '45px',
                minSize: 0.1
            },
            scrollButton: {
                enable: true,
                background: '#cecece'
            },
            scrollPanel: {
                easing: 'easeInQuad',
                speed: 800
            },
            vuescroll: {
                wheelScrollDuration: 0,
                wheelDirectionReverse: false,
                sizeStrategy: 'percent',
                detectResize: true
            }
        },
        currentZoom: 0,
        displayOtherButtons: false,
        isHorizontalScrollbarStyled: false,
        isVerticalScrollbarStyled: false,

        stiftSizeMenu: false,
        colorMenu: false,
        addPageMenu: false,
        currentPageIndex: 0,

        scrollbarStyling,

        // Editor Messages
        messageList: [],
        textMessageList: [],
        textTaskList: [],
        textAnswerList: [],
        imageList: [],
        videoList: [],
        multipleChoices: [],
        moveableTexts: [],
        rectangles: [],
        lines: [],
        additionalPagesDropdown: [{title: 'Leere Seite', value: 'leer', img: leeresBlatt}, {
            title: 'Karierte Seite',
            value: 'kariert',
            img: kariertesBlatt
        }, {title: 'Linierte Seite', value: 'liniert', img: liniertesBlatt}, {
            title: 'Hauslinien Seite',
            value: 'haus',
            img: liniertesBlatt
        }],

        sehen: false,
        hoeren: false,
        babyView: false,

        translate: false,

        // Icons
        stiftIcon,
        undoIcon,
        closeIcon,
        textIcon,
        speichernIcon,
        radiererIcon,
        fertigIcon,
        drehIcon,
        seitenIcon,
        keyboardIcon,
        lupenIcon,
        malenMetacom,
        tastaturTippenMetacom,
        loeschenMetacom,
        farbenMetacom,
        aussuchenMetacom,
        drehenMetacom,
        fertigMetacom,
        aufhoerenMetacom,
        lupeMetacom,
        uebersetzenMetacom,
        kariertesBlatt,
        leeresBlatt,
        liniertesBlatt,
        mikrofonIcon,
        plusIcon,
        abbrechenIcon,
        lautsprecherIcon,
        lautsprecherAusIcon,
        // UI Changes
        speakerIcon: lautsprecherIcon,

        // PDF.JS Textlayer
        isTextLayerVisible: false,
        selectedText: null,
        textSelectionInterval: null,
        lastSelectedTextNode: null,
        textSelectionActionButtons: {
            show: false,
            top: null,
            left: null,
        },
        noTextOnTextLayerSnackbar: false,
        textLayerActivatedSnackbar: false,
        focusedElement: null,
        pageAdded: false,
        pupilXmlHttpRequest: null,
        // Tools and Messages
        tempTextMessage: null,
        currentLang: 'de',
        // Last sizes of the forms
        lastFormSpecs: {
            'rectangle': {
                // Pos in percentage
                x: 50,
                y: 50,
                // Width/Height in pixels
                width: 0.3,
                height: 0.1,
            },
            'line': {
                x: 50,
                y: 50,
                width: 100,
                height: 10,
                rotation: 0
            }
        },

        // Accessibility
        tabOrderArray: [],

        //DEBUG
        dragX: 0,
        dragY: 0,
        moveX: 0,
        moveY: 0,

    }),
    async mounted() {

        let fileType = '';
        let localUploadId = '';
        let localAppointmentId = '';
        if (this.$route.query.group) {
            this.groupName = this.$route.query.group;
        }
        if (this.$route.query.color) {
            this.groupColor = this.$route.query.color;
        }
        if (this.$route.query.aId) {
            this.appointmentId = this.$route.query.aId;
            localAppointmentId = this.$route.query.aId;
        }
        if (this.$route.query.fId) {
            this.fileId = this.$route.query.fId;
        }
        if (this.$route.query.title) {
            this.assignmentName = this.$route.query.title;
            const assignmentNameParts = this.assignmentName.split('.')

            fileType = assignmentNameParts[assignmentNameParts.length - 1];
        }
        if (this.$route.query.uploadid) {
            this.uploadId = this.$route.query.uploadid;
            localUploadId = this.$route.query.uploadid;
        }
        if (this.$route.query.pagetype) {
            this.emptyPageType = this.$route.query.pagetype;
        }

        let localUserGroup = 'pupil';
        if (this.$route.query.ug) {
            localUserGroup = this.$route.query.ug;
        }

        if (this.$route.query.pagetype) {
            this.emptyPageType = this.$route.query.pagetype;
        }

        this.setLoading(true);
        if (this.emptyPageType) {
            await this.$refs[`editingcanvas0`][0].setEmptyBackground(this.emptyPageType)
        } else {
            await this.requestFile(localUserGroup, fileType);
        }

        // Request messages
        try {
            const response = await this.getUploadMessages(this.fileId);
            if (Number.isInteger(response)) {
                // show error
            } else {
                // this.messageList = response && response.points ? response.points : [];
                this.sortIncomingMessages(response && response.points ? response.points : []);
            }
        } catch (e) {
            console.log(e);
        }

        // Editor Autosave parts

        const params = {
            appointmentId: localAppointmentId,
            uploadId: localUploadId
        };
        try {

            const bufferResponse = await this.pullSavedWork(params);

            if (bufferResponse) {
                const responseJson = await bufferResponse.json();
                this.loadWork(responseJson.changeHistory);
            }
        } catch (e) {
            // Buffer is probably empty, should it be empty tho?
            if (e.name !== 'SyntaxError') {
                console.error(e);
            }
        }

        this.checkForScrollbarStylingUpdates(document.getElementsByClassName('__vuescroll')[0]);

        const observer = new MutationObserver((mutationsList, observer) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    this.checkForScrollbarStylingUpdates(document.getElementsByClassName('__vuescroll')[0]);
                }
            }
        });

        // Start observing the target node for configured mutations
        observer.observe(
            document.getElementsByClassName('__vuescroll')[0],
            {attributes: false, childList: true, subtree: false}
        );

        // if (this.windowWidth > 600) {
        //     this.displayOtherButtons = true;
        // }

        // this.requestBabyViewAndAccessibilityMode();
    },
    watch: {
        selectedText(newVal) {

            if (newVal && this.lastSelectedTextNode) {
                const topPixels = this.lastSelectedTextNode.style.top.substring(0, this.lastSelectedTextNode.style.top.length - 2);
                // const leftPixels = this.lastSelectedTextNode.style.left.substring(0, this.lastSelectedTextNode.style.left.length-2);
                if (this.lastSelectedTextNode.parentElement && this.lastSelectedTextNode.parentElement.id) {
                    const lastMarkedPageIndex =
                        Number.parseInt(this.lastSelectedTextNode.parentElement.id.replace('textLayer', ''));

                    this.textSelectionActionButtons.top =
                        (((lastMarkedPageIndex * this.canvasHeight) + Number.parseFloat(topPixels))
                            / this.canvasHeight / this.pages.length) * 100 * (1 + this.currentZoom / 5);
                } else {
                    this.textSelectionActionButtons.top = (topPixels / this.canvasHeight) * 100 * (1 + this.currentZoom / 5);

                }
                this.textSelectionActionButtons.left = 80;
                this.textSelectionActionButtons.show = true;

                console.log(this.textSelectionActionButtons);
            } else {
                this.textSelectionActionButtons.show = false;

            }
        }
    },
    computed: {
        ...mapState("auth", ["account"]),
        ...mapState("magnifier", ["magnifier"]),
        ...mapState("util", ['keyboard', 'lastOpenedInEditor', 'openAppointment']),
        ...mapGetters('files', ['getCurrentFileUrl', 'getCurrentFile', 'getCurrentFileType', 'getWork']),
        ...mapGetters('appointments', ['appointment']),
        ...mapGetters('translation', ['getTargetLang']),
        teacherIdArray() {
            let currentAppointment = this.appointment(this.appointmentId);
            if (!currentAppointment) {
                currentAppointment = this.getAppointments().find(
                    (item) => item._id === this.appointmentId
                );
            }
            const resultArray = [];

            for (let i = 0; i < currentAppointment.teachers.length; i++) {
                resultArray.push(currentAppointment.teachers[i].account);
            }
            return resultArray;
        },
        targetLang() {
            return this.getTargetLang;
        },
        userAccount() {
            return this.account;
        }
    },
    methods: {
        ...mapActions("files", ["pullCurrentFile", "safeEmptyPage", "safeNewPdfFile", 'patchCurrentFile', 'pullSavedWork']),
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions("translation", ["setTargetLang", "translateToTargetLang", 'setTranslatedText', 'showTranslation']),
        ...mapActions("uploadMessage", ['getUploadMessages', 'createUploadMessages']),
        ...mapActions("util", ["toggleKeyboard"]),
        ...mapActions("magnifier", ["toggleMagnifier", "turnOffMagnifier"]),
        /**
         * Toolbar Functions
         */
        setTool(type) {
            this.stiftSizeMenu = true;
            if (this.currentActiveMethod !== type) {
                // Special Case for Highlighter toggle
                if (this.currentActiveMethod === 'marker' && type === 'pen') {
                    return;
                }
                this.currentActiveMethod = type;
                for (let i = 0; i < this.pages.length; i++) {
                    this.$refs[`editingcanvas${i}`][0].switchTool(type);
                }
            } else {
                this.currentActiveMethod = 'none';
                for (let i = 0; i < this.pages.length; i++) {
                    this.$refs[`editingcanvas${i}`][0].switchTool('none');
                }
            }
        },
        undo() {
            if (this.pages.length === 1) {
                this.$refs[`editingcanvas0`][0].undo();
            } else {
                const scrolledDistance = this.$refs.scrollbar.getPosition().scrollTop;
                const canvasIndex = (scrolledDistance - (scrolledDistance % this.canvasHeight)) / this.canvasHeight;
                this.$refs[`editingcanvas${canvasIndex}`][0].undo();
            }
        },
        rotateEditor() {
            this.messageList.forEach((el) => {
                let temp = {...el.x};
                el.x = el.y;
                el.y = temp;
                el.x = 100 - el.x;
            });
            for (let i = 0; i < this.pages.length; i++) {
                this.$refs[`editingcanvas${i}`][0].rotateEditor();
            }
        },
        changeColor(colorHex) {
            this.color = colorHex;
            for (let i = 0; i < this.pages.length; i++) {
                this.$refs[`editingcanvas${i}`][0].changeColor(colorHex);
            }
        },
        changeSize(pixelCount) {
            for (let i = 0; i < this.pages.length; i++) {
                this.$refs[`editingcanvas${i}`][0].changeSize(pixelCount);
            }
        },

        /**
         *  UI Functions
         */
        setLoading(status, msg) {
            this.loadingMessage = msg ? msg : "Dokument wird geladen...";
            this.loadingActive = status;
        },
        scrolledEvent(vertical, horizontal, nativeEvent) {
            // Currently a placeholder, might come in use for drag & drop & scroll
            this.currentPageIndex = (vertical.scrollTop - (vertical.scrollTop % this.canvasHeight)) / this.canvasHeight;
        },
        changeZoom(direction) {
            for (let i = 0; i < this.pages.length; i++) {
                this.$refs[`editingcanvas${i}`][0].$el.style.transform = "";
            }
            if (direction === "minus" && this.currentZoom > 0) {
                this.currentZoom--;
            } else if (direction === "plus" && this.currentZoom < 10) {
                this.currentZoom++;
            }

            setTimeout(() => {
                for (let i = 0; i < this.pages.length; i++) {
                    this.$refs[`editingcanvas${i}`][0].$el.style.transform = `scale(${
                        1 + this.currentZoom / 5
                    }, ${1 + this.currentZoom / 5})`;
                    this.$refs[
                        `editingcanvas${i}`
                        ][0].$el.style.transformOrigin = `left top`;
                }
                this.$nextTick(this.$refs.scrollbar.refresh());
                this.$nextTick(this.$refs.scrollbar.refreshInternalStatus());
            }, 150);
        },
        changePDFTextLayerVisibility() {
            console.log('changePDFTextLayerVisibility called');
            const intervalFunction = () => {
                let selection = {...this.selectedText};
                if (window.getSelection) {
                    selection = window.getSelection();
                }
                // document.getSelection
                else if (document.getSelection) {
                    selection = document.getSelection();
                }
                // document.selection
                else if (document.selection) {
                    selection =
                        document.selection.createRange().text;
                }
                if (selection.focusNode && selection.focusNode.parentNode) {
                    this.lastSelectedTextNode = selection.focusNode.parentNode;
                }

                if (selection.toString() !== this.selectedText) {
                    this.update = !this.update;
                }
                this.selectedText = selection.toString();

            };

            this.isTextLayerVisible = !this.isTextLayerVisible;
            this.textSelectionInterval =
                this.textSelectionInterval == null
                    ? setInterval(intervalFunction, 100) : clearInterval(this.textSelectionInterval);

            if (!this.isTextLayerVisible) {
                this.selectedText = null;
                this.lastSelectedTextNode = null;
            } else {
                setTimeout(() => {
                    for (let i = 0; i < this.pages.length; i++) {
                        this.$refs[`editingcanvas${i}`][0].calculateParagraphPositions();
                    }
                }, 250);
                this.textLayerActivatedSnackbar = true;
            }
        },
        checkForScrollbarStylingUpdates(scrollbarParent) {
            if (scrollbarParent.classList.contains("hasVBar")) {
                if (!this.isVerticalScrollbarStyled) {
                    this.scrollbarStyling.styleVerticalScrollbar();
                    this.isVerticalScrollbarStyled = true;
                }
            } else {
                this.isVerticalScrollbarStyled = false;
            }

            if (scrollbarParent.classList.contains("hasHBar")) {
                if (!this.isHorizontalScrollbarStyled) {
                    this.scrollbarStyling.styleHorizontalScrollbar();
                    this.isHorizontalScrollbarStyled = true;
                }
            } else {
                this.isHorizontalScrollbarStyled = false;
            }
        },

        /**
         * Text To Speech Functions (might be moved to util)
         */
        async readText(text, callback) {
            console.log(' readText called');
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
            }

            let msgText = text;

            if (this.translate) {
                msgText = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: text
                });

                this.setTranslatedText(msgText);
                this.showTranslation(true);
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
                this.speakerIcon = this.lautsprecherIcon;
                callback(this.lautsprecherIcon, false);

                if (this.currentMessage.text === msgText) {
                    this.currentMessage = null;
                    this.showTranslation(false);
                    return;
                }
            }

            let msg = new SpeechSynthesisUtterance();
            msg.text = msgText;
            msg.lang = this.translate ? this.targetLang : this.currentLang;
            if (this.isLangPackageAvailable()) {
                window.speechSynthesis.speak(msg);
                this.currentMessage = msg;
                this.speakerIcon = this.lautsprecherAusIcon;
                callback(this.lautsprecherAusIcon, true);
            }

            msg.onend = () => {
                this.speakerIcon = this.lautsprecherIcon;
                this.currentMessage = null;
                callback(this.lautsprecherIcon, false);
            };
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.currentLang)) {
                    return true;
                }
            }

            return false;
        },
        changeLang(newLang, newLangImg) {

            this.currentTTSLangImg = newLangImg;
            this.setTargetLang(newLang);
            this.currentLang = newLang;
        },
        changeStamp(stempel) {

            localStorage.setItem('editStempel', stempel);
            if (this.pages.length === 1) {
                this.$refs[`editingcanvas0`][0].setStempelImg(stempel);
            } else {
                for (let i = 0; i < this.pages.length; i++) {
                    this.$refs[`editingcanvas${i}`][0].setStempelImg(stempel);
                }
            }
            if (this.currentActiveMethod !== 'stempel') {
                this.setTool('stempel');
            }
        },

        /**
         * File Functions
         */
        async requestFile(userGroup, fileType) {
            const response = await this.pullCurrentFile({
                userGroup,
                fileType,
                fileName: this.assignmentName,
                appointmentId: this.appointmentId,
                fileId: this.fileId,
            });

            if (response &&
                (response.status === 200 ||
                    response.status === 201 ||
                    response.status === 204)) {
                if (response.file) {
                    this.currentFile = response.file;
                    if (this.getCurrentFileType === "pdf") {
                        await this.computeLoadedPDF(this.currentFile);
                    } else {
                        await this.$refs[`editingcanvas0`][0].setBackgroundImg(
                            this.currentFile
                        );
                    }
                } else {
                    if (response.error) {
                        this.snackbars.genericError = true;
                    }
                    this.setLoading(false);
                }
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.snackbars.fileNotAllowed = true;
                } else if (response.status === 404) {
                    this.snackbars.fileNotAvailable = true;
                } else {
                    this.snackbars.genericError = true;
                }
                this.setLoading(false);
            }
        },
        async computeLoadedPDF(pdfFile) {
            const pdfDocumentLoadingTask = pdfjs.getDocument({data: pdfFile});
            const pdfDocument = await pdfDocumentLoadingTask.promise;

            const pagePromises = Array.from(Array(pdfDocument.numPages).keys()) // Create array from [0 ... countPages]
                .map((element) => element + 1) // Add 1 to each element to get all page numbers
                .map((page) => pdfDocument.getPage(page));
            const resolvedPages = await Promise.all(pagePromises);
            console.log("ResolvedPages", resolvedPages);
            this.pages = resolvedPages;

            for (let i = 0; i < resolvedPages.length; i++) {
                const nameString = `editingcanvas${i}`.toString();
                await this.$refs[nameString][0].setBackgroundPDF(resolvedPages[i]);
            }
        },
        spawnTextOnCanvas() {
            if (this.pages.length === 1) {
                this.$refs[`editingcanvas0`][0].spawnText(null, null);
            } else {
                const scrolledDistance = this.$refs.scrollbar.getPosition().scrollTop;
                const canvasIndex = (scrolledDistance - (scrolledDistance % this.canvasHeight)) / this.canvasHeight;
                this.$refs[`editingcanvas${canvasIndex}`][0].spawnText(null, null);
            }
        },

        /**
         * Region Editor Elements (Audio, Video, Text)
         */
        audioMessageYAxis(message) {
            if (this.pages.length === 1) {
                return message.y * (1 + this.currentZoom / 5);
            } else {
                return (
                    (message.page / this.pages.length) * 100 +
                    message.y * (1 / this.pages.length)
                );
            }
        },
        htmlObjectYAxis(object) {
            if (this.pages.length === 1) {
                return object.y * (1 + this.currentZoom / 5);
            } else {
                return (
                    (object.page / this.pages.length) * 100 +
                    object.y * (1 / this.pages.length) * (1 + this.currentZoom / 5)
                );
            }
        },
        spawnAudioObject() {
            if (this.pages.length === 1) {
                this.$refs[`editingcanvas0`][0].spawnAudio();
            } else {
                let canvasIndex = this.getCanvasIndex();
                this.$refs[`editingcanvas${canvasIndex}`][0].spawnAudio();
            }
        },
        spawnTextObject(text) {
            this.tempTextMessage = text;
            if (this.pages.length === 1) {
                this.$refs[`editingcanvas0`][0].spawnTextMessage();
            } else {
                let canvasIndex = this.getCanvasIndex();
                this.$refs[`editingcanvas${canvasIndex}`][0].spawnTextMessage();
            }
        },
        placeNewImage(url, blob) {
            let canvasIndex = this.getCanvasIndex();
            this.imageList.push({
                x: 50,
                y: 25,
                page: canvasIndex,
                fileUrl: url,
                fileBlob: blob,
                text: blob.name,
                width: '300',
                height: '200',
                tabIndex: this.tabOrderArray.length
            });
            this.tabOrderArray.push(this.imageList[this.imageList.length - 1]);
        },
        placeNewVideo(url, blob) {
            let canvasIndex = this.getCanvasIndex();
            this.videoList.push({
                x: 50,
                y: 25,
                page: canvasIndex,
                fileUrl: url,
                fileBlob: blob,
                width: '300',
                height: '200',
                tabIndex: this.tabOrderArray.length
            });
            this.tabOrderArray.push(this.videoList[this.videoList.length - 1]);
        },
        placeNewEditorMessage(page, top, left) {
            this.messageList.push({x: left * 100, y: top * 100, page, tabIndex: this.tabOrderArray.length});
            this.tabOrderArray.push(this.messageList[this.messageList.length - 1]);
        },
        placeNewEditorTextMessage(page, top, left) {
            this.textMessageList.push({
                x: left * 100,
                y: top * 100,
                page,
                text: this.tempTextMessage,
                tabIndex: this.tabOrderArray.length,
            });
            this.tabOrderArray.push(this.textMessageList[this.textMessageList.length - 1]);
        },
        placeNewEditorTextTask(page, top, left) {
            if (this.pages.length === 1) {
                this.textTaskList.push({
                    x: left * 100,
                    y: top * 100,
                    page,
                    width: 0.85,
                    height: 200 / this.canvasHeight,
                    tabIndex: this.tabOrderArray.length
                });
            } else {
                let canvasIndex = this.getCanvasIndex();
                this.textTaskList.push({
                    x: left * 100,
                    y: top * 100,
                    page: canvasIndex,
                    width: 0.85,
                    height: 200 / this.canvasHeight,
                    tabIndex: this.tabOrderArray.length
                });
            }
            this.tabOrderArray.push(this.textTaskList[this.textTaskList.length - 1]);
            console.log('Aufgabe', this.tabOrderArray);
        },
        placeNewEditorAnswerField(page, top, left) {
            if (this.pages.length === 1) {
                this.textAnswerList.push({
                    x: left * 100,
                    y: top * 100,
                    page,
                    tabIndex: this.tabOrderArray.length
                });
            } else {
                let canvasIndex = this.getCanvasIndex();
                this.textAnswerList.push({
                    x: left * 100,
                    y: top * 100,
                    page: canvasIndex,
                });
            }
            this.tabOrderArray.push(this.textAnswerList[this.textAnswerList.length - 1]);
            console.log('Antwort', this.tabOrderArray);
        },
        placeNewMultipleChoice(title, answers, sizeIndex = 0) {
            let canvasIndex = this.getCanvasIndex();
            this.multipleChoices.push({
                x: 25,
                y: 50,
                width: sizeIndex,
                page: canvasIndex,
                startTextTask: title,
                multipleChoices: answers,
                tabIndex: this.tabOrderArray.length,
            });
            this.tabOrderArray.push(this.multipleChoices[this.multipleChoices.length - 1]);
        },
        async addNewPage(type) {
            const index = this.pages.push(this.pages.length) - 1;
            // Wait for the next tick, so the page has been added and a new canvas is created
            this.$nextTick(async () => {
                await this.$refs[`editingcanvas${index}`][0].setEmptyBackground(type, this.canvasWidth, this.canvasHeight);
                this.pageAdded = true;
            });
        },
        addMoveableText(value, size) {
            let canvasIndex = this.getCanvasIndex();
            this.moveableTexts.push({
                x: 50,
                y: 50,
                width: size,
                page: canvasIndex,
                text: value,
                tabIndex: this.tabOrderArray.length
            });
            this.tabOrderArray.push(this.moveableTexts[this.moveableTexts.length - 1]);
        },
        addRectangle(color, border) {
            let canvasIndex = this.getCanvasIndex();
            const lineIndex = this.rectangles.length - 1;
            this.rectangles.push({
                // Use the last rectangles' position as new position
                x: this.lastFormSpecs.rectangle.x + 2,
                y: this.lastFormSpecs.rectangle.y + 2,
                width: this.lastFormSpecs.rectangle.width,
                height: this.lastFormSpecs.rectangle.height,
                text: color,
                startTextTask: (border ? border : ''),
                page: canvasIndex
            });
        },
        addLine(color){
            let canvasIndex = this.getCanvasIndex();
            this.lines.push({
                // Use the last lines' position as new position
                x: this.lastFormSpecs.line.x + 2,
                y: this.lastFormSpecs.line.y + 2,
                width: this.lastFormSpecs.line.width,
                height: this.lastFormSpecs.line.height,
                rotation: this.lastFormSpecs.line.rotation,
                text: color,
                page: canvasIndex
            });
        },
        getCanvasIndex() {
            let canvasIndex = 0;
            if (this.pages.length > 1) {
                const scrolledDistance = this.$refs.scrollbar.getPosition().scrollTop;
                canvasIndex = (scrolledDistance - (scrolledDistance % this.canvasHeight)) / this.canvasHeight;
            }
            return canvasIndex;
        },
        updateTextTaskPosition(task, coords) {
            let newXAdditive = 0,
                newYAdditive = 0;
            if (navigator.vendor === "Apple Computer, Inc.") {
                newXAdditive = (window.innerWidth - this.canvasWidth) / 2;
                newYAdditive = 100;
            }

            const newX =
                task.x + ((coords.left - newXAdditive) / this.canvasWidth) * 100;
            const newY =
                task.y + ((coords.top - newYAdditive) / this.canvasHeight) * 100;

            if (newX > 0 && newX < 100 && newY > 0 && newY < 100) {
                task.x = newX;
                task.y = newY;
            }
        },

        /** no longer in use
         * @deprecated
         */
        updateAnswerEntryPosition(answerEntry, coords) {
            const newX =
                /*answerEntry.x +*/ ((coords.left) / this.canvasWidth) *
                100;
            const newY =
                /*answerEntry.y +*/ ((coords.top) / this.canvasHeight) *
                100;
            console.log(
                "canvasWidth and Height",
                this.canvasWidth,
                this.canvasHeight
            );
            console.log(
                "percentage added width and height",
                (coords.left / this.canvasWidth) * 100,
                (coords.top / this.canvasHeight) * 100
            );
            console.log("X and Y", newX, newY);

            if (newX > -10 && newX < 110 && newY > -10 && newY < 110) {
                answerEntry.x = newX;
                answerEntry.y = newY;
            }
        },
        updateTextTaskDimension(task, dimensions) {
            const widthPercentage = dimensions.width / this.canvasWidth;
            const heightPercentage = dimensions.height / this.canvasHeight;

            task.width = widthPercentage;
            task.height = heightPercentage;
        },
        updateAnswerFieldDimension(answer, dimensions) {
            console.log(dimensions);
            const widthPercentage = dimensions.width / this.canvasWidth;
            const heightPercentage = dimensions.height / this.canvasHeight;

            answer.width = widthPercentage;
            answer.height = heightPercentage;
            console.log(answer);
        },

        deleteTextTask(task) {
            const taskIndex = this.textTaskList.findIndex((item) => item === task);
            if (taskIndex >= 0) {
                this.textTaskList.splice(taskIndex, 1);
            }
        },
        deleteAnswerField(index) {
            this.textAnswerList.splice(index, 1);
        },
        async saveNewPage(newFileName, selectedUser, startDate, isAssignment, base64) {
            try {
                const response = await this.safeNewPdfFile({
                    data: base64,
                    appointmentId: this.appointmentId,
                    fileName: newFileName,
                    params: {selectedUser, startDate, isAssignment},
                    userGroup: 'teacher'
                });
                console.log("Status", response);

                response.addEventListener('load', async (e) => {
                    if (response.status === 200
                        || response.status === 201
                        || response.status === 202
                        || response.status === 204) {
                        const responseJson = JSON.parse(response.response);
                        this.fileId = responseJson._id;
                        await this.safeAllEditorMessages();
                    } else {
                        this.snackbars.dataCouldntBeSaved = true;
                        this.setLoading(false);
                    }
                });
            } catch (e) {
                console.error(e);
                this.snackbars.dataCouldntBeSaved = true;
                this.setLoading(false);
            }
        },
        async handleSaveClick() {
            const data = await this.convertPagesToPdf();

            // Check if something is drawn on canvases
            let drawings = [];
            for (let i = 0; i < this.pages.length; i++) {
                drawings = drawings.concat(this.$refs[`editingcanvas${i}`][0].filterHistoryForAdded);
            }
            const needsRedrawing = this.pageAdded || drawings.length !== 0;

            this.saveFunction(data, this.appointmentId, this.fileId, this.assignmentName, needsRedrawing);
        },
        async convertPagesToPdf() {
            const images = [];
            for (let i = 0; i < this.pages.length; i++) {
                images.push(this.$refs[`editingcanvas${i}`][0].canvasAsImage);
            }
            const pdfDoc = await PDFDocument.create();
            for (let i = 0; i < images.length; i++) {

                const embeddableImage = await fetch(images[i]).then(res => res.arrayBuffer());

                const page = pdfDoc.addPage([this.canvasWidth, this.canvasHeight]);

                const pngImage = await pdfDoc.embedPng(embeddableImage);

                page.drawImage(pngImage, {
                    x: 0,
                    y: 0,
                    width: this.canvasWidth,
                    height: this.canvasHeight,
                });
            }
            return await pdfDoc.save();
        },
        setNewValuesForEmptyPage(newName, fileId) {
            this.assignmentName = newName;
            this.fileId = fileId;
        },

        async safeAllEditorMessages(fileId = this.fileId) {
            console.log('Speichern: ', this.tabOrderArray);

            if (this.account.role === "teacher" && !this.$route.query.correction) {
                // if (this.messageList.filter((el) => !el.startFile && !el.start).length > 0) {
                //     return false;
                // }
                const messages = [];
                for (let i = 0; i < this.messageList.length; i++) {
                    const message = {
                        x: this.messageList[i].x,
                        y: this.messageList[i].y,
                        page: this.messageList[i].page,
                        messageType: "audio",
                        start: this.messageList[i].start,
                        answer: null,
                        correction: null,
                        tabIndex: this.messageList[i].tabIndex,
                    };
                    if (this.messageList[i].startFile) {
                        this.messageList[i].start = await this.uploadRecordedMessage(
                            this.messageList[i].startFile,
                            this.fileId + ".wav"
                        );
                        message.start = this.messageList[i].start._id;
                    }

                    messages.push(message);
                }
                for (let i = 0; i < this.textMessageList.length; i++) {
                    const message = {
                        x: this.textMessageList[i].x,
                        y: this.textMessageList[i].y,
                        page: this.textMessageList[i].page,
                        messageType: "text",
                        text: this.textMessageList[i].text,
                        tabIndex: this.textMessageList[i].tabIndex,
                    };
                    messages.push(message);
                }

                for (let i = 0; i < this.textTaskList.length; i++) {
                    console.log(this.textTaskList[i]);
                    const message = {
                        x: this.textTaskList[i].x,
                        y: this.textTaskList[i].y,
                        page: this.textTaskList[i].page,
                        messageType: "texttask",
                        width: this.textTaskList[i].width,
                        height: this.textTaskList[i].height,
                        startTextTask: this.textTaskList[i].startTextTask,
                        tabIndex: this.textTaskList[i].tabIndex,
                    };
                    messages.push(message);
                }

                for (let i = 0; i < this.textAnswerList.length; i++) {
                    const message = {
                        x: this.textAnswerList[i].x,
                        y: this.textAnswerList[i].y,
                        page: this.textAnswerList[i].page,
                        messageType: "answerField",
                        width: this.textAnswerList[i].width,
                        height: this.textAnswerList[i].height,
                        author: this.textAnswerList[i].author,
                        tabIndex: this.textAnswerList[i].tabIndex,
                    };
                    messages.push(message);
                }

                // Add the images
                for (const img of this.imageList) {
                    const message = {
                        x: img.x,
                        y: img.y,
                        width: img.width,
                        height: img.height,
                        page: img.page,
                        start: img.start,
                        text: img.text,
                        messageType: 'imageObject',
                        tabIndex: img.tabIndex,
                    };
                    // Image is new and needs to be uploaded to the server
                    if (!message.start) {
                        let res = await backend.postUploadMessage(img.fileBlob);
                        res = await res.json();
                        message.start = res._id;
                    }
                    messages.push(message);
                }
                // Add the videos
                for (const video of this.videoList) {
                    const message = {
                        x: video.x,
                        y: video.y,
                        width: video.width,
                        height: video.height,
                        page: video.page,
                        start: video.start,
                        messageType: 'videoObject',
                        tabIndex: video.tabIndex,
                    };
                    // Video is new and needs to be uploaded to the server
                    if (!message.start) {
                        let res = await backend.postUploadMessage(video.fileBlob);
                        res = await res.json();
                        message.start = res._id;
                    }
                    messages.push(message);
                }
                // Add the multiple choices
                for (const mChoice of this.multipleChoices) {
                    // Copy the data, so we don't change the actual object
                    let data = mChoice.multipleChoices;
                    if (this.account.role === 'teacher') {
                        // Remove the checks on the teacher side
                        data.forEach(m => {
                            m.checked = false;
                        });
                    }
                    const message = {
                        x: mChoice.x,
                        y: mChoice.y,
                        width: mChoice.width,
                        page: mChoice.page,
                        startTextTask: mChoice.startTextTask,
                        multipleChoices: data,
                        messageType: 'multipleChoice',
                        tabIndex: mChoice.tabIndex,
                    };
                    messages.push(message);
                }
                // Add the moveable texts
                for (const text of this.moveableTexts) {
                    const message = {
                        x: text.x,
                        y: text.y,
                        width: text.width,
                        page: text.page,
                        text: text.text,
                        messageType: 'moveableText',
                        tabIndex: text.tabIndex,
                    };
                    messages.push(message);
                }
                // Add the rectangles
                for (const rect of this.rectangles) {
                    const message = {
                        x: rect.x,
                        y: rect.y,
                        width: rect.width,
                        height: rect.height,
                        page: rect.page,
                        text: rect.text,
                        startTextTask: rect.startTextTask,
                        messageType: 'rectangle',
                    };
                    messages.push(message);
                }
                // Add the lines
                for (const line of this.lines) {
                    const message = {
                        x: line.x,
                        y: line.y,
                        width: line.width,
                        height: line.height,
                        page: line.page,
                        text: line.text,
                        rotation: line.rotation,
                        messageType: 'line',
                    };
                    messages.push(message);
                }

                if (messages.length) {
                    console.log(messages);
                    const response = await this.createUploadMessages({
                        file: fileId,
                        points: messages,
                    });
                    console.log("response", response);
                    if (
                        response._id ||
                        !response ||
                        (Number.isInteger(response) && response < 205)
                    ) {
                        // got success response
                        this.snackbars.audioMessagesSaved = true;
                    } else {
                        this.snackbars.genericError = true;
                    }
                }

                return true;
            }
        },
        handleFormSpecsChanged(type, data){
            if(this.lastFormSpecs[type]){
                this.lastFormSpecs[type].width = data.width;
                this.lastFormSpecs[type].height = data.height;
                this.lastFormSpecs[type].x = data.x;
                this.lastFormSpecs[type].y = data.y;
                this.lastFormSpecs[type].rotation = data.rotation ? data.rotation : 0;
            }
        },
        sortIncomingMessages(messages) {

            // Fill tab order array
            this.tabOrderArray = [];
            const indexedMessages = messages.filter(item => item.tabIndex);
            for (let i = 0; i < indexedMessages.length; i++) {
                this.tabOrderArray[indexedMessages[i].tabIndex] = indexedMessages[i];
            }
            console.log(this.tabOrderArray);

            this.messageList = messages.filter((item) => item.messageType === "audio" || !item.messageType);
            // console.log("messageList", this.messageList);
            this.textMessageList = messages.filter((item) => item.messageType === "text");
            // console.log("textMessageList", this.textMessageList);
            this.textTaskList = messages.filter((item) => item.messageType === "texttask");
            // console.log("textTaskList", this.textTaskList);
            this.textAnswerList = messages.filter((item) => item.messageType === "answerField");
            // console.log("textAnswerList", this.textAnswerList);
            this.imageList = messages.filter(item => item.messageType === 'imageObject');
            // console.log('imageList', this.imageList);
            this.videoList = messages.filter(item => item.messageType === 'videoObject');
            // console.log('videoList', this.videoList);
            this.multipleChoices = messages.filter(item => item.messageType === 'multipleChoice');
            // console.log('videoList', this.videoList);
            this.moveableTexts = messages.filter(item => item.messageType === 'moveableText');
            this.rectangles = messages.filter(item => item.messageType === 'rectangle');

            // Bugfix: Checks width and height of old rectangles > ONLY NEEDED DURING TRANSITION
            for (let i = 0; i < this.rectangles.length; i++) {
                if (this.rectangles[i].width > 2) {
                    this.rectangles[i].width = this.rectangles[i].width / this.canvasWidth;
                }
                if (this.rectangles[i].height > 2) {
                    this.rectangles[i].height = this.rectangles[i].height / this.canvasHeight;
                }
            }

            this.lines = messages.filter(item => item.messageType === 'line');
        },
        async uploadRecordedMessage(file, nameForCompleteness) {
            if (file) {
                let file2 = new File([file], nameForCompleteness, {
                    type: "audio/wav",
                });
                const res = await backend.postUploadMessage(file2);
                return await res.json();
            }
        },

        deleteMessage(index) {
            this.messageList.splice(index, 1);
        },
        deleteTextNote(index) {
            this.textMessageList.splice(index, 1);
        },

        /**
         * General
         */
        async close() {
            // await this.safeAllEditorMessages();
            this.$router.back();
        },
    },

}
</script>

<style scoped lang="scss">
@import "~@/styles/globals.scss";

#headerText {
    color: white;
    font-size: x-large;
    font-weight: bold;

    /*max-width: calc(100% - 70px);*/
    /*min-width: calc(100% - 70px);*/
    width: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    float: left !important;
}

.headerTeacherText {
    /*max-width: calc(100% - 325px) !important;*/
    /*min-width: calc(100% - 325px) !important;*/
}

#closeBtn {
    background-color: #e6221e80;
    width: 49px;
    height: 49px;

    @media (max-width: 380px) {
        width: 40px !important;
        height: 40px !important;
        margin-right: 0 !important;
    }
}

.toolBtn {
    background-color: #555555;
    height: 49px !important;

    @media (max-width: 380px) {
        height: 40px !important;
        margin-right: 0 !important;

        p {
            display: none;
        }
    }
}

.greenBtn {
    background-color: #8cbd46;
    color: white;

    img {
        filter: brightness(1000%);
    }
}

.toolSelector {
    background-color: #f8f8f880;
    width: 49px !important;
    height: 49px !important;

    @media (max-width: 380px) {
        width: 40px !important;
        height: 40px !important;
    }
}

.zoomControl {
    position: fixed;
    z-index: 8 !important;
    top: 95px;
    left: 20px;
    background-color: #f8f8f8 !important;
    width: 49px !important;
    height: 49px !important;
    border-radius: 8px;

    p {
        line-height: 0;
        font-size: 60px;
        margin-bottom: 0;
        font-weight: 300;
        color: #333;
    }

    @media (max-width: 380px) {
        width: 40px !important;
        height: 40px !important;

        p {
            font-size: 50px;
        }
    }
}

#textLayerButton {

    img {
        filter: brightness(0);
    }
}

.textLayerActive {
    filter: invert(0.8);
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}

.focusMe {
    /* Fixes the position offset when selecting an object */
    margin-top: -2px !important;
    margin-left: -2px !important;
    border: 2px solid red;
}

.notesButtonsImage {
    height: 30px;
    filter: brightness(0%);
}
</style>
