<template>
  <v-row class="actionbar hidden-print">
    <v-btn tabindex="-1" class="ml-2" color="primary" small @click="clickLoginSimple">Alice</v-btn>
    <v-btn tabindex="-1" class="ml-2" color="primary" small @click="clickLoginPupil">Monika</v-btn>
    <v-btn tabindex="-1" class="ml-2" color="primary" small @click="clickLoginParent">Ben</v-btn>
    <v-btn tabindex="-1" class="ml-2" color="primary" small @click="clickLoginTeacher">Kim</v-btn>
    <v-btn tabindex="-1" class="ml-2" color="primary" small @click="clickLoginMaintainer">Verwaltung</v-btn>
    <v-btn tabindex="-1" class="ml-2" color="primary" small @click="clickLogoutUser">Logout</v-btn>
  </v-row>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
// v-if="rocess.env.NODE_ENV === 'development'"
export default {
  name: "UserSwitch",
  data: () => ({
    userName: "Verwaltung",
    password: "rb5n-7uxa-4mfp",
  }),
  props: {},
  computed: {},
  methods: {
    ...mapActions("auth", ["loginUser", "logoutUser"]),
    clickLoginTeacher() {
      this.loginUser({ accountName: "Kim", password: "Kim" });
    },
    clickLoginMaintainer() {
      this.loginUser({ accountName: "Verwaltung", password: "rb5n-7uxa-4mfp" });
    },
    clickLoginPupil() {
      this.loginUser({ accountName: "Monika", password: "Monika" });
    },
      clickLoginSimple() {
      this.loginUser({ accountName: "Alice", password: "Alice" });
    },
      clickLoginParent() {
      this.loginUser({ accountName: "Ben", password: "Ben" });
    },

    clickLogoutUser() {
      this.logoutUser();
    },
  },
};
</script>
<style scoped>
.actionbar {
  position: fixed;
  bottom: 8px;
  right: 16px;
  z-index: 1;
}

@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    .actionbar {
        bottom: 78px;
    }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}
</style>
