const defaultState = {
  faq: false,
};

const mutations = {
  setFaq(state, value) {
    state.faq = value;
  }
};


const actions = {
  toggleFaq({ commit, state }) {
    commit("setFaq", !state.faq);
  },
  closeFaq({ commit, state }) {
    commit("setFaq", false);
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
