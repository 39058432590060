import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';


const defaultState = {
};

const mutations = {
};


const actions = {

  async getMaintainers ({ commit, dispatch }) {

    try {
      const res = await backend.getMaintainers();
      await checkResponseStatus(200, res);
      return  await res.json();
    }
    catch(err) {
      return err.response.status;
    }
  },


};


export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
