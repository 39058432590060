var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.showLoading)?_c('v-progress-linear',{staticStyle:{"position":"fixed","top":"0 !important"},attrs:{"indeterminate":"","buffer-value":"100","color":_vm.backendReachable ? 'primary' : 'error',"height":"10"}}):_vm._e(),_c('progress-bar',{attrs:{"title":"Hochladen","show-progress":_vm.showPupilUploadProgress,"progress":_vm.pupilUploadProgress,"abort-progress":_vm.pupilXmlHttpRequest ? function () { _vm.pupilXmlHttpRequest.abort() }: function () {}}}),_c('label',{staticStyle:{"display":"none"},attrs:{"for":"uploadInput"}},[_vm._v("Datei hochladen")]),_c('input',{ref:"uploadInput",attrs:{"id":"uploadInput","type":"file","hidden":"","label":"Datei hochladen"},on:{"change":function () { return _vm.uploadInputChange(); }}}),_c('name-file',{attrs:{"original-name":_vm.uploadFileName,"callback":_vm.fileNameCallback,"isSubtitleReady":_vm.isSubtitleReady,"group":_vm.currentlyOpenAppointment ? _vm.currentlyOpenAppointment.group : null},model:{value:(_vm.showNameFile),callback:function ($$v) {_vm.showNameFile=$$v},expression:"showNameFile"}}),(_vm.developmentMode)?_c('UserSwitch',{staticStyle:{"z-index":"5"}}):_vm._e(),_c('router-view'),_c('v-snackbar',{attrs:{"timeout":"5000","color":"success"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","small":""},on:{"click":function($event){_vm.fileSnackbars.dataSaved = false}}},'v-btn',attrs,false),[_c('img',{staticStyle:{"max-width":"20px"},attrs:{"alt":"Schließen","src":_vm.closeIcon}})])]}}]),model:{value:(_vm.fileSnackbars.dataSaved),callback:function ($$v) {_vm.$set(_vm.fileSnackbars, "dataSaved", $$v)},expression:"fileSnackbars.dataSaved"}},[_vm._v(" Ergebnisse gespeichert. ")]),_c('v-snackbar',{attrs:{"timeout":"5000","color":"success"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","small":""},on:{"click":function($event){_vm.fileSnackbars.dataSubmitted = false}}},'v-btn',attrs,false),[_c('img',{staticStyle:{"max-width":"20px"},attrs:{"alt":"Schließen","src":_vm.closeIcon}})])]}}]),model:{value:(_vm.fileSnackbars.dataSubmitted),callback:function ($$v) {_vm.$set(_vm.fileSnackbars, "dataSubmitted", $$v)},expression:"fileSnackbars.dataSubmitted"}},[_vm._v(" Dein Aufschrieb ist abgegeben. Gut gemacht! ")]),_c('v-snackbar',{attrs:{"timeout":"5000","color":"success"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","small":""},on:{"click":function($event){_vm.snackbarSave = false}}},'v-btn',attrs,false),[_c('img',{staticStyle:{"max-width":"20px"},attrs:{"alt":"Schließen","src":_vm.closeIcon}})])]}}]),model:{value:(_vm.snackbarSave),callback:function ($$v) {_vm.snackbarSave=$$v},expression:"snackbarSave"}},[_vm._v(" Gespeichert! ")]),_c('v-snackbar',{attrs:{"timeout":"5000","color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","small":""},on:{"click":function($event){_vm.snackbarGenericError = false}}},'v-btn',attrs,false),[_c('img',{staticStyle:{"max-width":"20px"},attrs:{"alt":"Schließen","src":_vm.closeIcon}})])]}}]),model:{value:(_vm.snackbarGenericError),callback:function ($$v) {_vm.snackbarGenericError=$$v},expression:"snackbarGenericError"}},[_vm._v(" Ein Fehler ist aufgetreten. ")]),_c('v-snackbar',{attrs:{"timeout":"5000","color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","small":""},on:{"click":function($event){_vm.fileSnackbars.fileToBig = false}}},'v-btn',attrs,false),[_c('img',{staticStyle:{"max-width":"20px"},attrs:{"alt":"Schließen","src":_vm.closeIcon}})])]}}]),model:{value:(_vm.fileSnackbars.fileToBig),callback:function ($$v) {_vm.$set(_vm.fileSnackbars, "fileToBig", $$v)},expression:"fileSnackbars.fileToBig"}},[_c('span',[_vm._v("Datei zu groß, max. 5 MB")])]),_c('v-snackbar',{attrs:{"timeout":"5000","color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","small":""},on:{"click":function($event){_vm.fileSnackbars.bigFileToBig = false}}},'v-btn',attrs,false),[_c('img',{staticStyle:{"max-width":"20px"},attrs:{"alt":"Schließen","src":_vm.closeIcon}})])]}}]),model:{value:(_vm.fileSnackbars.bigFileToBig),callback:function ($$v) {_vm.$set(_vm.fileSnackbars, "bigFileToBig", $$v)},expression:"fileSnackbars.bigFileToBig"}},[_c('span',[_vm._v("Datei zu groß, max. 1 GB")])]),_c('v-snackbar',{attrs:{"timeout":"5000","color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","small":""},on:{"click":function($event){_vm.fileSnackbars.fileDoesntFitInPrivateFolder = false}}},'v-btn',attrs,false),[_c('img',{staticStyle:{"max-width":"20px"},attrs:{"alt":"Schließen","src":_vm.closeIcon}})])]}}]),model:{value:(_vm.fileSnackbars.fileDoesntFitInPrivateFolder),callback:function ($$v) {_vm.$set(_vm.fileSnackbars, "fileDoesntFitInPrivateFolder", $$v)},expression:"fileSnackbars.fileDoesntFitInPrivateFolder"}},[_c('span',[_vm._v("Nicht genügend Platz im privaten Ordner")])]),_c('v-snackbar',{attrs:{"timeout":"5000","color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","small":""},on:{"click":function($event){_vm.snackbarPrivateFull = false}}},'v-btn',attrs,false),[_c('img',{staticStyle:{"max-width":"20px"},attrs:{"alt":"Schließen","src":_vm.closeIcon}})])]}}]),model:{value:(_vm.snackbarPrivateFull),callback:function ($$v) {_vm.snackbarPrivateFull=$$v},expression:"snackbarPrivateFull"}},[_vm._v(" Speicher überschritten. Datei konnte nicht gespeichert werden ")]),_c('v-snackbar',{attrs:{"timeout":"10000","color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","small":""},on:{"click":function($event){_vm.showBackendUnreachable = false}}},'v-btn',attrs,false),[_c('img',{staticStyle:{"max-width":"20px"},attrs:{"alt":"Schließen","src":_vm.closeIcon}})])]}}]),model:{value:(_vm.showBackendUnreachable),callback:function ($$v) {_vm.showBackendUnreachable=$$v},expression:"showBackendUnreachable"}},[_vm._v(" Überprüfe deine Internetverbindung oder es veruche später noch einmal. Die Server sind gerade nicht erreichbar. ")]),(_vm.showTranslatedText)?_c('v-card',{staticStyle:{"background-color":"#8cbe46"},attrs:{"id":"translatedTextShow","type":"info"}},[_c('v-card-text',{staticClass:"py-2",staticStyle:{"height":"100%","text-align":"center"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('p',{staticClass:"mb-0 pb-2 mx-auto",staticStyle:{"color":"white !important","overflow-y":"auto"},attrs:{"id":"translatedTextText"},domProps:{"innerHTML":_vm._s(_vm.urlify(_vm.translatedText, true))}}),_c('v-icon',{staticClass:"mr-1",attrs:{"role":"button","aria-label":"Schließen","large":"","dark":"","color":"white"},on:{"click":function($event){return _vm.showTranslation(false)}}},[_vm._v(" fas fa-times ")])],1)])],1):_vm._e(),_c('keyboard'),(_vm.magnifier)?_c('magnifier'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }