<template>
    <div>
        <v-row class="mx-0">
            <div class="whiteBg" style="height: auto; width: 90%; min-height: 80vh; border-radius: 15px; box-shadow: 1px 5px 5px silver; margin: auto auto 5em;">

                <!--Header of Stundenplan-->
                <div style="background-color: #6995CD; border-radius: 15px 15px 0 0; padding-top: 10px; padding-bottom: 10px; color: white;">
                    <v-row v-if="this.windowWidth > 600" style="max-width: 100%;" class="mx-2 d-flex align-center justify-space-between">
                        <v-col cols="9" md="8" lg="8" class="pa-0 pl-2 d-flex align-center" style="font-size: larger">
                            <img :src="stundenplanIcon" class="icon iconToWhite mr-1"/>
                            Stundenplan
                        </v-col>

                        <div class="d-flex justify-end align-center">
                            <div class="pa-0 calWeek" style="font-size: medium">
                                KW {{ getMonday(date).getWeek() }}
                            </div>

                            <div class="pa-0">
                                <v-btn @click="() => { planVollbild = true; requestAppointments() }" v-if="!small" x-small class="ml-2" style="background-color: #f8f8f880; width: 35px; height: 35px">
                                    <img :src="vollbildIcon" class="icon iconToWhite"/>
                                </v-btn>
                            </div>
                        </div>
                    </v-row>

                    <v-row v-else style="max-width: 100%; height: 35px; max-height: 35px;" class="d-flex align-center justify-space-between" :class="this.windowWidth > 340 ? 'mx-2' : 'mx-1'">
                        <v-col class="pa-0 d-flex align-center justify-space-between"
                               :class="dateMargin(this.windowWidth)"
                               :cols="calcCols(this.windowWidth)"
                        >
                            <v-btn @click="subDay" style="background-color: #f8f8f880" small icon color="white">
                              <img :src="linksIcon" class="icon iconToWhite"/>
                            </v-btn>

                            <p class="planWidgetDisplayDate mb-0 mx-1"
                               style="color: #ffffff; font-weight: 500">
                                {{ getDisplayDateShort() }}
                            </p>

                            <v-btn @click="addDay" style="background-color: #f8f8f880" small icon color="white">
                              <img :src="rechtsIcon" class="icon iconToWhite"/>
                            </v-btn>
                        </v-col>

                        <v-col cols="4" class="pa-0 d-flex align-center justify-end"
                               :class="todayMargin(this.windowWidth)"
                        >
                            <div class="calWeek mr-1" :style="this.windowWidth > 370 ? 'font-size: medium' : 'font-size: 12px'">
                                KW {{ getMonday(date).getWeek() }}
                            </div>

                            <v-btn @click="jumpToCurrentDay()" elevation="0" small style="background-color: #f8f8f880; color: #ffffff" class="ml-1 pa-0 text-capitalize">
                                Heute
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>

                <!--menu bar of Stundenplan-->
                <div style="border-radius: 15px" class="whiteBg">
                    <v-row v-if="this.windowWidth > 600" cols="12" style="width: 99%; margin: 0 auto 0.5em;" class="whiteBg">
                        <v-col :cols="this.windowWidth < 960 && this.windowWidth > 900 ?  3 : 2" md="3" class="text-center pb-0 clockIcon">
                            <img :src="uhrIcon" width="35"/>
                        </v-col>

                        <v-col :cols="this.windowWidth < 960 && this.windowWidth > 900 ?  3 : 2" md="2" class="d-flex justify-center px-2">
                          <v-btn @click="subDay" style="background-color: #6995CD" icon color="white">
                            <img :src="linksIcon" style="height: 24px;" class="iconToWhite"/>
                          </v-btn>
                        </v-col>

                        <v-col cols="6" sm="6" md="5" class="text-center pa-0 pt-4">
                            <p class="planWidgetDisplayDate mb-2"
                               :style="isDisplayDateToday(getDisplayDate()) ? 'color: #6995CD; font-weight: bold !important': 'color: #707070; font-weight: 500'">
                                {{ getDisplayDate() }}
                            </p>
                            <div style="width: 100%">
                                <v-btn @click="jumpToCurrentDay()" elevation="0" small :class="isDisplayDateToday(getDisplayDate()) ? 'planWidgetTodayButton' : 'planWidgetTodayButtonActive'" class="text-capitalize">
                                    Heute
                                </v-btn>
                            </div>
                        </v-col>

                        <v-col :cols="this.windowWidth < 960 && this.windowWidth > 900 ?  3 : 2" md="2" class="d-flex justify-center px-2">
                          <v-btn @click="addDay" style="background-color: #6995CD" icon color="white">
                            <img :src="rechtsIcon" style="height: 24px;" class="iconToWhite"/>
                          </v-btn>
                        </v-col>
                    </v-row>

                    <!--scrollable Stundenplan-->
                    <vue-custom-scrollbar class="scroll-area" :settings="settings">
                        <table style="width: 99%; margin: 0.5em auto 5em auto; color: #707070">
                            <v-row cols="12" v-for="(item,index) in slots" :key="index" style="width: 99%; margin: 0.1em auto 0.1em auto;">
                                <v-col cols="2" style="font-weight: normal; color: #707070" class="text-center pa-2">
                                    {{("0" + new Date(item.start).getHours()).slice(-2) + ":" + ("0" + new Date(item.start).getMinutes()).slice(-2)}}
                                    <br>
                                    {{("0" + new Date(item.end).getHours()).slice(-2) + ":" + ("0" + new Date(item.end).getMinutes()).slice(-2)}}
                                </v-col>
                                <v-col cols="10" class="pa-0 pl-4">
                                    <div v-if="getAppointmentsToday(item._id).length">
                                        <div v-for="el in getAppointmentsToday(item._id)" :key="el._id">
                                            <Fach :is-running="isTimeslotCurrentlyRunningToday(item)" :isOpen="el.open" :appointment="el" :requestAppointments="requestAppointments"></Fach>
                                        </div>
                                    </div>

                                    <div v-else>
                                        <Fach :is-running="isTimeslotCurrentlyRunningToday(item)" :requestAppointments="requestAppointments"></Fach>
                                    </div>
                                </v-col>
                            </v-row>
                        </table>
                    </vue-custom-scrollbar>
                </div>
            </div>
        </v-row>

        <!--fullscreen/weekly view of stundenplan-->
        <v-dialog v-model="planVollbild" @click:outside="planVollbild = false" style="border-radius: 15px">
            <v-row style="z-index: 15;" class="ma-0">
                <div style="border-radius: 15px; width: 100%; box-shadow: 1px 5px 5px silver; background: white;">
                    <div style="background-color: #6995CD; border-radius: 15px 15px 0 0; padding-top: 10px; padding-bottom: 10px; color: white">
                        <v-row class="d-flex align-center mx-4">
                            <v-col cols="4" class="d-flex align-center pa-0 text-left" style="font-size: x-large;">
                                <img :src="stundenplanIcon" style="height: 35px;" class="iconToWhite"/>
                                <p class="pl-2 mb-0">Stundenplan</p>
                            </v-col>

                            <v-col cols="3" class="pa-0">
                                <v-btn @click="jumpToCurrentDay()" class="mr-4 text-capitalize" style="background-color: #f8f8f880; color: white">
                                    Heute
                                </v-btn>

                                <v-btn @click="subWeek" class="mx-2" style="background-color: #f8f8f880" elevation="2" icon small>
                                  <img :src="linksIcon" class="icon iconToWhite"/>
                                </v-btn>

                                <v-btn @click="addWeek" class="mx-2" style="background-color: #f8f8f880" elevation="2" icon small>
                                  <img :src="rechtsIcon" class="icon iconToWhite"/>
                                </v-btn>
                            </v-col>

                            <v-col cols="2" class="px-0 text-right">
                                {{ getDisplayMonth() + ' ' + getMonday(date).getFullYear() }}
                            </v-col>

                            <v-col cols="2" class="px-0 text-right" style="font-size: medium">
                                KW {{ getMonday(date).getWeek() }}
                            </v-col>

                            <v-col cols="1" class="px-0 text-right">
                                <v-btn @click="planVollbild = false" x-small elevation="2" style="background-color: #f8f8f880; width: 48px; height: 48px">
                                    <img :src="schliessenIcon" style="height: 40px;" class="iconToWhite"/>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>

                    <v-row style="width: 99%; margin: 2em auto 0.5em auto;">
                        <th style="width: 10%"><img :src="uhrIcon" width="24" height="24"/></th>

                        <th class="pl-5 text-left" :style="(isDisplayDateTodayShort(getDisplayDateMonday()) ? 'color: #6995CD;' : 'color: #707070;') + 'width: 18%'">Mo., {{(getMonday(date).getDate())+'.'+(getMonday(date).getMonth()+1) +'.'+getMonday(date).getFullYear().toString().slice(-2)}}</th>
                        <th class="pl-5 text-left" :style="(isDisplayDateTodayShort(getDisplayDateTuesday()) ? 'color: #6995CD;' : 'color: #707070;') + 'width: 18%'">Di., {{(getTuesday(date).getDate())+'.'+(getTuesday(date).getMonth()+1) +'.'+getTuesday(date).getFullYear().toString().slice(-2)}}</th>
                        <th class="pl-5 text-left" :style="(isDisplayDateTodayShort(getDisplayDateWednesday()) ? 'color: #6995CD;' : 'color: #707070;') + 'width: 18%'">Mi., {{(getWednesday(date).getDate())+'.'+(getWednesday(date).getMonth()+1) +'.'+getWednesday(date).getFullYear().toString().slice(-2)}}</th>
                        <th class="pl-5 text-left" :style="(isDisplayDateTodayShort(getDisplayDateThursday()) ? 'color: #6995CD;' : 'color: #707070;') + 'width: 18%'">Do., {{(getThursday(date).getDate())+'.'+(getThursday(date).getMonth()+1) +'.'+getThursday(date).getFullYear().toString().slice(-2)}}</th>
                        <th class="pl-5 text-left" :style="(isDisplayDateTodayShort(getDisplayDateFriday()) ? 'color: #6995CD;' : 'color: #707070;') + 'width: 18%'">Fr., {{(getFriday(date).getDate())+'.'+(getFriday(date).getMonth()+1) +'.'+getFriday(date).getFullYear().toString().slice(-2)}}</th>
                    </v-row>

                    <vue-custom-scrollbar class="scroll-area" :settings="settings">
                        <v-row v-for="(item,index) in slots" :key="index" style="width: 99%; margin: 0.5em auto 0.5em auto;">
                            <td style="width: 10%; color: #707070; font-weight: normal;" class="text-center pa-2">
                                {{("0" + new Date(item.start).getHours()).slice(-2) + ":" + ("0" + new Date(item.start).getMinutes()).slice(-2)}}
                                <br>
                                {{("0" + new Date(item.end).getHours()).slice(-2) + ":" + ("0" + new Date(item.end).getMinutes()).slice(-2)}}
                            </td>

                            <v-row style="width: 18%">
                                <div v-if="getAppointmentsPerDay(item._id, 'mon').length" style="width: 100%">
                                    <div v-for="el in getAppointmentsPerDay(item._id, 'mon')" :key="el._id + 'mon'">
                                        <Fach :is-running="isTimeslotCurrentlyRunning(item, 'mon')" :appointment="el" :requestAppointments="requestAppointments"></Fach>
                                    </div>
                                </div>
                                <div v-else style="width: 100%">
                                    <Fach :is-running="isTimeslotCurrentlyRunning(item, 'mon')" :requestAppointments="requestAppointments"></Fach>
                                </div>
                            </v-row>

                            <v-row style="width: 18%">
                                <div v-if="getAppointmentsPerDay(item._id, 'die').length" style="width: 100%">
                                    <div v-for="el in getAppointmentsPerDay(item._id, 'die')" :key="el._id + 'tue'">
                                        <Fach :is-running="isTimeslotCurrentlyRunning(item, 'die')" :appointment="el" :requestAppointments="requestAppointments"></Fach>
                                    </div>
                                </div>
                                <div v-else style="width: 100%">
                                    <Fach :is-running="isTimeslotCurrentlyRunning(item, 'die')" :requestAppointments="requestAppointments"></Fach>
                                </div>
                            </v-row>

                            <v-row style="width: 18%">
                                <div v-if="getAppointmentsPerDay(item._id, 'mit').length" style="width: 100%">
                                    <div v-for="el in getAppointmentsPerDay(item._id, 'mit')" :key="el._id + 'wed'">
                                        <Fach :is-running="isTimeslotCurrentlyRunning(item, 'mit')" :appointment="el" :requestAppointments="requestAppointments"></Fach>
                                    </div>
                                </div>
                                <div v-else style="width: 100%">
                                    <Fach :is-running="isTimeslotCurrentlyRunning(item, 'mit')" :requestAppointments="requestAppointments"></Fach>
                                </div>
                            </v-row>

                            <v-row style="width: 18%">
                                <div v-if="getAppointmentsPerDay(item._id, 'don').length" style="width: 100%">
                                    <div v-for="el in getAppointmentsPerDay(item._id, 'don')" :key="el._id + 'thr'">
                                        <Fach :is-running="isTimeslotCurrentlyRunning(item, 'don')" :appointment="el" :requestAppointments="requestAppointments"></Fach>
                                    </div>
                                </div>
                                <div v-else style="width: 100%">
                                    <Fach :is-running="isTimeslotCurrentlyRunning(item, 'don')" :requestAppointments="requestAppointments"></Fach>
                                </div>
                            </v-row>

                            <v-row style="width: 18%">
                                <div v-if="getAppointmentsPerDay(item._id, 'fre').length" style="width: 100%">
                                    <div v-for="el in getAppointmentsPerDay(item._id, 'fre')" :key="el._id + 'fri'">
                                        <Fach :is-running="isTimeslotCurrentlyRunning(item, 'fre')" :appointment="el" :requestAppointments="requestAppointments"></Fach>
                                    </div>
                                </div>
                                <div v-else style="width: 100%">
                                    <Fach :is-running="isTimeslotCurrentlyRunning(item, 'fre')" :requestAppointments="requestAppointments"></Fach>
                                </div>
                            </v-row>
                        </v-row>
                    </vue-custom-scrollbar>
                </div>
            </v-row>
        </v-dialog>
    </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import Fach from "./FachEltern"
import uhrIcon from '../../assets/Icons/uhr-04.svg'
import teacherIcon from '../../assets/Icons/lehrer-24.svg'
import vollbildIcon from '../../assets/Icons/vollbild-09.svg'
import linksIcon from '../../assets/Icons/links-10.svg'
import rechtsIcon from '../../assets/Icons/rechts-11.svg'
import schliessenIcon from '../../assets/Icons/abbrechen-08.svg'
import stundenplanIcon from '../../assets/Icons/stundenplan-neu-neu-99.svg'
import {mapState, mapMutations, mapActions, mapGetters} from "vuex";

export default {
    name: "PlanWidgetEltern",
    components: {
        Fach,
        vueCustomScrollbar,
    },
    data() {
        return {
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },
            menu: false,
            date: new Date(),    //is being changed when date in calendar is changed
            today: new Date(),   //don't change! reference to highlight current day
            appointments: [],
            person: null,
            slots: [],

            future: 0,
            past: 0,

            futureWeek: 0,
            pastWeek: 0,

            planVollbild: false,
            windowWidth: window.innerWidth,
            Fach,
            uhrIcon,
            teacherIcon,
            vollbildIcon,
            linksIcon,
            rechtsIcon,
            schliessenIcon,
            stundenplanIcon,
        };
    },
    props: {
        small: { required: false, default: false },
        currentChild: {required: false, type: Object},
    },
    beforeCreate() {
        // Returns the ISO week of the date.
        Date.prototype.getWeek = function() {
            var date = new Date(this.getTime());
            date.setHours(0, 0, 0, 0);
            // Thursday in current week decides the year.
            date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
            // January 4 is always in week 1.
            var week1 = new Date(date.getFullYear(), 0, 4);
            // Adjust to Thursday in week 1 and count number of weeks from date to week1.
            return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                - 3 + (week1.getDay() + 6) % 7) / 7);
        }
    },
    async created() {
    },
    async mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
        this.date = await this.getDay();
        this.today = await this.getDay();
        this.requestSlots();
        await this.requestPerson();
        await this.requestAppointments();
        this.jumpToCurrentDay();    //because somehow it jumps to a different day sometimes

        setTimeout(function(){ speechSynthesis.cancel(); }, 500);   //if you spam through tutorial this should cancel speaking
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    computed: {
        ...mapGetters('util', ['currentlyOpenAppointment']),
    },
    watch: {
        currentlyOpenAppointment(newVal, oldVal) {
            if (!newVal && oldVal) {
                this.requestAppointments();
            }
        },
        async currentChild() {
            this.appointments = await this.getAppointmentsParent(this.currentChild._id);
        }
    },
    methods: {
        ...mapActions("appointments", ["getAppointmentsParent"]),
        ...mapActions("timeslots", ["getSlots"]),
        ...mapActions("parent", ["getMeParent"]),
        ...mapActions("pupils", ["getPupil"]),
        ...mapActions("auth", ["logoutUser"]),
        ...mapActions("util", ["loadServerTime"]),

        async requestAppointments() {
            this.appointments = await this.getAppointmentsParent(this.currentChild._id);
        },

        async requestSlots() {
            this.slots = await this.getSlots();
        },

        async requestPerson() {
            this.person = await this.getMeParent();
            this.currentChild = await this.getPupil(this.person.pupils[0]);
            return this.person;
        },

        clickLogoutUser() {
            this.logoutUser();
        },

        ISO8601_week_no(dt) {
            let tdt = new Date(dt.valueOf());
            let dayn = (dt.getDay() + 6) % 7;
            tdt.setDate(tdt.getDate() - dayn + 3);
            let firstThursday = tdt.valueOf();
            tdt.setMonth(0, 1);
            if (tdt.getDay() !== 4)
            {
                tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
            }
            return 1 + Math.ceil((firstThursday - tdt) / 604800000);
        },

        jumpToCurrentDay() {
            this.date = this.today;
        },

        addWeek() {
            this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()+7);
        },
        subWeek() {
            this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()-7);
        },
        addDay() {
            do {
                this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()+1);
            } while (!(this.date.getDay() % 6))
        },
        subDay() {
            do {
                this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()-1);
            } while (!(this.date.getDay() % 6))
        },
        //compare displayDate with today, return true if equal, to color current day
        isDisplayDateToday(compareDate) {
            let heute, monat;
            let jahr = (this.today).toString().slice(13, 15);
            let tag = (this.today).toString().slice(8, 10);

            //from month string to number
            switch ((this.today).toString().slice(4, 7)) {
                case "Jan":
                    monat = "01"; break;
                case "Feb":
                    monat = "02"; break;
                case "Mar":
                    monat = "03"; break;
                case "Apr":
                    monat = "04"; break;
                case "May":
                    monat = "05"; break;
                case "Jun":
                    monat = "06"; break;
                case "Jul":
                    monat = "07"; break;
                case "Aug":
                    monat = "08"; break;
                case "Sep":
                    monat = "09"; break;
                case "Oct":
                    monat = "10"; break;
                case "Nov":
                    monat = "11"; break;
                case "Dec":
                    monat = "12"; break;
            }

            heute = tag + "." + monat + "." + jahr;

            if ((compareDate.toString()).slice(5, compareDate.toString().length) === heute) {
                return true;
            }
            else {
                return false;
            }
        },

        //WEEKLY VIEW Version | compare displayDate with today, return true if equal, to color current day
        isDisplayDateTodayShort(compareDate) {
            let heute, monat, tag;
            let jahr = (this.today).toString().slice(13, 15);
            let unshortenedDay = (this.today).toString().slice(8, 10);

            //if day shorter than 10, cut of first character
            if (unshortenedDay.slice(0,1) === "0") {
                tag = unshortenedDay.slice(1,2);
            }
            else {
                tag = unshortenedDay.slice(0,2);
            }

            //from month string to number
            switch ((this.today).toString().slice(4, 7)) {
                case "Jan":
                    monat = "1"; break;
                case "Feb":
                    monat = "2"; break;
                case "Mar":
                    monat = "3"; break;
                case "Apr":
                    monat = "4"; break;
                case "May":
                    monat = "5"; break;
                case "Jun":
                    monat = "6"; break;
                case "Jul":
                    monat = "7"; break;
                case "Aug":
                    monat = "8"; break;
                case "Sep":
                    monat = "9"; break;
                case "Oct":
                    monat = "10"; break;
                case "Nov":
                    monat = "11"; break;
                case "Dec":
                    monat = "12"; break;
            }

            heute = tag + "." + monat + "." + jahr;

            if ((compareDate.toString()).slice(5, compareDate.toString().length) === heute) {
                return true;
            }
            else {
                return false;
            }
        },
        //to display month string in header
        getDisplayMonth() {
            let monat;
            //from shortened month string to full month string
            switch ((this.date).toString().slice(4, 7)) {
                case "Jan":
                    monat = "Januar"; break;
                case "Feb":
                    monat = "Februar"; break;
                case "Mar":
                    monat = "März"; break;
                case "Apr":
                    monat = "April"; break;
                case "May":
                    monat = "Mai"; break;
                case "Jun":
                    monat = "Juni"; break;
                case "Jul":
                    monat = "Juli"; break;
                case "Aug":
                    monat = "August"; break;
                case "Sep":
                    monat = "September"; break;
                case "Oct":
                    monat = "Oktober"; break;
                case "Nov":
                    monat = "November"; break;
                case "Dec":
                    monat = "Dezember"; break;
            }
            return monat;
        },
        getDisplayDate() {
            let heute = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
            let datum, tag;

            switch (heute.getDay()) {
                case 0:
                    tag = ("So.");
                    break;
                case 1:
                    tag = ("Mo.");
                    break;
                case 2:
                    tag = ("Di.");
                    break;
                case 3:
                    tag = ("Mi.");
                    break;
                case 4:
                    tag = ("Do.");
                    break;
                case 5:
                    tag = ("Fr.");
                    break;
                case 6:
                    tag = ("Sa.");
                    break;
                default:
                    tag = ("TEST");
            }
            //add leading zeros to date
            //for day and month
            if (heute.getDate() < 10 && heute.getMonth()+1 < 10) {
                datum = (tag + ", 0" + (heute.getDate()) + '.0' + (heute.getMonth()+1) + '.' + heute.getFullYear().toString().slice(-2));
            } //for day
            else if (heute.getDate() < 10 ) {
                datum = (tag + ", 0" + (heute.getDate()) + '.' + (heute.getMonth()+1) + '.' + heute.getFullYear().toString().slice(-2));
            } //for month
            else if (heute.getMonth()+1 < 10 ) {
                datum = (tag + ", " + (heute.getDate()) + '.0' + (heute.getMonth()+1) + '.' + heute.getFullYear().toString().slice(-2));
            } //regular
            else {
                datum = (tag + ", " + (heute.getDate()) + '.' + (heute.getMonth()+1) + '.' + heute.getFullYear().toString().slice(-2));
            }
            return datum;
        },
        getDisplayDateShort() {
            return this.getDisplayDate().slice(0,11);
        },
        getDisplayDateMonday() {
            return "Mo., " + (this.getMonday(this.date).getDate()) +'.' + (this.getMonday(this.date).getMonth()+1) + '.' + this.getMonday(this.date).getFullYear().toString().slice(-2)
        },
        getDisplayDateTuesday() {
            return "Di., " + (this.getTuesday(this.date).getDate()) +'.' + (this.getTuesday(this.date).getMonth()+1) + '.' + this.getTuesday(this.date).getFullYear().toString().slice(-2)
        },
        getDisplayDateWednesday() {
            return "Mi., " + (this.getWednesday(this.date).getDate()) +'.' + (this.getWednesday(this.date).getMonth()+1) + '.' + this.getWednesday(this.date).getFullYear().toString().slice(-2)
        },
        getDisplayDateThursday() {
            return "Do., " + (this.getThursday(this.date).getDate()) +'.' + (this.getThursday(this.date).getMonth()+1) + '.' + this.getThursday(this.date).getFullYear().toString().slice(-2)
        },
        getDisplayDateFriday() {
            return "Fr., " + (this.getFriday(this.date).getDate()) +'.' + (this.getFriday(this.date).getMonth()+1) + '.' + this.getFriday(this.date).getFullYear().toString().slice(-2)
        },
        async getDay() {
            let date = new Date(await this.loadServerTime());
            while (!(date .getDay() % 6)) {
                date = new Date(date .getFullYear(), date .getMonth(), date .getDate()-1);
            }
            return date;
        },
        getMonday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getTuesday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -5:2); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getWednesday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -4:3); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getThursday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -3:4); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getFriday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -2:5); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },

        getAppointmentToday(slot) {
            return this.appointments.appointments.find((el) => { return (el.timeslot === slot && this.sameDay(new Date(el.day), this.date))})
        },
        getAppointmentsToday(slot) {
            return this.appointments.appointments.filter((el) => { return (el.timeslot === slot && this.sameDay(new Date(el.day), this.date))})
        },
        getAppointment(slot, day) {
            switch (day) {
                case 'mon':
                    day = this.getMonday(this.date);
                    break;
                case 'die':
                    day = this.getTuesday(this.date);
                    break;
                case 'mit':
                    day = this.getWednesday(this.date);
                    break;
                case 'don':
                    day = this.getThursday(this.date);
                    break;
                case 'fre':
                    day = this.getFriday(this.date);
                    break;
            }
            return this.appointments.appointments.find((el) => {
                return (el.timeslot === slot && this.sameDay(new Date(el.day), day))
            })
        },

        getAppointmentsPerDay(slot, day) {
            switch (day) {
                case 'mon':
                    day = this.getMonday(this.date);
                    break;
                case 'die':
                    day = this.getTuesday(this.date);
                    break;
                case 'mit':
                    day = this.getWednesday(this.date);
                    break;
                case 'don':
                    day = this.getThursday(this.date);
                    break;
                case 'fre':
                    day = this.getFriday(this.date);
                    break;
            }
            return this.appointments.appointments.filter((el) => {
                return (el.timeslot === slot && this.sameDay(new Date(el.day), day))
            })
        },

        sameDay(first, second) {
            return   first.getFullYear() === second.getFullYear() &&
                first.getMonth() === second.getMonth() &&
                first.getDate() === second.getDate();
        },

        // Not the cleanest code i know :/ - Jan
        isTimeslotCurrentlyRunning(timeslot, day) {
            const currentTimeAndDay = this.today;
            const appointment = this.getAppointment(timeslot._id, day);
            if (appointment) {
                const appointmentDate = new Date(appointment.day).toDateString();
                if (appointmentDate === currentTimeAndDay.toDateString()) {
                    // If we are in here that means the appointment is today
                    if (new Date(timeslot.start).toLocaleTimeString() <= currentTimeAndDay.toLocaleTimeString()
                        && new Date(timeslot.end).toLocaleTimeString() > currentTimeAndDay.toLocaleTimeString()) {
                        // Currenttime is between start and end of timeslot
                        return true;
                    }
                }
            }
            return false;
        },

        isTimeslotCurrentlyRunningToday(timeslot) {
            const currentTimeAndDay = this.today;
            const appointment = this.getAppointmentToday(timeslot._id);
            if (appointment) {
                const appointmentDate = new Date(appointment.day).toDateString();
                if (appointmentDate === currentTimeAndDay.toDateString()) {
                    // If we are in here that means the appointment is today
                    if (new Date(timeslot.start).toLocaleTimeString() <= currentTimeAndDay.toLocaleTimeString()
                        && new Date(timeslot.end).toLocaleTimeString() > currentTimeAndDay.toLocaleTimeString()) {
                        // Currenttime is between start and end of timeslot
                        return true;
                    }
                }
            }
            return false;
        },

        onResize() {
            this.windowWidth = window.innerWidth;
        },

        calcCols(width) {
            if (width > 450) {
                return '5'
            }
            else if (width > 380) {
                return '6'
            }
            else {
                return '7'
            }
        },

        dateMargin(width) {
            if (width > 400) {
                return 'ml-4'
            }
            else if (width > 350) {
                return 'ml-2'
            }
            else {
                return 'ml-1'
            }
        },

        todayMargin(width) {
            if (width > 460) {
                return 'mr-8'
            }
            else if (width > 400) {
                return 'mr-6'
            }
            else if (width > 355) {
                return 'mr-4'
            }
            else {
                return 'mr-2'
            }
        },
    },
};
</script>

<style lang="scss" scoped>
table, th, td {
    border: none;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

th {
    vertical-align: top;
}


table tr th:first-child,
table tr td:first-child {
    padding-top: 10px;
    width: 10px !important;
}

th:nth-child(3) {
    border-radius: 15px;
}

table tr th:last-child,
table tr td:last-child {
    width: 5px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}

table, tr, td {
    border: none;
}

.fach {
    cursor: pointer;
}

.icon {
    height: 20px;
}

.whiteBg {
    background-color: #ffffff;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(65vh - 30px);
}

.planWidgetDisplayDate {
    display: inline-block;
    font-weight: normal;
    font-size: large;
}

.planWidgetTodayButtonActive {
    background-color: #6995CD !important;
    color: white;
    border-radius: 8px;
}

.planWidgetTodayButton {
    background-color: #eaeaea !important;
    color: #707070;
    border-radius: 8px;
    box-shadow: 1px 5px 5px silver;
}

@media only screen and (min-width: 901px) and (max-width: 1050px) {
    .calWeek {
        display: none;
    }
}

@media only screen and (max-width: 959px) {
    .clockIcon {
        display: none;
    }
}

@media only screen and (max-width: 900px) {
    .clockIcon {
        display: initial;
    }
}
</style>
