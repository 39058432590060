const defaultState = {
    showTooltips: true,
    shortcutTooltips: false,
    tabPressedOnce: false,
};

const mutations = {
    setTooltips(state, value) {
        state.showTooltips = value;
    },
    setShortcutTooltips(state, value) {
        state.shortcutTooltips = value;
    },
    setTabPressedOnce(state, value) {
        state.tabPressedOnce = value;
    }
};


const actions = {
    // toggleTooltips({ commit, state }) {
    //     commit("setTooltips", !state.showTooltips);
    // },

    triggerShortcutTooltips({ commit, state }) {
        commit("setShortcutTooltips", true);
        setTimeout(() => {
            commit("setShortcutTooltips", false);
        }, 2000);
    },

    triggerTabPressedOnce({ commit, state }) {
        commit("setTabPressedOnce", true);
        commit("setShortcutTooltips", true);
        setTimeout(() => {
            commit("setShortcutTooltips", false);
        }, 11000);
    },
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
};
