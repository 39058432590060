<template>
    <div @click="$emit('focusMe')" :style="{'margin-top': account.role === 'teacher' ? '0px' : '32px'}">
        <v-row no-gutters justify="space-between" v-if="account.role === 'teacher'">
            <v-col>
                <img :src="moveIcon" ref="movingIcon" style="cursor: grab" height="25" width="25"/>
            </v-col>
            <v-col cols="auto">
                <img :src="papierkorbIcon" @click="$emit('deleteObject')" style="cursor: pointer" ref="deleteIcon" height="25" width="25" class=""/>
            </v-col>
        </v-row>
        <div
            :class="{ 'moveableItemShadow': account.role === 'teacher' }"
            ref="imgObjectOuter"
            id="imgObjectOuter"
            @mouseup="resizeEnd"
            :style="`
        resize: ${account.role === 'teacher' ? 'both !important' : 'none'};
        overflow: hidden;
        border: ${account.role === 'teacher' ? '1px solid grey' : 'none'};
        width: ${img.width ? img.width + 'px' : '200px'};
        height: ${img.height ? img.height + 'px' : 'auto'}
        `"
        >
            <img :src="imgUrl" style="max-width: 100%; max-height: 100%" width="auto" height="auto" :alt="img.text" :tabindex="tabIndex"/>
        </div>
    </div>
</template>

<script>

import moveIcon from '../../assets/Icons/Temp/move.svg';
import papierkorbIcon from "../../assets/Icons/papierkorb-109.svg";
import * as backend from "@/api/backend";
import {mapState} from "vuex";

export default {
    name: "EditorImageObject",
    computed: {
        ...mapState("auth", ["account"]),
    },
    props: {
        img: {type: Object, required: true},
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
        tabIndex: { type: Number, required: false, default: 19 },
    },
    data: () => ({
        imgUrl: "",
        moveIcon,
        papierkorbIcon
    }),
    mounted() {
        if(this.account.role === 'teacher')
            this.dragElement(this.$refs.movingIcon, this.$refs.imgObjectOuter);
        if(!this.img.fileUrl || !this.img.fileBlob)
            this.loadImage();
        else
            this.imgUrl = this.img.fileUrl;
    },
    methods: {
        resizeEnd(event) {
            if (event.dataTransfer) {
                this.dragEnd(event);
            }

            const width = this.$refs.imgObjectOuter.style.width.substring(
                0,
                this.$refs.imgObjectOuter.style.width.length - 2
            );
            const height = this.$refs.imgObjectOuter.style.height.substring(
                0,
                this.$refs.imgObjectOuter.style.height.length - 2
            );
            this.img.width = width;
            this.img.height = height;
            //this.$emit("dimensionsChanged", { width, height });
        },
        async loadImage() {
            // fileUrl: url, fileBlob: blob,
            const res = await backend.getUploadMessage(this.img.start);
            const blob = await res.blob();
            this.img.fileBlob = blob;
            this.img.fileUrl = window.URL.createObjectURL(blob);
            this.imgUrl = this.img.fileUrl;
        },
        dragElement(element, elementToMove) {
            let _this = this;
            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            let lastMove = null;

            element.onmousedown = dragMouseDown;
            element.ontouchstart = dragMouseDown;
            // element.onpointerdown = dragMouseDown;
            // element.onpointermove = elementDrag;

            function dragMouseDown(e) {
                _this.$emit('focusMe');
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                lastMove = e;
                document.onmouseup = closeDragElement;
                document.ontouchend = closeDragElement;
                // document.onpointerup = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
                document.ontouchmove = elementDrag;
                // document.onpointermove = elementDrag;
            }

            const elementDrag = (e) => {
                e = e || window.event;
                lastMove = e;
                if (e.type !== 'touchmove' && e.type !== 'pointermove') {
                    e.preventDefault();
                }

                // calculate the new cursor position:
                pos1 = pos3 - (e.clientX ? e.clientX : e.touches[0].clientX);
                pos2 = pos4 - (e.clientY ? e.clientY : e.touches[0].clientY);
                pos3 = (e.clientX ? e.clientX : e.touches[0].clientX);
                pos4 = (e.clientY ? e.clientY : e.touches[0].clientY);
                // set the element's new position:
                const top = (Number.parseFloat(this.img.y)) - ((pos2 / this.canvasHeight) * 100);
                const left = (Number.parseFloat(this.img.x)) - ((pos1 / this.canvasWidth) * 100);

                if ((left > -10 && left < 110) && (top > -10 && top < 110)) {
                    this.img.x = left;
                    this.img.y = top;
                }
            }

            const closeDragElement = (e) => {
                // stop moving when mouse button is released:
                document.onmouseup = null;
                document.onmousemove = null;
                document.ontouchend = null;
                document.onpointerup = null;
                document.ontouchmove = null;
                document.onpointermove = null;
                try {
                    this.click(e.clientX ? e.clientX : lastMove.touches[0].clientX, e.clientY ? e.clientY : lastMove.touches[0].clientY);
                } catch (e) {
                    // console.log(e);
                }
                lastMove = null;
            }
        },
    }
}
</script>

<style scoped>

</style>
