<template>
    <div>
        <progress-bar
            title="Hochladen"
            :show-progress="showPupilUploadProgress"
            :progress="pupilUploadProgress"
            :abort-progress="pupilXmlHttpRequest ? () => { pupilXmlHttpRequest.abort() }: () => {}"
        />
        <label style="display: none" for="hausiPupilUploadInput" hidden>Hausaufgaben Hochladen</label>
        <input
            @change="() => pupilUploadInputChange(item.appointmentId, item._id)"
            id="hausiPupilUploadInput"
            ref="hausiPupilUploadInput"
            type="file"
            hidden
        />

        <v-row class="mx-0">
            <v-col class="pa-0">
                <div id="hausiWidgetBabyContainer">

                    <!--Header of Hausaufgaben widget-->
                    <div @click="readWidgetInfoText()" id="hausiWidgetHeader">
                        <v-row style="max-width: 100%; display: flex; align-items: center;" class="mx-4">
                            <v-col cols="9" md="8" lg="9" class="pa-0 text-left d-flex align-center"
                                   style="font-size: xx-large">
                                <img :src="hausaufgabenIconLeer"
                                     style="height: 30px; margin-bottom: -3px;"
                                     alt=""
                                />
                                <h1 class="ma-0 ml-2" tabindex="0" aria-label="Hausaufgaben"><span style="text-decoration: underline white">H</span>ausaufgaben</h1>
                            </v-col>
                            <v-col cols="3" md="4" lg="3" class="pa-0 d-flex align-center justify-end">
                                <v-tooltip :disabled="!showTooltips" bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                                               v-on="on" aria-label="Vorlesen" x-small class="pa-0"
                                               style="background-color: #f8f8f800; height: 45px; width: 45px">
                                            <img :src="lautsprecherWeissIcon" style="height: 42px;" id="infoHausiBtn"
                                                 alt="Vorlesen">
                                        </v-btn>
                                    </template>
                                    <span>Vorlesen</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-if="showLoading" class="d-flex justify-center mt-4">
                        <v-progress-circular color="#6995CD" indeterminate size="100"/>
                    </div>
                    <!-- Hausiwidget mit magnifier -->
                    <div v-else-if="magnifier">
                        <div @keydown.right="handleArrowKeyRight()" @keydown.left="handleArrowKeyLeft()">
                            <v-row class="px-3">
                                <v-col class='px-1' cols="4">
                                    <v-tooltip :value="shortcutTooltips" :disabled="!showTooltips" bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn :id="'toDoTab'" :color="tab === 'toDo' ? '#e6231e' : 'white'"
                                                   @click="tab = 'toDo'; checkImagePreviews(); setFocusOn('toDoTab')" :dark="tab === 'toDo'"
                                                   style="width: 100%; border: solid 1px #e6231e !important; overflow: hidden; text-overflow: ellipsis"
                                                   elevation="0"
                                                   v-on="on"
                                                   role="tab"
                                                   @keydown.tab="setFocusOnFirstItemTab('toDoIconMagnifier-0', 'toDo')">
                                                Unerledigt
                                            </v-btn>
                                        </template>
                                        <div style="display: flex; flex-direction: column; text-align: center">
                                            <span><span style="text-decoration: underline white">H</span>ausaufgaben (Alt + H)</span>
                                            <div>
                                                <img v-if="hoeren" :src="hausaufgabenMetacom" width="100" style="margin: auto" alt="" class="mr-1">
                                                <img v-if="hoeren" :src="toDoMetacom" width="100" style="margin: auto" alt="">
                                            </div>
                                        </div>
                                    </v-tooltip>
                                </v-col>
                                <v-col class='px-1' cols="4">
                                    <v-tooltip :disabled="!showTooltips || !hoeren" bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on"
                                                   :id="'doneTab'"
                                                   :color="tab === 'done' ? '#F49F31' : 'white'" @click="tab = 'done'; checkImagePreviews(); setFocusOn('doneTab')"
                                                   :dark="tab === 'done'"
                                                   style="width: 100%; border: solid 1px #F49F31 !important; overflow: hidden; text-overflow: ellipsis"
                                                   elevation="0"
                                                   role="tab"
                                                   @keydown.tab="setFocusOnFirstItemTab('doneIconMagnifier-0', 'toDo')">
                                                Erledigt
                                            </v-btn>
                                        </template>
                                            <img v-if="hoeren" :src="hausaufgabenMetacom" width="100" style="margin: auto" alt="" class="mr-1">
                                            <img v-if="hoeren" :src="doneMetacom" width="100" style="margin: auto" alt="">
                                    </v-tooltip>
                                </v-col>
                                <v-col class='px-1' cols="4">
                                    <v-tooltip :disabled="!showTooltips || !hoeren" bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on"
                                                   :id="'correctedTab'"
                                                   :color="tab === 'corrected' ? '#8cbe46' : 'white'" @click="tab = 'corrected'; checkImagePreviews(); setFocusOn('correctedTab')"
                                                   :dark="tab === 'corrected'"
                                                   style="width: 100%; border: solid 1px #8cbe46 !important; overflow: hidden; text-overflow: ellipsis"
                                                   elevation="0"
                                                   role="tab"
                                                   @keydown.tab="setFocusOnFirstItemTab('correctedIconMagnifier-0', 'toDo')">
                                                Korrigiert
                                            </v-btn>
                                        </template>
                                        <img v-if="hoeren" :src="hausaufgabenMetacom" width="100" style="margin: auto" alt="" class="mr-1">
                                        <img v-if="hoeren" :src="correctedMetacom" width="100" style="margin: auto" alt="">
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <anti-scroll class="scroll-area">

                                <div v-if="tab ==='toDo' && babyFilesToDoDisplay">

                                    <div
                                        v-for="(item, index) in babyFilesToDoDisplay.filter(item => (!('canPip' in item) || item.canPip === true))"
                                        :key="item.file">
                                        <div class="fileRow mx-2 d-flex align-center"
                                             :class="{ brighterFileRow: index % 2 === 1, disabledFileRow: false }">

                                            <!--Fach icon-->
                                            <v-tooltip :disabled="!showTooltips" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-avatar v-on="on" class="ml-2 smallBabyViewActionBtn fachLogo"
                                                              :style="`background-color: ${item.fachColor} !important`">
                                                        <img :id="'toDoIconMagnifier-' + index" tabindex="0" :src="getSrc(item.fachIcon)"
                                                             class="smallBabyViewActionBtnImg" :alt="item.groupName"/>
                                                    </v-avatar>
                                                </template>
                                                <span> {{ item.groupName }}</span>
                                            </v-tooltip>
                                            <!--                              Fach icon-->
                                            <!--                              <v-btn small class="ml-2 smallBabyViewActionBtn fachLogo" disabled-->
                                            <!--                                     :style="`background-color: ${item.fachColor} !important`">-->
                                            <!--                                <img :src="getSrc(item.fachIcon)" class="smallBabyViewActionBtnImg"/>-->
                                            <!--                              </v-btn>-->

                                            <!--colored line instead of icon if width < 600-->
                                            <div class="fachLine ma-0 pa-0"
                                                 :style="`background-color: ${item.fachColor} !important`">
                                            </div>

                                            <p :id="'toDoItemMagnifier-' + index" style="width: fit-content" class="fileTitle mx-2 my-0"
                                               @focus="readExerciseName(item)" @click="readExerciseName(item)" tabindex="0">
                                                {{ item.title }}
                                            </p>

                                            <div class="my-0 mr-2" style="display: flex; margin-left: auto">
                                                <v-btn small class="babyViewActionBtn" v-if="false">
                                                    <img :src="lautsprecherIcon" class="babyViewActionBtnImg"
                                                         alt="Vorlesen"/>
                                                </v-btn>
                                                <v-tooltip :disabled="!showTooltips" bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" small class="babyViewActionBtn ml-3"
                                                               @click="clickDownloadTeacherUpload(item)">
                                                            <img :src="runterladenIcon" class="babyViewActionBtnImg"
                                                                 alt="Herunterladen"/>
                                                        </v-btn>
                                                    </template>
                                                    <div style="display: flex; flex-direction: column; text-align: center">
                                                        <span>Herunterladen</span>
                                                        <img v-if="hoeren" :src="herunterladenMetacom" width="100" style="margin: auto" alt="">
                                                    </div>
                                                </v-tooltip>

                                                <v-tooltip :disabled="!showTooltips" bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" small class="babyViewActionBtn ml-3"
                                                               @click="pupilUploadFile(item)">
                                                            <img :src="kameraIcon" class="babyViewActionBtnImg"
                                                                 alt="Hochladen"/>
                                                        </v-btn>
                                                    </template>
                                                    <div style="display: flex; flex-direction: column; text-align: center">
                                                        <span>Hochladen</span>
                                                        <img v-if="hoeren" :src="hochladenMetacom" width="100" style="margin: auto" alt="">
                                                    </div>
                                                </v-tooltip>

                                                <v-tooltip
                                                    v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(item.title.split('.').pop())"
                                                    :disabled="!showTooltips" bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            v-on="on"
                                                            @click="play(item)" small class="babyViewActionBtn edit ml-3"
                                                            dark
                                                            :loading="videoLoading"
                                                        >
                                                            <img :src="rechtsIcon" class="babyViewActionBtnImg editImg"
                                                                 alt="Video abspielen"/>
                                                        </v-btn>
                                                    </template>
                                                    <span>Video abspielen</span>
                                                </v-tooltip>

                                                <v-tooltip
                                                    v-else-if="['wav', 'mp3', 'wma', 'm4a' , 'acc', 'ogg'].includes(item.title.split('.').pop())"
                                                    :disabled="!showTooltips" bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            v-on="on"
                                                            @click="playAudio(item)" small
                                                            class="babyViewActionBtn edit ml-3" dark
                                                            :loading="videoLoading"
                                                        >
                                                            <img :src="rechtsIcon" class="babyViewActionBtnImg editImg"
                                                                 alt="Audio abspielen"/>
                                                        </v-btn>
                                                    </template>
                                                    <span>Audio abspielen</span>
                                                </v-tooltip>

                                                <v-tooltip
                                                    v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(item.title.split('.').pop().toLowerCase())"
                                                    :disabled="!showTooltips" bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" small class="babyViewActionBtn edit ml-3"
                                                               @click="openEdit(item)">
                                                            <img :src="bearbeitenIcon" class="babyViewActionBtnImg editImg"
                                                                 alt="im Editor bearbeiten"/>
                                                        </v-btn>
                                                    </template>
                                                    <div style="display: flex; flex-direction: column; text-align: center">
                                                        <span>Bearbeiten</span>
                                                        <div style="margin: auto">
                                                            <img v-if="hoeren" :src="hausaufgabenMetacom" width="100" class="mr-1" alt="">
                                                            <img v-if="hoeren" :src="toDoMetacom" width="100" alt="">
                                                        </div>
                                                    </div>
                                                </v-tooltip>
                                                <v-tooltip
                                                        v-else-if="!item.seenByMe  && ['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(item.title.split('.').pop().toLowerCase())"
                                                        :disabled="!showTooltips" bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" small class="babyViewActionBtn edit ml-3"
                                                               @click="handleOpenAnsichtClick(item)">
                                                            <img :src="previewIcon" class="babyViewActionBtnImg editImg"
                                                                 alt="Ansehen"/>
                                                        </v-btn>
                                                    </template>
                                                    <span>Ansehen</span>
                                                </v-tooltip>

                                                <v-tooltip v-else :disabled="!showTooltips" bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            v-on="on"
                                                            dark @click="fileNotSupported(item)" x-small
                                                            class="babyViewActionBtn edit ml-3" elevation="0"
                                                            style="background-color: #8CBD46;">
                                                            <img :src="infoIcon" class="babyViewActionBtnImg editImg"
                                                                 alt="Dateiformat nicht unterstützt"/>
                                                        </v-btn>
                                                    </template>
                                                    <span>Dateiformat nicht unterstützt</span>
                                                </v-tooltip>
                                            </div>
                                        </div>
                                        <div v-if="item.previewData" class="mx-2 mb-1 d-flex justify-center"
                                             style="max-height: 15vh; background-color: gray; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;">
                                            <!-- Display image preview -->
                                            <v-progress-circular
                                                v-if="item.previewData.loading"
                                                indeterminate
                                                class="my-3"
                                                color="#F49F31"
                                            ></v-progress-circular>
                                            <img
                                                v-else-if="['png', 'jpg', 'jpeg', 'bmp', 'mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(item.title.split('.').pop().toLowerCase()) && item.previewData.preview"
                                                @click="() => ['png', 'jpg', 'jpeg', 'bmp', ].includes(item.title.split('.').pop().toLowerCase()) ? openEdit(item) : play(item)"
                                                :src="item.previewData.preview"
                                                style="cursor: pointer; max-width: 345px; max-height: 15vh"/>
                                            <!-- Display pdf preview -->
                                            <object type="application/pdf"
                                                    v-else-if="['pdf'].includes(item.title.split('.').pop().toLowerCase()) && item.previewData.preview"
                                                    @click="() => openEdit(item)"
                                                    :data="item.previewData.preview"
                                                    style="cursor: pointer; max-width: 345px; max-height: 15vh"/>
                                            <div v-else-if="item.previewData.fetched" class="my-2 grey--text text--lighten-5 subtitle-1">Es ist keine Vorschau verfügbar</div>


                                            <!-- Display video preview -->
                                            <!--                                        <video-->
                                            <!--                                            v-else-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(item.title.split('.').pop().toLowerCase())"-->
                                            <!--                                            @click="() => play(item)"-->
                                            <!--                                            :src="item.teacherBlob"-->
                                            <!--                                            style="cursor: pointer;  max-width: 345px; max-height: 15vh"/>-->
                                        </div>
                                    </div>

                                    <!--expand and collapse btns-->
                                    <div v-if="babyFilesToDo.length > 5" class="d-flex justify-center my-2">
                                        <v-tooltip v-if="!toDoLong" :disabled="!showTooltips" bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" @click="unsliceList('toDo')" small elevation="0">
                                                    <img :src="arrowDown" style="height: 20px;" alt="Alles anzeigen"/>
                                                </v-btn>
                                            </template>
                                            <span>Alles anzeigen</span>
                                        </v-tooltip>

                                        <v-tooltip v-else :disabled="!showTooltips" bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" @click="sliceList('toDo')" small elevation="0">
                                                    <img :src="arrowDown" style="height: 20px; transform: rotate(180deg)"
                                                         alt="Weniger anzeigen"/>
                                                </v-btn>
                                            </template>
                                            <span>Weniger anzeigen</span>
                                        </v-tooltip>
                                    </div>

                                    <v-row
                                        v-if="babyFilesToDoDisplay && babyFilesToDoDisplay.length === 0"
                                        class="emptyRow ma-2"
                                        align="center" justify="center"
                                    >
                                        <v-col class="pa-0">
                                            <p tabindex="0" id="toDoEmpty-magnifier">Aktuell gibt es nichts zu erledigen</p>
                                        </v-col>
                                    </v-row>
                                </div>


                                <div v-if="tab === 'done'">
                                    <v-row cols="12" style="width: 99%; margin: 0.5em auto 0.5em auto;" v-show="false">
                                        <v-col style="display: flex; align-items: center" class="sectionHeaderCol">
                                            <img :src="doneIcon" style="height: 30px" class="sectionHeaderImg" alt=""/>
                                            <h2 class="pl-2 sectionHeaderText" tabindex="0">Erledigt:</h2>
                                        </v-col>
                                    </v-row>

                                    <div
                                        v-for="(item, index) in babyFilesDoneDisplay.filter(item => (!('canPip' in item) || item.canPip === true))"
                                        :key="item.file">
                                        <div class="fileRow mx-2 d-flex align-center"
                                             :class="{ brighterFileRow: index % 2 === 1, disabledFileRow: false }">

                                            <!-- Fach icon -->
                                            <v-tooltip :disabled="!showTooltips" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-avatar v-on="on" class="ml-2 smallBabyViewActionBtn fachLogo"
                                                              :style="`background-color: ${item.fachColor} !important`">
                                                        <img :id="'doneIconMagnifier-' + index" tabindex="0" :src="getSrc(item.fachIcon)"
                                                             class="smallBabyViewActionBtnImg" :alt="item.groupName"/>
                                                    </v-avatar>
                                                </template>
                                                <span> {{ item.groupName }}</span>
                                            </v-tooltip>

                                            <!--colored line instead of icon if width < 600-->
                                            <div class="fachLine ma-0 pa-0"
                                                 :style="`background-color: ${item.fachColor} !important`">
                                            </div>

                                            <p tabindex="0" style="width: fit-content" class="fileTitle mx-2 my-0" @click="readExerciseName(item)">
                                                {{ item.title }}
                                            </p>

                                            <div class="my-0 mr-2" style="display: flex; margin-left: auto">
                                                <v-btn small class="babyViewActionBtn" v-if="false">
                                                    <img :src="lautsprecherIcon" class="babyViewActionBtnImg"
                                                         alt="Vorlesen"/>
                                                </v-btn>

                                                <v-tooltip
                                                    v-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(item.title.split('.').pop().toLowerCase())"
                                                    :disabled="!showTooltips" bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on"
                                                               small class="babyViewActionBtn ml-3"
                                                               @click="handleOpenAnsichtClick(item)">
                                                            <!--                                        <v-icon style="width: 20px; color: #939393" alt="Vorschausymbol">fas fa-eye</v-icon>-->
                                                            <img :src="previewIcon" style="width: 30px; color: #939393"
                                                                 alt="Vorschau"/>
                                                        </v-btn>
                                                    </template>
                                                    <span>Vorschau</span>
                                                </v-tooltip>

                                                <v-tooltip v-else :disabled="!showTooltips" bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-bind="on" small class="babyViewActionBtn ml-3"
                                                               @click="downloadCorrectFile(item)">
                                                            <img :src="runterladenIcon" class="babyViewActionBtnImg"
                                                                 alt="Herunterladen"/>
                                                        </v-btn>
                                                    </template>
                                                    <span>Herunterladen</span>
                                                </v-tooltip>

                                                <v-tooltip :disabled="!showTooltips" bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            v-on="on" small class="babyViewActionBtn ml-3"
                                                            @click="deleteDialog = true; elementToDelete = item">
                                                            <img :src="papierkorbIcon" class="babyViewActionBtnImg"
                                                                 alt="Datei löschen"/>
                                                        </v-btn>
                                                    </template>
                                                    <span>Datei löschen</span>
                                                </v-tooltip>
                                            </div>
                                        </div>

                                        <div v-if="item.previewData" class="mx-2 mb-1 d-flex justify-center"
                                             style="max-height: 15vh; background-color: gray; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;">
                                            <!-- Display image preview -->
                                            <v-progress-circular
                                                v-if="item.previewData.loading"
                                                indeterminate
                                                class="my-3"
                                                color="#F49F31"
                                            ></v-progress-circular>
                                            <img
                                                v-else-if="['png', 'jpg', 'jpeg', 'bmp', 'mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(item.title.split('.').pop().toLowerCase()) && item.previewData.preview"
                                                @click="() => ['png', 'jpg', 'jpeg', 'bmp', ].includes(item.title.split('.').pop().toLowerCase()) ? openEdit(item) : play(item)"
                                                :src="item.previewData.preview"
                                                style="cursor: pointer; max-width: 345px; max-height: 15vh"/>
                                            <!-- Display pdf preview -->
                                            <object type="application/pdf"
                                                    v-else-if="['pdf'].includes(item.title.split('.').pop().toLowerCase()) && item.previewData.preview"
                                                    @click="() => openEdit(item)"
                                                    :data="item.previewData.preview"
                                                    style="cursor: pointer; max-width: 345px; max-height: 15vh"/>
                                            <div v-else-if="item.previewData.fetched" class="my-2 grey--text text--lighten-5 subtitle-1">Es ist keine Vorschau verfügbar</div>
                                            <!-- Display video preview -->
                                            <!--                                        <video-->
                                            <!--                                            v-else-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(item.title.split('.').pop().toLowerCase())"-->
                                            <!--                                            @click="() => play(item)"-->
                                            <!--                                            :src="item.pupilBlob"-->
                                            <!--                                            style="cursor: pointer;  max-width: 345px; max-height: 15vh"/>-->
                                        </div>
                                    </div>

                                    <!--expand and collapse btns-->
                                    <div v-if="babyFilesToDo.length > 5" class="d-flex justify-center my-2">
                                        <v-tooltip v-if="!toDoLong" :disabled="!showTooltips" bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" @click="unsliceList('toDo')" small elevation="0">
                                                    <img :src="arrowDown" style="height: 20px;" alt="Alles anzeigen"/>
                                                </v-btn>
                                            </template>
                                            <span>Alles anzeigen</span>
                                        </v-tooltip>

                                        <v-tooltip v-else :disabled="!showTooltips" bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" @click="sliceList('toDo')" small elevation="0">
                                                    <img :src="arrowDown" style="height: 20px; transform: rotate(180deg)"
                                                         alt="Weniger anzeigen"/>
                                                </v-btn>
                                            </template>
                                            <span>Weniger anzeigen</span>
                                        </v-tooltip>
                                    </div>

                                    <v-row
                                        v-if="babyFilesDone && babyFilesDone.length === 0"
                                        class="emptyRow ma-2"
                                        align="center" justify="center"
                                    >
                                        <v-col class="pa-0">
                                            <p tabindex="0" id="doneEmpty-magnifier">Aktuell gibt es noch nichts Erledigtes</p>
                                        </v-col>
                                    </v-row>
                                </div>


                                <div v-if="tab === 'corrected'">
                                    <v-row cols="12" style="width: 99%; margin: 0.5em auto 0.5em auto;" v-show="false">
                                        <v-col style="display: flex; align-items: center" class="sectionHeaderCol">
                                            <img :src="correctedIcon" style="height: 30px" class="sectionHeaderImg" alt=""/>
                                            <h2 class="pl-2 sectionHeaderText" tabindex="0">Korrigiert:</h2>
                                        </v-col>
                                    </v-row>

                                    <div
                                        v-for="(item, index) in babyFilesCorrectedDisplay.filter(item => (!('canPip' in item) || item.canPip === true))"
                                        :key="item.file">
                                        <div class="fileRow mx-2 d-flex align-center"
                                             :class="{ brighterFileRow: index % 2 === 1, disabledFileRow: false }">

                                            <!--Fach icon-->
                                            <v-tooltip :disabled="!showTooltips" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-avatar v-on="on" class="ml-2 smallBabyViewActionBtn fachLogo"
                                                              :style="`background-color: ${item.fachColor} !important`">
                                                        <img :id="'correctedIconMagnifier-' + index" tabindex="0" :src="getSrc(item.fachIcon)"
                                                             class="smallBabyViewActionBtnImg" :alt="item.groupName"/>
                                                    </v-avatar>
                                                </template>
                                                <span> {{ item.groupName }}</span>
                                            </v-tooltip>

                                            <!--colored line instead of icon if width < 600-->
                                            <div class="fachLine ma-0 pa-0"
                                                 :style="`background-color: ${item.fachColor} !important`">
                                            </div>

                                            <p tabindex="0" style="width: fit-content" class="fileTitle mx-2 my-0" @click="readExerciseName(item)">
                                                {{ item.title }}
                                            </p>

                                            <div class="my-0 mr-2" style="display: flex; margin-left: auto">
                                                <v-btn small class="babyViewActionBtn" v-if="false">
                                                    <img :src="lautsprecherIcon" class="babyViewActionBtnImg"
                                                         alt="Vorlesen"/>
                                                </v-btn>

                                                <v-tooltip
                                                    v-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(item.title.split('.').pop().toLowerCase())"
                                                    :disabled="!showTooltips" bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on"
                                                               small class="babyViewActionBtn ml-3"
                                                               @click="handleOpenAnsichtClick(item)">
                                                            <!--                                        <v-icon style="width: 20px; color: #939393" alt="Vorschausymbol">fas fa-eye</v-icon>-->
                                                            <img :src="previewIcon" style="width: 30px; color: #939393"
                                                                 alt="Vorschau"/>
                                                        </v-btn>
                                                    </template>
                                                    <span>Vorschau</span>
                                                </v-tooltip>

                                                <v-tooltip v-else :disabled="!showTooltips" bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-bind="on" small class="babyViewActionBtn ml-3"
                                                               @click="downloadCorrectFile(item)">
                                                            <img :src="runterladenIcon" class="babyViewActionBtnImg"
                                                                 alt="Herunterladen"/>
                                                        </v-btn>
                                                    </template>
                                                    <span>Herunterladen</span>
                                                </v-tooltip>
                                            </div>
                                        </div>

                                        <div v-if="item.previewData" class="mx-2 mb-1 d-flex justify-center"
                                             style="max-height: 15vh; background-color: gray; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;">
                                            <!-- Display image preview -->
                                            <v-progress-circular
                                                v-if="item.previewData.loading"
                                                indeterminate
                                                class="my-3"
                                                color="#F49F31"
                                            ></v-progress-circular>
                                            <img
                                                v-else-if="['png', 'jpg', 'jpeg', 'bmp', 'mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(item.title.split('.').pop().toLowerCase()) && item.previewData.preview"
                                                @click="() => ['png', 'jpg', 'jpeg', 'bmp', ].includes(item.title.split('.').pop().toLowerCase()) ? openEdit(item) : play(item)"
                                                :src="item.previewData.preview"
                                                style="cursor: pointer; max-width: 345px; max-height: 15vh"/>
                                            <!-- Display pdf preview -->
                                            <object type="application/pdf"
                                                    v-else-if="['pdf'].includes(item.title.split('.').pop().toLowerCase()) && item.previewData.preview"
                                                    @click="() => openEdit(item)"
                                                    :data="item.previewData.preview"
                                                    style="cursor: pointer; max-width: 345px; max-height: 15vh"/>
                                            <div v-else-if="item.previewData.fetched" class="my-2 grey--text text--lighten-5 subtitle-1">Es ist keine Vorschau verfügbar</div>
                                            <!-- Display video preview -->
                                            <!--                                        <video-->
                                            <!--                                            v-else-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(item.title.split('.').pop().toLowerCase())"-->
                                            <!--                                            @click="() => play(item)"-->
                                            <!--                                            :src="item.correctedBlob"-->
                                            <!--                                            style="cursor: pointer;  max-width: 345px; max-height: 15vh"/>-->
                                        </div>
                                    </div>

                                    <!--expand and collapse btns-->
                                    <div v-if="babyFilesToDo.length > 5" class="d-flex justify-center my-2">
                                        <v-tooltip v-if="!toDoLong" :disabled="!showTooltips" bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" @click="unsliceList('toDo')" small elevation="0">
                                                    <img :src="arrowDown" style="height: 20px;" alt="Alles anzeigen"/>
                                                </v-btn>
                                            </template>
                                            <span>Alles anzeigen</span>
                                        </v-tooltip>

                                        <v-tooltip v-else :disabled="!showTooltips" bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" @click="sliceList('toDo')" small elevation="0">
                                                    <img :src="arrowDown" style="height: 20px; transform: rotate(180deg)"
                                                         alt="Weniger anzeigen"/>
                                                </v-btn>
                                            </template>
                                            <span>Weniger anzeigen</span>
                                        </v-tooltip>
                                    </div>

                                    <v-row
                                        v-if="babyFilesCorrected && babyFilesCorrected.length === 0"
                                        class="emptyRow ma-2"
                                        align="center" justify="center"
                                    >
                                        <v-col class="pa-0">
                                            <p tabindex="0" id="correctedEmpty-magnifier">Aktuell gibt es nichts Korrigiertes</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </anti-scroll>
                        </div>
                    </div>
                    <!--scrollable content-->

                    <!-- Hausiwidget ohne magnifier -->
                    <vue-custom-scrollbar v-else class="scroll-area" :settings="settings">
                        <div @keydown.right="handleArrowKeyRight()" @keydown.left="handleArrowKeyLeft()">
                            <v-row class="px-3">
                                <v-col class='px-1' cols="4">
                                    <v-tooltip :value="shortcutTooltips" :disabled="!showTooltips" bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn :id="'toDoTab'" :color="tab === 'toDo' ? '#e6231e' : 'white'"
                                                   @click="tab = 'toDo'; checkImagePreviews(); setFocusOn('toDoTab')" :dark="tab === 'toDo'"
                                                   style="width: 100%; border: solid 1px #e6231e !important; overflow: hidden; text-overflow: ellipsis"
                                                   elevation="0"
                                                   v-on="on"
                                                   role="tab"
                                                   @keydown.tab="setFocusOnFirstItemTab('toDoIcon-0', 'toDo')">
                                                Unerledigt
                                            </v-btn>
                                        </template>
                                        <div style="display: flex; flex-direction: column; text-align: center">
                                            <span><span style="text-decoration: underline white">H</span>ausaufgaben (Alt + H)</span>
                                            <div>
                                                <img v-if="hoeren" :src="hausaufgabenMetacom" width="100" style="margin: auto" alt="" class="mr-1">
                                                <img v-if="hoeren" :src="toDoMetacom" width="100" style="margin: auto" alt="">
                                            </div>
                                        </div>
                                    </v-tooltip>
                                </v-col>
                                <v-col class='px-1' cols="4">
                                    <v-tooltip :disabled="!showTooltips || !hoeren" bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on"
                                                   :id="'doneTab'"
                                                   :color="tab === 'done' ? '#F49F31' : 'white'" @click="tab = 'done'; checkImagePreviews(); setFocusOn('doneTab')"
                                                   :dark="tab === 'done'"
                                                   style="width: 100%; border: solid 1px #F49F31 !important; overflow: hidden; text-overflow: ellipsis"
                                                   elevation="0"
                                                   role="tab"
                                                   @keydown.tab="setFocusOnFirstItemTab('doneIcon-0', 'done')">
                                                Erledigt
                                            </v-btn>
                                        </template>
                                            <img v-if="hoeren" :src="hausaufgabenMetacom" width="100" style="margin: auto" alt="" class="mr-1">
                                            <img v-if="hoeren" :src="doneMetacom" width="100" style="margin: auto" alt="">
                                    </v-tooltip>
                                </v-col>
                                <v-col class='px-1' cols="4">
                                    <v-tooltip :disabled="!showTooltips || !hoeren" bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on"
                                                   :id="'correctedTab'"
                                                   :color="tab === 'corrected' ? '#8cbe46' : 'white'" @click="tab = 'corrected'; checkImagePreviews(); setFocusOn('correctedTab')"
                                                   :dark="tab === 'corrected'"
                                                   style="width: 100%; border: solid 1px #8cbe46 !important; overflow: hidden; text-overflow: ellipsis"
                                                   elevation="0"
                                                   role="tab"
                                                   @keydown.tab="setFocusOnFirstItemTab('correctedIcon-0', 'corrected')"
                                            >
                                                Korrigiert
                                            </v-btn>
                                        </template>
                                        <img v-if="hoeren" :src="hausaufgabenMetacom" width="100" style="margin: auto" alt="" class="mr-1">
                                        <img v-if="hoeren" :src="correctedMetacom" width="100" style="margin: auto" alt="">
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                            <div v-if="tab ==='toDo' && babyFilesToDoDisplay">

                                <div
                                    v-for="(item, index) in babyFilesToDoDisplay.filter(item => (!('canPip' in item) || item.canPip === true))"
                                    :key="item.file">
                                    <div class="fileRow mx-2 d-flex align-center"
                                         :class="{ brighterFileRow: index % 2 === 1, disabledFileRow: false }">

                                        <!--                                Fach icon-->
                                        <v-tooltip :disabled="!showTooltips" bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-avatar v-on="on" class="ml-2 smallBabyViewActionBtn fachLogo"
                                                          :style="`background-color: ${item.fachColor} !important`">
                                                    <img :id="'toDoIcon-' + index" tabindex="0" :src="getSrc(item.fachIcon)"
                                                         class="smallBabyViewActionBtnImg" :alt="item.groupName" :class="index === 0 ? 'firstItemOderSo' : ''"/>
                                                </v-avatar>
                                            </template>
                                            <span> {{ item.groupName }}</span>
                                        </v-tooltip>
                                        <!--                              Fach icon-->
                                        <!--                              <v-btn small class="ml-2 smallBabyViewActionBtn fachLogo" disabled-->
                                        <!--                                     :style="`background-color: ${item.fachColor} !important`">-->
                                        <!--                                <img :src="getSrc(item.fachIcon)" class="smallBabyViewActionBtnImg"/>-->
                                        <!--                              </v-btn>-->

                                        <!--colored line instead of icon if width < 600-->
                                        <div class="fachLine ma-0 pa-0"
                                             :style="`background-color: ${item.fachColor} !important`">
                                        </div>
                                        <p :id="'toDoItem-' + index" style="width: fit-content" class="fileTitle mx-2 my-0"
                                           @focus="readExerciseName(item)" @click="readExerciseName(item)" tabindex="0">
                                            {{ item.title }}
                                        </p>
                                        <div class="my-0 mr-2" style="display: flex; margin-left: auto">
                                            <v-btn small class="babyViewActionBtn" v-if="false">
                                                <img :src="lautsprecherIcon" class="babyViewActionBtnImg" alt="Vorlesen"/>
                                            </v-btn>
                                            <v-tooltip :disabled="!showTooltips" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" small class="babyViewActionBtn ml-3"
                                                           @click="clickDownloadTeacherUpload(item)">
                                                        <img :src="runterladenIcon" class="babyViewActionBtnImg"
                                                             alt="Herunterladen"/>
                                                    </v-btn>
                                                </template>
                                                <div style="display: flex; flex-direction: column; text-align: center">
                                                    <span>Herunterladen</span>
                                                    <img v-if="hoeren" :src="herunterladenMetacom" width="100" style="margin: auto" alt="">
                                                </div>
                                            </v-tooltip>

                                            <v-tooltip :disabled="!showTooltips" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" small class="babyViewActionBtn ml-3"
                                                           @click="pupilUploadFile(item)">
                                                        <img :src="kameraIcon" class="babyViewActionBtnImg"
                                                             alt="Hochladen"/>
                                                    </v-btn>
                                                </template>
                                                <div style="display: flex; flex-direction: column; text-align: center">
                                                    <span>Hochladen</span>
                                                    <img v-if="hoeren" :src="hochladenMetacom" width="100" style="margin: auto" alt="">
                                                </div>
                                            </v-tooltip>

                                            <v-tooltip
                                                v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(item.title.split('.').pop())"
                                                :disabled="!showTooltips" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        @click="play(item)" small class="babyViewActionBtn edit ml-3" dark
                                                        :loading="videoLoading"
                                                    >
                                                        <img :src="rechtsIcon" class="babyViewActionBtnImg editImg"
                                                             alt="Video abspielen"/>
                                                    </v-btn>
                                                </template>
                                                <span>Video abspielen</span>
                                            </v-tooltip>

                                            <v-tooltip
                                                v-else-if="['wav', 'mp3', 'wma', 'm4a' , 'acc', 'ogg'].includes(item.title.split('.').pop())"
                                                :disabled="!showTooltips" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        @click="playAudio(item)" small class="babyViewActionBtn edit ml-3"
                                                        dark :loading="videoLoading"
                                                    >
                                                        <img :src="rechtsIcon" class="babyViewActionBtnImg editImg"
                                                             alt="Audio abspielen"/>
                                                    </v-btn>
                                                </template>
                                                <span>Audio abspielen</span>
                                            </v-tooltip>

                                            <v-tooltip
                                                v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(item.title.split('.').pop().toLowerCase())
                                                            && (item.isAssignment || item.isAssignment == null)"
                                                :disabled="!showTooltips" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn :id="item._id + '-editButton'" v-on="on" small class="babyViewActionBtn edit ml-3"
                                                           @click="openEdit(item)">
                                                        <img :src="bearbeitenIcon" class="babyViewActionBtnImg editImg"
                                                             alt="im Editor bearbeiten"/>
                                                    </v-btn>
                                                </template>
                                                <div style="display: flex; flex-direction: column; text-align: center">
                                                    <span>Bearbeiten</span>
                                                    <div style="margin: auto">
                                                        <img v-if="hoeren" :src="hausaufgabenMetacom" width="100" class="mr-1" alt="">
                                                        <img v-if="hoeren" :src="toDoMetacom" width="100" alt="">
                                                    </div>
                                                </div>
                                            </v-tooltip>
                                            <v-tooltip
                                                v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(item.title.split('.').pop().toLowerCase())"
                                                :disabled="!showTooltips" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" small class="babyViewActionBtn edit ml-3"
                                                           @click="handleOpenAnsichtClick(item)">
                                                        <img :src="previewIcon" class="babyViewActionBtnImg editImg"
                                                             alt="Ansehen"/>
                                                    </v-btn>
                                                </template>
                                                <span>Ansehen</span>
                                            </v-tooltip>

                                            <v-tooltip v-else :disabled="!showTooltips" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        dark @click="fileNotSupported(item)" x-small
                                                        class="babyViewActionBtn edit ml-3" elevation="0"
                                                        style="background-color: #8CBD46;">
                                                        <img :src="infoIcon" class="babyViewActionBtnImg editImg"
                                                             alt="Dateiformat nicht unterstützt"/>
                                                    </v-btn>
                                                </template>
                                                <span>Dateiformat nicht unterstützt</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                    <div v-if="item.previewData" class="mx-2 mb-1 d-flex justify-center"
                                         style="max-height: 15vh; background-color: gray; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;">
                                        <!-- Display image preview -->
                                        <v-progress-circular
                                            v-if="item.previewData.loading"
                                            indeterminate
                                            class="my-3"
                                            color="#F49F31"
                                        ></v-progress-circular>
                                        <img
                                            v-else-if="['png', 'jpg', 'jpeg', 'bmp', 'mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(item.title.split('.').pop().toLowerCase()) && item.previewData.preview"
                                            @click="() => ['png', 'jpg', 'jpeg', 'bmp', ].includes(item.title.split('.').pop().toLowerCase()) ? openEdit(item) : play(item)"
                                            :src="item.previewData.preview"
                                            style="cursor: pointer; max-width: 345px; max-height: 15vh"/>
                                        <!-- Display pdf preview -->
                                        <object type="application/pdf"
                                                v-else-if="['pdf'].includes(item.title.split('.').pop().toLowerCase()) && item.previewData.preview"
                                                @click="() => openEdit(item)"
                                                :data="item.previewData.preview"
                                                style="cursor: pointer; max-width: 345px; max-height: 15vh"
                                                aria-hidden="true"
                                                tabindex="-1"/>
                                        <div v-else-if="item.previewData.fetched" class="my-2 grey--text text--lighten-5 subtitle-1">Es ist keine Vorschau verfügbar</div>
                                    </div>
                                </div>
                                <!--expand and collapse btns-->
                                <div v-if="babyFilesToDo.length > 5" class="d-flex justify-center my-2">
                                    <v-tooltip v-if="!toDoLong" :disabled="!showTooltips" bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" @click="unsliceList('toDo')" small elevation="0">
                                                <img :src="arrowDown" style="height: 20px;" alt="Alles anzeigen"/>
                                            </v-btn>
                                        </template>
                                        <span>Alles anzeigen</span>
                                    </v-tooltip>

                                    <v-tooltip v-else :disabled="!showTooltips" bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" @click="sliceList('toDo')" small elevation="0">
                                                <img :src="arrowDown" style="height: 20px; transform: rotate(180deg)"
                                                     alt="Weniger anzeigen"/>
                                            </v-btn>
                                        </template>
                                        <span>Weniger anzeigen</span>
                                    </v-tooltip>
                                </div>

                                <v-row
                                    v-if="babyFilesToDoDisplay && babyFilesToDoDisplay.length === 0"
                                    class="emptyRow ma-2"
                                    align="center" justify="center"
                                >
                                    <v-col class="pa-0">
                                        <p tabindex="0" id="toDoEmpty">Aktuell gibt es nichts zu erledigen</p>
                                    </v-col>
                                </v-row>
                            </div>


                            <div v-if="tab === 'done'">
                                <v-row cols="12" style="width: 99%; margin: 0.5em auto 0.5em auto;" v-show="false">
                                    <v-col style="display: flex; align-items: center" class="sectionHeaderCol">
                                        <img :src="doneIcon" style="height: 30px" class="sectionHeaderImg" alt=""/>
                                        <h2 class="pl-2 sectionHeaderText" tabindex="0">Erledigt:</h2>
                                    </v-col>
                                </v-row>

                                <div v-for="(item, index) in babyFilesDoneDisplay" :key="item.file">
                                    <div class="fileRow mx-2 disabledFileRow d-flex align-center"
                                         style="flex-direction: row" :class="{ brighterFileRow: index % 2 === 1 }">

                                        <!-- Fach icon -->
                                        <v-tooltip :disabled="!showTooltips" bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-avatar v-on="on" class="ml-2 smallBabyViewActionBtn fachLogo"
                                                          :style="`background-color: ${item.fachColor} !important`">
                                                    <img :id="'doneIcon-' + index" tabindex="0" :src="getSrc(item.fachIcon)"
                                                         class="smallBabyViewActionBtnImg" :alt="item.groupName"/>
                                                </v-avatar>
                                            </template>
                                            <span> {{ item.groupName }}</span>
                                        </v-tooltip>

                                        <!--colored line instead of icon if width < 600-->
                                        <div class="fachLine ma-0 pa-0"
                                             :style="`background-color: ${item.fachColor} !important`">
                                        </div>

                                        <p tabindex="0" style="width: fit-content" class="fileTitle mx-2 my-0" @click="readExerciseName(item)">
                                            {{ item.title }}
                                        </p>

                                        <div class="my-0 mr-2" style="display: flex; margin-left: auto" >
                                            <v-btn small class="babyViewActionBtn" v-if="false">
                                                <img :src="lautsprecherIcon" class="babyViewActionBtnImg"
                                                     alt="Vorlesen"/>
                                            </v-btn>

                                            <v-tooltip
                                                v-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(item.title.split('.').pop().toLowerCase())"
                                                :disabled="!showTooltips" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on"
                                                           small class="babyViewActionBtn ml-3"
                                                           @click="handleOpenAnsichtClick(item)">
                                                        <!--                                        <v-icon style="width: 20px; color: #939393" alt="Vorschausymbol">fas fa-eye</v-icon>-->
                                                        <img :src="previewIcon" style="width: 30px; color: #939393"
                                                             alt="Vorschau"/>
                                                    </v-btn>
                                                </template>
                                                <span>Vorschau</span>
                                            </v-tooltip>

                                            <v-tooltip v-else :disabled="!showTooltips" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-bind="on" small class="babyViewActionBtn ml-3"
                                                           @click="downloadCorrectFile(item)">
                                                        <img :src="runterladenIcon" class="babyViewActionBtnImg"
                                                             alt="Herunterladen"/>
                                                    </v-btn>
                                                </template>
                                                <span>Herunterladen</span>
                                            </v-tooltip>

                                            <v-tooltip :disabled="!showTooltips" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on" small class="babyViewActionBtn ml-3"
                                                        @click="deleteDialog = true; elementToDelete = item">
                                                        <img :src="papierkorbIcon" class="babyViewActionBtnImg"
                                                             alt="Datei löschen"/>
                                                    </v-btn>
                                                </template>
                                                <div style="display: flex; flex-direction: column; text-align: center">
                                                    <span>Datei löschen</span>
                                                    <img v-if="hoeren" :src="loeschenMetacom" width="100" style="margin: auto" alt="">
                                                </div>
                                            </v-tooltip>
                                        </div>
                                    </div>


                                    <div v-if="item.previewData" class="mx-2 mb-1 d-flex justify-center"
                                         style="max-height: 15vh; background-color: gray; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;">
                                        <!-- Display image preview -->
                                        <v-progress-circular
                                            v-if="item.previewData.loading"
                                            indeterminate
                                            class="my-3"
                                            color="#F49F31"
                                        ></v-progress-circular>
                                        <img
                                            v-else-if="['png', 'jpg', 'jpeg', 'bmp', 'mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(item.title.split('.').pop().toLowerCase()) && item.previewData.preview"
                                            @click="() => ['png', 'jpg', 'jpeg', 'bmp', ].includes(item.title.split('.').pop().toLowerCase()) ? openEdit(item) : play(item)"
                                            :src="item.previewData.preview"
                                            style="cursor: pointer; max-width: 345px; max-height: 15vh"/>
                                        <!-- Display pdf preview -->
                                        <object type="application/pdf"
                                                v-else-if="['pdf'].includes(item.title.split('.').pop().toLowerCase()) && item.previewData.preview"
                                                @click="() => openEdit(item)"
                                                :data="item.previewData.preview"
                                                style="cursor: pointer; max-width: 345px; max-height: 15vh"/>
                                        <div v-else-if="item.previewData.fetched" class="my-2 grey--text text--lighten-5 subtitle-1">Es ist keine Vorschau verfügbar</div>
                                        <!-- Display video preview -->
                                        <!--                                    <video-->
                                        <!--                                        v-else-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(item.title.split('.').pop().toLowerCase())"-->
                                        <!--                                        @click="() => play(item)"-->
                                        <!--                                        :src="item.pupilBlob"-->
                                        <!--                                        style="cursor: pointer;  max-width: 345px; max-height: 15vh"/>-->
                                    </div>
                                </div>
                              <!--expand and collapse btns-->
                              <div v-if="babyFilesDone.length > 5" class="d-flex justify-center my-2">
                                <v-tooltip v-if="!doneLong" :disabled="!showTooltips" bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" @click="unsliceList('done')" small elevation="0">
                                      <img :src="arrowDown" style="height: 20px;" alt="Alles anzeigen"/>
                                    </v-btn>
                                  </template>
                                  <span>Alles anzeigen</span>
                                </v-tooltip>

                                <v-tooltip v-else :disabled="!showTooltips" bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" @click="sliceList('done')" small elevation="0">
                                      <img :src="arrowDown"
                                           style="height: 20px; transform: rotate(180deg)"
                                           alt="Weniger anzeigen"/>
                                    </v-btn>
                                  </template>
                                  <span>Weniger anzeigen</span>
                                </v-tooltip>
                              </div>

                                <v-row
                                    v-if="babyFilesDone && babyFilesDone.length === 0"
                                    class="emptyRow ma-2"
                                    align="center" justify="center"
                                >
                                    <v-col class="pa-0">
                                        <p tabindex="0" id="doneEmpty">Aktuell gibt es noch nichts Erledigtes</p>
                                    </v-col>
                                </v-row>
                            </div>


                            <div v-if="tab === 'corrected'">
                                <v-row cols="12" style="width: 99%; margin: 0.5em auto 0.5em auto;" v-show="false">
                                    <v-col style="display: flex; align-items: center" class="sectionHeaderCol">
                                        <img :src="correctedIcon" style="height: 30px" class="sectionHeaderImg"
                                             alt=""/>
                                        <h2 class="pl-2 sectionHeaderText" tabindex="0">Korrigiert:</h2>
                                    </v-col>
                                </v-row>

                                <div v-for="(item, index) in babyFilesCorrectedDisplay" :key="item.file">
                                    <div class="fileRow mx-2 d-flex align-center"
                                         :class="{ brighterFileRow: index % 2 === 1, disabledFileRow: false }">

                                        <!--Fach icon-->
                                        <v-tooltip :disabled="!showTooltips" bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-avatar v-on="on" class="ml-2 smallBabyViewActionBtn fachLogo"
                                                          :style="`background-color: ${item.fachColor} !important`">
                                                    <img :id="'correctedIcon-' + index" tabindex="0" :src="getSrc(item.fachIcon)"
                                                         class="smallBabyViewActionBtnImg" :alt="item.groupName"/>
                                                </v-avatar>
                                            </template>
                                            <span> {{ item.groupName }}</span>
                                        </v-tooltip>

                                        <!--colored line instead of icon if width < 600-->
                                        <div class="fachLine ma-0 pa-0"
                                             :style="`background-color: ${item.fachColor} !important`">
                                        </div>

                                        <p tabindex="0" style="width: fit-content" class="fileTitle mx-2 my-0" @click="readExerciseName(item)">
                                            {{ item.title }}
                                        </p>

                                        <div class="my-0 mr-2" style="display: flex; margin-left: auto">
                                            <v-btn small class="babyViewActionBtn" v-if="false">
                                                <img :src="lautsprecherIcon" class="babyViewActionBtnImg"
                                                     alt="Vorlesen"/>
                                            </v-btn>

                                            <v-tooltip
                                                v-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(item.title.split('.').pop().toLowerCase())"
                                                :disabled="!showTooltips" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on"
                                                           small class="babyViewActionBtn ml-3"
                                                           @click="handleOpenAnsichtClick(item)">
                                                        <!--                                        <v-icon style="width: 20px; color: #939393" alt="Vorschausymbol">fas fa-eye</v-icon>-->
                                                        <img :src="previewIcon" style="width: 30px; color: #939393"
                                                             alt="Vorschau"/>
                                                    </v-btn>
                                                </template>
                                                <span>Vorschau</span>
                                            </v-tooltip>

                                            <v-tooltip v-else :disabled="!showTooltips" bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-bind="on" small class="babyViewActionBtn ml-3"
                                                           @click="downloadCorrectFile(item)">
                                                        <img :src="runterladenIcon" class="babyViewActionBtnImg"
                                                             alt="Herunterladen"/>
                                                    </v-btn>
                                                </template>
                                                <span>Herunterladen</span>
                                            </v-tooltip>
                                        </div>
                                    </div>

                                    <!--expand and collapse btns-->
                                    <div v-if="babyFilesCorrected.length > 5" class="d-flex justify-center my-2">
                                        <v-tooltip v-if="!correctedLong" :disabled="!showTooltips" bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" @click="unsliceList()" small elevation="0">
                                                    <img :src="arrowDown" style="height: 20px;"
                                                         alt="Alles anzeigen"/>
                                                </v-btn>
                                            </template>
                                            <span>Alles anzeigen</span>
                                        </v-tooltip>

                                        <v-tooltip v-else :disabled="!showTooltips" bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" @click="sliceList()" small elevation="0">
                                                    <img :src="arrowDown"
                                                         style="height: 20px; transform: rotate(180deg)"
                                                         alt="Weniger anzeigen"/>
                                                </v-btn>
                                            </template>
                                            <span>Weniger anzeigen</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-if="item.previewData" class="mx-2 mb-1 d-flex justify-center"
                                         style="max-height: 15vh; background-color: gray; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;">
                                        <!-- Display image preview -->
                                        <v-progress-circular
                                            v-if="item.previewData.loading"
                                            indeterminate
                                            class="my-3"
                                            color="#F49F31"
                                        ></v-progress-circular>
                                        <img
                                            v-else-if="['png', 'jpg', 'jpeg', 'bmp', 'mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(item.title.split('.').pop().toLowerCase()) && item.previewData.preview"
                                            @click="() => ['png', 'jpg', 'jpeg', 'bmp', ].includes(item.title.split('.').pop().toLowerCase()) ? openEdit(item) : play(item)"
                                            :src="item.previewData.preview"
                                            style="cursor: pointer; max-width: 345px; max-height: 15vh"/>
                                        <!-- Display pdf preview -->
                                        <object type="application/pdf"
                                                v-else-if="['pdf'].includes(item.title.split('.').pop().toLowerCase()) && item.previewData.preview"
                                                @click="() => openEdit(item)"
                                                :data="item.previewData.preview"
                                                style="cursor: pointer; max-width: 345px; max-height: 15vh"/>
                                        <div v-else-if="item.previewData.fetched" class="my-2 grey--text text--lighten-5 subtitle-1">Es ist keine Vorschau verfügbar</div>
                                        <!-- Display video preview -->
                                        <!--                                    <video-->
                                        <!--                                        v-else-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(item.title.split('.').pop().toLowerCase())"-->
                                        <!--                                        @click="() => play(item)"-->
                                        <!--                                        :src="item.correctedBlob"-->
                                        <!--                                        style="cursor: pointer;  max-width: 345px; max-height: 15vh"/>-->
                                    </div>
                                </div>
                                <v-row
                                    v-if="babyFilesCorrected && babyFilesCorrected.length === 0"
                                    class="emptyRow ma-2"
                                    align="center" justify="center"
                                >
                                    <v-col class="pa-0">
                                        <p tabindex="0" id="correctedEmpty">Aktuell gibt es nichts Korrigiertes</p>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </vue-custom-scrollbar>
                </div>
            </v-col>
        </v-row>


        <!-- popups, overlays and snackbars -->
        <!--        <v-overlay opacity="0.95" v-if="videoUrl" z-index="95">-->
        <!--            <v-icon alt="Video schließen" tabindex="2" large style="position: fixed; top: 50px; right: 50px; z-index: 99" @click="videoUrl = false" dark>-->
        <!--                fas fa-times-->
        <!--            </v-icon>-->
        <!--            <video id="videoPlayer" tabindex="1" :src="videoUrl" controls :width="windowWidth > 900 ? '80%' : '40%'"-->
        <!--                   :style="{ marginLeft: windowWidth > 900 ? '10%' : '55%' }">-->
        <!--                Your browser does not support the video tag.-->
        <!--            </video>-->
        <!--        </v-overlay>-->

        <!-- Normal Video Popup -->
        <v-dialog
            :value="videoUrl"
            v-if="videoUrl"
            overlay-opacity=".9"
            overlay-color="black"
            max-width="90%"
            width="unset"
            @click:outside="videoUrl = null; $refs.normalVideo.stop();"
        >
            <v-row justify="end" align="end">
                <v-col cols="1" align-self="end">
                    <v-icon large
                            @click="videoUrl = null; primaryVideoUrl = null; secondaryVideoUrl = null; $refs.normalVideo.stop();"
                            dark>
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <video-in-video ref="normalVideo" :primary-video-src="videoUrl" :subtitle-src="subtitleURL"></video-in-video>
                </v-col>
            </v-row>
        </v-dialog>

        <!-- Video in Video Popup -->
        <v-dialog
            :value="primaryVideoUrl && secondaryVideoUrl"
            overlay-opacity=".9"
            v-if="primaryVideoUrl && secondaryVideoUrl"
            overlay-color="black"
            max-width="90%"
            width="unset"
            @click:outside="primaryVideoUrl = null; secondaryVideoUrl = null; $refs.videoInVideo.stop();"
        >
            <v-row justify="end" align="end">
                <v-col cols="1" align-self="end">
                    <v-icon large @click="primaryVideoUrl = null; secondaryVideoUrl = null; $refs.videoInVideo.stop();"
                            dark>
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <video-in-video size-controls ref="videoInVideo" :primary-video-src="primaryVideoUrl"
                                    :secondary-video-src="secondaryVideoUrl" :subtitle-src="subtitleURL"></video-in-video>
                </v-col>
            </v-row>
        </v-dialog>

        <v-dialog overlay-opacity=".9"
                  :value="audioUrl"
                  v-if="audioUrl"
                  z-index="95"
                  overlay-color="black"
                  max-width="90%"
                  width="unset"
                  @click:outside="audioUrl = null;">
            <v-row justify="end" align="end">
                <v-col cols="1" align-self="end">
                    <v-icon large @click="audioUrl = null;" dark>
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <audio id="audioPlayer" :src="audioUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"
                           :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">
                        Your browser does not support the video tag.
                    </audio>
                </v-col>
            </v-row>
            <!--            <v-icon alt="Audio schließen" tabindex="2" large style="position: fixed; top: 50px; right: 50px; z-index: 99" @click="audioUrl = false" dark>-->
            <!--                fas fa-times-->
            <!--            </v-icon>-->
            <!--            <audio id="audioPlayer" alt="" tabindex="1" :src="audioUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"-->
            <!--                   :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">-->
            <!--                Your browser does not support the video tag.-->
            <!--            </audio>-->
        </v-dialog>

        <v-dialog v-model="deleteDialog" max-width="350px">
            <v-card>
                <v-card-title>
                    Löschen bestätigen
                </v-card-title>

                <v-card-text>
                    Willst du die Datei wirklich <strong>unwiderruflich</strong> löschen?
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn @click="clickDeleteUpload" class="mr-2 text-capitalize" color="rot" dark>
                        <img :src="papierkorbIcon" class="iconToWhite mr-2">
                        Löschen
                    </v-btn>

                    <v-btn @click="deleteDialog = false" class="mr-2 text-capitalize" color="gruen" dark>
                        <img :src="schliesenIcon" class="iconToWhite mr-2">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar bottom v-model="snackbarFileNotSupported" color="grey" timeout="5000"
                    style="z-index: 9999 !important">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbarFileNotSupported = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="schliessenIcon" />
            </v-btn>
          </template>
            Dieses Dateiformat kann in eKlara nicht bearbeitet werden. Bitte lade die Datei herunter.
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarDelete" :color="snackbarDeleteColor" timeout="3000">
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    small
                    @click="snackbarDelete = false"
                >
                    <img style="max-width: 20px" alt="Schließen" :src="schliesenIcon" />
                </v-btn>
            </template>
            {{ snackbarDeleteText }}
        </v-snackbar>
    </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import teacherIcon from '../../assets/Icons/lehrer-24.svg'
import bearbeitenIcon from '../../assets/Icons/bearbeiten-komplimentär-weiß-88.svg'
import linksIcon from '../../assets/Icons/links-10.svg'
import rechtsIcon from '../../assets/Icons/rechts-filled-11.svg'
import schliessenIcon from '../../assets/Icons/abbrechen-08.svg'
import hausaufgabenIconLeer from '../../assets/Icons/dokumente-56 .svg'
import runterladenIcon from '../../assets/Icons/runterladen-12.svg'
import lautsprecherIcon from '../../assets/Icons/lautsprecher-13.svg'
import lautsprecherWeissIcon from '../../assets/Icons/lautsprecher-weiss-13.svg'
import lautsprecherWeissAusIcon from '../../assets/Icons/lautsprecher-weiss-aus-89.svg'
import kameraIcon from '../../assets/Icons/kamera-15.svg'
import toDoIcon from '../../assets/Icons/noch-zu-machen-orange-89.svg'
import doneIcon from '../../assets/Icons/erledigt-orange-90.svg'
import correctedIcon from '../../assets/Icons/korrigiert-orange-91.svg'
import arrowDown from '../../assets/Icons/unten-dropdown-12.svg'
import infoIcon from "../../assets/Icons/info-45.svg";
import previewIcon from "../../assets/Icons/preview-85.svg";
import papierkorbIcon from "../../assets/Icons/papierkorb-109.svg";
import schliesenIcon from '../../assets/Icons/abbrechen-08.svg'

import bioIcon from '../../assets/Icons/f-biologie-35.svg'
import chemieIcon from '../../assets/Icons/f-chemie-37.svg'
import deutschIcon from '../../assets/Icons/f-deutsch-29.svg'
import englischIcon from '../../assets/Icons/f-englisch-30.svg'
import erdkundeIcon from '../../assets/Icons/f-erdkunde-31.svg'
import geschichteIcon from '../../assets/Icons/f-geschichte-40.svg'
import matheIcon from '../../assets/Icons/f-mathe-32.svg'
import musikIcon from '../../assets/Icons/f-musik-84.svg'
import physikIcon from '../../assets/Icons/f-physik-36.svg'
import politikIcon from '../../assets/Icons/f-politik-34.svg'
import religionIcon from '../../assets/Icons/f-religion-39.svg'
import sportIcon from '../../assets/Icons/f-sport-38.svg'
import wirtschaftIcon from '../../assets/Icons/f-wirtschaft-33.svg'
import hausaufgabenMetacom from '../../assets/METACOM/Hausis/hausaufgaben.png'
import toDoMetacom from '../../assets/METACOM/Hausis/machen.png'
import doneMetacom from '../../assets/METACOM/Hausis/beenden.png'
import correctedMetacom from '../../assets/METACOM/Hausis/Korrektur.png'
import herunterladenMetacom from '../../assets/METACOM/Hausis/speichern.png'
import hochladenMetacom from '../../assets/METACOM/Hausis/fotoapparat.png'
import loeschenMetacom from '../../assets/METACOM/Allgemein/loeschendaten.png'


import {mapActions, mapGetters, mapState} from "vuex";
import * as backend from "../../api/backend";
import {tutorials} from '@/constants/tutorial';
import ProgressBar from "../ProgressBar";
import AntiScroll from "../AntiScroll";
import VideoInVideo from "@/components/Utils/VideoInVideo";

export default {
    name: "HausiWidgetBaby",
    components: {
        VideoInVideo,
        AntiScroll,
        vueCustomScrollbar,
        ProgressBar,
    },
    data() {
        return {
            subtitleURL: null,
            noSubtitle: false,
            tab: 'toDo',
            windowWidth: window.innerWidth,
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },
            showPupilUploadProgress: false,
            pupilUploadProgress: 0.0,
            pupilXmlHttpRequest: null,
            appointments: [],

            babyFilesToDo: [],
            babyFilesCorrected: [],
            babyFilesDone: [],

            /*to toggle btwn showing 3 items and whole list*/
            babyFilesToDoDisplay: [],
            babyFilesCorrectedDisplay: [],
            babyFilesDoneDisplay: [],
            toDoLong: false,
            doneLong: false,
            correctedLong: false,
            snackbarFileNotSupported: false,
            showLoading: false,

            tutorials,
            person: {
                accessibility: {
                    screenreader: false,
                }
            },
            account: '',
            videoUrl: null,
            audioUrl: null,
            videoLoading: false,
            primaryVideoUrl: null,
            secondaryVideoUrl: null,

            sehen: false,
            hoeren: false,
            babyView: false,

            deleteDialog: false,
            elementToDelete: null,
            snackbarDelete: false,
            snackbarDeleteText: '',
            snackbarDeleteColor: 'success',

            teacherIcon,
            infoIcon,
            bearbeitenIcon,
            linksIcon,
            rechtsIcon,
            schliessenIcon,
            hausaufgabenIconLeer,
            runterladenIcon,
            lautsprecherIcon,
            lautsprecherWeissIcon,
            lautsprecherWeissAusIcon,
            kameraIcon,
            toDoIcon,
            doneIcon,
            correctedIcon,
            arrowDown,
            previewIcon,
            papierkorbIcon,
            schliesenIcon,
            hausaufgabenMetacom,
            toDoMetacom,
            doneMetacom,
            correctedMetacom,
            herunterladenMetacom,
            hochladenMetacom,
            loeschenMetacom,

            fachIcons: [
                {name: 'bioIcon', icon: bioIcon},
                {name: 'chemieIcon', icon: chemieIcon},
                {name: 'deutschIcon', icon: deutschIcon},
                {name: 'englischIcon', icon: englischIcon},
                {name: 'erdkundeIcon', icon: erdkundeIcon},
                {name: 'geschichteIcon', icon: geschichteIcon},
                {name: 'matheIcon', icon: matheIcon},
                {name: 'musikIcon', icon: musikIcon},
                {name: 'physikIcon', icon: physikIcon},
                {name: 'politikIcon', icon: politikIcon},
                {name: 'religionIcon', icon: religionIcon},
                {name: 'sportIcon', icon: sportIcon},
                {name: 'wirtschaftIcon', icon: wirtschaftIcon},
            ],
        };
    },
    props: {
        small: {required: false, default: false},
    },
    async created() {
        let temp = await this.getMePupil();
        this.person = temp;
        this.account = temp.account;
        this.babyView = temp.babyView;
        if(temp.accessibility) {
            this.sehen = temp.accessibility.sehen;
            this.hoeren = temp.accessibility.hoeren;
        } else {
            this.sehen = false;
            this.hoeren = false;
        }
        //to check if they are babys
        if (this.babyView) {
            this.babyViewTut = true;
        }
        await this.getAllTeacherFiles();
        this.sliceLists();
    },
    computed: {
        ...mapState("auth", ["token"]),
        ...mapState('translation', ['targetLang']),
        ...mapGetters('pupils', ['getTutorialProgress']),
        ...mapGetters('util', ['currentlyOpenTeacherUploadId']),
        ...mapState('magnifier', ["magnifier"]),
        ...mapState('util', ['keyboard']),
        ...mapState("speechControl", ["word"]),
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),
        ...mapGetters("gamepad", { gamepadBButtonPressed: "bButtonPressed"}),
        getTutProgress() {
            if (!this.account)
                return [];
            return this.getTutorialProgress[this.account] || [];
        },
    },
    async beforeMount() {
        this.account = (await this.getMePupil()).account;
    },
    async mounted() {
        window.addEventListener("keyup", event => {
            if (event.altKey && event.key === 'h') {
                this.focusOnHausaufgaben();
                this.triggerShortcutTooltips();
            }
        });
        this.account = (await this.getMePupil()).account;
        this.requestAppointments();
    },
    watch: {
        word(newVal) {
            if (newVal === 'hausaufgaben') {
                this.focusOnHausaufgaben();
            }
        },
        async currentlyOpenTeacherUploadId(newVal, oldVal) {
            if (oldVal && !newVal) {
                await this.getAllTeacherFiles();
                this.sliceLists();
            }
        },
        gamepadBButtonPressed(newVal, oldVal) {
            // on keyup of gamepadYButton
            if(oldVal === true && newVal === false) {
                this.focusOnHausaufgaben();
            }
        }
    },
    methods: {
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions('pupils', [
            'setTutorialProgress',
            'createPupil',
            'getMePupil',
        ]),
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),
        ...mapActions("util", ['toggleLoading', 'toggleOpenAppointment', 'toggleCurrentUploadGroup', 'toggleOpenTeacherUploadId', 'insertLastOpenedInEditor']),
        ...mapActions("tooltips", ["triggerShortcutTooltips"]),

        focusOnHausaufgaben() {
            this.tab = 'toDo';
            if (!this.magnifier) {
                if (this.babyFilesToDoDisplay.length === 0) {
                    document.getElementById("toDoTab").focus();
                } else {
                    document.getElementById("toDoItem-0").focus();
                }
            } else {
                if (this.babyFilesToDoDisplay.length === 0) {
                    document.getElementById("toDoTabMagnifier").focus();
                } else {
                    document.getElementById("toDoItemMagnifier-0").focus();
                }
            }
        },

        async finishTut(progress) {
            this.setTutorialProgress({account: this.account, progress});
        },

        async clickDownloadTeacherUpload(file) {
            file.seenByMe = true;
            const res = await backend.getTeacherUpload(file.appointmentId, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = file.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                });
        },

        async clickDeleteUpload() {
            let file = this.elementToDelete;

            // find pupil upload
            const neededAppointment = this.appointments.filter(app => app._id === this.elementToDelete.appointmentId)[0];
            const fileToDelete = neededAppointment.pupilUpload.filter(
                upload => upload.uploader === this.account && file.editLeafs.includes(upload._id)
            )[0];

            const res = await backend.deleteUpload((fileToDelete._id).toString());

            if (res.status === 204) {
                this.snackbarDeleteText = "Datei " + file.title + " erfolgreich gelöscht"
                this.snackbarDelete = true;
            } else {
                this.snackbarDeleteText = "Beim Löschen ist ein Fehler aufgetreten"
                this.snackbarDeleteColor = 'error';
                this.snackbarDelete = true;
            }

            this.deleteDialog = false;

            this.getAllTeacherFiles(); //reload files and sort in tabs/arrays
            this.elementToDelete = null; //clear temp reference to file
        },

        getSrc(icon) {
            const foundItem = this.fachIcons.find(item => item.name === icon);
            return foundItem.icon;
        },

        async requestAppointments() {
            this.appointments = await this.getAppointments();
        },

        getAllTeacherFiles() {
            this.showLoading = true;

            this.babyFilesToDo = [];
            this.babyFilesCorrected = [];
            this.babyFilesDone = [];
            this.getAppointments().then(async (appointments) => {
                for (let i = 0; i < appointments.length; i++) {
                    const appointment = appointments[i];
                    if (appointment.teacherUploads.length) {
                        for (let j = 0; j < appointment.teacherUploads.length; j++) {
                            // Check if this is a picture in picture record -> don't show this
                            const element = appointment.teacherUploads[j];
                            // Add upload if there is no canPip-Property or if its true
                            element.fachIcon = appointment.schoolSubject.icon;
                            element.fachColor = appointment.schoolSubject.color;
                            element.groupName = appointment.schoolSubject.name;
                            element.appointmentId = appointment._id;
                            element.isDone = appointment.teacherUploads[j].editLeafs.length > 0;
                            element.previewData = null;
                            // The default preview data (only added if some files are really there)
                            let previewData = {
                                fileId: element.file,
                                loading: false,
                                fetched: false,
                                preview: null
                            }

                            if (this.isOpen(appointment, element)) {
                                element.previewData = previewData;
                                this.babyFilesToDo.push(element);
                            } else if (element.editParent && !element.seenByMe) {
                                let correctedFile = appointment.pupilUpload.filter(
                                    upload => upload.editLeafs && upload.editLeafs.length > 0 && upload.editParent === element._id);
                                if (correctedFile && correctedFile.length > 0 && correctedFile.editLeafs && correctedFile.editLeafs.leading > 0) {
                                    // Only set the preview data if there is a corrected file
                                    // Set the correct id of the file
                                    previewData.fileId = correctedFile[0].editLeafs[correctedFile[0].editLeafs.length - 1];
                                    element.previewData = previewData;
                                }
                                this.babyFilesCorrected.push(element);
                            } else {
                                let pupilFile = appointment.pupilUpload.filter(upload => upload.uploader === this.account && upload.editParent === element._id);
                                if (pupilFile && pupilFile.length > 0) {
                                    previewData.fileId = pupilFile[0].file;
                                    element.previewData = previewData;
                                }else
                                    previewData = null;
                                this.babyFilesDone.push(element);
                            }
                        }
                    }
                }
                // console.log("ToDo List: ", this.babyFilesToDo);
                // console.log("Done List: ", this.babyFilesDone);
                this.sliceLists();
                this.showLoading = false;
            });
        },
        async fetchPreview(element, isTeacherUpload){
            // Check if the element even has a preview and if it hasn't been fetched yet
            if(!element.previewData || element.previewData.fetched || element.previewData.loading)
                return;
            element.previewData.loading = true;
            try {

                let res;
                if (isTeacherUpload) {
                    // Fetch teacher upload
                    if (element.title.endsWith('.pdf')) // Fetch the pdf file
                        res = await backend.getTeacherUpload(element.appointmentId, element.previewData.fileId);
                    else    // Fetch the thumbnail
                        res = await backend.getTeacherUploadThumbnail(element.appointmentId, element.previewData.fileId);
                    if (res.ok && res.status === 200) {
                        let blob = await res.blob();
                        element.previewData.preview = window.URL.createObjectURL(blob);
                    }
                } else {
                    // Fetch pupil upload
                    if(element.title.endsWith('.pdf')) {
                        console.log("fetching normal");
                        res = await backend.getPupilUpload(element.appointmentId, element.previewData.fileId);
                    }else {
                        console.log("fetching thumbnail");
                        res = await backend.getPupilUploadThumbnail(element.appointmentId, element.previewData.fileId);
                    }
                    if (res.ok && res.status === 200) {
                        let blob = await res.blob();
                        element.previewData.preview = window.URL.createObjectURL(blob);
                    }
                }
            }catch (e) {
                console.log(e);
            }
            // Set fetched to true, to not fetch it again
            element.previewData.loading = false;
            element.previewData.fetched = true;

            // Sorry: I tried it with keys and a subcomponents, but nothing worked, this is the only thing that forces the list to update...
            this.$forceUpdate();
        },
        isOpen(appointment, element) {
            /** Needs explicit check for false (for now) because ! could also mean not defined */
            if (element.isAssignment === false && element.seenByMe) {
                return false;
            }

            const checkingLeafs = appointment.pupilUpload.filter(
                upload => upload.uploader === this.account && element.editLeafs.includes(upload._id)
            );

            return checkingLeafs.length === 0 && !element.editParent;

        },
        isDone(appointment, icon) {
            //check if file belongs to user by comparing uploaderId and accountId
            const newArray = appointment.pupilUpload.filter(upload => upload.uploader === this.account);

            for (let i = 0; i < newArray.length; i++) {
                if (!this.babyFilesDone.includes(newArray[i])) {
                    newArray[i].appointment = appointment._id;
                    newArray[i].fachIcon = icon;

                    this.babyFilesDone.push(newArray[i]);
                }
                return true;
            }
            return false;
        },
        isCorrectionAvailable(appointment) {
            const newArray = appointment.pupilUpload.filter(upload => upload.uploader === this.account);

            for (let i = 0; i < newArray.length; i++) {
                for (let j = 0; j < appointment.teacherUploads.length; j++) {
                    //is teacherUploadId (correction) same as pupilUploads editLeaf
                    if ((appointment.teacherUploads[j]._id).toString() === (newArray[i].editLeafs).toString()) {
                        if (!this.babyFilesCorrected.includes(appointment.teacherUploads[j])) {
                            this.babyFilesCorrected.push(appointment.teacherUploads[j]);
                        }
                        return true;
                    }
                }
            }
            return false;
        },
        async downloadCorrectFile(file) {

            if (file.editParent && !file.editLeafs.length) {
                // File is Correction and seen by user
                await this.clickDownloadTeacherUpload(file);
            } else {
                // File is pupil upload that is not corrected
                await this.clickDownloadPupilUpload(file);

            }
        },

        async clickDownloadPupilUpload(file) {
            // find pupil upload
            const neededAppointment = this.appointments.filter(app => app._id === file.appointmentId)[0];

            const neededPupilUpload = neededAppointment.pupilUpload.filter(
                upload => upload.uploader === this.account && file.editLeafs.includes(upload._id)
            )[0];

            const res = await backend.getPupilUpload(file.appointmentId, neededPupilUpload.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = neededPupilUpload.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                });
        },

        async play(file) {
            // this.setFocusOn("videoPlayer");
            file.seenByMe = true;
            this.videoLoading = true;
            if(file.subtitle) {
                const subtitle = await backend.getSubtitle(file.subtitle);
                this.subtitleURL = window.URL.createObjectURL(await subtitle.blob());
            } else {
                this.noSubtitle = true;
            }
            let pipFile = null;
            if (file.pipReference)
                pipFile = this.babyFilesToDo.find(value => value._id === file.pipReference);
            console.log(file.pipReference);
            if (pipFile) {
                // PiP Video
                const primary = await backend.getTeacherUpload(file.appointmentId, file.file);
                const secondary = await backend.getTeacherUpload(file.appointmentId, pipFile.file);
                this.primaryVideoUrl = window.URL.createObjectURL(await primary.blob());
                this.secondaryVideoUrl = window.URL.createObjectURL(await secondary.blob());
            } else {
                const res = await backend.getTeacherUpload(file.appointmentId, file.file);
                this.videoUrl = window.URL.createObjectURL(await res.blob());
            }
            this.videoLoading = false;
        },

        async playAudio(file) {
            // this.setFocusOn("audioPlayer");
            file.seenByMe = true;
            this.videoLoading = true;
            const res = await backend.getTeacherUpload(file.appointmentId, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    console.log(blob);
                    var url = window.URL.createObjectURL(blob);
                    console.log(url);
                    this.audioUrl = url;
                    this.videoLoading = false;
                });

        },

        openEdit(item) {
            const title = item.title;
            const file = item.file;
            const id = item._id;
            const group = item.groupName;
            const color = item.fachColor;
            const appointmentId = item.appointmentId;
            this.insertLastOpenedInEditor(id);

            this.$router.push({
                name: 'schueler.edit',
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: title,
                        fId: file,
                        uploadid: id
                    }
            });
        },

        handleOpenAnsichtClick(element) {
            console.log('handleOpenAnsichtClick called with element', element);
            const neededAppointment = this.appointments.filter(app => app._id === element.appointmentId)[0];
            console.log('appointment', neededAppointment);
            console.log('account', this.account);
            // console.log()
            // const pupilFile = neededAppointment.pupilUpload.filter(
            //     item => (element.editLeafs && element.editLeafs.includes(item._id)) && item.uploader === this.account
            // )[0];
            // console.log('pupilFile', pupilFile);

            if (element.editParent && !element.editLeafs.length || !element.isAssignment) {
                this.openAnsicht(neededAppointment, element.title, element.file, element._id, 'pupil');
            } else {
                const pupilFile = neededAppointment.pupilUpload.filter(
                    item => (element.editLeafs && element.editLeafs.includes(item._id)) && item.uploader === this.account
                )[0];
                this.openAnsicht(neededAppointment, pupilFile.title, pupilFile.file, pupilFile._id, 'teacher')
            }

            // if (this.isCorrectionFileAvailable(neededAppointment, pupilFile)) {
            //     const fileToOpen = this.getCurrentCorrection(neededAppointment, pupilFile);
            //
            //     if (fileToOpen) {
            //         this.openAnsicht(neededAppointment, fileToOpen.title, fileToOpen.file, fileToOpen._id, 'pupil');
            //     }
            // } else {
            //     console.log('no correction');
            //     this.openAnsicht(neededAppointment, pupilFile.title, pupilFile.file, pupilFile._id, 'teacher')
            // }
        },
        bypassOpenAnsicht(element){
          const neededAppointment = this.appointments.filter(app => app._id === element.appointmentId)[0];

          this.openAnsicht(neededAppointment, element.title, element.file, element.id, 'pupil')
        },

        isCorrectionFileAvailable(appointment, pupilFile) {
            console.log(appointment);
            for (let i = 0; i < appointment.teacherUploads.length; i++) {
                if (appointment.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(appointment.teacherUploads[i]._id);
                    if (index !== -1) {
                        return true;
                    }
                }
            }
            return false;
        },

        getCurrentCorrection(appointment, pupilFile) {
            for (let i = 0; i < appointment.teacherUploads.length; i++) {
                if (appointment.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(appointment.teacherUploads[i]._id);
                    if (index !== -1) {
                        return appointment.teacherUploads[i];
                    }
                }
            }
            return null;
        },

        openAnsicht(appointment, name, id, uploadId, userGroup) {

            const group = appointment.schoolSubject.name;
            const color = appointment.schoolSubject.color;
            const appointmentId = appointment._id;
            const fileTitle = name;
            const fileId = id;

            this.$router.push({
                name: 'schueler.view',
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: fileTitle,
                        fId: fileId,
                        uploadid: uploadId,
                        ug: userGroup
                    }
            });
        },

        getAppointment(slot, day) {
            switch (day) {
                case 'mon':
                    day = this.getMonday(this.date);
                    break;
                case 'die':
                    day = this.getTuesday(this.date);
                    break;
                case 'mit':
                    day = this.getWednesday(this.date);
                    break;
                case 'don':
                    day = this.getThursday(this.date);
                    break;
                case 'fre':
                    day = this.getFriday(this.date);
                    break;
            }
            return this.appointments.find((el) => {
                return (el.timeslot === slot && this.sameDay(new Date(el.day), day))
            })
        },

        countBadges() {
            this.future = 0;
            this.past = 0;
            this.appointments.forEach((el) => {
                if (!this.sameDay(new Date(el.day), this.date)) {
                    if (new Date(el.day) > this.date) {
                        this.future += el.pupilUpload.filter((element) => !element.seenByMe).length;
                    } else {
                        this.past += el.pupilUpload.filter((element) => !element.seenByMe).length;
                    }
                }
            })
        },

        async readExerciseName(item) {
            if(!this.person.accessibility.screenreader) {
                if (!window.speechSynthesis.speaking) {
                    if ('speechSynthesis' in window) {
                        // Speech Synthesis supported 🎉
                    } else {
                        // Speech Synthesis Not Supported 😣
                        alert("Sorry, your browser doesn't support text to speech!");
                    }

                    let text = item.title;

                    if (this.targetLang !== 'de') {
                        text = await this.translateToTargetLang({
                            targetLang: this.targetLang, textToTranslate: text
                        });

                        this.setTranslatedText(text);
                        this.showTranslation(true);

                        if (this.showTextTimeout) {
                            clearTimeout(this.showTextTimeout);
                            this.showTextTimeout = null;
                        }

                        this.showTextTimeout = setTimeout(() => {
                            this.showTranslation(false);
                            this.showTextTimeout = null;
                        }, 15000)
                    }
                    let msg = new SpeechSynthesisUtterance();
                    msg.text = text;
                    if (this.isLangPackageAvailable()) {
                        msg.lang = this.targetLang;
                        window.speechSynthesis.speak(msg);
                    }
                }
            }
        },
        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },
        pupilUploadFile(item) {

            let neededAppointment = this.appointments.filter(app => app._id === item.appointmentId)[0];

            if (!neededAppointment) {
                neededAppointment = {};
                neededAppointment._id = item.appointmentId;
            }

            this.toggleOpenAppointment(neededAppointment);
            this.toggleCurrentUploadGroup('baby');
            this.toggleOpenTeacherUploadId(item.file);
            document.getElementById("uploadInput").click();
        },

        async pupilUploadInputChange(appointmentId, teacherFileId) {
            const input = this.$refs.hausiPupilUploadInput;
            const file = input.files[0];
            if (file) {
                const name = file.name;
                const lastDot = name.lastIndexOf('.');
                const fileName = name.substring(0, lastDot);
                const fileExtension = name.substring(lastDot + 1);
                // this.uploadFileName = fileName;
                // this.fileNameCallback = (newFileName) => {
                //     this.showNameFile = false;
                // };
                // this.showNameFile = true;
                await this.uploadFile(appointmentId, teacherFileId, fileName, fileExtension);
            }
        },

        async uploadFile(appointmentId, teacherFileId, fileName, fileExtension) {
            const input = this.$refs.hausiPupilUploadInput;
            const file = input.files[0];
            if (file) {
                const formData = new FormData();
                formData.append('file', file, `${fileName}.${fileExtension}`);
                this.pupilXmlHttpRequest = backend.postTeacherUploadEdit(appointmentId, teacherFileId, file);

                this.pupilXmlHttpRequest.onerror = (e) => {
                    console.error('Pupil upload error:', e)
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.hausiPupilUploadInput.value = '';
                };

                this.pupilXmlHttpRequest.onabort = (e) => {
                    console.warn('Pupil upload aborted');
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.hausiPupilUploadInput.value = '';
                }

                this.pupilXmlHttpRequest.upload.addEventListener('progress', (e) => {
                    this.pupilUploadProgress = (e.loaded / e.total) * 100;
                });

                this.pupilXmlHttpRequest.addEventListener('load', (e) => {
                    if (this.pupilXmlHttpRequest.status !== 201)
                        console.error('Pupil upload failed:', this.pupilXmlHttpRequest.response);
                    this.showPupilUploadProgress = false;
                    this.snackbarSave = true;
                    this.requestAppointments();
                    this.pupilXmlHttpRequest = null;
                    this.$refs.hausiPupilUploadInput.value = '';
                });
                this.pupilUploadProgress = 0.0;
                this.showPupilUploadProgress = true;
                this.pupilXmlHttpRequest.send(formData);
            }
        },

        // sorts all Lists by descending uploadDate
        sliceLists() {
            this.babyFilesToDoDisplay = this.babyFilesToDo.filter(item => (!('canPip' in item) || item.canPip)).slice(0, 5).sort(function(a,b){
                return Date.parse(b.uploadDate)-Date.parse(a.uploadDate);
            });
            this.babyFilesDoneDisplay = this.babyFilesDone.slice(0,5).sort(function(a,b){
                return Date.parse(b.uploadDate)-Date.parse(a.uploadDate);
            });
            this.babyFilesCorrectedDisplay = this.babyFilesCorrected.slice(0,5).sort(function(a,b){
                return Date.parse(b.uploadDate)-Date.parse(a.uploadDate);
            });
            this.checkImagePreviews();
        },
        async checkImagePreviews(){
            if(this.tab === 'toDo'){
                this.babyFilesToDoDisplay.filter(value => value.previewData && !value.previewData.fetched && !value.previewData.loading && !value.previewData.preview)
                    .forEach(value => setTimeout(() => this.fetchPreview(value, true), 0));
            }else if(this.tab === 'done'){
                this.babyFilesDoneDisplay.filter(value => value.previewData && !value.previewData.fetched && !value.previewData.loading && !value.previewData.preview)
                    .forEach(value => this.fetchPreview(value, false));
            }else if(this.tab === 'corrected'){
                this.babyFilesCorrectedDisplay.filter(value => value.previewData && !value.previewData.fetched && !value.previewData.loading && !value.previewData.preview)
                    .forEach(value => this.fetchPreview(value, true));
            }
        },
        unsliceList(list) {
            if (list === 'toDo') {
                this.babyFilesToDoDisplay = this.babyFilesToDo;
                // console.log('babyFilesToDo: ', this.babyFilesToDo);
                this.toDoLong = true;
            } else if (list === 'done') {
                this.babyFilesDoneDisplay = this.babyFilesDone;
                this.doneLong = true;
            } else {
                this.babyFilesCorrectedDisplay = this.babyFilesCorrected;
                this.correctedLong = true;
            }
            this.checkImagePreviews();
        },
        fileNotSupported(el) {
            this.snackbarFileNotSupported = true;
        },
        sliceList(list) {
            if (list === 'toDo') {
                this.babyFilesToDoDisplay = this.babyFilesToDo.slice(0, 5).sort(function(a,b){
                    return Date.parse(b.uploadDate)-Date.parse(a.uploadDate);
                });
                this.toDoLong = false;
            } else if (list === 'done') {
                this.babyFilesDoneDisplay = this.babyFilesDone.slice(0, 5).sort(function(a,b){
                    return Date.parse(b.uploadDate)-Date.parse(a.uploadDate);
                });
                this.doneLong = false;
            } else {
                this.babyFilesCorrectedDisplay = this.babyFilesCorrected.slice(0, 5).sort(function(a,b){
                    return Date.parse(b.uploadDate)-Date.parse(a.uploadDate);
                });
                this.correctedLong = false;
            }
            this.checkImagePreviews();
        },
        async readWidgetInfoText() {
            if(!this.person.accessibility.screenreader) {
                if ('speechSynthesis' in window) {
                    // Speech Synthesis supported 🎉
                } else {
                    // Speech Synthesis not supported 😣
                    alert("Sorry, your browser doesn't support text to speech!");
                    return
                }

                let text = 'Du siehst deine Hausaufgaben die du noch machen musst und wenn deine Lehrerin sie dir zurückgibt.';

                let icon = document.getElementById('infoHausiBtn');

                if (window.speechSynthesis.speaking) {
                    window.speechSynthesis.cancel();
                    icon.src = lautsprecherWeissIcon;
                } else {
                    if (this.targetLang !== 'de') {
                        text = await this.translateToTargetLang({
                            targetLang: this.targetLang, textToTranslate: text
                        });
                    }

                    icon.src = lautsprecherWeissAusIcon;

                    let msg = new SpeechSynthesisUtterance();
                    msg.text = text;
                    if (this.isLangPackageAvailable()) {
                        msg.lang = this.targetLang;
                        window.speechSynthesis.speak(msg);
                    }

                    msg.onend = function () {
                        icon.src = lautsprecherWeissIcon;
                    }
                }
            }
        },
        setFocusOn(element) {
            const el = document.getElementById(element).focus();
        },

        /**
         * Function setzt Fokus wenn Tab Taste gedrückt wird.
         * Bitte beachten: Fokus muss immer auf das Element zuvor gesetzt werden, weil mit Tab noch direkt ein Element
         * nach vorne gesprungen wird.
         * Function sets focus on tabpress
         * ! Focus always has to be set on the previous element since a tabpress will be executed at the same time this
         * function ist called
         * @param element elementId to set focus on if array isn't empty
         * @param tab HausiWidgetTab
         */
        setFocusOnFirstItemTab(element, tab) {
            this.tab = tab;
            switch (tab) {
                case "toDo":
                    if(this.babyFilesToDoDisplay.length > 0) {
                        const el = document.getElementById(element);
                        if(el !== null) {
                            el.focus();
                        }
                    } else {
                        document.getElementById("correctedTab").focus();
                    }
                    break;
                case  "done":
                    if(this.babyFilesDoneDisplay.length > 0) {
                        const el = document.getElementById(element);
                        if(el !== null) {
                            el.focus();
                        }
                    } else {
                        document.getElementById("correctedTab").focus();
                    }
                    break;
                case "corrected":
                    if(this.babyFilesCorrectedDisplay.length > 0) {
                        const el = document.getElementById(element);
                        if(el !== null) {
                            el.focus();
                        }
                    } else {
                        document.getElementById("correctedTab").focus();
                    }
                    break;
                default:
                    break;
            }
        },
        handleArrowKeyLeft() {
            if(!this.keyboard) {
                switch (this.tab) {
                    case  "done":
                        this.setFocusOn("toDoTab");
                        this.tab = "toDo";
                        break;
                    case "corrected":
                        this.setFocusOn("doneTab");
                        this.tab = "done";
                        break;
                    default:
                        break;
                }
            }
        },
        handleArrowKeyRight() {
            if(!this.keyboard) {
                switch (this.tab) {
                    case  "toDo":
                        this.setFocusOn("doneTab");
                        this.tab = "done";
                        break;
                    case "done":
                        this.setFocusOn("correctedTab");
                        this.tab = "corrected";
                        break;
                    default:
                        break;
                }
            }
        },
    },
};
</script>


<style lang="scss" scoped>
table, th, td {
    border: none;
    border-collapse: collapse;
}

tr {
    height: 40px;
}


th {
    vertical-align: top;
}

table tr th:first-child,
table tr td:first-child {
    padding-top: 10px;
    width: 10px !important;
}

table tr th:nth-child(2),
table tr td:nth-child(2) {
    width: 5px !important;
}

table tr th:last-child,
table tr td:last-child {
    width: 5px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}

table, tr, td {
    border: none;
}

#hausiWidgetBabyContainer {
    height: auto;
    width: 90%;
    min-height: 80vh;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    margin: auto auto 5em;
    background-color: white;
}

#hausiWidgetHeader {
    background-color: #F49F31;
    border-radius: 15px 15px 0 0;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: white;
}

.fach {
    cursor: pointer;
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 75px);
    height: calc(80vh - 75px);
    overflow: hidden;
}

.fileRow {
    /*margin: 0.5em auto 0.5em auto;*/
    background-color: rgb(112 112 112 / 15%);
    //border-radius: 5px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    height: 76px !important;
}

.emptyRow {
    background-color: transparent;
    border-radius: 5px;
    height: 35px !important;

    font-size: 25px;

    & p {
        text-align: center;
        width: 100%;
        margin-bottom: 0;
    }
}

.brighterFileRow {
    background-color: rgb(214 214 214 / 15%);
}

.disabledFileRow {
    opacity: 0.5;
}

.fileTitle {
    margin-bottom: 0 !important;
    font-size: 25px;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.babyViewActionBtn {
    min-width: 50px !important;
    width: 50px !important;
    height: 50px !important;
}

.fachLine {
    display: none;
}

.sectionHeaderText {
    color: #F49F31;
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 0;
    display: inline-block;
}

.sectionHeaderCol {
    padding-top: 0;
    padding-bottom: 0;
}

@media screen and (max-width: 600px) {
    .sectionHeaderText {
        font-size: 24px;
    }

    .sectionHeaderImg {
        height: 24px !important;
    }

    .babyViewActionBtn {
        margin-left: 4px !important;
        min-width: 30px !important;
        width: 30px !important;
        height: 30px !important;
    }

    .babyViewActionBtnImg {
        max-width: 24px !important;
        max-height: 24px !important;
        width: 24px !important;
        height: 24px !important;
    }

    .fachLogo {
        display: none;
    }

    .fachLine {
        display: block;
        min-width: 10px;
        max-width: 10px;
        height: 100%;
        border-radius: 5px 0 0 5px;
    }

    .fileRow {
        height: 40px !important;
    }

    .fileTitle {
        font-size: 16px;
    }
}


.smallBabyViewActionBtn {
    height: 40px !important;
    min-width: 40px !important;
    width: 40px !important;
    border-radius: 5px;
    box-shadow: 1px 2px 3px silver;
}

.babyViewActionBtnImg {
    width: 35px !important;
    height: 35px !important;
}

.smallBabyViewActionBtnImg {
    width: 28px !important;
    height: 28px !important;
    filter: brightness(1000%);
}

.editImg {
    filter: brightness(1000%);
}

.edit {
    background-color: #8CBD46 !important;
}

.iconToWhite {
    height: 20px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

</style>
