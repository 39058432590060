<template>
    <div class="pa-0">
            <v-menu :value="stiftSizeMenu" style="border-radius: 5px !important;"
                    :close-on-content-click="false"
                    offset-y nudge-left="54" nudge-bottom="5">
                <template v-slot:activator="{ on: onMenu, attrs }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: onTooltip }">
                            <v-btn
                                    tabindex="1"
                                    :class="{ activeTool: currentActiveMethod === 'pen' }"
                                    v-bind="attrs"
                                    :disabled="isTextLayerVisible"
                                    v-on="getHandlers(onMenu, onTooltip)"
                                    x-small class="mr-3 toolSelector"
                                    @click="setTool('pen')"
                            >
                                <v-icon v-if="currentActiveMethod === 'marker'" style="font-size: 22px !important;">
                                    fas fa-highlighter
                                </v-icon>
                                <img v-else alt="Stift" :src="stiftIcon" style="max-width: 25px; max-height: 25px"/>
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span>Stift</span>
                            <div>
                                <img v-if="hoeren" :src="malenMetacom" width="100" style="margin: auto" class="mr-1">
                                <img v-if="hoeren" :src="aussuchenMetacom" width="100" style="margin: auto">
                            </div>
                        </div>
                    </v-tooltip>
                </template>

                <div style="width: 200px; height: 25px; display: inline; border-radius: 0 !important; background-color: #EAEAEA; ">
                    <v-btn
                            role="menuitem"
                            text x-small
                            tabindex="2"
                            @click="changeSize(3)"
                            style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                            aria-label="Stiftgröße klein"
                    >
                        <v-icon style="font-size: 6px !important;">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn text x-small
                           role="menuitem"
                           tabindex="3"
                           @click="changeSize(13)"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           class="pa-0"
                           aria-label="Stiftgröße mittel">
                        <v-icon style="font-size: 13px !important;">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn text x-small
                           role="menuitem"
                           tabindex="4"
                           @click="changeSize(22)"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           class="pa-0"
                           aria-label="Stiftgröße groß">
                        <v-icon style="font-size: 22px !important;">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn
                            role="menuitem"
                            tabindex="4"
                            :class="{ markerActive: currentActiveMethod === 'marker' }"
                            x-small text
                            style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                            class="pa-0"
                            aria-label="Stifttyp Textmarker"
                            @click="setTool('marker')"
                            @focusout="stiftSizeMenu = false"
                    >
                        <v-icon style="font-size: 22px !important;">fas fa-highlighter</v-icon>
                    </v-btn>
                </div>
            </v-menu>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" tabindex="5" x-small class="mr-3 toolSelector" :class="{ activeTool: currentActiveMethod === 'text' }"
                           :disabled="isTextLayerVisible"
                           @click="() => { setTool('text'); /*spawnTextOnCanvas();*/ }">
                        <img alt="Text einfügen" :src="textIcon" style="max-width: 28px; max-height: 35px"/>
                    </v-btn>
                </template>
                <div style="display: flex; flex-direction: column; text-align: center">
                    <span>Text einfügen</span>
                    <div>
                        <img v-if="hoeren" :src="tastaturTippenMetacom" width="100" style="margin: auto">
                    </div>
                </div>
            </v-tooltip>

            <!-- Eraser Button -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" tabindex="7" :class="{ activeTool: currentActiveMethod === 'eraser', 'mr-1': windowWidth < 600, 'mr-3': windowWidth > 600 }"
                           :disabled="isTextLayerVisible"
                           x-small class="toolSelector"
                           @click="setTool('eraser')">
                        <img alt="Radiergummi" :src="radiererIcon" style="max-width: 25px; max-height: 25px"/>
                    </v-btn>
                </template>
                <div style="display: flex; flex-direction: column; text-align: center">
                    <span>Radiergummi</span>
                    <div>
                        <img v-if="hoeren" :src="loeschenMetacom" width="100" style="margin: auto">
                    </div>
                </div>
            </v-tooltip>

            <!--            -->

            <v-btn v-if="false" style="width: 49px; height: 49px;"
                   x-small text class="mr-3"
                   @click="() => { displayOtherButtons = !displayOtherButtons }">
                <p style="font-size: 30px; color: white">...</p>
            </v-btn>

            <!--            Color palette    -->
            <v-menu :value="colorMenu" v-if="displayOtherButtons" style="border-radius: 5px !important;" max-width="378px" offset-y nudge-left="162" nudge-bottom="5">
                <template v-slot:activator="{ on: onMenu, attrs }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: onTooltip }">
                            <v-btn

                                    tabindex="8"
                                    v-on="getHandlers(onMenu, onTooltip)"
                                    v-bind="attrs"
                                    x-small class="mr-3 toolSelector preventColorInvert"
                                    aria-label="Farbe auswählen"
                                    @click="colorMenu = true"

                            >
                                <v-icon style="font-size: 22px !important;" :color="color">fas fa-circle</v-icon>
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span>Farbe auswählen</span>
                            <div>
                                <img v-if="hoeren" :src="farbenMetacom" width="100" style="margin: auto" class="mr-1">
                                <img v-if="hoeren" :src="aussuchenMetacom" width="100" style="margin: auto">
                            </div>
                        </div>
                    </v-tooltip>
                </template>

                <div style="width: 200px; height: 25px; display: inline; border-radius: 0 !important; background-color: #EAEAEA; ">
                    <v-btn text x-small
                           role="menuitem"
                           tabindex="9"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           aria-label="Farbe weiß"
                           class="preventColorInvert">
                        <v-icon @click="changeColor('#ffffff')" style="font-size: 22px !important; color: #ffffff">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn text x-small
                           role="menuitem"
                           tabindex="9"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           aria-label="Farbe gelb"
                           class="preventColorInvert">
                        <v-icon @click="changeColor('#fac837')" style="font-size: 22px !important; color: #fac837">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn text x-small
                           role="menuitem"
                           tabindex="10"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           class="pa-0 preventColorInvert"
                           aria-label="Farbe orange" >
                        <v-icon @click="changeColor('#f07d32')" style="font-size: 22px !important; color: #f07d32">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn text x-small
                           role="menuitem"
                           tabindex="11"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           class="pa-0 preventColorInvert"
                           aria-label="Farbe rot">
                        <v-icon  @click="changeColor('#e6231e')" style="font-size: 22px !important; color: #e6231e">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn text x-small
                           role="menuitem"
                           tabindex="12"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           class="pa-0 preventColorInvert"
                           aria-label="Farbe hellblau">
                        <v-icon  @click="changeColor('#6996cd')" style="font-size: 22px !important; color: #6996cd">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn text x-small
                           role="menuitem"
                           tabindex="13"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           class="pa-0 preventColorInvert"
                           aria-label="Farbe dunkelblau">
                        <v-icon  @click="changeColor('#505faa')" style="font-size: 22px !important; color: #505faa">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn text x-small
                           role="menuitem"
                           tabindex="14"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           class="pa-0 preventColorInvert"
                           aria-label="Farbe grün" >
                        <v-icon @click="changeColor('#8cbe46')" style="font-size: 22px !important; color: #8cbe46">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn text x-small
                           role="menuitem"
                           tabindex="15"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           class="pa-0 preventColorInvert"
                           aria-label="Farbe grau">
                        <v-icon @click="changeColor('#706f6f')" style="font-size: 22px !important; color: #706f6f">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn text x-small
                           role="menuitem"
                           tabindex="15"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           class="pa-0 preventColorInvert"
                           aria-label="Farbe türkis">
                        <v-icon @click="changeColor('#40E0D0')" style="font-size: 22px !important; color: #40E0D0">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn text x-small
                           role="menuitem"
                           tabindex="15"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           class="pa-0 preventColorInvert"
                           aria-label="Farbe beige">
                        <v-icon @click="changeColor('#D1BC8A')" style="font-size: 22px !important; color: #D1BC8A">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn text x-small
                           role="menuitem"
                           tabindex="15"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           class="pa-0 preventColorInvert"
                           aria-label="Farbe bordeaux">
                        <v-icon @click="changeColor('#5e2028')" style="font-size: 22px !important; color: #5e2028">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn text x-small
                           role="menuitem"
                           tabindex="15"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           class="pa-0 preventColorInvert"
                           aria-label="Farbe lila">
                        <v-icon @click="changeColor('#8800FF')" style="font-size: 22px !important; color: #8800FF">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn text x-small
                           role="menuitem"
                           tabindex="15"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           class="pa-0 preventColorInvert"
                           aria-label="Farbe rosa">
                        <v-icon @click="changeColor('#D47479')" style="font-size: 22px !important; color: #D47479">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn text x-small
                           role="menuitem"
                           tabindex="15"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           class="pa-0 preventColorInvert"
                           aria-label="Farbe schwarz"
                           @focusout="colorMenu = false">
                        <v-icon @click="changeColor('#000000')" style="font-size: 22px !important; color: #000000">fas fa-circle</v-icon>
                    </v-btn>
                </div>
            </v-menu>

            <v-btn v-if="false" style="background-color: #f8f8f8; width: 49px; height: 49px" x-small class="mr-3" @click="undo">
                <img :src="undoIcon" style="max-width: 25px; max-height: 25px"/>
            </v-btn>

            <v-tooltip bottom v-if="displayOtherButtons">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" tabindex="16"  x-small class="mr-3 toolSelector" @click="rotateEditor">
                        <img alt="Um 90° im Uhrzeigersinn drehen" :src="drehIcon" style="max-width: 25px; max-height: 25px"/>
                    </v-btn>
                </template>
                <div style="display: flex; flex-direction: column; text-align: center">
                    <span>Arbeitsblatt drehen</span>
                    <img v-if="hoeren" :src="drehenMetacom" width="100" style="margin: auto">
                </div>
            </v-tooltip>
            <!--<v-menu :value="addPageMenu" v-if="displayOtherButtons" style="border-radius: 5px !important;" offset-y  nudge-bottom="5">
                <template v-slot:activator="{ on: onMenu, attrs }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: onTooltip }">
                            <v-btn
                                    tabindex="16"
                                    v-on="getHandlers(onMenu, onTooltip)"
                                    v-bind="attrs"
                                    x-small class="mr-3 toolSelector"
                                    aria-label="Seite hinzufügen"
                                    @click="addPageMenu = true"
                            >
                                &lt;!&ndash;                                    <img :src="kariertesBlatt" class="mr-2"&ndash;&gt;
                                &lt;!&ndash;                                         height="25px" width="20px"&ndash;&gt;
                                &lt;!&ndash;                                         style="height: 25px; width: 20px"/>&ndash;&gt;
                                <div>
                                    <v-icon style="position: absolute; bottom: 0px; top: 0px; left: 0px; right: 0px; font-size: 30px !important;">far fa-file</v-icon>
                                    <v-icon style="position: absolute; bottom: 0px; top: 2px; left: 0px; right: 0px;  font-size: 14px !important;">fas fa-plus</v-icon>
                                </div>
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span>Seite hinzufügen</span>
                        </div>
                    </v-tooltip>
                </template>
                <v-list>
                    <v-list-item
                            v-for="(item, index) in additionalPagesDropdown"
                            :key="index"
                            @click="() => addAdditionalPageClick(item)"
                            tabindex="16" role="menuitem"
                    >
                        <v-list-item-title>
                            <div style="display: flex">
                                <img :src="item.img" class="mr-2"
                                     style="height: 20px;"/>
                                <p>{{ item.title }}</p>
                            </div>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>-->

            <v-menu offset-y v-if="windowWidth < 600">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn style="width: 49px; height: 49px;"
                           id="smallDisplayMoreButtonsTrigger"
                           x-small text class="mr-3" v-bind="attrs"
                           v-on="on" >
                        <p style="font-size: 30px; color: white">...</p>
                    </v-btn>
                </template>
                <div id="smallDisplayMoreButtons">
                    <v-btn x-small class="mr-3 toolSelector" @click="rotateEditor">
                        <img alt="Um 90° im Uhrzeigersinn drehen" :src="drehIcon" style="max-width: 25px; max-height: 25px"/>
                    </v-btn>

                    <!--            Color palette    -->
                    <v-menu style="border-radius: 5px !important;" offset-y nudge-left="162" nudge-bottom="5">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    style="background-color: #f8f8f8; width: 49px; height: 49px"
                                    x-small class="mr-3"
                                    aria-label="Farbe auswählen"
                            >
                                <v-icon style="font-size: 22px !important;" :color="color">fas fa-circle</v-icon>
                            </v-btn>
                        </template>

                        <div class="colorPickerMenu">
                            <v-btn text x-small
                                   role="menuitem"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                   aria-label="Farbe weiß">
                                <v-icon @click="changeColor('#ffffff')" style="font-size: 22px !important; color: #ffffff">fas fa-circle</v-icon>
                            </v-btn>
                            <v-btn text x-small
                                   role="menuitem"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                   aria-label="Farbe gelb">
                                <v-icon @click="changeColor('#fac837')" style="font-size: 22px !important; color: #fac837">fas fa-circle</v-icon>
                            </v-btn>
                            <v-btn text x-small
                                   role="menuitem"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                   class="pa-0"
                                   aria-label="Farbe orange">
                                <v-icon @click="changeColor('#f07d32')" style="font-size: 22px !important; color: #f07d32">fas fa-circle</v-icon>
                            </v-btn>
                            <v-btn text x-small
                                   role="menuitem"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                   class="pa-0"
                                   aria-label="Farbe rot">
                                <v-icon @click="changeColor('#e6231e')" style="font-size: 22px !important; color: #e6231e">fas fa-circle</v-icon>
                            </v-btn>
                            <v-btn text x-small
                                   role="menuitem"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                   class="pa-0"
                                   aria-label="Farbe hellblau">
                                <v-icon @click="changeColor('#6996cd')" style="font-size: 22px !important; color: #6996cd">fas fa-circle</v-icon>
                            </v-btn>
                            <v-btn text x-small
                                   role="menuitem"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                   class="pa-0"
                                   aria-label="Farbe dunkelblau">
                                <v-icon @click="changeColor('#505faa')" style="font-size: 22px !important; color: #505faa">fas fa-circle</v-icon>
                            </v-btn>
                            <v-btn text x-small
                                   role="menuitem"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                   class="pa-0"
                                   aria-label="Farbe grün">
                                <v-icon @click="changeColor('#8cbe46')" style="font-size: 22px !important; color: #8cbe46">fas fa-circle</v-icon>
                            </v-btn>
                            <v-btn text x-small
                                   role="menuitem"
                                   style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                   class="pa-0"
                                   aria-label="Farbe grau">
                                <v-icon @click="changeColor('#706f6f')" style="font-size: 22px !important; color: #706f6f">fas fa-circle</v-icon>
                            </v-btn>
                        </div>
                    </v-menu>
                </div>
            </v-menu>

            <!--            Stempel List    -->
            <v-menu v-if="displayOtherButtons" style="border-radius: 5px !important;" offset-y nudge-left="270" nudge-bottom="5">
                <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...menu }"
                                x-small
                                tabindex="16"
                                :class="{ activeTool: currentActiveMethod === 'stempel' }"
                                class="toolSelector mr-3"
                                aria-label="Sticker auswählen"
                                @click="setTool('stempel')"
                            >
                                <img :src="currentStempel" style="max-width: 25px; max-height: 25px; filter: none !important;" />
                            </v-btn>
                        </template>
                        <span>Sticker</span>
                    </v-tooltip>
                </template>
                <div style="width: 200px; height: 25px; display: inline; border-radius: 0 !important; background-color: #EAEAEA; ">
                    <v-btn text x-small v-for="(item, index) in stempelList" class="stempelButton"
                           :key="index" @click="setStempel(item)"
                           :aria-label="`${item.split('.')[0].substring(5)} Sticker`">
                        <img :src="item" style="max-width: 25px; max-height: 25px" />
                    </v-btn>
                </div>
            </v-menu>
    </div>
</template>

<script>
import stempels from "@/util/editorStempelList";
import drehIcon from '@/assets/Icons/90-grad-drehung.svg';
import radiererIcon from '@/assets/Icons/radierer-73.svg';
import stiftIcon from '@/assets/Icons/bearbeiten-komplimentär-weiß-88.svg';
import textIcon from '@/assets/Icons/Textfunktion-67.svg';

export default {
    name: 'EditorToolbar',
    data: () => ({
        windowWidth: window.innerWidth,
        stiftSizeMenu: false,
        colorMenu: false,
        stempelList: stempels,
        currentStempel: stempels[5],

        // Icons
        drehIcon,
        radiererIcon,
        stiftIcon,
        textIcon,
    }),
    props: {
        currentActiveMethod: { required: true, type: String },
        color: { required: true, type: String },
        changeColor: { required: true, type: Function },
        changeSize: { required: true, type: Function },
        changeStamp: { required: true, type: Function },
        setTool: { required: true, type: Function },
        spawnTextOnCanvas: { required: true, type: Function },
        rotateEditor: { required: true, type: Function },
        isTextLayerVisible: { required: false, type: Boolean, default: false },
        displayOtherButtons: { required: false, type: Boolean, default: false },
        hoeren: { required: true, type: Boolean },
        sehen: { required: true, type: Boolean },
    },
    methods: {
        // https://stackoverflow.com/questions/58075400/using-v-tooltip-inside-v-menu-activator-in-vuetify-2-0
        getHandlers() {
            return [...arguments].reduce((seed, item) => {
                const mergedHandlers = { ...item, ...seed };

                return mergedHandlers;
            }, {});
        },

        setStempel(newStamp) {
            this.currentStempel = newStamp;
            this.changeStamp(newStamp);
        }
    }
}
</script>

<style scoped lang="scss">
.toolSelector {
    background-color: #f8f8f8 !important;
    width: 49px !important;
    height: 49px !important;

    img {
        filter: brightness(0%);
    }

    @media (max-width: 380px) {
        width: 40px !important;
        height: 40px !important;
    }
}

.activeTool {
    background-color: #d8d8d8 !important;
}

.markerActive {
    border: 2px solid rgb(234, 234, 234);
    background-color: #707070 !important;
    color: white;
    padding: 10px !important;
}

#smallDisplayMoreButtons {
    display: block;
    width: 50px;

    button {
        border-radius: 0 !important;
    }
}

#smallDisplayMoreButtonsTrigger {
    width: 49px !important;
    height: 49px !important;
    padding-left: 2px !important;

    @media (max-width: 380px) {
        width: 40px !important;
        height: 40px !important;
        padding-left: 0 !important;
    }
}

.colorPickerMenu {
    width: 216px !important;
    height: 25px;
    display: inline;
    border-radius: 0 !important;
    background-color: #EAEAEA;
}

.stempelButton {
    background-color: #EAEAEA;
    border-radius: 0 !important;
    width: 54px;
    height: 49px !important;
}
</style>
