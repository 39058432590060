<template>
    <v-card>
        <v-toolbar color="orange" dense>
            <v-toolbar-title class="white--text">
                Hinzufügen
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="$emit('close')" elevation="2" x-small
                   class="ml-2 pa-0" style="width: 30px; height: 30px">
                <img :src="schliesenIcon" alt="Schließensymbol" style="height: 20px;"/>
            </v-btn>
        </v-toolbar>
        <div v-if="showList">
            <div v-for="(item, i) in items" :key="i">
                <div style="display: inline-flex; justify-content: space-between; width: 100%">
                    <v-list-item @click="$emit('itemClicked', item)">
                        <v-list-item-icon>
                            <img style="width: 35px" :src="item.icon"/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-btn v-if="item.help" @click="() => openTutorial(item.help)" depressed class="my-auto"
                           x-small height="40px" color="white"
                    >
                        <img style="width: 30px; opacity: 0.8" :src="infoIcon"/>
                    </v-btn>
                </div>

                <v-divider/>
            </div>
        </div>
        <!-- A slot to display custom data -->
        <slot v-else/>

        <!-- lets hope this works -->
        <v-dialog v-model="showTutorial" scrollable width="85vw">
            <v-card>
                <v-toolbar color="orange" dense style="max-height: 65px">
                    <v-toolbar-title class="white--text">
                        Editor Elemente erklärt: {{ currentHelp.title }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="showTutorial = !showTutorial" elevation="2" x-small
                           class="ml-2 pa-0" style="width: 40px; height: 40px; opacity: 0.8">
                        <img :src="schliesenIcon" alt="Schließensymbol" style="height: 35px;"/>
                    </v-btn>
                </v-toolbar>
                <div id="currentHelpContainer">
                    <div class="mx-10 pa-2" v-html="currentHelp.content"></div>
                </div>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import schliesenIcon from "@/assets/Icons/abbrechen-08.svg";
import infoIcon from "@/assets/Icons/info-2-121.svg";

export default {
    name: "BaseMenu",
    props: {
        showList: {
            required: false,
            default: true,
        },
        items: {
            required: false
        },
    },
    data() {
        return {
            schliesenIcon,
            infoIcon,

            showTutorial: false,
            currentHelp: { title: '', content: '' },
        }
    },
    methods: {
        openTutorial(help) {
            this.currentHelp = help.content;
            this.showTutorial = true;
        },
    }
}
</script>

<style scoped lang="scss">
#currentHelpContainer {
    margin-top: 1em;
    overflow: auto;
}
</style>
