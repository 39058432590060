import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';


const defaultState = {
  subjectListInvalid: false,
};

const mutations = {
  setSubjectListInvalid: (state, status) => {
    state.subjectListInvalid = status;
  },
};

const getters = {
  getSubjectInvalidStatus: state => state.subjectListInvalid,
};

const actions = {
  setSubjectListStatus ({ commit }, status) {
    commit('setSubjectListInvalid', status);
  },
  async createSubject ({ commit, dispatch }, params) {

    try {
      const res = await backend.postSchoolSubject(params);
      await checkResponseStatus(201, res);
      const subject = await res.json();
      return subject;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async editSubject ({ commit, dispatch }, params) {

    try {
      const id = params._id
      delete params._id;
      const res = await backend.patchSchoolSubject(id, params);
      if(res.status === 409) {
        return 409;
      }
      const subject = await res.json();
      return subject;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async deleteSubject ({ commit, dispatch }, id) {
    try {
      const res = await backend.deleteSchoolSubject(id);
      if(res.status === 404) {
        return 404;
      }
      await checkResponseStatus(204, res);
      return true;
    }
    catch(err) {
      console.log(err)
      return false;
    }
  },

  async getSubjects ({ commit, dispatch }) {

    try {
      const res = await backend.getSchoolSubjects();
      await checkResponseStatus(200, res);
      const subjects = await res.json();
      return subjects;
    }
    catch(err) {
      return err.response.status;
    }
  },


};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  getters,
  actions,
};
