<template>
  <div style="max-height: 100vh">
    <div id="qrCodeUI">
      <v-btn @click="closeFunction" x-small class="mr-0" id="qrCodeClose">
        <img :src="closeIcon" style="height: 40px; filter: brightness(1000%)" alt="QR Code Login schließen"/>
      </v-btn>
      <div class="qrCodeVisorContainer">
        <div class="qrCodeVisor"></div>
      </div>
    </div>
    <qrcode-stream
      v-if="codeScanActive"
      class="qrCodeScanner"
      ref="qrCodeScanner"
      @decode="onDecode"
      @init="onInit"
      style="max-height: 100vh !important; overflow: hidden"
    >
      <div class="qrCodeVisorContainer">
        <div class="qrCodeVisor">
          <div>
            <img src="../assets/Bilder/sample_qrvisor.png" />
          </div>
        </div>
      </div>
    </qrcode-stream>

    <v-snackbar v-model="snackbars.codeNotValid" timeout="5000" color="error">
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            small
            @click="snackbars.codeNotValid = false"
        >
          <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
        </v-btn>
      </template>
      QRCode nicht gültig!
    </v-snackbar>
    <v-snackbar v-model="snackbars.cameraInUse" timeout="-1" color="error" min-width="80%" max-width="80%">
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            small
            @click="snackbars.cameraInUse = false"
        >
          <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
        </v-btn>
      </template>
      Fehler beim Kamerazugriff! Wird die Kamera aktuell noch in einer anderen App verwendet?
      <template v-slot:action="{ attrs }">
        <v-btn
                color="white"
                text outlined style="text-transform: inherit"
                v-bind="attrs"
                @click="restartScan"
        >
          Nochmal versuchen
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="snackbars.cameraNotFound" timeout="-1" color="error" min-width="80%" max-width="80%">
      Das Gerät scheint keine nutzbare Kamera zu besitzen. Bitte schließe eine an oder wechsel das Gerät.
      <template v-slot:action="{ attrs }">
        <v-btn
                color="white"
                text outlined style="text-transform: inherit"
                v-bind="attrs"
                @click="restartScan"
        >
          Nochmal versuchen
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="snackbars.genericError" timeout="-1" color="error">
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            small
            @click="snackbars.genericError = false"
        >
          <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
        </v-btn>
      </template>
      Fehler! {{ errorName }}!
    </v-snackbar>
    <v-snackbar v-model="snackbars.cameraPermission" timeout="-1" color="error">
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            small
            @click="snackbars.cameraPermission = false"
        >
          <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
        </v-btn>
      </template>
      Fehler! {{ errorName }}!
    </v-snackbar>
    <!--    <v-snackbar v-model="snackbars.cameraPermission" timeout="-1" color="error" min-width="80%" max-width="80%">-->
<!--      eKlassenraum braucht Zugriff auf die Kamera um QR-Codes scannen zu können.-->
<!--      <template v-slot:action="{ attrs }">-->
<!--        <v-btn-->
<!--                color="white"-->
<!--                text outlined style="text-transform: inherit"-->
<!--                v-bind="attrs"-->
<!--                @click="askAgainForPermission"-->
<!--        >-->
<!--          Zugriff erlauben-->
<!--        </v-btn>-->
<!--      </template>-->
<!--    </v-snackbar>-->
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { decompress } from "shrink-string";
import closeIcon from "../assets/Icons/abbrechen-08.svg";
import qrIcon from "../assets/Bilder/sample_qrvisor.png";

export default {
  name: "QRScanLogin",
  data() {
    return {
      closeIcon,
      qrIcon,
      snackbars: {
        codeNotValid: false,
        cameraInUse: false,
        cameraPermission: false,
        cameraNotFound: false,
        genericError: false,
      },
      errorName: '',
    };
  },
  components: {
    QrcodeStream,
  },
  props: {
    codeScanActive: { type: Boolean, required: true },
    returnFunction: { type: Function, required: true },
    closeFunction: { type: Function, required: true },
  },
  methods: {
      async onInit(promise) {
        try {
          await promise;
        } catch (e) {
          console.log(e);
          if (e.name === 'NotReadableError') {
            this.snackbars.cameraInUse = true;
          } else  if (e.name === 'NotAllowedError') {
            this.snackbars.cameraPermission = true;
            this.errorName = e.name;
          } else if (e.name === 'NotFoundError' || e.name === 'OverconstrainedError') {
            this.snackbars.cameraNotFound = true;
          } else {
            this.snackbars.genericError = true;
            this.errorName = e.name;
          }
        }
    },
    async onDecode(result) {
      if (result.length > 0 && this.scannedCodeValid(result)) {
        try {
          const jsonResult = JSON.parse(result);
          const decompressedResultName = await decompress(jsonResult.name);
          const decompressedResultPW = await decompress(jsonResult.pw);

          this.returnFunction({
            name: decompressedResultName,
            pw: decompressedResultPW,
          });

          // Only for demo purpuoses
          //  this.returnFunction({
          //     name: decompressedResultName,
          //     pw: decompressedResultName
          // });
        } catch (e) {
          this.snackbars.backendNotReachable = true;
        }
      } else if (result.length > 0 && !this.scannedCodeValid(result)) {
        this.snackbars.codeNotValid = true;
      }
    },
    scannedCodeValid(scannedCode) {
      try {
        const jsonCode = JSON.parse(scannedCode);
        if (jsonCode != null) {
          return !!(jsonCode.name && jsonCode.pw);
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    restartScan() {
        this.codeScanActive = false;
        this.snackbars.cameraInUse = false;

        setTimeout(() => {
          this.codeScanActive = true;
        }, 100)
    },
    async askAgainForPermission() {
        // open popup with instructions
    },
  },
};
</script>

<style lang="scss" scoped>
#qrCodeUI {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

#qrCodeClose {
  position: absolute;
  background-color: #707070;
  width: 49px !important;
  height: 49px !important;
  top: 10px;
  right: 10px;
  z-index: 11;
}

.qrCodeVisorContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.qrCodeVisor {
  position: absolute;
  width: 30% !important;
  height: 0% !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: opacity(50%);

  div {
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative; /* If you want text inside of it */
    transform: translate(0%, -25%);
    img {
      display: block;
      width: 100%;
      height: 100%;
      transform: translate(0%, -100%);
    }
  }
}

#qrCodeVisorContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.v-snack.v-snack--top {
  bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
  top: initial; /* or auto */
}
</style>
