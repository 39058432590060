<template>
  <v-app>
    <!-- Universal Loading Bar -->
    <v-progress-linear
      v-if="showLoading"
      indeterminate
      buffer-value="100"
      :color="backendReachable ? 'primary' : 'error'"
      height="10"
      style="position: fixed; top: 0 !important;"
    />

    <!-- Universal Upload -->
    <progress-bar
      title="Hochladen"
      :show-progress="showPupilUploadProgress"
      :progress="pupilUploadProgress"
      :abort-progress="pupilXmlHttpRequest ? () => { pupilXmlHttpRequest.abort() }: () => {}"
    />

    <label style="display: none" for="uploadInput">Datei hochladen</label>
    <input
      @change="() => uploadInputChange()"
      id="uploadInput"
      ref="uploadInput"
      type="file"
      hidden
      label="Datei hochladen"
    />

    <name-file
      v-model="showNameFile"
      :original-name="uploadFileName"
      :callback="fileNameCallback"
      :isSubtitleReady="isSubtitleReady"
      :group="currentlyOpenAppointment ? currentlyOpenAppointment.group : null"
    />

    <!-- Universal Upload End -->

    <UserSwitch v-if="developmentMode" style="z-index: 5"></UserSwitch>
    <router-view></router-view>

    <!-- Snackbar Area -->

    <v-snackbar
        v-model="fileSnackbars.dataSaved"
        timeout="5000"
        color="success"
    >
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            small
            @click="fileSnackbars.dataSaved = false"
        >
          <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
        </v-btn>
      </template>
      Ergebnisse gespeichert.
    </v-snackbar>
    <v-snackbar
        v-model="fileSnackbars.dataSubmitted"
        timeout="5000"
        color="success"
    >
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            small
            @click="fileSnackbars.dataSubmitted = false"
        >
          <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
        </v-btn>
      </template>
      Dein Aufschrieb ist abgegeben. Gut gemacht!
    </v-snackbar>
    <v-snackbar
        timeout="5000"
        color="success"
        v-model="snackbarSave"
    >
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            small
            @click="snackbarSave = false"
        >
          <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
        </v-btn>
      </template>
      Gespeichert!
    </v-snackbar>
    <v-snackbar
        timeout="5000"
        color="error"
        v-model="snackbarGenericError"
    >
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            small
            @click="snackbarGenericError = false"
        >
          <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
        </v-btn>
      </template>
      Ein Fehler ist aufgetreten.
    </v-snackbar>
    <v-snackbar
        timeout="5000"
        color="error"
        v-model="fileSnackbars.fileToBig"
    >
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            small
            @click="fileSnackbars.fileToBig = false"
        >
          <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
        </v-btn>
      </template>
      <span>Datei zu groß, max. 5 MB</span>
    </v-snackbar>
    <v-snackbar
        timeout="5000"
        color="error"
        v-model="fileSnackbars.bigFileToBig"
    >
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            small
            @click="fileSnackbars.bigFileToBig = false"
        >
          <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
        </v-btn>
      </template>
      <span>Datei zu groß, max. 1 GB</span>
    </v-snackbar>
    <v-snackbar
        timeout="5000"
        color="error"
        v-model="fileSnackbars.fileDoesntFitInPrivateFolder"
    >
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            small
            @click="fileSnackbars.fileDoesntFitInPrivateFolder = false"
        >
          <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
        </v-btn>
      </template>
      <span>Nicht genügend Platz im privaten Ordner</span>
    </v-snackbar>
    <v-snackbar
        timeout="5000"
        color="error"
        v-model="snackbarPrivateFull"
    >
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            small
            @click="snackbarPrivateFull = false"
        >
          <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
        </v-btn>
      </template>
      Speicher überschritten. Datei konnte nicht gespeichert werden
    </v-snackbar>
    <v-snackbar
        timeout="10000"
        color="error"
        v-model="showBackendUnreachable"
    >
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            small
            @click="showBackendUnreachable = false"
        >
          <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
        </v-btn>
      </template>
      Überprüfe deine Internetverbindung oder es veruche später noch einmal. Die Server sind gerade nicht erreichbar.
    </v-snackbar>

    <v-card
      id="translatedTextShow"
      type="info"
      v-if="showTranslatedText"
      style="background-color: #8cbe46;"
    >
        <v-card-text class="py-2" style="height: 100%; text-align: center">
            <div style="display: flex;">
                <p id="translatedTextText" style="color: white !important; overflow-y: auto" class="mb-0 pb-2 mx-auto" v-html="urlify(translatedText, true)">
                </p>
                <v-icon role="button" aria-label="Schließen" @click="showTranslation(false)" large dark class="mr-1" color="white">
                    fas fa-times
                </v-icon>
            </div>
        </v-card-text>
    </v-card>

    <keyboard></keyboard>
    <magnifier v-if="magnifier"></magnifier>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import UserSwitch from "./components/Utils/UserSwitch";
import NameFile from "./components/NameFile";
import ProgressBar from "./components/ProgressBar";
import * as backend from "./api/backend";
import Keyboard from "./components/Keyboard";
import Magnifier from "./components/Magnifier";

import closeIcon from "./assets/Icons/abbrechen-08.svg"

export default {
  name: "App",

  components: {Magnifier, Keyboard, UserSwitch, NameFile, ProgressBar },
  methods: {
    ...mapActions('gamepad', ["initGamepadApi"]),
    ...mapActions(['setFabricLibLoaded']),
    ...mapActions("account", ["getCurrentAccount"]),
    ...mapActions("auth", ["autoLogoutUser", "logoutUser"]),
    ...mapActions('translation', [ 'setTargetLang', 'showTranslation' ]),
    ...mapActions("groups", ["getGroupByAppointment"]),
    ...mapActions('util', [ 'loadServerTime', 'resetServerTimeOffset','toggleOpenAppointment', 'toggleCurrentUploadGroup', 'toggleOpenTeacherUploadId', 'setOpenAppointment', 'reopenAppointment' ]),

      urlify(text, alternate) {
          // eslint-disable-next-line no-useless-escape
          var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|software|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
          return text.toString().replace(urlRegex, function (url) {
              url = url.includes('http') ? url : 'http://' + url;
              if (alternate) {
                  return '<a style="color: white" href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
              }
              return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
          })
          // or alternatively
          // return text.replace(urlRegex, '<a href="$1">$1</a>')
      },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
      } else if (orientation === "landscape-primary") {
        // landscape mode
      }
    },
    getExtension(filename) {
      var parts = filename.split('.');
      return parts[parts.length - 1];
    },
    isVideo(filename) {
      var ext = this.getExtension(filename);
      switch (ext.toLowerCase()) {
        case 'm4v':
        case 'avi':
        case 'mpg':
        case 'mp4':
        case 'mkv':
          return true;
      }
      return false;
    },

    async uploadInputChange() {
      if(this.currentUploadGroup === 'teacher') {
        let appointmnet = JSON.parse(JSON.stringify(this.currentlyOpenAppointment));
        let group = await this.getGroupByAppointment(this.currentlyOpenAppointment._id);
        appointmnet.group = group[0] ? group[0] : null;
        this.toggleOpenAppointment(appointmnet);
      }

      const input = this.$refs.uploadInput;
      const file = input.files[0];
      if(this.isVideo(file.name)) // ToDo check Video duration
      {
        this.isSubtitleReady = true;
      } else {
        this.isSubtitleReady = false;
      }

      if (file) {
        const name = file.name;
        const lastDot = name.lastIndexOf('.');
        const fileName = name.substring(0, lastDot);
        const fileExtension = name.substring(lastDot + 1);
        this.uploadFileName = fileName;
        this.fileNameCallback = (newFileName, selectedUser, startDate, isAssignment, createSubtitles) => {
          this.showNameFile = false;
          this.uploadFile(newFileName, fileExtension, selectedUser, startDate, isAssignment, createSubtitles);

          if(this.currentUploadGroup === 'pupil' || this.currentUploadGroup === 'teacher') {
            this.$route.query.appointment = localStorage.getItem('reopenAppointment');
            localStorage.removeItem('reopenAppointment');
          }
        };
        this.showNameFile = true;
      }
    },

    uploadFile(fileName, fileExtension, selectedUser, startDate, isAssignment, createSubtitles) {
      const localAppointment = this.currentlyOpenAppointment;
      const localTeacherUploadId = this.currentlyOpenTeacherUploadId;

      const input = this.$refs.uploadInput;
      const file = input.files[0];
      if (file) {
        const formData = new FormData();
        formData.append('file', file, `${fileName}.${fileExtension}`);

        if (this.currentUploadGroup === 'teacher') {
          formData.append('visibleFor', JSON.stringify(selectedUser));
          formData.append('startDate', JSON.stringify(startDate));
          formData.append('isAssignment', JSON.stringify(isAssignment));
          formData.append('createSubtitles', JSON.stringify(createSubtitles));

          this.pupilXmlHttpRequest = backend.postTeacherUpload(localAppointment._id, file);
        } else if (this.currentUploadGroup === 'pupil') {
          this.pupilXmlHttpRequest = backend.postPupilUpload(localAppointment._id, file);
        } else if (this.currentUploadGroup === 'subject') {
          this.pupilXmlHttpRequest = backend.postSubjectFolderUpload(localAppointment._id, file);
        } else if (this.currentUploadGroup === 'baby' && localTeacherUploadId) {
          this.pupilXmlHttpRequest = backend.postTeacherUploadEdit(localAppointment._id, localTeacherUploadId, file).then((response) => {
            if (response.status === 201) {
              this.snackbarSave = true;
            } else {
              this.snackbarGenericError = true;
            }
            this.toggleOpenAppointment(null);
            this.toggleCurrentUploadGroup(null);
            this.toggleOpenTeacherUploadId(null);
            this.pupilXmlHttpRequest = null;
            this.$refs.uploadInput.value = '';
          });
        } else if(this.currentUploadGroup === 'teacherFolder') {
          this.pupilXmlHttpRequest = backend.postTeacherFolderUpload(file);
        } else {
          // Default: private upload
          this.pupilXmlHttpRequest = backend.postPrivateUpload(file);
        }

        this.pupilXmlHttpRequest.onerror = (e) => {
          console.error('Pupil upload error:', e)
          this.showPupilUploadProgress = false;
          this.pupilXmlHttpRequest = null;
          this.$refs.uploadInput.value = '';
          this.toggleOpenAppointment(null);
          this.toggleOpenTeacherUploadId(null);
          this.toggleCurrentUploadGroup(null);
        };

        this.pupilXmlHttpRequest.onabort = (e) => {
          console.warn('Pupil upload aborted');
          this.showPupilUploadProgress = false;
          this.pupilXmlHttpRequest = null;
          this.$refs.uploadInput.value = '';
          this.toggleOpenAppointment(null);
          this.toggleOpenTeacherUploadId(null);
          this.toggleCurrentUploadGroup(null);
        }

        this.pupilXmlHttpRequest.upload.addEventListener('progress', (e) => {
          this.pupilUploadProgress = (e.loaded / e.total) * 100;
        });

        this.pupilXmlHttpRequest.addEventListener('load', (e) => {
          if (this.pupilXmlHttpRequest.status !== 201) {
            console.error('Pupil upload failed:', this.pupilXmlHttpRequest.response);
            if(this.pupilXmlHttpRequest.status === 409) {
              this.snackbarText = 'Speicher überschritten. Datei konnte nicht gespeichert werden!';
              this.snackbarPrivateFull = true;
            }
          }
          this.showPupilUploadProgress = false;
          this.snackbarSave = true;
          this.toggleOpenAppointment(null);
          this.toggleCurrentUploadGroup(null);
          this.toggleOpenTeacherUploadId(null);
          this.pupilXmlHttpRequest = null;
          this.$refs.uploadInput.value = '';
        });

        this.pupilUploadProgress = 0.0;
        this.showPupilUploadProgress = true;
        this.pupilXmlHttpRequest.send(formData);
      }
    }
  },
  computed: {
    ...mapState("magnifier", ["magnifier"]),
    ...mapState("account", ["account"]),
    ...mapState("connection", ["backendReachable"]),
    ...mapGetters('files', [ 'getSnackbars', 'getFileToBigSnackbarSize' ]),
    ...mapGetters('translation', [ 'getTranslatedText', 'isTranslationShown' ]),
    ...mapGetters('util', [ 'isLoadingShown', 'currentlyOpenAppointment', 'currentUploadGroup', 'currentlyOpenTeacherUploadId' ]),
    fileSnackbars() {
      return this.getSnackbars;
    },
    translatedText() {
      return this.getTranslatedText;
    },
    showTranslatedText() {
      return this.isTranslationShown;
    },
    showLoading() {
      return this.isLoadingShown;
    }
  },
  created() {
    this.loadServerTime();
  },
  async mounted() {
    window.addEventListener("orientationchange", this.handleOrientationChange);
    window.addEventListener('beforeunload', (event) => {
      this.autoLogoutUser();
      this.resetServerTimeOffset();
    });
    this.setTargetLang('de');
    this.initGamepadApi();
    const currentAccount = await this.getCurrentAccount();
    if (!(typeof currentAccount === 'object' && currentAccount._id)) {
      this.logoutUser();
    }
  },
  data: () => ({
    transText: '',
    developmentMode: process.env.NODE_ENV === "development",
    showNameFile: false,
    isSubtitleReady: false,
    uploadFileName: '',
    fileNameCallback: () => {},
    showPupilUploadProgress: false,
    showBackendUnreachable: false,
    pupilUploadProgress: 0.0,
    pupilXmlHttpRequest: null,
    snackbarPrivateFull: false,
    snackbarSave: false,
    snackbarGenericError: false,

    closeIcon,
  }),
  watch: {
    isTranslationShown(value) {
      if (value) {
        setTimeout(() => {
          this.showTranslation(false);
          this.transText = '';
        }, 15000);
      }
      this.transText = this.translatedText;
    },
    backendReachable(backendReachability) {
      if (backendReachability === false) {
        this.showBackendUnreachable = true;
      }
    },
    getTranslatedText() {
      this.transText = this.getTranslatedText;
    },
    isLoadingShown(value) {
    }
  }
};
</script>


<style lang="scss">
  /*@import '~@/styles/globals.scss';*/
  /*@import url('https://fonts.googleapis.com/css2?family=Andika+New+Basic&display=swap');*/

  img {
    -webkit-user-select: none !important;
    -webkit-touch-callout: none !important; /* iOS Safari */
  }

  #translatedTextShow {
    position: fixed;
    bottom: 10px;
    right: 0;
    left: 0;
    margin-right: 10%;
    margin-left: 10%;
    min-width: 300px !important;
    height: 100px;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    z-index: 10;
  }

  @media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
      #translatedTextShow {
          bottom: 80px;
      }
  }

  #translatedTextText {
    text-align: center !important;
    font-size: x-large !important;
    color: #000000 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .v-snack.v-snack--top {
    bottom: initial; /* or auto */
    width: 100vw !important;
  }

  .v-snack.v-snack--bottom {
    top: initial; /* or auto */
    width: 100vw !important;
  }

  .preventColorInvert {
    filter: unset !important;
    border-color: #22A9FF !important;
  }
  .preventColorInvertFilter {
    filter: unset !important;
  }
  .visually-hidden{
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px; width: 1px;
    margin: -1px; padding: 0; border: 0;
  }
  h1 {
    display: inherit;
    font-size: inherit;
    margin-top: inherit;
    margin-bottom: inherit;
    margin-left: inherit;
    margin-right: inherit;
    font-weight: inherit;
  }
  h2 {
    display: inherit;
    font-size: inherit;
    margin-top: inherit;
    margin-bottom: inherit;
    margin-left: inherit;
    margin-right: inherit;
    font-weight: inherit;
  }

  .moveableItemShadow{
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }


  @font-face {
    font-family: 'eKlara Custom';
    src: url('~@/assets/Fonts/ABeeZee-Regular.ttf');
    font-weight: normal;
    font-style: normal;
  }

  .v-application {
    font-family: 'eKlara Custom', sans-serif !important;
  }
</style>
