<template>
    <div>
        <div class="video-container"
             ref="outerDiv"
             tabindex="0"
             @keydown.space="$refs.outerVideo.paused ? $refs.outerVideo.play() : $refs.outerVideo.pause()"
             @keydown.right="$refs.outerVideo.currentTime += 1"
             @keydown.left="$refs.outerVideo.currentTime -= 1"
             :style="{
            'min-width': primaryMinWidth + 'px',
            'min-height': primaryMinHeight + 'px',
            'width': primaryVideoWidth + 'px',
            'height': primaryVideoHeight + 'px',
            'resize': 'both',
            'border': '2px solid grey',
            'padding': '10px',
            'overflow' : 'auto',
            'background-color': '#000'
        }">
            <!--             @drop="drop" @dragover="dragOver"-->

            <video
                playsinline
                id="outer-video"
                @play="onPlay"
                @pause="onPause"
                @seeked="timeChanged"
                ref="outerVideo"
                :poster="videoPoster"
                v-if="primaryVideoSrc"
                controls
            >
                <source :src="primaryVideoSrc" />
                <track default label="Deutsch" kind="subtitles" srclang="de" :src="subtitleSrc">
                Ihr Browser unterstützt dieses Video leider nicht, bitte benutzen Sie einen anderen Brwoser.
            </video>
            <div
                v-show="secondaryVideoSrc"
                id="innerDiv"
                ref="innerDiv"
                @mouseup="resizeEnd"
                :style="{
                    'position': 'absolute',
                    'min-width': secondaryMinWidth + 'px',
                    'min-height': secondaryMinHeight + 'px',
                    'width': secondaryVideoWidth + 'px',
                    'height': secondaryVideoHeight + 'px',
                    'resize': 'both',
                    'overflow' : 'auto',
                    'background-color': 'rgba(10,10,10,1)',
                    'left': innerVideoX + 'px',
                    'top': innerVideoY + 'px'
                }">
                <div
                    id="innerMovingDiv"
                    ref="innerMovingDiv"
                >
                    <video
                        muted
                        :poster="videoPoster"
                        v-if="secondaryVideoSrc"
                        playsinline
                        id="inner-video"
                        ref="innerVideo"
                    >
                        <source :src="secondaryVideoSrc" />
                        Ihr Browser unterstützt dieses Video leider nicht, bitte benutzen Sie einen anderen Brwoser.
                    </video>

                </div>
            </div>
        </div>
        <div v-if="error.length > 0">
            <v-row align="center">
                <v-col cols="auto">
                    <img :src="infoIcon" alt="" style="height: 15px;">
                </v-col>
                <v-col>
                    <div v-text="error" class="error--text"></div>
                </v-col>
            </v-row>
        </div>
        <div v-if="sizeControls">
            <v-row align="center" justify="center">
                <v-col cols="auto">
                  <div
                      tabindex="1"
                      @keypress.space="resizeMinus"
                      @keypress.enter="resizeMinus"
                  >
                    <v-btn
                        rounded
                        fab
                        @click="resizeMinus"
                        elevation="0"
                        style="font-size: 28pt">
                        -
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="auto" class="white--text">
                    <div>
                        Größe ändern
                    </div>
                </v-col>
                <v-col cols="auto">
                  <div
                      tabindex="2"
                      @keypress.space="resizePlus"
                      @keypress.enter="resizePlus"
                  >
                    <v-btn
                        rounded
                        fab
                        tabindex="2"
                        @click="resizePlus"
                        elevation="0"
                        style="font-size: 22pt"
                    >
                        +
                    </v-btn>
                  </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import infoIcon from "@/assets/Icons/ausrufezeichen-62.svg";
import videoPoster from "@/assets/Bilder/videoposter.png";

export default {
    name: "VideoInVideo",
    props: {
        primaryVideoWidth: {
            required: false,
            default: 600
        },
        secondaryVideoWidth: {
            required: false,
            default: 230
        },
        primaryVideoSrc: {
            type: String,
            required: true,
            default: ''
        },
        secondaryVideoSrc: {
            type: String,
            required: false,
            default: ''
        },
        subtitleSrc: {
            type: String,
            required: false,
            default: ''
        },
        sizeControls: {
            type: Boolean,
            required: false,
            default: true
        },
        altError: {
            type: String,
            required: false,
            default: ""
        }
    },
    data() {
        return {
            error: "",
            dragStartX: 0,
            dragStartY: 0,
            innerVideoX: 0,
            innerVideoY: 0,
            calculatedHeight: 0,
            calculatedMaxHeight: 50,
            primaryVideoHeight: 340,
            secondaryVideoHeight: 135,

            primaryMinWidth: 150,
            primaryMinHeight: 85,
            secondaryMinWidth: 80,
            secondaryMinHeight: 32,
            infoIcon,
            videoPoster
        }
    },
    watch: {
        secondaryVideoSrc(newVal) {
            if (!newVal)
                return;
            setTimeout(() => {
                if (this.$refs.innerVideo.canPlayType(newVal.type).length === 0) {
                    if (this.altError)
                        this.error = this.altError;
                    else
                        this.error = "Ihr Browser unterstützt dieses Video-Format nicht, bitte nutzen Sie hierfür Chrome";
                } else
                    this.error = "";
            }, 900);
        },
        primaryVideoSrc(newVal) {
            if (!newVal)
                return;
            setTimeout(() => {
                if (this.$refs.outerVideo.canPlayType(newVal.type).length === 0) {
                    if (this.altError)
                        this.error = this.altError;
                    else
                        this.error = "Ihr Browser unterstützt dieses Video-Format nicht, bitte nutzen Sie hierfür Chrome";
                } else
                    this.error = "";
            }, 900);
        }
    },
    mounted() {
        this.calculatedHeight = this.$refs.outerVideo.clientHeight;
        this.dragElement(this.$refs.innerMovingDiv, this.$refs.innerDiv);
    },
    methods: {
        onPlay() {
            if (this.$refs.innerVideo)
                this.$refs.innerVideo.play();
            this.timeChanged();
        },
        onPause() {
            if (this.$refs.innerVideo)
                this.$refs.innerVideo.pause();
            this.timeChanged();
        },
        stop() {
            this.$refs.outerVideo.pause();
            if (this.$refs.innerVideo)
                this.$refs.innerVideo.pause();
        },
        timeChanged() {
            if (this.$refs.innerVideo)
                this.$refs.innerVideo.currentTime = this.$refs.outerVideo.currentTime;
        },
        resizeEnd(event) {
            console.log("Resize End: ", event);
            if (event.dataTransfer) {
                this.dragEnd(event);
            }

            const width = this.$refs.innerDiv.style.width.substring(
                0,
                this.$refs.innerDiv.style.width.length - 2
            );
            const height = this.$refs.innerDiv.style.height.substring(
                0,
                this.$refs.innerDiv.style.height.length - 2
            );
            console.log("Old", this.secondaryVideoWidth, this.secondaryVideoHeight);
            console.log("New", width, height);
            this.secondaryVideoHeight = height;
            this.secondaryVideoWidth = width;
            //this.$emit("dimensionsChanged", { width, height });
        },
        dragEnd(evt) {
            if (!this.$refs.innerDiv) {
                return;
            }

            let newX = evt.offsetX - this.dragStartX;
            let newY = evt.offsetY - this.dragStartY;

            if (navigator.vendor === "Apple Computer, Inc.") {
                newX = evt.screenX - this.dragStartX;
                newY = evt.screenY - this.dragStartY;
            }
            this.innerVideoX = newX;
            this.innerVideoY = newY;
        },
        dragElement(element, elementToMove) {
            // let _this = this;
            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            let lastMove = null;

            element.onmousedown = dragMouseDown;
            element.ontouchstart = dragMouseDown;

            function dragMouseDown(e) {
                e = e || window.event;
                console.log("Start: " + e.type);
                e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                lastMove = e;
                document.onmouseup = closeDragElement;
                document.ontouchend = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
                document.ontouchmove = elementDrag;
            }

            const elementDrag = (e) => {
                e = e || window.event;
                lastMove = e;
                console.log(e.type);
                if (e.type !== 'touchmove') {
                    e.preventDefault();
                }

                // calculate the new cursor position:
                pos1 = pos3 - (e.clientX ? e.clientX : e.touches[0].clientX);
                pos2 = pos4 - (e.clientY ? e.clientY : e.touches[0].clientY);
                pos3 = (e.clientX ? e.clientX : e.touches[0].clientX);
                pos4 = (e.clientY ? e.clientY : e.touches[0].clientY);

                // set the element's new position:
                const top = (Number.parseFloat(elementToMove.style.top)) - pos2;
                const left = (Number.parseFloat(elementToMove.style.left)) - pos1;

                if ((left >= 0 && left + this.$refs.innerVideo.clientWidth <= this.$refs.outerVideo.clientWidth) && (top >= 0 && top + this.$refs.innerVideo.clientHeight <= this.$refs.outerVideo.clientHeight)) {
                    this.innerVideoX = left;
                    this.innerVideoY = top;
                }
            }

            const closeDragElement = (e) => {
                // stop moving when mouse button is released:
                document.onmouseup = null;
                document.onmousemove = null;
                document.ontouchend = null;
                document.ontouchmove = null;
                try {
                    this.click(e.clientX ? e.clientX : lastMove.touches[0].clientX, e.clientY ? e.clientY : lastMove.touches[0].clientY);
                } catch (e) {
                    // console.log(e);
                }
                lastMove = null;
            }
        },
        resizeMinus() {
            if (this.primaryVideoWidth > this.primaryMinWidth)
                this.primaryVideoWidth /= 1.2;
            if (this.primaryVideoHeight > this.primaryMinHeight)
                this.primaryVideoHeight /= 1.2;
            if (this.secondaryVideoWidth > this.secondaryMinWidth)
                this.secondaryVideoWidth /= 1.2;
            if (this.secondaryVideoHeight > this.secondaryMinHeight)
                this.secondaryVideoHeight /= 1.2;
        },
        resizePlus() {
            this.primaryVideoWidth *= 1.2;
            this.primaryVideoHeight *= 1.2;
            this.secondaryVideoWidth *= 1.2;
            this.secondaryVideoHeight *= 1.2;
        },
    },
}
</script>

<style scoped>

video::-webkit-media-controls-fullscreen-button {
    display: none !important;
}

.video-container {
    position: relative;
}

#outer-video:focus {
    outline: none;
}

#outer-video {
    left: 3%;
    position: absolute;
    width: 94%;
    height: 95%;
}

#inner-video {
    cursor: grab;
    position: absolute;
    width: 100%;
    height: 100%;
}

#inner-video:active {
    cursor: grab !important;
}

</style>
