<template>
    <div>
        <v-row class="mx-0">
            <!-- TEACHER -->
            <div v-if="this.role === 'teacher'" class="widgetContainer whiteBg">
                <!-- Header of Elternbrief -->
                <div class="header">
                    <v-row class="headerRow mx-2 d-flex align-center justify-space-between">
                        <v-col @click="readText(widgetInfoTeacher)" cols="9" md="8" lg="8" class="pa-0 pl-2 d-flex align-center" style="font-size: larger">
                            <img :src="mailIcon" class="icon iconToWhite mr-1"/>
                            Elternbriefe
                        </v-col>
                        <v-col cols="3" md="4" lg="3" class="pa-0 pr-2 d-flex align-center justify-end">
                            <img @click="readText(widgetInfoTeacher)" :src="infoIcon" style="height: 15px;">

                            <v-btn @click="createLetterDialog = !createLetterDialog" class="optionBtn ml-2" x-small color="rgba(248, 248, 248, 0.4)">
                                <img :src="hinzufuegenIcon" class="icon iconToWhite">
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>

                <!-- letters -->
                <div class="letters">
                    <!-- open letters -->
                    <v-row cols="12" class="dialogRow">
                        <v-col class="sectionHeaderCol d-flex align-center">
                            <img :src="toDoIcon" class="icon">
                            <p class="pl-2 mb-0 font-weight-bold">Aktuelle Elternbriefe</p>
                        </v-col>
                    </v-row>

                    <!-- list of open letters -->
                    <v-row cols="12" class="openLetter mx-2">
                        <v-col cols="1" class="pa-0 d-flex align-center">
                            <div class="redInfo ml-2 d-flex justify-center align-center">
                                <img :src="infoRoundIcon" class="icon iconToWhite">
                            </div>
                        </v-col>
                        <v-col cols="7" class="pa-0 d-flex align-center">
                            <p class="ellipsis px-2 mb-0">Ausflug in die Alpen von lorem ipsum dolor sit amet</p>
                        </v-col>
                    </v-row>

                    <!-- archived letters -->
                    <v-row cols="12" class="dialogRow mt-4">
                        <v-col class="sectionHeaderCol d-flex align-center">
                            <img :src="doneIcon" class="icon">
                            <p class="pl-2 mb-0 font-weight-bold">Elternbriefe</p>
                        </v-col>
                    </v-row>

                    <v-row cols="12" class="doneLetter mx-2" style="background-color: rgba(112, 112, 112, 0.25)">
                        <!-- lighter row      <v-row cols="12" class="doneLetter mx-2" style="background-color: rgba(112, 112, 112, 0.15)">-->
                        <v-col cols="1" class="pa-0 d-flex align-center">
                            <div class="redInfo ml-2 d-flex justify-center align-center">
                                <img :src="gruppenIcon" class="icon iconToWhite">
                            </div>
                        </v-col>
                        <v-col cols="10" class="pa-0 d-flex align-center">
                            <p class="ellipsis px-2 mb-0">Klasse 1c</p>
                        </v-col>
                    </v-row>
                </div>
            </div>

            <!-- PARENT -->
            <div v-else-if="this.role === 'parent'" class="widgetContainer whiteBg">
                <!-- Header of Elternbrief -->
                <div @click="readText(widgetInfo)" class="header">
                    <v-row class="headerRow mx-2 d-flex align-center justify-space-between">
                        <v-col cols="9" md="8" lg="8" class="pa-0 pl-2 d-flex align-center" style="font-size: larger">
                            <img :src="mailIcon" class="icon iconToWhite mr-1"/>
                            Elternbriefe
                        </v-col>
                        <v-col cols="3" md="4" lg="3" class="pa-0 pr-2 d-flex align-center justify-end">
                            <img :src="infoIcon" style="height: 15px;">
                        </v-col>
                    </v-row>
                </div>

                <!-- letters -->
                <div class="letters">
                    <!-- open letters -->
                    <v-row cols="12" class="dialogRow">
                        <v-col class="sectionHeaderCol d-flex align-center">
                            <img :src="toDoIcon" class="icon">
                            <p class="pl-2 mb-0 font-weight-bold">Offene Elternbriefe</p>
                        </v-col>
                    </v-row>

                    <!-- list of open letters -->
                    <v-row cols="12" class="openLetter mx-2">
                        <v-col cols="1" class="pa-0 d-flex align-center">
                            <div class="redInfo ml-2 d-flex justify-center align-center">
                                <img :src="infoRoundIcon" class="icon iconToWhite">
                            </div>
                        </v-col>
                        <v-col cols="7" class="pa-0 d-flex align-center">
                            <p class="ellipsis px-2 mb-0">Ausflug in die Alpen von lorem ipsum dolor sit amet</p>
                        </v-col>

                        <v-col cols="4" class="pa-0 d-flex align-center justify-end">
                            <v-btn @click="readText('Ausflug in die Alpen')" x-small class="actionBtn ml-2">
                                <img :src="lautsprecherIcon" class="icon">
                            </v-btn>

                            <v-btn x-small class="actionBtn ml-2">
                                <img :src="downloadIcon" class="icon">
                            </v-btn>

                            <v-tooltip v-model="camToolTip" color="blau" bottom nudge-left="120">
                                <template v-slot:activator="{}">
                                    <v-btn @click="camToolTip = !camToolTip"
                                           x-small class="actionBtn ml-2">
                                        <img :src="kameraIcon" class="icon">
                                    </v-btn>
                                </template>

                                <div class="d-flex flex-column align-center" style="width: 275px">
                                    <p class="mb-0">Sie können ein Foto Ihres vorliegenden Elternbriefs machen und diesen dann
                                        abschicken. Lorem ipsum dolor sit amet</p>
                                    <v-btn class="toolTipBtn mb-1">
                                       <img :src="kameraIcon" class="icon">
                                        <p class="headlineText mb-0 ml-2">Foto machen</p>
                                    </v-btn>

                                    <!-- show after photo was taken -->
                                    <v-btn class="toolTipBtn mb-1">
                                        <img :src="sendenIcon" class="icon">
                                        <p class="headlineText mb-0 ml-2">Abschicken</p>
                                    </v-btn>
                                    <!-- TODO add title of letter here -->
                                    <p class="mb-0">Der Elternbrief "TITEL DES BRIEFS" wird dadurch als bearbeitet
                                        gewertet und an den Lehrer geschickt.</p>
                                </div>
                            </v-tooltip>

                            <v-btn @click="letter = true" x-small class="actionBtn mx-2" color="fhellgruen">
                                 <img :src="unterschriftWeissIcon" class="icon iconToWhite">
                            </v-btn>
                        </v-col>
                    </v-row>

                    <!-- done letters -->
                    <v-row cols="12" class="dialogRow mt-4">
                        <v-col class="sectionHeaderCol d-flex align-center">
                            <img :src="doneIcon" class="icon">
                            <p class="pl-2 mb-0 font-weight-bold">Erledigte Elternbriefe</p>
                        </v-col>
                    </v-row>

                    <v-row cols="12" class="doneLetter mx-2" style="background-color: rgba(112, 112, 112, 0.25)">
                        <!-- lighter row      <v-row cols="12" class="doneLetter mx-2" style="background-color: rgba(112, 112, 112, 0.15)">-->
                        <v-col cols="1" class="pa-0 d-flex align-center">
                            <div class="redInfo lowOpacity ml-2 d-flex justify-center align-center">
                                <img :src="infoRoundIcon" class="icon iconToWhite">
                            </div>
                        </v-col>
                        <v-col cols="10" class="pa-0 d-flex align-center">
                            <p class="ellipsis px-2 mb-0">Durchschnitt der Klasse</p>
                        </v-col>

                        <v-col cols="1" class="pa-0 d-flex align-center justify-end">
                            <v-btn x-small class="actionBtn mx-2">
                                <img :src="downloadIcon" class="icon">
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-row>

        <!-- view/sign letter PARENT dialog -->
        <v-dialog v-model="letter" width="500">
                <div class="header">
                    <v-row class="headerRow mx-2 d-flex align-center justify-space-between">
                        <v-col cols="9" md="8" lg="8" class="pa-0 pl-2 d-flex align-center" style="font-size: larger">
                            <img :src="mailIcon" class="icon iconToWhite mr-1"/>
                        </v-col>
                        <v-col cols="3" md="4" lg="3" class="pa-0 pr-2 d-flex align-center justify-end">
                            <v-btn class="optionBtn" x-small color="rgba(111, 111, 111, 0.6)">
                                <img :src="fertigIcon" class="icon iconToWhite">
                            </v-btn>

                            <v-btn @click="letter = false" class="optionBtn ml-2" x-small color="rgba(248, 248, 248, 0.4)">
                                <img :src="schliessenIcon" class="icon iconToWhite">
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>

                <div class="whiteBg pa-2">
                    <v-row cols="12" class="dialogRow mt-4">
                        <v-col class="pa-0 d-flex align-center justify-space-between">
                            <p class="headlineText mb-0 font-weight-bold">Titel</p>

                            <v-btn class="optionBtn">
                                <img :src="lautsprecherIcon" class="icon">
                            </v-btn>
                        </v-col>
                    </v-row>
                    <hr class="greyLine">

                    <v-row cols="12" class="dialogRow mt-4">
                        <v-col class="pa-0 d-flex align-center justify-space-between">
                            <p class="headlineText mb-0 font-weight-bold">Inhalt</p>

                            <v-btn class="optionBtn">
                                <img :src="lautsprecherIcon" class="icon">
                            </v-btn>
                        </v-col>
                    </v-row>
                    <hr class="greyLine">

                    <v-row cols="12" class="dialogRow mt-4">
                        <v-col class="pa-0 d-flex align-center justify-space-between">
                            <p class="headlineText mb-0 font-weight-bold">Angehängte Datei(en)</p>

                            <v-btn class="actionBtn" x-small>
                                <img :src="downloadIcon" class="icon">
                            </v-btn>
                        </v-col>
                    </v-row>

                    <!-- signature -->
                    <v-row cols="12">
                        <v-col cols="5">
                            <img :src="unterschriftBalken" style="margin-left: -20px">
                        </v-col>

                        <v-col cols="7">
                            <div class="d-flex justify-end mb-2">
                                <v-btn class="actionBtn" x-small>
                                    <img :src="unterschriftIcon" class="icon">
                                </v-btn>
                            </div>

                            <hr class="greyLine">
                            <p class="mb-0">Unterschreibungspflichtig</p>
                        </v-col>
                    </v-row>
                </div>
        </v-dialog>

        <!-- create letter TEACHER dialog -->
        <v-dialog v-model="createLetterDialog" width="500">
            <div class="header">
                <v-row class="headerRow mx-2 d-flex align-center justify-space-between">
                    <v-col cols="9" md="8" lg="8" class="pa-0 pl-2 d-flex align-center" style="font-size: larger">
                        <img v-show="!selectReceiver" :src="mailIcon" class="icon iconToWhite mr-1"/>

                        <v-btn v-show="selectReceiver" @click="goBack" class="optionBtn mr-1" x-small color="rgba(248, 248, 248, 0.4)">
                            <img :src="rechtsIcon" style="transform: rotate(180deg)" class="icon iconToWhite">
                        </v-btn>

                        Neuer Elternbrief
                    </v-col>
                    <v-col cols="3" md="4" lg="3" class="pa-0 pr-2 d-flex align-center justify-end">
                        <v-btn @click="createLetter" class="optionBtn" x-small color="fhellgruen">
                            <img :src="fertigIcon" class="icon iconToWhite">
                        </v-btn>

                        <v-btn @click="resetCreateLetter" class="optionBtn ml-2" x-small color="rgba(248, 248, 248, 0.4)">
                            <img :src="schliessenIcon" class="icon iconToWhite">
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <div class="whiteBg pa-2">
                <!-- create page -->
                <div v-show="!selectReceiver">
                    <v-row cols="12" class="dialogRow mt-4">
                        <v-col class="pa-0 d-flex align-center justify-space-between">
                            <p class="headlineText mb-0 font-weight-bold">Gruppen- / Einzelauswahl</p>

                            <v-btn @click="selectReceiver = true" class="optionBtn">
                                <img :src="sucheIcon" class="icon">
                            </v-btn>
                        </v-col>
                    </v-row>
                    <hr class="greyLine">

                    <v-row cols="12" class="dialogRow mt-4">
                        <v-col class="pa-0 d-flex align-center justify-space-between">
                            <p class="headlineText mb-0 font-weight-bold">Titel</p>

                            <v-btn @click="editTitle = true" class="optionBtn">
                                <img :src="bearbeitenIcon" class="icon">
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="editTitle" class="dialogRow">
                        <v-textarea v-model="title" rows="1" auto-grow autofocus></v-textarea>
                    </v-row>
                    <hr class="greyLine">

                    <v-row cols="12" class="dialogRow mt-4">
                        <v-col class="pa-0 d-flex align-center justify-space-between">
                            <p class="headlineText mb-0 font-weight-bold">Inhalt</p>

                            <v-btn @click="editBody = true" class="optionBtn">
                                <img :src="bearbeitenIcon" class="icon">
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="editBody" class="dialogRow">
                        <v-textarea v-model="body" rows="1" auto-grow autofocus></v-textarea>
                    </v-row>
                    <hr class="greyLine">

                    <v-row cols="12" class="dialogRow mt-4">
                        <v-col class="pa-0 d-flex align-center justify-space-between">
                            <p class="headlineText mb-0 font-weight-bold">Angehängte Datei(en)</p>

                            <v-btn class="optionBtn">
                                <img :src="hochladenIcon" class="icon">
                            </v-btn>
                        </v-col>
                    </v-row>

                    <!-- signature -->
                    <v-row cols="12" class="dialogRow mt-4">
                        <v-col class="pa-0 d-flex align-center justify-space-between">
                            <p class="headlineText mb-0 font-weight-bold">Unterschreibungspflicht?</p>
                        </v-col>
                    </v-row>

                    <v-row cols="12">
                        <v-col cols="5">
                        </v-col>

                        <v-col cols="7" class="d-flex justify-center align-end">
                            <v-btn @click="requiresSignature = true" class="optionBtn ma-2" :color="requiresSignature === true ? 'fhellgruen' : 'grau'">
                                <img :src="fertigIcon" class="icon iconToWhite">
                            </v-btn>
                            <v-btn @click="requiresSignature = false" class="optionBtn ma-2" :color="requiresSignature === false ? 'rot' : 'grau'">
                                <img :src="schliessenIcon" class="icon iconToWhite">
                            </v-btn>

                            <img :src="unterschriftIcon" class="icon">
                        </v-col>
                        <hr class="greyLine">
                    </v-row>
                </div>

                <!-- select receiver(s) page -->
                <div v-show="selectReceiver">
                    <p>SUCHE hier</p>
                    <hr class="greyLine">

                    <!--  group view -->
                    <!--TODO v-for groups-->
                    <div v-show="!selectSingleReceiver">
                        <v-row>
                            <v-col class="d-flex justify-space-between">
                                <p @click="openGroup" class="mb-0 pointer">Klasse 1a</p>

                                <v-btn @click="addGroup" class="textBtn text-capitalize" small>
                                    <p class="mb-0">Auswählen</p>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <hr class="greyLine">
                    </div>

                    <!-- members of group view -->
                    <div v-show="selectSingleReceiver">
                        <v-row>
                            <v-col class="d-flex justify-space-between">
                                <p @click="openGroup" class="mb-0">Klasse 1a</p>
                                <v-checkbox color="fhellgruen" class="ma-0" hide-details></v-checkbox>
                            </v-col>
                        </v-row>
                        <hr class="greyLine">

                        <v-row>
                            <v-col class="d-flex justify-space-between align-center">
                                <p class="headlineText mb-0 d-flex align-center">
                                    <img :src="gender === 'f' ? personFIcon : (gender === 'm' ? personMIcon :personDIcon)"
                                         class="personIcon mr-2">
                                    Anna A. (Familie Albert)
                                </p>

                                <v-checkbox color="fhellgruen" class="ma-0" hide-details></v-checkbox>
                            </v-col>
                        </v-row>
                        <hr class="greyLine">
                    </div>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

import mailIcon from '@/assets/Icons/mail-19.svg'
import infoIcon from "@/assets/Icons/info-2-weiß-89.svg";
import infoRoundIcon from "@/assets/Icons/info-45.svg";
import lautsprecherIcon from "@/assets/Icons/lautsprecher-13.svg";
import downloadIcon from "@/assets/Icons/runterladen-14.svg";
import kameraIcon from "@/assets/Icons/kamera-21.svg";
import schliessenIcon from '@/assets/Icons/abbrechen-08.svg'
import fertigIcon from '@/assets/Icons/fertig-74.svg'
import sendenIcon from '@/assets/Icons/senden-23.svg'
import hinzufuegenIcon from '@/assets/Icons/hinzufuegen-07.svg'
import sucheIcon from '@/assets/Icons/suche-26.svg'
import bearbeitenIcon from '@/assets/Icons/bearbeiten-normal-41.svg'
import hochladenIcon from '@/assets/Icons/hochladen-15.svg'
import gruppenIcon from '@/assets/Icons/gruppen-normal-25.svg'
import toDoIcon from '@/assets/Icons/noch-zu-machen-rot-86.svg'
import doneIcon from '@/assets/Icons/erledigt-rot-87.svg'
import unterschriftIcon from '@/assets/Icons/unterschrift-113.svg'
import unterschriftWeissIcon from '@/assets/Icons/unterschrift-weiss-113.svg'
import unterschriftBalken from '@/assets/Icons/roter-Balken-Unterschrift-125.svg'
import rechtsIcon from '@/assets/Icons/rechts-11.svg'
import personDIcon from '@/assets/Icons/profil-schueler-d.svg'
import personFIcon from '@/assets/Icons/profil-schueler-f.svg'
import personMIcon from '@/assets/Icons/profil-schueler-m.svg'

export default {
name: "Elternbrief",
    data() {
        return {
            mailIcon,
            infoIcon,
            infoRoundIcon,
            lautsprecherIcon,
            downloadIcon,
            kameraIcon,
            schliessenIcon,
            fertigIcon,
            sendenIcon,
            hinzufuegenIcon,
            gruppenIcon,
            sucheIcon,
            bearbeitenIcon,
            hochladenIcon,
            toDoIcon,
            doneIcon,
            unterschriftIcon,
            unterschriftWeissIcon,
            unterschriftBalken,
            rechtsIcon,
            personDIcon,
            personFIcon,
            personMIcon,

            widgetInfo: 'Die Elternbriefe Ihrer Lehrer können Sie beantworten und herunterladen.',
            widgetInfoTeacher: 'Alte Elternbriefe werden sortiert und archiviert. Sie können einen neuen Elternbrief erstellen.',
            letter: false,
            camToolTip: false,

            createLetterDialog: false,
            groups: [],
            title: '',
            body: '',
            attachedFiles: [],
            requiresSignature: null,
            editTitle: null,
            editBody: null,

            selectReceiver: null,
            selectSingleReceiver: null,

            //TODO remove placeholders
            gender: 'd',
        };
    },
    props: {
        role: {required: true},
    },
    computed: {
        ...mapState('translation', [ 'targetLang' ]),
    },
    methods: {
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation', 'translateToTargetLang' ]),

        createLetter() {
            console.log('Titel:', this.title, 'Inhalt:', this.body, 'Unterschriftspflicht:', this.requiresSignature);
            //TODO create letter in backend
            this.createLetterDialog = false;
        },

        resetCreateLetter() {
            this.createLetterDialog = false;
            this.title = '';
            this.body = '';
            this.requiresSignature = null;
            this.groups = [];
            this.attachedFiles = [];
            this.editTitle = null;
            this.editBody = null;
            console.log('Reset Letter | Titel:', this.title, 'Inhalt:', this.body, 'Unterschriftspflicht:', this.requiresSignature);
        },

        openGroup(group) {
            console.log('openGroup method');
            this.selectSingleReceiver = true;
        },

        addGroup(group) {
            let id = group._id
            this.groups.push(id);
        },

        goBack() {
          if (this.selectReceiver && !this.selectSingleReceiver) {
              this.selectReceiver = false;
          } else {
              this.selectSingleReceiver = false;
          }
        },

        async readText(textToRead) {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
                return
            }

            let text = textToRead;

            if (this.targetLang !== 'de') {
                text = await this.translateToTargetLang({
                  targetLang: this.targetLang,
                  textToTranslate: text
                });
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
                this.showTranslation(false);
            } else {
                this.setTranslatedText(text);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)

                let msg = new SpeechSynthesisUtterance();
                msg.text = text;
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                }
            }
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },
    }
}
</script>

<style lang="scss" scoped>
.icon {
    height: 20px;
}

.personIcon {
    height: 25px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.whiteBg {
    background-color: #ffffff;
}

.ellipsis {
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.pointer {
    cursor: pointer;
}

.widgetContainer {
    height: auto;
    width: 90%;
    min-height: 80vh;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    margin: auto auto 5em;
}

.header {
    background-color: var(--v-frot-base);
    border-radius: 15px 15px 0 0;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
}

.headerRow {
    max-width: 100%;
    height: 35px;
}

.sectionHeaderCol {
    padding-top: 0;
    padding-bottom: 0;
}

.letters {
    p {
        color: var(--v-dunkelgrau-base);
    }
}

.openLetter {
    height: 50px;
    background-color: var(--v-hellgrau-base);
    border-radius: 12px;
}

.doneLetter {
    height: 50px;
    border-radius: 12px;
}

.redInfo {
    min-width: 30px;
    height: 30px;
    width: 30px;
    background-color: red;
    border-radius: 8px;
    opacity: 50%;
}

.lowOpacity {
    opacity: 30%;
}

/*TODO reduce amount of btn classes*/
.actionBtn {
    color: white;
    border-radius: 8px;
    min-width: 30px !important;
    width: 30px !important;
    height: 30px !important;
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;
}

.dialogBtn{
    border-radius: 8px;
    min-width: 30px !important;
    width: 30px !important;
    height: 30px !important;
}

.greyLine {
    color: var(--v-hellgrau-base);
    background-color: var(--v-hellgrau-base);
    border: 1px solid;
}

.textBtn {
    border-radius: 8px;
    height: 25px;
}

.headlineText {
    color: var(--v-dunkelgrau-base);
}

.toolTipBtn {
    width: 160px;
    text-transform: none;
    border-radius: 12px;
}

.dialogRow {
    width: 99%;
    margin: 0.5em auto 0.5em auto;
}
</style>
