<template>
    <div class="d-flex flex-column justify-start" id="sideBarContainer">
        <div class="e-row">
            <v-tooltip right>
                <template v-slot:activator="{ on }">
                    <v-btn aria-label="Vergrößern" tabindex="17" elevation="3" x-small v-on="on" id="plusZoomButton"
                           class="mr-3 sidebarButton" @click="changeZoom(1)">
                        <p>+</p>
                    </v-btn>
                </template>
                <div style="display: flex; flex-direction: column; text-align: center">
                    <span>Vergrößern</span>
                </div>
            </v-tooltip>
        </div>
        <div class="e-row">
            <v-tooltip right>
                <template v-slot:activator="{ on }">
                    <v-btn aria-label="Verkleinern" tabindex="17" elevation="3" x-small v-on="on"
                           class="mr-3 sidebarButton" @click="changeZoom(-1)">
                        <p>-</p>
                    </v-btn>
                </template>
                <div style="display: flex; flex-direction: column; text-align: center">
                    <span>Verkleinern</span>
                </div>
            </v-tooltip>
        </div>

        <v-divider class="my-2 mr-3 ml-1"/>

        <!-- Buttons -->
        <div class="e-row">
            <v-tooltip right>
                <template v-slot:activator="{ on: menu, attrs }">
                    <v-menu
                        v-model="memoMenu"
                        offset-x nudge-top="130"
                        nudge-right="10"
                        transition="scale-transition"
                        :close-on-content-click="false">
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                                aria-label="Notiz, Sprachmemo"
                                tabindex="17"
                                elevation="3"
                                x-small
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...menu }"
                                class="mr-3 sidebarButton"
                                :class="{ 'sidebarButtonActive': memoMenu }"
                            >
                                <img height="35px" :src="memoMenuIcon"/>
                            </v-btn>
                        </template>
                        <MemoMenu
                            @close="memoMenu = false"
                            @addAufnahme="spawnOnCanvas('addAudioMessage')"
                            @addNotiz="(text) => spawnOnCanvas('addTextMessage', text)"
                        />
                    </v-menu>
                </template>
                <div style="display: flex; flex-direction: column; text-align: center">
                    <span>Notiz, Sprachmemo</span>
                </div>
            </v-tooltip>
        </div>
        <div class="e-row">
            <v-tooltip right>
                <template v-slot:activator="{ on: menu, attrs }">
                    <v-menu
                            v-model="mediaMenu"
                            offset-x nudge-top="185"
                            nudge-right="10"
                            transition="scale-transition"
                            :close-on-content-click="false">
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                                    aria-label="Audio, Bilder, Video"
                                    tabindex="17"
                                    elevation="3"
                                    x-small
                                    v-bind="attrs"
                                    v-on="{ ...tooltip, ...menu }"
                                    class="mr-3 sidebarButton"
                                    :class="{ 'sidebarButtonActive': mediaMenu }"
                            >
                                <img height="35px" :src="mediaMenuIcon"/>
                            </v-btn>
                        </template>
                        <MediaMenu
                            @close="mediaMenu = false"
                            @addImage="(url, file) => $emit('addImageField', url, file)"
                            @addVideo="(url, file) => $emit('addVideoField', url, file)"
                        />
                    </v-menu>
                </template>
                <div style="display: flex; flex-direction: column; text-align: center">
                    <span>Audio, Bilder, Video</span>
                </div>
            </v-tooltip>
        </div>
        <div class="e-row">
            <v-tooltip right>
                <template v-slot:activator="{ on: menu, attrs }">
                    <v-menu
                            v-model="formMenu"
                            offset-x  nudge-top="240"
                            nudge-right="10"
                            transition="scale-transition"
                            :close-on-content-click="false">
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                                    aria-label="Formen"
                                    tabindex="17"
                                    elevation="3"
                                    x-small
                                    v-bind="attrs"
                                    v-on="{ ...tooltip, ...menu }"
                                    class="mr-3 sidebarButton"
                                    :class="{ 'sidebarButtonActive': formMenu }"
                            >
                                <img height="35px" :src="formMenuIcon"/>
                            </v-btn>
                        </template>
                        <FormMenu
                            @addRectangle="(color, border) => $emit('addRectangle', color, border)"
                            @addLine="(color) => $emit('addLine', color)"
                            @close="formMenu = false"/>
                    </v-menu>
                </template>
                <div style="display: flex; flex-direction: column; text-align: center">
                    <span>Formen</span>
                </div>
            </v-tooltip>
        </div>
        <div class="e-row">
            <v-tooltip right>
                <template v-slot:activator="{ on: menu, attrs }">
                    <v-menu
                            v-model="taskMenu"
                            offset-x
                            nudge-right="10" nudge-top="300"
                            transition="scale-transition"
                            :close-on-content-click="false">
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                                    aria-label="Interaktive Komponenten"
                                    tabindex="17"
                                    elevation="3"
                                    x-small
                                    v-bind="attrs"
                                    v-on="{ ...tooltip, ...menu }"
                                    class="mr-3 sidebarButton"
                                    :class="{ 'sidebarButtonActive': taskMenu }"
                            >
                                <img height="35px" :src="taskMenuIcon"/>
                            </v-btn>
                        </template>
                        <TaskMenu
                            @close="taskMenu = false"
                            @addTextTask="$emit('addTextTask')"
                            @addAnswerField="$emit('addAnswerField')"
                            @addMoveableText="(text, size) => $emit('addMoveableText', text, size)"
                            @addMultipleChoiceField="(question, answers, size) => {
                                $emit('addMultipleChoiceField', question, answers, size)
                            }"
                        />
                    </v-menu>
                </template>
                <div style="display: flex; flex-direction: column; text-align: center">
                    <span>Interaktive Komponenten</span>
                </div>
            </v-tooltip>
        </div>

        <v-spacer></v-spacer>

        <!-- Add Page Button -->
        <div style="align-items: flex-end !important;">
            <div class="text-justify pr-3">
                <p id="pageCount">{{ currentPageIndex + 1 }}/{{ pageCount }}</p>
            </div>
            <div class="e-row">
                <v-tooltip right>
                    <template v-slot:activator="{ on: menu, attrs }">
                        <v-menu
                                v-model="addPageMenu"
                                offset-x
                                nudge-right="10"
                                transition="scale-transition"
                                :close-on-content-click="false">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                    aria-label="Seiten hinzufügen"
                                    tabindex="17"
                                    elevation="3"
                                    x-small
                                    v-bind="attrs"
                                    v-on="{ ...tooltip, ...menu }"
                                    class="mr-3 sidebarButton"
                                    :class="{ 'sidebarButtonActive': addPageMenu }"
                                >
                                    <img height="35px" :src="pageMenuIcon"/>
                                </v-btn>
                            </template>
                            <AddPageMenu
                                @close="addPageMenu = false"
                                @addAdditionalPage="(type) => $emit('addAdditionalPage', type)"
                            />
                        </v-menu>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span>Seite hinzufügen: Leer, Liniert, Kariert, Haus</span>
                    </div>
                </v-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import arrowDown from "@/assets/Icons/unten-dropdown-12.svg";
import memoMenuIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_Sprachmemo.svg'
import mediaMenuIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_elemente.svg'
import formMenuIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_Form_dreieck.svg'
import taskMenuIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_checkbox.svg'
import pageMenuIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_neue_seite.svg'
import MemoMenu from "@/components/Editortools/Toolbar/Menus/MemoMenu";
import AddPageMenu from "./Menus/AddPageMenu";
import MediaMenu from "./Menus/MediaMenu";
import FormMenu from "./Menus/FormMenu";
import TaskMenu from "./Menus/TaskMenu";

export default {
    name: "EditorSideBar",
    components: { AddPageMenu, MemoMenu, MediaMenu, FormMenu, TaskMenu },
    props: {
        currentPageIndex: { required: true, type: Number },
        pageCount: { required: true, type: Number },
        mode: { required: true, type: String },
    },
    data() {
        return {
            //Icons
            arrowDown,
            memoMenuIcon,
            mediaMenuIcon,
            formMenuIcon,
            taskMenuIcon,
            pageMenuIcon,

            // Menu Toggles
            memoMenu: false,
            mediaMenu: false,
            taskMenu: false,
            formMenu: false,
            addPageMenu: false,
        }
    },
    methods: {
        changeZoom(factor) {
            if (factor > 0)
                this.$emit('zoomIn');
            if (factor < 0)
                this.$emit('zoomOut');
        },
        spawnOnCanvas(type, params = {}) {
            this.$emit(type, params);
        }
    }
}
</script>
<style scoped lang="scss">
#sideBarContainer {
    position: fixed;
    left: 10px;
    top: 95px;
    z-index: 8;
    height: calc(100vh - 100px);
}

.e-row {
    margin: 4px 0px 4px 0px;
}

#pageCount {
    font-weight: bold;
    font-size: larger;
    margin-bottom: 0;
    text-align: center;
}

.sidebarButton {
    width: 49px !important;
    height: 49px !important;
    border-radius: 8px;

    p {
        line-height: 0;
        font-size: 55px;
        margin-bottom: 0;
        font-weight: 300;
        color: #333;
    }
}

.sidebarButtonActive {
    background-color: var(--v-orange-base) !important;

    img {
        filter: brightness(1000%);
    }
}
</style>
