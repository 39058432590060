<template>
    <BaseMenu
            :items="items"
            :show-list="showList"
            @close="close"
            @itemClicked="onItemSelected"
    >
        <!-- Add custom data here -->
        <v-container v-if="dragDropHTML" class="taskMenuContainer">
            <v-row>
                <v-col>
                    <h3>Verschiebbaren Text einfügen</h3>
                </v-col>
            </v-row>
            <v-row class="mt-1">
                <v-col class="pb-1">
                    <v-select
                        label="Text Größe"
                        hide-details outlined dense
                        v-model="sizeSelector"
                        :items="sizesToSelect" />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field hide-details outlined label="Text..." v-model="textAreaValue" />
                </v-col>
            </v-row>
            <div class="text-caption">
                Platzieren Sie einen Text, den die Schüler und Schülerinnen frei bewegen können.
            </div>
            <v-row>
                <v-col>
                    <v-btn @click="addDragDropText" color="success">Platzieren</v-btn>
                    <v-btn @click="abort" color="error" class="ml-1">Abbrechen</v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-container v-if="multipleChoiceHTML" class="pt-0 taskMenuContainer">
            <v-row no-gutters>
                <v-col class="pt-1">
                    <h3>Multiple Choice einfügen</h3>
                </v-col>
            </v-row>
            <div class="caption">Möchten Sie nur eine Checkbox einfügen, lassen Sie die Felder einfach leer.</div>
            <v-row class="mt-1">
                <v-col class="pb-1">
                    <v-select
                        label="Elementgröße"
                        hide-details
                        outlined
                        dense
                        v-model="sizeSelector"
                        :items="sizesToSelect" />
                </v-col>
            </v-row>
            <v-row class="">
                <v-col>
                    <v-text-field hide-details label="Frage" outlined v-model="mQuestionTitle" ></v-text-field>
                </v-col>
            </v-row>
            <div style="overflow-x: hidden">
                <div v-for="(txt, i) in mQuestions" :key="'question'+i">
                    <v-row dense class="" align="center">
                        <v-col cols="auto">
                            <v-checkbox v-model="txt.correct"></v-checkbox>
                        </v-col>
                        <v-col>
                            <v-text-field :color="txt.correct ? 'green' : 'error'" hide-details :label="`Antwort ${i + 1}`" outlined v-model="txt.text" ></v-text-field>
                        </v-col>
                        <v-col cols="auto" @click="mQuestions.splice(i, 1)" v-if="mQuestions.length > 1">
                            <v-btn icon>
                                <v-icon color="red">fas fa-minus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div class="d-flex justify-end">
                <v-btn icon right @click="mQuestions.push({ text: '' , correct: false, checked: false })">
                    <v-icon color="green">fas fa-plus</v-icon>
                </v-btn>
            </div>
            <v-row>
                <v-col>
                    <v-btn @click="addMultipleChoice" color="success">Platzieren</v-btn>
                    <v-btn @click="abort" color="error" class="ml-1">Abbrechen</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </BaseMenu>
</template>

<script>
import BaseMenu from "@/components/Editortools/Toolbar/Menus/BaseMenu";
import taskIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_Text.svg';
import answerIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_antwortfeld.svg';
import dragDropIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_drag&drop.svg';
import multipleChoiceIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_checkbox.svg';
import { taskMenuHelp } from '@/constants/editorHelpCollection';

export default {
    name: 'TaskMenu',
    components: { BaseMenu },
    data() {
        return {
            showList: true,

            // Drag and Drop / Moveable Text
            dragDropHTML: false,
            textAreaValue: null,

            // Multiple Choice
            multipleChoiceHTML: false,
            mQuestionTitle: null,
            mQuestions: null,

            items: [
                { icon: taskIcon, text: 'Textfeld', help: { type: 'text', content: taskMenuHelp[0] } },
                { icon: answerIcon, text: 'Antwortfeld', help: { type: 'text', content: taskMenuHelp[1] } },
                { icon: dragDropIcon, text: 'Drag & Drop', help: { type: 'text', content: taskMenuHelp[2] } },
                { icon: multipleChoiceIcon, text: 'Checkboxes', help: { type: 'text', content: taskMenuHelp[3] } },
            ],
            sizesToSelect: [
                { value: 0, text: 'Normal'},
                { value: 1, text: 'Groß'},
                { value: 2, text: 'Extra groß'},
            ],
            sizeSelector: 0,
        }
    },
    methods: {
        onItemSelected(item){
            console.log("Item selected", item);
            switch (item.text) {
                case 'Textfeld':
                    this.addTextTask();
                    break;
                case 'Antwortfeld':
                    this.addAnswerField();
                    break;
                case 'Drag & Drop':
                    this.dragDropHTML = true;
                    this.showList = false;
                    break;
                case 'Checkboxes':
                    this.mQuestionTitle = '';
                    this.mQuestions = [{ text: '' , correct: false, checked: false }];
                    this.multipleChoiceHTML = true;
                    this.showList = false;
                    break;
            }
        },

        addTextTask() {
            this.$emit('addTextTask');
        },
        addAnswerField() {
            this.$emit('addAnswerField');
        },
        addDragDropText() {
            if(this.textAreaValue.toString().trim().length <= 0)
                return;
            this.$emit('addMoveableText', this.textAreaValue, this.sizeSelector);

            this.abort();
        },
        addMultipleChoice() {
            // Removed the filter, because some teachers want empty boxes
            let data = this.mQuestions/*.filter(value => value.text.trim().length > 0)*/;
            console.log("M Choices", this.mQuestions.length);
            if(data.length > 0) {
                this.$emit('addMultipleChoiceField', this.mQuestionTitle, data, this.multipleChoiceSelectedSize);
                this.abort();
            }
        },

        /**
         * General
         */
        abort() {
            this.dragDropHTML = false;
            this.multipleChoiceHTML = false;
            this.textAreaValue = '';
            this.sizeSelector = 0;

            this.showList = true;
        },
        close() {
            this.abort();
            this.$emit('close');
        }
    }
}
</script>

<style scoped lang="scss">

.taskMenuContainer {
    max-width: 300px;
    /*max-height: 40vh;
    overflow: auto;*/
}

</style>
