<template>
    <div v-if="loaded" style="position: relative">
        <!-- simplified view -->
        <div v-if="babyView && tutorialState" style="background-color: #f8f8f8">

            <!-- Navbar tag for Accessibility Reasons (not Navbar Component) -->
            <nav role="navigation">
                <!-- Floating Text to Speech button to be in front of an overlay -->
                <div class="btmNav" v-if="textSelected && !faq" style="z-index: 999; background-color: transparent">
                    <div class="d-flex flex-row justify-space-around align-center" style="height: 100%; width: 100%; background-color: transparent!important;">
                        <div small v-for="item in btmNavItemsSimple" :key="item.name" class="btmNavBtn pa-0" style="background-color: transparent !important;pointer-events: none;">
                        </div>

                        <!--textToSpeech when text is selected-->
                        <v-btn  @click.self="clickTTSFloat" elevation="0" small class="btmNavBtn pa-0" style="z-index: 999">
                            <TTSSmall :icon-size="'35px'" ref="ttsFloat" alt="Vorlesen"></TTSSmall>
                        </v-btn>
                    </div>
                </div>

                <!--bottom navigation bar (simplified view version), used only for mobile devices-->
                <div class="btmNav" v-if="!faq">
                    <div class="d-flex flex-row justify-space-around align-center" style="height: 100%; width: 100%">

                        <v-tooltip v-for="item in btmNavItemsSimple" :key="item.name" :disabled="!showTooltips" top>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" @click="switchSlide(item.goToIndex)" elevation="0" small class="btmNavBtn pa-0" :style="currSlide === item.goToIndex && !btmNavMenu ? 'background-color:' + item.bgColor + '!important;' : ''">
                                    <img :src="item.icon" class="btmNavIcon" :style="currSlide === item.goToIndex && !btmNavMenu ? 'filter: brightness(1000%);' : item.color" :alt="item.alt">
                                </v-btn>
                            </template>
                            <span>{{ item.alt }}</span>
                        </v-tooltip>

                        <!-- menu activator -->
                        <v-tooltip v-if="!textSelected" :disabled="!showTooltips" top>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"  @click="handleBtmMenuClick" ref="btmMenuBtn" elevation="0" small class="btmNavBtn pa-0" :style="btmNavMenu ? 'background-color: #6995CD !important;' : ''">
                                    <img :src="menuIcon" class="btmNavIcon" :style="btmNavMenu ? 'filter: brightness(1000%);' : 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);'" alt="Ausklappbares Menü">
                                </v-btn>
                            </template>
                            <span>Ausklappbares Menü</span>
                        </v-tooltip>

                        <!--textToSpeech when text is selected-->
                        <v-tooltip v-else :disabled="!showTooltips" top>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" @click.self="clickTTS" elevation="0" small class="btmNavBtn pa-0" style="z-index: 201">
                                    <TTSSmall :icon-size="'35px'" ref="tts" alt="Vorlesen"></TTSSmall>
                                </v-btn>
                            </template>
                            <span>Vorlesen</span>
                        </v-tooltip>

                        <!-- menu content -->
                        <div v-closable="{exclude: ['btmMenuBtn', 'btmMenu'], handler: 'onClose'}" ref="btmMenu" class="d-flex flex-column pa-2 btmNavMenu" :class="btmNavMenu ? '' : 'hideMe'" :style="btmNavLangMenu ? 'box-shadow: -5px -2px 4px silver;' : 'border-top-left-radius: 12px;'">
                            <div class="d-flex pa-2 mb-2">
                                <img :src="getPersonPic()" height="25" style="border-radius: 13px; box-shadow: 1px 5px 5px silver;" alt="Profilbild"/>
                                <p class="ml-2 ma-0 ellipsis greyText">{{ person ? person.name : '' }}</p>
                            </div>

                            <!-- Klassenraum Button -->
                            <join-klassenraum-button :bottom-nav="true"/>

                            <!--activator language menu-->
                            <v-btn v-ripple @click="btmNavLangMenu = !btmNavLangMenu" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer preventColorInvert" depressed>
                                <img :src="btmNavLangMenu ? abbrechenIcon : currentTTSLangImg" :alt="btmNavLangMenu ? 'Abbrechen' : 'Vorlesen'" height="25"/>
                                <p class="ml-2 ma-0 text-capitalize">Sprache</p>
                            </v-btn>

                            <!--language menu content-->
                            <div :class="btmNavLangMenu ? '' : 'hideMe'" class="pa-2 pr-0 btmNavLangMenu">

                                <!--1st lang row-->
                                <div class="d-flex">
                                    <v-btn v-for="lang in btmNavLangItems.slice(0, 3)" :key="lang.countryCode" @click="changeLangBtmNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mb-2 mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')">
                                        <img v-if="lang.icon !== ''" :src="lang.icon" height="25" width="25" :alt="lang.alt">
                                        <div v-else class="btmNavLangNoIcon"></div>
                                    </v-btn>
                                </div>

                                <!--2nd lang row-->
                                <div class="d-flex">
                                    <v-btn v-for="lang in btmNavLangItems.slice(3, 6)" :key="lang.countryCode" @click="changeLangBtmNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mb-2 mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')">
                                        <img v-if="lang.icon !== ''" :src="lang.icon" height="25" width="25" :alt="lang.alt">
                                        <div v-else class="btmNavLangNoIcon"></div>
                                    </v-btn>
                                </div>

                                <!--3rd lang row-->
                                <div class="d-flex">
                                    <v-btn v-for="lang in btmNavLangItems.slice(6, 9)" :key="lang.countryCode" @click="changeLangBtmNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mb-2 mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')">
                                        <img v-if="lang.icon !== ''" :src="lang.icon" height="25" width="25" :alt="lang.alt">
                                        <div v-else class="btmNavLangNoIcon"></div>
                                    </v-btn>
                                </div>

                                <!--4th lang row-->
                                <div class="d-flex">
                                    <v-btn v-for="lang in btmNavLangItems.slice(9, 12)" :key="lang.countryCode" @click="changeLangBtmNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mb-2 mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')">
                                        <img v-if="lang.icon !== ''" :src="lang.icon" height="25" width="25" :alt="lang.alt">
                                        <div v-else class="btmNavLangNoIcon"></div>
                                    </v-btn>
                                </div>

                                <!--5th lang row, does not have mb-2 class-->
                                <div class="d-flex">
                                    <v-btn v-for="lang in btmNavLangItems.slice(12, 15)" :key="lang.countryCode" @click="changeLangBtmNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')">
                                        <img v-if="lang.icon !== ''" :src="lang.icon" height="25" width="25" :alt="lang.alt">
                                        <div v-else class="btmNavLangNoIcon"></div>
                                    </v-btn>
                                </div>
                            </div>

                            <v-btn v-if="sehen" v-ripple @click="toggleMagnifier(); closeBtmNavMenus();" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer" depressed>
                                <img :src="lupenIcon" height="25" alt=""/>
                                <p class="ml-2 ma-0 text-capitalize">Lupe</p>
                            </v-btn>

                            <v-btn v-ripple @click="reloadPage(); closeBtmNavMenus()" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer" depressed>
                                <img :src="aktualisierenIcon" height="25" alt=""/>
                                <p class="ml-2 ma-0 text-capitalize" style="font-size: x-small">Aktualisieren</p>
                            </v-btn>

                            <v-btn v-ripple @click="resetTutorial(); closeBtmNavMenus()" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer" depressed>
                                <img :src="fragezeichenIcon" height="25" alt=""/>
                                <p class="ml-2 ma-0 text-capitalize">Tutorial</p>
                            </v-btn>
<!--                            <v-badge overlap content="Neues" left offset-x="20" offset-y="10">-->
                                <v-btn style="width: 100%" aria-label="F A Q" v-ripple @click="toggleFaq(); closeBtmNavMenus()" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer">
                                    <img :src="fragezeichenIcon" height="25" alt=""/>
                                    <p class="ml-2 ma-0">FAQ</p>
                                </v-btn>
<!--                            </v-badge>-->

                            <v-btn v-ripple @click="clickLogoutUser(); closeBtmNavMenus()" class="d-flex pa-2 btmNavMenuBtn pointer" depressed>
                                <img :src="logoutIcon" height="25" alt=""/>
                                <p class="ml-2 ma-0 text-capitalize">Abmelden</p>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </nav>
            <!--top menu bar-->
            <v-overlay v-if="topMenu" @click.native="onCloseTop()" style="z-index: 10" overlay-opacity="0.8"></v-overlay>
            <header role="banner" style="z-index: 12 !important;">
                <v-row style="background-color: #EEEEEE;" class="displayHide">
                    <v-col class="d-flex align-center" style="margin-left: 5%" cols="4">
                      <!-- Klassenraum Button -->
                        <join-klassenraum-button/>
                    </v-col>

                    <!-- name and options -->
                    <v-col class="d-flex justify-end align-center" style="margin-right: 5%">
                        <img v-if="person" :src="getPersonPic()" height="35" style="max-width: 100px;" alt="Profilbild"/>
                        <p class="ma-0 ml-2 ellipsis">{{person ? person.name : ''}}</p>

                        <v-tooltip v-if="magnifier" :disabled="!showTooltips" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    @click="toggleMagnifier();"
                                    style="background-color: #8CBD46 !important; width: 50px; height: 50px; border-radius: 8px;"
                                    x-small elevation="0"
                                    class="ml-4"
                                >
                                    <img :src="lupenIcon" style="max-width: 25px; max-height: 25px;" alt="Lupe einschalten/ausschalten (Alt + L)"/>
                                </v-btn>
                            </template>
                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span><span style="text-decoration: underline white">L</span>upe einschalten/ausschalten (Alt + L)</span>
                                <img v-if="hoeren" :src="lupeMetacom" width="100" style="margin: auto">
                            </div>
                        </v-tooltip>

                        <v-tooltip v-if="keyboard" :disabled="!showTooltips" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        v-on="on"
                                        x-small elevation="0"
                                        class="ml-4"
                                        style="background-color: #8CBD46 !important; width: 50px; height: 50px; border-radius: 8px;"
                                        @click="toggleKeyboard()"
                                >
                                    <img :src="keyboardIcon" alt="Tastatursteuerung einschalten/ausschalten (Alt + T)" height="25">
                                </v-btn>
                            </template>
                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span><span style="text-decoration: underline white">T</span>astatursteuerung einschalten/ausschalten (Alt + T)</span>
                                <img v-if="hoeren" :src="tastaturTippenMetacom" width="100" style="margin: auto">
                            </div>
                        </v-tooltip>

<!--                        <v-tooltip v-if="speechControl" :disabled="!showTooltips" bottom>-->
<!--                            <template v-slot:activator="{ on }">-->
<!--                                <v-btn-->
<!--                                    v-on="on"-->
<!--                                    x-small elevation="0"-->
<!--                                    class="ml-4"-->
<!--                                    style="background-color: #8CBD46 !important; width: 50px; height: 50px; border-radius: 8px;"-->
<!--                                    @click="toggleControl()"-->
<!--                                >-->
<!--                                    <img :src="mikrofonIcon" style="max-width: 25px; max-height: 25px;" alt="Sprachsteuerung aktiv"/>-->
<!--                                </v-btn>-->
<!--                            </template>-->
<!--                            <span>Sprachsteuerung aktiv</span>-->
<!--                        </v-tooltip>-->

                        <BabyChatDropDown @closedChat="closeChat = false" :close-chat="closeChat"/>

                        <v-tooltip :disabled="!showTooltips" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        v-on="on"
                                        @click="reloadPage()"
                                        @focusin="closeChat = true;"
                                        style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                                        x-small elevation="0"
                                        class="ml-4"
                                >
                                    <img :src="aktualisierenIcon" style="max-width: 25px; max-height: 25px;" alt="Aktualisieren"/>
                                </v-btn>
                            </template>
                            <span>Aktualisieren</span>
                        </v-tooltip>

                        <!--menu activator-->
                        <v-tooltip v-if="!textSelected
                        || (person && person.accessibility && person.accessibility.screenreader)" :disabled="!showTooltips" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn id="menuActivatorBaby" v-on="on" @click="handleTopMenuClick" ref="topMenuBtn" elevation="0" small class="pa-0 ml-4" style="z-index: 11;height: 50px; border-radius: 8px; background-color: white;" :style="topMenu ? 'background-color: #6995CD !important;' : ''">
                                    <img :src="menuIcon" style="max-width: 25px; max-height: 25px;" :style="topMenu ? 'filter: brightness(1000%);' : 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);'" alt="Ausklappbares Menü">
                                </v-btn>
                            </template>
                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span>Ausklappbares Menü</span>
                                <img v-if="hoeren" :src="menuMetacom" width="100" style="margin: auto">
                            </div>
                        </v-tooltip>

                        <!--textToSpeech when text is selected-->
                        <v-tooltip v-else :disabled="!showTooltips" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" @click.self="clickTTS" elevation="0" small class="btmNavBtn pa-0 ml-4" style="background-color: #6995CD !important; z-index: 201">
                                    <TTSSmall :icon-color="'white'" ref="tts" alt="Vorlesen"></TTSSmall>
                                </v-btn>
                            </template>
                            <span>Vorlesen</span>
                        </v-tooltip>

                        <!--menu content-->
                        <div role="menu" v-closable="{exclude: ['topMenuBtn'], handler: 'onCloseTop'}" style="z-index: 2;" class="d-flex flex-column pa-2 topMenu" :class="topMenu ? 'onTop' : 'hideMe'" :style="topLangMenu ? 'box-shadow: -5px -2px 4px silver;' : 'border-bottom-left-radius: 12px'">
                            <!--activator language menu-->
                            <v-btn role="menuitem" v-ripple @click="topLangMenu = !topLangMenu" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer preventColorInvert" depressed>
                                <img :src="topLangMenu ? abbrechenIcon : currentTTSLangImg" height="25" alt=""/>
                                <p class="ml-2 ma-0 text-capitalize">Sprache</p>
                            </v-btn>

                            <!--language menu content-->
                            <div :class="topLangMenu ? '' : 'hideMe'" class="pa-2 pr-0 topLangMenu">

                                <!--1st lang row-->
                                <div class="d-flex">
                                    <v-btn role="menuitem" v-for="lang in btmNavLangItems.slice(0, 3)" :key="lang.countryCode" @click="changeLangNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mb-2 mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')">
                                        <img v-if="lang.icon !== ''" :src="lang.icon" height="25" width="25" :alt="lang.alt">
                                        <div v-else class="btmNavLangNoIcon"></div>
                                    </v-btn>
                                </div>

                                <!--2nd lang row-->
                                <div class="d-flex">
                                    <v-btn role="menuitem" v-for="lang in btmNavLangItems.slice(3, 6)" :key="lang.countryCode" @click="changeLangNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mb-2 mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')">
                                        <img v-if="lang.icon !== ''" :src="lang.icon" height="25" width="25" :alt="lang.alt">
                                        <div v-else class="btmNavLangNoIcon"></div>
                                    </v-btn>
                                </div>

    <!--                            &lt;!&ndash;3rd lang row&ndash;&gt;-->
    <!--                            <div class="d-flex">-->
    <!--                                <v-btn v-for="lang in btmNavLangItems.slice(6, 9)" :key="lang.countryCode" @click="changeLangNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')">-->
    <!--                                    <img v-if="lang.icon !== ''" :src="lang.icon" height="25" width="25" :alt="lang.alt">-->
    <!--                                    <div v-else class="btmNavLangNoIcon"></div>-->
    <!--                                </v-btn>-->
    <!--                            </div>-->

    <!--                            &lt;!&ndash;3rd lang row&ndash;&gt;-->
    <!--                            <div class="d-flex">-->
    <!--                                <div v-for="lang in btmNavLangItems.slice(6, 9)" :key="lang.countryCode" @click="changeLangNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')">-->
    <!--                                    <img v-if="lang.icon !== ''" :src="lang.icon" height="25" width="25" alt="">-->
    <!--                                    <div v-else class="btmNavLangNoIcon"></div>-->
    <!--                                </div>-->
    <!--                            </div>-->
                            </div>

                            <v-tooltip :value="showMenuTooltips"  :disabled="!showTooltips" left>
                                <template v-slot:activator="{ on }">
                                    <v-btn role="menuitem" v-on="on" v-if="sehen || hoeren" v-ripple @click="toggleKeyboard(); closeTopMenus()" :style="keyboard ? 'background-color: #8CBD46 !important' : ''" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer" depressed>
                                        <img :src="keyboardIcon" alt="" height="25">
                                        <p class="ml-2 ma-0 text-capitalize"><span style="text-decoration: underline black">T</span>astatur</p>
                                    </v-btn>
                                </template>
                                    <div style="display: flex; flex-direction: column; text-align: center">
                                        <span v-if="keyboard"><span style="text-decoration: underline white">T</span>astatursteuerung ausschalten (Alt + T)</span>
                                        <span v-else><span style="text-decoration: underline white">T</span>astatursteuerung einschalten (Alt + T)</span>
                                        <img v-if="hoeren" :src="tastaturTippenMetacom" width="100" style="margin: auto">
                                    </div>
                            </v-tooltip>

                            <v-tooltip :value="showMenuTooltips" :disabled="!showTooltips" left>
                                <template v-slot:activator="{ on }">
                                    <v-btn role="menuitem" v-on="on" v-if="sehen" v-ripple @click="toggleMagnifier(); closeTopMenus();" :style="magnifier ? 'background-color: #8CBD46 !important' : ''" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer" depressed>
                                        <img :src="lupenIcon" height="25" alt=""/>
                                        <p class="ml-2 ma-0 text-capitalize"><span style="text-decoration: underline black">L</span>upe</p>
                                    </v-btn>
                                </template>
                                <div style="display: flex; flex-direction: column; text-align: center">
                                    <span v-if="magnifier"><span style="text-decoration: underline white">L</span>upe ausschalten (Alt + L)</span>
                                    <span v-else><span style="text-decoration: underline white">L</span>upe einschalten (Alt + L)</span>
                                    <img v-if="hoeren" :src="lupeMetacom" width="100" style="margin: auto">
                                </div>
                            </v-tooltip>

<!--                            <v-btn v-ripple @click="toggleControl(); closeTopMenus()" :style="speechControl ? 'background-color: #8CBD46 !important' : ''" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer" depressed>-->
<!--                                <img :src="mikrofonIcon" height="25" alt=""/>-->
<!--                                <p class="ml-2 ma-0 text-capitalize" style="overflow: hidden; text-overflow: ellipsis;">Befehle</p>-->
<!--                            </v-btn>-->

                            <v-btn role="menuitem" v-ripple @click="resetTutorial(); closeTopMenus()" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer" depressed>
                                <img :src="fragezeichenIcon" height="25" alt=""/>
                                <p class="ml-2 ma-0 text-capitalize">Tutorial</p>
                            </v-btn>
<!--                            <v-badge overlap content="Neues" left offset-x="20" offset-y="10">-->
                                <v-btn width="100%" role="menuitem" aria-label="F A Q" v-ripple @click="toggleFaq(); closeTopMenus()" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer">
                                    <img :src="fragezeichenIcon" height="25" alt=""/>
                                    <p class="ml-2 ma-0 text-uppercase">FAQ</p>
                                </v-btn>
<!--                            </v-badge>-->

                            <!-- Switch um Tooltips überall ein-/auszuschalten ( ! Achtung: auf Safari ist der Switch nach unten verschoben, benötigt Design Anpassung) -->
<!--                            <v-btn :aria-label="showTooltips ? 'Tipps an' : 'Tipps aus'" @click="showTooltips = !showTooltips" v-ripple class="d-flex mb-2 pa-2 btmNavMenuBtn pointer">-->
<!--                                <v-switch v-model="showTooltips"></v-switch>-->
<!--                                <span class="ml-2 ma-0">Tipps</span>-->
<!--                            </v-btn>-->

                            <v-tooltip :value="showMenuTooltips" :disabled="!showTooltips" left>
                                <template v-slot:activator="{ on }">
                                    <v-btn role="menuitem" v-on="on" v-ripple @click="clickLogoutUser(); closeTopMenus()" @focusout="topMenu = false" class="d-flex pa-2 btmNavMenuBtn pointer" depressed>
                                        <img :src="logoutIcon" height="25" alt=""/>
                                        <p class="ml-2 ma-0 text-capitalize"><span style="text-decoration: underline black">A</span>bmelden</p>
                                    </v-btn>
                                </template>
                                <span><span style="text-decoration: underline white">A</span>bmelden (Alt + A)</span>
                            </v-tooltip>
                        </div>
                    </v-col>
                </v-row>
            </header>
            <main v-if="windowWidth > 900 && !faq">
                <div class="big">
                    <v-row style="max-width: 100%">
                            <v-col cols="6" class="pr-0">
                                <PlanWidgetBaby @start-bbb-tut="startBBBTut"/>
                            </v-col>

                            <v-col cols="6" class="px-0">
                                <HausiWidgetBaby/>
                            </v-col>
                    </v-row>
                </div>
            </main>
                <Faq v-else-if="windowWidth > 900 && faq" style="height: 100%; min-height: 90vh"></Faq>
                <main v-else>
                    <VueSlickCarousel v-if="!faq" class="small topPadding" :arrows="false" :initialSlide="0" :infinite="false" :dots="false" ref="carouselBabyView" @afterChange="slideChangeAllowed = true; setCurrSlide($refs.carouselBabyView.$refs.innerSlider.currentSlide)">
                        <PlanWidgetBaby :small="true" @start-bbb-tut="startBBBTut"></PlanWidgetBaby>
                        <HausiWidgetBaby style="height: 100vh"/>
                        <BabyChatWidget></BabyChatWidget>
                    </VueSlickCarousel>
                    <Faq v-else style="height: 100%; min-height: 100vh"></Faq>
                </main>
        </div>

        <!-- regular view -->
        <div v-else-if="tutorialState" style="background-color: #f8f8f8">
            <nav role="navigation">
                <!-- Floating Text to Speech button to be in front of an overlay -->
                <div class="btmNav" v-if="textSelected" style="z-index: 999; background-color: transparent">
                    <div class="d-flex flex-row justify-space-around align-center" style="height: 100%; width: 100%; background-color: transparent!important;">
                        <div v-for="item in btmNavItems" :key="item.name" class="btmNavBtn pa-0" style="background-color: transparent !important;pointer-events: none;">
                        </div>
                        <!--textToSpeech when text is selected-->
                        <v-btn  @click.self="clickTTSFloat" elevation="0" small class="btmNavBtn pa-0" style="z-index: 999">
                            <TTSSmall :icon-size="'35px'" ref="ttsFloat" alt="Vorlesen"></TTSSmall>
                        </v-btn>
                    </div>
                </div>
                <!--bottom navigation bar, used only for mobile devices-->
                <div class="btmNav" v-if="!faq">
                    <div class="d-flex flex-row justify-space-around align-center" style="height: 100%; width: 100%">
                        <v-tooltip v-for="item in btmNavItems" :key="item.name" :disabled="!showTooltips" top>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" @click="switchSlide(item.goToIndex)" elevation="0" small class="btmNavBtn pa-0" :style="currSlide === item.goToIndex && !btmNavMenu ? 'background-color:' + item.bgColor + '!important;' : ''">
                                    <img :src="item.icon" class="btmNavIcon" :alt="item.alt" :style="currSlide === item.goToIndex && !btmNavMenu ? 'filter: brightness(1000%);' : item.color">
                                </v-btn>
                            </template>
                        <span> {{ item.alt }}</span>
                        </v-tooltip>

                        <!--menu activator-->
                        <v-tooltip v-if="!textSelected" :disabled="!showTooltips" top>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" @click="handleBtmMenuClick" ref="btmMenuBtn" elevation="0" small class="btmNavBtn pa-0" :style="btmNavMenu ? 'background-color: #6995CD !important;' : ''">
                                    <img :src="menuIcon" class="btmNavIcon" alt="Ausklappbares Menü" :style="btmNavMenu ? 'filter: brightness(1000%);' : 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);'">
                                </v-btn>
                            </template>
                            <span>Ausklappbares Menü</span>
                        </v-tooltip>

                        <!--textToSpeech when text is selected-->
                        <v-tooltip v-else :disabled="!showTooltips" top>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" @click.self="clickTTS" elevation="0" small class="btmNavBtn pa-0" style="z-index: 201">
                                    <TTSSmall :icon-size="'35px'" ref="tts" alt="Vorlesen"></TTSSmall>
                                </v-btn>
                            </template>
                            <span>Vorlesen</span>
                        </v-tooltip>

                        <!--menu content-->
                        <div v-closable="{exclude: ['btmMenuBtn', 'btmMenu'], handler: 'onClose'}" ref="btmMenu" class="d-flex flex-column pa-2 btmNavMenu" :class="btmNavMenu ? '' : 'hideMe'" :style="btmNavLangMenu ? 'box-shadow: -5px -2px 4px silver;' : 'border-top-left-radius: 12px;'">

                            <div class="d-flex pa-2 mb-2">
                                <img :src="getPersonPic()" alt="Profilbild" height="25" style="border-radius: 13px; box-shadow: 1px 5px 5px silver;"/>
                                <p class="ml-2 ma-0 ellipsis greyText">{{ person ? person.name : '' }}</p>
                            </div>

                            <!-- Klassenraum Button -->
                            <join-klassenraum-button :bottom-nav="true"/>

                            <!--activator language menu-->
                            <div v-ripple @click="btmNavLangMenu = !btmNavLangMenu" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer preventColorInvert">
                                <img :src="btmNavLangMenu ? abbrechenIcon : currentTTSLangImg" :alt="btmNavLangMenu ? 'Abbrechensymbol' : 'Sprachausgabesymbol'" height="25"/>
                                <p class="ml-2 ma-0">Sprache</p>
                            </div>

                            <!--language menu content-->
                            <div :class="btmNavLangMenu ? '' : 'hideMe'" class="pa-2 pr-0 btmNavLangMenu">

                                <!--1st lang row-->
                                <div class="d-flex">
                                    <v-btn v-for="lang in btmNavLangItems.slice(0, 3)" :key="lang.countryCode" @click="changeLangBtmNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mb-2 mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')">
                                        <img v-if="lang.icon !== ''" :src="lang.icon" height="25" width="25" :alt="lang.alt">
                                        <div v-else class="btmNavLangNoIcon"></div>
                                    </v-btn>
                                </div>

                                <!--2nd lang row-->
                                <div class="d-flex">
                                    <v-btn v-for="lang in btmNavLangItems.slice(3, 6)" :key="lang.countryCode" @click="changeLangBtmNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mb-2 mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')">
                                        <img v-if="lang.icon !== ''" :src="lang.icon" height="25" width="25" :alt="lang.alt">
                                        <div v-else class="btmNavLangNoIcon"></div>
                                    </v-btn>
                                </div>

                                <!--3rd lang row-->
                                <div class="d-flex">
                                    <v-btn v-for="lang in btmNavLangItems.slice(6, 9)" :key="lang.countryCode" @click="changeLangBtmNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mb-2 mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')">
                                        <img v-if="lang.icon !== ''" :src="lang.icon" height="25" width="25" :alt="lang.alt">
                                        <div v-else class="btmNavLangNoIcon"></div>
                                    </v-btn>
                                </div>

                                <!--4th lang row-->
                                <div class="d-flex">
                                    <v-btn v-for="lang in btmNavLangItems.slice(9, 12)" :key="lang.countryCode" @click="changeLangBtmNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mb-2 mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')">
                                        <img v-if="lang.icon !== ''" :src="lang.icon" height="25" width="25" :alt="lang.alt">
                                        <div v-else class="btmNavLangNoIcon"></div>
                                    </v-btn>
                                </div>

                                <!--5th lang row, does not have mb-2 class-->
                                <div class="d-flex">
                                    <v-btn v-for="lang in btmNavLangItems.slice(12, 15)" :key="lang.countryCode" @click="changeLangBtmNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')">
                                        <img v-if="lang.icon !== ''" :src="lang.icon" height="25" width="25" :alt="lang.alt">
                                        <div v-else class="btmNavLangNoIcon"></div>
                                    </v-btn>
                                </div>

                                <!--6th lang row, does not have mb-2 class-->
                                <div class="d-flex">
                                    <div v-for="lang in btmNavLangItems.slice(16, 18)" :key="lang.countryCode" @click="changeLangBtmNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg' : '')">
                                        <img v-if="lang.icon !== ''" :alt="lang.alt" :src="lang.icon" height="25" width="25">
                                        <div v-else class="btmNavLangNoIcon"></div>
                                    </div>
                                </div>
                            </div>

                            <v-btn v-ripple @click="reloadPage(); closeBtmNavMenus()" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer" depressed>
                                <img :src="aktualisierenIcon" alt="" height="25"/>
                                <p class="ml-2 ma-0 text-capitalize" style="font-size: x-small">Aktualisieren</p>
                            </v-btn>

                            <v-btn v-ripple @click="resetTutorial(); closeBtmNavMenus()" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer" depressed>
                                <img :src="fragezeichenIcon" alt="" height="25"/>
                                <p class="ml-2 ma-0 text-capitalize">Tutorial</p>
                            </v-btn>

                            <v-btn  v-if="sehen" v-ripple @click="toggleMagnifier(); closeBtmNavMenus();" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer" depressed>
                                <img :src="lupenIcon" height="25" alt=""/>
                                <p class="ml-2 ma-0 text-capitalize">Lupe</p>
                            </v-btn>
<!--                            <v-badge overlap content="Neues" left offset-x="20" offset-y="10">-->
                                <v-btn style="width: 100%;" aria-label="F A Q" v-ripple @click="toggleFaq(); closeTopMenus()" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer">
                                    <img :src="fragezeichenIcon" height="25" alt=""/>
                                    <p class="ml-2 ma-0 text-uppercase">FAQ</p>
                                </v-btn>
<!--                            </v-badge>-->

                            <v-btn v-ripple @click="clickLogoutUser(); closeBtmNavMenus()" class="d-flex pa-2 btmNavMenuBtn pointer" depressed>
                                <img :src="logoutIcon" alt="" height="25"/>
                                <p class="ml-2 ma-0 text-capitalize">Abmelden</p>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </nav>

            <!--top menu bar-->
            <v-overlay v-if="topMenu" @click.native="onCloseTop()" style="z-index: 10" overlay-opacity="0.8"></v-overlay>
            <header role="banner">
                <v-row style="background-color: #EEEEEE;" class="displayHide">
                    <v-col class="d-flex align-center" style="margin-left: 5%" cols="4">
                        <!-- Klassenraum Button -->
                        <join-klassenraum-button/>
                    </v-col>

                    <!-- name and options -->
                    <v-col class="d-flex justify-end align-center" style="margin-right: 5%">
                        <img v-if="person" :src="getPersonPic()" alt="Profilbild" height="35" style="max-width: 100px;"/>
                        <p class="ma-0 ml-2 ellipsis greyText">{{person ? person.name : ''}}</p>

                        <v-tooltip v-if="magnifier" :disabled="!showTooltips" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        v-on="on"
                                        @click="turnOffMagnifier();"
                                        style="background-color: #8CBD46 !important; width: 50px; height: 50px; border-radius: 8px;"
                                        x-small elevation="0"
                                        class="ml-4"
                                >
                                    <img :src="lupenIcon" style="max-width: 25px; max-height: 25px;" alt="Lupe einschalten/ausschalten (Alt + L)"/>
                                </v-btn>
                            </template>
                            <span><span style="text-decoration: underline white">L</span>upe einschalten/ausschalten (Alt + L)</span>
                        </v-tooltip>

                        <v-tooltip v-if="keyboard" :disabled="!showTooltips" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        v-on="on"
                                        x-small elevation="0"
                                        class="ml-4"
                                        style="background-color: #8CBD46 !important; width: 50px; height: 50px; border-radius: 8px;"
                                        @click="toggleKeyboard()"
                                >
                                    <img :src="keyboardIcon" alt="Tastatursteuerung einschalten/ausschalten (Alt + T)" height="25">
                                </v-btn>
                            </template>
                            <span><span style="text-decoration: underline white">T</span>astatursteuerung einschalten/ausschalten (Alt + T)</span>
                        </v-tooltip>

<!--                        <v-tooltip v-if="speechControl" :disabled="!showTooltips" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        v-on="on"
                                        x-small elevation="0"
                                        class="ml-4"
                                        style="background-color: #8CBD46 !important; width: 50px; height: 50px; border-radius: 8px;"
                                        @click="toggleControl()"
                                >
                                    <img :src="mikrofonIcon" style="max-width: 25px; max-height: 25px;" alt="Sprachsteuerung aktiv"/>
                                </v-btn>
                            </template>
                            <span>Sprachsteuerung aktiv</span>
                        </v-tooltip>-->

                        <v-tooltip :disabled="!showTooltips" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" @click="reloadPage()" style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;" x-small elevation="0" class="ml-4 animateIcon">
                                    <img :src="aktualisierenIcon" alt="Aktualisieren" style="max-width: 25px; max-height: 25px;"/>
                                </v-btn>
                            </template>
                            <span>Aktualisieren</span>
                        </v-tooltip>

                        <!--menu activator-->
                        <v-tooltip v-if="!textSelected
                            || (person && person.accessibility && person.accessibility.screenreader)" :disabled="!showTooltips" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn id="menuActivator" aria-haspopup="menu" v-on="on" @click="handleTopMenuClick" ref="topMenuBtn" elevation="0" small class="pa-0 ml-4" style="z-index: 11; height: 50px; border-radius: 8px; background-color: white;" :style="topMenu ? 'background-color: #6995CD !important;' : ''">
                                    <img :src="menuIcon" alt="Ausklappbares Menü" style="max-width: 25px; max-height: 25px;" :style="topMenu ? 'filter: brightness(1000%);' : 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);'">
                                </v-btn>
                            </template>
                            <span>Ausklappbares Menü</span>
                        </v-tooltip>

                        <!--textToSpeech when text is selected-->
                        <v-tooltip v-else :disabled="!showTooltips" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" @click.self="clickTTS" elevation="0" small class="btmNavBtn pa-0 ml-4" style="background-color: #6995CD !important; z-index: 201">
                                    <TTSSmall :icon-color="'white'" ref="tts" alt="Vorlesen"></TTSSmall>
                                </v-btn>
                            </template>
                            <span>Vorlesen</span>
                        </v-tooltip>

                        <!--menu content-->
                        <div role="menu" v-closable="{exclude: ['topMenuBtn'], handler: 'onCloseTop'}" style="z-index: 11;" class="d-flex flex-column pa-2 topMenu" :class="topMenu ? 'onTop' : 'hideMe'" :style="topLangMenu ? 'box-shadow: -5px -2px 4px silver;' : 'border-bottom-left-radius: 12px'">
                            <!--activator language menu-->
                            <v-btn role="menuitem" v-ripple @click="topLangMenu = !topLangMenu" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer preventColorInvert" depressed>
                                <img :src="topLangMenu ? abbrechenIcon : currentTTSLangImg" :alt="topLangMenu ? 'Abbrechensymbol' : ''" height="25"/>
                                <p class="ml-2 ma-0 text-capitalize">Sprache</p>
                            </v-btn>

                            <!--language menu content-->
                            <div :class="topLangMenu ? '' : 'hideMe'" class="pa-2 pr-0 topLangMenu">

                                <!--1st lang row-->
                                <div class="d-flex">
                                    <v-btn role="menuitem" v-for="lang in btmNavLangItems.slice(0, 3)" :key="lang.countryCode" @click="changeLangNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mb-2 mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')">
                                        <img v-if="lang.icon !== ''" :alt="lang.alt" :src="lang.icon" height="25" width="25">
                                        <div v-else class="btmNavLangNoIcon"></div>
                                    </v-btn>
                                </div>

                                <!--2nd lang row-->
                                <div class="d-flex">
                                    <v-btn role="menuitem" v-for="lang in btmNavLangItems.slice(3, 6)" :key="lang.countryCode" @click="changeLangNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mb-2 mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')">
                                        <img v-if="lang.icon !== ''" :alt="lang.alt" :src="lang.icon" height="25" width="25">
                                        <div v-else class="btmNavLangNoIcon"></div>
                                    </v-btn>
                                </div>

                                <!--3rd lang row-->
                                <div class="d-flex">
                                    <v-btn role="menuitem" v-for="lang in btmNavLangItems.slice(6, 9)" :key="lang.countryCode" @click="changeLangNav(lang.countryCode, lang.icon)" class="btmNavLangBtn d-flex mr-2 pa-2 preventColorInvert" :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')">
                                        <img v-if="lang.icon !== ''" :alt="lang.alt" :src="lang.icon" height="25" width="25">
                                        <div v-else class="btmNavLangNoIcon"></div>
                                    </v-btn>
                                </div>
                            </div>

                            <v-tooltip :value="showMenuTooltips" :disabled="!showTooltips" left>
                                <template v-slot:activator="{ on }">
                                    <v-btn role="menuitem" v-on="on" v-if="sehen || hoeren" v-ripple @click="toggleKeyboard(); closeTopMenus()" :style="keyboard ? 'background-color: #8CBD46 !important' : ''" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer" depressed>
                                        <img :src="keyboardIcon" alt="" height="25">
                                        <p class="ml-2 ma-0 text-capitalize"><span style="text-decoration: underline black">T</span>astatur</p>
                                    </v-btn>
                                </template>
                                <span v-if="keyboard"><span style="text-decoration: underline white">T</span>astatursteuerung ausschalten (Alt + T)</span>
                                <span v-else><span style="text-decoration: underline white">T</span>astatursteuerung einschalten (Alt + T)</span>
                            </v-tooltip>

                            <v-tooltip :value="showMenuTooltips" :disabled="!showTooltips" left>
                                <template v-slot:activator="{ on }">
                                    <v-btn role="menuitem" v-on="on" v-if="sehen" v-ripple @click="toggleMagnifier(); closeTopMenus()" :style="magnifier ? 'background-color: #8CBD46 !important' : ''" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer" depressed>
                                        <img :src="lupenIcon" height="25" alt=""/>
                                        <p class="ml-2 ma-0 text-capitalize"><span style="text-decoration: underline black">L</span>upe</p>
                                    </v-btn>
                                </template>
                                <span v-if="magnifier"><span style="text-decoration: underline white">L</span>upe ausschalten (Alt + L)</span>
                                <span v-else><span style="text-decoration: underline white">L</span>upe einschalten (Alt + L)</span>
                            </v-tooltip>

<!--                            <v-btn v-ripple @click="toggleControl(); closeTopMenus()" :style="speechControl ? 'background-color: #8CBD46 !important' : ''" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer" depressed>-->
<!--                                <img :src="mikrofonIcon" height="25" alt=""/>-->
<!--                                <p class="ml-2 ma-0 text-capitalize" style="overflow: hidden; text-overflow: ellipsis;">Befehle</p>-->
<!--                            </v-btn>-->

                            <v-btn role="menuitem" v-ripple @click="resetTutorial(); closeTopMenus()" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer" depressed>
                                <img :src="fragezeichenIcon" height="25" alt=""/>
                                <p class="ml-2 ma-0 text-capitalize">Tutorial</p>
                            </v-btn>

                            <!-- Switch um Tooltips überall ein-/auszuschalten ( ! Achtung: auf Safari ist der Switch nach unten verschoben, benötigt Design Anpassung) -->
<!--                            <v-btn v-ripple class="d-flex mb-2 pa-2 btmNavMenuBtn pointer">-->
<!--                                <v-switch tabindex="0" v-model="showTooltips" label="Tipps"></v-switch>-->
<!--                            </v-btn>-->
<!--                            <v-badge overlap content="Neues" left offset-x="20" offset-y="10">-->
                                <v-btn style="width: 100%" role="menuitem" aria-label="F A Q" v-ripple @click="toggleFaq(); closeTopMenus()" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer">
                                    <img :src="fragezeichenIcon" height="25" alt=""/>
                                    <p class="ml-2 ma-0 text-uppercase">FAQ</p>
                                </v-btn>
<!--                            </v-badge>-->

                            <v-tooltip :value="showMenuTooltips" :disabled="!showTooltips" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn role="menuitem" @focusout="topMenu = false" v-on="on" v-ripple @click="clickLogoutUser(); closeTopMenus()" class="d-flex pa-2 btmNavMenuBtn pointer" depressed>
                                        <img :src="logoutIcon" height="25" alt=""/>
                                        <p class="ml-2 ma-0 text-capitalize"><span style="text-decoration: underline black">A</span>bmelden</p>
                                    </v-btn>
                                </template>
                                <span><span style="text-decoration: underline white">A</span>bmelden (Alt + A)</span>
                            </v-tooltip>
                        </div>
                    </v-col>
                </v-row>
            </header>

            <div class="big" v-if="windowWidth > 900 && !faq">
                <v-tooltip :disabled="!showTooltips" right>
                    <template v-slot:activator="{ on }">
                        <v-btn @focusin="buttonFocusLeft = true" @focusout="buttonFocusLeft = false" v-on="on" v-if="!(sehen || hoeren)" fab small color="#eaeaea" style="margin-left: 5px; margin-top: 20%" @click="page= Math.abs((page-1) %2)" class="arrowBtn buttonFocusHover">
                            <img :alt="page === 0 ? 'Vorherige Seite mit Dateien' : page === 1 ? 'Vorherige Seite mit Stundenplan, Schwarzem Brett und Chat' : 'Vorherige Seite'" style="position: relative; margin:auto; display: block; width: 20px" :src="left" :class="buttonFocusLeft ? 'imgFocus' : ''" class="imgFocusHover">
                        </v-btn>
                    </template>
                    <span>Vorherige Seite</span>
                </v-tooltip>
                <main style="width: 100%;">
                    <v-row>
                        <v-col>
                            <PlanWidget v-if="page === 0" @start-bbb-tut="startBBBTut"></PlanWidget>
                            <FileWidget v-if="page === 1"></FileWidget>
                        </v-col>

                        <v-col>
                            <BrettWidget v-if="page === 0"></BrettWidget>
                        </v-col>
                        <v-col>
                            <chat-widget v-if="page === 0"></chat-widget>
                        </v-col>
                    </v-row>
                </main>
                <v-tooltip :disabled="!showTooltips" left>
                    <template v-slot:activator="{ on }">
                        <v-btn @focusin="buttonFocusRight = true" @focusout="buttonFocusRight = false" v-on="on" fab small style="margin-right: 5px; margin-top: 20%" @click="() => { page= Math.abs((page+1) %2); setFocusOnNextPage() }" class="arrowBtn buttonFocusHover">
                            <img style="position: relative; margin:auto; display: block; width: 20px" :alt="page === 0 ? 'Nächste Seite mit Dateien' : page === 1 ? 'Nächste Seite mit Stundenplan, Schwarzem Brett und Chat' : 'Nächste Seite'" :src="right" :class="buttonFocusRight ? 'imgFocus' : ''" class="imgFocusHover">
                        </v-btn>
                    </template>
                    <span>Nächste Seite</span>
                </v-tooltip>
            </div>
            <main v-else-if="windowWidth > 900">
                <Faq @closeTopMenu="onCloseTop()" style="height: 100%; min-height: 90vh"></Faq>
            </main>

            <main  v-else>
                <VueSlickCarousel v-if="!faq" class="small topPadding" :arrows="false" :initialSlide="0" :infinite="false" :dots="false" ref="carousel" @afterChange="slideChangeAllowed = true; setCurrSlide($refs.carousel.$refs.innerSlider.currentSlide)">
                    <PlanWidget :small="true" style="height: 100vh" @start-bbb-tut="startBBBTut"></PlanWidget>
                    <BrettWidget style="height: 100vh"></BrettWidget>
                    <chat-widget></chat-widget>
                    <FileWidget style="height: 100vh"></FileWidget>
                </VueSlickCarousel>
                <Faq v-else style="height: 100%; min-height: 100vh"></Faq>
            </main>

        </div>
        <Tutorial v-else :babyView="babyViewTut" :bbb-tut="bbbTut" @bbb-tut-done="openBBB" :checkTutorialState="checkTutorialState"> </Tutorial>

        <v-snackbar bottom v-model="snackbar" color="error" timeout="3000">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbar = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon" />
            </v-btn>
          </template>
            {{snackbarText}}
        </v-snackbar>
        <v-snackbar bottom v-model="snackbar2" color="error" timeout="3000">
            <template v-slot:action="{ attrs }">
                <v-btn
                        text
                        v-bind="attrs"
                        small
                        @click="snackbar2 = false"
                >
                    <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon" />
                </v-btn>
            </template>
            {{ snackbarText2 }}
        </v-snackbar>

<!--        <vue-speech v-if="speechControl" lang="de-DE"  @onTranscriptionEnd="onEnd" style="display: none"/>-->

        <!-- Dialog Voice Control aktivieren BabyView -->
        <v-dialog
                :value="voiceControlDialog"
                v-if="voiceControlDialog && babyView"
                max-width="600"
                @click:outside="voiceControlDialog = false"
                overlay-opacity="0.8">
            <v-card>
                <v-toolbar color="primary" dark>
                    <img :src="mikrofonIcon" style="height: 30px" class="iconToWhite">
                    <p tabindex="0" class="ml-2 mb-0 heading" style="font-size: x-large">Befehle</p>
                    <v-spacer></v-spacer>
                    <v-icon role="button" aria-label="Schließen" large @click="voiceControlDialog = false" dark class="mr-1">
                        fas fa-times
                    </v-icon>
                </v-toolbar>
                <v-card-text>
                    <div class="pt-9 px-3">
                        <p tabindex="0" class="mb-0" style="font-size: x-large; width: fit-content">
                            Die Sprachsteuerung ist jetzt aktiviert.
                        </p>
                        <p tabindex="0" style="font-size: large">
                            Diese Befehle kannst du verwenden um eKlara mit deiner Stimme zu steuern:
                            <br>
                            <br>
                            Sage <span style="font-weight: bold">"Chat öffnen"</span> um den Chat zu öffnen
                            <br>
                            Sage <span style="font-weight: bold">"Chat schließen"</span> um den Chat zu schließen
                            <br>
                            Sage <span style="font-weight: bold">"Unterricht"</span> um dir die aktuelle Stunde vorlesen zu lassen
                            <br>
                            Sage <span style="font-weight: bold">"Starte Unterricht"</span> um der aktuellen Stunde beizutreten
                            <br>
                            Sage <span style="font-weight: bold">"Hausaufgaben"</span> um deine aktuellen Hausaufgaben vorlesen zu lassen
                            <br>
                            Sage <span style="font-weight: bold">"Abmelden"</span> um dich abzumelden
                            <br>
                            <br>
                            <br>
                            Versuch es doch mal!
                            <br>
                            Sage
                            <span style="font-weight: bold" class="mt-7">"Sprachsteuerung"</span> um dieses Fenster zu schließen.
                        </p>
                        <div style="display: flex; justify-content: center" v-if="voiceControlSuccessful">
                            <p tabindex="0" class="mb-0 pt-3 pr-4" style="font-size: x-large;">Super!</p>
                            <img :src="daumenHochIcon" style="height: 50px; display:inline-block" alt="0">
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Dialog Voice Control aktivieren Regular View -->
        <v-dialog
                :value="voiceControlDialog"
                v-if="voiceControlDialog && !babyView"
                max-width="600"
                @click:outside="voiceControlDialog = false"
                overlay-opacity="0.8">
            <v-card>
                <v-toolbar color="primary" dark>
                    <img :src="mikrofonIcon" style="height: 30px" class="iconToWhite">
                    <p tabindex="0" class="ml-2 mb-0 heading" style="font-size: x-large">Befehle</p>
                    <v-spacer></v-spacer>
                    <v-icon role="button" aria-label="Schließen" large @click="voiceControlDialog = false" dark class="mr-1">
                        fas fa-times
                    </v-icon>
                </v-toolbar>
                <v-card-text>
                    <div class="pt-9 px-3">
                        <p tabindex="0" class="mb-0" style="font-size: x-large; width: fit-content">
                            Die Sprachsteuerung ist jetzt aktiviert.
                        </p>
                        <p tabindex="0" style="font-size: large">
                            Diese Befehle kannst du verwenden um eKlara mit deiner Stimme zu steuern:
                            <br>
                            <br>
                            Sage <span style="font-weight: bold">"Starte Unterricht"</span> um der aktuellen Stunde beizutreten
                            <br>
                            Sage <span style="font-weight: bold">"Abmelden"</span> um dich abzumelden
                            <br>
                            <br>
                            <br>
                            Versuch es doch mal!
                            <br>
                            Sage
                            <span style="font-weight: bold" class="mt-7">"Sprachsteuerung"</span> um dieses Fenster zu schließen.
                        </p>
                        <div style="display: flex; justify-content: center" v-if="voiceControlSuccessful">
                            <p tabindex="0" class="mb-0 pt-3 pr-4" style="font-size: x-large;">Super!</p>
                            <img :src="daumenHochIcon" style="height: 50px; display:inline-block" alt="0">
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import BabyChatWidget from "./BabyChatWidget";
import TTSSmall from "../TextToSpeechSmall";
import PlanWidget from "@/components/Schueler/PlanWidget";
import PlanWidgetBaby from "@/components/Schueler/PlanWidgetBaby";
import HausiWidgetBaby from "@/components/Schueler/HausiWidgetBaby";
import BrettWidget from "@/components/BrettWidget";
import FileWidget from "@/components/FileWidget";
import BabyChatDropDown from "@/components/Schueler/BabyChatDropDown";
import ChatWidget from "../Chat/ChatWidget";
import Tutorial from "../Tutorial/Tutorial";
import JoinKlassenraumButton from "@/components/JoinKlassenraumButton";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import setGlobalButtonContrast from "../../util/accessibilityButtonContrast";

import deFlagIcon from '../../assets/Icons/deutsch-59.svg'
import frFlagIcon from '../../assets/Icons/französisch-60.svg'
import enFlagIcon from '../../assets/Icons/englisch-61.svg'
import trFlagIcon from '../../assets/Icons/türkisch-97.svg'
import arFlagIcon from '../../assets/Icons/syrisch-98.svg'
import ruFlagIcon from '../../assets/Icons/russisch.svg'
import manPic from '../../assets/Icons/Mann-85.svg'
import womanPic from '../../assets/Icons/Frau-84.svg'
import diversPic from '../../assets/Icons/Divers-87.svg'
import left from '../../assets/Icons/links-filled-10.svg'
import right from '../../assets/Icons/rechts-filled-11.svg'
import logoutIcon from '../../assets/Icons/beitretensvg-06.svg'
import stundenplanIcon from '../../assets/Icons/stundenplan-neu-neu-99.svg'
import brettIcon from '../../assets/Icons/sw-brett-neu-100.svg'
import chatIcon from '../../assets/Icons/chat-alternative-filled-102.svg'
import dateienIcon from '../../assets/Icons/leere-korrektur-78.svg'
import menuIcon from '../../assets/Icons/einstellungen-neu-103.svg'
import fragezeichenIcon from '../../assets/Icons/Fragezeichen-83.svg'
import abbrechenIcon from '../../assets/Icons/abbrechen-08.svg'
import lupenIcon from '../../assets/Icons/lupe-18.svg'
import aktualisierenIcon from '../../assets/Icons/aktualisieren-107.svg'
import logo from '../../assets/logo_klassenraum.png'
import mikrofonIcon from '../../assets/Icons/mikrofon-22.svg'
import daumenHochIcon from '../../assets/Icons/daumen-hoch.svg'
import keyboardIcon from '../../assets/Icons/keyboard-24px.svg'
import menuMetacom from '../../assets/METACOM/Header/menueSW.png'
import lupeMetacom from '../../assets/METACOM/Allgemein/suchenlupe.png'
import tastaturTippenMetacom from '../../assets/METACOM/Allgemein/tastaturtippen.png'

import Faq from "../Faq";

// This variable will hold the reference to document's click handler, used for the v-directive closable
let handleOutsideClick

export default {
    name: "Plan",
    components: {
        Faq,
        BabyChatWidget,
        Tutorial,
        ChatWidget,
        BrettWidget,
        TTSSmall,
        PlanWidget,
        PlanWidgetBaby,
        FileWidget,
        VueSlickCarousel,
        HausiWidgetBaby,
        BabyChatDropDown,
        JoinKlassenraumButton,
    },

    directives: {
        //directive from https://tahazsh.com/detect-outside-click-in-vue with changes from Peter Means (in comments)
        closable: {
            bind (el, binding, vnode) {
                // Here's the click/touchstart handler (it is registered below)
                handleOutsideClick = (e) => {
                    e.stopPropagation()
                    // Get the handler method name and the exclude array from the object used in v-closable
                    const { handler, exclude } = binding.value

                    // This variable indicates if the clicked element is excluded
                    let clickedOnExcludedEl = false
                    exclude.forEach(refName => {
                        // We only run this code if we haven't detected any excluded element yet
                        if (!clickedOnExcludedEl) {
                            // Get the element using the reference name
                            const excludedEl = vnode.context.$refs[refName];
                            let excludedDomEl = null;
                            if (excludedEl) {
                                // If it's a vue component grab the element, otherwise it is the element
                                excludedDomEl = excludedEl.$el ? excludedEl.$el : excludedEl;
                                clickedOnExcludedEl = excludedDomEl.contains(e.target);
                            }
                        }
                    })
                    // We check to see if the clicked element is not the dialog element and not excluded
                    if (!el.contains(e.target) && !clickedOnExcludedEl) {
                        // If the clicked element is outside the dialog and not the button,
                        // then call the outside-click handler from the same component this directive is used in
                        vnode.context[handler]()
                    }
                }
                // Register click/touchstart event listeners on the whole page
                document.addEventListener('click', handleOutsideClick)
                document.addEventListener('touchstart', handleOutsideClick)
            },

            unbind () {
                // If the element that has v-closable is removed, then
                // unbind click/touchstart listeners from the whole page
                document.removeEventListener('click', handleOutsideClick)
                document.removeEventListener('touchstart', handleOutsideClick)
            }
        }
    },

    data() {
        return {
            windowWidth: window.innerWidth,
            menu: false,
            topMenu: false,
            topLangMenu: false,
            btmNavMenu: false,
            btmNavLangMenu: false,
            date: new Date(),
            appointments: [],
            person: null,
            snackbar: false,
            snackbarText: 'Dieser Browser wird nicht unterstützt. Bitte benutzen Sie einen anderen Browser.',
            snackbar2: false,
            snackbarText2: 'Die Sprachsteuerung ist bereits aktiv. Lade die Seite neu um sie zu deaktivieren.',
            currentTTSLangImg: deFlagIcon,
            slots: [
            ],
            page: 0,

            babyView: null,             //for displaying simplified view
            sehen: null,
            hoeren: null,

            tutorialState: false,
            bbbTut: null,
            babyViewTut: false,         //to display BBB tut in simplified view

            callbackMethod: null,       //joinBBB method passed up from Fach-component

            currSlide: 0,               //to color icons of mobile bottom navBar
            slideChangeAllowed: true,   //to prevent spamming of btmNavBar, which would bring highlighting of btmNavIcons and currently displayed widget out of sync

            textSelected: false,
            selectedText: '',

            closeChat: false,
            buttonFocusLeft: false,
            buttonFocusRight: false,

            voiceControlDialog: false,
            voiceControlSuccessful: false,

            setGlobalButtonContrast,

            loaded: false,
            deFlagIcon,
            frFlagIcon,
            trFlagIcon,
            arFlagIcon,
            enFlagIcon,
            ruFlagIcon,
            manPic,
            womanPic,
            diversPic,
            left,
            right,
            logoutIcon,
            menuIcon,
            fragezeichenIcon,
            lupenIcon,
            abbrechenIcon,
            aktualisierenIcon,
            logo,
            mikrofonIcon,
            daumenHochIcon,
            keyboardIcon,
            menuMetacom,
            lupeMetacom,
            tastaturTippenMetacom,

            btmNavItems: [
                { name: 'stundenplan', icon: stundenplanIcon, alt: 'Uhr', goToIndex: 0, bgColor: '#6995CD',color: 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);' },
                { name: 'brett', icon: brettIcon, alt: 'Schwarzes Brett', goToIndex: 1, bgColor: '#707070', color: 'filter: brightness(0) saturate(100%) invert(47%) sepia(0%) saturate(98%) hue-rotate(150deg) brightness(90%) contrast(86%);' },
                { name: 'chat', icon: chatIcon, alt: 'Chat', goToIndex: 2, bgColor: '#8CBD46', color: 'filter: brightness(0) saturate(100%) invert(70%) sepia(22%) saturate(1027%) hue-rotate(43deg) brightness(94%) contrast(90%);' },
                { name: 'dateien', icon: dateienIcon, alt: 'Datei', goToIndex: 3, bgColor: '#F49F31', color: 'filter: brightness(0) saturate(100%) invert(67%) sepia(71%) saturate(1286%) hue-rotate(338deg) brightness(104%) contrast(91%);' },
            ],
            btmNavItemsSimple: [
                { name: 'stundenplan', icon: stundenplanIcon, goToIndex: 0, bgColor: '#6995CD',color: 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);', alt: 'Stundenplan' },
                { name: 'hausaufgaben', icon: dateienIcon, goToIndex: 1, bgColor: '#F49F31', color: 'filter: brightness(0) saturate(100%) invert(67%) sepia(71%) saturate(1286%) hue-rotate(338deg) brightness(104%) contrast(91%);', alt: 'Hausaufgaben' },
                { name: 'chat', icon: chatIcon, goToIndex: 2, bgColor: '#8cbd46', color: 'filter: brightness(0) saturate(100%) invert(70%) sepia(22%) saturate(1027%) hue-rotate(43deg) brightness(94%) contrast(90%);', alt: 'Chat' },
            ],
            btmNavLangItems: [
                /*1st lang row*/
                { countryCode: 'de', icon: deFlagIcon, alt: 'Deutsche Flagge' },
                { countryCode: 'en', icon: enFlagIcon, alt: 'Englische Flagge' },
                { countryCode: 'fr', icon: frFlagIcon, alt: 'Französische Flagge' },
                /*2nd lang row*/
                { countryCode: 'tr', icon: trFlagIcon, alt: 'Türkische Flagge' },
                { countryCode: 'ar', icon: arFlagIcon, alt: 'Arabische Flagge' },
                { countryCode: 'ru', icon: ruFlagIcon, alt: 'Russische Flagge' },
                /*3rd lang row*/
                { icon: '', alt: '' },
                { icon: '', alt: '' },
                { icon: '', alt: '' },
                /*4th lang row*/
                { icon: '', alt: '' },
                { icon: '', alt: '' },
                { icon: '', alt: '' },
                /*5th lang row*/
                { icon: '', alt: '' },
                { icon: '', alt: '' },
                { icon: '', alt: '' },
                /*6th lang row*/
                { icon: '', alt: '' },
                { icon: '', alt: '' },
                { icon: '', alt: '' },
            ]
        };
    },
    props: {
            isRunning: {type: Boolean, required: false, default: false},
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    async created() {
        this.checkTutorialState();
        await this.requestBabyViewAndAccessibilityMode();
    },
    async mounted() {
        window.addEventListener("keyup", event => {
            if (event.altKey && event.key === 'a') {
                console.log("ToggleAbmelden");
                this.clickLogoutUser()
                this.triggerShortcutTooltips();
            }
        });
        window.addEventListener("keyup", event => {
            if(event.key === 'Tab' && !this.tabPressedOnce && this.babyView) {
                this.triggerTabPressedOnce();
            }
        });

        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);

            window.addEventListener('orientationchange', this.onOrientationChange);
        });

        setInterval(() => {
            let selection = { ...this.selectedText };
            if (window.getSelection) {
                selection = window.getSelection();
            }
            // document.getSelection
            else if (document.getSelection) {
                selection = document.getSelection();
            }
            // document.selection
            else if (document.selection) {
                selection =
                    document.selection.createRange().text;
            }
            if(selection.toString() !== this.selectedText) {
                this.update = !this.update;
            }
            this.selectedText = selection.toString();

        }, 100);

        if (this.targetLang !== 'de') {
            this.currentTTSLangImg = this[`${this.targetLang}FlagIcon`];
        }

        this.date = new Date(await this.loadServerTime());
        this.requestSlots();
        this.requestAppointments();
        this.requestPerson();
    },
    computed: {
        ...mapState("auth", ["account"]),
        ...mapState("faq", ["faq"]),
        ...mapState("speechControl", ["speechControl"]),
        ...mapState("speechControl", ["word"]),
        ...mapState("magnifier", ["magnifier"]),
        ...mapState("util", ["keyboard"]),
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips", "tabPressedOnce"]),
        ...mapState('translation', [ 'targetLang' ]),

        showMenuTooltips() {
            return (this.shortcutTooltips && this.topMenu);
        }
    },
    methods: {
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions("timeslots", ["getSlots"]),
        ...mapActions("pupils", ["getMePupil"]),
        ...mapActions("translation", ["setTargetLang"]),
        ...mapActions("auth", ["logoutUser", "reloadDontLogout"]),
        ...mapActions("util", ["loadServerTime", "toggleKeyboard"]),
        ...mapActions("magnifier", ["toggleMagnifier", "turnOffMagnifier"]),
        ...mapActions("faq", ["toggleFaq", "closeFaq"]),
        ...mapActions("speechControl", ["toggleSpeechControl", "setSpeechControlWord"]),
        ...mapActions("tooltips", ["triggerShortcutTooltips", "toggleTooltips", "triggerTabPressedOnce"]),

        async setFocusOnNextPage() {
            await this.$nextTick();
            console.log(this.page);
            if(this.page === 1) {
                document.getElementById("dateienHeading").focus();
            } else if(this.page === 0) {
                document.getElementById("stundenplanHeading").focus();
            }

        },

        onEnd ({ lastSentence, transcription }) {
            console.log(lastSentence.toLowerCase())
            this.setSpeechControlWord(lastSentence.toLowerCase())
        },

        startBBBTut(callback) {
            this.tutorialState = false;

            this.bbbTutState = JSON.parse(localStorage.getItem('bbbTut'));
            this.bbbTut = true;

            if(this.babyView) {
                this.babyViewTut = false;
            }

            this.callbackMethod = callback;
        },
        toggleControl() {
            if(!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) {
                if(!this.speechControl) {
                    this.toggleSpeechControl(true);
                    this.voiceControlDialog = true;
                } else {
                    this.snackbar2 = true;
                }
            } else {
                this.snackbar = true;
            }
        },
        openBBB() {
            this.callbackMethod();
        },

        resetTutorial() {
            this.tutorialState = false;
            localStorage.setItem('tutorial', 'false');
        },
        checkTutorialState() {
            this.tutorialState = JSON.parse(localStorage.getItem('tutorial'));
        },
        onResize() {
            this.windowWidth = window.innerWidth;
            this.closeBtmNavMenus();
        },
        async requestAppointments() {
            this.appointments = await this.getAppointments();
        },

        async requestBabyViewAndAccessibilityMode() {
            let temp = await this.getMePupil();
            this.babyView = temp.babyView;
            if(temp.accessibility) {
                this.sehen = temp.accessibility.sehen;
                this.hoeren = temp.accessibility.hoeren;
            } else {
                this.sehen = false;
                this.hoeren = false;
            }
            this.loaded = true; //to check if they are babys
            if (this.babyView) {
                this.babyViewTut = true;
            }
        },

        async requestPerson() {
            this.person = await this.getMePupil();
            console.log(this.person);
            //set babyView and accessibility initale
            this.babyView = this.person.babyView;
            if(this.person.accessibility) {
                this.sehen = this.person.accessibility.sehen;
                this.hoeren = this.person.accessibility.hoeren;

                if (this.person.accessibility.sehen) {
                    this.setGlobalButtonContrast(
                        'invert(75%) saturate(200%) brightness(150%)',
                        '5px solid orange'
                    );
                }
            } else {
                this.sehen = false;
                this.hoeren = false;
                this.setGlobalButtonContrast('none', 'none');
            }
            return this.person;
        },
        async requestSlots() {
            this.slots = await this.getSlots();
        },

        //for later to toggle babyView via menu
        setBabyView(value) {
            this.babyView = value;
        },

        getPersonPic() {
            try {
                let gender = this.person.gender;
                switch (gender) {
                    case "m": return manPic;
                    case "w": return womanPic;
                    case "d": return diversPic;
                }
            } catch (e) {
                console.log(e);
            }
        },

        clickLogoutUser() {
            this.logoutUser();
        },

        ISO8601_week_no(dt) {
            let tdt = new Date(dt.valueOf());
            let dayn = (dt.getDay() + 6) % 7;
            tdt.setDate(tdt.getDate() - dayn + 3);
            let firstThursday = tdt.valueOf();
            tdt.setMonth(0, 1);
            if (tdt.getDay() !== 4)
            {
                tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
            }
            return 1 + Math.ceil((firstThursday - tdt) / 604800000);
        },

        addWeek() {
            this.date = new Date(this.date .getFullYear(), this.date .getMonth(), this.date .getDate()+7);
        },
        subWeek() {
            this.date = new Date(this.date .getFullYear(), this.date .getMonth(), this.date .getDate()-7);
        },
        getMonday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getTuesday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -5:2); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getWednesday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -4:3); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getThursday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -3:4); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getFriday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -2:5); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getAppointment(slot, day) {
            switch (day) {
                case 'mon':
                    day = this.getMonday(this.date);
                    break;
                case 'die':
                    day = this.getTuesday(this.date);
                    break;
                case 'mit':
                    day = this.getWednesday(this.date);
                    break;
                case 'don':
                    day = this.getThursday(this.date);
                    break;
                case 'fre':
                    day = this.getFriday(this.date);
                    break;
            }
            return this.appointments.find((el) => {
                return (el.timeslot === slot && this.sameDay(new Date(el.day), day))
            })
        },

        sameDay(first, second) {
            return   first.getFullYear() === second.getFullYear() &&
                first.getMonth() === second.getMonth() &&
                first.getDate() === second.getDate();
        },

        changeLang(newLang, newLangImg) {
            this.currentTTSLangImg = newLangImg;
            this.setTargetLang(newLang);
            console.log('lang changed to ' + newLang);
        },

        reloadPage() {
            this.reloadDontLogout(true);
            this.$router.go();
        },

        /*btmNav methods*/
        changeLangBtmNav(newLang, newLangImg) {
            //statement needed for empty menuIcons in btmNavLangMenu
            if(newLangImg !== '') {
                this.currentTTSLangImg = newLangImg;
                this.setTargetLang(newLang);
                this.btmNavLangMenu = false;
            }
        },
        switchSlide(index) {
            this.closeFaq();
            if(this.slideChangeAllowed && index !== this.currSlide) {
                if (this.babyView) {
                    this.$refs.carouselBabyView.goTo(index);
                } else {
                    this.$refs.carousel.goTo(index);
                }
                this.currSlide = index;
                this.slideChangeAllowed = false;
                this.closeBtmNavMenus();
            }
            /*if btmNavMenu is open and you click on the button for the widget which is already being displayed*/
            else if(this.slideChangeAllowed && this.btmNavMenu) {
                this.btmNavMenu = false; //then just close the btmNavMenu
            }
        },
        setCurrSlide(index) {
            this.currSlide = index;
        },
        closeBtmNavMenus() {
            this.btmNavLangMenu = false;
            this.btmNavMenu = false;
        },
        handleBtmMenuClick() {
            this.btmNavMenu = !this.btmNavMenu;
            this.btmNavLangMenu = false;
        },
        onClose() {
            this.closeBtmNavMenus();
        },
        onOrientationChange() {
            this.switchSlide(0);
            this.slideChangeAllowed = true;
        },
        clickTTS() {
            this.$refs.tts.$el.click();
        },
        clickTTSFloat() {
            this.$refs.ttsFloat.$el.click();
        },

        /*topMenu methods*/
        changeLangNav(newLang, newLangImg) {
            //statement needed for empty menuIcons in btmNavLangMenu
            if(newLangImg !== '') {
                this.currentTTSLangImg = newLangImg;
                this.setTargetLang(newLang);
                this.topLangMenu = false;
            }
        },
        handleTopMenuClick() {
            this.topMenu = !this.topMenu;
            this.topLangMenu = false;
        },
        onCloseTop() {
            this.topLangMenu = false;
            this.topMenu = false;
        },
        closeTopMenus(elementIdFocus) {
            if(elementIdFocus) {
                const el = document.getElementById(elementIdFocus);
                el.focus();
            }
            else if(this.babyView) {
                const el = document.getElementById("menuActivatorBaby");
                el.focus();
            } else {
                const el = document.getElementById("menuActivator");
                el.focus();
            }

            this.topLangMenu = false;
            this.topMenu = false;

        },
    },

    watch: {
        word(newVal) {
            if(newVal === 'sprachsteuerung') {
                this.voiceControlSuccessful = true;
                setTimeout(() => {
                    this.voiceControlDialog = false;
                    this.voiceControlSuccessful = false;
                }, 2000);
            }
            if(newVal === 'abmelden') {
                this.logoutUser();
            }
        },
        windowWidth(val) {
            if(val <900) {
                this.turnOffMagnifier()
            }
        },
        selectedText() {
            if(this.selectedText.length) {
                if(!this.textSelected) {
                    this.textSelected = true;
                }
            } else {
                if(this.textSelected) {
                    this.textSelected = false;
                }
            }
        },
    }
};
</script>

<style lang="scss" scoped>
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    tr {
        height: 40px;
    }

    table tr th:first-child,
    table tr td:first-child {
        width: 10px !important;
    }

    table tr th,
    table tr td {
        width: 9em;
        border: none;
    }
    table, tr, td {
        border: none;
    }

    .fach {
        cursor: pointer;
    }

    .small {
        display: none;
    }

    .big {
        display: flex;
    }

    .headerAsOne {
        display: none !important;
    }

    .slick-slider {
        user-select: auto!important;
    }

    .ellipsis {
        text-overflow: ellipsis;

        /* Required for text-overflow to do anything */
        white-space: nowrap;
        overflow: hidden;
    }

    .greyText {
        color: #707070;
    }

    .btmNav {
        display: none;
        z-index: 10;
        position: fixed;
        bottom: 0;
        width: 100% !important;
        height: 70px !important;
        background-color: #EEEEEE;
    }

    .btmNavBtn {
        height: 50px !important;
        width: 50px !important;
        background-color: white !important;
        border-radius: 8px !important;
    }

    /*removes darker background after btn is clicked
    also works without the specific class in front*/
    .btmNavBtn.v-btn:before {
        opacity: 0 !important;
    }
    .arrowBtn:before {
      opacity: 0 !important;
    }

    .arrowBtn:focus {
        background-color: #6996cd !important;
    }

    .imgFocus {
      filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%) !important;
    }

    .buttonFocusHover:hover {
      background-color: #6994cd !important;
    }

    .buttonFocusHover:hover .imgFocusHover{
      filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%) !important;
    }

    .btmNavIcon {
        height: 35px;
    }

    .hideMe {
        display: none !important;
    }

    .topMenu {
        position: absolute;
        top: 74px;
        right: 5%;
        width: 160px;
        max-width: 160px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px silver;
        z-index: 11;
    }

    .topLangMenu {
        position: fixed;
        top: 74px;
        right: calc(5% + 160px);
        border-bottom-left-radius: 12px;
        background-color: #FFFFFF;
        box-shadow: -3px 2px 3px silver;
    }

    .btmNavMenu {
        position: fixed;
        bottom: 70px;
        right: 0;
        width: 160px;
        max-width: 160px;
        background-color: #FFFFFF;
        box-shadow: 0 -2px 4px silver;
    }

    .btmNavMenuBtn {
        color: black;
        background-color: #EDEDED;
        border-radius: 8px;
        justify-content: flex-start;
    }

    .btmNavLangNoIcon {
        height: 25px;
        width: 25px;
        background-color: transparent;
    }

    .btmNavLangMenu {
        position: fixed;
        bottom: 70px;
        right: 160px;
        border-top-left-radius: 12px;
        background-color: #FFFFFF;
        box-shadow: -5px -2px 4px silver;
    }

    .btmNavLangBtn {
        width: 41px; height: 41px;  /*needed so that border and box-sizing work*/
        color: #707070;
        border-radius: 8px;
        min-width: 41px !important;
    }

    /*if there is a flag/icon it has a grey background*/
    .langBtnBg{
        background-color: #EFEFEF !important;
    }

    .border {
        border: 3px #6995CD solid;
        box-sizing: border-box !important;
    }

    .pointer {
        cursor: pointer;
    }

    .btmNavMenuIconActive {
        background-color: #6995CD !important;
    }

    @media only screen and (max-width: 900px) {
        .small {
            display: block !important;
        }

        .big {
            display: none !important;
        }

        .topPadding {
            padding-top: 12px;
        }
        .headerSides {
            display: none !important;
        }

        .headerAsOne {
            display: inline-flex !important;
        }

        .headerMid {
            width: 100% !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        .displayHide {
            display: none;
        }

        .btmNav {
            display: flex;
        }

        .moreBottom {
            bottom: 80px !important;
        }
    }

    @media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
        .headerSides {
            display: none !important;
        }

        .headerAsOne {
            display: inline-flex !important;
        }

        .headerMid {
            width: 100% !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        .displayHide {
            display: none;
        }

        .btmNav {
            display: flex;
        }

        .moreBottom {
            bottom: 80px !important;
        }
    }

    .animateIcon {
        overflow: hidden;
    }

    .animateIcon:after {
        animation: shine 60s ease-in-out infinite;
        animation-fill-mode: forwards;
        content: "";
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);

        background: rgba(255, 255, 255, 0.0);
        background: linear-gradient(
                to right,
                rgba(112, 112, 112, 0.2) 0%,
                rgba(112, 112, 112, 0.2) 77%,
                rgba(112, 112, 112, 0.5) 92%,
                rgba(112, 112, 112, 0.0) 100%
        );
    }

    /* Hover state - trigger effect */

    /* Active state */
    .animateIcon:active:after {
        opacity: 0;
    }

    @keyframes shine{
        1% {
            opacity: 1;
            top: -30%;
            left: -30%;
            transition-property: left, top, opacity;
            transition-duration: 0.7s, 0.7s, 0.15s;
            transition-timing-function: ease;
        }
        5% {
            opacity: 0;
            top: -30%;
            left: -30%;
            transition-property: left, top, opacity;
        }
    }

    .v-snack.v-snack--top {
        bottom: initial; /* or auto */
    }

    .v-snack.v-snack--bottom {
        top: initial; /* or auto */
    }

    .iconToWhite {
        filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
    }

    .onTop{
        z-index: 11!important;
    }
</style>
