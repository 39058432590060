/* eslint-disable no-console */

import { register } from 'register-service-worker'

// My longer file test
// Varaible to show if application is loaded for the first time
// or if it is a update
let serviceWorkerIsInstalledFirstTime = true;

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log('## SW ##',
          'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('## SW ##','Service worker has been registered.')
    },
    cached () {
      console.log('## SW ##','Content has been cached for offline use.')
    },
    updatefound (registration) {
      console.log('## SW ##','New content is downloading.')
      window.alert("Update wird heruntergeladen.");

      const newWorker = registration.installing;
      newWorker.addEventListener('statechange', function (e) {
        console.log('## SW ## New SW State:', e.target.state);

        // if new service worker is installed an an service worker is active
        // then this is only a update
        if(e.target.state === "installed" && registration.active) {
          serviceWorkerIsInstalledFirstTime = false;
        }
        if (e.target.state === 'activated') {
          console.log('## SW ##','New SW is activated.')
          
          // Show prompt only if its really an update and not the first launch
          if (
            !serviceWorkerIsInstalledFirstTime
            && window.confirm("Eine neue Version ist verfügbar. Jetzt aktualisieren?")) {
            window.location.reload();
          }
        }
      });
      newWorker.addEventListener('install', (event) => {
        console.log('## SW ##','New SW is installing.')
        event.waitUntil(newWorker.skipWaiting());
      });
      newWorker.addEventListener('activate', (event) => {
        console.log('## SW ##','New SW is activating.')
        event.waitUntil(newWorker.clients.claim());
      });
    },
    updated () {
      console.log('## SW ##','New content is available; please refresh.')
    },
    offline () {
      console.log('## SW ##','No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('## SW ##: Error during service worker registration:', error)
    }
  })
}

export {

}