<template>
   <div id="slide" v-if="content.length">
       <v-btn
               :disabled="!moveAlong"
               class="navBtn"
               style="top: 10px; z-index: 2"
               :style="moveAlong ? 'filter: invert(0) sepia(1) saturate(2) hue-rotate(170deg)' : '' "
               text color="#6f6f6f"
               large @click="setTutorialDone()"
       >
           Tutorial beenden
           <img :style="moveAlong ? '' : 'opacity: 0.5' " :src="rechts" alt="">
       </v-btn>
       <div id="langAndReadWrapper">
           <div id="langChooserDiv">
               <div id="menuContainer">
               <v-menu style="border-radius: 5px !important;" offset-y nudge-left="98" nudge-bottom="5" :attach="'#menuContainer'">
                   <template v-slot:activator="{ on, attrs }">
                       <v-btn
                           v-bind="attrs"
                           v-on="on"
                           id="langBtn"
                           color="#F8F8F8"
                           x-small
                           style="width: 45px; height: 45px; border-radius: 8px; box-shadow: 0 5px 10px rgba(0,0,0, 0.16)"
                       >
                           <img :src="currentTTSLangImg" style="max-width: 25px; max-height: 25px" alt="Sprache auswählen"/>
                       </v-btn>
                   </template>
                   <div style="width: 120px; height: 25px; display: inline; border-radius: 0 !important; background-color: #EAEAEA !important;">
                       <v-btn @click="changeLang('de', deFlagIcon)"
                              text x-small
                              style="background-color: #EAEAEA; border-radius: 0 !important; width: 49px; height: 49px"
                              role="menuitem">
                           <img :src="deFlagIcon" width="24" height="24" alt="Deutsche Flagge"/>
                       </v-btn>
                       <v-btn @click="changeLang('en', enFlagIcon)"
                              text x-small
                              style="background-color: #EAEAEA; border-radius: 0 !important; width: 49px; height: 49px"
                              role="menuitem">
                           <img :src="enFlagIcon" width="24" height="24" alt="Englische Flagge"/>
                       </v-btn>
                       <v-btn @click="changeLang('fr', frFlagIcon)"
                              text x-small
                              style="background-color: #EAEAEA; border-radius: 0 !important; width: 49px; height: 49px"
                              role="menuitem">
                           <img :src="frFlagIcon" width="24" height="24" alt="Französische Flagge"/>
                       </v-btn>
                       <v-btn @click="changeLang('tr', trFlagIcon)"
                              text x-small
                              style="background-color: #EAEAEA; border-radius: 0 !important; width: 49px; height: 49px"
                              role="menuitem">
                           <img :src="trFlagIcon" width="24" height="24" alt="Türkische Flagge"/>
                       </v-btn>
                       <v-btn @click="changeLang('ar', arFlagIcon)"
                              text x-small
                              style="background-color: #EAEAEA; border-radius: 0 !important; width: 49px; height: 49px"
                              role="menuitem">
                           <img :src="arFlagIcon" width="24" height="24" alt="Arabische Flagge"/>
                       </v-btn>
                       <v-btn @click="changeLang('ru', ruFlagIcon)"
                              text x-small
                              style="background-color: #EAEAEA; border-radius: 0 !important; width: 49px; height: 49px"
                              role="menuitem">
                           <img :src="ruFlagIcon" width="24" height="24" alt="Russische Flagge"/>
                       </v-btn>
                   </div>
               </v-menu>
                   </div>
           </div>

           <div ref="readBtn" style="display: none" @click="greet"></div>

           <div id="readDiv" @click="toggleReading">
               <v-btn color="#F8F8F8" x-small class="mx-0" style="width: 45px; height: 45px; border-radius: 8px; box-shadow: 0 5px 10px rgba(0,0,0, 0.16)">
                   <img v-if="readOutAllowed" :src="lautsprecherAusIcon" style="height: 35px; opacity: 0.3" alt="Vorlesen stoppen"/>
                   <img v-else :src="lautsprecherIcon" style="height: 35px; opacity: 0.3" alt="Vorlesen"/>
               </v-btn>
               <p>Vorlesen</p>
               <img :src="musiknoteRot" id="musiknoteRot" alt=""/>
               <img :src="musiknoteGelb" id="musiknoteGelb" alt=""/>
           </div>
       </div>

       <div id="content">
           <img id="contentImg" :src="content[index].img"/>

           <div id="contentHeaderAndText">
               <div id="contentHeader">
                   {{currentHeader}}
                   <v-overlay v-if="false" color="primary" absolute style="height: 150px"/>
                   <v-progress-circular v-if="showLoading" color="primary" indeterminate id="loadingTranslation" />
               </div>

               <div id="contentText" v-html="currentText">
               </div>
           </div>
       </div>

       <div id="bottomBar">
           <div id="navBar">
               <v-row>
                   <v-col style="display: flex" md="2" sm="3" cols="5">
                       <div style="height: 40px"></div>
                       <v-btn
                           v-if="index>0" class="navBtn"
                           style="opacity: 0.8"
                           text color="#6f6f6f" large
                           @click="index--"
                       >
                           <img :src="links" alt="Seite zurück">
                           Zurück
                       </v-btn>
                   </v-col>

                   <v-col style="text-align: center; color: #707070">
                       <div v-if="$vuetify.breakpoint.width < 700" id="navCounter">
                           {{index + 1}}/{{content.length}}
                       </div>

                       <div v-else style="display: inline-flex; margin: auto">
                           <div v-for="(el, elIndex) in content" :key="el._id + elIndex" class="dot" :active="elIndex<=index">
                           </div>
                       </div>
                   </v-col>

                   <v-col style="display: flex" md="2" sm="3" cols="5">
                       <v-btn
                           :disabled="!moveAlong"
                           v-if="index+ 1 !== content.length"
                           class="navBtn"
                           :style="moveAlong ? 'filter: invert(0) sepia(1) saturate(2) hue-rotate(170deg)' : '' "
                           text color="#6f6f6f" large @click="index++"
                           aria-label="Seite weiter"
                       >
                           Weiter
                           <img :style="moveAlong ? '' : 'opacity: 0.5' " :src="rechts" >
                       </v-btn>
                       <v-btn
                           :disabled="!moveAlong"
                           v-else class="navBtn"
                           :style="moveAlong ? 'filter: invert(0) sepia(1) saturate(2) hue-rotate(170deg)' : '' "
                           text color="#6f6f6f"
                           large @click="setTutorialDone()"
                           aria-label="Tutorial beenden"
                       >
                           Beenden
                           <img :style="moveAlong ? '' : 'opacity: 0.5' " :src="rechts">
                       </v-btn>
                   </v-col>
               </v-row>
           </div>

           <!--Progress Bar-->
           <div id="ProgressBarWrapper">
               <v-progress-linear
                   v-if="false"
                   v-model="progressBar"
                   class="progressbar"
                   color="#BDBDBD"
                   rounded
                   background-color="rgba(112,112,112,15%)"
                   height="8"
               >
               </v-progress-linear>
           </div>
       </div>

   </div>
</template>

<script>
import lautsprecherIcon from '../../assets/Icons/lautsprecher-13.svg'
import lautsprecherAusIcon from '../../assets/Icons/lautsprecher-aus-89.svg'
import logo from '../../assets/logo_klassenraum.png'
import links from '../../assets/Icons/links-filled-10.svg'
import rechts from '../../assets/Icons/rechts-filled-11.svg'
import tutorialCollection from "../../constants/tutorialCollection";

import foto from '../../assets/Tutorial/Hausaufgaben-fotografieren.svg'
import ha from '../../assets/Tutorial/Hausaufgaben-machen.svg'
import oab from '../../assets/Tutorial/online-Arbeitsblätter-einsehen.svg'
import swb from '../../assets/Tutorial/SchwarzesBrett-anpinnen.svg'
import speakclass from '../../assets/Tutorial/Spreche-mit-deiner-Klasse.svg'
import speak from '../../assets/Tutorial/text-vorlesen.svg'
import bbb from '../../assets/Tutorial/unterricht-beitreten.svg'

import musiknoteGelb from '../../assets/Icons/Musiknote-1-gelb.svg'
import musiknoteRot from '../../assets/Icons/Musiknote-2-rot.svg'
import deFlagIcon from '../../assets/Icons/deutsch-59.svg'
import frFlagIcon from '../../assets/Icons/französisch-60.svg'
import enFlagIcon from '../../assets/Icons/englisch-61.svg'
import trFlagIcon from '../../assets/Icons/türkisch-97.svg'
import arFlagIcon from '../../assets/Icons/syrisch-98.svg'
import ruFlagIcon from '../../assets/Icons/russisch.svg'
import {mapActions, mapState} from "vuex";
import {getMePupil} from "@/api/backend";

export default {
name: "Tutorial",
    components: {
    },
    data() {
        return {
            foto,
            ha,
            oab,
            swb,
            speakclass,
            speak,
            bbb,
            logo,
            links,
            rechts,
            lautsprecherIcon,
            lautsprecherAusIcon,
            index: 0,
            content: [
            ],
            tutorialCollection,
            progressBar: 0,
            progressBarIntervall: null,
            readOutAllowed: false,
            moveAlong: true,
            showLoading: false,
            musiknoteGelb,
            musiknoteRot,
            currentTTSLangImg: deFlagIcon,
            currentLang: 'de',
            currentHeader: '',
            currentText: '',
            sehen: false,
            hoeren: false,
            deFlagIcon,
            frFlagIcon,
            trFlagIcon,
            arFlagIcon,
            enFlagIcon,
            ruFlagIcon,
            person: {
                accessibility: {
                    screenreader: false,
                }
            }
        };
    },
    async mounted() {
        //Add eventListener for skipping with Right/Left Arrow through Tutorial
        window.addEventListener("keydown", this.handleClickEvent);

        this.setContent();
        this.setTimer();
        await this.requestPerson();
        setTimeout(() => {this.$refs.readBtn.click()}, 500);

        this.currentHeader = this.content[0].header;
        this.currentText = this.content[0].text;

        this.isLangPackageAvailable();
    },
    beforeDestroy() {
        window.speechSynthesis.cancel()
        this.readOutAllowed = false;
    },
    computed: {
        ...mapState('util', ['keyboard']),
        ...mapState('auth', ['account']),
    },
    props: {
        babyView: {required: true},
        teacherView: {required: false, default: false},
        teacherBBB: {required: false, default: false},
        bbbTut: {required: false, default: false},
        openBBB: {required: false},
        checkTutorialState: {required: true},
    },
    methods: {
        ...mapActions("translation", ["setTargetLang" , "translateToTargetLang"]),
        ...mapActions('pupils', [ 'getMePupil' ]),

        async requestPerson() {
            if(this.account.role === 'pupil') {
                this.person = await this.getMePupil();
                //set babyView and accessibility initale
                if (this.person.accessibility) {
                    this.sehen = this.person.accessibility.sehen;
                    this.hoeren = this.person.accessibility.hoeren;

                    // if (this.person.accessibility.sehen) {
                    //     this.setGlobalButtonContrast('invert(75%) saturate(200%) brightness(150%)', '5px solid orange');
                    // }
                } else {
                    this.sehen = false;
                    this.hoeren = false;
                }
            }
        },

        handleClickEvent(event) {
            if(!this.keyboard) {
                if (event.key === 'ArrowRight') {
                    if (this.index + 1 !== this.content.length) {
                        this.index++;
                    } else {
                        this.setTutorialDone();
                    }
                } else if (event.key === 'ArrowLeft' && this.index > 0) {
                    this.index--;
                }
            }
        },

        async translateContent() {
            this.showLoading = true;

            this.currentHeader = await this.translateToTargetLang({
              targetLang: this.currentLang,
              textToTranslate: this.content[this.index].header}
              );

            let tmp = document.createElement('div');
            tmp.innerHTML = this.content[this.index].text;
            let text = tmp.textContent || tmp.innerText;

            this.currentText = await this.translateToTargetLang({
              targetLang: this.currentLang,
              textToTranslate: text
            });

            this.showLoading = false;
        },
        setTutorialDone() {
            window.speechSynthesis.cancel();
            //Remove the eventListener for skipping with Right/Left Arrow through Tutorial
            window.removeEventListener("keydown", this.handleClickEvent);

            // reset timer if already set
            if (this.progressBarIntervall) {
                clearInterval(this.progressBarIntervall);
                this.progressBarIntervall = 0;
                this.progressBar = 0;
            }
            if(window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
            }

            if(this.bbbTut || this.teacherBBB) {
                localStorage.setItem('bbbTut', 'true');
                this.checkTutorialState();
                this.$emit('bbb-tut-done');
            } else {
                localStorage.setItem('tutorial', 'true');
                this.checkTutorialState();
            }

        },
        toggleReading() {
            if(!this.person.accessibility.screenreader) {
                this.readOutAllowed = !this.readOutAllowed;

                if (window.speechSynthesis.speaking) {
                    window.speechSynthesis.cancel()
                }

                if (this.readOutAllowed) {
                    this.greet();
                }
            }
        },
        setContent() {
            if(this.babyView) {
                this.content = tutorialCollection.babyView;
            } else if(this.bbbTut) {
                this.content = tutorialCollection.bbbPupil;
            } else if(this.teacherView) {
                this.content = tutorialCollection.normalTeacher;
            } else if(this.teacherBBB) {
                this.content = tutorialCollection.bbbTeacher;
            } else {
                this.content = tutorialCollection.normalPupil;
            }
        },
        setTimer() {
            // reset timer if already set
            if (this.progressBarIntervall) {
                clearInterval(this.progressBarIntervall);
                this.progressBarIntervall = 0;
                this.progressBar = 0;
            }

            // set Intervall (ges. 10 Sekunden)
            this.progressBarIntervall = setInterval(() => {
                if (this.progressBar !== 100) {
                    this.progressBar++;
                } else {
                    // Reset progressbar and trigger something
                    this.moveAlong = true;
                    clearInterval(this.progressBarIntervall);
                    this.progressBarIntervall = 0;
                    this.progressBar = 0;
                }
            }, 100)
        },
        greet() {
            if(!this.person.accessibility.screenreader) {
                if ('speechSynthesis' in window) {
                    // Speech Synthesis supported 🎉
                } else {
                    // Speech Synthesis Not Supported 😣
                    alert("Sorry, your browser doesn't support text to speech!");
                }

                if (window.speechSynthesis.speaking) {
                    window.speechSynthesis.cancel()
                }

                let tmp = document.createElement('div');
                tmp.innerHTML = this.content[this.index].header + '. ' + this.content[this.index].text;
                let msgText = tmp.textContent || tmp.innerText;

                if (this.currentLang !== 'de') {
                    msgText = this.currentHeader + '. ' + this.currentText;
                }

                let msg = new SpeechSynthesisUtterance();
                msg.text = msgText;
                msg.lang = this.currentLang;
                if (this.readOutAllowed && this.isLangPackageAvailable()) {
                    window.speechSynthesis.speak(msg);
                } else {
                    this.readOutAllowed = false;
                }
            }
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.currentLang)) {
                    return true;
                }
            }

            return false;
        },

        changeLang(newLang, newLangImg) {

            this.currentTTSLangImg = newLangImg;
            this.setTargetLang(newLang);
            this.currentLang = newLang;
            if (newLang !== 'de') {
                this.translateContent();
            } else {
                this.currentHeader = this.content[this.index].header;
                this.currentText = this.content[this.index].text;
            }
        }
    },
    watch: {
        babyView () {
            this.setContent();
        },
        index () {
            this.moveAlong = true;
            this.currentHeader = this.content[this.index].header;
            this.currentText = this.content[this.index].text;

            this.setTimer()
            if (this.currentLang !== 'de') {
                this.translateContent();
            }

            setTimeout(() => {this.greet()}, 500);
        },
    }

}
</script>

<style lang="scss" scoped>
    #slide {
        position: fixed;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #F8F8F8;
        z-index: 999;
    }

    #logo {
        position: fixed;
        top: 30px;
        left: 40px;
        height: 4rem;
    }

    @media only screen and (max-width: 700px) {
        #logo {
            top: 40px;
            left: 10px;
            height: 3rem;
        }
    }

    #langAndReadWrapper {
        display: flex;
        position: fixed;
        top: 45px;
        right: 40px;
    }

    @media only screen and (max-width: 700px) {
        #langAndReadWrapper {
            right: 10px;
        }
    }

    #langBtn {
        margin-right: 16px;
    }

    @media only screen and (max-width: 340px) {
        #langBtn {
            margin-right: 4px;
        }
    }

    #readDiv {
        height: 5rem;
        display: inline-flex;
        color: #D6D6D6;
        cursor: pointer;

        p {
            height: 45px;
            text-align: center;
            line-height: 45px;
            font-size: 20px;
            padding-left: 10px;
            margin-bottom: 0;
        }
    }

    #content {
        color: #737373;
        left: 0;
        right: 0;
        margin: 0;
        top: 50%;
        position: absolute;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    #contentHeaderAndText {
        height: 120px;
        margin-top: 30px;
    }

    #bottomBar {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 5%;
    }

    #navBar {
        width: 100%;
        display: inline-flex;
    }

    .navBtn {
        margin: auto;
        text-transform: inherit;

        & img {
            width: 40px;
        }
    }

    #navCounter {
        font-weight: bold;
        line-height: 3rem;
        text-align: center;
    }

    #contentText {
        max-width: 700px;
        font-size: large;
        margin: auto;
        margin-top: 0.75rem;
        text-align: center;
    }

    @media only screen and (max-width: 700px) {
        #contentText {
            max-width: 90%;
        }
    }

    #contentImg {
        display: flex;
        margin: auto;

        max-height: calc(100vh - 400px);
        /*max-width: min(700px, 100%);*/
    }

    #contentHeader {
        margin: auto;
        text-align: center;
        font-weight: bold;
        font-size: larger;
        max-width: 700px;
    }

    .dot[active]{
        width: 20px;
        height: 20px;
        background-color: #737373;
        border-radius: 20px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .dot{
        width: 20px;
        height: 20px;
        background-color: #73737350;
        border-radius: 20px;
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 0.55rem;
    }

    #ProgressBarWrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .progressbar {
        width: 50%;
    }

    #musiknoteRot {
        position: absolute;
        top: -15px;
        right: 0;
        width: 20px;
        height: 20px;
    }

    #musiknoteGelb {
        position: absolute;
        bottom: 15px;
        left: 50px;
        width: 20px;
        height: 20px;
    }

    #loadingTranslation {
        position: absolute;
        left: 50%;
        bottom: 150px;
    }

    @media only screen and (max-width: 700px) {
        #logo {
            object-fit: contain;
            width: 45vw;
        }

        #langAndReadWrapper {
            top: 40px;
        }
    }

    @media only screen and (max-width: 400px) {
        #logo {
            top: 24px;
            left: 10px;
            height: 2rem;
        }

        #langAndReadWrapper {
            top: 8px;
            right: 0;
            -ms-transform: translateX(20px);
            transform: scale(0.75) translateX(20px);
        }

        #contentImg {
            max-height: 150px;
        }

        #contentHeaderAndText {
            margin-top: 16px;
        }

        #contentHeader {
            font-size: medium;
            width: 90%;
        }

        #contentText {
            margin-top: 4px;
            font-size: small;
            white-space: pre-line;
        }

        .navBtn {
            & img {
                width: 28px;
            }
        }
    }

    .v-snack.v-snack--top {
        bottom: initial; /* or auto */
    }

    .v-snack.v-snack--bottom {
        top: initial; /* or auto */
    }
    .v-menu__content {
      max-width: 76% !important;
    }
</style>
