<template>
    <div
        id="answerFieldOuter"
        ref="answerFieldOuter"
        @click="$emit('focusMe')"
        @mouseup="resizeEnd"
        :style="`margin-top: ${account.role === 'teacher' ? '0px' : '32px'};
      height: ${answerEntry.height * canvasHeight}px;
      width: ${answerEntry.width * canvasWidth}px;`"
    >
        <v-row style="height: 100%" class="mx-0 pa-1">
            <img :src="moveIcon" v-if="account.role === 'teacher'" ref="movingIcon" style="cursor: grab; height: 30px"
                 height="25" width="25"/>
            <v-col
                v-if="!answerEntry.text || !answerEntry.text.length"
                class="pa-0"
                style="display: inline-flex"
            >
                <v-textarea
                    :class="{ 'moveableItemShadow': account.role === 'teacher' }"
                    :tabindex="tabIndex"
                    id="pupilAnswerTextArea"
                    ref="pupilAnswerTextArea"
                    outlined
                    background-color="#ffffff00"
                    hide-details dense
                    rows="1" row-height="10" auto-grow
                    label="Antworteingabe"
                    v-model="currentPupilAnswer"
                />

                <v-btn
                    v-if="account.role !== 'teacher'"
                    :tabindex="tabIndex"
                    color="success"
                    class="ml-2 my-4 userExerciseAnswerButn pa-0"
                    id="fertigBtn"
                    @click="userExerciseAnswer"
                >
                    <img
                        :src="fertigIcon"
                        alt="Antwort fertig"
                        :ref="`fertigBtn`"
                        class="icon iconToWhite"
                    />
                </v-btn>
                <v-btn
                    :tabindex="tabIndex" v-if="account.role === 'teacher' "
                    class="ml-2 my-4 userExerciseAnswerButn pa-0"
                    id="leereAntwortLoeschenIcon"
                    @click="deleteAnswerField"
                >
                    <img
                        :src="papierkorbIcon"
                        alt="Antwortfeld entfernen"
                        :ref="`loeschenBtn`"
                        class="icon"
                    />
                </v-btn>
            </v-col>
            <v-col v-else style="display: inline-flex">
                <div class="answerField pa-2 ma-2">
                    <p id="antwortText" ref="antwortText" class="my-auto" :tabindex="tabIndex">{{ answerEntry.text }}</p>

                    <v-btn
                        class="ml-2"
                        small
                        @click="deleteAnswer"
                        width="40px"
                        height="40px"
                        :tabindex="tabIndex"
                    >
                        <img
                            :src="papierkorbIcon"
                            alt="Antwort löschen"
                            width="35px"
                            height="35px"
                        />
                    </v-btn>
                </div>
                <v-btn
                    :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                    class="ml-2 my-auto"
                    small ref="readAnswerButton"
                    @click="readText(answerEntry.text, `vorlesenBtnIcon`)"
                    width="40px"
                    height="40px"
                    :tabindex="tabIndex"
                >
                    <img
                        :src="lautsprecherIcon"
                        alt="Antwort vorlesen"
                        :ref="`vorlesenBtnIcon`"
                        width="35px"
                        height="35px"
                        style="filter: brightness(0%)"
                    />
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import lautsprecherIcon from "../../assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "../../assets/Icons/lautsprecher-aus-89.svg";
import papierkorbIcon from "../../assets/Icons/papierkorb-109.svg";
import fertigIcon from "../../assets/Icons/fertig-74.svg";
import moveIcon from '@/assets/Icons/Temp/move.svg';

export default {
    name: 'EditorAnswerField',
    props: {
        answerEntry: {type: Object, required: true},
        tabIndex: { type: Number, required: false, default: 19 },
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
    },
    data() {
        return {
            //Textarea model
            currentPupilAnswer: null,

            // Drag and drop
            dragStartX: null,
            dragStartY: null,

            // UI
            papierkorbIcon,
            lautsprecherIcon,
            lautsprecherAusIcon,
            fertigIcon,
            moveIcon,
            vorlesenBtnIcons: [],

            x: 0,
            y: 0,
            person: {
                accessibility: {
                    screenreader: false,
                }
            }
        };
    },
    computed: {
        ...mapState("auth", ["account"]),
        isAnswerMine() {
            return (answer) => answer.author === this.account._id;
        },
    },
    mounted() {
        if (this.account.role === 'teacher') {
            this.dragElement(this.$refs.movingIcon);
        }
        this.requestPerson();
    },
    methods: {
        ...mapActions('pupils', ['getMePupil']),
        async requestPerson() {
            if(this.account.role === 'pupil') {
                this.person = await this.getMePupil();
                return this.person;
            }
        },
        // General Methods
        deleteAnswer() {
            this.answerEntry.text = "";
            this.currentPupilAnswer = "";

            setTimeout(() => {
                this.$refs.pupilAnswerTextArea.focus();
            }, 500)

            this.$forceUpdate();
        },
        deleteAnswerField() {
            this.$emit('deleteAnswerField');
        },
        dragElement(element) {
            let _this = this;
            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            let lastMove = null;

            if (element.id && document.getElementById(element.id + "header")) {
                // if present, the header is where you move the DIV from:
                document.getElementById(element.id + "header").onmousedown = dragMouseDown;
                document.getElementById(element.id + "header").ontouchstart = dragMouseDown;
            } else {
                // otherwise, move the DIV from anywhere inside the DIV:
                element.onmousedown = dragMouseDown;
                element.ontouchstart = dragMouseDown;
            }

            function dragMouseDown(e) {
                _this.$emit('focusMe');
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                lastMove = e;
                document.onmouseup = closeDragElement;
                document.ontouchend = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
                document.ontouchmove = elementDrag;
            }

            const elementDrag = (e) => {
                e = e || window.event;
                lastMove = e;
                if (e.type !== 'touchmove') {
                    e.preventDefault();
                }

                // calculate the new cursor position:
                pos1 = pos3 - (e.clientX ? e.clientX : e.touches[0].clientX);
                pos2 = pos4 - (e.clientY ? e.clientY : e.touches[0].clientY);
                pos3 = (e.clientX ? e.clientX : e.touches[0].clientX);
                pos4 = (e.clientY ? e.clientY : e.touches[0].clientY);

                // set the element's new position:
                const top = (Number.parseFloat(this.answerEntry.y)) - ((pos2 / this.canvasHeight) * 100);
                const left = (Number.parseFloat(this.answerEntry.x)) - ((pos1 / this.canvasWidth) * 100);

                if ((left > -10 && left < 110) && (top > -10 && top < 110)) {
                    this.answerEntry.x = left;
                    this.answerEntry.y = top;
                }
            }

            const closeDragElement = (e) => {
                // stop moving when mouse button is released:
                document.onmouseup = null;
                document.onmousemove = null;
                document.ontouchend = null;
                document.ontouchmove = null;
                try {
                    this.click(e.clientX ? e.clientX : lastMove.touches[0].clientX, e.clientY ? e.clientY : lastMove.touches[0].clientY);
                } catch (e) {
                    console.log(e);
                }
                lastMove = null;
            }
        },

        // Resizing Method
        resizeEnd(event) {
            if (event.dataTransfer) {
                this.dragEnd(event);
            }

            const width = this.$refs.answerFieldOuter.getBoundingClientRect().width;
            const height = this.$refs.answerFieldOuter.getBoundingClientRect().height;

            this.$emit("dimensionsChanged", {width, height});
        },

        // Answer Methods
        async userExerciseAnswer() {
            this.answerEntry.text = this.currentPupilAnswer;
            this.answerEntry.author = this.account._id;
            // this.answerEntry = {
            //     text: this.currentPupilAnswer,
            //     author: this.account._id
            // };
            this.currentPupilAnswer = "";
            await this.$nextTick();
            this.$refs.antwortText.focus();
        },

        // Read out Methods
        readText(text, answerRef) {
            if ("speechSynthesis" in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
            }

            let imageRef = this.$refs[answerRef];
            if (Array.isArray(imageRef)) {
                imageRef = imageRef[0];
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
                imageRef.src = this.lautsprecherIcon;
            } else {
                let msg = new SpeechSynthesisUtterance();
                msg.text = text;
                msg.lang = "de";
                window.speechSynthesis.speak(msg);

                imageRef.src = this.lautsprecherAusIcon;

                msg.onend = () => {
                    imageRef.src = this.lautsprecherIcon;
                };
            }
        },

        clickTestenBtn() {
            // Ich glaube, dass das etwa 600px an Bildschirmbreite waren, was 60 x 10px nach rechts verschieben entspricht. Müssten dann ja 1200px gewesen sein, da wir in der Mitte gestartet sind, aber ok :) Naja das war ganz grob 1/4 der Gesamtbildschirmbreite. 1920 / 4 etwa 505. Der Bildschirm ist 2560x1440
            // Dann halt eben 2560 / 4 = 660 px :) :)))) Aber ja, es ist ein viertel weil ich den Casnvas auch so fülle wenn das Bild sonst zu breit wär, also mit je 25% an den Seiten frei damit man scrollen kann. Das kommt aber drauf an ob das Bild so breit ist das der Catch greift.
            // Darum hab ich bei der FilePreview diese Addititve Sache geschrieben weil die das einigermaßen abfägt, zumindest beim Task.
            // Hi Jan‚
            // Eine Alternative fuer Drag and Drop
            // Fuer Waldi im Notfall okay
            // Gebe Tastatur Frei
            // Das die das per Tastatur bewegen können? Oder per Buttonclicks :) :/ > someone there? Jetzt schon. Gerade kam etwas unerwartet eine Stimme aus meinem Smartphone und es waren Isaak und Peter‚
            // Hab vorhin das aus den Tasks hier rein kopiert. Hmm
            // Warum funktioniert das jetz anders als bei dem Task :thinking:
            // Hi Simon oder RObin :) Simon :) lol, hab ne Idee wie mans fixen könnt
            // Probieren wir mal wie sich die Antwortfelder verhalten :)
            // Ok probier du noch kurz weiter, ich muss mal googlen OK
            // was is die Tastenkombi fürs Fesnter wischen?  CMD PLUS Tab
            // Hallo?
            // Das Antwortfeld scheint sich um nen bestimmten Punkt zu ankern, und wenn man auf diesem verschiebt funktioniert das besser als gedacht.
            // Ja. Also ich kann mir Vorstellen, dass man das als Beta verkaufen kann oder aehnlichers > Schreibt hier waldi oder simon? :) Simon, Waldi ist beim Burger King. Vielleicht kann man sagen, Hey wir haben das Feature... Hinweis fuer Safari User, hier mussten wir eine Sonderloesung implementieren und die ist gerade noch etwas problematisch / die hat noch ihre Eigenheiten :P Ich habs. Wir Streamen den Inhalt des Editors von einem Windows Rechner aus. Oder wir besorgen denen allen Windows Tablets. Ich meine Geforce Now schafft das doch auch ;) EASY
            // Das macht mich traurig
            // Ist da noch jmd? Wenn nich würd ich so langsam schlafen gehen :) Ja. Ich bin gerade an den Rechner ggegangen. Was gibt es denn? :) Nee, weil mir fallen grade keine Lösungen mehr ein, darum je nachdem was ihr jetz macht würd ich vllt schlafen gehen. Packs mal auf die Accessiblity Branch und wir testen es dann auf den iPads. Du kannst dann Schlafen gehen, also jetzt schon :)
        },
        // Ok nice, dann euch noch viel Glück und  zum Morgen :) Vielen Dank, dass du durchgehlaten hast (von Waldi), Den dank geb ich gern auch zurück :) Danke euch :)
    },
};
</script>

<style scoped lang="scss">
.userExerciseAnswerButton {
    float: right;
}

.icon {
    width: 30px;
    height: 30px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

#answerFieldOuter {
    -webkit-transform-origin: top left !important; // vllt hilft das ja sogar, sonst hab ich das mal für morgen reingeschrieben :D thx. bb.
    background-color: #00000000;
    display: block;
    overflow: hidden !important;
    resize: both !important;

    min-width: 200px;
    min-height: 50px;
}

.answerField {
    font-size: large;
    display: inline-flex;
    border-radius: 8px;

    p {
        max-width: 400px;
        overflow-wrap: break-word;
    }
}

#pupilAnswerTextArea {
    width: 100% !important;
    height: 100% !important;
    resize: none;
    outline: none;

}

textarea.v-input {
    min-height: 10px !important;
}
</style>

<style lang="scss">

div.v-input, div.v-input div {
    height: 90%;
    /*min-height: 10px !important;*/
}
</style>
