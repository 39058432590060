import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';


const defaultState = {
};

const mutations = {
};

const actions = {

  async getTeacherFolder ({ commit, dispatch }, data) {

    try {
      const res = await backend.getTeacherFolderUploads();
      await checkResponseStatus(200, res);
      const teacherFiles = await res.json();
      return teacherFiles;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async deleteTeacherFile ({ commit, dispatch }, data) {

    try {
      const res = await backend.deleteTeacherFolderUpload(data);
      await checkResponseStatus(204, res);
    }
    catch(err) {
      return err.response.status;
    }
  },



};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
