<template>
    <BaseMenu
            :items="items"
            :show-list="showList"
            @close="$emit('close')"
            @itemClicked="onItemSelected"
    >
        <!-- Add custom data here -->
        <div>
            Custom data
        </div>
    </BaseMenu>
</template>

<script>
import BaseMenu from "@/components/Editortools/Toolbar/Menus/BaseMenu";
import leeresBlatt from "@/assets/Icons/leeres-blatt-110.svg";
import kariertesBlatt from "@/assets/Icons/kariertes blatt-112.svg";
import liniertesBlatt from "@/assets/Icons/liniertes-blatt-111.svg";
import hausBlatt from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_leeres_Blatt_hauslinien.svg';

export default {
    name: 'AddPageMenu',
    components: { BaseMenu },
    data() {
        return {
            showList: true,
            items: [
                { icon: leeresBlatt, text: 'Leer' },
                { icon: liniertesBlatt, text: 'Liniert' },
                { icon: kariertesBlatt, text: 'Kariert' },
                { icon: hausBlatt, text: 'Haus' },
            ],

            // Icons
            liniertesBlatt,
        }
    },
    methods: {
        onItemSelected(item){
            this.$emit('addAdditionalPage', item.text.toLowerCase());
        }
    }
}
</script>

<style scoped>

</style>
