<template>
    <div ref="lineItem">
        <v-row no-gutters align="center" justify="center">
            <v-col :style="`margin-right: ${account.role === 'teacher' ? '30px' : '55px'}`">
                <div v-if="account.role === 'teacher'">
                    <img :src="moveIcon" ref="moveIcon" style="cursor: grab" height="25" width="25"/>
                </div>
            </v-col>
            <v-col :style="`
                        transform: rotate(${line.rotation}rad);
                        -moz-transform: rotate(${line.rotation}rad);
                        -webkit-transform: rotate(${line.rotation}rad);
                        -o-transform: rotate(${line.rotation}rad);
                    `">
                <v-row justify="center" dense>
                    <v-col cols="auto">
                        <v-col cols="auto">
                            <div ref="rotateIcon" v-show="account.role === 'teacher'">
                                <v-icon style="cursor: grab" height="25" width="25">fas fa-sync-alt</v-icon>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
                <v-row dense :style="`margin-top: ${account.role === 'teacher' ? '0px' : '26px'}`">
                    <v-col>
                        <div
                            class="mr-2"
                            ref="box"
                            :style="`
                                width: ${line.width}px;
                                height: 15px;
                                resize: ${account.role === 'teacher' ? 'horizontal !important' : 'none'};
                                overflow: hidden;
                            `"
                        >
                            <div
                                :class="{ 'moveableItemShadow': account.role === 'teacher' }"
                                :style="`
                                    background-color: ${line.text};
                                    width: 100%;
                                    height: 1px;
                                    overflow: hidden;
                                `"
                            ></div>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="auto" v-if="account.role === 'teacher'" style="padding: 1px 2px 1px 9px" class="mt-2">
                <img :src="papierkorbIcon" @click="$emit('deleteObject')" style="cursor: pointer" ref="deleteIcon"
                     height="25" width="25"/>
            </v-col>
        </v-row>
    </div>
</template>

<script>

    import moveIcon from '../../assets/Icons/Temp/move.svg';
    import papierkorbIcon from "../../assets/Icons/papierkorb-109.svg";
    import {mapState} from "vuex";
    import lautsprecherIcon from "@/assets/Icons/lautsprecher-13.svg";
    import lautsprecherAusIcon from "@/assets/Icons/lautsprecher-aus-89.svg";

    export default {
    name: "EditorLine",
    computed: {
        ...mapState("auth", ["account"]),
    },
    props: {
        line: {type: Object, required: true},
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
    },
    data: () => ({
        moveIcon,
        papierkorbIcon,
        lautsprecherIcon,
        lautsprecherAusIcon,

        speakerIcon: lautsprecherIcon
    }),
    mounted() {
        if (this.account.role === 'teacher') {
            this.dragElement(this.$refs.moveIcon);
            this.rotateElement(this.$refs.rotateIcon);
            this.handleResize(this.$refs.box);
        }
    },
    methods: {
        resizeEnd(event) {
            if (event.dataTransfer) {
                this.dragEnd(event);
            }

            const width = this.$refs.box.style.width.substring(
                0,
                this.$refs.box.style.width.length - 2
            );
            const height = this.$refs.box.style.height.substring(
                0,
                this.$refs.box.style.height.length - 2
            );
            this.line.width = width;
            this.line.height = height;
            document.onmouseup = null;
            document.ontouchend = null;
            this.$emit('specsChanged');
            //this.$emit("dimensionsChanged", { width, height });
        },
        handleResize(element){
            let _this = this;
            element.onmousedown = resizeStart;
            element.ontouchstart = resizeStart;

            function resizeStart(){
                document.onmouseup = _this.resizeEnd;
                document.ontouchend = _this.resizeEnd;
            }
        },
        rotateElement(element) {
            let _this = this;
            element.onmousedown = rotateMouseDown;
            element.ontouchstart = rotateMouseDown;
            // element.onpointerdown = dragMouseDown;
            // element.onpointermove = elementDrag;
            var rotStartX, rotStartY;
            var startAngle;
            var imgStartAngle;

            function rotateMouseDown(e) {
                // if (_this.account.role === 'teacher')
                //     _this.$emit('focusMe');
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                var x = e.clientX ? e.clientX : e.touches[0].clientX;
                var y = e.clientY ? e.clientY : e.touches[0].clientY;
                rotStartX = x;
                rotStartY = y + (_this.$refs.lineItem.offsetHeight / 2);
                startAngle = Math.atan2( rotStartY, rotStartX );
                imgStartAngle = isNaN(_this.line.rotation) ? 0 : _this.line.rotation;
                // lastMove = e;
                document.onmouseup = closeRotateElement;
                document.ontouchend = closeRotateElement;
                // document.onpointerup = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementRotate;
                document.ontouchmove = elementRotate;
                // document.onpointermove = elementDrag;
            }

            const elementRotate = (e) => {
                e = e || window.event;
                if (e.type !== 'touchmove' && e.type !== 'pointermove') {
                    e.preventDefault();
                }

                var x = e.clientX ? e.clientX : e.touches[0].clientX;
                var y = e.clientY ? e.clientY : e.touches[0].clientY;
                var mouseXFromCentre = x - rotStartX;
                var mouseYFromCentre = y - rotStartY;
                var mouseAngle = Math.atan2( mouseYFromCentre, mouseXFromCentre ) + (Math.PI / 2);
                // Calculate the new rotation angle for the image
                _this.line.rotation = mouseAngle - startAngle + imgStartAngle;
            }

            const closeRotateElement = (e) => {
                // stop moving when mouse button is released:
                document.onmouseup = null;
                document.onmousemove = null;
                document.ontouchend = null;
                document.onpointerup = null;
                document.ontouchmove = null;
                document.onpointermove = null;
                this.$emit('specsChanged');
            }
        },
        dragElement(element) {
            let _this = this;
            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            let lastMove = null;

            element.onmousedown = dragMouseDown;
            element.ontouchstart = dragMouseDown;
            // element.onpointerdown = dragMouseDown;
            // element.onpointermove = elementDrag;

            function dragMouseDown(e) {
                // if (_this.account.role === 'teacher')
                //     _this.$emit('focusMe');
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                lastMove = e;
                document.onmouseup = closeDragElement;
                document.ontouchend = closeDragElement;
                // document.onpointerup = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
                document.ontouchmove = elementDrag;
                // document.onpointermove = elementDrag;
            }

            const elementDrag = (e) => {
                e = e || window.event;
                lastMove = e;
                if (e.type !== 'touchmove' && e.type !== 'pointermove') {
                    e.preventDefault();
                }

                // calculate the new cursor position:
                pos1 = pos3 - (e.clientX ? e.clientX : e.touches[0].clientX);
                pos2 = pos4 - (e.clientY ? e.clientY : e.touches[0].clientY);
                pos3 = (e.clientX ? e.clientX : e.touches[0].clientX);
                pos4 = (e.clientY ? e.clientY : e.touches[0].clientY);
                // set the element's new position:
                const top = (Number.parseFloat(this.line.y)) - ((pos2 / this.canvasHeight) * 100);
                const left = (Number.parseFloat(this.line.x)) - ((pos1 / this.canvasWidth) * 100);

                if ((left > -10 && left < 110) && (top > -10 && top < 110)) {
                    this.line.x = left;
                    this.line.y = top;
                }
            }

            const closeDragElement = (e) => {
                // stop moving when mouse button is released:
                document.onmouseup = null;
                document.onmousemove = null;
                document.ontouchend = null;
                document.onpointerup = null;
                document.ontouchmove = null;
                document.onpointermove = null;
                try {
                    this.click(e.clientX ? e.clientX : lastMove.touches[0].clientX, e.clientY ? e.clientY : lastMove.touches[0].clientY);
                } catch (e) {
                    // console.log(e);
                }
                lastMove = null;
                this.$emit('specsChanged');
            }
        },
    }
}
</script>

<style lang="scss">

</style>
