<template>
  <div>
    <div>Git hash: {{ gitHash }}</div>
    <div>Build time: {{ buildTime }}</div>
  </div>
</template>

<script>
export default {
  name: "Version",
  components: {},
  mounted() {},
  data: () => ({
    gitHash: process.env.VUE_APP_GIT_HASH,
    buildTime: process.env.VUE_APP_BUILD_TIME,
  }),
  props: {},
  computed: {},
  methods: {},
};
</script>