import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';


const defaultState = {
  // groupsVerwaltung: [],
  groupsInvalid: false,
};

const mutations = {
  SET_GROUPS: (state, groups) => {
    state.groupsVerwaltung = groups;
  },

  ADD_GROUP: (state, group) => {
    state.groupsVerwaltung.push(group);
  },

  REMOVE_GROUP: (state, index) => {
  },
  setGroupsInvalid: (state, status) => {
    state.groupsInvalid = status;
  },
};

const getters = {

  getGroupsVerwaltungState: state => state.groupsVerwaltung,
  getGroupStatus: state => state.groupsInvalid,
};


const actions = {
  async createGroup ({ commit, dispatch }, params) {

    try {
      const res = await backend.postGroup(params);
      await checkResponseStatus(201, res);
      console.log("createGroup", res);
      const group = await res.json();
      return group;
    }
    catch(err) {
      return err.response.status;
    }

  },

  async editGroup ({ commit, dispatch }, params) {

    try {
      const id = params._id;
      delete params._id;
      const res = await backend.patchGroup(id, params);
      if(res.status === 409) {
        return 409;
      }
      const account = await res.json();
      return account;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async deleteGroup ({ commit, dispatch }, id) {
    try {
      const res = await backend.deleteGroup(id);
      if(res.status === 409) {
        return 409;
      }
      await checkResponseStatus(204, res);
      return true;
    }
    catch(err) {
      console.log(err);
      return false;
    }
  },

  async  getGroupsVerwaltung ({commit, dispatch }) {
    try {
      const res = await backend.getGroups();
      await checkResponseStatus(200, res);
      const groups = await res.json();
      // dispatch('setGroupsVerwaltung', groups);
    }
    catch(err) {
      return err.response.status;
    }
  },
  // setGroupsVerwaltung: async ({commit}, groups) => {
  //   commit('SET_GROUPS', groups);
  // },
  addGroupVerwaltung: async({commit}, group) => {
    commit('ADD_GROUP', group);
  },
  removeGroupVerwaltung: async({commit}, index) => {
    commit('REMOVE_GROUP', index);
  },
  setGroupInvalidStatus: async({ commit }, status) => {
    commit('setGroupsInvalid', status);
  },

  async getGroups ({ commit, dispatch }) {

    try {
      const res = await backend.getGroups();
      await checkResponseStatus(200, res);
      const groups = await res.json();
      return groups;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async getGroupsInfo ({commit, dispatch }) {
    try {
      const res = await backend.getGroupsInfo();
      await checkResponseStatus(200, res);
      const groupsInfo = await res.json();
      return groupsInfo;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async getGroup ({ commit, dispatch }, id) {

    try {
      const res = await backend.getGroup(id);
      await checkResponseStatus(200, res);
      const groups = await res.json();
      return groups;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async getGroupByAppointment ({ commit, dispatch }, id) {

    try {
      const res = await backend.getGroupByAppointment(id);
      await checkResponseStatus(200, res);
      const groups = await res.json();
      return groups;
    }
    catch(err) {
      return err.response.status;
    }
  },

};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
