import award from '../assets/Editing/Stempel/award.svg'
import chatOne from '../assets/Editing/Stempel/chat (1).svg'
import chatTwo from '../assets/Editing/Stempel/chat (2).svg'
import frown from '../assets/Editing/Stempel/frown.svg'
import heart from '../assets/Editing/Stempel/heart.svg'
import idea from '../assets/Editing/Stempel/idea.svg'
import meh from '../assets/Editing/Stempel/meh.svg'
import rollingEyes from '../assets/Editing/Stempel/rolling-eyes.svg'
import winkEmoji from '../assets/Editing/Stempel/smile-emoticon.svg'
import smilingFace from '../assets/Editing/Stempel/smiling-face.svg'
import star from '../assets/Editing/Stempel/star.svg'

export default [
    award,
    chatOne,
    chatTwo,
    star,
    idea,
    heart,
    smilingFace,
    winkEmoji,
    rollingEyes,
    meh,
    frown
];
