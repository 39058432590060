<template>
    <div style="padding-right: 3px; padding-left: -3px">
        <!--fach with text and image-->
        <div v-if="thisAppointment" class="fach pa-2 white--text"
             style="width: 90%; display: block; padding-left: 0 !important;">
            <v-row class="ml-2 font-weight-medium" cols="12" justify="center">
                <v-col
                    cols="12"
                    class="pa-0 ma-0"
                >
                    <div :class="{ inactiveAppointment: !isRunning }"
                         :style="isRunning ? 'width: calc(100% - 3px) !important' : ''"
                         class="pa-3 ma-0 appointmentItem"
                    >
                      <img v-if="getSrc()"
                           :src="isRunning ? getSrcWhite() : getSrc()"
                           :style="isRunning ? getBackgroundColor() : 'background-color: transparent'"
                           class="iconSelect"/>

                      <div v-else class="iconSelect"></div>
                      {{ thisAppointment.schoolSubject.name }}
                      <!--bookmark-->
                      <img :src="getColorIcon()" style="position: absolute; height: 25px; top: 0; right: 10px;"/>
                    </div>
                </v-col>
            </v-row>
        </div>

        <!--fach with no text and image-->
        <div v-else class="fach pa-2 white--text"
             style="width: 90%; height: 70.5px !important; display: block; padding-left: 0 !important">
            <v-row class="ml-2 font-weight-medium">
                <v-col class="pa-0 ma-0" cols="12" style="height: 48px !important;">
                    <div class="pa-3 ma-0 appointmentItem inactiveAppointment">
                        <img class="iconSelectFrei"/>
                        Hohlstunde
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import schliesenIcon from '../../assets/Icons/abbrechen-08.svg'
import buchzeichenIcon from '../../assets/Icons/lesezeichen-01-43.svg'
import lautsprecherIcon from '../../assets/Icons/lautsprecher-13.svg'
import lehrerIcon from '../../assets/Icons/lehrer-24.svg'
import rechtsIcon from '../../assets/Icons/rechts-11.svg'
import infoIcon from "../../assets/Icons/info-45.svg";

import bioIcon from '../../assets/Icons/f-biologie-35.svg'
import chemieIcon from '../../assets/Icons/f-chemie-37.svg'
import deutschIcon from '../../assets/Icons/f-deutsch-29.svg'
import englischIcon from '../../assets/Icons/f-englisch-30.svg'
import erdkundeIcon from '../../assets/Icons/f-erdkunde-31.svg'
import geschichteIcon from '../../assets/Icons/f-geschichte-40.svg'
import matheIcon from '../../assets/Icons/f-mathe-32.svg'
import musikIcon from '../../assets/Icons/f-musik-84.svg'
import physikIcon from '../../assets/Icons/f-physik-36.svg'
import politikIcon from '../../assets/Icons/f-politik-34.svg'
import religionIcon from '../../assets/Icons/f-religion-39.svg'
import sportIcon from '../../assets/Icons/f-sport-38.svg'
import wirtschaftIcon from '../../assets/Icons/f-wirtschaft-33.svg'

import bioIconWeiss from "@/assets/Icons/f-biologie-weiss-35.svg";
import chemieIconWeiss from "@/assets/Icons/f-chemie-weiss-37.svg";
import deutschIconWeiss from "@/assets/Icons/f-deutsch-weiss-29.svg";
import englischIconWeiss from "@/assets/Icons/f-englisch-weiss-30.svg";
import erdkundeIconWeiss from "@/assets/Icons/f-erdkunde-weiss-31.svg";
import geschichteIconWeiss from "@/assets/Icons/f-geschichte-weiss-40.svg";
import matheIconWeiss from "@/assets/Icons/f-mathe-weiss-32.svg";
import musikIconWeiss from "@/assets/Icons/f-musik-weiss-82.svg";
import physikIconWeiss from "@/assets/Icons/f-physik-weiss-36.svg";
import politikIconWeiss from "@/assets/Icons/f-politik-weiss-34.svg";
import religionIconWeiss from "@/assets/Icons/f-religion-weiss-39.svg";
import sportIconWeiss from "@/assets/Icons/f-sport-weiss-38.svg";
import wirtschaftIconWeiss from "@/assets/Icons/f-wirtschaft-weiss-33.svg";

import colorfcd20a from '../../assets/Icons/lesezeichen-gelb-47.svg'
import colorf0b423 from '../../assets/Icons/lesezeichen-orange-48.svg'
import colorff6941 from '../../assets/Icons/lesezeichen-rot-49.svg'
import color91c364 from '../../assets/Icons/lesezeichen-hellgrün-50.svg'
import color91c887 from '../../assets/Icons/lesezeichen-mintgrün-51.svg'
import colord7875f from '../../assets/Icons/lesezeichen-braun-52.svg'
import colorebaabe from '../../assets/Icons/lesezeichen-rosa-53.svg'
import color9b91c8 from '../../assets/Icons/lesezeichen-lila-54.svg'
import color6ec3dc from '../../assets/Icons/lesezeichen-blau-55.svg'
import color9bcdc3 from '../../assets/Icons/lesezeichen-minze-56.svg'
import colorb2b2b2 from '../../assets/Icons/lesezeichen-hellgrau-43.svg'
import color707070 from '../../assets/Icons/lesezeichen-dunkelgrau-44.svg'

export default {
    name: "FachEltern",
    components: {
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            menu: false,
            thisAppointment: null,
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false
            },
            showTextTimeout: null,

            schliesenIcon,
            buchzeichenIcon,
            lautsprecherIcon,
            lehrerIcon,
            rechtsIcon,
            infoIcon,

            bioIcon,
            chemieIcon,
            deutschIcon,
            englischIcon,
            erdkundeIcon,
            geschichteIcon,
            matheIcon,
            musikIcon,
            physikIcon,
            politikIcon,
            religionIcon,
            sportIcon,
            wirtschaftIcon,

            bioIconWeiss,
            chemieIconWeiss,
            deutschIconWeiss,
            englischIconWeiss,
            erdkundeIconWeiss,
            geschichteIconWeiss,
            matheIconWeiss,
            musikIconWeiss,
            physikIconWeiss,
            politikIconWeiss,
            religionIconWeiss,
            sportIconWeiss,
            wirtschaftIconWeiss,

            colorfcd20a,
            colorf0b423,
            colorff6941,
            color91c364,
            color91c887,
            colord7875f,
            colorebaabe,
            color9b91c8,
            color6ec3dc,
            color9bcdc3,
            colorb2b2b2,
            color707070,
        };
    },
    props: {
        appointment: {required: false, default: null},
        requestAppointments: {required: true},
        isRunning: {type: Boolean, required: false, default: false},
        isOpen: {type: Boolean, required: false, default: false}
    },
    computed: {
        ...mapState("auth", ["token"]),
        ...mapGetters('util', ['currentlyOpenAppointment']),

        getTimeString() {
            let startObj = this.thisAppointment.timeslot;
            return startObj;
        },
    },
    mounted() {
        this.thisAppointment = this.appointment;
        this.reloadDontLogout(false);
    },
    watch: {
        appointment() {
            this.thisAppointment = this.appointment;
        },
        isOpen() {
            if (!this.menu) {
                this.menu = this.isOpen;
                this.$router.replace({
                    query: {
                        appointment: undefined,
                    }
                })
            }
        },
    },
    methods: {
        ...mapActions("auth", ["reloadDontLogout"]),
        ...mapActions("util", ['toggleLoading', 'toggleOpenAppointment', 'toggleCurrentUploadGroup']),
        ...mapActions('account', ['getCurrentAccount']),

        getSrc() {
            return this.thisSubject ? this[this.thisSubject.icon] : (this.thisAppointment ? this[this.thisAppointment.schoolSubject.icon] : null);
        },

        getSrcWhite() {
            /**
             * Cant be done like the top one because the second part of string doesnt mathc with the normal icon, so a search is probably needed
             */
            let temp = this.thisSubject
                ? this[this.thisSubject.icon]
                : this.thisAppointment
                    ? this[this.thisAppointment.schoolSubject.icon]
                    : null;
            let searchString = temp.slice(0, temp.length - 16) + "-weiss";
            let returnString = '';

            for (let i = 0; i < Object.keys(this.$data).length; i++) {
                let key = Object.keys(this.$data)[i];
                if (this.$data[key] && this.$data[key].includes && this.$data[key].includes(searchString)) {
                    returnString = this.$data[key];
                    break;
                }
            }

            return returnString.length > 0 ? returnString : temp;
        },

        getColorIcon() {
            return this.thisSubject ? this['color' + this.thisSubject.color.substring(1)] : (this.thisAppointment ? this['color' + this.thisAppointment.schoolSubject.color.substring(1)] : this.colorb2b2b2)
        },

        /*background color of icon when slot is active*/
        getBackgroundColor() {
            let returnString = "background-color: ";
            return returnString + this.thisAppointment.schoolSubject.color;
        },

        getTimeslotTimes() {
            const timeslot = this.$parent.$parent.slots.find((item) => item._id === this.thisAppointment.timeslot);
            if (timeslot) {
                return (
                    ("0" + new Date(timeslot.start).getHours()).slice(-2) + ":" + ("0" + new Date(timeslot.start).getMinutes()).slice(-2)
                    + ' - ' +
                    ("0" + new Date(timeslot.end).getHours()).slice(-2) + ":" + ("0" + new Date(timeslot.end).getMinutes()).slice(-2));
            }
        },
    }
};
</script>

<style lang="scss" scoped>
table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 6em !important;
}

table tr th,
table tr td {
    width: 9em;
}

table,
tr,
td {
    border: none;
}

.appointmentItem {
    position: relative;
    color: #707070;
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #FFF;
    width: 100%;
    height: 100%;
}

.inactiveAppointment {
    opacity: 0.4 !important;
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: 100px;
}

.scroll-area2 {
    position: relative;
    margin: auto;
    max-height: 100px;
}

.scroll-area3 {
    position: relative;
    margin: auto;
    max-height: 50px;
}

.scroll-area-fachPopup {
    position: relative;
    margin: auto;
    max-height: calc(65vh - 30px);
}

.buttonIcon {
    align-self: start;
    background-color: #eaeaeacc;
    padding: 2px;
    border-radius: 3px;
    margin-right: 1em;
}

//border-radius of v-menu
.v-menu__content {
    border-radius: 12px;
}

.iconSelect {
    display: inline-flex;
    width: 24px;
    height: 24px;
    padding: 2px;
    margin-bottom: -6px;
    border-radius: 5px;
    margin-right: 2px;
    background-color: rgba(234, 234, 234, 0.7);
}

.iconSelectFrei {
    display: inline-flex;
    width: 24px;
    padding: 2px;
    margin-bottom: -6px;
    border-radius: 5px;
    margin-right: 2px;
    background-color: transparent;
}

.smallFach {
    position: relative;
    color: #707070;
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    white-space: nowrap;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #ffffff;
}

.isFachRunning {
    color: white;
    background-color: #8cbd46 !important;
}

.isFachRunningIcon {
    filter: brightness(1000%);
}

.eHr {
    width: 90%;
    margin: auto;
    border-width: 0;
    height: 2px;
    color: #eaeaea;
    background-color: #eaeaea;
}

.eButton {
    width: 33px !important;
    min-width: 33px !important;
    height: 33px !important;
    min-height: 33px !important;
    border-radius: 4px !important;
    box-shadow: 0 5px 10px #00000025 !important;
}

.btnStyle {
    min-width: 36px;
    min-height: 36px;
    margin-top: -3px;
}

.fileName {
    max-width: 85%;
    min-width: 85%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
}

.matLong, .uploadsLong {
    display: none;
}

.brighterFileRow {
    background-color: rgba(224, 224, 224, 0.15) !important;
}

.correctionAvailableClass {
    background-color: rgba(140, 190, 70, 0.30) !important;
}

@media only screen and (max-width: 399px) {
    #fachPopup {
        max-width: 100vw !important;
    }

    #dialog {
        margin: 0;
    }
}

@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    .scroll-area-fachPopup {
        /*250px = 59px header, 48px join btn, 70px btmNavBar and paddings,margins*/
        max-height: calc(100vh - 250px);
    }
}

#emptyPageMenu {
    display: block;
    background-color: #F8F8F8;

    & button {
        width: 100%;
    }
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}
</style>
