const defaultState = {
  copyMode: false,
  copyOfSubject: null,
  copyOfTeachers: null,

  copySourceId: null,
  
  isCopy: null,
  copiedAppointment: null,
  copiedSubject: null,
  copiedTeachers: null,
  copiedIsWeekly: null,
  copiedWeeklyTill: null,
};

//store does mutations that are called via actions
const mutations = {
  setCopyMode(state, value) {
    state.copyMode = value;
  },
  setCopySourceId(state, value) {
    state.copySourceId = value;
  },
  setCopyOfSubject(state, value) {
    state.copyOfSubject = value;
  },
  setCopyOfTeachers(state, value) {
    state.copyOfTeachers = value;
  },
  
  setStatesOfAppointment(state, obj) {
    state.isCopy = obj.isCopy;
    state.copiedAppointment = obj.copiedAppointment;
    state.copiedSubject = obj.copiedSubject;
    state.copiedTeachers = obj.copiedTeachers;
    state.copiedIsWeekly = obj.copiedIsWeekly;
    state.copiedWeeklyFrom = obj.copiedWeeklyFrom;
    state.copiedWeeklyTill = obj.copiedWeeklyTill;
  
    // console.log(state.isCopy, state.copiedAppointment, state.copiedSubject, state.copiedTeachers, state.copiedIsWeekly, state.copiedWeeklyTill);
  }
};

//getters so that Vue components can read status of a store state
const getters = {
  isCopyModeActive: (state) => state.copyMode,
  getCopyOfSubject: (state) => state.copyOfSubject,
  getCopyOfTeachers: (state) => state.copyOfTeachers,
};

//actions are called from Vue components
const actions = {
  toggleCopyMode({ commit, state }) {
    commit("setCopyMode", !state.copyMode);
    // console.log('STORE');
  },
  turnOffCopyMode({ commit, state }) {
    commit("setCopyMode", false);
  },
  getCopiedFachData({commit, state}) {
    let obj = {
      isCopy: state.isCopy,
      copiedAppointment: state.copiedAppointment,
      copiedSubject: state.copiedSubject,
      copiedTeachers: state.copiedTeachers,
      copiedIsWeekly: state.copiedIsWeekly,
      copiedWeeklyTill: state.copiedWeeklyTill,
    };
    return obj;
  },
  setCopyModeOriginFachId({commit, state}, id) {
    commit("setCopySourceId", id);
  },
  setAppointmentData({commit, state}, obj) {
    // console.log('apptnsdf data:', obj);
    commit('setStatesOfAppointment', obj);
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
