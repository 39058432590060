<template>
    <div style="z-index: 7" :id="`editorAudioMessageContainer${index}`" ref="editorAudioMessageContainer">
        <v-menu
                role="dialog"
                v-model="isOpen"
                absolute
                offset-y
                max-width="600px"
                :close-on-content-click="false"
                :close-on-click="true"
                :attach="`#editorAudioMessageContainer${index}`"
        >
            <template v-slot:activator="{ on, attrs }">
                <div style="transform: translate(-50%, -50%);">
                    <div class="heartbeat" v-bind="attrs" v-on="on" :tabindex="tabIndex" @keypress.enter="isOpen = !isOpen">
                        <v-icon style="font-size: 50px !important; color: #e6231e;">fas fa-circle</v-icon>
                        <img :src="lautsprecherIcon" style="height: 40px; position: absolute; left: 5px; top: 5px" alt="Sprachnotiz anhören"/>
                    </div>
                </div>
            </template>

            <v-card max-width="300px" width="220px" id="card" class="pa-1" >
                <v-card-title class="pb-0">
                    <v-container class="pa-0">
                        <v-row>
                            <v-col>
                                <p :tabindex="tabIndex">Audio</p>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn @click="isOpen = false" elevation="2" x-small class="ml-2 pa-0" :tabindex="tabIndex"
                                       style="width: 30px; height: 30px">
                                    <img alt="Dialog schließen" :src="schliesenIcon" style="height: 20px;"/>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-card-text class="pb-0">
                    <v-container class="pa-0">
                        <v-row>
                            <v-col style="place-content: flex-start">

                                <v-row v-if="pulledStartMessage" class="mt-1">
                                    <v-btn elevation="0" class="text-capitalize ml-1 playButton" :color="playingToggles.start ? 'error' : 'gruen'"
                                           @click="playRecordedMessage('startMessageAudio', 'start')"
                                           aria-label="Lehrernotiz abspielen"
                                           :tabindex="tabIndex"
                                    >
                                        <img :src="playIcon" class="iconToWhite" style="height: 20px;"/>
                                        <p aria-hidden="true" class="mb-0 ml-2">Lehrernotiz</p>
                                        <p  class="mb-0 ml-2 visually-hidden">Lehrernotiz abspielen</p>
                                    </v-btn>
                                </v-row>

                                <v-row v-if="pulledAnswerMessage" class="mt-1">
                                    <v-btn elevation="0" class="text-capitalize ml-1 playButton" :color="playingToggles.answer ? 'error' : 'gruen'"
                                           @click="playRecordedMessage('answerMessageAudio', 'answer')"
                                           :tabindex="tabIndex"
                                           aria-label="Dein Audio nochmal anhören"
                                    >
                                        <img :src="playIcon" class="iconToWhite" style="height: 20px;"/>
                                        <p class="mb-0 ml-2">Schülerabgabe</p>
                                    </v-btn>
                                </v-row>

                                <v-row v-if="pulledCorrectionMessage" class="mt-1">
                                    <v-btn elevation="0" class="text-capitalize ml-1 playButton" :color="playingToggles.correction ? 'error' : 'gruen'"
                                           @click="playRecordedMessage('correctionMessageAudio', 'correction')"
                                           :tabindex="tabIndex"
                                    >
                                        <img :src="playIcon" class="iconToWhite" style="height: 20px;"/>
                                        <p class="mb-0 ml-2">Lehrerkorrektur</p>
                                    </v-btn>
                                </v-row>

                                <v-row v-if="audioCheck && state !== 'finished' && state !== 'watch'" class="mt-1">
                                    <v-tooltip top :open-on-hover="false" v-model="recordingTooltip">
                                        <template v-slot:activator="{ attrs }">
                                            <v-btn v-bind="attrs" elevation="0" class="text-capitalize ml-1"
                                                   v-long-press="100"
                                                   @long-press-start="beginRecording"
                                                   @long-press-stop="stopRecording(state)"
                                                   @keydown.enter="beginRecording()"
                                                   @keyup.enter="stopRecording(state)"
                                                   @click="keyboard ? (recordingToggle ? stopRecording(state) : beginRecording()) : null"
                                                   :color="recordingToggle ? 'error' : 'gruen'"
                                                   :tabindex="tabIndex"
                                                   aria-label="Ein Audio als Antwort aufnehmen (gedrückt halten)"
                                            >
<!--                                                <img :src="mikrofonIcon" class="iconToWhite" style="height: 20px;"/>-->
                                                <div role="img" id="micIcon" :class="recordingToggle ? 'iconToRecording' : ''"></div>
                                            </v-btn>
                                        </template>
                                        <span>{{ recordingTime }}</span>
                                    </v-tooltip>

                                    <v-btn elevation="0" class="text-capitalize ml-1"
                                           color="gruen"
                                           v-if="state=== 'start'"
                                           @click="() => {$emit('deleteMessage', index); isOpen = false}"
                                    >
                                        <img :src="papierkorbIcon" class="iconToWhite" style="height: 20px;"/>
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-menu>
        <audio ref="startMessageAudio" :src="pulledStartMessage" controls hidden>
            Your browser does not support the video tag.
        </audio>
        <audio ref="answerMessageAudio" :src="pulledAnswerMessage" controls hidden>
            Your browser does not support the video tag.
        </audio>
        <audio ref="correctionMessageAudio" :src="pulledCorrectionMessage" controls hidden>
            Your browser does not support the video tag.
        </audio>
    </div>
</template>

<script>
    import schliesenIcon from '../../assets/Icons/abbrechen-08.svg';
    import mikrofonIcon from '../../assets/Icons/mikrofon-22.svg'
    import papierkorbIcon from '../../assets/Icons/papierkorb-109.svg'
    import lautsprecherIcon from '../../assets/Icons/lautsprecher-weiss-13.svg'
    import playIcon from '../../assets/Icons/rechts-11.svg'
    import * as backend from "../../api/backend";
    import Recorder from "js-audio-recorder";
    import { mapState, mapActions, mapGetters } from 'vuex';

    export default {
    name: 'EditorAudioMessage',
    props: {
        uploadMessage: { type: Object, required: true },
        index: { type: Number, required: true },
        state: { type: String, required: true },
        tabIndex: { type: Number, required: false, default: 19 },
    },
    components: {
    },
    data() {
        return {
            // Icons
            schliesenIcon,
            mikrofonIcon,
            playIcon,
            papierkorbIcon,
            lautsprecherIcon,
            // Everything else
            isOpen: false,
            audioCheck: false,
            recordedMessage: null,
            recordingToggle: false,
            playingToggles: {
                start: false,
                answer: false,
                correction: false,
            },
            recorder: new Recorder(),
            pulledStartMessage: null,
            pulledAnswerMessage: null,
            pulledCorrectionMessage: null,
            recordingTime: '',
            elapsedTimeIntervalRef: '',
            recordingTooltip: false,
        };
    },
    computed: {
        ...mapState("util", ["keyboard"]),
        ...mapState("auth", ["token", "account"]),
    },
    methods: {
        checkPermission() {
            Recorder.getPermission().then(() => {
                this.audioCheck = true;
            }, (error) => {
                console.log(`${error.name} : ${error.message}`);
            });
        },
        beginRecording() {
            if (!this.recordingToggle) {
                this.startTimer();
                this.recordingTooltip = true;
                this.recordingToggle = true;
                this.recorder.startRecord();
            }
        },
        stopRecording(msgType) {
            this.recordingTooltip = false;
            this.recordingToggle = false;
            this.recorder.stopRecord();
            const file = this.recorder.getWAVBlob();

            if (msgType === 'start') {
                this.uploadMessage.startFile = file;
                this.pulledStartMessage = window.URL.createObjectURL(file);
                // const startId = this.uploadRecordedMessage('wav', file, 'startabgabe');
                // console.log(startId);
            } else if (msgType === 'answer') {
                this.uploadMessage.answerFile = file;
                this.pulledAnswerMessage = window.URL.createObjectURL(file);
            } else if (msgType === 'correction') {
                this.uploadMessage.correctionFile = file;
                this.pulledCorrectionMessage = window.URL.createObjectURL(file);
            }
            setTimeout(() => {
              this.stopTimer();
            }, 500);
        },
        async pullAudioFile(messageId, resultContainer) {
            const res = await backend.getUploadMessage(messageId);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                    })
                })
                .then(response => response.blob())
                .then(blob => {
                    this[resultContainer] = window.URL.createObjectURL(blob);
                    console.log(this.pulledStartMessage)
                });
        },
        playRecordedMessage(audioRef, stylingToggle) {
            if (this.$refs[audioRef].duration > 0 && !this.$refs[audioRef].paused) {
                this.playingToggles[stylingToggle] = false;
                this.$refs[audioRef].pause();
            } else {
                this.playingToggles[stylingToggle] = true;
                this.$refs[audioRef].play();
            }

            this.$refs[audioRef].onended = () => {
                this.playingToggles[stylingToggle] = false;
            }
        },
        close() {

            this.isOpen = false;
        },
      startTimer() {
        var startTime = new Date();
        this.recordingTime = this.getElapsedTime(startTime);
        this.elapsedTimeIntervalRef = setInterval(() => {
          this.recordingTime = this.getElapsedTime(startTime);
        }, 1000);
      },
      stopTimer() {
        if (typeof this.elapsedTimeIntervalRef !== "undefined") {
          clearInterval(this.elapsedTimeIntervalRef);
          this.elapsedTimeIntervalRef = undefined;
        }
        this.recordingTime = '';
      },
      getElapsedTime(startTime) {
        // Record end time
        let endTime = new Date();
        // Compute time difference in milliseconds
        let timeDiff = endTime.getTime() - startTime.getTime();
        // Convert time difference from milliseconds to seconds
        timeDiff = timeDiff / 1000;
        // Extract integer seconds that dont form a minute using %
        let seconds = Math.floor(timeDiff % 60); //ignoring uncomplete seconds (floor)
        // Pad seconds with a zero if neccessary
        let secondsAsString = seconds < 10 ? "0" + seconds : seconds + "";
        // Convert time difference from seconds to minutes using %
        timeDiff = Math.floor(timeDiff / 60);
        // Extract integer minutes that don't form an hour using %
        let minutes = timeDiff % 60; //no need to floor possible incomplete minutes, becase they've been handled as seconds
        // Pad minutes with a zero if neccessary
        let minutesAsString = minutes < 10 ? "0" + minutes : minutes + "";
        // Convert time difference from minutes to hours
        timeDiff = Math.floor(timeDiff / 60);
        // Extract integer hours that don't form a day using %
        let hours = timeDiff % 24; //no need to floor possible incomplete hours, becase they've been handled as seconds
        // Convert time difference from hours to days
        timeDiff = Math.floor(timeDiff / 24);
        // The rest of timeDiff is number of days
        let days = timeDiff;
        let totalHours = hours + (days * 24); // add days to hours
        let totalHoursAsString = totalHours < 10 ? "0" + totalHours : totalHours + "";
        if (totalHoursAsString === "00") {
          return minutesAsString + ":" + secondsAsString;
        } else {
          return totalHoursAsString + ":" + minutesAsString + ":" + secondsAsString;
        }
      }
    },
    watch: {
        isOpen() {
            this.playingToggles.start = false;
            this.$refs.startMessageAudio.pause();
            this.playingToggles.answer = false;
            this.$refs.answerMessageAudio.pause();
            this.playingToggles.correction = false;
            this.$refs.correctionMessageAudio.pause();
        }
    },
    mounted() {
        this.checkPermission();

        // request files for playback
        if (this.uploadMessage.start) {
            this.pullAudioFile(this.uploadMessage.start, 'pulledStartMessage')
        }

        if (this.uploadMessage.answer) {
            this.pullAudioFile(this.uploadMessage.answer, 'pulledAnswerMessage')
        }

        if (this.uploadMessage.correction) {
            this.pullAudioFile(this.uploadMessage.correction, 'pulledCorrectionMessage')
        }
    }
}
</script>

<style lang="scss" scoped>
#messageContainer {

}



/* ----------------------------------------------
* Generated by Animista on 2021-1-19 12:22:3
* Licensed under FreeBSD License.
* See http://animista.net/license for more info.
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

.heartbeat {
    -webkit-animation: heartbeat 2s ease-in-out 2s infinite both;
    animation: heartbeat 2s ease-in-out 2s infinite both;
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

.iconToWhite {
    filter: brightness(1000%);
}

.playButton {
    text-transform: inherit;
    color: white;
}

#micIcon {
    background-image: url('../../assets/Icons/mikrofon-22.svg');
    height: 20px;
    width: 20px;
    filter: brightness(1000%);
}

.iconToRecording {
    /*filter: brightness(1);*/
    /*background-image: url('../assets/Icons/recording-dot.svg') !important;*/
}

</style>
