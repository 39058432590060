import qr_code from '@/assets/Tooltip/qr_code.svg';
import read_text from '@/assets/Tooltip/text_vorlesen.svg';
import homework from '@/assets/Tooltip/deine_hausaufgaben.svg';
import file_widget from '@/assets/Tooltip/file_widget.svg';
import dein_stundenplan from '@/assets/Tooltip/dein_stundenplan.svg';
import unterricht_beitreten from '@/assets/Tooltip/unterricht_beitreten.svg';

const isActive = ({ title }, list) => {
  const found = Object.values(tutorials).find(e => e.title === title);
  if (!found) return false;
  if (list.includes(found.title))
    return false;
  if (!found.prev || list.includes(found.prev().title))
    return true;
  return false;
};

const tutorials = {
  'Einloggen': {
    title: 'Einloggen',
    text: 'Hebe deinen digitalen Schülerausweis vor die Kamera.',
    image: qr_code,
    isActive: () => { return true },
  },
  'Text vorlesen': {
    title: 'Text vorlesen',
    text: 'Drücke und markiere ein Wort und lass es dir von Pero vorlesen.',
    image: read_text,
    next: () => { return tutorials['Deine Hausaufgaben'] },
    isActive: (list) => { return isActive(tutorials['Text vorlesen'], list) },
  },
  'Deine Hausaufgaben': {
    title: 'Deine Hausaufgaben',
    text: 'Hier findest du deine Hausaufgaben.',
    image: homework,
    prev: () => { return tutorials['Text vorlesen'] },
    next: () => { return tutorials['Herunterladen / Hochladen'] },
    isActive: (list) => { return isActive(tutorials['Deine Hausaufgaben'], list) },
  },
  'Herunterladen / Hochladen': {
    title: 'Herunterladen / Hochladen',
    text: 'Du kannst Dateien entweder hoch oder runterladen.',
    image: file_widget,
    prev: () => { return tutorials['Deine Hausaufgaben'] },
    next: () => { return tutorials['Dein Stundenplan'] },
    isActive: (list) => { return isActive(tutorials['Herunterladen / Hochladen'], list) },
  },
  'Dein Stundenplan': {
    title: 'Dein Stundenplan',
    text: 'Dein Stundenplan zeigt dir deine Schulfächer an.',
    image: dein_stundenplan,
    prev: () => { return tutorials['Herunterladen / Hochladen'] },
    next: () => { return tutorials['Unterricht beitreten'] },
    isActive: (list) => { return isActive(tutorials['Dein Stundenplan'], list) },
  },
  'Unterricht beitreten': {
    title: 'Unterricht beitreten',
    text: 'Drücke auf den Videoknopf, um deine Mitschüler und Lehrer zu sehen.',
    image: unterricht_beitreten,
    prev: () => { return tutorials['Dein Stundenplan'] },
    isActive: (list) => { return isActive(tutorials['Unterricht beitreten'], list) },
  },
};

export {
  tutorials,
  isActive,
};
