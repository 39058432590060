import * as backend from "../../api/backend";
import { checkResponseStatus } from "../../util/check";

const defaultState = {
  magnifier: false,
};

const mutations = {
  setMagnifier(state, value) {
    state.magnifier = value;
  }
};


const actions = {
  toggleMagnifier({ commit, state }) {
    console.log('toggleMagnifier');
    commit("setMagnifier", !state.magnifier);
  },

  turnOffMagnifier({ commit, state }) {
    commit("setMagnifier", false);
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
