<template>
    <div>
        <v-row class="mx-0 mt-3 mb-4" >
            <div style="height: auto; width: 90%; margin: auto; border-radius: 15px; box-shadow: 1px 5px 5px silver;">
                <div style=" background-color: #bdbdbd; border-radius: 15px 15px 0 0; font-size: larger; padding-top: 5px; padding-bottom: 20px; text-align: center; color: white;">

                    <div style="display: flex; width: 50%">
                        <img :src="suchIcon" style="height: 24px; margin-left: 24px; margin-top: 20px;
                        filter: brightness(0) saturate(100%) invert(100%) sepia(5%) saturate(19%) hue-rotate(65deg) brightness(104%) contrast(105%);"/>
                        <v-text-field
                            v-model="search"
                            label="Nach Fach suchen"
                            single-line
                            dark
                            hide-details
                            style="width: 50%"
                            class="pl-6"
                        ></v-text-field>
                    </div>
                </div>
                <div style="display: flex; font-size: larger; font-family: Roboto; margin-top: 24px; margin-left: 26px" class="">
                    <img :src="fachIcon" style="height: 24px; margin-right: 10px"/>
                    Fächer
                </div>
                <v-data-table
                    :headers="headers"
                    :items="subjects"
                    :search="search"
                    disable-pagination
                    hide-default-footer
                    fixed-header
                    sort-by="name"
                    :height="height"
                    class="ma-3"
                    locale="de-DE"
                    no-data-text="Keine Daten verfügbar"
                    no-results-text="Keine übereinstimmenden Aufzeichnungen gefunden"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td>{{row.item.name}}</td>
                            <td :style="'color:' +row.item.color "><v-btn :style="'background-color:' +row.item.color" style="border-radius: 8px; max-width: 42px!important; min-width: 36px!important;" light></v-btn></td>
                            <td>
                                <v-btn class="mx-2" @click="edit(row.item)" color="#EAEAEA" small style="text-transform: inherit; border-radius: 5px" elevation="1" light>
                                    Fach bearbeiten
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <v-overlay absolute :value="showLoadingOverlay"
                           style="width: 100% !important;">
                    <v-progress-circular v-if="showLoadingOverlay" indeterminate size="100"/>
                </v-overlay>
            </div>

        </v-row>


        <!-- Fach anlegene / bearbeiten -->
        <v-row class="mx-0 my-3" id="edit" >
            <div class="editContainer" style="">
<!--                <div style="background-color: #6995CD; border-radius: 15px 15px 0 0; font-size: larger; padding-top: 10px; padding-bottom: 5px; ">-->

<!--                    <div style="color: white; font-size: larger" class="ma-2 ml-6 ">-->
<!--                        <img class="ml-2" style="width: 24px; color: white" :src="bearbeitenIcon"/> Fach {{ oldSubject ? 'bearbeiten' : 'anlegen'}}-->
<!--                    </div>-->
<!--                </div>-->
                <v-row class="mx-0" style="background-color: #bdbdbd; border-radius: 15px 15px 0 0">

                    <v-col style="color: white; font-size: larger;" class="ma-1 ml-5">
                        <v-avatar style="width: 24px; " color="rgba(255,255,255,0.8)">
                            <img :src="fachIcon"  class=""/>
                        </v-avatar>
                        Fach {{ oldSubject ? 'bearbeiten' : 'anlegen'}}
                    </v-col>
                    <v-col class="mr-6" style="display: flex; justify-content: flex-end;">
                        <v-btn color="#FFFFFF40" x-small class="mainFunctionButtons ml-3 my-auto"
                               :disabled="!oldSubject" @click="deleteDialog = true">
                            <img :src="loeschIcon">
                        </v-btn>
                        <v-tooltip top nudge-right="100" :open-on-click="true" :open-on-hover="false"
                                   content-class="infoTextToolTip">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="#FFFFFF40" x-small class="mainFunctionButtons ml-3 my-auto"
                                       v-bind="attrs" v-on="on">
                                    <img :src="infoIcon">
                                </v-btn>
                            </template>
                            <span>Hier können Sie Fächer für Ihre Schule anlegen. Wählen Sie eine Farbe, einen Leiter und
                                        ein Icon. Sie können die Farben und Icons auch mehrfach benutzen.</span>
                        </v-tooltip>
                        <v-btn class="mainControlButtons ml-3 my-auto pa-5" color="#BDBDBD50" @click="stopEdit" dark elevation="0">
                            <span>Abbrechen</span>
                        </v-btn>
                        <v-btn class="mainControlButtons ml-3 my-auto pa-5" color="#69CD70" v-if="oldSubject" @click="editThisSubject" dark elevation="4">
                            <span>Speichern</span>
                        </v-btn>
                        <v-btn class="mainControlButtons ml-3 my-auto pa-5" color="#69CD70" v-else @click="saveSubject" dark elevation="4">
                            <span>Speichern</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <div class="mx-2 mb-2">

                    <v-row>
                        <div style="min-width: 25px!important;"></div>
                        <v-col>
                            <div style="font-weight: bold; margin-bottom: 10px">Fachdaten</div>
                            <v-row>
                                <v-col cols="2">
                                    Name:
                                </v-col>
                                <v-col cols="7">
                                    <input v-model="name" type="text" class="input" :class="{ errorInput: fields.name }" @change="validateInput">
                                </v-col>
                            </v-row>

                            <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-row>
                                        <v-col cols="2">
                                            <v-row>
                                                <v-col>
                                                    Lehrer:
                                                </v-col>

                                            </v-row>
                                            <v-row>
                                                <v-col class="pl-0">
                                                    <v-btn style="margin-top: -18px; max-height: 25px; color: black" dark small color="#EAEAEA" v-bind="attrs" v-on="on"
                                                           @click="() => { oldMembers = JSON.parse(JSON.stringify(members)) }">
                                                        Auswählen
                                                    </v-btn>
                                                </v-col>
                                            </v-row>


<!--                                            <v-row>-->
<!--                                                <v-col>-->
<!--                                                    <v-btn class="" style="margin-top: -15px; max-width: 100%; width: 100%; background-color: rgba(230,34,30,0.41); border-radius: 8px" v-if="oldSubject" @click="deleteDialog = true" small light>-->
<!--                                                        Löschen-->
<!--                                                    </v-btn>-->
<!--                                                </v-col>-->
<!--                                            </v-row>-->

                                        </v-col>
                                        <v-col cols="7">
                                            <v-col cols="2">

                                                <v-row style=" " v-for="(item, index) in members.filter((el) => el.selected)" :key="index">
                                                    <div class="px-3 py-1 mb-1" style="min-width: 200px; background-color: #EAEAEA; border-radius: 8px">{{item.name + ' ' + item.lastName}}</div>
                                                </v-row>
<!--                                                <v-row>-->
<!--                                                    <v-col class="pl-0">-->
<!--                                                        <v-btn style="margin-top: -15px; max-height: 25px; color: black; border-radius: 8px; text-transform: inherit" dark color="#EAEAEA" v-bind="attrs" v-on="on"-->
<!--                                                               @click="() => { oldMembers = JSON.parse(JSON.stringify(members)) }">-->
<!--                                                            Bearbeiten-->
<!--                                                        </v-btn>-->
<!--                                                    </v-col>-->
<!--                                                </v-row>-->
                                            </v-col>
                                        </v-col>
                                    </v-row>
                                </template>
                                    <v-card>
                                        <v-toolbar dark color="#6995CD">
                                            <v-toolbar-title>
                                                <v-text-field
                                                        v-model="search2"
                                                        prepend-icon="mdi-magnify"
                                                        label="Nach Personen suchen"
                                                        single-line
                                                        dark
                                                        hide-details
                                                        class="pl-6"
                                                ></v-text-field>
                                            </v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-toolbar-items>
<!--                                                <v-btn dark text @click="dialog = false">Auswählen</v-btn>-->
                                                <v-btn dark elevation="3" style="background-color: #3BAA69; color: white; max-height: 40px; margin-top: 10px; border-radius: 8px" text @click="() => { dialog = false; }">Speichern</v-btn>
                                                <v-btn
                                                        @click="() => { members = JSON.parse(JSON.stringify(oldMembers)); dialog = false; }"
                                                        x-small class="mx-4" elevation="3" style="background-color: #e6221e80; border-radius: 8px; margin-top: 10px; width: 40px; max-height: 40px">
                                                    <img :src="abbrechenIcon" style="height: 40px; filter: brightness(1000%)"/>
                                                </v-btn>
                                            </v-toolbar-items>
                                        </v-toolbar>

                                        <v-data-table
                                                :key="members._id"
                                                :headers="headers2"
                                                :items="members"
                                                :search="search2"
                                                disable-pagination
                                                hide-default-footer
                                                fixed-header
                                                class="ma-3"
                                                locale="de-DE"
                                                no-data-text="Keine Daten verfügbar"
                                                no-results-text="Keine übereinstimmenden Aufzeichnungen gefunden"
                                        >
                                            <template v-slot:item="row">
                                                <tr>
                                                    <td>{{row.item.lastName }}</td>
                                                    <td>{{row.item.name}}</td>
                                                    <td>{{row.item.gender}}</td>
                                                    <td>{{row.item.note}}</td>
                                                    <td>
                                                        <v-checkbox class="mx-2" rounded v-model="row.item.selected" small light>
                                                        </v-checkbox>
                                                    </td>
                                                </tr>
                                            </template>
                                        </v-data-table>
                                    </v-card>


                            </v-dialog>

                        </v-col>
                        <v-col cols="12" md="6" class="mt-8">
                            <v-row >
                                <v-col cols="3">
                                    Farbe:
                                </v-col>
                                <v-col cols="9">
                                    <verwaltung-color-picker
                                        :current-color="color"
                                        :callback="(result) => { color = result; validateInput(); }"
                                    />
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <v-col cols="3">
                                    Icon:
                                </v-col>
                                <v-col cols="9">
                                    <img class="iconSelect" @click="icon = 'englischIcon'" :selected="icon == 'englischIcon'" :src="englischIcon"/>
                                    <img class="iconSelect" @click="icon = 'deutschIcon'" :selected="icon == 'deutschIcon'" :src="deutschIcon"/>
                                    <img class="iconSelect" @click="icon = 'matheIcon'" :selected="icon == 'matheIcon'" :src="matheIcon"/>
                                    <img class="iconSelect" @click="icon = 'bioIcon'" :selected="icon == 'bioIcon'" :src="bioIcon"/>
                                    <img class="iconSelect" @click="icon = 'chemieIcon'" :selected="icon == 'chemieIcon'" :src="chemieIcon"/>
                                    <img class="iconSelect" @click="icon = 'erdkundeIcon'" :selected="icon == 'erdkundeIcon'" :src="erdkundeIcon"/>
                                    <img class="iconSelect" @click="icon = 'geschichteIcon'" :selected="icon == 'geschichteIcon'" :src="geschichteIcon"/>
                                    <img class="iconSelect" @click="icon = 'physikIcon'" :selected="icon == 'physikIcon'" :src="physikIcon"/>
                                    <img class="iconSelect" @click="icon = 'politikIcon'" :selected="icon == 'politikIcon'" :src="politikIcon"/>
                                    <img class="iconSelect" @click="icon = 'religionIcon'" :selected="icon == 'religionIcon'" :src="religionIcon"/>
                                    <img class="iconSelect" @click="icon = 'sportIcon'" :selected="icon == 'sportIcon'" :src="sportIcon"/>
                                    <img class="iconSelect" @click="icon = 'wirtschaftIcon'" :selected="icon == 'wirtschaftIcon'" :src="wirtschaftIcon"/>
                                    <img class="iconSelect" @click="icon = 'musikIcon'" :selected="icon == 'musikIcon'" :src="musikIcon"/>
                                </v-col>
                            </v-row>

                        </v-col>

                    </v-row>

<!--                    <v-row>-->
<!--                        <v-row>-->
<!--                            <v-col cols="6" >-->
<!--                                <div style="display: flex" class="mx-3">-->
<!--                                    <v-btn fab color="#EAEAEA" class="mx-4" small><img :src="infoIcon" style="width: 24px; display: flex" class="" alt="Info Icon"/></v-btn>-->

<!--                                    <div>Hier können Sie Fächer für Ihre Schule anlegen. Wählen Sie eine Farbe, einen Leiter und-->
<!--                                        ein Icon. Sie können die Farben und Icons auch mehrfach benutzen.-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </v-col>-->

<!--                            <v-col cols="3" class="pl-0">-->

<!--                            </v-col>-->
<!--                            <div style="min-width: 100px!important;"></div>-->
<!--                            <v-col cols="1" class="pl-0">-->
<!--                                <v-btn block style="color: white"  @click="stopEdit" color="#BDBDBD" small light>-->
<!--                                    Abbrechen-->
<!--                                </v-btn>-->
<!--                            </v-col>-->
<!--                            <v-col cols="1" class="pl-0">-->
<!--                                <v-btn block style="color: white; border-radius: 8px" color="#3BAA69" v-if="oldSubject" @click="editThisSubject" small light>-->
<!--                                    Speichern-->
<!--                                </v-btn>-->
<!--                                <v-btn block style="color: white; border-radius: 8px" color="#3BAA69"  v-else @click="saveSubject" small light>-->
<!--                                    Speichern-->
<!--                                </v-btn>-->
<!--                            </v-col>-->

<!--                        </v-row>-->
<!--                    </v-row>-->
                </div>



            </div>

        </v-row>

        <!--        Delete dialog-->
        <v-dialog v-model="deleteDialog" persistent max-width="400">
            <v-card>
                <v-card-title class="headline">Fach löschen?</v-card-title>
                <v-card-text>
                    Sind sie sicher, dass sie das Fach löschen möchten?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="" text @click="deleteDialog = false">Abbrechen</v-btn>
                    <v-btn color="red red-1" text @click="() => {this.deleteThisSubject(); this.deleteDialog = false}">Löschen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar bottom v-model="snackbar" color="error" timeout="3000">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbar = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon" />
            </v-btn>
          </template>
            {{snackbarText}}
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarSave" color="success" timeout="3000">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbarSave = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon" />
            </v-btn>
          </template>
            Gespeichert!
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarQuickSave" color="success" timeout="3000">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbarQuickSave = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon" />
            </v-btn>
          </template>
            {{snackbarText}}
        </v-snackbar>

    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
    import suchIcon from '../../assets/Icons/suche-26.svg'

    import bioIcon from '../../assets/Icons/f-biologie-35.svg'
    import chemieIcon from '../../assets/Icons/f-chemie-37.svg'
    import deutschIcon from '../../assets/Icons/f-deutsch-29.svg'
    import englischIcon from '../../assets/Icons/f-englisch-30.svg'
    import erdkundeIcon from '../../assets/Icons/f-erdkunde-31.svg'
    import geschichteIcon from '../../assets/Icons/f-geschichte-40.svg'
    import matheIcon from '../../assets/Icons/f-mathe-32.svg'
    import musikIcon from '../../assets/Icons/f-musik-84.svg'
    import physikIcon from '../../assets/Icons/f-physik-36.svg'
    import politikIcon from '../../assets/Icons/f-politik-34.svg'
    import religionIcon from '../../assets/Icons/f-religion-39.svg'
    import sportIcon from '../../assets/Icons/f-sport-38.svg'
    import wirtschaftIcon from '../../assets/Icons/f-wirtschaft-33.svg'
    import bearbeitenIcon from "../../assets/Icons/bearbeiten-normal-weiß-41.svg";
    import infoIcon from "../../assets/Icons/ausrufezeichen-62.svg";
    import abbrechenIcon from "../../assets/Icons/abbrechen-08.svg";
    import fachIcon from "../../assets/Icons/fächer-05.svg";
    import loeschIcon from '../../assets/Icons/papierkorb-verwaltung-109.svg';


    // Import the styles too, globally
    import VerwaltungColorPicker from "./VerwaltungColorPicker";

    export default {
        name: "Faecher",
        components: {
            VerwaltungColorPicker,
        },
        data() {
            return {
                bioIcon,
                chemieIcon,
                deutschIcon,
                englischIcon,
                erdkundeIcon,
                geschichteIcon,
                matheIcon,
                musikIcon,
                physikIcon,
                politikIcon,
                religionIcon,
                sportIcon,
                wirtschaftIcon,
                bearbeitenIcon,
                infoIcon,
                abbrechenIcon,
                fachIcon,
                loeschIcon,

                showLoadingOverlay: false,
                deleteDialog: false,

                icon: 'deutschIcon',
                dialog: false,
                notifications: false,
                sound: true,
                widgets: false,

                name: '',
                color: '#fcd20a',
                swatches: ['#fcd20a', '#f0b423',  '#ff6941', '#91c364', '#91c887', '#d7875f', '#ebaabe', '#9b91c8',  '#6ec3dc', '#9bcdc3',  '#b2b2b2', '#707070'],

                fields: {
                    name: false,
                    color: false,
                    teacher: false
                },

                snackbar: false,
                snackbarText: '',

                snackbarQuickSave: false,
                snackbarSave: false,

                oldSubject: null,
                height: 0,
                search: '',
                headers: [
                    { text: 'Name', value: 'name' },
                    { text: 'Farbe', value: 'color', sortable: false},
                    { text: '', sortable: false},
                ],


                search2: '',
                headers2: [
                    { text: 'Name', value: 'lastName' },
                    { text: 'Vorname', value: 'name' },
                    { text: 'Geschlecht', value: 'gender' },
                    { text: 'Notiz', value: 'note' },
                    { text: 'Ausgewählt', value: 'selected'},
                ],
                members: [
                ],
                oldMembers: [],
                subjects: [
                ],

                suchIcon,
            };
        },
        props: {

        },
        async created() {
            this.height = window.innerHeight * 0.4
        },
        async mounted() {
            this.showLoadingOverlay = true;
            this.requestSubjects();
            this.requestTeachers();
        },
        watch: {
            getTeacherStatus(newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.requestTeachers();
                }
            },
            getSubjectInvalidStatus(newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.requestSubjects();
                }
            },

        },
        computed: {
            ...mapGetters('teachers', [ 'getTeacherStatus' ]),
            ...mapGetters('subjects', ['getSubjectInvalidStatus']),
        },
        methods: {
            ...mapActions("teachers", ["getTeachers", 'setTeachersInvalidStatus']),
            ...mapActions("subjects", ["getSubjects","createSubject", "editSubject", "deleteSubject", 'setSubjectListStatus']),
            async requestTeachers() {
                this.showLoadingOverlay = true;
                this.members = await this.getTeachers();
                this.showLoadingOverlay = false;
                this.setTeachersInvalidStatus(false);
            },
            async requestSubjects() {
                this.subjects = await this.getSubjects();
                this.setSubjectListStatus(false);
            },

            async edit(subject) {
                this.$vuetify.goTo('#edit');
                this.oldSubject = subject;
                this.name = subject.name;
                this.color = subject.color;
                this.icon = subject.icon;
                this.members.forEach((el) => {
                    el.selected = false;
                });
                subject.teachers.forEach((el) => {
                    this.members.find((element) => {
                        return el.account == element.account}).selected = true;
                })
            },
            stopEdit() {
                this.name = '';
                this.color = '#fcd20a';
                this.icon = 'deutschIcon';
                this.members.forEach((el) => {
                    el.selected = false;
                });
                this.oldSubject = null;

                this.fields.name = false;
                this.fields.color = false;
            },

            async deleteThisSubject() {
                try {
                    this.showLoadingOverlay = true;
                    let response = await this.deleteSubject(this.oldSubject._id);
                    if (response) {
                        const index = this.subjects.indexOf(this.oldSubject);
                        if (index > -1) {
                            this.subjects.splice(index, 1);
                        }
                        this.stopEdit();
                        this.setSubjectListStatus(true);
                        this.snackbarSave = true;
                    }
                    else {
                        this.snackbarText = 'Etwas ist schief gelaufen - Bitte aktualisieren Sie die Seite!';
                        this.snackbar = true;
                    }
                    this.showLoadingOverlay = false;
                } catch (e) {
                    console.log(e);
                    this.showLoadingOverlay = false;
                }
            },

            validateInput() {
                let result = true;

                this.fields.name = false;
                this.fields.color = false;

                if (this.name.replace(/\s/g, '') === '') {
                    this.fields.name = true;
                    result = false;
                }

                if (this.color.replace(/\s/g, '') === '') {
                    this.fields.color = true;
                    result = false;
                }

                return result;
            },

            async editThisSubject() {
                try {
                    this.dialog = false;
                    this.showLoadingOverlay = true;
                    if (!this.validateInput()) {
                        this.snackbarText = 'Bitte füllen Sie alle benötigten Felder aus!';
                        this.snackbar = true;
                        this.showLoadingOverlay = false;
                        return;
                    }
                    let teachers = this.members.filter((el) => {
                        return el.selected;
                    });

                    let sendTeachers = [];
                    teachers.forEach((el) => {
                        sendTeachers.push(el._id)
                    });

                    let data = {
                        _id: this.oldSubject._id,
                        color: this.color,
                        icon: this.icon,
                        teachers: sendTeachers,
                    };

                    if (this.name !== this.oldSubject.name) {
                        data['name'] = this.name;
                    }
                    let response = await this.editSubject(data);
                    if (Number.isInteger(response)) {
                        if (response === 409) {
                            this.snackbarText = 'Name bereits vergeben. Bitte geben Sie einen anderen Fachnamen ein!';
                        } else {
                            this.snackbarText = 'Etwas ist schief gelaufen - Bitte aktualisieren Sie die Seite!';
                        }
                        this.snackbar = true;
                    } else {
                        const index = this.subjects.indexOf(this.oldSubject);
                        if (index > -1) {
                            this.subjects.splice(index, 1);
                        }
                        this.subjects.push(response);
                        this.setSubjectListStatus(true);
                        this.stopEdit();
                        this.snackbarSave = true;
                    }
                    this.showLoadingOverlay = false;
                } catch (e) {
                    console.log(e);
                    this.showLoadingOverlay = false;
                }
            },
            async saveSubject() {
                try {
                    this.dialog = false;
                    this.showLoadingOverlay = true;
                    if (!this.validateInput()) {
                        this.snackbarText = 'Bitte füllen Sie alle benötigten Felder aus!';
                        this.snackbar = true;
                        this.showLoadingOverlay = false;
                        return;
                    }
                    let teachers = this.members.filter((el) => {
                        return el.selected;
                    });

                    let sendTeachers = [];
                    teachers.forEach((el) => {
                        sendTeachers.push(el._id)
                    });

                    let data = {
                        name: this.name,
                        color: this.color,
                        icon: this.icon,
                        teachers: sendTeachers,
                    };
                    let response = await this.createSubject(data);
                    if (Number.isInteger(response)) {
                        if (response === 409) {
                            this.snackbarText = 'Name bereits vergeben. Bitte geben Sie einen anderen Fachnamen ein!';
                        } else {
                            this.snackbarText = 'Etwas ist schief gelaufen - Bitte aktualisieren Sie die Seite!';
                        }
                        this.snackbar = true;
                    } else {
                        this.subjects.push(response);
                        this.setSubjectListStatus(true);
                        this.stopEdit();
                        this.snackbarSave = true;
                    }
                    this.showLoadingOverlay = false;
                } catch (e) {
                    console.log(e);
                    this.showLoadingOverlay = false;
                }
            },
            closeMemberSelection(){
                this.dialog = false;
                this.snackbarText = 'Lehrer erfolgreich ausgewählt!';
                this.snackbarQuickSave = true;
            },
        },
    };
</script>


<style lang="scss" scoped>
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    tr {
        height: 40px;
    }

    table tr th:first-child,
    table tr td:first-child {
        width: 10px !important;
    }

    table tr th,
    table tr td {
        width: 9em;
        border: none;
    }
    table, tr, td {
        border: none;
    }

    input[type="date"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        min-height: 1.6rem;
    }

    .input{
        display: block;
        width: 100%;
        max-width: 100%;
        border-radius: 5px;
        border-width:0px;color: black;
        padding: 1px 5px 1px 5px;
        border: none !important;
        background-color: #EAEAEA;
        overflow: hidden;
        appearance:none
    }

    .input[disabled]{
        color: grey;
        background-color: #CACACA;
    }

    .errorInput {
        border: 2px solid red !important;
    }

    .iconSelect {
        width: 42px;
        padding: 2px;
        border: solid 2px #EAEAEA;
        border-radius: 10px;
        margin-right: 2px;
    }

    .iconSelect[selected] {
        width: 42px;
        padding: 2px;
        border: solid 2px #737373;
        border-radius: 10px;
    }

    .editContainer {
        min-height: 40vh;
        height: fit-content;
        width: 90%;
        border-radius: 15px;
        box-shadow: 1px 5px 5px silver;
        margin: auto;
        margin-bottom: 5em
    }

    .mainControlButtons {
        font-weight: 300;
        font-size: 10px;
        border-radius: 8px;

        & span {
            line-height: 0 !important;
        }
    }

    .mainFunctionButtons {
        width: 38px !important;
        height: 38px !important;
        border-radius: 8px;

        img {
            width: 27px !important;
            height: 27px !important;
            filter: brightness(1000%);
        }
    }

    .infoTextToolTip {
        max-width: 250px;
        background-color: #505FAA;
    }
</style>
