import {
  request,
  getRequest,
  patchRequest,
  postRequest,
  deleteRequest,
  filePostRequest,
  fileGetRequest,
  createFileXMLHttpRequest,
  createFileXmlPatchRequest
} from "./request";

// {"accountName":"m-vr8-xh3-brd","password":"m-bpp-ydc-xu4"}
const loginAccount = async (body) => postRequest('/account/login', body);
const getCurrentAccount = async () => getRequest('/account/get');
const acceptTermsOfService = async () => postRequest('/account/accepttos');

const deleteMessage = async (parms) => deleteRequest(`/matrix/message/${parms.roomId}/${parms.eventId}/${parms.txnId}`, parms);
const getCurrentMatrixUser = async () => getRequest('/matrix/user/me');
const getMatrixName = async (accountId) => getRequest(`/matrix/user/name/${accountId}`);
const postVoiceMessage = ( file) => createFileXMLHttpRequest(`/matrix/voice`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const deleteVoiceMessage = async (fileId) => deleteRequest(`/matrix/voice/${fileId}`);
const getVoiceMessage = async ( fileId) => getRequest(`/matrix/voice/${fileId}`);
const postPrivateRoom = async (body) => postRequest('/matrix/room/private', body);
const getMatrixRoomLockStatus = async () => getRequest(`/matrix/room/lock`);
const getMatrixRoomLockStatusById = async (roomId) => getRequest(`/matrix/room/lock/${roomId}`);
const setMatrixRoomLockStatus = async (roomId, body) => postRequest(`/matrix/room/lock/${roomId}`, body);

const postNameAvailable = async (body) => postRequest('/account/available', body);

const getTimeslots = async () => getRequest('/timeslot');
const postTimeslot = async (body) => postRequest('/timeslot', body);
const patchTimeslot = async (timeslotId, body) => patchRequest(`/timeslot/${timeslotId}`, body);
const deleteTimeslot = async (timeslotId) => deleteRequest(`/timeslot/${timeslotId}`);

const getGroups = async (appointmentId) => getRequest(`/group${appointmentId ? `?appointmentId=${appointmentId}` : ''}`);
const getGroupsInfo = async (appointmentId) => getRequest(`/group/lean${appointmentId ? `?appointmentId=${appointmentId}` : ''}`);
const getGroup = async (groupId) => getRequest(`/group/${groupId}`);
const getGroupByAppointment = async (appointmentId) => getRequest(`/groupByAppointment/${appointmentId}`);
const postGroup = async (body) => postRequest('/group', body);
const patchGroup = async (groupId, body) => patchRequest(`/group/${groupId}`, body);
const deleteGroup = async (groupId) => deleteRequest(`/group/${groupId}`);

const getMaintainers = async () => getRequest('/maintainer');
const getMaintainer = async (maintainerId) => getRequest(`/maintainer/${maintainerId}`);
const postMaintainer = async (body) => postRequest('/maintainer', body);
const patchMaintainer = async (maintainerId, body) => patchRequest(`/maintainer/${maintainerId}`, body);
const deleteMaintainer = async (maintainerId) => deleteRequest(`/maintainer/${maintainerId}`);

const getPupils = async () => getRequest('/pupil');
const getPupil = async (pupilId) => getRequest(`/pupil/${pupilId}`);
const getMePupil = async () => getRequest(`/pupil/me`);
const postPupil = async (body) => postRequest('/pupil', body);
const patchPupil = async (pupilId, body) => patchRequest(`/pupil/${pupilId}`, body);
const deletePupil = async (pupilId) => deleteRequest(`/pupil/${pupilId}`);

const getParents = async () => getRequest('/parent');
const getParent = async (parentId, body) => getRequest(`/parent/${parentId}`, body);
const getMeParent = async () => getRequest('/parent/me');
const postParent = async (body) => postRequest('/parent', body);
const patchParent = async (parentId, body) => patchRequest(`/parent/${parentId}`, body);
const deleteParent = async (parentId) => deleteRequest(`/parent/${parentId}`);

const getSchoolSubjects = async () => getRequest('/schoolsubject');
const getSchoolSubject = async (schoolSubjectId) => getRequest(`/schoolsubject/${schoolSubjectId}`);
const postSchoolSubject = async (body) => postRequest('/schoolsubject', body);
const patchSchoolSubject = async (schoolSubjectId, body) => patchRequest(`/schoolsubject/${schoolSubjectId}`, body);
const deleteSchoolSubject = async (schoolSubjectId) => deleteRequest(`/schoolsubject/${schoolSubjectId}`);

const getSchoolSubjectAppointments = async () => getRequest('/schoolsubjectappointment');
const getSchoolSubjectAppointmentsParent = async (pupilId) => getRequest(`/schoolsubjectappointment/parent/${pupilId}`);
const postSchoolSubjectAppointment = async (body) => postRequest('/schoolsubjectappointment', body);
const patchSchoolSubjectAppointment = async (schoolSubjectAppointmentId, body) => patchRequest(`/schoolsubjectappointment/${schoolSubjectAppointmentId}`, body);
const deleteSchoolSubjectAppointment = async (schoolSubjectAppointmentId) => deleteRequest(`/schoolsubjectappointment/${schoolSubjectAppointmentId}`);
const deleteSchoolSubjectAppointmentRule = async (ruleId) => deleteRequest(`/schoolsubjectappointment/rule/${ruleId}`);
const deleteSchoolSubjectAppointmentFromRule = async (appointmentId, ruleId) => deleteRequest(`/schoolsubjectappointment/${appointmentId}/rule/${ruleId}`);
const getSchoolSubjectAppointmentRuleFromId = async (ruleId) => getRequest(`/schoolSubjectAppointment/rule/${ruleId}`);
const joinSchoolSubjectAppointmentBbbMeetingUrl = async (schoolSubjectAppointmentId) => getRequest(`/schoolsubjectappointment/${schoolSubjectAppointmentId}/joinBbbMeetingUrl`);
const joinTeachersRoomBbbMeetingUrl = async () => getRequest(`/teacher/joinTeachersRoomUrl`);
const postTeacherUpload = (appointmentId, file) => createFileXMLHttpRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const postTeacherUploadEdit = async (appointmentId, fileId, file) => filePostRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/${fileId}/edit`, file);
const postTeacherUploadFromFileWidget = async (appointmentId, body) => postRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/postFromFileWidget`, body);
const patchTeacherUploadPip = async (appointmentId, fileId, args) => patchRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/${fileId}/pip`, args);
const patchTeacherUploadFile = async (appointmentId, fileId, newFile) => createFileXmlPatchRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/${fileId}/file`, newFile);
const getTeacherUpload = async (appointmentId, fileId) => getRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/${fileId}`);
const getTeacherUploadThumbnail = async (appointmentId, fileId) => getRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/thumb/${fileId}`);
const deleteUpload = async (fileId) => deleteRequest(`/schoolSubjectAppointment/upload/${fileId}`);
const postPupilUpload = (appointmentId, file) => createFileXMLHttpRequest(`/schoolSubjectAppointment/${appointmentId}/pupilUploads`, file);
const postPupilUploadEdit = async (appointmentId, fileId, file) => filePostRequest(`/schoolSubjectAppointment/${appointmentId}/pupilUploads/${fileId}/edit`, file);
const getPupilUpload = async (appointmentId, fileId) => getRequest(`/schoolSubjectAppointment/${appointmentId}/pupilUploads/${fileId}`);
const getPupilUploadThumbnail = async (appointmentId, fileId) => getRequest(`/schoolSubjectAppointment/${appointmentId}/pupilUploads/thumb/${fileId}`);

const getTeachers = async () => getRequest('/teacher');
const getTeacher = async (teacherId) => getRequest(`/teacher/${teacherId}`);
const getProfilePicture = async (teacherId) => getRequest(`/teacher/${teacherId}/profilePicture`);
const getMeTeacher = async () => getRequest(`/teacher/me`);
const postTeacher = async (body) => postRequest('/teacher', body);
const postTeacherPicture = (teacherId, file) => createFileXMLHttpRequest(`/teacher/${teacherId}/profilePicture`, file);
const patchTeacher = async (teacherId, body) => patchRequest(`/teacher/${teacherId}`, body);
const deleteTeacher = async (teacherId) => deleteRequest(`/teacher/${teacherId}`);

const getMyExternalRoom = async () => getRequest('/teacherExternalRoom');
const getGuestURL = async () => getRequest('/teacherExternalRoom/guestURL');
const getJoinExternalRoom = async () => getRequest('/teacherExternalRoom/teacherURL');
const postChangeExternalRoomStatus = async (body) => postRequest('/teacherExternalRoom/changeStatus', body);

const patchAutoSave = async (appointmentId, uploadId, body) => patchRequest(`/schoolSubjectAppointment/${appointmentId}/autoSave/${uploadId}`, body);
const getAutoSave = async (appointmentId, uploadId) => getRequest(`/schoolSubjectAppointment/${appointmentId}/uploads/${uploadId}`);

const getBlackboard = async () => getRequest('/blackboard');
const getParentBlackboard = async (pupilId) => getRequest(`/blackboard/${pupilId}`);
const postBlackboard = async (body) => postRequest('/blackboard', body);
const postBlackboardFile = (blackboardId, file) => createFileXMLHttpRequest(`/blackboard/${blackboardId}/file`, file);
const getBlackboardUpload = async (fileId) => getRequest(`/blackboard/file/${fileId}`);
const getBlackboardUploadThumbnail = async (fileId) => getRequest(`/blackboard/file/thumb/${fileId}`);
const patchBlackboard = async (blackboardId, body) => patchRequest(`/blackboard/${blackboardId}`, body);
const deleteBlackboard = async (blackboardId, body) => deleteRequest(`/blackboard/${blackboardId}`, body);
const deleteBlackboardUpload = async (blackboardId, fileId) => deleteRequest(`/blackboard/${blackboardId}/file/${fileId}`);

const postPrivateUpload = ( file) => createFileXMLHttpRequest(`/privateFolder`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getPrivateUploads = async () => getRequest('/privatefolder');
const getPrivateUpload = async ( fileId) => getRequest(`/privatefolder/${fileId}`);
const deletePrivateUpload = async (fileId) => deleteRequest(`/privatefolder/${fileId}`);

const postUploadMessage = ( file) => filePostRequest(`/uploadMessage`, file);
const postUploadMessages = async (body) => postRequest('/uploadMessages', body);
const getUploadMessages = async ( fileId) => getRequest(`/uploadMessages/${fileId}`);
const getUploadMessage = async ( fileId) => getRequest(`/uploadMessage/${fileId}`);

const postSubjectFolderUpload = (subjectId, file) => createFileXMLHttpRequest(`/subjectFolder/${subjectId}`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getSubjectFolderUploads = async (subjectId, teacherId) => getRequest(`/subjectFolder/${subjectId}/${teacherId}`);
const getSubjectFolderUpload = async (fileId) => getRequest(`/subjectFolder/${fileId}`);
const deleteSubjectFolderUpload = async (subjectId, teacherId, fileId) => deleteRequest(`/subjectFolder/${subjectId}/${teacherId}/${fileId}`);

const postTeacherFolderUpload = (file) => createFileXMLHttpRequest(`/teacherFolder/`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getTeacherFolderUploads = async ()  => getRequest(`/teacherFolder`);
const getTeacherFolderUpload = async (fileId) => getRequest(`/teacherFolder/${fileId}`);
const deleteTeacherFolderUpload = async ( fileId) => deleteRequest(`/teacherFolder/${fileId}`);

const getServerTime = async (body) => getRequest('/time', body);
const getVersionNumber = async () => getRequest('/version');
const getBonusFunctions = async (body) => getRequest('/bonusfunctions');

const teacherClassroom = async () => getRequest('/teacherClassroom');
const joinClassroom = async (classroomId) => getRequest(`/teacherClassroom/${classroomId}`);
const changeRoomStatus = async (classroomId, body) => postRequest(`/teacherClassroom/${classroomId}`, body);

const getMyConferenceTeacher = async () => getRequest('/teacherConferenceRoom');
const getMyConferenceParent = async (pupilId) => getRequest(`/teacherConferenceRoom/${pupilId}`);
const joinConferenceRoom = async (roomId) => getRequest(`/teacherConferenceRoom/bbb/${roomId}`);
const changeConferenceRoomStatus = async (roomId, body) => postRequest(`/teacherConferenceRoom/${roomId}`, body);

const translateToTargetLang = async (body) => postRequest('/translate', body)

const getSubtitle = async (fileId) => getRequest(`/subtitle/${fileId}`);

export {
  loginAccount,
  getCurrentAccount,
  acceptTermsOfService,

  deleteMessage,
  getCurrentMatrixUser,
  getMatrixName,
  postVoiceMessage,
  getVoiceMessage,
  deleteVoiceMessage,
  postPrivateRoom,
  getMatrixRoomLockStatus,
  getMatrixRoomLockStatusById,
  setMatrixRoomLockStatus,

  postNameAvailable,

  getGroups,
  getGroupsInfo,
  getGroup,
  getGroupByAppointment,
  postGroup,
  patchGroup,
  deleteGroup,

  getMaintainers,
  getMaintainer,
  postMaintainer,
  patchMaintainer,
  deleteMaintainer,

  getPupils,
  getPupil,
  getMePupil,
  postPupil,
  patchPupil,
  deletePupil,

  getParents,
  getParent,
  getMeParent,
  postParent,
  patchParent,
  deleteParent,

  getSchoolSubjects,
  getSchoolSubject,
  postSchoolSubject,
  patchSchoolSubject,
  deleteSchoolSubject,

  getSchoolSubjectAppointments,
  getSchoolSubjectAppointmentsParent,
  postSchoolSubjectAppointment,
  patchSchoolSubjectAppointment,
  deleteSchoolSubjectAppointment,
  deleteSchoolSubjectAppointmentRule,
  deleteSchoolSubjectAppointmentFromRule,
  getSchoolSubjectAppointmentRuleFromId,
  joinSchoolSubjectAppointmentBbbMeetingUrl,
  postTeacherUpload,
  postTeacherUploadEdit,
  postTeacherUploadFromFileWidget,
  patchTeacherUploadPip,
  patchTeacherUploadFile,
  getTeacherUpload,
  getTeacherUploadThumbnail,
  deleteUpload,
  postPupilUpload,
  postPupilUploadEdit,
  getPupilUpload,
  getPupilUploadThumbnail,

  joinTeachersRoomBbbMeetingUrl,

  getTeachers,
  getTeacher,
  getProfilePicture,
  getMeTeacher,
  postTeacher,
  postTeacherPicture,
  patchTeacher,
  deleteTeacher,
  getMyExternalRoom,
  getGuestURL,
  getJoinExternalRoom,
  postChangeExternalRoomStatus,

  getTimeslots,
  postTimeslot,
  patchTimeslot,
  deleteTimeslot,

  getBlackboard,
  getParentBlackboard,
  postBlackboard,
  postBlackboardFile,
  getBlackboardUpload,
  getBlackboardUploadThumbnail,
  deleteBlackboardUpload,
  patchBlackboard,
  deleteBlackboard,

  patchAutoSave,
  getAutoSave,

  postPrivateUpload,
  getPrivateUploads,
  getPrivateUpload,
  deletePrivateUpload,

  postUploadMessage,
  postUploadMessages,
  getUploadMessages,
  getUploadMessage,

  postSubjectFolderUpload,
  getSubjectFolderUploads,
  getSubjectFolderUpload,
  deleteSubjectFolderUpload,

  postTeacherFolderUpload,
  getTeacherFolderUploads,
  getTeacherFolderUpload,
  deleteTeacherFolderUpload,

  getServerTime,
  getVersionNumber,
  getBonusFunctions,

  teacherClassroom,
  joinClassroom,
  changeRoomStatus,

  getMyConferenceTeacher,
  getMyConferenceParent,
  joinConferenceRoom,
  changeConferenceRoomStatus,

  translateToTargetLang,

  getSubtitle,
};
