<template>
    <div style="width: 90%; padding-right: 3px; padding-left: -3px">
        <progress-bar
            title="Hochladen"
            :show-progress="showTeacherUploadProgress"
            :progress="teacherUploadProgress"
            :abort-progress="teacherXmlHttpRequest ? () => { teacherXmlHttpRequest.abort() }: () => {}"
        />

        <v-dialog
            v-if="thisAppointment"
            v-model="menu"
            @click:outside="resetDialogContent()"
            :max-width="windowWidth >= 1000 ? 800 : 400"
            scrollable
            class="dialog"
            overlay-opacity="0.8"
        >

            <template v-slot:activator="{ on, attrs }">
                <!--fach with text and image-->
                <div v-bind="attrs" class="fach pa-2 white--text"
                     style="width: 100%; display: block; padding-left: 0 !important;">
                    <v-row cols="12" v-on="on" class="ml-2 font-weight-medium" justify="center">
                        <v-col
                            :cols="isRunning ? 9 : 12"
                            v-on="on"
                            class="pa-0 ma-0"
                        >
                            <v-badge
                                color="red"
                                :content="thisAppointment.pupilUpload.filter((el) => !el.seenByMe).length"
                                :value="thisAppointment.pupilUpload.filter((el) => !el.seenByMe).length"
                                overlap
                                left
                                offset-x="5"
                                style="z-index: 8; width: 100%; height: 48px !important;"
                            >
                                <div @click="getMissingMembers" class="pa-3 ma-0 appointmentItem"
                                     :class="{ inactiveAppointment: !isRunning }"
                                     :style="isRunning ? 'width: calc(100% - 3px) !important' : ''"
                                >
                                    <img
                                        v-if="getSrc()"
                                        class="iconSelect"
                                        :src="isRunning ? getSrcWhite() : getSrc()"
                                        :style="isRunning ? getBackgroundColor() : 'background-color: transparent'"
                                        alt="Fachsymbol"
                                    />

                                    <div v-else class="iconSelect"></div>
                                    <p class="mb-0 ml-1" style="display: inline-block">
                                        {{ thisAppointment.schoolSubject.name }}
                                    </p>
                                    <!--bookmark-->
                                    <img :src="getColorIcon()" alt="Fachsymbol"
                                         style="position: absolute; height: 25px; top: 0; right: 10px;"/>
                                </div>
                                <!--paperclip-->
                                <img v-if="thisAppointment.teacherUploads.length > 0 || thisAppointment.pupilUpload.length > 0" alt="Anhangsymbol"
                                     :src="paperclipIcon" :style="isRunning ? 'right: 30px' : 'right: 27px'"
                                     style="opacity: 60%; position: absolute; height: 21px; top: -9px;"/>
                            </v-badge>
                        </v-col>

                        <!--Kamera-Icon neben Fach-->
                        <v-col
                            v-if="isRunning"
                            :cols="3"
                            class="pa-0 mr-0"
                            @click="clickBeitreten"
                            style="min-height: 100%"
                        >
                            <v-btn
                                class="pa-0" small
                                :class="{ isFachRunning: isRunning }"
                                style="height: 48px; width: 100%; background-color: white; border-radius: 8px;"
                            >
                                <img
                                    :src="beitretenKameraIcon"
                                    class="pa-0"
                                    :class="{ isFachRunningIcon: isRunning }"
                                    style="height: 35px !important; width: 35px !important;"
                                    alt="Videokonferenz beitreten Symbol"
                                />
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </template>

            <!--menu that opens on click on fach in table-->
            <v-card tile style="color: #707070;" id="fachPopup">
                <v-card-title class="pa-0 ma-0">
                    <div class="fach d-flex"
                         style="box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3); font-size: large; align-items: center; padding: 0 20px; height: 59px; width: 100%;">
                        <v-col cols="6" class="d-flex align-center pl-0">
                            <img :src="getColorIcon()" alt="Fachsymbol"
                                 style="position: absolute; right: 20%; top: 0; height: 25px"/>
                            <img v-if="getSrc()" alt="Fachsymbol" class="iconSelect mr-2" :src="getSrcWhite()"
                                 style="margin-bottom: 0" :style="getBackgroundColor()"/>
                            <div style="flex: auto">{{ thisAppointment.schoolSubject.name }}</div>
                        </v-col>

                        <v-col
                            cols="4" class="pa-0 pr-6 d-flex flex-column justify-start align-end"
                            :style="`font-size: ${windowWidth < 400 ? 'small': 'medium'}`"
                        >

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on" style="text-decoration: underline;">{{ groupName }}</div>
                                </template>
                                <div style="max-height: 80vh; overflow: hidden;">
                                    <span v-for="(item, index) in groupMembers" :key="item.lastName + index">{{ index === groupMembers.length - 1 ? item.name + ' ' + item.lastName :  item.name + ' ' + item.lastName + ', '}} <br v-if="0 === (index+1) %4"></span>
                                </div>
                            </v-tooltip>
<!--                            <div>{{groupMembers}}</div>-->
                        </v-col>

                        <v-col cols="2" align-self="end" :class="windowWidth >= 1000 ? 'd-flex flex-row-reverse' : ''">
                            <v-btn @click="menu = false; resetDialogContent()" elevation="2" x-small
                                   class="ml-2 pa-0 btnStyle" style="width: 30px; height: 30px">
                                <img :src="schliesenIcon" alt="Schließensymbol" style="height: 20px;"/>
                            </v-btn>
                        </v-col>
                    </div>
                </v-card-title>

                <v-card-text class="pa-0 ma-0" style="overflow: hidden;">
                    <vue-custom-scrollbar class="scroll-area-fachPopup mb-2" :settings="settings">
                        <div class="mx-5">
                            <div class="mt-2">
                                <v-row>
                                    <v-col class="font-weight-bold py-2 d-flex align-center">
                                        <img :src="infoIcon"
                                             style="height: 20px;"
                                             alt="Informationssymbol"
                                        />
                                        <p class="pl-2 ma-0">Info</p>
                                    </v-col>
                                    <v-col class="text-right py-1">
                                        <v-btn v-if="update" @click="saveNote" x-small elevation="0" class="btnStyle">
                                            <img :src="speichernIcon" alt="Speichernsymbol" style="height: 20px;"/>
                                        </v-btn>
                                        <v-btn v-else @click="updateNote" x-small elevation="0" class="btnStyle">
                                            <img :src="bearbeitenIcon" alt="Bearbeitensymbol" style="height: 20px;"/>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <!--                                <v-textarea v-if="update" v-model="thisAppointment.note" autofocus></v-textarea>-->
                                <advanced-quill-editor
                                    v-if="update"
                                    v-model="thisAppointment.note"
                                    placeholder="Hier die Info der Stunde einfügen"
                                >

                                </advanced-quill-editor>
                                <vue-custom-scrollbar
                                    style="font-size: medium; font-weight: normal; line-height: 25px"
                                    class="mb-4 mt-1 scroll-area"
                                    :settings="settings"
                                    v-else-if="thisAppointment.note"
                                >
                                    <span class="ql-editor" v-html="urlify(thisAppointment.note)"></span>
                                </vue-custom-scrollbar>
                                <div v-else class="pb-1" style="color: #BDBDBD">
                                    Tragen Sie hier die Info zum Fach ein
                                </div>
                                <v-expand-transition>
                                    <div v-show="!thisAppointment.note && update"
                                         style="border: 1px solid lightgray; border-top: none;" id="infoBox">
                                        <v-row justify="center" align="center">
                                            <v-col cols="auto" class="ml-1 pr-0">
                                                <img :src="infoIcon"
                                                     style="height: 18px;"
                                                />
                                            </v-col>
                                            <v-col class="caption pl-2" cols="10"
                                                   style="display: flex; flex-flow: wrap;">
                                                Benutzen Sie das
                                                <img
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACXSURBVEhL7ZXRCcAgDETTDqRL6ESu4UZOoQu1tUQqajD9SEHoA+kJl3BGodtxAULs+BVh3ebdzJ1zkFLCHQ+tNXjvcffQJX/bOBNjRNWQk9cYY+5VNEXtoXzkzK21qGhmHrJ5CAEVzcwjmrx7LaUgp5oV157RKb59521aaq4c37oXKpp8OnMurJkrpVDxoWr+P9GQVZsDnKdflorH3FuAAAAAAElFTkSuQmCC"/>
                                                -Symbol um Videos, und das
                                                <img
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAATCAYAAAB/TkaLAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAChSURBVDhP5ZRRCsMgDECz0fPoKbyNf+Ip9NPT6Cn0Qu1qMzbbxuloP0ofSBLQR4jiY3wBB/PEeCjXkRYzVUpBSgmrNjjnYK3FaqHotFc4E2PE7EPRqRAixxBCjr+g9pMzlVLm9Q9dF+W9B2MMVjQDxg3OOcwW1kKtNWZbqp2+RWvhXNcgpZSwBVLaK/rmlCe1K+2l+k4ZY5i1s3fm1v8pwAT8ElNU+b/IfgAAAABJRU5ErkJggg=="/>
                                                -Symbol um Bilder zu verlinken.
                                            </v-col>
                                        </v-row>

                                    </div>
                                </v-expand-transition>
                            </div>
                        </div>

                        <hr class="eHr"/>

                        <div class="mx-0">
                            <div class="mt-2">
                                <v-row>
                                    <v-col class="font-weight-bold py-2 mx-5 pt-0 d-flex align-center">
                                        <img :src="materialIcon"
                                             style="height: 20px;"
                                             alt="Materialsymbol"
                                        />
                                        <p class="pl-2 ma-0">Material</p>
                                    </v-col>
                                </v-row>
                                <div v-if="!teacherUpload()" class="mx-5 pb-1"
                                     style="color: #BDBDBD; font-size: medium">
                                    Laden Sie hier Ihre Materialien hoch.
                                </div>

                                <!--up to two items if collapsed-->
                                <div class="matShort">
                                    <div
                                        v-for="(el,index) in thisAppointment.teacherUploads.filter(item => !item.editParent).slice(0, 2)"
                                        :class="{ brighterFileRow: index % 2 === 1}"
                                        :key="el + index"
                                        style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%;"
                                    >
                                        <div style="display: flex; flex-flow: column; width: 100%;"
                                             class="py-2 px-5 d-flex justify-space-between align-center">
                                            <div style="width: 100%; background-color: rgba(112,112,112, 0.15); border-top-left-radius: 6px; border-top-right-radius: 6px;"
                                                 class="py-2 px-5 d-flex justify-space-between align-center">
                                                <div class="fileName"
                                                     style="min-width: 42%; width: 42%" :title="el.title">
                                                    {{ el.title }}
                                                </div>
                                                <div class="pa-0 ml-2">
                                                    <div style="display: inline-block">
                                                        <v-btn
                                                            v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                            dark @click="() => {play(el); menu = false}" x-small
                                                            class="btnStyle" elevation="0"
                                                            style="background-color: #8CBD46;">
                                                            <img :src="rechtsIcon" alt="Video abspielen"
                                                                 style="height: 20px; filter: brightness(1000%)"/>
                                                        </v-btn>

                                                        <v-btn
                                                            v-else-if="['wav', 'mp3', 'wma', 'm4a' , 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                                            dark @click="() => {playAudio(el); menu = false}" x-small
                                                            class="btnStyle" elevation="0"
                                                            style="background-color: #8CBD46;">
                                                            <img :src="rechtsIcon" alt="Audio abspielen"
                                                                 style="height: 20px; filter: brightness(1000%)"/>
                                                        </v-btn>
                                                        <v-btn
                                                            v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                            @click="() => openAnsicht(el.title, el.file, el._id, 'pupil')"
                                                            class="btnStyle" x-small elevation="0">
                                                            <img :src="stiftIcon"
                                                                 style="filter: brightness(60%); height: 20px;"
                                                                 alt="Bearbeitensymbol"/>
                                                        </v-btn>
                                                        <v-btn v-else
                                                               dark @click="() => { snackbarFileNotSupported = true; }"
                                                               x-small class="btnStyle" elevation="0"
                                                               style="background-color: #8CBD46;">
                                                            <img :src="infoIcon" alt="Informationssymbol"
                                                                 style="height: 20px; filter: brightness(1000%)"/>
                                                        </v-btn>
                                                    </div>

                                                    <div style="display: inline-block">
                                                        <v-btn @click="deleteDialog = true; elementToDelete = el"
                                                               class="btnStyle ml-2" x-small elevation="0">
                                                            <img :src="papierkorbIcon" style="height: 20px;"
                                                                 alt="Löschensymbol"/>
                                                        </v-btn>
                                                    </div>
                                                    <div style="display: inline-block">
                                                        <v-btn @click="() => clickDownloadTeacherUpload(el)"
                                                               class="btnStyle ml-2" x-small elevation="0">
                                                            <img :src="runterladenIcon" alt="Herunterladen"
                                                                 style="height: 20px;"/>
                                                        </v-btn>
                                                    </div>
                                                    <div style="display: inline-block">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase()) && (!('canPip' in el) || el.canPip)"
                                                                    @click="() => showUploadVideoInVideoPopup(el)"
                                                                    x-small
                                                                    class="btnStyle ml-2" elevation="0">
                                                                    <v-icon color="#646464" size="17"
                                                                            class="fas fa-photo-video">
                                                                    </v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Video in Video für Hörgeschädigte</span>
                                                        </v-tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="urls[el._id]" class="d-flex justify-center" style="max-height: 15vh; background-color: gray; width: 100%; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;">
                                                <!-- Display image preview -->
                                                <img
                                                    v-if="['png', 'jpg', 'jpeg', 'bmp', 'mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                    @click="() => ['png', 'jpg', 'jpeg', 'bmp'].includes(el.title.split('.').pop().toLowerCase()) ? openAnsicht(el.title, el.file, el._id, 'pupil') : play(el, index)"
                                                    :src="urls[el._id]"
                                                    style="cursor: pointer; max-width: 345px; max-height: 15vh;" />
                                                <!-- Display pdf preview -->
                                                <object type="application/pdf"
                                                        v-else-if="['pdf'].includes(el.title.split('.').pop().toLowerCase())"
                                                        @click="() => openEdit(el.title, el.file, el._id)"
                                                        :data="urls[el._id]"
                                                        style="cursor: pointer; max-height: 100px; width: 100%"/>
                                                <!-- Display video preview -->
<!--                                                <video-->
<!--                                                    v-else-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"-->
<!--                                                    @click="() => play(el, index)"-->
<!--                                                    :src="urls[el._id]"-->
<!--                                                    style="cursor: pointer; max-width: 345px; max-height: 15vh;"/>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!--all items if expanded-->
                                <div class="matLong">
                                    <div
                                        v-for="(el,index) in thisAppointment.teacherUploads.filter(item => !item.editParent)"
                                        :class="{ brighterFileRow: index % 2 === 1}"
                                        :key="el + index"
                                        style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%;">

                                        <div style="display: flex; flex-flow: column; width: 100%;"
                                             class="py-2 px-5 d-flex justify-space-between align-center">
                                            <div style="width: 100%; background-color: rgba(112,112,112, 0.15); border-top-left-radius: 6px; border-top-right-radius: 6px;"
                                                 class="py-2 px-5 d-flex justify-space-between align-center">
                                                <div class="fileName" style="min-width: 42%; width: 42%;" :title="el.title">
                                                    {{ el.title }}
                                                </div>
                                                <div class="pa-0 ml-2">
                                                    <div style="display: inline-block">
                                                        <v-btn
                                                            v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                            dark @click="() => {play(el); menu = false}" x-small
                                                            class="btnStyle" elevation="0"
                                                            style="background-color: #8CBD46;">
                                                            <img :src="rechtsIcon" alt="Video abspielen Symbol"
                                                                 style="height: 20px; filter: brightness(1000%)"/>
                                                        </v-btn>

                                                        <v-btn
                                                            v-else-if="['wav', 'mp3', 'wma', 'm4a' , 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                                            dark @click="() => {playAudio(el); menu = false}" x-small
                                                            class="btnStyle" elevation="0"
                                                            style="background-color: #8CBD46;">
                                                            <img :src="rechtsIcon" alt="Audio abspielen Symbol"
                                                                 style="height: 20px; filter: brightness(1000%)"/>
                                                        </v-btn>
                                                        <v-btn
                                                            v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                            @click="() => openAnsicht(el.title, el.file, el._id, 'pupil')"
                                                            class="btnStyle" x-small elevation="0">
                                                            <!--                                                    <img :src="papierkorbIcon" style="height: 20px;"/>-->
                                                            <!--                                                    <v-icon style="width: 20px; color: #939393">fas fa-eye</v-icon>-->
                                                            <img :src="stiftIcon" alt="Bearbeitensymbol"
                                                                 style="filter: brightness(60%); height: 20px;"/>
                                                        </v-btn>
                                                        <v-btn v-else
                                                               dark @click="() => { snackbarFileNotSupported = true; }"
                                                               x-small class="btnStyle" elevation="0"
                                                               style="background-color: #8CBD46;">
                                                            <img :src="infoIcon" alt="Informationssymbol"
                                                                 style="height: 20px; filter: brightness(1000%)"/>
                                                        </v-btn>
                                                    </div>
                                                    <div style="display: inline-block">
                                                        <v-btn @click="deleteDialog = true; elementToDelete = el"
                                                               class="btnStyle ml-2" x-small elevation="0">
                                                            <img :src="papierkorbIcon" alt="Löschensymbol"
                                                                 style="height: 20px;"/>
                                                        </v-btn>
                                                    </div>
                                                    <div style="display: inline-block">
                                                        <v-btn @click="() => clickDownloadTeacherUpload(el)"
                                                               class="btnStyle ml-2" x-small elevation="0">
                                                            <img :src="runterladenIcon" alt="Herunterladensymbol"
                                                                 style="height: 20px;"/>
                                                        </v-btn>
                                                    </div>
                                                    <div style="display: inline-block">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase()) && (!('canPip' in el) || el.canPip)"
                                                                    @click="() => showUploadVideoInVideoPopup(el)"
                                                                    x-small
                                                                    class="btnStyle ml-2" elevation="0">
                                                                    <v-icon color="#646464" size="17"
                                                                            class="fas fa-photo-video">
                                                                    </v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Video in Video für Hörgeschädigte</span>
                                                        </v-tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                                <div v-if="urls[el._id]" class="d-flex justify-center" style="max-height: 15vh; background-color: gray; width: 100%; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;">
                                                    <!-- Display image preview -->
                                                    <img
                                                        v-if="['png', 'jpg', 'jpeg', 'bmp', 'mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                        @click="() => ['png', 'jpg', 'jpeg', 'bmp'].includes(el.title.split('.').pop().toLowerCase()) ? openAnsicht(el.title, el.file, el._id, 'pupil') : play(el, index)"
                                                        :src="urls[el._id]"
                                                        style="cursor: pointer; max-width: 345px; max-height: 15vh;" />
                                                    <!-- Display pdf preview -->
                                                    <object type="application/pdf"
                                                            v-else-if="['pdf'].includes(el.title.split('.').pop().toLowerCase())"
                                                            @click="() => openEdit(el.title, el.file, el._id)"
                                                            :data="urls[el._id]"
                                                            style="cursor: pointer; max-height: 100px; width: 100%"/>
                                                    <!-- Display video preview -->
<!--                                                    <video-->
<!--                                                        v-else-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"-->
<!--                                                        @click="() => play(el, index)"-->
<!--                                                        :src="urls[el._id]"-->
<!--                                                        style="cursor: pointer; max-width: 345px; max-height: 15vh;"/>-->
                                                </div>
                                        </div>
                                    </div>
                                </div>

                                <!--expand and collapse btns-->
                                <div v-if="thisAppointment.teacherUploads.filter(item => !item.editParent).length > 2"
                                     class="d-flex flex-column align-center justify-center mt-2">
                                    <v-btn @click="() => { hideEl('matShort'); showEl('matLong') }"
                                           class="matShort" small
                                           :style="`background-color: ${thisAppointment.schoolSubject.color};`"
                                           elevation="0">
                                        <img :src="arrowDown" alt="Pfeil nach unten Symbol" style="height: 20px; filter: brightness(1000%)"/>
                                    </v-btn>
                                    <p class="mb-0 matShort">Mehr anzeigen</p>

                                    <v-btn @click="() => { hideEl('matLong'); showEl('matShort') }"
                                           class="matLong" small
                                           :style="`background-color: ${thisAppointment.schoolSubject.color};`"
                                           elevation="0">
                                        <img :src="arrowDown" alt="Pfeil nach oben Symbol"
                                             style="height: 20px; transform: rotate(180deg); filter: brightness(1000%)"/>
                                    </v-btn>
                                    <p class="mb-0 matLong">Weniger anzeigen</p>

                                </div>
                            </div>
                        </div>

                        <div class="my-4">
                            <input
                                @change="() => teacherUploadInputChange(thisAppointment._id)"
                                id="teacherUploadInput"
                                type="file"
                                hidden
                                ref="teacherUploadInput"
                            />

                            <div class="d-flex" style="justify-content: center">
                                <v-tooltip v-model="supFormats"
                                           max-width="350" bottom
                                           v-click-outside="{handler: closeDialog, include: include}">
                                    <template v-slot:activator="{ }">
                                        <v-btn @click="supFormats = !supFormats" elevation="0"
                                               class="mr-1 squareBtn included">
                                            <img :src="infoIcon" class="iconSize">
                                        </v-btn>
                                    </template>

                                    <span>Unterstützte Formate:</span>
                                    <div class="d-flex align-center"><img :src="mikrofonIcon"
                                                                          class="tinyIcon iconToWhite mr-1"><span>Audio: AAC, MP3, M4A, OGG, WAV, WMA</span>
                                    </div>
                                    <div class="d-flex align-center"><img :src="kameraIcon"
                                                                          class="tinyIcon iconToWhite mr-1"><span>Bilder/Texte: BMP, JPG, PDF, PNG, TIFF</span>
                                    </div>
                                    <div class="d-flex align-center"><img :src="videoIcon"
                                                                          class="tinyIcon iconToWhite mr-1"><span>Video: FLV, MOV, MPG, MP4, M4V, WEBM, WMV</span>
                                    </div>
                                    <div class="d-flex align-center"><img :src="exclamationIcon"
                                                                        class="tinyIcon iconToWhite mr-1"><span style="line-height: 16px">MS Office Dateien (z.B. DOCX, PPTX, XLSX) müssen heruntergeladen und lokal bearbeitet werden</span>
                                    </div>
                                </v-tooltip>

                                <v-btn
                                    @click="clickTeacherUploadButton"
                                    style="color: #707070" class="text-capitalize" elevation="0"
                                    :style="windowWidth > 500 ? 'padding-left: 30px; padding-right: 30px;' : ''"
                                >
                                    <img v-if="windowWidth > 360" :src="hochladenIcon" class="mr-2 iconSize" alt=""/>
                                    Hochladen
                                </v-btn>

                                <v-menu v-if="true" offset-y bottom nudge-left="180%">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-on="on" v-bind="attrs"
                                            elevation="0" class="squareBtn">
                                            <img :src="arrowDown" class="iconSize" alt="Pfeil nach unten Symbol">
                                        </v-btn>
                                    </template>

                                    <div
                                        id="uploadFromFileWidgetMenu"
                                        >
                                        <v-btn
                                            tabindex="0"
                                            @click="() => {this.fileWidgetUploadDialog = true; this.requestFilesFromFileWidget()}"
                                            style="text-transform: inherit; color: #707070; width: 100%"
                                            text
                                        >
                                            <img :src="dateiIcon" class="iconSize mr-1">
                                            Hochladen per Dateiarchiv</v-btn>
                                        <v-btn tabindex="0"
                                               style="text-transform: inherit; color: #707070; width: 100%;"
                                               text
                                               class="d-flex justify-start"
                                               @click="openEmptyEdit('blank')">
                                            <img :src="leeresBlatt" class="mr-2"
                                                 style="height: 20px;"/>
                                            Leeres Blatt
                                        </v-btn>
                                        <v-btn tabindex="0"
                                               style="text-transform: inherit; color: #707070; width: 100%;"
                                               text class="d-flex justify-start"
                                               @click="openEmptyEdit('kariert')">
                                            <img :src="kariertesBlatt" class="mr-2"
                                                 style="height: 20px;"/>
                                            Kariertes Blatt
                                        </v-btn>
                                        <v-btn tabindex="0"
                                               style="text-transform: inherit; color: #707070; width: 100%;"
                                               text class="d-flex justify-start"
                                               @click="openEmptyEdit('liniert')">
                                            <img :src="liniertesBlatt" class="mr-2"
                                                 style="height: 20px;"/>
                                            Liniertes Blatt
                                        </v-btn>
                                        <v-btn tabindex="0"
                                               style="text-transform: inherit; color: #707070; width: 100%;"
                                               text class="d-flex justify-start"
                                               @click="openEmptyEdit('haus')">
                                            <img :src="liniertesBlatt" class="mr-2"
                                                 style="height: 20px;"/>
                                            Hauslinien Blatt
                                        </v-btn>
                                    </div>
                                </v-menu>

                                <AudioUpload :upload="uploadFile" :appointmentId="thisAppointment._id" :teacher="true"
                                             :group="thisAppointment.group"></AudioUpload>
                            </div>
                        </div>

                        <hr class="eHr"/>

                        <div class="mx-0">
                            <div class="mt-2">
                                <v-row class="mb-2">
                                    <v-col class="font-weight-bold pb-0 mx-5 pt-0 d-flex align-center">
                                        <img :src="abgabenIcon"
                                             style="height: 20px;"
                                             alt="Abgabensymbol"/>
                                        <p class="pl-2 ma-0">Abgaben</p>
                                    </v-col>
                                </v-row>

                                <div>
                                    <!-- if atleast one pupil has uploaded homework -->
                                    <div v-if="thisAppointment.pupilUpload.length > 0 || isCorrectionAvailable(thisAppointment.pupilUpload)">

                                        <!-- Zu korrigieren-section -->
                                        <v-row class="mb-2">
                                            <v-col class="font-weight-bold pb-0 mx-5 pt-0 d-flex align-center">
                                                <img :src="offenIcon"
                                                     style="height: 20px;"
                                                     alt="Zu Korrigieren Symbol"
                                                />
                                                <p class="pl-2 ma-0">Zu korrigieren</p>
                                            </v-col>
                                        </v-row>

                                        <v-row dense class="ma-0 fileUploadPlaceholderText"
                                               align-content="center"
                                               v-if="!pupilUploadsToCorrect.length">
                                            <p class="mb-0">Aktuell sind keine Aufgaben bereit zum korrigieren.</p>
                                        </v-row>

                                        <!-- up to two items if collapsed -->
                                        <div class="toCorrectShort">
                                            <div
                                                v-for="(el, index) in pupilUploadsToCorrect.slice(0,2)"
                                                :key="el + index"
                                                :class="{ brighterToCorrectRow: index % 2 === 1}"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(128, 128, 128, 0.25)"
                                            >
                                                <div class="fileName" style="min-width: 60%; width: 60%;"
                                                     @click="clickDownloadPupilUpload(el)">
                                                    {{ el.title }}
                                                </div>
                                                <div class="d-flex align-center">
                                                    <div class="pa-0">
                                                        <v-badge
                                                            color="red"
                                                            content="1"
                                                            :value="!el.seenByMe"
                                                            overlap
                                                            offset-x="45"
                                                            style="z-index: 9"
                                                        >
                                                            <v-btn
                                                                v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                                dark @click="() => {playPupil(el, index); menu = false}"
                                                                x-small class="btnStyle" elevation="0"
                                                                style="background-color: #8CBD46;">
                                                                <img :src="rechtsIcon" alt="Video abspielen Symbol"
                                                                     style="height: 20px; filter: brightness(1000%)"/>
                                                            </v-btn>

                                                            <v-btn
                                                                v-else-if="['wav', 'mp3', 'wma', 'm4a' , 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                                                dark
                                                                @click="() => {playAudioPupil(el, index); menu = false}"
                                                                x-small class="btnStyle" elevation="0"
                                                                style="background-color: #8CBD46;">
                                                                <img :src="rechtsIcon" alt="Audio abspielen Symbol"
                                                                     style="height: 20px; filter: brightness(1000%)"/>
                                                            </v-btn>

                                                            <v-btn
                                                                v-else-if="!el.seenByMe && ['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                                @click="() => openEdit(el.title, el.file, el._id)"
                                                                x-small
                                                                style="background-color: #8CBD46;" class="btnStyle"
                                                                elevation="0">
                                                                <img :src="stiftIcon" alt="Bearbeiten Symbol"
                                                                     style="height: 20px;"/>
                                                            </v-btn>

                                                            <v-btn
                                                                v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                                @click="() => openEdit(el.title, el.file, el._id)"
                                                                x-small
                                                                class="btnStyle" elevation="0">
                                                                <img :src="stiftIcon" alt="Bearbeiten Symbol"
                                                                     style="height: 20px;"/>
                                                            </v-btn>

                                                            <v-btn
                                                                v-else
                                                                dark @click="fileNotSupported(el)" x-small
                                                                class="btnStyle" elevation="0"
                                                                style="background-color: #8CBD46;">
                                                                <img :src="infoIcon" alt="Informationssymbol"
                                                                     style="height: 20px; filter: brightness(1000%)"/>
                                                            </v-btn>
                                                        </v-badge>
                                                    </div>

                                                    <div class="pa-0 ml-2">
                                                        <!-- teacher did correction that can be downloaded -->
                                                        <v-btn v-if="isCorrectionAvailable(el)" @click="() => clickDownloadPupilCorrection(el)"
                                                               x-small class="btnStyle whiteBg" elevation="0">
                                                            <img :src="bearbeitetIcon" style="height: 20px;"
                                                                 alt="Korrektur herunterladen Symbol"/>
                                                        </v-btn>

                                                        <!-- teacher hasn't done correction -->
                                                        <v-btn v-else @click="clickDownloadPupilUpload(el)"
                                                               elevation="0" x-small class="btnStyle whiteBg">
                                                            <img :src="offenIcon" style="height: 20px;"
                                                                 alt="Korrektur ausstehend Symbol"/>
                                                        </v-btn>
                                                    </div>

                                                    <div class="pa-0 ml-2">
                                                        <v-btn @click="deleteDialog = true; elementToDelete = el" class="btnStyle whiteBg"
                                                               x-small elevation="0">
                                                            <img :src="papierkorbIcon" style="height: 20px;"/>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- all items if expanded -->
                                        <div class="toCorrectLong">
                                            <div
                                                v-for="(el, index) in pupilUploadsToCorrect"
                                                :key="el + index"
                                                :class="{ brighterToCorrectRow: index % 2 === 1}"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(128, 128, 128, 0.25)"
                                            >
                                                <div class="fileName" style="min-width: 60%; width: 60%;"
                                                     @click="clickDownloadPupilUpload(el)">
                                                    {{ el.title }}
                                                </div>
                                                <div class="d-flex">
                                                    <div class="pa-0">
                                                        <v-badge
                                                            color="red"
                                                            content="1"
                                                            :value="!el.seenByMe"
                                                            overlap
                                                            offset-x="45"
                                                            style="z-index: 9"
                                                        >
                                                            <v-btn
                                                                v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                                dark @click="() => {playPupil(el, index); menu = false}"
                                                                x-small class="btnStyle" elevation="0"
                                                                style="background-color: #8CBD46;">
                                                                <img :src="rechtsIcon" alt="Video abspielen Symbol"
                                                                     style="height: 20px; filter: brightness(1000%)"/>
                                                            </v-btn>

                                                            <v-btn
                                                                v-else-if="['wav', 'mp3', 'wma', 'm4a' , 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                                                dark
                                                                @click="() => {playAudioPupil(el, index); menu = false}"
                                                                x-small class="btnStyle" elevation="0"
                                                                style="background-color: #8CBD46;">
                                                                <img :src="rechtsIcon" alt="Audio abspielen Symbol"
                                                                     style="height: 20px; filter: brightness(1000%)"/>
                                                            </v-btn>

                                                            <v-btn
                                                                v-else-if="!el.seenByMe && ['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                                @click="() => openEdit(el.title, el.file, el._id)"
                                                                x-small
                                                                style="background-color: #8CBD46;" class="btnStyle"
                                                                elevation="0">
                                                                <img :src="stiftIcon" alt="Bearbeiten Symbol"
                                                                     style="height: 20px;"/>
                                                            </v-btn>

                                                            <v-btn
                                                                v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                                @click="() => openEdit(el.title, el.file, el._id)"
                                                                x-small
                                                                class="btnStyle" elevation="0">
                                                                <img :src="stiftIcon" alt="Bearbeiten Symbol"
                                                                     style="height: 20px;"/>
                                                            </v-btn>

                                                            <v-btn
                                                                v-else
                                                                dark @click="fileNotSupported(el)" x-small
                                                                class="btnStyle" elevation="0"
                                                                style="background-color: #8CBD46;">
                                                                <img :src="infoIcon" alt="Informationssymbol"
                                                                     style="height: 20px; filter: brightness(1000%)"/>
                                                            </v-btn>
                                                        </v-badge>
                                                    </div>
                                                    <div class="pa-0 ml-2">
                                                        <!-- teacher did correction that can be downloaded -->
                                                        <v-btn v-if="isCorrectionAvailable(el)"
                                                               @click="() => clickDownloadPupilCorrection(el)" x-small
                                                               class="btnStyle whiteBg" elevation="0">
                                                            <img :src="bearbeitetIcon" style="height: 20px;"
                                                                 alt="Korrektur herunterladen Symbol"/>
                                                        </v-btn>
                                                        <!-- teacher hasn't done correction -->
                                                        <v-btn v-else elevation="0"
                                                               @click="clickDownloadPupilUpload(el)" x-small
                                                               class="btnStyle whiteBg">
                                                            <img :src="offenIcon" style="height: 20px;"
                                                                 alt="Korrektur ausstehend Symbol"/>
                                                        </v-btn>
                                                    </div>
                                                    <div class="pa-0 ml-2">
                                                        <v-btn @click="deleteDialog = true; elementToDelete = el" class="btnStyle whiteBg"
                                                               x-small elevation="0">
                                                            <img :src="papierkorbIcon" style="height: 20px;"/>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!--expand and collapse btns-->
                                        <div v-if="thisAppointment.pupilUpload.length > 2"
                                             class="d-flex justify-center mt-2">
                                            <v-btn @click="hideEl('toCorrectShort'); showEl('toCorrectLong')"
                                                   class="toCorrectShort" small elevation="0">
                                                <img :src="arrowDown" style="height: 20px;"/>
                                            </v-btn>

                                            <v-btn @click="hideEl('toCorrectLong'); showEl('toCorrectShort')"
                                                   class="toCorrectLong" small elevation="0">
                                                <img :src="arrowDown" style="height: 20px; transform: rotate(180deg)"/>
                                            </v-btn>
                                        </div>

                                        <!--Nicht abgegeben-section-->
                                        <v-row class="mb-2">
                                            <v-col class="font-weight-bold pb-0 mx-5 d-flex align-center">
                                                <img :src="keinDocument"
                                                     style="height: 20px;"
                                                     alt="Nicht abgegeben Symbol"
                                                />
                                                <p class="pl-2 ma-0">Nicht abgegeben</p>
                                            </v-col>
                                        </v-row>

                                        <!--up to two items if collapsed-->
                                        <div class="toDoShort">
                                            <div
                                                v-for="(el, index) in missingAppointments.slice(0,2)"
                                                :key="el + index"
                                                :class="{ brighterToDoRow: index % 2 === 1}"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(230, 35, 30, 0.25)"
                                            >
                                                <div class="fileName">
                                                    {{ el.name }} {{ el.lastName }}
                                                </div>
                                                <!--Schüler haben noch keine Abgabe abgegeben-->
                                                <div style="display: inline-block">
                                                    <v-btn elevation="0" x-small class="btnStyle" color="rgba(0,0,0,0)">
                                                        <img :src="keinDocument" alt="Keine Abgaben Symbol"
                                                             style="height: 20px;"/>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </div>

                                        <!--all items if expanded-->
                                        <div class="toDoLong">
                                            <div
                                                v-for="(el, index) in missingAppointments"
                                                :key="el + index"
                                                :class="{ brighterToDoRow: index % 2 === 1}"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(230, 35, 30, 0.25)"
                                            >
                                                <div class="fileName">
                                                    {{ el.name }} {{ el.lastName }}
                                                </div>
                                                <!--Schüler haben noch keine Abgabe abgegeben-->
                                                <div style="display: inline-block">
                                                    <v-btn elevation="0" x-small class="btnStyle" color="rgba(0,0,0,0)">
                                                        <img :src="keinDocument" alt="Keine Abgaben Symbol"
                                                             style="height: 20px;"/>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </div>

                                        <!--expand and collapse btns-->
                                        <div v-if="missingAppointments.length > 2" class="d-flex justify-center mt-2">
                                            <v-btn @click="hideEl('toDoShort'); showEl('toDoLong')" class="toDoShort"
                                                   small elevation="0">
                                                <img :src="arrowDown" alt="Pfeil nach unten Symbol"
                                                     style="height: 20px;"/>
                                            </v-btn>

                                            <v-btn @click="hideEl('toDoLong'); showEl('toDoShort')" class="toDoLong"
                                                   small elevation="0">
                                                <img :src="arrowDown" alt="Pfeil nach oben Symbol"
                                                     style="height: 20px; transform: rotate(180deg)"/>
                                            </v-btn>
                                        </div>

                                        <!--Korrigiert-section-->
                                        <v-row class="mb-2">
                                            <v-col class="font-weight-bold pb-0 mx-5 d-flex align-center">
                                                <img :src="bearbeitetIcon"
                                                     style="height: 20px;"
                                                     alt="Korrigiert Symbol"
                                                />
                                                <p class="pl-2 ma-0">Korrigiert</p>
                                            </v-col>
                                        </v-row>

                                        <v-row dense class="ma-0 fileUploadPlaceholderText"
                                               align-content="center"
                                               v-if="!pupilUploadsCorrected.length">
                                            <p class="mb-0">Noch ist nichts korrigiert.</p>
                                        </v-row>

                                        <div class="correctedShort">
                                            <div
                                                v-for="(el, index) in pupilUploadsCorrected.slice(0,2)"
                                                :key="el + index"
                                                :class="{ brighterCorrectedRow: index % 2 === 1}"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                @click="() => clickDownloadPupilCorrection(el)"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(60, 170, 105, 0.25)"
                                            >
                                                <div class="fileName" style="width: 75%; min-width: 75%">
                                                    {{ el.title }}
                                                </div>
                                                <div class="pa-0 ml-2">
                                                    <div style="display: inline-block">
                                                        <v-btn
                                                            v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                            dark @click="() => { snackbarFileNotSupported = true; }"
                                                            x-small class="btnStyle" elevation="0"
                                                            style="background-color: #8CBD46;">
                                                            <img :src="infoIcon" alt="Informationssymbol"
                                                                 style="height: 20px; filter: brightness(1000%)"/>
                                                        </v-btn>
                                                        <v-btn v-else
                                                               @click="(event) => {event.stopPropagation(); handleOpenAnsichtClick(el)}"
                                                               class="btnStyle" x-small elevation="0"
                                                               color="rgba(0,0,0,0)">
                                                            <!--                                                            <img :src="papierkorbIcon" style="height: 20px;"/>-->
                                                            <v-icon style="width: 20px; color: #939393">fas fa-eye
                                                            </v-icon>
                                                        </v-btn>
                                                    </div>
                                                    <div style="display: inline-block">
                                                        <v-btn
                                                            elevation="0" x-small class="btnStyle" color="rgba(0,0,0,0)"
                                                        >
                                                            <img :src="bearbeitetIcon" alt="Bearbeitensymbol"
                                                                 style="height: 20px;"/>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="correctedLong">
                                            <div
                                                v-for="(el, index) in pupilUploadsCorrected"
                                                :key="el + index"
                                                :class="{ brighterCorrectedRow: index % 2 === 1}"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                @click="() => clickDownloadPupilCorrection(el)"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(60, 170, 105, 0.25)"
                                            >
                                                <div class="fileName" style="width: 75%">
                                                    {{ el.title }}
                                                </div>
                                                <div class="pa-0 ml-2">
                                                    <div style="display: inline-block">
                                                        <v-btn
                                                            v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                            dark @click="() => { snackbarFileNotSupported = true; }"
                                                            x-small class="btnStyle" elevation="0"
                                                            style="background-color: #8CBD46;">
                                                            <img :src="infoIcon" alt="Informationssymbol"
                                                                 style="height: 20px; filter: brightness(1000%)"/>
                                                        </v-btn>
                                                        <v-btn v-else
                                                               @click="(event) => {event.stopPropagation(); handleOpenAnsichtClick(el)}"
                                                               class="btnStyle" x-small elevation="0"
                                                               color="rgba(0,0,0,0)">
                                                            <!--                                                            <img :src="papierkorbIcon" style="height: 20px;"/>-->
                                                            <v-icon style="width: 20px; color: #939393">fas fa-eye
                                                            </v-icon>
                                                        </v-btn>
                                                    </div>
                                                    <div style="display: inline-block">
                                                        <v-btn
                                                            @click="() => clickDownloadPupilCorrection(el)"
                                                            elevation="0" x-small class="btnStyle" color="rgba(0,0,0,0)"
                                                        >
                                                            <img :src="bearbeitetIcon" alt="Bearbeitensymbol"
                                                                 style="height: 20px;"/>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!--expand and collapse btns-->
                                        <div v-if="pupilUploadsCorrected.length > 2" class="d-flex justify-center mt-2">
                                            <v-btn @click="hideEl('correctedShort'); showEl('correctedLong')"
                                                   class="correctedShort" small elevation="0">
                                                <img :src="arrowDown" alt="Pfeil nach unten Symbol"
                                                     style="height: 20px;"/>
                                            </v-btn>

                                            <v-btn @click="hideEl('correctedLong'); showEl('correctedShort')"
                                                   class="correctedLong" small elevation="0">
                                                <img :src="arrowDown" alt="Pfeil nach oben Symbol"
                                                     style="height: 20px; transform: rotate(180deg)"/>
                                            </v-btn>
                                        </div>
                                    </div>

                                    <!--there are no pupil uploads to view or correct-->
                                    <div v-else class="mx-5" style="color: #BDBDBD; font-size: medium">
                                        Es hat noch kein Schüler eine Abgabe getätigt.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vue-custom-scrollbar>
                </v-card-text>

                <v-card-actions class="pa-0 ma-0" style="overflow: hidden;">
                    <!--Beitreten-Button in Fach-Popup-->
                    <v-row>
                        <hr class="eHr mt-2"/>
                        <v-col class="text-center">
                            <v-btn @click="clickBeitreten" elevation="0" class="text-capitalize"
                                   style="color: white; background-color: #8CBD46; padding-left: 50px; padding-right: 50px"
                            >
                                <img :src="beitretenKameraIcon" alt="Videochat beitreten Symbol" class="mr-5"
                                     style="height: 20px;"/>
                                Beitreten
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--fach with no text and image-->
        <div v-else class="fach pa-2 white--text"
             style="width: 100%; height: 70.5px !important; display: block; padding-left: 0 !important;">
            <v-row class="ml-2 font-weight-medium">
                <v-col cols="12" class="pa-0 ma-0" style="height: 48px !important;">
                    <div class="pa-3 ma-0 appointmentItem inactiveAppointment">
                        <img class="iconSelectFrei"/>
                        Hohlstunde
                    </div>
                </v-col>
            </v-row>
        </div>

        <!-- pick file to upload from FileWidget -->
        <v-dialog v-model="fileWidgetUploadDialog" max-width="400" style="overflow: hidden;" scrollable>
            <v-card>
                <v-card-title class="greyText d-flex justify-space-between px-5 py-3" style="box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3)">
                    <div>
                        Datei auswählen
                    </div>

                    <v-btn @click="fileWidgetUploadDialog = false;" elevation="2" x-small
                           class="ml-2 pa-0 btnStyle" style="width: 30px; height: 30px">
                        <img :src="schliesenIcon" alt="Schließensymbol" style="height: 20px;"/>
                    </v-btn>
                </v-card-title>

                <v-card-text class="pt-2" style="overflow: hidden;">
                    <div v-if="loadFolders" class="d-flex justify-center">
                        <v-progress-circular indeterminate></v-progress-circular>
                    </div>

                    <vue-custom-scrollbar v-if="!loadFolders" class="scroll-area-fileUpload mb-2" :settings="settings">
                        <span v-if="subjectFolder.length === 0 && privateFolder.length === 0 && teacherFolder.length===0">
                            Das Dateiarchiv enthält keine Dateien
                        </span>

                        <div v-else class="mb-2">
                            <div v-if="privateFolder.length > 0" class="mb-2">
                                <span class="font-weight-bold">Dateien aus dem privaten Ordner</span>

                                <div @click="prepareUploadViaFileWidget(file)" v-for="file in privateFolder" :key="file.index" class="fileDiv d-flex align-center py-2 px-3">
                                    <div class="ellipsis">{{ file.uploadedName }}</div>

                                    <div>
                                        <v-btn
                                            class="squareBtn text-capitalize ml-2" elevation="0"
                                        >
                                            <img :src="hochladenIcon" class="iconSize" alt=""/>
                                        </v-btn>
                                    </div>
                                </div>

                                <div class="d-flex justify-center">
                                    <v-btn v-if="privateFolderFull.length > 2" @click="toggleSlice('private')" class="mt-2" small>
                                        <img :src="arrowDown" class="iconSize" :style="privateFolderSliced ? '' : 'transform: rotate(180deg)'">
                                    </v-btn>
                                </div>
                            </div>

                            <div v-if="subjectFolder.length > 0" class="mb-2">
                                <span class="font-weight-bold">Dateien aus dem allgemeinen Fach-Ordner</span>

                                <div @click="prepareUploadViaFileWidget(file)" v-for="file in subjectFolder" :key="file.index" class="fileDiv d-flex align-center py-2 px-3">
                                    <div class="ellipsis">{{ file.uploadedName }}</div>

                                    <div>
                                        <v-btn
                                            @click="prepareUploadViaFileWidget(file)"
                                            class="squareBtn text-capitalize ml-2" elevation="0"
                                        >
                                            <img :src="hochladenIcon" class="iconSize" alt=""/>
                                        </v-btn>
                                    </div>
                                </div>

                                <div class="d-flex justify-center">
                                    <v-btn v-if="subjectFolderFull.length > 2" @click="toggleSlice('subject')" class="mt-2" small>
                                        <img :src="arrowDown" class="iconSize" :style="subjectFolderSliced ? '' : 'transform: rotate(180deg)'">
                                    </v-btn>
                                </div>
                            </div>

                            <div v-if="teacherFolder.length > 0">
                                <span class="font-weight-bold">Dateien aus dem Lehrer-Ordner</span>

                                <div @click="prepareUploadViaFileWidget(file)" v-for="file in teacherFolder" :key="file.index" class="fileDiv d-flex align-center py-2 px-3">
                                    <div class="ellipsis">{{ file.file.uploadedName }}</div>

                                    <div>
                                        <v-btn
                                            @click="prepareUploadViaFileWidget(file)"
                                            class="squareBtn text-capitalize ml-2" elevation="0"
                                        >
                                            <img :src="hochladenIcon" class="iconSize" alt=""/>
                                        </v-btn>
                                    </div>
                                </div>

                                <div class="d-flex justify-center">
                                    <v-btn v-if="teacherFolderFull.length > 2" @click="toggleSlice()" class="mt-2" small>
                                        <img :src="arrowDown" class="iconSize" :style="teacherFolderSliced ? '' : 'transform: rotate(180deg)'">
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </vue-custom-scrollbar>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- name file and set parameters to upload from FileWidget -->
        <v-dialog v-model="fileWidgetNameDialog" max-width="400" style="overflow: hidden;" scrollable>
            <v-card>
                <v-card-title class="greyText d-flex justify-space-between px-5 py-3" style="box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3)">
                    <div>
                        Daten eingeben
                    </div>

                    <v-btn @click="fileWidgetNameDialog = false;" elevation="2" x-small
                           class="ml-2 pa-0 btnStyle" style="width: 30px; height: 30px">
                        <img :src="schliesenIcon" alt="Schließensymbol" style="height: 20px;"/>
                    </v-btn>
                </v-card-title>

                <v-card-text class="pt-2" style="overflow: hidden;">
                    <v-container class="pa-0">
                        <v-row align="center" justify="center">
                            <v-col>
                                <v-text-field v-model="newFileName" hide-details/>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn
                                    @click="uploadViaFileWidget()"
                                    elevation="2"
                                    x-small
                                    class="pa-0 mb-2"
                                    style="width: 30px; height: 30px"
                                    color="secondary"
                                >
                                    <v-icon x-small dark>fas fa-check</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-row class="mt-0 pt-0" align="center" justify="center">
                            <v-col style="">
                                <v-checkbox
                                    style="margin-top: 0"
                                    v-model="isAssignment"
                                    label="Es handelt sich um eine Abgabe"
                                >
                                </v-checkbox>
                            </v-col>
                        </v-row>

                        <v-row v-if="groupN" class="ma-0" align="center" justify="center" style="width: 100%">
                            <v-dialog
                                ref="dialog"
                                v-model="modal"
                                :return-value.sync="startDate"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="startDate"
                                        label="Startdatum"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="startDate"
                                    scrollable
                                    locale="de-DE"
                                    first-day-of-week="1"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn @click="modal = false" text color="primary">Abbruch</v-btn>
                                    <v-btn @click="$refs.dialog.save(startDate)" text color="primary">Ok</v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-row>

                        <v-row class="ma-0" align="center" justify="center" style="width: 100%">
                            <vue-custom-scrollbar class="scroll-area" :settings="settings">
                                <v-data-table
                                    :items="this.groupN.participants"
                                    disable-pagination
                                    hide-default-footer
                                    fixed-header
                                    class="ma-0"
                                    style="width: 100%"
                                >
                                    <template v-slot:item="row">
                                        <tr>
                                            <td class="pl-0">{{row.item.lastName }}</td>
                                            <td> {{row.item.name}}</td>
                                            <td>
                                                <v-checkbox class="mx-2" rounded :value="row.item.account" v-model="selectedUsers" small light>
                                                </v-checkbox>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </vue-custom-scrollbar>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Upload Video in Video Popup -->
        <UploadVideoInVideoPopup :on-close="onUploadVideoDone" ref="uploadVideoInVideo">

        </UploadVideoInVideoPopup>

        <!-- popups, overlays and snackbars -->
        <v-dialog v-model="deleteDialog" max-width="350px">
            <v-card>
                <v-card-title>
                    Löschen bestätigen
                </v-card-title>

                <v-card-text>
                    Wollen Sie die Datei wirklich <strong>unwiderruflich</strong> löschen?
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn @click="clickDeleteUpload" class="mr-2 text-capitalize" color="rot" dark>
                        <img :src="papierkorbIcon" style="height: 20px; filter: brightness(1000%)" class="mr-2">
                        Löschen
                    </v-btn>

                    <v-btn @click="deleteDialog = false" class="mr-2 text-capitalize" color="gruen" dark>
                        <img :src="schliesenIcon" style="height: 20px; filter: brightness(1000%)" class="mr-2">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar bottom v-model="snackbar" color="error" timeout="3000">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbar = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="schliesenIcon" />
            </v-btn>
          </template>
            {{ snackbarText }}
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarSave" color="success" timeout="3000">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbarSave = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="schliesenIcon" />
            </v-btn>
          </template>
            Gespeichert!
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarDelete" :color="snackbarDeleteColor" timeout="3000">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbarDelete = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="schliesenIcon" />
            </v-btn>
          </template>
            {{ snackbarDeleteText }}
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarFileNotSupported" color="grey" timeout="5000"
                    style="z-index: 9999 !important">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbarFileNotSupported = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="schliesenIcon" />
            </v-btn>
          </template>
            Dieses Dateiformat kann in eKlara nicht bearbeitet werden. Bitte lade die Datei herunter.
        </v-snackbar>

        <!--
        <v-overlay opacity="0.95" v-if="videoUrl" z-index="95">
            <v-icon large style="position: fixed; top: 50px; right: 50px; z-index: 99" @click="videoUrl = false" dark>
                fas fa-times
            </v-icon>
            <video :src="videoUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"
                   :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">
                Your browser does not support the video tag.
            </video>
        </v-overlay>
        -->

        <!--
        <v-overlay opacity="0.95" v-if="primaryVideoUrl && secondaryVideoUrl" z-index="95">
            <v-icon large style="position: fixed; top: 50px; right: 50px; z-index: 99" @click="() => { primaryVideoUrl = null; secondaryVideoUrl = null }" dark>
                fas fa-times
            </v-icon>
            <video-in-video :primary-video-src="primaryVideoUrl" :secondary-video-src="secondaryVideoUrl"></video-in-video>
        </v-overlay>
        -->


        <!-- Normal Video Popup -->
        <v-dialog
            :value="videoUrl && (subtitleURL || noSubtitle)"
            v-if="videoUrl && (subtitleURL || noSubtitle)"
            overlay-opacity=".9"
            overlay-color="black"
            max-width="90%"
            width="unset"
            @click:outside="videoUrl = null; $refs.normalVideo.stop();"
        >
            <v-row justify="end" align="end">
                <v-col cols="1" align-self="end">
                    <v-icon large
                            @click="videoUrl = null; primaryVideoUrl = null; secondaryVideoUrl = null; $refs.normalVideo.stop(); subtitleURL = null; noSubtitle = false"
                            dark>
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <!--                    <video ref="normalVideo" :src="videoUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"-->
                    <!--                           :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">-->
                    <!--                        Your browser does not support the video tag.-->
                    <!--                    </video>-->
                    <video-in-video ref="normalVideo" :primary-video-src="videoUrl" :subtitle-src="subtitleURL"></video-in-video>
                </v-col>
            </v-row>
        </v-dialog>

        <!-- Video in Video Popup -->
        <v-dialog
            :value="primaryVideoUrl && secondaryVideoUrl  && (subtitleURL || noSubtitle)"
            overlay-opacity=".9"
            v-if="primaryVideoUrl && secondaryVideoUrl &&  (subtitleURL || noSubtitle)"
            overlay-color="black"
            max-width="90%"
            width="unset"
            @click:outside="primaryVideoUrl = null; secondaryVideoUrl = null; $refs.videoInVideo.stop(); subtitleURL = null; noSubtitle = false"
        >
            <v-row justify="end" align="end">
                <v-col cols="1" align-self="end">
                    <v-icon large @click="primaryVideoUrl = null; secondaryVideoUrl = null; $refs.videoInVideo.stop(); subtitleURL = null; noSubtitle = false"
                            dark>
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <video-in-video ref="videoInVideo" :primary-video-src="primaryVideoUrl"
                                    :secondary-video-src="secondaryVideoUrl" :subtitle-src="subtitleURL"></video-in-video>
                </v-col>
            </v-row>
        </v-dialog>

        <v-overlay opacity="0.8" v-if="audioUrl" z-index="95">
            <v-icon large style="position: fixed; top: 50px; right: 50px; z-index: 99" @click="audioUrl = false" dark>
                fas fa-times
            </v-icon>
            <audio :src="audioUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"
                   :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">
                Your browser does not support the video tag.
            </audio>
        </v-overlay>
    </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import * as backend from "../../api/backend";

import beitretenKameraIcon from "../../assets/Icons/kamera-video-weiss-72.svg";
import schliesenIcon from "../../assets/Icons/abbrechen-08.svg";
import buchzeichenIcon from "../../assets/Icons/lesezeichen-01-43.svg";
import speichernIcon from "../../assets/Icons/speichern-64.svg";
import lehrerIcon from "../../assets/Icons/lehrer-24.svg";
import abgabenIcon from "../../assets/Icons/bearbeiten-16.svg";
import stiftIcon from "../../assets/Icons/bearbeiten-komplimentär-weiß-88.svg";
import bearbeitenIcon from "../../assets/Icons/bearbeiten-normal-41.svg";
import bearbeitetIcon from "../../assets/Icons/bearbeitet-17.svg";
import runterladenIcon from "../../assets/Icons/runterladen-14.svg";
import hochladenIcon from "../../assets/Icons/hochladen-15.svg";
import rechtsIcon from "../../assets/Icons/rechts-11.svg";
import lesezeichenIcon from "../../assets/Icons/lesezeichen-01-43.svg";
import offenIcon from "../../assets/Icons/offen-18.svg";
import keinDocument from '../../assets/Icons/kein-dokument-68.svg';
import uhrIcon from '../../assets/Icons/uhr-04.svg';
import toDoIcon from '../../assets/Icons/noch-zu-machen-86.svg';
import doneIcon from '../../assets/Icons/erledigt-87.svg';
import correctedIcon from '../../assets/Icons/korrigiert-88.svg';
import materialIcon from '../../assets/Icons/material-93.svg';
import arrowDown from '../../assets/Icons/unten-dropdown-filled-12.svg';
import paperclipIcon from '../../assets/Icons/Bueroklammer-94.svg';
import papierkorbIcon from '../../assets/Icons/papierkorb-110.svg';
import infoIcon from '../../assets/Icons/info-45.svg';
import mikrofonIcon from '../../assets/Icons/mikrofon-22.svg';
import kameraIcon from '../../assets/Icons/kamera-21.svg';
import videoIcon from '../../assets/Icons/kamera-video-73.svg';
import dateiIcon from '../../assets/Icons/leere-korrektur-78.svg';
import exclamationIcon from '../../assets/Icons/ausrufezeichen-62.svg';

import bioIcon from "../../assets/Icons/f-biologie-35.svg";
import chemieIcon from "../../assets/Icons/f-chemie-37.svg";
import deutschIcon from "../../assets/Icons/f-deutsch-29.svg";
import englischIcon from "../../assets/Icons/f-englisch-30.svg";
import erdkundeIcon from "../../assets/Icons/f-erdkunde-31.svg";
import geschichteIcon from "../../assets/Icons/f-geschichte-40.svg";
import matheIcon from "../../assets/Icons/f-mathe-32.svg";
import musikIcon from '../../assets/Icons/f-musik-84.svg'
import physikIcon from "../../assets/Icons/f-physik-36.svg";
import politikIcon from "../../assets/Icons/f-politik-34.svg";
import religionIcon from "../../assets/Icons/f-religion-39.svg";
import sportIcon from "../../assets/Icons/f-sport-38.svg";
import wirtschaftIcon from "../../assets/Icons/f-wirtschaft-33.svg";

import bioIconWeiss from "../../assets/Icons/f-biologie-weiss-35.svg";
import chemieIconWeiss from "../../assets/Icons/f-chemie-weiss-37.svg";
import deutschIconWeiss from "../../assets/Icons/f-deutsch-weiss-29.svg";
import englischIconWeiss from "../../assets/Icons/f-englisch-weiss-30.svg";
import erdkundeIconWeiss from "../../assets/Icons/f-erdkunde-weiss-31.svg";
import geschichteIconWeiss from "../../assets/Icons/f-geschichte-weiss-40.svg";
import matheIconWeiss from "../../assets/Icons/f-mathe-weiss-32.svg";
import musikIconWeiss from "@/assets/Icons/f-musik-weiss-82.svg";
import physikIconWeiss from "../../assets/Icons/f-physik-weiss-36.svg";
import politikIconWeiss from "../../assets/Icons/f-politik-weiss-34.svg";
import religionIconWeiss from "../../assets/Icons/f-religion-weiss-39.svg";
import sportIconWeiss from "../../assets/Icons/f-sport-weiss-38.svg";
import wirtschaftIconWeiss from "../../assets/Icons/f-wirtschaft-weiss-33.svg";

import colorfcd20a from "../../assets/Icons/lesezeichen-gelb-47.svg";
import colorf0b423 from "../../assets/Icons/lesezeichen-orange-48.svg";
import colorff6941 from "../../assets/Icons/lesezeichen-rot-49.svg";
import color91c364 from "../../assets/Icons/lesezeichen-hellgrün-50.svg";
import color91c887 from "../../assets/Icons/lesezeichen-mintgrün-51.svg";
import colord7875f from "../../assets/Icons/lesezeichen-braun-52.svg";
import colorebaabe from "../../assets/Icons/lesezeichen-rosa-53.svg";
import color9b91c8 from "../../assets/Icons/lesezeichen-lila-54.svg";
import color6ec3dc from "../../assets/Icons/lesezeichen-blau-55.svg";
import color9bcdc3 from "../../assets/Icons/lesezeichen-minze-56.svg";
import colorb2b2b2 from "../../assets/Icons/lesezeichen-hellgrau-43.svg";
import color707070 from "../../assets/Icons/lesezeichen-dunkelgrau-44.svg";

import {mapState, mapGetters, mapActions} from "vuex";
import ProgressBar from "@/components/ProgressBar";
import AudioUpload from "../AudioUpload";
import UploadVideoInVideoPopup from "@/components/Lehrer/UploadVideoInVideoPopup";
import VideoInVideo from "@/components/Utils/VideoInVideo";
import AdvancedQuillEditor from "@/components/Utils/AdvancedQuillEditor";
import leeresBlatt from "@/assets/Icons/leeres-blatt-110.svg";
import kariertesBlatt from "@/assets/Icons/kariertes blatt-112.svg";
import liniertesBlatt from "@/assets/Icons/liniertes-blatt-111.svg";

export default {
    name: "Fach",
    components: {
        AdvancedQuillEditor,
        VideoInVideo,
        UploadVideoInVideoPopup,
        AudioUpload,
        ProgressBar,
        vueCustomScrollbar,
    },
    data() {
        return {
            subtitleURL: null,
            noSubtitle: false,
            urls: [],
            windowWidth: window.innerWidth,
            missingAppointments: [],
            menu: false,
            thisAppointment: null,
            snackbar: false,
            snackbarText: "",
            snackbarSave: false,
            snackbarDelete: false,
            snackbarDeleteText: '',
            snackbarDeleteColor: 'success',
            snackbarFileNotSupported: false,
            update: false,
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },
            showTeacherUploadProgress: false,
            teacherUploadProgress: 0.0,
            teacherXmlHttpRequest: null,
            showNameFile: false,
            uploadFileName: "",
            fileNameCallback: () => {
            },
            group: null,
            bbbTutState: null,
            bbbTut: false,
            deleteDialog: false,
            elementToDelete: null,

            teacherMaterials: 0,

            primaryVideoUrl: null,
            secondaryVideoUrl: null,
            videoUrl: null,
            audioUrl: null,

            groupName: '',
            groupMembers: '',
            uploadingVideoInVideo: false,

            supFormats: false,      //supported formats tooltip next to Hochladen-btn

            //upload files from FileWidget
            me: null,
            fileWidgetUploadDialog: false,
            privateFolderFull: [],  //Full are the complete arrays
            subjectFolderFull: [],
            teacherFolderFull: [],
            privateFolder: [],      //these are the arrays that are being displayed
            subjectFolder: [],      //and thus they may be sliced to show only
            teacherFolder: [],      //a few elements
            privateFolderSliced: true,
            subjectFolderSliced: true,  //reference if folder-array is sliced currently
            teacherFolderSliced: true,  //to toggle between full and sliced array
            loadFolders: false,

            fileWidgetNameDialog: false,
            modal: false,
            fileToUpload: '',
            groupN: '',
            newFileName: '',
            selectedUsers: [],
            startDate: new Date().toISOString().substr(0, 10),
            isAssignment: null,

            beitretenKameraIcon,
            schliesenIcon,
            buchzeichenIcon,
            speichernIcon,
            lehrerIcon,
            abgabenIcon,
            stiftIcon,
            bearbeitenIcon,
            bearbeitetIcon,
            runterladenIcon,
            hochladenIcon,
            rechtsIcon,
            lesezeichenIcon,
            offenIcon,
            keinDocument,
            uhrIcon,
            toDoIcon,
            doneIcon,
            correctedIcon,
            materialIcon,
            arrowDown,
            paperclipIcon,
            papierkorbIcon,
            infoIcon,
            mikrofonIcon,
            kameraIcon,
            videoIcon,
            dateiIcon,
            exclamationIcon,

            leeresBlatt,
            kariertesBlatt,
            liniertesBlatt,

            bioIcon,
            chemieIcon,
            deutschIcon,
            englischIcon,
            erdkundeIcon,
            geschichteIcon,
            matheIcon,
            musikIcon,
            physikIcon,
            politikIcon,
            religionIcon,
            sportIcon,
            wirtschaftIcon,

            bioIconWeiss,
            chemieIconWeiss,
            deutschIconWeiss,
            englischIconWeiss,
            erdkundeIconWeiss,
            geschichteIconWeiss,
            matheIconWeiss,
            musikIconWeiss,
            physikIconWeiss,
            politikIconWeiss,
            religionIconWeiss,
            sportIconWeiss,
            wirtschaftIconWeiss,

            colorfcd20a,
            colorf0b423,
            colorff6941,
            color91c364,
            color91c887,
            colord7875f,
            colorebaabe,
            color9b91c8,
            color6ec3dc,
            color9bcdc3,
            colorb2b2b2,
            color707070,
        };
    },
    props: {
        appointment: {required: false, default: null},
        requestAppointments: {required: true},
        isRunning: {type: Boolean, required: false, default: false},
        isOpen: {type: Boolean, required: false, default: false},
    },
    watch: {
        appointment() {
            this.thisAppointment = {...this.appointment};
            this.loadImagePreviews();
        },
        async menu(newVal, oldVal) {
            if (newVal && !oldVal) {
                await this.getGroupName();
            }
        },
        isOpen() {
            if (!this.menu) {
                this.menu = this.isOpen;
                this.getMissingMembers();
                this.$router.replace({
                    query: {
                        appointment: undefined,
                    },
                });
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });

        this.checkTutorialState();
        this.thisAppointment = JSON.parse(JSON.stringify(this.appointment));

        this.reloadDontLogout(false);
        this.getGroupName();

        this.loadImagePreviews();
        if (this.isOpen) {
            if (!this.menu) {
                this.menu = this.isOpen;
                this.getMissingMembers();
                this.$router.replace({
                    query: {
                        appointment: undefined,
                    },
                });
            }
        }
    },
    computed: {
        ...mapState("auth", ["token"]),
        ...mapGetters('util', ['currentlyOpenAppointment']),
        pupilUploadsToCorrect() {
            return this.thisAppointment.pupilUpload.filter(item => !item.editLeafs || item.editLeafs.length === 0);
        },
        pupilUploadsCorrected() {
            return this.thisAppointment.pupilUpload.filter(item => item.editLeafs && item.editLeafs.length > 0);
        },
    },
    methods: {
        ...mapActions('appointments', ['getAppointments', 'editAppointment', 'deletePupilUpload', 'createTeacherUploadViaFileId']),
        ...mapActions("auth", ["reloadDontLogout"]),
        ...mapActions("teachers", ["deleteUpload", "getMeTeacher"]),
        ...mapActions("util", ['toggleLoading', 'toggleOpenAppointment', 'toggleCurrentUploadGroup']),
        ...mapActions('files', ['setSnackbar']),
        ...mapActions("groups", ["getGroupByAppointment"]),
        ...mapActions("privateFolder", ["getPrivateFolder"]),
        ...mapActions("teacherFolder", ["getTeacherFolder"]),
        ...mapActions("subjectFolder", ["getSubjectFolder"]),

        onResize () {
            this.windowWidth = window.innerWidth;
        },

        async loadImagePreviews() {
            if (this.thisAppointment && this.thisAppointment.teacherUploads) {
                let counter = 0; //counting to see if we arrived at last file
                this.thisAppointment.teacherUploads.forEach(async (file, index) => {
                    try{
                        let res;
                        if(file.title.endsWith('.pdf')) // Fetch the pdf file
                            res = await backend.getTeacherUpload(this.thisAppointment._id, file.file);
                        else    // Fetch the thumbnail
                            res = await backend.getTeacherUploadThumbnail(this.thisAppointment._id, file.file);
                        if(res.ok && res.status === 200){
                            let blob = await res.blob();
                            this.urls[file._id] = window.URL.createObjectURL(blob);
                        }
                        counter++; //counting up here, because not all files have a preview

                        //if iterated through all teacherUploads force re-render to show previews after reopening appointment
                        if(counter === this.thisAppointment.teacherUploads.length && this.thisAppointment['open'] === true) {
                            this.$forceUpdate();
                        }
                    }catch(e) {
                        console.log("ERROR");
                        console.error(e);
                    }
                });
            }
        },
        async play(file) {
            console.log(file)
            file.seenByMe = true;
            let pipFile = null;
            if(file.subtitle) {
                const subtitle = await backend.getSubtitle(file.subtitle);
                this.subtitleURL = window.URL.createObjectURL(await subtitle.blob());
            } else {
                this.noSubtitle = true;
            }
            if (file.pipReference)
                pipFile = this.thisAppointment.teacherUploads.find(value => value._id === file.pipReference);
            if (pipFile) {
                // PiP Video
                const primary = await backend.getTeacherUpload(this.thisAppointment._id, file.file);
                const secondary = await backend.getTeacherUpload(this.thisAppointment._id, pipFile.file);
                this.primaryVideoUrl = window.URL.createObjectURL(await primary.blob());
                this.secondaryVideoUrl = window.URL.createObjectURL(await secondary.blob());
            } else {
                const res = await backend.getTeacherUpload(this.thisAppointment._id, file.file);
                this.videoUrl = window.URL.createObjectURL(await res.blob());
            }
        },

        async playPupil(file) {
            file.seenByMe = true;
            const res = await backend.getPupilUpload(this.thisAppointment._id, file.file);
            this.videoUrl = window.URL.createObjectURL(await res.blob());
        },

        async playAudio(file) {
            file.seenByMe = true;
            const res = await backend.getTeacherUpload(this.thisAppointment._id, file.file);
            this.audioUrl = window.URL.createObjectURL(await res.blob());
        },

        async playAudioPupil(file) {
            file.seenByMe = true;
            const res = await backend.getPupilUpload(this.thisAppointment._id, file.file);
            this.audioUrl = window.URL.createObjectURL(await res.blob());
        },
        showUploadVideoInVideoPopup(file) {
            this.$refs.uploadVideoInVideo.show(file, this.appointment);
        },
        onUploadVideoDone() {
            this.requestAppointments();
        },
        urlify(text) {
            // eslint-disable-next-line no-useless-escape
            var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|software|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
            return text.toString().replace(urlRegex, function (url) {
                url = url.includes('http') ? url : 'http://' + url;
                return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
            });
            // or alternatively
            // return text.replace(urlRegex, '<a href="$1">$1</a>')
        },
        checkTutorialState() {
            this.bbbTutState = JSON.parse(localStorage.getItem('bbbTut'));
            if (this.bbbTutState) {
                this.bbbTut = false;
            }
        },
        getSrc() {
            return this.thisSubject
                ? this[this.thisSubject.icon]
                : this.thisAppointment
                    ? this[this.thisAppointment.schoolSubject.icon]
                    : null;
        },
        getSrcWhite() {
            /**
             * Cant be done like the top one because the second part of string doesnt mathc with the normal icon, so a search is probably needed
             */
            let temp = this.thisSubject
                ? this[this.thisSubject.icon]
                : this.thisAppointment
                    ? this[this.thisAppointment.schoolSubject.icon]
                    : null;
            let searchString = temp.slice(0, temp.length - 16) + "-weiss";
            let returnString = '';

            for (let i = 0; i < Object.keys(this.$data).length; i++) {
                let key = Object.keys(this.$data)[i];
                if (this.$data[key] && this.$data[key].includes && this.$data[key].includes(searchString)) {
                    returnString = this.$data[key];
                    break;
                }
            }

            return returnString.length > 0 ? returnString : temp;
        },

        getColorIcon() {
            return this.thisSubject
                ? this["color" + this.thisSubject.color.substring(1)]
                : this.thisAppointment
                    ? this["color" + this.thisAppointment.schoolSubject.color.substring(1)]
                    : this.colorb2b2b2;
        },

        getBackgroundColor() {
            let returnString = "background-color: ";
            return returnString + this.thisAppointment.schoolSubject.color;
        },

        getTimeslotTimes() {
            const timeslot = this.$parent.$parent.slots.find(
                (item) => item._id === this.thisAppointment.timeslot
            );
            if (timeslot) {
                return (
                    ("0" + new Date(timeslot.start).getHours()).slice(-2) +
                    ":" +
                    ("0" + new Date(timeslot.start).getMinutes()).slice(-2) +
                    " - " +
                    ("0" + new Date(timeslot.end).getHours()).slice(-2) +
                    ":" +
                    ("0" + new Date(timeslot.end).getMinutes()).slice(-2)
                );
            }
        },

        async teacherUploadInputChange(appointmentId) {
            const input = this.$refs.teacherUploadInput;
            const file = input.files[0];
            if (file) {
                const name = file.name;
                const lastDot = name.lastIndexOf(".");
                const fileName = name.substring(0, lastDot);
                const fileExtension = name.substring(lastDot + 1);
                this.uploadFileName = fileName;
                this.fileNameCallback = (newFileName) => {
                    this.showNameFile = false;
                    this.uploadFile(appointmentId, newFileName, fileExtension);
                };
                this.showNameFile = true;
            }
        },

        fileNotSupported(el) {
            this.snackbarFileNotSupported = true;
        },
        async uploadFile(appointmentId, newFileName, fileExtension, file, selectedUser) {
            if (!file) {
                const input = this.$refs.teacherUploadInput;
                file = input.files[0];
            }
            if (file) {

                if (file.size > 1000000000) {
                    this.setSnackbar({type: 'bigFileToBig', value: 'true'});
                    return;
                }

                const formData = new FormData();
                formData.append("file", file, `${newFileName}.${fileExtension}`);
                formData.append('visibleFor', JSON.stringify(selectedUser));
                this.teacherXmlHttpRequest = backend.postTeacherUpload(
                    appointmentId,
                    file
                );

                this.teacherXmlHttpRequest.onerror = (e) => {
                    console.error("Teacher upload error:", e);
                    this.showTeacherUploadProgress = false;
                    this.teacherXmlHttpRequest = null;
                    this.$refs.teacherUploadInput.value = "";
                };

                this.teacherXmlHttpRequest.onabort = (e) => {
                    console.warn("Teacher upload aborted");
                    this.showTeacherUploadProgress = false;
                    this.teacherXmlHttpRequest = null;
                    this.$refs.teacherUploadInput.value = "";
                };

                this.teacherXmlHttpRequest.upload.addEventListener("progress", (e) => {
                    this.teacherUploadProgress = (e.loaded / e.total) * 100;
                });

                this.teacherXmlHttpRequest.addEventListener("load", (e) => {
                    if (this.teacherXmlHttpRequest.status !== 201)
                        console.error(
                            "Teacher upload failed:",
                            this.teacherXmlHttpRequest.response
                        );
                    this.showTeacherUploadProgress = false;
                    this.snackbarSave = true;
                    this.requestAppointments();
                    this.teacherXmlHttpRequest = null;
                    this.$refs.teacherUploadInput.value = "";
                });
                this.teacherUploadProgress = 0.0;
                this.showTeacherUploadProgress = true;
                this.teacherXmlHttpRequest.send(formData);
            }
        },

        async clickDownloadTeacherUpload(file) {
            try{
                const res = await backend.getTeacherUpload(
                    this.thisAppointment._id,
                    file.file
                );
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = url;
                a.download = file.title;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove(); //afterwards we remove the element again
            }catch (e) {
                console.log(e);
            }
        },

        async clickDeleteUpload() {
            //setting reopenAppointment to open popup after deleting is done
            this.$route.query.appointment = this.thisAppointment._id;

            let file = this.elementToDelete;
            const res = await backend.deleteUpload((file._id).toString());

            if (res.status === 204) {
                this.snackbarDeleteText = "Datei " + file.title + " erfolgreich gelöscht"
                this.snackbarDelete = true;
                this.deleteDialog = false;
            } else {
                this.snackbarDeleteText = "Beim Löschen ist ein Fehler aufgetreten"
                this.snackbarDeleteColor = 'error';
                this.snackbarDelete = true;
                this.deleteDialog = false;
            }

            setTimeout(() => {
                /*emit to PlanWidgetLehrer*/
                this.$emit('update-me');
            }, 3000)

            this.elementToDelete = null; //clear temp reference to file
        },

        async clickDownloadPupilUpload(file) {
            try {
                file.seenByMe = true;
                const res = await backend.getPupilUpload(
                    this.thisAppointment._id,
                    file.file
                );
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = url;
                a.download = file.title;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove(); //afterwards we remove the element again
            }catch (e) {
                console.log(e);
            }
        },
        updateNote() {
            this.update = true;
        },
        async saveNote() {
            this.thisAppointment.note = this.thisAppointment.note.replaceAll('</p>', ' </p>');
            this.thisAppointment.note = this.thisAppointment.note.replaceAll('</span>', ' </span>');
            this.thisAppointment.note = this.thisAppointment.note.replaceAll('</li>', ' </li>');
            this.thisAppointment.note = this.thisAppointment.note.replaceAll('</em>', ' </em>');
            this.thisAppointment.note = this.thisAppointment.note.replaceAll('</strong>', ' </strong>');
            let data = {
                _id: this.thisAppointment._id,
                note: this.thisAppointment.note,
            };
            try {
                let response = await this.editAppointment(data);
                if (Number.isInteger(response)) {
                    if (response === 404) {
                        this.snackbarText =
                            "Etwas ist schief gelaufen - Bitte aktualisieren Sie die Seite!";
                    } else {
                        this.snackbarText = "Etwas ist schief gelaufen - Bitte aktualisieren Sie die Seite!";
                    }
                    this.snackbar = true;
                } else {
                    this.snackbarSave = true;
                    this.update = false;
                    this.thisAppointment.note = this.thisAppointment.note.replaceAll('</p>', ' </p>');
                    this.thisAppointment.note = this.thisAppointment.note.replaceAll('</span>', ' </span>');
                    this.thisAppointment.note = this.thisAppointment.note.replaceAll('</li>', ' </li>');
                    this.thisAppointment.note = this.thisAppointment.note.replaceAll('</em>', ' </em>');
                    this.thisAppointment.note = this.thisAppointment.note.replaceAll('</strong>', ' </strong>');
                }
            } catch (e) {
                this.snackbarText = "Die Notiz ist zu groß. Bitte benutzen Sie für große Dateien den Material-Upload.";
                this.snackbar = true;
                console.error(e);
            }
        },
        clickTeacherUploadButton() {
            this.toggleOpenAppointment(this.thisAppointment);
            this.toggleCurrentUploadGroup('teacher');
            localStorage.setItem('reopenAppointment', this.thisAppointment._id);
            document.getElementById("uploadInput").click();
        },

        openEdit(name, id, uploadId) {

            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;
            const fileTitle = name;
            const fileId = id;

            this.$router.push({
                name: "lehrer.edit",
                query: {
                    group: group,
                    color: color,
                    aId: appointmentId,
                    title: fileTitle,
                    fId: fileId,
                    uploadid: uploadId,
                },
            });
        },
        openEmptyEdit(newPageType) {
            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;
            this.toggleOpenAppointment(this.thisAppointment);

            this.$router.push({
                name: "lehrer.view",
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: group + ' ' + new Date().toLocaleDateString(),
                        pagetype: newPageType,
                    }
            });
        },
        handleOpenAnsichtClick(element) {
            if (this.isCorrectionAvailable(element)) {
                const fileToOpen = this.getCurrentCorrection(element);

                if (fileToOpen) {
                    this.openAnsicht(fileToOpen.title, fileToOpen.file, fileToOpen._id, 'pupil', true);
                }
            } else {
                this.openAnsicht(element.title, element.file, element._id, 'teacher')
            }
        },

        openAnsicht(name, id, uploadId, userGroup, correction) {
            console.log(this.thisAppointment);

            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;
            const fileTitle = name;
            const fileId = id;

            if (correction) {
                this.$router.push({
                    name: "lehrer.view",
                    query: {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: fileTitle,
                        fId: fileId,
                        uploadid: uploadId,
                        ug: userGroup,
                        correction: correction
                    },
                });
            } else {
                this.$router.push({
                    name: "lehrer.view",
                    query: {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: fileTitle,
                        fId: fileId,
                        uploadid: uploadId,
                        ug: userGroup
                    },
                });
            }

        },

        //startBBBTut
        startBBB() {
            /*emit to PlanWidgetLehrer*/
            this.$emit('pass-on-bbb-tut', this.joinBBB);
        },

        async clickBeitreten() {
            this.menu = false;

            if (!this.bbbTutState) {
                this.startBBB();
            } else if (this.bbbTutState) {
                await this.joinBBB();
            }
        },

        async joinBBB() {
            const res = await backend.joinSchoolSubjectAppointmentBbbMeetingUrl(
                this.thisAppointment._id
            );
            this.toggleLoading(true);
            this.reloadDontLogout(true);
            const {redirectUrl} = await res.json();
            if (res.status === 422) {
                this.snackbarText = "Aktuell ist der Raum geschlossen!";
                this.snackbar = true;
            } else {
                window.location.href = redirectUrl;
                //  window.open(redirectUrl);
            }
            this.toggleLoading(false);
        },

        isCorrectionAvailable(pupilFile) {
            for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                if (this.thisAppointment.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(
                        this.thisAppointment.teacherUploads[i]._id
                    );
                    if (index !== -1) {
                        return true;
                    }
                }
            }
            return false;
        },

        getCurrentCorrection(pupilFile) {
            for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                if (this.thisAppointment.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(this.thisAppointment.teacherUploads[i]._id);
                    if (index !== -1) {
                        return this.thisAppointment.teacherUploads[i];
                    }
                }
            }
            return null;
        },

        async clickDownloadPupilCorrection(file) {
            let teacherCorrection = null;

            for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                if (this.thisAppointment.teacherUploads[i].editParent) {
                    const index = file.editLeafs.indexOf(
                        this.thisAppointment.teacherUploads[i]._id
                    );
                    if (index !== -1) {
                        teacherCorrection = this.thisAppointment.teacherUploads[i];
                    }
                }
            }

            if (teacherCorrection) {
                teacherCorrection.seenByMe = true;
                try {
                    const res = await backend.getTeacherUpload(
                        this.thisAppointment._id,
                        teacherCorrection.file
                    );
                    let blob = await res.blob();
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = teacherCorrection.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove(); //afterwards we remove the element again
                }catch (e) {
                    console.log(e);
                }
            }
        },

        async getMissingMembers() {
            this.missingAppointments = [];
            let pupilUploadData = this.appointment.pupilUpload;
            let idListUploads = [];

            //IDs of pupils who have done homework
            for (let k = 0; k < pupilUploadData.length; k++) {
                idListUploads.push(pupilUploadData[k].uploader);
            }

            //get all members of the group
            const groupMemberAppointment = await backend.getGroupByAppointment(this.appointment._id);
            let groupMemberAppointmentData = groupMemberAppointment.json().then(data => ({
                data: data,
                status: groupMemberAppointment.status
            }));
            this.groupMembers = (await groupMemberAppointmentData).data[0].participants;

            let teacherUploads = this.appointment.teacherUploads;
            let visibleFor = [];

            //go through teacherUploads to collect the visibleFor array but only if the upload is flagged as Assignment
            for (let i = 0; i < teacherUploads.length; i++) {
              if(teacherUploads[i].isAssignment || teacherUploads[i].isAssignment === undefined){
                visibleFor = visibleFor.concat(teacherUploads[i].visibleFor);
              }
            }

            //remove done homework (idListUploads) from visibleFor (visibleFor), so only the undone homework is left
            //result is in visibleFor array
            idListUploads.forEach(function (val) {
                var foundIndex = visibleFor.indexOf(val);
                if (foundIndex !== -1) {
                    visibleFor.splice(foundIndex, 1);
                }
            });

            //groupMembers = all pupils of this appointment's group with ID and name
            //visibleFor = IDs of pupils who haven't done homework (includes duplicates
            //e.g. pupilA hasn't done homework apples and coins)
            for (let i = 0; i < visibleFor.length; i++) {
                let el = this.groupMembers.find(obj => obj.account === visibleFor[i]);
                this.missingAppointments.push(el);
            }

            this.missingAppointments = [...new Set(this.missingAppointments)]; //remove duplicate names

            //sort array by firstname
            this.missingAppointments.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            })
        },

        //checks if there is a teacherUpload to set placeholder text in Fach-Popup, if there is no upload
        teacherUpload() {
            let t = this.thisAppointment.teacherUploads.filter((item) => !item.editParent);
            return t.length !== 0;
        },

        hideEl(element) {
            let x = [];
            x = document.getElementsByClassName(element);
            for (let i = 0; i < x.length; i++) {
                x[i].style.display = "none";
            }
        },

        showEl(element) {
            let x = [];
            x = document.getElementsByClassName(element);
            for (let i = 0; i < x.length; i++) {
                x[i].style.display = "block";
            }
        },

        resetDialogContent() {
            this.hideEl('matLong');
            this.showEl('matShort');
            this.hideEl('toCorrectLong');
            this.showEl('toCorrectShort');
            this.hideEl('toDoLong');
            this.showEl('toDoShort');
            this.hideEl('correctedLong');
            this.showEl('correctedShort');
        },

        async getGroupName() {
            let group = await this.getGroupByAppointment(this.thisAppointment._id);
            this.thisAppointment.group = group[0] ? group[0] : null;

            this.groupName = group[0].name;
        },

        //used for supFormats tooltip
        closeDialog() {
            this.supFormats = false;
        },
        include() {
            return [document.querySelector('.included')]
        },

        async requestFilesFromFileWidget() {
            this.loadFolders = true;
            this.me = await this.getMeTeacher();

            this.privateFolder.length = this.subjectFolder.length = this.teacherFolder.length = 0;

            this.privateFolderFull = await this.getPrivateFolder();
            this.subjectFolderFull = await this.getSubjectFolder({
                subjectId: this.thisAppointment.schoolSubject._id,
                teacherId: this.me._id,
            });
            this.teacherFolderFull = await this.getTeacherFolder();

            //sort arrays alphabetically
            this.privateFolderFull.sort(this.alphabetically);
            this.subjectFolderFull.sort(this.alphabetically);
            this.teacherFolderFull.sort(this.fileAlphabetically);

            //slice arrays initially
            this.privateFolder = this.privateFolderFull.slice(0, 2);
            this.subjectFolder = this.subjectFolderFull.slice(0, 2);
            this.teacherFolder = this.teacherFolderFull.slice(0, 2);

            this.privateFolderSliced = true;
            this.subjectFolderSliced = true;
            this.teacherFolderSliced = true;

            this.loadFolders = false;
        },

        alphabetically(a, b) {
            var x = a.uploadedName.toLowerCase();
            var y = b.uploadedName.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        },

        fileAlphabetically(a, b) {
            var x = a.file.uploadedName.toLowerCase();
            var y = b.file.uploadedName.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        },

        prepareUploadViaFileWidget(file) {
            this.groupN =  this.thisAppointment.group;
            let name;
            // teacherFolder uses nested file.file.*, privateFolder uses file.*
            if(file.uploadedName){
                name = file.uploadedName;
                this.fileToUpload = file;

            } else if (file.file.uploadedName){
                name = file.file.uploadedName;
                this.fileToUpload = file.file;
            } else {
                console.error('invalid file format');
            }
            let lastDot = name.lastIndexOf('.');
            this.newFileName = name.substring(0, lastDot);
            this.fileWidgetNameDialog = true;
        },

        //method that sends request to backend
        async uploadViaFileWidget() {
            let data = {
                appointmentId: this.thisAppointment._id,
                fileName: this.newFileName,
                fileId: this.fileToUpload._id,
                visibleFor: JSON.stringify(this.selectedUsers),
                startDate: this.startDate,
                isAssignment: this.isAssignment,
            };
            let res = await this.createTeacherUploadViaFileId(data);
            if (res === true) {
                this.fileWidgetNameDialog = false;
                this.fileWidgetUploadDialog = false;
                this.snackbarSave = true;
            }

            // this should refresh store.appointments but it still requires page reload. regular way of refreshing would be implementing a callback that eventually leads to this function. seems obfuscated?
            // await this.getAppointments();
            // below is my ugly solution, if smn can find a better way to only reload appointment uploads, please go for it
            this.reloadDontLogout(true);
            this.$router.go();
        },

        toggleSlice(folder) {
            switch (folder) {
                case 'private':
                    if(this.privateFolderSliced) {
                        this.privateFolder = this.privateFolderFull;
                        this.privateFolderSliced = false;
                    }
                    else {
                        this.privateFolder = this.privateFolderFull.slice(0,2);
                        this.privateFolderSliced = true;
                    }
                    break;
                case 'subject':
                    if(this.subjectFolderSliced) {
                        this.subjectFolder = this.subjectFolderFull;
                        this.subjectFolderSliced = false;
                    }
                    else {
                        this.subjectFolder = this.subjectFolderFull.slice(0,2);
                        this.subjectFolderSliced = true;
                    }
                    break;
                default:
                    if(this.teacherFolderSliced) {
                        this.teacherFolder = this.teacherFolderFull;
                        this.teacherFolderSliced = false;
                    }
                    else {
                        this.teacherFolder = this.teacherFolderFull.slice(0,2);
                        this.teacherFolderSliced = true;
                    }
            }
        },
    },
};
</script>

<style>
.ql-container {
    font-size: 16px !important;
    padding: 12px !important;
}

.ql-editor {
    padding: 0 !important;
}
</style>

<style lang="scss" scoped>
table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 6em !important;
}

table tr th,
table tr td {
    width: 9em;
}

table,
tr,
td {
    border: none;
}

.fach {
    cursor: pointer;
}

.whiteBg {
    background-color: white !important;
}

.tinyIcon {
    width: 15px;
}

.iconSize {
    height: 20px;
}

.iconToWhite {
    filter: brightness(1000%);
}

.greyText {
    color: var(--v-dunkelgrau-base);
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.squareBtn {
    min-width: 20px !important;
    min-height: 20px !important;
    width: 36px;
    height: 36px;
}

.appointmentItem {
    position: relative;
    color: #707070;
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #FFF;
    width: 100%;
    height: 100%;
}

.inactiveAppointment {
    opacity: 0.4 !important;
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: 200px;
}

.scroll-area2 {
    position: relative;
    margin: auto;
    max-height: 80px;
}

.scroll-area3 {
    position: relative;
    margin: auto;
    max-height: 50px;
}

.scroll-area-fachPopup {
    position: relative;
    margin: auto;
    height: calc(65vh - 30px);
}

.scroll-area-fileUpload {
    position: relative;
    margin: auto;
    max-height: 75vh;
}

.buttonIcon {
    align-self: start;
    background-color: #eaeaeacc;
    padding: 2px;
    border-radius: 3px;
    margin-right: 1em;
}

//border-radius of v-menu
.v-menu__content {
    border-radius: 12px;
}

.iconSelect {
    display: inline-flex;
    width: 24px;
    height: 24px;
    padding: 2px;
    margin-bottom: -6px;
    border-radius: 5px;
    margin-right: 2px;
    background-color: rgba(234, 234, 234, 0.7);
}

.iconSelectFrei {
    display: inline-flex;
    width: 24px;
    padding: 2px;
    margin-bottom: -6px;
    border-radius: 5px;
    margin-right: 2px;
    background-color: transparent;
}

.smallFach {
    position: relative;
    color: #707070;
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    white-space: nowrap;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #ffffff;
}

.isFachRunning {
    color: white;
    background-color: #8cbd46 !important;
}

.isFachRunningIcon {
    filter: brightness(1000%);
}

.fileUploadPlaceholderText {

    p {
        color: #BBBBBB;
        width: 100%;
        text-align: center;
    }
}

.eHr {
    width: 90%;
    margin: auto;
    border-width: 0;
    height: 2px;
    color: #eaeaea;
    background-color: #eaeaea;
}

.eButton {
    width: 33px !important;
    min-width: 33px !important;
    height: 33px !important;
    min-height: 33px !important;
    border-radius: 4px !important;
    box-shadow: 0 5px 10px #00000025 !important;
}

.btnStyle {
    min-width: 36px;
    min-height: 36px;
    margin-top: -3px;
}

.fileName {
    max-width: 90%;
    min-width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
}

.matLong, .toCorrectLong, .toDoLong, .correctedLong {
    display: none;
}

.brighterFileRow {
    background-color: rgba(224, 224, 224, 0.15) !important;
}

.brighterToCorrectRow {
    background-color: rgba(128, 128, 128, 0.15) !important;
}

.brighterToDoRow {
    background-color: rgba(230, 35, 30, 0.15) !important;
}

.brighterCorrectedRow {
    background-color: rgba(60, 170, 105, 0.15) !important;
}

#uploadFromFileWidgetMenu {
    display: block;
    background-color: #F8F8F8;
}

.fileDiv {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    width: 99%;
    cursor: pointer;
}

@media only screen and (max-width: 399px) {
    #fachPopup {
        max-width: 100vw !important;
    }

    #dialog {
        margin: 0;
    }
}

@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    .scroll-area-fachPopup {
        /*250px = 59px header, 48px join btn, 70px btmNavBar and paddings,margins*/
        max-height: calc(100vh - 250px);
    }
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}
</style>
