<template>
    <BaseMenu
        :items="items"
        :show-list="showList"
        @close="close"
        @itemClicked="onItemSelected"
    >
        <!-- Add custom data here -->
        <div v-if="selectedItem">
            <v-container>
                <v-row dense>
                    <v-col>
                        <h3>{{ selectedItem.text }} einfügen</h3>
                    </v-col>
                </v-row>
                <div class="text-body-2">
                    Wählen Sie die Farbe der Form aus:
                </div>
                <v-row class="mt-1 mb-0 pb-0" dense>
                    <v-col class="">
                        <v-color-picker
                            class="ma-2"
                            id="cPicker"
                            :swatches="swatches"
                            v-model="selectedColor"
                            hide-mode-switch
                            hide-canvas
                            hide-inputs
                            show-swatches
                        ></v-color-picker>
                    </v-col>
                </v-row>
                <v-row align="center" no-gutters v-if="selectedItem.text !== 'Linie'">
                    <v-col class="" cols="auto">
                        <v-checkbox v-model="rectangleBorderState"></v-checkbox>
                    </v-col>
                    <v-col class="">
                        <div>
                            Form mit Rahmen
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-btn @click="spawnForm" color="success">Hinzufügen</v-btn>
                        <v-btn @click="cancel" color="error" class="ml-1">Abbrechen</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </BaseMenu>
</template>

<script>
import BaseMenu from "@/components/Editortools/Toolbar/Menus/BaseMenu";
import rechteckIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_form_rechtteck.svg';
import kreisIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_Form_kreis.svg';
import dreieckIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_Form_dreieck.svg';
import linieIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_Form_Linie.svg';
import { formMenuHelp } from "@/constants/editorHelpCollection";

export default {
    name: 'MediaMenu',
    components: {BaseMenu},
    data() {
        return {
            items: [
                {icon: rechteckIcon, text: 'Rechteck', help: { type: 'text', content: formMenuHelp[0] }},
                {icon: linieIcon, text: 'Linie', help: { type: 'text', content: formMenuHelp[1] }},
            ],
            showList: true,
            selectedItem: { text: '', icon: null },
            rectangleBorderState: true,
            selectedColor: '#FFFFFF',
            swatches: [
                ['#FFFFFF', '#b2b2b2', '#707070'],
                ['#000000', '#1b5f99', '#9bcdc3'],
                ['#fcd20a', '#f0b423', '#ff6941'],
                ['#91c364', '#91c887', '#d7875f'],
                ['#9b91c8', '#ebaabe', '#6ec3dc'],
            ],
        }
    },
    methods: {
        onItemSelected(item) {
            console.log("Item selected", item);
            this.selectedItem = item;
            this.showList = false;
        },
        spawnForm() {
            if (this.selectedItem.text === 'Rechteck') {
                this.spawnRectangle();
            }else if(this.selectedItem.text === 'Linie') {
                this.spawnLine();
            }
        },
        spawnRectangle() {
            this.$emit('addRectangle', this.selectedColor, (this.rectangleBorderState ? '1px solid black': ''));
            this.close();
        },
        spawnLine() {
            this.$emit('addLine', this.selectedColor);
            this.close();
        },
        cancel() {
            this.showList = true;
        },

        /**
         * General
         */
        close() {
            this.$emit('close');
            this.selectedColor = '#FFFFFF';
            this.showList = true;
        }
    }
}
</script>

<style lang="scss">

#cPicker {
    .v-color-picker__controls {
        .v-color-picker__preview--hide-alpha {
            .v-color-picker__dot {
                border-radius: 0% !important;
            }

            .v-color-picker__dot {
                border: 1px solid gray;
                -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
                box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
            }

            .v-color-picker__sliders {
                display: none !important;
            }
        }
    }
}

</style>
