import * as backend from '../../api/backend';
import {checkResponseStatus} from '../../util/check';


const defaultState = {
};

const mutations = {
};

const actions = {

  async getUploadMessages ({ commit, dispatch }, params) {

    try {
      const res = await backend.getUploadMessages(params);
      await checkResponseStatus(200, res);
      const uploadMessages = await res.json();
      return uploadMessages;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async getUploadMessageAudio ({ commit, dispatch }, fileId) {
    try {
      const res = await backend.getUploadMessage(fileId);
      await checkResponseStatus(200, res);
      return await res.json();
    } catch (e) {
      return e.response.status;
    }
  },

  async createUploadMessages ({ commit, dispatch }, params) {

    try {
      const res = await backend.postUploadMessages(params);
      await checkResponseStatus(201, res);
      const uploadMessages = await res.json();
      return uploadMessages;
    }
    catch(err) {
      return err.response.status;
    }

  },

};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
