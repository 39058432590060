<template>
    <div id="slide" v-if="content.length">
        <div id="content" class="mt-10">
            <div id="contentHeaderAndText">
                <div class="contentHeader">
                    <h2>{{ `Die Verwaltung - Schritt ${pageIndex + 1}` }}</h2>
                </div>
                <div class="contentHeader mt-5">
                    {{currentHeader}}
                    <v-overlay v-if="false" color="primary" absolute style="height: 150px"/>
                </div>

                <div id="contentText" v-html="currentText">
                </div>
            </div>
        </div>

        <div id="bottomBar">
            <div id="navBar">
                <v-row>
                    <v-col style="display: flex" md="2" sm="3" cols="5">
                        <div style="height: 40px"></div>
                        <v-btn
                                v-if="pageIndex > 0" class="navBtn"
                                style="opacity: 0.5"
                                text color="#6f6f6f" large
                                @click="pageIndex--"
                        >
                            <img :src="links">
                            Zurück
                        </v-btn>
                    </v-col>

                    <v-col style="text-align: center; color: #707070">
                        <div v-if="$vuetify.breakpoint.width < 700" id="navCounter">
                            {{pageIndex + 1}}/{{content.length}}
                        </div>

                        <div v-else style="display: inline-flex; margin: auto">
                            <div v-for="(el, elIndex) in content" :key="elIndex" class="dot"
                                 @click="() => { pageIndex = elIndex }"
                                 :active="elIndex <= pageIndex">
                            </div>
                        </div>
                    </v-col>

                    <v-col style="display: flex" md="2" sm="3" cols="5">
                        <v-btn
                                :disabled="!moveAlong"
                                v-if="pageIndex + 1 !== content.length"
                                class="navBtn"
                                :style="moveAlong ? 'filter: invert(0) sepia(1) saturate(2) hue-rotate(170deg)' : '' "
                                text color="#6f6f6f" large @click="pageIndex++"
                        >
                            Weiter
                            <img :style="moveAlong ? '' : 'opacity: 0.5' " :src="rechts">
                        </v-btn>
                        <v-btn
                                :disabled="!moveAlong"
                                v-else class="navBtn"
                                :style="moveAlong ? 'filter: invert(0) sepia(1) saturate(2) hue-rotate(170deg)' : '' "
                                text color="#6f6f6f"
                                large @click="setTutorialDone"
                        >
                            Beenden
                            <img :style="moveAlong ? '' : 'opacity: 0.5' " :src="rechts">
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
import tutorialVerwaltung from '../../constants/tutorialVerwaltung'
import links from '../../assets/Icons/links-10.svg'
import rechts from '../../assets/Icons/rechts-11.svg'

export default {
    name: 'TutorialVerwaltung',
    data() {
        return {
            // normal imports
            tutorialVerwaltung,
            links,
            rechts,
            //Everything else
            pageIndex: -1,
            content: null,
            currentText: '',
            currentHeader: '',
            //flags
            moveAlong: true,
        };
    },
    watch: {
        pageIndex() {
            this.moveAlong = true;
            this.currentHeader = this.content[this.pageIndex].header;
            this.currentText = this.content[this.pageIndex].text;
        }
    },
    methods: {
        setContent() {
            this.content = tutorialVerwaltung.collection;
        },
        setTutorialDone() {
            this.$router.back();
            this.pageIndex = 0;
        }
    },
    beforeMount() {
        this.setContent();
        this.pageIndex = 0;
    }
}
</script>

<style scoped lang="scss">
#slide {
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFF;
    z-index: 999;
}

#logo {
    position: fixed;
    top: 30px;
    left: 40px;
    height: 4rem;
}

@media only screen and (max-width: 700px) {
    #logo {
        top: 40px;
        left: 10px;
        height: 3rem;
    }
}

#langAndReadWrapper {
    display: flex;
    position: fixed;
    top: 45px;
    right: 40px;
}

@media only screen and (max-width: 700px) {
    #langAndReadWrapper {
        right: 10px;
    }
}

#langBtn {
    margin-right: 16px;
}

@media only screen and (max-width: 340px) {
    #langBtn {
        margin-right: 4px;
    }
}

#readDiv {
    height: 5rem;
    display: inline-flex;
    color: #D6D6D6;
    cursor: pointer;

    p {
        height: 45px;
        text-align: center;
        line-height: 45px;
        font-size: 20px;
        padding-left: 10px;
        margin-bottom: 0;
    }
}

#content {
    color: #737373;
    left: 0;
    right: 0;
    margin: 0;
    top: 50%;
    position: absolute;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

#contentHeaderAndText {
    height: 120px;
    margin-top: 30px;
}

#bottomBar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5%;
}

#navBar {
    width: 100%;
    display: inline-flex;
}

.navBtn {
    margin: auto;
    text-transform: inherit;

    & img {
        width: 40px;
    }
}

#navCounter {
    font-weight: bold;
    line-height: 3rem;
    text-align: center;
}

#contentText {
    max-width: 700px;
    font-size: large;
    margin: auto;
    margin-top: 0.75rem;
    text-align: center;
}

@media only screen and (max-width: 700px) {
    #contentText {
        max-width: 90%;
    }
}

#contentImg {
    display: flex;
    margin: auto;

    max-height: calc(100vh - 400px);
    /*max-width: min(700px, 100%);*/
}

.contentHeader {
    margin: auto;
    text-align: center;
    font-weight: bold;
    font-size: larger;
    max-width: 700px;
}

.dot[active]{
    width: 20px;
    height: 20px;
    background-color: #737373;
    border-radius: 20px;
    margin-left: 5px;
    margin-right: 5px;
}

.dot{
    width: 20px;
    height: 20px;
    background-color: #73737350;
    border-radius: 20px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 0.55rem;
}

#ProgressBarWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.progressbar {
    width: 50%;
}

#musiknoteRot {
    position: absolute;
    top: -15px;
    right: 0;
    width: 20px;
    height: 20px;
}

#musiknoteGelb {
    position: absolute;
    bottom: 15px;
    left: 50px;
    width: 20px;
    height: 20px;
}

#loadingTranslation {
    position: absolute;
    left: 50%;
    bottom: 150px;
}

@media only screen and (max-width: 700px) {
    #logo {
        object-fit: contain;
        width: 45vw;
    }

    #langAndReadWrapper {
        top: 40px;
    }
}

@media only screen and (max-width: 400px) {
    #logo {
        top: 24px;
        left: 10px;
        height: 2rem;
    }

    #langAndReadWrapper {
        top: 8px;
        right: 0;
        -ms-transform: translateX(20px);
        transform: scale(0.75) translateX(20px);
    }

    #contentImg {
        max-height: 150px;
    }

    #contentHeaderAndText {
        margin-top: 16px;
    }

    .contentHeader {
        font-size: medium;
        width: 90%;
    }

    #contentText {
        margin-top: 4px;
        font-size: small;
        white-space: pre-line;
    }

    .navBtn {
        & img {
            width: 28px;
        }
    }
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}
</style>
