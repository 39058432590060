<template>
    <div v-if="magnifier">
        <anti-scroll ref="scroll" :class="'scroll-area-antiScroll' + getCss()">
            <slot></slot>
        </anti-scroll>
    </div>
    <div v-else>
        <vue-custom-scrollbar :style="calcHeight" id="vue-custom-scrollbar" :class="'scroll-area-scrollbar' + getCss()" ref="scroll" :settings="settings" @ps-y-reach-start="loadMore()" style="position: relative; margin: auto">
            <slot></slot>
        </vue-custom-scrollbar>
    </div>
</template>

<script>
import {mapState} from "vuex";
import AntiScroll from "@/components/AntiScroll";
import vueCustomScrollbar from "vue-custom-scrollbar";

export default {
name: "ScrollAreaMagnifier",
    components: {AntiScroll, vueCustomScrollbar},
    data() {
        return {
            heightChat: "max-height: calc(80vh - 176px)"
        }
    },

    computed: {
        ...mapState("magnifier", ["magnifier"]),
        calcHeight() {
            if(this.usage === 'chatWidget'){
                return 'max-height: calc(80vh - ' + (this.offsetHeightChat + 144) + 'px)';
            }
            return '';
        },
    },

    props: {
        settings: {type: Object, required: false},
        usage: {type: String, required: false},
        offsetHeightChat: {required: false, default: 144},
    },

    watch: {
        magnifier () {
            if(this.magnifier) {
                setTimeout(() => {
                    this.$refs.scroll.scrollTop();
                    console.log('Scroll Top');
                }, 1000);

            }
        },
        offsetHeightChat () {
            let h = 176 + this.offsetHeightChat;
            this.heightChat = "calc(80vh - " + h + "px)";
            setTimeout(() => this.jumpToLatestInitial(), 20);
            console.log("Change height: ", this.heightChat);
        }
    },

    methods: {
        sendMessage() {
            this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight
        },
        sleep(milliseconds) {
            return new Promise(resolve => setTimeout(resolve, milliseconds));
        },
        async loadMore() {
            if(this.usage === 'chatWidget') {
                this.$emit('ps-y-reach-start')
                this.$refs.scroll.$el.scrollTop = 10;

                if(this.magnifier && this.usage === 'chatWidgetBaby') {
                    this.$refs.scroll.scrollTop();
                }
            }
        },
        async jumpToLatestInitial() {
            while (!this.$refs.scroll) {
                await this.sleep(500);
            }
            this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight;
        },
        async jumpToLatest() {
            setTimeout(() => {
                this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight;
                if(this.magnifier) {
                    this.$refs.scroll.scrollBottom();
                }
            }, 500);
        },
        getCss() {
            if(this.usage === 'chatWidget') {
                return '-chatWidget';
            } else if(this.usage === 'chatWidgetBabyVerwaltung') {
                return '-chatWidgetBabyVerwaltung';
            } else if(this.usage === 'chatWidgetBaby') {
                return '-chatWidgetBaby';
            } else if(this.usage === 'brettWidget') {
                return '-brettWidget';
            }
        }
    },

    async created() {
        if (this.usage === 'chatWidget') {
            await this.jumpToLatest();
        }
        // else if (this.usage === 'brettWidget') {
        //     if(this.magnifier)
        //         this.$refs.scroll.scrollTop();
        //     else
        //         this.jumpToLatestInitial();
        // }
    },
}
</script>

<style>
.scroll-area-scrollbar-chatWidgetBabyVerwaltung {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 180px);
}

.scroll-area-antiScroll-chatWidgetBabyVerwaltung {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 184px);
    height: calc(80vh - 179px);
    overflow: hidden;
    margin-bottom: 16px;
}

.scroll-area-scrollbar-chatWidget {
}

.scroll-area-scrollbar-chatWidget2 {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 152px);
}

.scroll-area-antiScroll-chatWidget {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 184px);
    height: calc(80vh - 179px);
    overflow: hidden;
    margin-bottom: 16px;
}

.scroll-area-scrollbar-chatWidgetBaby {
    position: relative;
    overflow: hidden;
    max-height: calc(80vh - 279px);
    height: calc(80vh - 279px);
    margin-bottom: 2px;
}

.scroll-area-antiScroll-chatWidgetBaby {
    position: relative;
    overflow: hidden;
    max-height: calc(80vh - 279px);
    height: calc(80vh - 279px);
    margin-bottom: 2px;
}

.scroll-area-scrollbar-brettWidget {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 75px);
}

.scroll-area-antiScroll-brettWidget {
    position: relative;
    overflow: hidden;
    margin: auto;
    max-height: calc(80vh - 75px);
    height: calc(80vh - 75px);
}
</style>
