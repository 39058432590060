const isSupported = () => {
  return 'speechSynthesis' in window;
};

const textToSpeech = (text, language = 'de', onStartCallback = null, onEndCallback = null) => {
  if (isSupported && text) {
    if(window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
    } else {
      const msg = new SpeechSynthesisUtterance();
      msg.text = text;
      msg.lang = language;
      msg.onstart = () => {
        if(onStartCallback)
          onStartCallback();
      };
      msg.onend = () => {
        if(onEndCallback)
          onEndCallback();
      };
      window.speechSynthesis.speak(msg);
    }
    return true;
  }
  return false;
};

export {
  textToSpeech,
  isSupported,
}
