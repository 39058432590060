import { render, staticRenderFns } from "./BriefWidget.vue?vue&type=template&id=41501dd6&scoped=true&"
import script from "./BriefWidget.vue?vue&type=script&lang=js&"
export * from "./BriefWidget.vue?vue&type=script&lang=js&"
import style0 from "./BriefWidget.vue?vue&type=style&index=0&id=41501dd6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41501dd6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCheckbox,VCol,VDialog,VRow,VTextarea,VTooltip})
