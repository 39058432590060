<template>
    <div @click="greet" class="noselect" :key="update"
         style="background-color: transparent; z-index: 2;"
         :style="'height:' + iconSize + '; width:' + iconSize"
    >
       <img v-if="this.iconColor === 'white'" :src="isReading ? lautsprecherAusWhite : lautsprecherWhite"/>
       <img v-else :src="isReading ? lautsprecherAus : lautsprecher"/>
    </div>
</template>

<script>
import lautsprecher from '@/assets/Icons/lautsprecher-13.svg'
import lautsprecherAus from '@/assets/Icons/lautsprecher-aus-89.svg'
import lautsprecherWhite from '@/assets/Icons/lautsprecher-weiss-13.svg'
import lautsprecherAusWhite from '@/assets/Icons/lautsprecher-weiss-aus-89.svg'

import {mapActions, mapGetters} from 'vuex';

export default {
    name: "TTS",
    components: {
    },
    data() {
        return {
            name: '',
            selectedText: '',
            update: false,
            // targetLang: 'de',

            lautsprecher,
            lautsprecherAus,
            lautsprecherWhite,
            lautsprecherAusWhite,

            isReading: false,
            textSelected: false,
            tutText: false,
            account: '',
            showTextTimeout: null,
        };
    },
    props: {
        iconSize: {required: false, type: String, default: '25px'},
        iconColor: {required: false, type: String},
    },
    computed: {
        ...mapGetters('translation', [ 'getTargetLang' ]),
        ...mapGetters('pupils', ['getTutorialProgress']),
        getTutProgress() {
            if (!this.account)
                return [];
            return this.getTutorialProgress[this.account] || [];
        },
        targetLang() {
            return this.getTargetLang;
        },

    },
    async mounted() {

        setInterval(() => {
            let selection = JSON.parse(JSON.stringify(this.selectedText));
            if (window.getSelection) {
                selection = window.getSelection();
            }
            // document.getSelection
            else if (document.getSelection) {
                selection = document.getSelection();
            }
            // document.selection
            else if (document.selection) {
                selection =
                    document.selection.createRange().text;
            }
            if(selection.toString() !== this.selectedText) {
                this.update = !this.update;

            }
            this.selectedText = selection.toString();

        }, 100);
        this.isLangPackageAvailable();

        this.tut();
        setInterval(() => {
            this.tut();
        }, 300000);

        this.account = (await this.getMePupil()).account;
    },

    methods: {
        ...mapActions('pupils', ['setTutorialProgress', 'createPupil', 'getMePupil']),
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation', 'translateToTargetLang']),

        async finishTut(progress) {
            this.setTutorialProgress({ account: this.account, progress });
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },

        async greet() {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
            }

            if (window.speechSynthesis.speaking) {
                this.isReading = false;
                window.speechSynthesis.cancel()
            } else {
                // Code for translation, english as default
                let text = '';
                if (this.targetLang === 'de') {
                    text = this.selectedText.toString();
                } else {
                    let deText = this.selectedText.toString();

                    text = await this.translateToTargetLang({
                        targetLang: this.targetLang,
                        textToTranslate: deText
                    });

                    this.setTranslatedText(text);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000)
                }

                this.isReading = true;
                let msg = new SpeechSynthesisUtterance();
                msg.text = (text !== '' ? text : 'Markiere einen Text, klicke mich an und ich lese ihn dir vor.');
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                } else {
                    this.isReading = false;
                }

                msg.onend = () => {
                    this.isReading = false;
                }
            }
        },

        async tut() {
            this.tutText = true;

            setTimeout(() => {
                this.tutText = false;
            }, 5000);
        }
    },
    watch: {
        selectedText() {
            if(this.selectedText.length) {
                if(!this.textSelected) {
                    this.textSelected = true;
                }
            } else {
                if(this.textSelected) {
                    this.textSelected = false;
                }
            }
        },
    }
};
</script>

<style lang="scss" scoped>
div[color] {
    background-color: $schueler-blau !important;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
