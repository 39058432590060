<template>
    <div id="btnsContainer" class="d-flex justify-start">
        <!-- teacher -->
        <v-btn v-if="account.role === 'teacher'" @click.stop.prevent="toggleMatrixRoom"
               :color="isOpen ? 'gruen' : 'fgrau'" id="schlossBtn" class="ml-2" elevation="0"
               :small="this.windowWidth <= 900 && this.windowWidth > 770"
               :x-small="this.windowWidth <= 770"
               >
            <img :src="isOpen ? schlossOffenIcon : schlossIcon">
        </v-btn>

        <!-- Error Snackbar -->
        <v-snackbar bottom v-model="snackbar" :color="snackbarColor" timeout="15000">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbar = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon" />
            </v-btn>
          </template>
            {{ snackbarText }}
        </v-snackbar>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import schlossIcon from '../../assets/Icons/passwort-weiß-02.svg';
import schlossOffenIcon from '../../assets/Icons/schloss-geöffnet-123.svg';
import abbrechenIcon from "../../assets/Icons/abbrechen-08.svg";

export default {
    name: "ToggleMatrixRoomButton",
    data() {
        return {
            schlossIcon,
            schlossOffenIcon,
            abbrechenIcon,

            snackbar: false,
            snackbarText: '',
            snackbarColor: '',
            menu: '',
            isOpen: null,
            windowWidth: window.innerWidth,
        };
    },
    async mounted() {
        this.isOpen = this.room.isOpen;
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    computed : {
        ...mapState("auth", ["account"]),
        ...mapState('translation', ['targetLang']),
    },
    watch: {
        room() {
            this.isOpen = this.room.isOpen;
        }
    },
    props: {
        room: { required: true, type: Object },
    },
    methods: {
        ...mapActions("matrix", ["getMatrixRoomLockStatus", "setMatrixRoomLockStatus"]),
        onResize () {
            this.windowWidth = window.innerWidth;
        },

        async toggleMatrixRoom() {
            let newStatus = !this.isOpen;
            let data = {
                roomId: this.room.roomId,
                isOpen: newStatus,
            }
            const res = await this.setMatrixRoomLockStatus(data);
            if(res.status==201) {
                this.isOpen = newStatus;
                this.$emit("lockStatusChanged", this.isOpen, this.room.roomId);

                // and show snackbar
                if (this.room.isOpen) {
                    this.snackbarText = 'Der Chat ist nun für alle Schüler geöffnet.';
                    this.snackbarColor = 'gruen';
                    this.snackbar = true;
                } else {
                    this.snackbarText = 'Der Chat ist nun für alle Schüler geschlossen. Schüler können nichtmehr beitreten. Bereits beigetretene Schüler können nichtmehr schreiben und keine Nachrichten löschen.';
                    this.snackbarColor = 'gruen';
                    this.snackbar = true;
                }
            }
        },
    }
}
</script>

<style scoped lang="scss">
.v-btn {
    border-radius: 8px;
}

#schlossBtn {
    height: 50px;
    min-width: 50px;
    width: 50px;

    img {
        height: 30px;
        filter: brightness(1000%);
    }
}

@media only screen and (max-width: 900px) {
    #schlossBtn {
        height: 40px;
        min-width: 40px;
        width: 40px;

        img {
            height: 21px !important;
            margin: 0 !important;
        }
    }
}

@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    #schlossBtn {
        margin-left: 0 !important;
        padding: 0 !important;
        width: 40px !important;

        img {
            margin: 0 !important;
        }
    }
}
</style>
