<template>
    <div>
        <v-row class="mx-0">
            <div class="chat-container whiteBg">

                <!--Header of Chat-->
                <div @click="readWidgetInfoText()" style="background-color: #8cbd46; border-radius: 15px 15px 0 0; padding-top: 10px; padding-bottom: 10px; text-align: left; color: white;">
                    <v-row style="max-width: 100%; display: flex; align-items: center; height: 35px" class="mx-2">
                        <v-col cols="9" md="8" lg="9" class="pa-0 pl-2 text-left d-flex align-center" style="font-size: larger">
                            <img :src="chatIcon" alt="" class="iconToWhite mr-1" style="height: 20px"/>
                            <h1 tabindex="0" class="mb-0" id="chatHeading">Chat</h1>
                        </v-col>
                        <v-col cols="3" md="4" lg="3" class="pa-0 pr-2 d-flex align-center justify-end">
                            <img :src="infoIcon" alt="" style="height: 15px;">
                        </v-col>
                    </v-row>
            </div>
                <ChatWidgetGroupList :current-child="currentChild" :rooms="rooms" @handleLockStatusChanged="handleLockStatusChanged" @roomSelected="handleRoomSelected" @setRoomList="setRoomList" v-if="!viewingRoom" :matrixClient="matrixClient"></ChatWidgetGroupList>
                <ChatWidgetGroupChat v-else :current-child="currentChild" :room="viewingRoom" :matrixClient="matrixClient" @exitRoom="handleExitRoom" @sendMessage="sendMessage" @deleteMessage="deleteMessage" :show-tooltips="showTooltips"></ChatWidgetGroupChat>
                <!-- Error Snackbar -->
                <v-snackbar bottom v-model="snackbar" :color="snackbarColor" timeout="5000">
                    <template v-slot:action="{ attrs }">
                        <v-btn
                        text
                        v-bind="attrs"
                        small
                        @click="snackbar = false"
                        >
                    <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon" />
                    </v-btn>
                    </template>
                    {{ snackbarText }}
                </v-snackbar>
            </div>
        </v-row>
    </div>
</template>

<script>
import ChatWidgetGroupList from "./ChatWidgetGroupList";
import ChatWidgetGroupChat from "./ChatWidgetGroupChat";
import {getMatrixAddress} from "../../util/getMatrixAddress";
import * as BackendApi from "../../api/backend";
import * as sdk from "matrix-js-sdk";
import {mapActions, mapState} from "vuex";

import chatIcon from "../../assets/Icons/chat-alternative-filled-102.svg";
import infoIcon from "@/assets/Icons/info-2-weiß-89.svg";
import abbrechenIcon from "@/assets/Icons/abbrechen-08.svg";

export default {
    name: "ChatWidget",
    components: {ChatWidgetGroupChat, ChatWidgetGroupList},
    data: () => ({
        chatIcon,
        infoIcon,
        abbrechenIcon,

        acc: null,
        matrixRooms: [],
        rooms: [],
        matrixClient: null,
        viewingRoom: null,
        numMessagesToShow: 20,
        groups: [],
        person: {
            accessibility: {
                screenreader: false,
            }
        },

        snackbar: false,
        snackbarText: '',
        snackbarColor: '',
    }),
    props: {
        showTooltips: {required: false, default: true},
        currentChild: {required: false, type: Object},
    },
    async created() {
    },

    async mounted() {
        this.prepareClient();
        this.requestMatrixGroups();
        this.acc = await this.getCurrentAccount();
        if(this.acc.role==='maintainer'){
            this.groups = await this.getGroupsInfo();
        }
        this.requestPerson();
    },
    async destroyed() {
        this.matrixClient.stopClient();
        console.log("Stop matrix client");
    },
    computed: {
        ...mapState('translation', ['targetLang',]),
    },
    methods: {
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation', 'translateToTargetLang' ]),
        ...mapActions('account', [ 'getCurrentAccount' ]),
        ...mapActions('groups', [ 'getGroupsInfo' ]),
        ...mapActions('matrix', ['getMatrixRoomLockStatus', 'getMatrixRoomLockStatusById']),
        ...mapActions('pupils', [ 'getMePupil' ]),

        async requestPerson() {
            if(this.acc.role === 'pupil') {
                this.person = await this.getMePupil();
            }
        },

        async requestMatrixGroups() {
            this.matrixRooms = await this.getMatrixRoomLockStatus();
        },

        async prepareClient() {
            const matrixUserResponse = await BackendApi.getCurrentMatrixUser();
            const matrixUser = await matrixUserResponse.json();

            // Transform name like @alice-1603048221873-estundenplan-localhost-simon:estundenplan-synapse-development
            // to alice-1603048221873-estundenplan-localhost-simon
            const loginName = matrixUser.name.slice(1).split(":")[0];

            const matrixServerUrl = getMatrixAddress();
            const loginDataResponse = await fetch(
                `${matrixServerUrl}/_matrix/client/r0/login`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        type: "m.login.password",
                        user: loginName,
                        password: matrixUser.password,
                    }),
                }
            );

            const loginData = await loginDataResponse.json();
            const matrixClient = sdk.createClient({
                baseUrl: matrixServerUrl,
                accessToken: loginData.access_token,
                userId: loginData.user_id,
            });
            this.matrixClient = matrixClient;

            matrixClient.on("sync", this.handleMatrixClientOnSync);
            matrixClient.on("Room", this.handleMatrixClientOnRoom);
            matrixClient.on("Room.timeline", this.handleMatrixClientOnRoomTimeline);

            matrixClient.startClient();
            console.log("Start matrix client");
        },

        async sendMessage(message) {
            if(this.acc.role === 'pupil'){
                const res = await this.getMatrixRoomLockStatusById(this.viewingRoom.roomId);
                if(res.status===401){
                    console.log('room is locked, cannot send message');
                    this.snackbarText = 'Der Chat ist momentan gesperrt. Nachricht wurde nicht gesendet.';
                    this.snackbarColor = 'error';
                    this.snackbar = true;
                    return;
                }
            }
            const content = {
                body: message,
                msgtype: "m.text",
            };
            this.matrixClient.sendEvent(
                this.viewingRoom.roomId,
                "m.room.message",
                content,
                "",
                (err, res) => {
                    console.log(err);
                    this.prepareClient();
                }
            );
        },

        async deleteMessage(message) {
            if(this.acc.role === 'pupil'){
                const res = await this.getMatrixRoomLockStatusById(this.viewingRoom.roomId);
                if(res.status===401){
                    console.log('room is locked, cannot delete message');
                    this.snackbarText = 'Der Chat ist momentan gesperrt. Nachricht wurde nicht gelöscht';
                    this.snackbarColor = 'error';
                    this.snackbar = true;
                    return;
                }
            }            
            this.matrixClient.redactEvent(
                this.viewingRoom.roomId,
                message.event.event_id,
                message._txnId,
                (err, res) => {
                    console.log(err);
                }
            );
        },

        async handleExitRoom() {
            await this.matrixClient.sendReceipt(
                this.viewingRoom.timeline[this.viewingRoom.timeline.length - 1],
                "m.read"
            );
            this.viewingRoom = null;
            await this.$nextTick();
            await this.$nextTick();
            document.getElementById("gruppenHeading").focus();
        },
        
        async changeRoomStatus(room, isOpen) {
            if(room.isOpen===isOpen){
                return;
            }
            this.rooms = this.rooms.map((el) => {
                if(el.roomId === room.roomId){
                    el.isOpen = isOpen;
                    console.log('changeRoomStatus, changed ', room, ' to ',isOpen);
                }
                return el;
            });
        },

        async handleRoomSelected(room) {
            if(this.acc===null){
                this.acc = await this.getCurrentAccount();
            }
            if(this.acc.role === 'pupil'){
                const res = await this.getMatrixRoomLockStatusById(room.roomId);
                if(res.status===401){
                    this.changeRoomStatus(room, false);
                    console.log('room is locked');
                    this.snackbarText = 'Der Chat ist momentan gesperrt.';
                    this.snackbarColor = 'error';
                    this.snackbar = true;
                    return;
                }
            }
            if(this.acc.role === 'pupil'){
                this.changeRoomStatus(room, true);
            }
            await this.matrixClient.joinRoom(room.roomId);
            await this.matrixClient.sendReceipt(
            room.timeline[room.timeline.length - 1],
            "m.read"
            );
            this.viewingRoom = room;
            await this.$nextTick();
            document.getElementById("backButton").focus();
        },

        handleMatrixClientOnSync(state, prevState, data) {
            switch (state) {
                case "PREPARED":
                    this.setRoomList();
                    break;
            }
        },
        handleMatrixClientOnRoom() {
            this.setRoomList();
            if (!this.viewingRoom) {
                // printRoomList();
                // rl.prompt();
            }
        },
        handleMatrixClientOnRoomTimeline(event, room, toStartOfTimeline) {
            if (toStartOfTimeline) {
                return; // don't print paginated results
            }
            if (!this.viewingRoom || this.viewingRoom.roomId !== room.roomId) {
                return; // not viewing a room or viewing the wrong room.
            }
        },
        handleLockStatusChanged(isOpen, roomId) {
            this.rooms = this.rooms.map((room) => {
                if(room.roomId === roomId){
                    room.isOpen = isOpen;
                }
                return room;
            });
        },
        setRoomList() {
            const roomList = this.matrixClient
                .getRooms()
                .filter((room) => room._selfMembership === "join");
            roomList.sort(function (a, b) {
                // < 0 = a comes first (lower index) - we want high indexes = newer
                var aMsg = a.timeline[a.timeline.length - 1];
                if (!aMsg) {
                    return -1;
                }
                var bMsg = b.timeline[b.timeline.length - 1];
                if (!bMsg) {
                    return 1;
                }
                if (aMsg.getTs() > bMsg.getTs()) {
                    return -1;
                } else if (aMsg.getTs() < bMsg.getTs()) {
                    return 1;
                }
                return 0;
            });
            this.rooms = roomList;
            // adds property isOpen from backend to matrixRoom
            this.rooms = this.rooms.map((room) => {
                const matchingRooms = this.matrixRooms.filter((matrixRoom) => {
                    return room.roomId===matrixRoom.roomId;
                })
                if(matchingRooms.length>0){
                    room.isOpen = matchingRooms[0].isOpen;
                } else {
                    //defaulting to open room if no match is found in backend, this should not happen
                    room.isOpen = true;
                    console.log('could not find matching matrix room in backend');
                }
                return room;
            });
            // if maintainer, remove chats that have groups in backend (means they are a classroom)
            if(this.acc.role==='maintainer'){
                this.rooms = this.rooms.filter((room) => {
                    const matchingRooms = this.groups.filter((group) => {
                        return room.name===group.name;
                    })
                    return matchingRooms.length===0;
                })
            }
        },

        async readWidgetInfoText() {
            if(!this.person.accessibility.screenreader) {
                if ('speechSynthesis' in window) {
                    // Speech Synthesis supported 🎉
                } else {
                    // Speech Synthesis Not Supported 😣
                    alert("Sorry, your browser doesn't support text to speech!");
                    return
                }

                this.acc = await this.getCurrentAccount();
                let text;
                if (this.acc.role === 'pupil') {
                    text = 'Im Chat kannst du Kontakt zu anderen Schülern und deinen Lehrern aufnehmen.';
                } else if (this.acc.role === 'teacher') {
                    text = 'Im Chat können Sie Kontakt zu Schülern und Eltern aufnehmen.';
                } else { //parent
                    text = 'Im Chat können Sie zu zuständigen Lehrern Ihres Kindes Kontakt aufnehmen.';
                }

                if (window.speechSynthesis.speaking) {
                    window.speechSynthesis.cancel();
                    this.showTranslation(false);
                } else {
                    if (this.targetLang !== 'de') {
                        text = await this.translateToTargetLang({
                            targetLang: this.targetLang, textToTranslate: text
                        });
                    }

                    this.setTranslatedText(text);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000)

                    let msg = new SpeechSynthesisUtterance();
                    msg.text = text;
                    if (this.isLangPackageAvailable()) {
                        msg.lang = this.targetLang;
                        window.speechSynthesis.speak(msg);
                    }
                }
            }
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
.chat-container {
    height: auto;
    width: 90%;
    min-height: 80vh;
    max-height: 80vh;
    // max-height: 80vh;
    margin: auto;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    margin-bottom: 5em;
    display: flex;
    flex-direction: column;
}

.chat-header {
    background-color: #3baa69;
    border-radius: 15px 15px 0 0;
    font-size: larger;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: left;
    color: white;
}

.chat-header-btn {
    background-color: #f8f8f880 !important;
    width: 35px;
    height: 35px !important;
}

.chat-header-img {
    height: 20px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scroll-area {
    position: relative;
    max-height: calc(80vh - 75px);
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.whiteBg {
    background-color: #ffffff;
}

//make text not selectable
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

h1 {
  display: inherit;
  font-size: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
  margin-left: inherit;
  margin-right: inherit;
  font-weight: inherit;
}
</style>
