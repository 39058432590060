<template>
    <BaseMenu
        :items="items"
        :show-list="showList"
        @close="close"
        @itemClicked="onItemSelected"
    >
        <!-- Add custom data here -->
        <v-container v-if="notizHTML">
            <v-row>
                <v-col>
                    <h3>Notiz einfügen</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-textarea outlined label="Dein Text" v-model="textAreaValue" />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn @click="addTextMessage" color="success">Platzieren</v-btn>
                    <v-btn @click="abort" color="error" class="ml-1">Abbrechen</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </BaseMenu>
</template>

<script>
import BaseMenu from "@/components/Editortools/Toolbar/Menus/BaseMenu";
import audioIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_Sprachmemo.svg';
import textIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_Textmemo.svg';
import { memoMenuHelp } from '@/constants/editorHelpCollection';

export default {
    name: "MemoMenu",
    components: { BaseMenu },
    data() {
        return {
            showList: true,
            audioHTML: false,

            // Text Notiz
            notizHTML: false,
            textAreaValue: null,

            items: [
                { icon: audioIcon, text: 'Aufnahme', help: { type: 'text', content: memoMenuHelp[0] } },
                { icon: textIcon, text: 'Notiz', help: { type: 'text', content: memoMenuHelp[1] } },
            ],

            //Icons
            audioIcon,
            textIcon,
        }
    },
    methods: {
        onItemSelected(item){
            console.log("Item selected", item);
            if (item.text === 'Aufnahme') {
                this.addAudioMessage();
            } else if (item.text === 'Notiz') {
                this.notizHTML = true;
                this.showList = false;
            }
        },
        addAudioMessage() {
            this.audioHTML = false;
            this.showList = true;
            this.$emit('addAufnahme');
        },
        addTextMessage() {
            this.$emit('addNotiz', this.textAreaValue);
        },

        /**
         * General
         */
        abort() {
            this.audioHTML = false;

            this.notizHTML = false;
            this.textAreaValue = null;

            this.showList = true;
        },
        close() {
            this.abort();

            this.$emit('close');
        }
    }
}
</script>

<style scoped>

</style>
