<template>
    <div>
        <!--top menu bar-->
        <v-row style="background-color: #EEEEEE;" >

          <v-col style="align-content: space-evenly; padding: 11px 0px 3px 12px;" cols="5" class="d-flex ml-2">
                <div class="topNavMobile" style="margin-left: 6%">
                    <v-btn
                            @click="handleTopMenuClick"
                            ref="topMenuBtn" elevation="0"
                            class="btmNavBtn pa-0 ml-4 animateIcon"
                            style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                            x-small
                            :style="topMenu ? 'background-color: #6995CD !important;' : ''">
                        <img :src="menuIcon" style="max-width: 25px; max-height: 25px;" :style="topMenu ? 'filter: brightness(1000%);' : 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);'">
                    </v-btn>

                  <!--menu content-->
                  <div v-closable="{exclude: ['topMenuBtn'], handler: 'onCloseTop'}" style="z-index: 2;" class="d-flex flex-column pa-2 topMenu" :class="topMenu ? '' : 'hideMe'">
                    <!-- Stundenplan -->
                    <div class="d-flex align-center" style="flex-direction: row;">
                      <v-btn
                          link
                          to="/verwaltung/plan"
                          active-class="activeLink"
                          style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                          x-small elevation="0"
                          class="ml-4"
                      >
                      <img :src="stundenplanIcon" style="max-width: 25px; max-height: 25px;"/>
                      </v-btn>
                      <div class="ml-2">Stundenplan</div>
                    </div>

                    <!-- Schwarzes Brett -->
                    <div class="d-flex align-center" style="flex-direction: row;">
                    <v-btn
                        link
                        to="/verwaltung/schwarzes-brett"
                        active-class="activeLink"
                        style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                        x-small elevation="0"
                        class="ml-4"
                    >
                      <img :src="swbIcon" style="max-width: 25px; max-height: 25px;"/>
                    </v-btn>
                      <div class="ml-2">Schw. Brett</div>
                    </div>

                    <!-- Schüler -->
                    <div class="d-flex align-center" style="flex-direction: row;">
                    <v-btn
                        link
                        to="/verwaltung/schueler"
                        active-class="activeLink"
                        style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                        x-small elevation="0"
                        class="ml-4"
                    >
                      <img :src="schuelerIcon" style="max-width: 25px; max-height: 25px;"/>
                    </v-btn>
                      <div class="ml-2">Schüler</div>
                    </div>

                    <!-- Lehrer -->
                    <div class="d-flex align-center" style="flex-direction: row;">
                    <v-btn
                        link
                        to="/verwaltung/lehrer"
                        active-class="activeLink"
                        style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                        x-small elevation="0"
                        class="ml-4"
                    >
                      <img :src="lehrerIcon" style="max-width: 25px; max-height: 25px;"/>
                    </v-btn>
                      <div class="ml-2">Lehrer</div>
                    </div>

                    <!-- Gruppen -->
                    <div class="d-flex align-center" style="flex-direction: row;">
                    <v-btn
                        link
                        to="/verwaltung/groups"
                        active-class="activeLink"
                        style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                        x-small elevation="0"
                        class="ml-4"
                    >
                      <img :src="gruppenIcon" style="max-width: 25px; max-height: 25px;"/>
                    </v-btn>
                      <div class="ml-2">Gruppen</div>
                    </div>

                    <!-- Fächer -->
                    <div class="d-flex align-center" style="flex-direction: row;">
                    <v-btn
                        link
                        to="/verwaltung/faecher"
                        active-class="activeLink"
                        style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                        x-small elevation="0"
                        class="ml-4"
                    >
                      <img :src="faecherIcon" style="max-width: 25px; max-height: 25px;"/>
                    </v-btn>
                      <div class="ml-2">Fächer</div>
                    </div>

                    <!-- Schulzeiten -->
                    <div class="d-flex align-center" style="flex-direction: row;">
                    <v-btn
                        link
                        to="/verwaltung/zeitslots"
                        active-class="activeLink"
                        style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                        x-small elevation="0"
                        class="ml-4"
                    >
                      <img :src="uhrIcon" style="max-width: 25px; max-height: 25px;"/>
                    </v-btn>
                      <div class="ml-2">Schulzeiten</div>
                    </div>

                    <!-- Chat -->
                    <div class="d-flex align-center" style="flex-direction: row;">
                    <v-btn
                        link
                        to="../verwaltung/chat-verwaltung"
                        active-class="activeLink"
                        style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                        x-small elevation="0"
                        class="ml-4"
                    >
                      <img :src="chatIcon" style="max-width: 25px; max-height: 25px;"/>
                    </v-btn>
                      <div class="ml-2">Chat</div>
                    </div>
                  </div>
                </div>


<!--            <v-tooltip bottom>-->
<!--                <template v-slot:activator="{ on, attrs }">-->
                  <div class="d-flex align-center ml-4" style="flex-direction: column; flex: 1;">
                    <v-btn
                        link
                        to="/verwaltung/plan"
                        active-class="activeLink"
                        x-small elevation="0"
                        class="topNav"
                    >
                      <img :src="stundenplanIcon"/>
                    </v-btn>
                    <div class="text-center topNavText mt-1">Stundenplan</div>

                  </div>

<!--                </template>-->
<!--                    <span>Stundenplan</span>-->
<!--                </v-tooltip>-->

<!--                <v-tooltip bottom>-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
                      <div class="d-flex align-center" style="flex-direction: column; flex: 1;">
                        <v-btn
                            link
                            to="/verwaltung/schwarzes-brett"
                            active-class="activeLink"
                            x-small elevation="0"
                            class="topNav"
                        >
                          <img :src="swbIcon"/>
                        </v-btn>
                        <div class="text-center topNavText mt-1">Schw. Brett</div>
                      </div>
<!--                    </template>-->
<!--                    <span>Schwarzes Brett</span>-->
<!--                </v-tooltip>-->

<!--                <v-tooltip bottom>-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
                      <div class="d-flex align-center" style="flex-direction: column; flex: 1;">
                        <v-btn
                            link
                            to="/verwaltung/schueler"
                            active-class="activeLink"
                            x-small elevation="0"
                            class="topNav"
                        >
                            <img :src="schuelerIcon"/>
                        </v-btn>
                        <div class="text-center topNavText mt-1">Schüler</div>
                      </div>
<!--                    </template>-->
<!--                    <span>Schüler</span>-->
<!--                </v-tooltip>-->

<!--                <v-tooltip bottom>-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
                      <div class="d-flex align-center" style="flex-direction: column; flex: 1;">
                        <v-btn
                            link
                            to="/verwaltung/lehrer"
                            active-class="activeLink"
                            x-small elevation="0"
                            class="topNav"
                        >
                            <img :src="lehrerIcon"/>
                        </v-btn>
                        <div class="text-center topNavText mt-1">Lehrer</div>
                      </div>
<!--                    </template>-->
<!--                    <span>Lehrer</span>-->
<!--                </v-tooltip>-->

<!--                <v-tooltip bottom>-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
                      <div class="d-flex align-center" style="flex-direction: column; flex: 1;">
                        <v-btn
                            link
                            to="/verwaltung/groups"
                            active-class="activeLink"
                            x-small elevation="0"
                            class="topNav"
                        >
                            <img :src="gruppenIcon"/>
                        </v-btn>
                        <div class="text-center topNavText mt-1">Gruppen</div>
                      </div>
<!--                    </template>-->
<!--                    <span>Gruppen</span>-->
<!--                </v-tooltip>-->

<!--                <v-tooltip bottom>-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
                      <div class="d-flex align-center" style="flex-direction: column; flex: 1;">
                        <v-btn
                            link
                            to="/verwaltung/faecher"
                            active-class="activeLink"
                            x-small elevation="0"
                            class="topNav"
                        >
                            <img :src="faecherIcon"/>
                        </v-btn>
                        <div class="text-center topNavText mt-1">Fächer</div>
                      </div>
<!--                    </template>-->
<!--                    <span>Fächer</span>-->
<!--                </v-tooltip>-->

<!--                <v-tooltip bottom>-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
                      <div class="d-flex align-center" style="flex-direction: column; flex: 1;">
                        <v-btn
                            link
                            to="/verwaltung/zeitslots"
                            active-class="activeLink"
                            x-small elevation="0"
                            class="topNav"
                        >
                            <img :src="uhrIcon" />
                        </v-btn>
                        <div class="text-center topNavText mt-1">Schulzeiten</div>
                      </div>
<!--                    </template>-->
<!--                    <span>Schulzeiten</span>-->
<!--                </v-tooltip>-->

<!--                <v-tooltip bottom>-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
                      <div class="d-flex align-center" style="flex-direction: column; flex: 1;">
                        <v-btn
                            link
                            to="../verwaltung/chat-verwaltung"
                            active-class="activeLink"
                            x-small elevation="0"
                            class="topNav"
                        >
                            <img :src="chatIcon"/>
                        </v-btn>
                        <div class="text-center topNavText mt-1">Chat</div>
                      </div>
<!--                    </template>-->
<!--                    <span>Chat</span>-->
<!--                </v-tooltip>-->
            </v-col>


          <v-col class="d-flex align-center ml-4" cols="auto">
            <img :src="logo" height="50px">
          </v-col>


            <!--name and options-->
            <v-col class="d-flex justify-end align-center" style="margin-right: 5%">

                <div>
                    <v-btn
                            link
                            to="/verwaltung/faq"
                            style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                            x-small elevation="0"
                            class="ml-4"
                    >
                        <div style="color: #737373">FAQ</div>
<!--                        <div style="border: solid 0.24em #737373; border-radius: 50%; width: 24px; height: 24px; color: #737373; text-align: center; margin: auto;vertical-align: middle; line-height: 22px; font-size: 8px" >FAQ</div>-->
                    </v-btn>
                </div>

                <div>
                    <v-btn
                        link
                        to="/verwaltung/tutorial"
                        style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                        x-small elevation="0"
                        class="ml-4"
                    >
                        <img :src="helpIcon" style="max-width: 25px; max-height: 25px;"/>
                    </v-btn>
                </div>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                @click="reloadPage()"
                                style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                                x-small elevation="0"
                                class="ml-4 animateIcon"
                                v-on="on"
                                v-bind="attrs"
                        >
                            <img :src="aktualisierenIcon" style="max-width: 25px; max-height: 25px;"/>
                        </v-btn>
                    </template>
                    <span>Aktualisieren</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                @click="clickLogoutUser"
                                style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                                x-small elevation="0"
                                class="ml-4 "
                                v-on="on"
                                v-bind="attrs"
                        >
                            <img :src="logoutIcon" style="max-height: 25px" />
                        </v-btn>
                    </template>
                    <span>Abmelden</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import stundenplanIcon from '../../assets/Icons/stundenplan-neu-neu-99.svg'
import schuelerIcon from '../../assets/Icons/profil-schueler.svg'
import gruppenIcon from '../../assets/Icons/gruppen-25.svg'
import lehrerIcon from '../../assets/Icons/lehrer-24.svg'
import faecherIcon from '../../assets/Icons/fächer-05.svg'
import uhrIcon from '../../assets/Icons/uhr-04.svg'
import logoutIcon from '../../assets/Icons/exit-20.svg'
import pinIcon from '../../assets/Icons/pin-63.svg'
import swbIcon from '../../assets/Icons/sw-brett-neu-100.svg'
import menuIcon from "@/assets/Icons/einstellungen-neu-103.svg";
import fragezeichenIcon from "@/assets/Icons/Fragezeichen-83.svg";
import abbrechenIcon from "@/assets/Icons/abbrechen-08.svg";
import aktualisierenIcon from "@/assets/Icons/aktualisieren-verwaltung-107.svg";
import logo from "@/assets/logo_klassenraum.png";
import chatIcon from "@/assets/Icons/chat-alternative-verwaltung-102.svg";
import helpIcon from '@/assets/Icons/Fragezeichen-83.svg'

// This variable will hold the reference to document's click handler, used for the v-directive closable
let handleOutsideClick

export default {
    name: "Header",

    directives: {
        //directive from https://tahazsh.com/detect-outside-click-in-vue with changes from Peter Means (in comments)
        closable: {
            bind (el, binding, vnode) {
                // Here's the click/touchstart handler (it is registered below)
                handleOutsideClick = (e) => {
                    e.stopPropagation()
                    // Get the handler method name and the exclude array from the object used in v-closable
                    const { handler, exclude } = binding.value

                    // This variable indicates if the clicked element is excluded
                    let clickedOnExcludedEl = false
                    exclude.forEach(refName => {
                        // We only run this code if we haven't detected any excluded element yet
                        if (!clickedOnExcludedEl) {
                            // Get the element using the reference name
                            const excludedEl = vnode.context.$refs[refName];
                            let excludedDomEl = null;
                            if (excludedEl) {
                                // If it's a vue component grab the element, otherwise it is the element
                                excludedDomEl = excludedEl.$el ? excludedEl.$el : excludedEl;
                                clickedOnExcludedEl = excludedDomEl.contains(e.target);
                            }
                        }
                    })
                    // We check to see if the clicked element is not the dialog element and not excluded
                    if (!el.contains(e.target) && !clickedOnExcludedEl) {
                        // If the clicked element is outside the dialog and not the button,
                        // then call the outside-click handler from the same component this directive is used in
                        vnode.context[handler]()
                    }
                }
                // Register click/touchstart event listeners on the whole page
                document.addEventListener('click', handleOutsideClick)
                document.addEventListener('touchstart', handleOutsideClick)
            },

            unbind () {
                // If the element that has v-closable is removed, then
                // unbind click/touchstart listeners from the whole page
                document.removeEventListener('click', handleOutsideClick)
                document.removeEventListener('touchstart', handleOutsideClick)
            }
        }
    },

    data() {
        return {
            // Icons
            stundenplanIcon,
            schuelerIcon,
            gruppenIcon,
            lehrerIcon,
            faecherIcon,
            uhrIcon,
            chatIcon,
            logoutIcon,
            pinIcon,
            swbIcon,
            menuIcon,
            fragezeichenIcon,
            abbrechenIcon,
            aktualisierenIcon,
            logo,
            helpIcon,

            // Flags
            topMenu: false,
        };
    },
    props: {

    },
    async created() {
    },
    computed: {

    },
    methods: {
        ...mapActions("auth", ["logoutUser", "reloadDontLogout"]),
        clickLogoutUser() {
            this.logoutUser();
        },
        reloadPage() {
            this.reloadDontLogout(true);
            this.$router.go();
        },
        /*topMenu methods*/
        handleTopMenuClick() {
            this.topMenu = !this.topMenu;
        },
        onCloseTop() {
            this.closeTopMenus();
        },
        closeTopMenus() {
            this.topMenu = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.headerAvatar {
    width: 20px;
    height: 20px;
}
@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    .displayHide {
        display: none;
    }

    .topPadding {
        padding-top: 12px;
    }

    .btmNav {
        display: block;
    }
}
.hideMe {
    display: none !important;
}

.topNav {
    background-color: #FFFFFF !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 8px;

    & img {
        max-width: 25px !important;
        max-height: 25px !important;
    }
}

.topMenu {
    position: absolute;
    top: 64px;
    left: 30px;
    width: 200px;
    max-width: 200px;
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0 2px 4px silver;
}

.animateIcon {
    overflow: hidden;
}

.animateIcon:after {
    animation: shine 60s ease-in-out infinite;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0.0);
    background: linear-gradient(
            to right,
            rgba(112, 112, 112, 0.2) 0%,
            rgba(112, 112, 112, 0.2) 77%,
            rgba(112, 112, 112, 0.5) 92%,
            rgba(112, 112, 112, 0.0) 100%
    );
}

/* Hover state - trigger effect */

/* Active state */
.animateIcon:active:after {
    opacity: 0;
}

@keyframes shine{
    1% {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    5% {
        opacity: 0;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
    }
}

@media only screen and (max-width: 1485px){
    .topNav, .topNavText{
        display: none;
    }
}
@media only screen and (min-width: 1485px){
    .topNavMobile{
        display: none;
    }
}



.activeLink {
    background-color: rgba(105, 149, 205, 0.8)!important;

    & img {
        filter: brightness(1000%);
    }

    & div {
        color: white !important;
        filter: brightness(1000%);
    }
}
</style>
