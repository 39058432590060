import { render, staticRenderFns } from "./BabyChatDropDown.vue?vue&type=template&id=6b1a2176&scoped=true&"
import script from "./BabyChatDropDown.vue?vue&type=script&lang=js&"
export * from "./BabyChatDropDown.vue?vue&type=script&lang=js&"
import style0 from "./BabyChatDropDown.vue?vue&type=style&index=0&id=6b1a2176&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b1a2176",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VBtn,VOverlay,VTooltip})
