<template>
    <div id="btnsContainer" class="d-flex justify-start">
        <!-- pupil -->
        <v-tooltip v-if="account.role === 'pupil'" :value="shortcutTooltips" :disabled="!showTooltips || bottomNav" bottom>
            <template v-slot:activator="{ on }">
                <v-btn
                        :aria-expanded="menu"
                    v-on="on"  @click="requestKlassenraums(true)"
                    id="joinKlassenraumBtnPupil" :color="atLeastOneKlassenraumOpen ? 'gruen' : 'fgrau'" elevation="0"
                    :aria-label="atLeastOneKlassenraumOpen ? 'Klassenraum geöffnet' : 'Klassenraum geschlossen'"
                        role="menu"
                        aria-haspopup="menu"
                >
                    <img :src="videoWeissIcon"/>
                    <p><span style="text-decoration: underline white">K</span>lassenraum</p>
                </v-btn>
            </template>
            <span v-if="atLeastOneKlassenraumOpen">Erstem <span style="text-decoration: underline white">K</span>lassenraum beitreten (Alt + K)</span>
            <span v-else><span style="text-decoration: underline white">K</span>lassenraum ist geschlossen</span>
        </v-tooltip>
        <!-- teacher -->
        <v-btn
            v-if="account.role === 'teacher'" @click="joinKlassenraum(teacherClassroomId)"
            id="joinKlassenraumBtnTeacher" :color="teacherClassroomIsOpen ? 'gruen' : 'fgrau'"
            style="margin-left: 15px" elevation="0"
        >
            <img :alt="teacherClassroomIsOpen ? 'Klassenraum geöffnet' : 'Klassenraum geschlossen'" :src="videoWeissIcon"/>
            <p>Schüler</p>
        </v-btn>

        <v-btn v-if="account.role === 'teacher'" @click="toggleKlassenraum"
               :color="teacherClassroomIsOpen ? 'gruen' : 'fgrau'" id="schlossBtn" class="ml-2" elevation="0"
               :small="this.windowWidth <= 900 && this.windowWidth > 770"
               :x-small="this.windowWidth <= 770"
        >
            <img :alt="teacherClassroomIsOpen ? 'Raum geöffnet' : 'Raum geschlossen'" :src="teacherClassroomIsOpen ? schlossOffenIcon : schlossIcon">
        </v-btn>

        <!-- pupil can chose from teachers -->
        <div v-if="menu && this.windowWidth >= 770" v-click-outside="onClickOutsideMenu" id="klassenraumMenu">
            <v-row v-for="(klassenraum, index) in klassenraums" :key="index" class="pa-0 ma-3">
                <v-col cols="10" class="pa-0" @click="readKlassenraumInfo(getAppropriateTeacherDisplayName(klassenraum.teacher))">
                    <v-btn tabindex="-1" aria-hidden="true" color="hellgrau" width="95%" class="d-flex justify-start">
                        <img :src="getTeachPic(klassenraum.teacher)" alt="" :class="(hasProfilePic(klassenraum.teacher) ? 'roundIcon' : '') + ' mr-2'">
                        <p class="text-capitalize ma-0" style="color: #707070">{{ getAppropriateTeacherDisplayName(klassenraum.teacher) }}</p>
                    </v-btn>
                </v-col>

                <v-col cols="2" class="pa-0 pl-2 d-flex justify-end" @click="joinKlassenraum(klassenraum._id)">
                    <v-tooltip :value="showSelectionTooltips" :disabled="!showTooltips || !klassenraum.isOpen" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" class="videoBtn" x-small
                                   role="menuitem"
                                   :aria-label="klassenraum.isOpen ? 'Klassenraum von ' + getAppropriateTeacherDisplayName(klassenraum.teacher) + ' beitreten' : 'Klassenraum von ' + getAppropriateTeacherDisplayName(klassenraum.teacher) + ' ist geschlossen'"
                                   :color="klassenraum.isOpen ? 'gruen' : 'hellgrau'"
                                    @focusout="() => { if(index === klassenraums.length - 1) menu = false }">
                                <img :alt="klassenraum.isOpen ? 'Klassenraum beitreten (Alt + K)' : 'Klassenraum geschlossen'" :src="klassenraum.isOpen ? videoWeissIcon : videoIcon" :style="klassenraum.isOpen ? 'filter: brightness(1000%)' : ''">
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span><span style="text-decoration: underline white">K</span>lassenraum beitreten (Alt + K)</span>
                            <img v-if="hoeren" :src="beitretenMetacom" width="100" style="margin: auto">
                        </div>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>

        <!-- pupil can chose from teachers in MOBILE -->
        <div v-if="menu && this.windowWidth < 770" v-click-outside="onClickOutsideMenu" id="klassenraumMenuBtm">
            <v-row v-for="(klassenraum, index) in klassenraums" :key="index" class="pa-0 ma-3">
                <v-btn @click="readKlassenraumInfo(getAppropriateTeacherDisplayName(klassenraum.teacher))"
                       color="hellgrau" class="d-flex justify-start" style="width: 140px">
                    <img :src="getTeachPic(klassenraum.teacher)" alt="Profilsymbol" :class="(hasProfilePic(klassenraum.teacher) ? 'roundIcon' : '') + ' mr-2'">
                    <p class="ellipsis text-capitalize text-left ma-0" style="width: 80px; color: #707070">{{ getAppropriateTeacherDisplayName(klassenraum.teacher) }}</p>
                </v-btn>

                <v-btn @click="joinKlassenraum(klassenraum._id)"
                       :color="klassenraum.isOpen ? 'gruen' : 'hellgrau'" class="videoBtn" x-small>
                    <img :src="klassenraum.isOpen ? videoWeissIcon : videoIcon" :alt="klassenraum.isOpen ? 'Videokonferenz offen' : 'Videokonferenz geschlossen'" :style="klassenraum.isOpen ? 'filter: brightness(1000%)' : ''">
                </v-btn>
            </v-row>
        </div>

        <!-- Error Snackbar -->
        <v-snackbar bottom v-model="snackbar" :color="snackbarColor" timeout="5000">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbar = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon" />
            </v-btn>
          </template>
            {{ snackbarText }}
        </v-snackbar>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';

import videoIcon from '@/assets/Icons/kamera-video-72.svg';
import videoWeissIcon from '@/assets/Icons/kamera-video-weiss-72.svg';
import schlossIcon from '@/assets/Icons/passwort-weiß-02.svg';
import schlossOffenIcon from '@/assets/Icons/schloss-geöffnet-123.svg';
import lehrerIcon from '@/assets/Icons/lehrer-24.svg';
import abbrechenIcon from "@/assets/Icons/abbrechen-08.svg";
import beitretenMetacom from '../assets/METACOM/Stundenplan/indieschule.png';

export default {
    name: "JoinKlassenraumButton",
    data() {
        return {
            videoIcon,
            videoWeissIcon,
            schlossIcon,
            schlossOffenIcon,
            lehrerIcon,
            abbrechenIcon,
            beitretenMetacom,

            snackbar: false,
            snackbarText: '',
            snackbarColor: '',
            menu: '',
            klassenraums: '',
            teacherClassroomId: '',
            teacherClassroomIsOpen: null,
            atLeastOneKlassenraumOpen: null,      //to make Klassenraum-btn for pupil green if >= 1 room isOpen
            teacherPics: [],

            sehen: false,
            hoeren: false,
            babyView: false,

            windowWidth: window.innerWidth,
        };
    },
    async mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
        window.addEventListener("keyup", event => {
            if (event.altKey && event.key === 'k') {
                console.log('now')
                if (this.atLeastOneKlassenraumOpen) {
                    this.joinKlassenraum(this.klassenraums[0]._id);
                    this.triggerShortcutTooltips();
                }
            }
        });
        if(this.account.role === 'pupil') {
            this.requestBabyViewAndAccessibilityMode();
        }

        await this.getTeacherRoom();
        await this.requestKlassenraums();
        await this.setTeachProfilePics();
    },
    props: {
        bottomNav: {type: Boolean, required: false, default: false},
    },
    computed : {
        ...mapState("auth", ["account"]),
        ...mapState('translation', ['targetLang']),
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),

        showSelectionTooltips() {
            return (this.shortcutTooltips && this.menu);
        }
    },
    methods: {
        ...mapActions('util', [ 'toggleLoading' ]),
        ...mapActions('auth', [ 'reloadDontLogout' ]),
        ...mapActions('rooms', [ 'teacherClassroom', 'changeRoomStatus', 'joinClassroom' ]),
        ...mapActions('teachers', [ 'getProfilePicture' ]),
        ...mapActions('translation', [ 'showTranslation', 'setTranslatedText', 'translateToTargetLang' ]),
        ...mapActions("pupils", ["getMePupil"]),
        ...mapActions("tooltips", ["triggerShortcutTooltips"]),

        async requestBabyViewAndAccessibilityMode() {
            let temp = await this.getMePupil();
            this.babyView = temp.babyView;
            if(temp.accessibility) {
                this.sehen = temp.accessibility.sehen;
                this.hoeren = temp.accessibility.hoeren;
            } else {
                this.sehen = false;
                this.hoeren = false;
            }
            this.loaded = true; //to check if they are babys
            if (this.babyView) {
                this.babyViewTut = true;
            }
        },

        onResize () {
            this.windowWidth = window.innerWidth;
        },

        async joinKlassenraum(id) {
            this.toggleLoading(true);

            const response = await this.joinClassroom(id);
            if(this.account.role === 'teacher') {
                if(!response.redirectUrl) {
                    this.snackbarText = 'Um den Klassenraum betreten zu können, müssen Sie ihn erst öffnen. Dazu müssen Sie das Schloss anklicken.';
                    this.snackbarColor = 'fgrau';
                    this.snackbar = true;
                    this.toggleLoading(false);
                    return;
                }
            } else {
                if(!response.redirectUrl) {
                    this.snackbarText = 'Dieser Klassenraum ist geschlossen.';
                    this.snackbarColor = 'fgrau';
                    this.snackbar = true;
                    this.toggleLoading(false);
                    return;
                }
            }

            if(!response || response === 422 || response === 404) {
                this.snackbarText = 'Der Klassenraum konnte nicht geladen werden - Bitte aktualisieren Sie die Seite!';
                this.snackbarColor = 'error';
                this.snackbar = true;
                this.toggleLoading(false);
                return;
            }

            this.reloadDontLogout(true);
            window.location.href = response.redirectUrl;

            this.toggleLoading(false);
        },

        async toggleKlassenraum() {
            let newStatus = !this.teacherClassroomIsOpen;
            let data = {
                classroomId: this.teacherClassroomId,
                newStatus: newStatus,
            }
            let res = await this.changeRoomStatus(data);

            if(res) {   // if successful also change the local one
                this.teacherClassroomIsOpen = res.isOpen;

                // and show snackbar
                if (this.teacherClassroomIsOpen) {
                    this.snackbarText = 'Der Klassenraum ist nun geöffnet.';
                    this.snackbarColor = 'gruen';
                    this.snackbar = true;
                } else {
                    this.snackbarText = 'Der Klassenraum wurde geschlossen.';
                    this.snackbarColor = 'gruen';
                    this.snackbar = true;
                }
            }
        },

        async requestKlassenraums(menu) {
            // make sure route is only called initial and if pupil opens menu, cause big route
            if(this.account.role === 'pupil' && !this.menu) {
                this.klassenraums = await this.teacherClassroom();
                for(let i = 0; i < this.klassenraums.length; i++) {
                    if(this.klassenraums[i].teacher === null) {
                        this.klassenraums[i].teacher = {
                            _id: null,
                            name: "Bugfix",
                            lastName: "Bugfix",
                            gender: "d",
                            note: "",
                            schoolSubjects: [],
                            profilePicture: "",
                            email: "bugfix@bugfix.de",
                            account: {
                                groups: [],
                                qrPassword: [],
                                role: 'teacher',
                                accountName: 'Bugfix',
                                privateFolder: [],
                                note: '',
                            }
                        }
                    }
                    if (this.klassenraums[i].isOpen) {
                        this.atLeastOneKlassenraumOpen = true;
                        break;
                    }
                }
            }
            // if called on click of Klassenraum btn, also toggle the menu
            if(menu && this.atLeastOneKlassenraumOpen) {
                this.menu = !this.menu;
            }
        },

        getAppropriateTeacherDisplayName(teacher) {
          const teacherBugfix = teacher || {};
          let prefix = '';

          if (teacherBugfix.gender === 'm') {
            prefix = 'Hr. ';
          } else if (teacherBugfix.gender === 'w') {
            prefix = 'Fr. ';
          }

          const lastName = teacherBugfix.lastName;

          return prefix + lastName;
        },

        async getTeacherRoom() {
            if(this.account.role === 'teacher') {
                let room = await this.teacherClassroom();
                this.teacherClassroomId = room._id;
                this.teacherClassroomIsOpen = room.isOpen;
            }
        },

        onClickOutsideMenu() {
            this.menu = false;
        },

        async readKlassenraumInfo(teacher) {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
            }

            let text = 'Um den Klassenraum von ' + teacher + ' zu betreten klicke auf die Fläche mit dem Kamera-Symbol, wenn es grün ist. Ist die Fläche grau, ist der Raum geschlossen.';

            if(window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel()
            } else {
                if (this.targetLang !== 'de') {
                    text = await this.translateToTargetLang({
                        targetLang: this.targetLang,
                        textToTranslate: text
                    });

                    this.setTranslatedText(text);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000)
                }

                let msg = new SpeechSynthesisUtterance();
                msg.text = text;
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                }
            }
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }
            return false;
        },

        // get profile pics from all teachers and save them in teacherPics array
        async setTeachProfilePics() {
            for (let i = 0; i < this.klassenraums.length; i++) {
                let profilePictureBlob = await this.getProfilePicture(this.klassenraums[i].teacher._id);

                if (profilePictureBlob !== 404) {
                    let objectURL = URL.createObjectURL(profilePictureBlob);
                    this.teacherPics.push([this.klassenraums[i].teacher._id, objectURL]);
                }
            }
        },

        // checks if provided user has a profile pic
        hasProfilePic(user) {
            if(!user) {
                return false;
            }
            for (let j = 0; j < this.teacherPics.length; j++) {
                let currTeacher = this.teacherPics[j];
                if (currTeacher === undefined) {
                    continue;
                } else if (currTeacher[0] === user._id) {
                    return true;
                }
            }
            return false;
        },

        // get teacher profile pic from teacherPics array
        getTeachPic(user) {
            if (!user) {
                return;
            }
            if (user._id == 0) {
                return null;
            }
            for (let j = 0; j < this.teacherPics.length; j++) {
                let currTeacher = this.teacherPics[j];
                if (currTeacher === undefined) {
                    continue;
                } else if (currTeacher[0] === user._id) {
                    return currTeacher[1];
                }
            }
            return lehrerIcon;
        },
    }
}
</script>

<style scoped lang="scss">
.v-btn {
  border-radius: 8px;
}

.ellipsis {
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.roundIcon {
    border-radius: 50%;
}

#joinKlassenraumBtnTeacher {
    color: white;
    text-transform: inherit;
    height: 50px !important;

    img {
      height: 30px;
      filter: brightness(1000%);
      margin-right: 5px;
    }

    p {
        font-size: large;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 0;
    }
}

#joinKlassenraumBtnPupil {
    color: white;
    text-transform: inherit;
    height: 50px !important;

    img {
      height: 30px;
      filter: brightness(1000%);
      margin-right: 5px;
    }

    p {
      font-size: large;
      font-weight: 400;
      line-height: 0;
      margin-bottom: 0;
    }
}

#schlossBtn {
    height: 50px;
    min-width: 50px;
    width: 50px;

    img {
        height: 30px;
        filter: brightness(1000%);
    }
}

#klassenraumMenu {
    position: fixed;
    top: 74px;
    width: 250px;
    background-color: white;
    border-radius: 0 0 0 8px;
    box-shadow: 0 2px 4px silver;
    z-index: 10; //to be one higher than fach
    color: var(--v-grau-base);

    img {
      height: 25px;
    }
}

.videoBtn {
    height: 36px !important;
    width: 36px !important;
}

@media only screen and (max-width: 900px) {
    #joinKlassenraumBtnTeacher {
        height: 40px !important;
        width: 144px;
        padding: 8px;
        margin-left: 0;
        margin-bottom: 8px;

        p {
            font-size: small;
        }

        img {
            height: 21px !important;
            margin-right: 8px !important;
        }
    }

    #joinKlassenraumBtnPupil {
        height: 40px !important;
        width: 144px;
        padding: 8px;
        margin-left: 0;
        margin-bottom: 8px;

        p {
          font-size: small;
        }

        img {
            height: 21px !important;
            margin-right: 8px !important;
        }
    }

    #schlossBtn {
        height: 40px;
        min-width: 40px;
        width: 40px;

        img {
            height: 21px !important;
            margin: 0 !important;
        }
    }
}

//when change to mobile nav on bottom
@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    #joinKlassenraumBtnTeacher {
        padding: 8px !important;
        margin-left: 0 !important;
        width: 104px;
    }

    #joinKlassenraumBtnPupil {
        height: 41px !important;
    }

    #klassenraumMenuBtm {
        position: fixed;
        bottom: 254px;
        right: 160px;
        width: 200px;
        background-color: white;
        border-radius: 8px 0 0 8px;
        box-shadow: 0 2px 4px silver;
        z-index: 2;
        color: var(--v-grau-base);

        img {
            height: 25px;
        }
    }

    #schlossBtn {
        margin-left: 0 !important;
        padding: 0 !important;
        width: 40px !important;

      img {
          margin: 0 !important;
      }
    }
}
</style>
