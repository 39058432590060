<template>
    <div>
        <v-row class="mx-0">
            <div class="whiteBg" style="height: auto; min-width: 100px !important; width: 90%; min-height: 80vh; border-radius: 15px; box-shadow: 1px 5px 5px silver; margin: auto auto 5em;">

                <!--Header of Schwarzes Brett-->
                <div @click="readWidgetInfoText()" style="background-color: #707070; border-radius: 15px 15px 0 0; padding-top: 10px; padding-bottom: 10px; color: white;">
                    <v-row style="max-width: 100%; display: flex; align-items: center; height: 35px" class="mx-2">
                        <v-col cols="9" md="8" lg="9" style="white-space: nowrap;" class="pa-0 pl-2 font-style d-flex align-center noselect">
                            <img :src="brettIcon" alt="Vorlesen" class="iconToWhite mr-1" style="height: 20px;"/>
                          <h1 tabindex="0" class="mb-0">Schwarzes Brett</h1>
                        </v-col>
                        <v-col cols="3" md="4" lg="3" class="pa-0 pr-2 d-flex align-center justify-end">
                            <img :src="infoIcon" alt="" class="mr-4 infoIcon" style="height: 15px">
                            <v-btn v-if="account.role === 'teacher'"
                                   @click="() => { eintragPopup = true; groupBlackboard = groupsFiltered[0].name; brettEintrag.groupId = groupsFiltered[0]._id; setTitle() }"
                                   x-small class="RightTitleText d-flex align-center" style="background-color: #f8f8f880; width: 35px; height: 35px;">
                                <img :src="plusIcon" class="boardIconDialog" alt="Plus-Icon"/>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>

                <!--body of Schwarzes Brett-->
                <scroll-area-magnifier :usage="'brettWidget'" :settings="settings">
                    <div :key="updateBlackboard">

                        <!--placeholder if there are no entries-->
                        <div v-if="blackboard.length === 0" class="ma-4" style="z-index: 0">
                            Die Schule hat noch keine Einträge verfasst
                        </div>

                        <!--pinned entry-->
                        <v-card v-for="(item, index) in displayedBlackboardItems().filter((el) => el.pin)" :key="item._id + index + urls + reload + 'pinned'" class="ma-2" style="z-index: 0">
                            <v-badge
                                color="red"
                                content="1"
                                :value="!item.seenByMe"
                                overlap
                                offset-x="15"
                                style="z-index: 9; width: 100%"
                            >
                                <v-card-title class="pa-1" style="color: #707070; background-color: #EAEAEA; max-width: 100%">
                                    <div class="d-flex justify-space-between" style="width: 100%;">
                                        <div class="leftTitleText d-flex align-center">
                                            <img :src="chooseIcon(item.icon)" alt="" class="boardIcon mr-2" :style="{ 'background-color': item.color}"/>
                                            <h2>
                                                <span aria-hidden="true" class="ellipsis mb-0 titleText" tabindex="0">{{ getTitle(item.createdBy, item.group) }}</span>
                                                <!-- Accessibility -->
                                                <span class="visually-hidden">{{ getTitle(item.createdBy, item.group, true) }}</span>
                                            </h2>
                                        </div>

                                        <div class="RightTitleText d-flex justify-end align-center">
                                            <div v-if="item.checkMarkStyle !== 'hide' && account.role === 'teacher'" class="mr-2 timeAndDate" >
                                                <div style="height: 2.5em">
                                                    <p class="mb-0" :var="seenByCount = item.seenByCount>item.seenByTarget ? item.seenByTarget : item.seenByCount">
                                                        <br>
                                                        {{seenByCount}} / {{item.seenByTarget}}
                                                    </p>
                                                  </div>
                                              </div>
                                            <div v-if="item.checkMarkStyle !== 'hide' && account.role === 'teacher'" class="mr-2" style="font-size: small;">
                                                <div style="height: 2.5em">
                                                    <p v-if="item.checkMarkStyle==='none'" class="mb-0 timeAndDate" style="font-weight:bold">
                                                        <br>✔</p>
                                                    <p v-if="item.checkMarkStyle==='some'" class="mb-0 timeAndDate" style="font-weight:bold">
                                                        <br><span style="margin-right:-7px">✔</span>✔</p>
                                                    <p v-if="item.checkMarkStyle==='all'" class="mb-0 checkMarks" style="font-weight:bold; color:#6996cd">
                                                        <br><span style="margin-right:-7px">✔</span>✔</p>
                                                </div>
                                              </div>
                                            <div class="mr-2 timeAndDate" >
                                                <div style="height: 2.5em">
                                                    <p tabindex="0" class="mb-0">
                                                        {{new Date(item.createdOn).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}}
                                                        <br>
                                                        {{dayname[new Date(item.createdOn).getDay()] + '. ' + new Date(item.createdOn).toLocaleDateString([], {day: '2-digit', month: '2-digit'})}}
                                                    </p>
                                                  </div>
                                              </div>
                                            <v-tooltip :disabled="!showTooltips" top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn :aria-hidden="me && me.accessibility && me.accessibility.screenreader" v-on="on" v-if="account.role === 'pupil'" @click="readText(item.message, $event)" x-small class="pa-0 mr-2" style="background-color: #F8F8F880 !important; height: 35px;">
                                                        <img :src="lautsprecherIcon" alt="Vorlesen" style="height: 20px; color: #707070"/>
                                                    </v-btn>
                                                </template>
                                              <span>Vorlesen</span>
                                            </v-tooltip>

                                            <v-btn v-if="myEntry(item.createdBy)" @click="() => { bearbeiten = true; eintragPopup = true; setBrettPopup(index);}" x-small class="pa-0" style="background-color: #F8F8F880 !important; height: 35px; width: 35px">
                                                <img :src="bearbeitenIcon" alt="" style="height: 20px; color: #707070"/>
                                            </v-btn>

                                            <v-tooltip :disabled="!showTooltips" top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" @click="pinEntry(item._id)" x-small class="pa-0" :style="{'background-color': item.color}" style="height: 35px; width: 35px">
                                                        <img :src="pinIcon" alt="Nachricht nicht mehr anheften" class="iconToWhite" style="height: 20px;"/>
                                                    </v-btn>
                                                </template>
                                                <span>Nachricht loslösen</span>
                                            </v-tooltip>
                                        </div>
                                    </div>

                                </v-card-title>
                                <v-card-text class="pa-2" style="font-size: medium" >
                                    <p tabindex="0" class="blackboardVCardText mb-0" style="font-size: medium; color: #444444" v-html="urlify(item.message)"></p>

                                    <hr v-if="item.uploads.length" class="my-2">
                                    <div v-for="(el, index2) in item.uploads" :key="el._id + index2 ">
                                        <div v-if="urls[el._id]" class="my-2 d-flex align-center justify-center">
                                            <!-- Display image preview -->
                                            <img
                                                    v-if="['png', 'jpg', 'jpeg', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                    :src="urls[el._id]"
                                                    @click="() => showFullImage(el)"
                                                    style="cursor: pointer; max-width: 100%" width="auto" height="auto" />
                                            <!-- Display pdf preview -->
                                            <object type="application/pdf"
                                                    v-else-if="['pdf'].includes(el.title.split('.').pop().toLowerCase())"
                                                    :data="urls[el._id]"
                                                    style="cursor: pointer; min-height: 300px; max-height: 400px; max-width: 100%" width="auto" height="auto" />
                                            <!-- Display video preview -->
                                            <video
                                                    v-else-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                    :poster="videoPoster"
                                                    controls
                                                    style="cursor: pointer;" width="100%" height="auto" >
                                                <source :src="urls[el._id]" />
                                            </video>
                                        </div>
                                        <hr v-if="blackboard[index].uploads.length > index2 + 1" class="my-2">
                                    </div>
                                </v-card-text>
                            </v-badge>
                        </v-card>

                        <!--regular entry-->
                        <v-card v-for="(item, index) in displayedBlackboardItems().filter((el) => !el.pin)" :key="item._id + index + urls + reload" class="ma-2" style="z-index: 0;">
                            <v-badge
                                color="red"
                                content="1"
                                :value="!item.seenByMe"
                                overlap
                                offset-x="15"
                                style="z-index: 9; width:100%"
                            >
                                <v-card-title class="pa-1" style="color: #707070; background-color: #EAEAEA; max-width: 100%">
                                    <div class="d-flex justify-space-between" style="width: 100%;">
                                        <div class="leftTitleText d-flex align-center">
                                            <img :src="chooseIcon(item.icon)" alt="" class="boardIcon mr-2" :style="{ 'background-color': item.color}"/>
                                            <h2>
                                                <span aria-hidden="true" class="ellipsis mb-0 titleText" tabindex="0">{{ getTitle(item.createdBy, item.group) }}</span>
                                                <!-- Accessibility -->
                                                <span class="visually-hidden">{{ getTitle(item.createdBy, item.group, true) }}</span>
                                            </h2>
                                        </div>

                                        <div class="RightTitleText d-flex justify-end align-center">
                                            <div v-if="item.checkMarkStyle!=='hide' && account.role === 'teacher'" class="mr-2 timeAndDate" >
                                                <div style="height: 2.5em">
                                                    <p class="mb-0" :var="seenByCount = item.seenByCount>item.seenByTarget ? item.seenByTarget : item.seenByCount">
                                                        <br>
                                                        {{seenByCount}} / {{item.seenByTarget}}
                                                    </p>
                                                  </div>
                                              </div>
                                            <div v-if="item.checkMarkStyle!=='hide' && account.role === 'teacher'" class="mr-2" style="font-size: small;">
                                                <div style="height: 2.5em">
                                                    <p v-if="item.checkMarkStyle==='none'" class="mb-0 timeAndDate" style="font-weight:bold">
                                                        <br>✔</p>
                                                    <p v-if="item.checkMarkStyle==='some'" class="mb-0 timeAndDate" style="font-weight:bold">
                                                        <br><span style="margin-right:-7px">✔</span>✔</p>
                                                    <p v-if="item.checkMarkStyle==='all'" class="mb-0 checkMarks" style="font-weight:bold; color:#6996cd">
                                                        <br><span style="margin-right:-7px">✔</span>✔</p>
                                                </div>
                                              </div>
                                            <div class="mr-2 timeAndDate">
                                                <p class="mb-0" style="height: 2.5em" tabindex="0">
                                                    {{ new Date(item.createdOn).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }}
                                                    <br>
                                                    {{dayname[new Date(item.createdOn).getDay()] + '. ' + new Date(item.createdOn).toLocaleDateString([], {day: '2-digit', month: '2-digit'}) }}
                                                </p>
                                            </div>

                                            <v-tooltip :disabled="!showTooltips" top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn :aria-hidden="me && me.accessibility && me.accessibility.screenreader" v-on="on" v-if="account.role === 'pupil'" @click="readText(item.message, $event)" x-small class="pa-0 mr-2 preventColorInvert" style="background-color: #F8F8F880 !important; height: 35px;">
                                                        <img :src="lautsprecherIcon" alt="Vorlesen" style="height: 20px; color: #707070"/>
                                                    </v-btn>
                                                </template>
                                                <span>Vorlesen</span>
                                            </v-tooltip>

                                            <v-btn v-if="myEntry(item.createdBy)" @click="() => { bearbeiten = true; eintragPopup = true; setBrettPopup(index);}" x-small class="pa-0 preventColorInvert" style="background-color: #F8F8F880 !important; height: 35px; width: 35px">
                                                <img :src="bearbeitenIcon" alt="" style="height: 20px; color: #707070"/>
                                            </v-btn>

                                            <v-tooltip :disabled="!showTooltips" top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" @click="pinEntry(item._id)" x-small class="pa-0 preventColorInvert" style="background-color: #F8F8F880 !important; height: 35px; width: 35px">
                                                        <img :src="pinIcon" alt="Nachricht anheften" style="height: 20px; color: #707070"/>
                                                    </v-btn>
                                                </template>
                                                <span>Nachricht anheften</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                </v-card-title>
                                <v-card-text class="pa-2">
                                    <p tabindex="0" class="blackboardVCardText mb-0" style="font-size: medium; color: #444444" v-html="urlify(item.message)"></p>

                                    <hr v-if="item.uploads.length" class="my-2">
                                    <div v-for="(el, index2) in item.uploads" :key="el._id + index2">
                                        <div v-if="urls[el._id]" class="my-2 d-flex align-center justify-center">
                                            <!-- Display image preview -->
                                            <img
                                                v-if="['png', 'jpg', 'jpeg', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                :src="urls[el._id]"
                                                @click="() => showFullImage(el)"
                                                style="cursor: pointer; max-width: 100%" width="auto" height="auto" />
                                            <!-- Display pdf preview -->
                                            <object type="application/pdf"
                                                    v-else-if="['pdf'].includes(el.title.split('.').pop().toLowerCase())"
                                                    :data="urls[el._id]"
                                                    style="cursor: pointer; min-height: 300px; max-height: 400px; max-width: 100%" width="auto" height="auto" />
                                            <!-- Display video preview -->
                                            <video
                                                v-else-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                :poster="videoPoster"
                                                controls
                                                style="cursor: pointer;" width="100%" height="auto" >
                                                <source :src="urls[el._id]" />
                                            </video>
                                        </div>
                                        <hr v-if="blackboard[index].uploads.length > index2 + 1" class="my-2">
                                    </div>
                                </v-card-text>
                            </v-badge>
                        </v-card>
                    </div>
                </scroll-area-magnifier>
            </div>
        </v-row>

        <!--Schwarzes Brett Popup-->
        <v-dialog persistent color="white" :value="eintragPopup" style="padding-top: 50px; width: 100%; z-index: 100; box-shadow: none !important;">
            <v-row class="popUpRow" style="margin: auto; box-shadow: none !important;">
                <v-card id="blackboardCardContainer">
                    <!--Popup-Titel-->
                    <div class="px-1 align-start"
                         style="display: inline-flex; width: 100%; background-color: #707070; border-radius: 15px 15px 0 0; font-size: larger; padding-top: 20px; padding-bottom: 20px; color: white;">


                        <div class="mt-1" style="margin-left: 10px; display: flex; align-items: center;" >
                            <img :src="brettIcon" class="mx-2 boardIconDialog" alt="Schwarzes Brett-Icon"/>
                            <p v-if="bearbeiten" class="mx-0 mb-2" style="display: inline-block;">Eintrag bearbeiten</p>
                            <p v-else class="ma-0" style="display: inline-block;">Neuer Eintrag</p>
                        </div>

                        <v-spacer></v-spacer>

                        <v-btn @click="() => { eintragPopup = false; bearbeiten = false; resetPopup() }" x-small class="RightTitleText d-flex align-center mr-2" style="background-color: #f8f8f880; width: 35px; height: 35px;">
                            <img :src="schliessenIcon" class="boardIconDialog" alt="schliessen-Icon"/>
                        </v-btn>
                    </div>

                    <!--Eintrag-->
                    <div>
                        <v-row>
                            <v-col cols="12">
<!--                                <vue-custom-scrollbar class="scroll-area mb-2" :settings="settings">-->
                                    <v-card light class="ma-2" style="border-radius: 15px; height: 590px">
                                        <!--Header-->
                                        <v-card-title
                                                style="color: #707070; background-color: #EAEAEA; padding-top: 10px; padding-bottom: 10px">
                                            <img :src="chooseIcon(brettEintrag.icon)" :style="{ 'background-color': brettEintrag.color}" class="mx-2 boardIconEntries" alt="Icon"/>
                                            <p class="mb-0">{{ bearbeiten ? brettEintrag.title : newEntryTitle }}</p>

                                        </v-card-title>

                                        <!--Vorschau, Text, Icon und Gruppe-->
                                        <v-card-text class="pa-2" style="overflow: auto; overflow-x: hidden; height: 520px">
                                            <v-row style="width: 100%">
                                                <!--left-->
                                                <v-col cols="12" md="8" class="py-0">
                                                    <!--                                                <v-textarea v-model="brettEintrag.message" class="pa-0 ma-0" rows="12"-->
                                                    <!--                                                            no-resize hide-details autofocus></v-textarea>-->
                                                    <advanced-quill-editor
                                                        style="max-height: 400px"
                                                        v-model="brettEintrag.message"
                                                        placeholder="Text einfügen">
                                                    </advanced-quill-editor>

                                                    <v-row class="my-1" v-for="(item, index) in brettEintrag.uploads" :key="item._id + index">
                                                        <v-col cols="8">
                                                            {{item.title}}
                                                        </v-col>

                                                        <v-col style="text-align: right;">
                                                            <v-btn class="ml-auto mr-0"
                                                                   style="color: red" @click="removeUpload(item)">Anhang entfernen
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col cols="11" class="pr-0">
                                                            <input ref="uploadInput" type="file" id="files" name="files" multiple class="mt-3" accept="image/*,video/*,application/pdf" @change="() => uploadInputChange()">
                                                        </v-col>

                                                        <v-col cols="1" class="pl-0">
                                                            <v-btn v-if="bearbeiten"
                                                                   @click="() => {eintragBearbeiten();}"
                                                                   x-small class="mr-6"
                                                                   style="background-color: #8CBD46; width: 48px; height: 48px">
                                                                <img :src="sendenIcon"
                                                                     style="height: 40px; color: white!important;"
                                                                     alt="senden-Icon"/>
                                                            </v-btn>

                                                            <v-btn v-else
                                                                   @click="() => {eintragErstellen();}"
                                                                   x-small class="mr-4 justify-center align-center"
                                                                   style="background-color: #8CBD46; width: 35px; height: 35px">
                                                                <img :src="sendenIcon"
                                                                     style="height: 20px; color: white!important;"
                                                                     class="boardIcon"
                                                                     alt="senden-Icon"/>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>

                                                </v-col>

                                                <!--right-->
                                                <v-col cols="12" md="4">
                                                    <v-row>

                                                        <v-col cols="12" md="12" style="font-size: medium" class="pb-0">
                                                            Gruppenauswahl:
                                                        </v-col>
                                                        <v-col cols="12" md="12">
                                                            <v-autocomplete
                                                                    v-model="groupBlackboard"
                                                                    :label="toString(groupBlackboard)"
                                                                    single-line
                                                                    hide-details
                                                                    :attach="true"
                                                                    item-text="name"
                                                                    :items="groupsFiltered"
                                                                    :filter="customFilter"
                                                                    no-data-text="Keine passende Gruppe"
                                                                    class="ma-0 pa-0"
                                                                    :allow-overflow="false"
                                                                    loader-height="50"
                                                            ></v-autocomplete>
                                                        </v-col>

                                                        <v-col cols="12" md="12" style="font-size: medium" class="py-0">
                                                            Farbauswahl:
                                                        </v-col>
                                                        <v-col cols="12" md="12">
                                                            <verwaltung-color-picker
                                                                    :callback="(result) => { brettEintrag.color = result }"
                                                                    :current-color="brettEintrag.color"
                                                            />
                                                        </v-col>

                                                        <v-col cols="12" md="12" style="font-size: medium" class="pb-0">
                                                            Iconauswahl:
                                                        </v-col>
                                                        <v-col cols="12" md="12">
                                                            <img class="iconSelect" @click="brettEintrag.icon = 'sonneIcon'"
                                                                 :selected="brettEintrag.icon === 'sonneIcon'"
                                                                 :src="sonneIconNormal" alt="Sonne-Icon"/>
                                                            <img class="iconSelect"
                                                                 @click="brettEintrag.icon = 'erdkundeIcon'"
                                                                 :selected="brettEintrag.icon === 'erdkundeIcon'"
                                                                 :src="erdkundeIconNormal" alt="Erdkunde-Icon"/>
                                                            <img class="iconSelect"
                                                                 @click="brettEintrag.icon = 'religionIcon'"
                                                                 :selected="brettEintrag.icon === 'religionIcon'"
                                                                 :src="religionIconNormal" alt="Religion-Icon"/>
                                                            <img class="iconSelect"
                                                                 @click="brettEintrag.icon = 'politikIcon'"
                                                                 :selected="brettEintrag.icon === 'politikIcon'"
                                                                 :src="politikIconNormal" alt="Politik-Icon"/>
                                                            <img class="iconSelect" @click="brettEintrag.icon = 'bioIcon'"
                                                                 :selected="brettEintrag.icon === 'bioIcon'"
                                                                 :src="bioIconNormal" alt="Biologie-Icon"/>
                                                            <img class="iconSelect"
                                                                 @click="brettEintrag.icon = 'englischIcon'"
                                                                 :selected="brettEintrag.icon === 'englischIcon'"
                                                                 :src="englischIconNormal" alt="Englisch-Icon"/>
                                                        </v-col>

                                                        <div v-if="bearbeiten">
                                                            <v-col cols="12" md="12" style="font-size: medium" class="pb-0">
                                                                Eintrag löschen:
                                                            </v-col>
                                                            <v-col cols="12" md="12">
                                                                <v-btn @click="eintragLoeschen(); eintragPopup = false"
                                                                       style="color: red">Ja, Eintrag löschen
                                                                </v-btn>
                                                            </v-col>
                                                        </div>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
<!--                                </vue-custom-scrollbar>-->
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-row>
        </v-dialog>
        <v-dialog
            :value="imgFullscreen"
            v-if="imgFullscreen"
            overlay-opacity="0.9"
            overlay-color="black"
            style="max-height: 90%;"
            max-width="90%"
            width="unset"
            @click:outside="imgFullscreen = null;"
        >
            <v-row justify="end" align="end">
                <v-col cols="1" align-self="end">
                    <v-icon large
                            @click="imgFullscreen = null;"
                            dark>
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
                <v-col cols="12" class="px-2">
                    <!--                    <video ref="normalVideo" :src="videoUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"-->
                    <!--                           :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">-->
                    <!--                        Your browser does not support the video tag.-->
                    <!--                    </video>-->
                    <img :src="imgFullscreen" style="max-width: 100%; max-height: 100%" />
                </v-col>
            </v-row>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import vueCustomScrollbar from "vue-custom-scrollbar";
import VerwaltungColorPicker from "./Verwaltung/VerwaltungColorPicker";

import brettIcon from '../assets/Icons/sw-brett-neu-100.svg';
import pinIcon from "../assets/Icons/pin-63.svg";
import lautsprecherIcon from "../assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "../assets/Icons/lautsprecher-aus-89.svg";
import infoIcon from "../assets/Icons/info-2-weiß-89.svg";

import sonneIcon from "../assets/Icons/sonne-weiss-46.svg";
import erdkundeIcon from '../assets/Icons/f-erdkunde-weiss-31.svg';
import religionIcon from '../assets/Icons/f-religion-weiss-39.svg';
import politikIcon from '../assets/Icons/f-politik-weiss-34.svg';
import bioIcon from '../assets/Icons/f-biologie-weiss-35.svg';
import englischIcon from "../assets/Icons/f-englisch-30.svg";
import englischIconWeiss from "../assets/Icons/f-englisch-weiss-30.svg";
import plusIcon from "../assets/Icons/hinzufuegen-07.svg";
import schliessenIcon from "../assets/Icons/abbrechen-08.svg";
import sendenIcon from "../assets/Icons/senden-weiß-23.svg";
import bearbeitenIcon from "../assets/Icons/bearbeiten-normal-41.svg";

import videoPoster from "@/assets/Bilder/videoposter.png";
import * as backend from "../api/backend";
import sonneIconNormal from "@/assets/Icons/sonne-46.svg";
import bioIconNormal from "@/assets/Icons/f-biologie-35.svg";
import englischIconNormal from "@/assets/Icons/f-englisch-30.svg";
import erdkundeIconNormal from "@/assets/Icons/f-erdkunde-31.svg";
import politikIconNormal from "@/assets/Icons/f-politik-34.svg";
import religionIconNormal from "@/assets/Icons/f-religion-39.svg";
import ScrollAreaMagnifier from "@/components/ScrollAreaMagnifier";
import AdvancedQuillEditor from "@/components/Utils/AdvancedQuillEditor";

export default {
name: "BrettWidget",
    components: {
        ScrollAreaMagnifier,
        AdvancedQuillEditor,
        VerwaltungColorPicker,
    },
    data() {
        return {
            imgFullscreen: null,
            urls: [],
            group: '',
            icon: '',
            color: '',
            message: '',
            timestamp: '',
            me: {
                accessibility: {
                    screenreader: false,
                }
            },
            role: '',
            reload: false,

            blackboard: [],
            updateBlackboard: true,
            eintragPopup: false,
            brettEintrag: {title: '', message: '', icon: 'sonneIcon', color: '#fcd20a', groupId: '', blackboardId: ''},
            groups: [],
            groupsFiltered: [],
            groupBlackboard: '',
            bearbeiten: false,
            showLoadingOverlay: false,
            teachers: [],
            newEntryTitle: '',

            editorOption: {
                placeholder: 'Text einfügen',
                modules: {
                    toolbar: {
                        container: [
                            [{'size': ['large', false]}],
                            [{'color': []}],
                            ['bold', 'italic', 'underline'],
                            [{'list': 'ordered'}, {'list': 'bullet'}],
                            ['link', 'video'],
                        ],
                    },
                }
            },

            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false
            },

            pinIcon,
            brettIcon,
            lautsprecherIcon,
            lautsprecherAusIcon,
            infoIcon,
            plusIcon,
            schliessenIcon,
            sendenIcon,
            bearbeitenIcon,
            videoPoster,

            sonneIconNormal,
            bioIconNormal,
            englischIconNormal,
            erdkundeIconNormal,
            politikIconNormal,
            religionIconNormal,

            //Icon array for dynamically picking the icon via string
            icons: [
                {icon: englischIcon, text: "englischIcon"},
                {icon: sonneIcon, text: "sonneIcon"},
                {icon: englischIconWeiss, text: "englischIconWeiss"},
                {icon: politikIcon, text: "politikIcon"},
                {icon: erdkundeIcon, text: "erdkundeIcon"},
                {icon: religionIcon, text: "religionIcon"},
                {icon: bioIcon, text: "bioIcon"},
            ],

            dayname: [
                'So',
                'Mo',
                'Di',
                'Mi',
                'Do',
                'Fr',
                'Sa',
            ],
            showTextTimeout: null,
        };
    },
    async mounted() {
        //Reihenfolge bitte erhalten
        await this.requestBlackboard();
        await this.requestMe();
        await this.requestGroups();
        await this.requestTeachers();
        // Disabled image previews for now due to too much traffic
        this.loadImagePreviews();
    },
    computed: {
        ...mapState('translation', ['targetLang',]),
        ...mapState("auth", ["account"]),
    },
    watch: {
        async currentChild() {
            await this.requestBlackboard();

            // Disabled image previews for now due to too much traffic
            this.loadImagePreviews();
        },
        groupBlackboard(newValue) {
            this.groups.forEach((el) => {
                if(el.name === newValue) {
                    this.brettEintrag.groupId = el._id;
                }
            });
            this.setTitle();
        },
        getGroupStatus(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestGroups();
            }
        },
    },
    props: {
        showTooltips: {required: false, default: true},
        currentChild: {required: false, type: Object},
    },
    methods: {
        ...mapActions("blackboard", ["getBlackboard", "getParentBlackboard", "createBlackboardEntry", "updateBlackboardEntry", "deleteBlackboardEntry", "deleteBlackboardUpload"]),
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation', 'translateToTargetLang' ]),
        ...mapActions('parent', [ 'getMeParent']),
        ...mapActions('account', [ 'getCurrentAccount']),
        ...mapActions("groups", ['getGroups', 'getGroup', 'setGroupInvalidStatus', 'getGroupsInfo']),
        ...mapActions("teachers", ['getTeachers', 'getMeTeacher']),
        ...mapActions("pupils", [ 'getMePupil' ]),

        displayedBlackboardItems() {
            const calculateCheckmarkStyle = (blackBoardItem) =>  {
                // Dont show checkmark for when recipient is group alle
                if (!this.groupsFiltered.filter(item => item._id === blackBoardItem.group).length) {
                    return 'hide';
                }
                // Everybody has seen the blackboard message
                if(blackBoardItem.seenByCount >= blackBoardItem.seenByTarget) {
                    return 'all';
                }
                // Nobody has seen the blackboard message
                if (blackBoardItem.seenByCount === 0) {
                    return 'none';
                }
                return 'some';
            }

            const calculateSeenByTarget = (blackBoardItem) => {
                const group = this.groups.find(group => group._id === blackBoardItem.group);
                return group ? group.participants.length: 0;
            };

            return this.blackboard
                .map(blackBoardItem => ({
                    ...blackBoardItem,
                    seenByTarget: calculateSeenByTarget(blackBoardItem),
                }))
                .map(blackBoardItem => ({
                    ...blackBoardItem,
                    checkMarkStyle: calculateCheckmarkStyle(blackBoardItem),
                }));
        },

        async setBrettPopup(i) {
            this.currentEntryId = this.blackboard[i]._id;
            this.brettEintrag.color = this.blackboard[i].color;
            this.brettEintrag.icon = this.blackboard[i].icon;
            this.brettEintrag.message = this.blackboard[i].message;
            this.brettEintrag.groupId = this.blackboard[i].blackboardId;
            this.brettEintrag.uploads = this.blackboard[i].uploads;
            let group = '';
            this.groups.forEach((el) => {
                if(el._id === this.blackboard[i].group) {
                    group = el.name;
                }
            });
            this.brettEintrag.title = this.me.name.slice(0, 1) + '. ' + this.me.lastName + ' → ' + group;

            let gruppenObj = await this.getGroup(this.blackboard[i].group);
            this.groupBlackboard = gruppenObj.name;
        },
        async requestGroups() { //this is a hotfix wich works and i have no clue why so please dont break it
            this.showLoadingOverlay = true;
            this.groups =  await this.getGroupsInfo();
            this.groups.forEach((el) => {
                // console.log(el.lead + ' === ' + this.me._id + ' → ' + (el.lead === this.me._id));
                if(el.lead === this.me._id) {
                    this.groupsFiltered.push(el);
                }
            })
            // console.log('filtered', this.groupsFiltered);
            this.showLoadingOverlay = false;
        },

        async requestTeachers() {
            const response = await backend.getTeachers();
            this.teachers = await response.json();
        },

        customFilter (item, queryText, itemText) {
            const textOne = item.name.toLowerCase();
            const searchText = queryText.toLowerCase();

            return textOne.indexOf(searchText) > -1
        },
        async eintragBearbeiten() {
            try {
                this.showLoadingOverlay = true;
                const _id = this.currentEntryId;
                let data = {
                    _id: _id,
                    message: this.brettEintrag.message,
                    icon: this.brettEintrag.icon,
                    color: this.brettEintrag.color,
                    group: this.brettEintrag.groupId,
                    requestAccountId: this.me.account,
                };
                await this.updateBlackboardEntry(data);
                const input = this.$refs.uploadInput;
                let inputsLength = input.files.length;
                input.files.forEach((el) => {
                    const formData = new FormData();
                    formData.append('file', el);
                    const XmlHttpRequest = backend.postBlackboardFile(_id, el);

                    XmlHttpRequest.addEventListener('load', (e) => {
                        inputsLength--;
                    });
                    XmlHttpRequest.send(formData)
                });
                // while (inputsLength > 0) {
                //     await this.sleep(100);
                // }
                await this.requestBlackboard();
                await this.resetPopup();
                // Disabled image previews for now due to too much traffic
                await this.loadImagePreviews();
                this.eintragPopup = false;
                this.showLoadingOverlay = false;
            } catch (e) {
                console.log(e);
                this.showLoadingOverlay = false;
            }
        },
        async eintragLoeschen() {
            const _id = this.currentEntryId;
            let data = {
                _id: _id,
                requestAccountId: this.me.account,
            };
            await this.deleteBlackboardEntry(data);
            this.resetPopup();
            this.blackboard = await this.getBlackboard();
        },
        async uploadInputChange() {
            const input = this.$refs.uploadInput;
            input.files.forEach((el) => {
                if(!(el['type'].split('/')[0] === 'image' || el['type'].split('/')[0] === 'video' || el['type'] === 'application/pdf')) {
                    this.snackbarText = 'Format nicht unterstützt'
                    this.snackbar = true;
                    input.value = '';
                }
            });
        },
        async eintragErstellen() {
            try {
                this.showLoadingOverlay = true;
                let data = {
                    message: this.brettEintrag.message,
                    icon: this.brettEintrag.icon,
                    color: this.brettEintrag.color,
                    group: this.brettEintrag.groupId,
                    createdBy: this.me.account,
                };
                const res = await this.createBlackboardEntry(data);
                const input = this.$refs.uploadInput;
                let inputsLength = input.files.length;
                input.files.forEach((el) => {
                    const formData = new FormData();
                    formData.append('file', el);
                    const XmlHttpRequest = backend.postBlackboardFile(res._id, el);

                    XmlHttpRequest.addEventListener('load', (e) => {
                        inputsLength--;
                    });
                    XmlHttpRequest.send(formData)
                });
                // while(inputsLength>0) {
                //     await this.sleep(100);
                // }
                await this.requestBlackboard();
                // Disabled image previews for now due to too much traffic
                await this.loadImagePreviews();
                await this.resetPopup();
                this.eintragPopup = false;
                this.showLoadingOverlay = false;
            } catch (e) {
                console.log(e);
                this.showLoadingOverlay = false;
            }
        },
        async removeUpload(upload) {
            this.showLoadingOverlay = true;
            const params = {
                blackboardId: this.currentEntryId,
                fileId: upload.file,
            }
            await this.deleteBlackboardUpload(params);
            this.brettEintrag.uploads = this.brettEintrag.uploads.filter((el) => el !== upload);
            await this.requestBlackboard();
            this.showLoadingOverlay = false;

        },
        resetPopup() {
            this.currentEntryId = '';
            this.brettEintrag.title = '';
            this.brettEintrag.message = '';
            this.brettEintrag.icon = 'sonneIcon';
            this.brettEintrag.color = '#fcd20a';
            this.brettEintrag.groupId = '';
            this.brettEintrag.blackboardId = '';
            this.brettEintrag.uploads = [];
            document.getElementById("files").value = "";
            this.bearbeiten = false;
        },

        async loadImagePreviews() {
            this.urls= [];
            this.blackboard.forEach((el) => {
                el.uploads.forEach(async (file, index) => {
                    if (file.file) {
                        let res;
                        // Try getting a thumbnail
                        res = await backend.getBlackboardUploadThumbnail(file.file);
                        if (res.ok && res.status === 200) {
                            let blob = await res.blob();
                            this.urls[file._id] = window.URL.createObjectURL(blob);
                        } else {
                            // If the thumbnail is not available => load the real file
                            res = await backend.getBlackboardUpload(file.file);
                            if (res.ok && res.status === 200) {
                                let blob = await res.blob();
                                this.urls[file._id] = window.URL.createObjectURL(blob);
                            }
                        }
                        this.reload = !this.reload
                    }
                });
            });
        },
        urlify(text) {
            // eslint-disable-next-line no-useless-escape
            var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|software|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
            return text.toString().replace(urlRegex, function(url) {
                url = url.includes('http') ? url : 'http://' + url;
                return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
            })
            // or alternatively
            // return text.replace(urlRegex, '<a href="$1">$1</a>')
        },

        getSrc() {
            return this.thisSubject ? this[this.thisSubject.icon] : (this.thisAppointment ? this[this.thisAppointment.schoolSubject.icon] : null);
        },

        getBackgroundColor() {
            let returnString =  "background-color: ";
            return (returnString + this.thisAppointment.schoolSubject.color);
        },

        async requestBlackboard() {
            let acc = await this.getCurrentAccount();
            this.role = acc.role;

            if (this.role === 'parent') {
                this.blackboard = await this.getParentBlackboard(this.currentChild._id);
            } else {
                this.blackboard = await this.getBlackboard();
            }

            this.blackboard.sort((a, b) => {
                let keyA = new Date(a.createdOn),
                    keyB = new Date(b.createdOn);
                // Compare the 2 dates
                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
                return 0;
            });
            this.updatedPin();
        },

        pinEntry(entry) {
            let entries = JSON.parse(localStorage.getItem('blackboard'));
            if(!entries) {
                entries = [];
            }
            if(!entries.includes(entry)) {
                entries.push(entry);
            } else {
                entries.splice(entries.indexOf(entry), 1);
            }
            localStorage.setItem('blackboard', JSON.stringify(entries));
            this.updatedPin();
        },

        updatedPin() {
            let entries = JSON.parse(localStorage.getItem('blackboard'));
            if(!entries) {
                entries = [];
            }
            this.blackboard.forEach( (el) => {
                if(entries.includes(el._id)) {
                    el['pin'] = true;
                } else {
                    el['pin'] = false;
                }
            });
            this.updateBlackboard = !this.updateBlackboard;
        },

        chooseIcon(iconName) {
          for(let i of this.icons){
            if(i.text === iconName){
              return i.icon;
            }
          }
        },

        getTitle(creatorId, groupId, aria) {
            let creator = 'Verwaltung';
            this.teachers.forEach((el) => {
                    if (el.account === creatorId) {
                        creator = el.name.slice(0, 1) + '. ' + el.lastName;
                        return creator; //Hope this works like break;
                    }
            });

            let group = '';
            this.groups.forEach((el) => {
                if(el._id === groupId) {
                    group = el.name;
                    return group; //Hope this works like break;
                }
            });
            if(!aria) {
                return creator + ' → ' + group;
            } else {
                return 'Nachricht von ' + creator + ' an ' + group;
            }
        },

        async readText(text, event) {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
                return
            }

            let txt;
            if (text) {
                txt = text;
            } else {
                txt = 'Dieser Eintrag hat keinen Inhalt.';
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
                event.target.src = lautsprecherIcon;
            } else {
                if (this.targetLang !== 'de') {
                    txt = await this.translateToTargetLang({
                        targetLang: this.targetLang,
                        textToTranslate: txt
                    });

                    this.setTranslatedText(txt);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000)
                }

                event.target.src = lautsprecherAusIcon;
                let msg = new SpeechSynthesisUtterance();
                msg.text = txt;
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                } else {
                    event.target.src = lautsprecherIcon;
                }

                msg.onend = function() {
                    event.target.src = lautsprecherIcon;
                }
            }
        },

        async readWidgetInfoText() {
            if(!this.me.accessibility.screenreader) {
                if ('speechSynthesis' in window) {
                    // Speech Synthesis supported 🎉
                } else {
                    // Speech Synthesis Not Supported 😣
                    alert("Sorry, your browser doesn't support text to speech!");
                    return
                }

                if (!this.eintragPopup) {
                    let acc = await this.getCurrentAccount();
                    let text;
                    if (acc.role === 'pupil') {
                        text = 'Das Schwarze Brett zeigt dir die aktuellen Schulnachrichten an';
                    } else { //parent and teacher
                        text = 'Das Schwarze Brett zeigt Ihnen die aktuellen Schulnachrichten an.';
                    }

                    if (this.targetLang !== 'de') {
                        text = await this.translateToTargetLang({
                            targetLang: this.targetLang, textToTranslate: text
                        });
                    }

                    if (window.speechSynthesis.speaking) {
                        window.speechSynthesis.cancel();
                        this.showTranslation(false);
                    } else {
                        this.setTranslatedText(text);
                        this.showTranslation(true);

                        if (this.showTextTimeout) {
                            clearTimeout(this.showTextTimeout);
                            this.showTextTimeout = null;
                        }

                        this.showTextTimeout = setTimeout(() => {
                            this.showTranslation(false);
                            this.showTextTimeout = null;
                        }, 15000)

                        let msg = new SpeechSynthesisUtterance();
                        msg.text = text;
                        if (this.isLangPackageAvailable()) {
                            msg.lang = this.targetLang;
                            window.speechSynthesis.speak(msg);
                        }
                    }
                }
            }
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },

        expandText(event) {
            //from https://stackoverflow.com/questions/54459816/vue-text-highlight-event
            const isTextHighlighting = window.getSelection().toString().trim() !== '';

            //only expand when no text is being selected
            if (!isTextHighlighting) {
                event.target.classList.toggle('fade');
                event.target.classList.toggle('initHeight');
            }
        },
        async showFullImage(file){
            const res = await backend.getBlackboardUpload(file.file);
            if(res.ok && res.status === 200){
                let blob = await res.blob();
                this.imgFullscreen = window.URL.createObjectURL(blob);
            }
        },

        setTitle() {
            const me = this.me.name.slice(0, 1) + '. ' + this.me.lastName;
            let group = this.groupBlackboard;
            if(!this.bearbeiten) {
                this.newEntryTitle = me + ' → ' + group;
            } else {
                this.brettEintrag.title = me + ' → ' + group;
            }
        },

        async requestMe() {
            if(this.role === 'teacher') {
                this.me = await this.getMeTeacher();
                this.me.accessibility = {
                    screenreader: false,
                };
            } else if(this.role === 'pupil') {
                this.me = await this.getMePupil();
            }
        },
        myEntry(creatorId) {
            return creatorId === this.me.account;
        },
    }
}
</script>

<style>
    .ql-video {
        max-width: 100%;
    }


    .v-dialog {
        box-shadow: none;
        overflow-x: hidden;
    }

</style>

<style lang="scss" scoped>

.scroll-area {
  position: relative;
  margin: auto;
  max-height: calc(80vh - 75px);
}

.whiteBg {
    background-color: #ffffff;
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.font-style {
  font-size: larger
}

.fade {
    width: 100%;
    overflow: hidden;
    position: relative;
    max-height: 44px;
}
.fade:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 25%;
    height: 50%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}

.initHeight {
    max-height: initial !important;
}

//make text respect line breaks
.blackboardVCardText {
    white-space: pre-line;
}

.leftTitleText {
    min-width: 1%;
}

.boardIcon {
    height: 30px;
    color: white !important;
    border-radius: 4px;
    padding: 5px;
}

.boardIconDialog {
  height: 25px;
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

h1 {
  display: inherit;
  font-size: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
  margin-left: inherit;
  margin-right: inherit;
  font-weight: inherit;
}

h2 {
  display: inherit;
  font-size: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
  margin-left: inherit;
  margin-right: inherit;
  font-weight: inherit;
}

.timeAndDate {
    white-space: nowrap;
    font-size: small;
    line-height: 1rem !important;
    text-align: right;
    opacity: 0.47;
}

.checkMarks {
    white-space: nowrap;
    font-size: small;
    line-height: 1rem !important;
    text-align: right;
}

//make text not selectable
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@media only screen and (min-width: 901px) and (max-width: 1300px){
    .timeAndDate {
        display: none;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1500px){
  .titleText {
    font-size: large;
  }
}
@media only screen and (min-width: 901px) and (max-width: 1300px){
  .titleText {
    font-size: medium;
  }
}
@media only screen and (min-width: 901px) and (max-width: 1200px){
  .titleText {
    font-size: small;
  }
}
@media only screen and (min-width: 901px) and (max-width: 1050px){
  .infoIcon {
    display: none;
  }
}

#blackboardCardContainer {
  height: 720px;
  width: 100%;
  margin: auto auto;
  border-radius: 15px;
  background: white;
}

.boardIconEntries {
  height: 30px;
  color: white !important;
  border-radius: 4px;
  padding: 5px;
}

.timeAndDate {
  white-space: nowrap;
  font-size: small;
  line-height: 1rem !important;
  text-align: right;
  opacity: 0.47;
}

.popUpRow {
  width: 95%;
  margin: auto;
}

@media only screen and (max-width: 900px){
  .popUpRow {
    margin-left: 33%;
    margin-right: 66%;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1110px){
  .timeAndDate {
    display: none;
  }
}

.iconSelect {
  width: 42px;
  padding: 2px;
  border: solid 2px #EAEAEA;
  border-radius: 10px;
  margin-right: 2px;
}

.iconSelect[selected] {
  width: 42px;
  padding: 2px;
  border: solid 2px #737373;
  border-radius: 10px;
}

</style>
