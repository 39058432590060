<template>
    <div style="z-index: 7" :id="`editorTextMessageContainer${index}`"
         ref="editorTextMessageContainer" @click="$emit('focusMe')">
        <v-menu :attach="`#editorTextMessageContainer${index}`" :value="openMenu"
                role="dialog"
                :close-on-content-click="false" nudge-bottom="55" nudge-left="100" max-width="300px">
            <template v-slot:activator="{ on, attrs }">
                <div style="transform: translate(-50%, -50%);">
                    <div class="heartbeat" v-bind="attrs" v-on="on"
                         @keypress.enter="openMenu = !openMenu" :tabindex="tabIndex">
                        <v-icon style="font-size: 50px !important; color: #e6231e;">fas fa-circle</v-icon>
                        <img :src="editIcon" style="height: 30px; position: absolute; left: 10px; top: 10px" alt=""/>
                    </div>
                </div>
            </template>
            <v-card max-width="300px">
                <v-card-text class="pa-2">
                    <v-container class="pa-0">
                        <v-row dense>
                            <v-col>
                                <p :tabindex="tabIndex" class="mb-0" style="width: fit-content" v-html="message.text"/>
                            </v-col>
                            <v-col v-if="account.role === 'teacher'" cols="2" class="ma-1" style="min-width: 35px">
                                <v-btn x-small elevation="1" style="height: 35px; width: 35px" @click="deleteNote" tabindex="19">
                                    <img :src="papierkorbIcon" class="notesButtonsImage" alt="Notiz entfernen"/>
                                </v-btn>
                            </v-col>
                            <v-col cols="2" class="ma-1" style="min-width: 35px">
                                <v-btn :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                                       x-small elevation="1" style="height: 35px; width: 35px" @click="readText" :tabindex="person && person.accessibility && person.accessibility.screenreader ? '-1' : tabIndex">
                                    <img :src="speakerIcon" class="notesButtonsImage" alt="Notiz vorlesen"/>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import editIcon from '../../assets/Icons/bearbeiten-komplimentär-weiß-88.svg';
import lautsprecherIcon from '../../assets/Icons/lautsprecher-13.svg';
import lautsprecherAusIcon from '../../assets/Icons/lautsprecher-aus-89.svg';
import papierkorbIcon from '../../assets/Icons/papierkorb-109.svg'
import { mapActions, mapState } from 'vuex';

export default {
    name: 'EditorTextMessage',
    props: {
        message: { type: Object, required: true },
        index: { type: Number, required: true },
        targetLang: { type: String, required: false, default: 'de' },
        translate: { type: Boolean, required: false, default: false },
        tabIndex: { type: Number, required: false, default: 19 },
    },
    data() {
        return {
            //Icons
            editIcon,
            lautsprecherIcon,
            lautsprecherAusIcon,
            papierkorbIcon,

            // UI Changes
            speakerIcon: lautsprecherIcon,
            openMenu: false,

            // Translate
            alreadyTranslated: false,   //whether the texts were already translated once
            untranslatedMessage: null,  //store original text
            translatedMessage: null,    //store translated text (so translating is only done once)
            person: {
                accessibility: {
                    screenreader: false,
                }
            }
        };
    },
    computed: {
        ...mapState('auth', ['account']),
    },
    watch: {
        translate() {
            this.toggleTextLanguage();
        },
    },
    mounted() {
        this.storeOriginalTexts();
        this.requestPerson();
    },
    methods: {
        ...mapActions('translation', [ 'translateToTargetLang' ]),
        ...mapActions('pupils', [ 'getMePupil' ]),

        async requestPerson() {
            this.person = await this.getMePupil();
        },

        storeOriginalTexts() {
            this.untranslatedMessage = this.message.text;
        },

        async toggleTextLanguage() {
            //translate and replace displayed texts
            if (this.translate && !this.alreadyTranslated) {
                this.translatedMessage = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: this.message.text
                });
                this.message.text = this.translatedMessage;

                this.alreadyTranslated = true;
            }
            //just replace displayed texts with stored translated texts
            else if (this.translate && this.alreadyTranslated) {
                this.message.text = this.translatedMessage;
            }
            //set texts to original language
            else {
                this.message.text = this.untranslatedMessage;
            }
        },

        deleteNote() {
            this.$emit('deleteNote');
        },
        readText() {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
            }

            if(window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel()
                this.speakerIcon = this.lautsprecherIcon;
            } else {
                let msg = new SpeechSynthesisUtterance();
                msg.text = this.message.text;
                if(this.translate) {
                    msg.lang = this.targetLang;
                } else {
                    msg.lang = 'de';
                }

                // if (this.isLangPackageAvailable()) {
                    window.speechSynthesis.speak(msg);
                    this.speakerIcon = this.lautsprecherAusIcon;
                // }

                msg.onend = () => {
                    this.speakerIcon = this.lautsprecherIcon;
                };
            }
        }
    }
}
</script>

<style scoped lang="scss">
    /* ----------------------------------------------
    * Generated by Animista on 2021-1-19 12:22:3
    * Licensed under FreeBSD License.
    * See http://animista.net/license for more info.
    * w: http://animista.net, t: @cssanimista
    * ---------------------------------------------- */

    /**
     * ----------------------------------------
     * animation heartbeat
     * ----------------------------------------
     */
    @-webkit-keyframes heartbeat {
        from {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transform-origin: center center;
            transform-origin: center center;
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
        10% {
            -webkit-transform: scale(0.91);
            transform: scale(0.91);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
        17% {
            -webkit-transform: scale(0.98);
            transform: scale(0.98);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
        33% {
            -webkit-transform: scale(0.87);
            transform: scale(0.87);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
        45% {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
    }

    .heartbeat {
        -webkit-animation: heartbeat 2s ease-in-out 2s infinite both;
        animation: heartbeat 2s ease-in-out 2s infinite both;
    }

    @keyframes heartbeat {
        from {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transform-origin: center center;
            transform-origin: center center;
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
        10% {
            -webkit-transform: scale(0.91);
            transform: scale(0.91);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
        17% {
            -webkit-transform: scale(0.98);
            transform: scale(0.98);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
        33% {
            -webkit-transform: scale(0.87);
            transform: scale(0.87);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
        45% {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
    }

    .iconToWhite {
        filter: brightness(1000%);
    }

    .playButton {
        text-transform: inherit;
        color: white;
    }

    .notesButtonsImage {
        height: 30px;
        filter: brightness(0%);
    }
</style>
