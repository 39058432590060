<template>
    <div class="record-page">
        <video autoplay muted playsinline ref="video" id="video" :width="width" :height="height" style="background-color: black"></video>
        <div>
            <v-row v-if="!countDownStarted || recording" align-content="center" justify="center" class="pt-0">
                <v-col v-if="!recording" cols="auto" class="pa-2 pt-0">
                    <v-btn v-if="!recording" @click="startRecording" color="" :disabled="recording" elevation="1" icon>
                        <v-icon color="error" class="fas fa-circle"></v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="auto" v-if="recording" class="pa-2 pt-0" align-self="center">
                    <v-btn v-if="recording" @click="stopRecording" color="error" :disabled="!recording" elevation="1" icon>
                        <v-icon class="fas fa-stop-circle"></v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="auto" class="pl-0 pr-0 pt-1" align-self="center">
                    <div v-text="time" class="text-caption"></div>
                </v-col>
            </v-row>
            <v-row v-else-if="countDownStarted && countDown > 0" align-content="center" justify="center" class="pt-0">
                <v-col cols="auto" v-if="recording" class="pa-2 pt-0" align-self="center">
                    <v-btn v-if="recording" @click="stopRecording" color="error" :disabled="!recording" elevation="1" icon>
                        <v-icon class="fas fa-stop-circle"></v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="auto" class="pl-0 pr-0 pt-1" align-self="center">
                    <div v-text="countDown" class="text-subtitle-1"></div>
                </v-col>
            </v-row>
        </div>
        <div v-text="error" class="error--text"></div>
    </div>
</template>
<script>


export default {
    name: "VideoRecorder",
    props: {
        width: {
            required: true,
            default: 200
        },
        height: {
            required: true,
            default: 350
        },
        onRecordStarted: {
            required: false
        },
        onRecordStopped: {
            required: false
        },
        onVideoAvailable: {
            required: false
        }
    },
    data() {
        return{
            error: null,
            stream: null,
            recorder: null,
            recording: false,
            link: "",
            dataChunks: [],
            time: "00:00",
            seconds: 0,
            countDownStarted: false,
            countDown: 3,
        }
    },
    mounted() {
        this.requestCamera();
    },
    methods: {
        async requestCamera(){
            try{
                let strm = await navigator.mediaDevices.getUserMedia({
                    video: {
                        width: { min: 1024, ideal: 1280, max: 1920 },
                        height: { min: 576, ideal: 720, max: 1080 },
                        frameRate: { ideal: 30, min: 25, max: 30 },
                    },
                    audio: true,
                });
                this.stream = strm;
                if("srcObject" in this.$refs.video)
                    this.$refs.video.srcObject = strm;
                else
                    this.$refs.video.src = window.URL.createObjectURL(strm);
            }catch (e) {
                console.error(e);
                this.error = "Wir konnten keine Kamera auf Ihrem Gerät finden. Achten Sie darauf, dass die Kameraberechtigungen im Browser erteilt wurden.";
            }
        },
        async reset() {
            this.seconds = 0;
            this.time = "00:00";
            this.recording = false;
            this.dataChunks = [];
            this.countDown = 3;
            this.countDownStarted = false;
        },
        startRecording() {
            if(!this.error && this.stream){
                try{
                    this.reset();
                    this.recorder = new MediaRecorder(this.stream);   // , { mimeType: 'video/webm;codecs=vp8' }
                    this.recorder.ondataavailable = this.dataAvailable;
                    this.countDownStarted = true;
                    setTimeout(this.calcTime, 1000);
                }catch (e) {
                    console.error(e);
                    this.error = "Ihr Browser unterstützt keine Videaufnahmen, bitte nutzen Sie hierfür eine aktuelle Chrome Version";
                }
            }
        },
        stopRecording() {
            if(this.recorder && this.recording && !this.error){
                this.recorder.stop();
                this.recording = false;
                if(this.onRecordStopped) {
                    this.onRecordStopped();
                }
            }
        },
        stopPreview(){
          try{
            if(this.stream){
              this.stream.getTracks().forEach(function(track) {
                track.stop();
              });
            }
          }catch (e){
            console.error(e);
          }
        },
        calcTime() {
            if(this.recording || this.countDownStarted) {
                if (this.recording) {
                    this.seconds++;
                    let mins = Math.floor(this.seconds / 60);
                    let seconds = this.seconds - mins * 60;
                    this.time = this.str_pad_left(mins, "0", 2) + ":" + this.str_pad_left(seconds, "0", 2);
                } else if (this.countDownStarted) {
                    this.countDown--;
                    if (this.countDown <= 0) {
                        this.recorder.start();
                        this.recording = true;
                        this.countDownStarted = false;
                        if (this.onRecordStarted)
                            this.onRecordStarted();
                    }
                }
                setTimeout(this.calcTime, 1000);
            }
        },
        str_pad_left(string,pad,length) {
            return (new Array(length+1).join(pad)+string).slice(-length);
        },
        dataAvailable(event){
            console.log("Data: ");
            console.log(event);
            if(event.data.size > 0){
                this.dataChunks.push(event.data);
                if(this.onVideoAvailable)
                    this.onVideoAvailable(new Blob(this.dataChunks, {
                        type: "video/webm"
                    }));
                //this.download();
            }
        },
        download(){
            var blob = new Blob(this.dataChunks, {
                type: "video/webm"
            });
            var url = URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "test.webm";
            a.click();
            window.URL.revokeObjectURL(url);
        }
    }
}
</script>

<style scoped>

</style>
