<template>
    <!--todo Unteresfeld grau und mülleimer in chat wie bei der normalen view sprich funktion für eigene Felder-->
    <div>
        <v-row class="mx-0">
            <div class="whiteBg backgroundContainer">
                <!--Header of Chat-->
                <div style="background-color: #8cbd46;border-radius: 15px 15px 0 0;padding-top: 10px;padding-bottom: 10px;text-align: center;color: white;">
                    <v-row style="width: 90%; display: flex; align-items: center" class="pr-2 ml-2">
                        <v-col cols="9" md="8" lg="9" class="pa-0 pl-2 text-left d-flex align-center" style="font-size: xx-large">
                            <img :src="chatIcon" class="iconToWhite mr-1" style="height: 30px; margin-bottom: -3px" alt=""/>
                            <h1 class="ma-0 pl-2"><span style="text-decoration: underline white">C</span>hat</h1>
                        </v-col>
                    </v-row>
                </div>

                <!--body of Chat-->
                <div class="chat-body">
                    <!--matix user list-->
                    <v-row class="mx-1" v-if="rooms.length > 0">
                        <v-col cols="3" v-if="(selected && matrixUsers.length > 4) && page > 0">
                            <v-btn @click="page === 2 ? (page -= 2) : page--" class="pageButton" elevation="0" tile color="#737373">
                                <img :src="linksIcon" :height="30" class="iconToWhite" alt="Nach links"/>
                            </v-btn>
                        </v-col>
                        <v-col cols="3" @click="openPrivateRoom(item, (index + page))" v-for="(item, index) in (selected && matrixUsers.length > 4)? matrixUsers.slice(page,matrixUsers.length > 3 &&(page === 0 || page === matrixUsers.length - 3)? page + 3: matrixUsers.length === 3? page + 3: page + 2) : matrixUsers" :key="item._id" class="ellipsis" style="text-align: center; cursor: pointer">
                            <v-badge
                                    color="red"
                                    :content="item._id !== '0' ? (notificationArray[index + page].newMessagesCount) : blackboard.filter((el) => !el.seenByMe).length"
                                    :value="item._id !== '0' ? (notificationArray[index + page].newMessagesAvailable > 10 ? '10+': notificationArray[index + page].newMessagesAvailable) : blackboard.filter((el) => !el.seenByMe).length > 0"
                                    overlap
                                    offset-x="20"
                                    offset-y="20"
                                    style="z-index: 9; width: 100%">
                                <v-row>
                                    <v-tooltip :disabled="!showTooltips || !(index === matrixUsers.length - 1) || !hoeren" bottom style="z-index: 9">
                                        <template v-slot:activator="{ on }">
                                            <v-btn :id="'chatPartner-' + index"
                                                   @keydown.tab="setFocusOnFirstChatItem()"
                                                   @keydown.right="handleArrowKeyRight(index, item)"
                                                   @keydown.left="handleArrowKeyLeft(index, item)"
                                                   v-on="on"
                                                   :selected="selected === item._id"
                                                   class="mx-auto"
                                                   medium
                                                   elevation="0"
                                                   fab
                                                   color="#6994cc">
                                                <img :selected="selected === item._id && hasProfilePic(item)" :src="getTeachPic(item)" :height="hasProfilePic(item) ? 56 : 25" :class="hasProfilePic(item) ? 'roundImage' : 'iconToWhite'" :alt="item.lastName + ' Chat'"/>
                                            </v-btn>
                                        </template>
                                        <img :src="verwaltungMetacom" width="100" style="margin: auto" alt="">
                                    </v-tooltip>
                                </v-row>
                                {{ item.lastName }}
                            </v-badge>
                        </v-col>
                        <v-col cols="3" v-if="(selected && matrixUsers.length > 4) &&matrixUsers.length > 3 &&page < matrixUsers.length - 3">
                            <v-btn @click="page === 0 ? (page += 2) : page++" class="pageButton" elevation="0" tile color="#737373">
                                <img :src="rechtsIcon" alt="Nach rechts" :height="30" class="iconToWhite"/>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <hr class="mx-6 mb-1" style="color: #737373" v-if="selected"/>
                    <!-- Verwaltung -->
                    <ScrollAreaMagnifier :settings="settings" :usage="'chatWidgetBabyVerwaltung'" v-if="selected === '0'">
                        <div v-for="(item, index) in blackboard" :key="item._id + index + urls + reload" class="mx-6 mt-2">
                            <v-row style="color: #737373; line-height: 56px">
                                <v-col cols="2">
                                    <v-avatar class="mx-auto" medium elevation="0" fab :color="item.color">
                                        <img :src="chooseIcon(item.icon)" style="height: 25px" alt="Fachsymbol"/>
                                    </v-avatar>
                                </v-col>
                                <v-col cols="7">
                                    <div class="ellipsis" style="font-weight: bold; font-size: larger">
                                        <p :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                                           tabindex="0" class="ma-0 ellipsis" style="width: fit-content; height: fit-content;">{{ getTitle(item.createdBy, item.group) }}</p>
                                        <p class="ma-0 ellipsis visually-hidden" style="width: fit-content; height: fit-content;">{{ getTitle(item.createdBy, item.group, true) + ' um ' +
                                            new Date(item.createdOn).toLocaleDateString([], {
                                                day: "2-digit", month: "2-digit",
                                            }) + " " + getAccessibilityTime(new Date(item.createdOn).toLocaleTimeString([], {
                                                hour: "2-digit", minute: "2-digit",
                                            }))}}
                                        </p>
                                    </div>
                                </v-col>
                                <v-col cols="3" style="text-align: right">
                                    <p :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                                       tabindex="0" class="ma-0" style="width: fit-content; height: fit-content;
                                       float: right; white-space: nowrap">
                                        {{
                                            new Date(item.createdOn).toLocaleDateString([], {
                                                day: "2-digit", month: "2-digit",
                                            }) + " " + new Date(item.createdOn).toLocaleTimeString([], {
                                                hour: "2-digit", minute: "2-digit",
                                            })
                                        }}
                                    </p>
                                </v-col>
                            </v-row>
                            <div style="display: flex">
                                <div class="pa-3 blackboard-message-content">
                                    <div class="pa-3 ">
                                        <p tabindex="0" class="ma-0" v-html="urlify(item.message)"></p>
                                    </div>
                                    <hr v-if="blackboard[index].uploads.length" class="my-2">
                                    <div v-for="(el, index2) in blackboard[index].uploads" :key="el._id + index2 ">
                                        <div v-if="urls[el._id]" class="my-2">
                                            <!-- Display image preview -->
                                            <img v-if="['png', 'jpg', 'jpeg', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                 :src="urls[el._id]"
                                                 style="cursor: pointer" width="100%" height="auto"/>
                                            <!-- Display pdf preview -->
                                            <object type="application/pdf"
                                                    v-else-if="['pdf'].includes(el.title.split('.').pop().toLowerCase())"
                                                    :data="urls[el._id]"
                                                    style="cursor: pointer; min-height: 300px" width="100%" height="auto"/>
                                            <!-- Display video preview -->
                                            <video v-else-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                   :poster="videoPoster"
                                                   controls
                                                   style="cursor: pointer" width="100%" height="auto">
                                                <source :src="urls[el._id]" />
                                            </video>
                                        </div>
                                        <hr v-if="blackboard[index].uploads.length > index2 + 1" class="my-2">
                                    </div>
                                </div>
                                <div style="width: 15%">
                                    <v-tooltip :disabled="!showTooltips" top style="z-index: 9">
                                        <template v-slot:activator="{ on }">
                                            <v-btn :aria-hidden="person && person.accessibility &&
                                            person.accessibility.screenreader" v-on="on"
                                                   @click="readText(item.message, $event)" class="speakerButton preventColorInvert"
                                                   elevation="0" tile color="#737373">
                                                <img :src="lautsprecherIcon" :height="30" alt="Vorlesen"/>
                                            </v-btn>
                                        </template>
                                        <span>Vorlesen</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </div>
                    </ScrollAreaMagnifier>

                    <div v-if="viewingRoom" class="chat-body" style="overflow: hidden !important">
                        <!-- Normaler Chat -->
                        <ScrollAreaMagnifier ref="scrollAreaMagnifier" :settings="settings" :usage="'chatWidgetBaby'" @ps-y-reach-start="loadMore()">
                            <div :key="index" v-for="(event, index) in events.filter((el) => el.getType() === 'm.room.message')" class="mx-6 mt-2">
                                <!-- Nachrichten die nicht von mir sind -->
                                <div v-if="!isMe(event)">
                                    <v-row style="color: #737373; line-height: 56px">
                                        <v-col cols="2">
                                            <v-avatar class="mx-auto" medium elevation="0" fab color="#6994cc" v-if="!hasProfilePic(matrixUsers.find((el) => el._id == selected))">
                                                <img :src="getTeachPic(matrixUsers.find((el) => el._id == selected))" style="height: 25px" class="iconToWhite" alt="Profilbild"/>
                                            </v-avatar>
                                            <v-avatar v-else class="mx-auto" medium elevation="0" fab color="#6994cc">
                                                <img :src="getTeachPic(matrixUsers.find((el) => el._id == selected))" style="height: 48px" class="roundImage" alt="Profilbild"/>
                                            </v-avatar>
                                        </v-col>
                                        <v-col>
                                            <div class="ellipsis" style="font-weight: bold; font-size: larger">
                                                <p tabindex="0" class="ma-0" style="width: fit-content; height: fit-content">
                                                    {{ matrixUsers.find((el) => el._id == selected).lastName }}
                                                </p>
                                                <p class="ma-0 ellipsis visually-hidden" style="width: fit-content; height: fit-content;">{{ 'Nachricht von ' + matrixUsers.find((el) => el._id == selected).lastName + ' am ' +
                                                    new Date(getTime(event)).toLocaleDateString([], {
                                                        day: "2-digit", month: "2-digit",
                                                    }) + " " + getAccessibilityTime(new Date(getTime(event)).toLocaleTimeString([], {
                                                        hour: "2-digit", minute: "2-digit",
                                                    }))}}
                                                </p>
                                            </div>
                                        </v-col>
                                        <v-col style="text-align: right">
                                            <p tabindex="0" class="ma-0" style="width: fit-content; float: right">
                                                {{
                                                    new Date(getTime(event)).toLocaleDateString([], {
                                                        day: "2-digit", month: "2-digit",
                                                    }) + " " + new Date(getTime(event)).toLocaleTimeString([], {
                                                        hour: "2-digit", minute: "2-digit",
                                                    })
                                                }}
                                            </p>
                                        </v-col>
                                    </v-row>
                                    <div style="display: flex">
                                        <div class="pa-3 chat-message-content">
                                            <div v-if="event.getContent().body">
                                                <div v-if="event.getContent().body.startsWith('$fileId:')">
                                                    <div @click="loadedAudios.filter((el) => {return (el.file ===event.getContent().body.replace('$fileId:', '') );}).length > 0? playPause(event.getContent().body.replace('$fileId:', '')): loadFile(event.getContent().body.replace('$fileId:', ''))" style="line-height: 20px">
                                                        <div class="d-flex align-center" style="min-height: 24px">
                                                            <p tabindex="0" class="mb-0 mr-1">Sprachnachricht</p>
                                                            <v-tooltip v-if="!loadedAudios.filter((el) => {return (el.file ===event.getContent().body.replace('$fileId:', ''));}).length > 0" :disabled="!showTooltips" top style="z-index: 9">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" x-small class="pa-0 preventColorInvert" depressed style="background-color: transparent; min-width: 20px !important; width: 20px !important;" aria-label="Sprachnachricht herunterladen um sie anzuhören">
                                                                        <img :src="downloadIcon" class="icon" alt="Sprachnachricht laden"/>
                                                                    </v-btn>
                                                                </template>
                                                                <div style="display: flex; flex-direction: column; text-align: center">
                                                                    <span>Laden</span>
                                                                    <img v-if="hoeren" :src="herunterladenMetacom" width="100" style="margin: auto" alt="">
                                                                </div>
                                                            </v-tooltip>
                                                            <v-tooltip v-else-if="audioSrc !==event.getContent().body.replace('$fileId:', '')" :disabled="!showTooltips" top style="z-index: 9">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" x-small class="pa-0 preventColorInvert" depressed style="background-color: transparent; min-width: 20px !important; width: 20px !important;" aria-label="Sprachnachricht anhören">
                                                                        <img alt="Abspielen" :src="lautsprecherIcon" class="icon"/>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Sprachnachricht abspielen</span>
                                                            </v-tooltip>
                                                            <v-btn class="preventColorInvert" x-small depressed style="background-color: transparent; min-width: 20px !important; width: 20px !important;" v-else>
                                                                <img :src="lautsprecherAusIcon" class="icon" alt=""/>
                                                            </v-btn>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p class="ma-0" v-else tabindex="0" style="word-break: break-all" v-html="urlify(event.getContent().body, true)">
                                                </p>
                                            </div>
                                            <p tabindex="0" v-else class="ma-0" style="font-style: italic; width: fit-content">
                                                Diese Nachricht wurde gelöscht.
                                            </p>
                                        </div>
                                        <div style="width: 15%" v-if="!event.getContent().body ||!event.getContent().body.startsWith('$fileId:')">
                                            <v-tooltip :disabled="!showTooltips" top style="z-index: 9">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn :aria-hidden="person && person.accessibility &&
                                                    person.accessibility.screenreader" v-on="on"
                                                           @click="readText(event.getContent().body ||'Diese Nachricht wurde gelöscht.',$event)" class="speakerButton preventColorInvert" elevation="0" tile color="#737373">
                                                        <img :src="lautsprecherIcon" alt="Vorlesen" :height="30"/>
                                                    </v-btn>
                                                </template>
                                                <span>Vorlesen</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                </div>
                                <!-- Nachrichten die von mir sind -->
                                <div v-else>
                                    <v-row style="color: #737373">
                                        <v-col style="text-align: right">
                                            <p :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                                               tabindex="0" class="ma-0" style="width: fit-content; float: right">
                                                {{
                                                    new Date(getTime(event)).toLocaleDateString([], {
                                                        day: "2-digit", month: "2-digit",
                                                    }) + " " + new Date(getTime(event)).toLocaleTimeString([], {
                                                        hour: "2-digit", minute: "2-digit",
                                                    })
                                                }}
                                            </p>
                                            <p class="ma-0 ellipsis visually-hidden" style="width: fit-content; height: fit-content;">{{ 'Nachricht von mir am ' +
                                                new Date(getTime(event)).toLocaleDateString([], {
                                                    day: "2-digit", month: "2-digit",
                                                }) + " " + getAccessibilityTime(new Date(getTime(event)).toLocaleTimeString([], {
                                                    hour: "2-digit", minute: "2-digit",
                                                }))}}
                                            </p>
                                        </v-col>
                                    </v-row>
                                    <div style="display: flex">
                                        <div style="width: 15%" v-if="event.getContent().body">
                                            <v-tooltip :disabled="!showTooltips" top style="z-index: 9">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" @click="isMe(event) && event.getContent().body? (selectedEvent = event) && (deleteDialog = true): null" class="deleteButton preventColorInvert" elevation="0" tile color="#737373">
                                                        <img :src="papierkorbIcon" :height="30" class="" alt="Nachricht löschen"/>
                                                    </v-btn>
                                                </template>
                                                <div style="display: flex; flex-direction: column; text-align: center">
                                                    <span>Nachricht löschen</span>
                                                    <img v-if="hoeren" :src="loeschenMetacom" width="100" style="margin: auto" alt="">
                                                </div>
                                            </v-tooltip>
                                        </div>
                                        <div class="pa-3 my-chat-message-content">
                                            <div v-if="event.getContent().body">
                                                <div v-if="event.getContent().body.startsWith('$fileId:')">
                                                    <div @click="loadedAudios.filter((el) => {return (el.file ===event.getContent().body.replace('$fileId:', ''));}).length > 0? playPause(event.getContent().body.replace('$fileId:', '')): loadFile(event.getContent().body.replace('$fileId:', ''))" style="line-height: 20px">
                                                        <div class="d-flex align-center" style="min-height: 24px">
                                                            <p tabindex="0" class="mb-0 mr-1">Sprachnachricht</p>
                                                            <v-tooltip v-if="!loadedAudios.filter((el) => {return (el.file ===event.getContent().body.replace('$fileId:', ''));}).length > 0" :disabled="!showTooltips" top style="z-index: 9">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" x-small class="pa-0 preventColorInvert" depressed style="background-color: transparent; min-width: 20px !important; width: 20px !important;">
                                                                        <img :src="downloadIcon" class="icon" alt="Sprachnachricht laden"/>
                                                                    </v-btn>
                                                                </template>
                                                                <div style="display: flex; flex-direction: column; text-align: center">
                                                                    <span>Laden</span>
                                                                    <img v-if="hoeren" :src="herunterladenMetacom" width="100" style="margin: auto" alt="">
                                                                </div>
                                                            </v-tooltip>

                                                            <v-tooltip v-else-if="audioSrc !==event.getContent().body.replace('$fileId:', '')" :disabled="!showTooltips" top style="z-index: 9">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" x-small class="pa-0 preventColorInvert" depressed style="background-color: transparent; min-width: 20px !important; width: 20px !important;" >
                                                                        <img alt="Abspielen" :src="lautsprecherIcon" class="icon"/>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Sprachnachricht abspielen</span>
                                                            </v-tooltip>

                                                            <v-btn class="preventColorInvert" x-small depressed style="background-color: transparent; min-width: 20px !important; width: 20px !important;" v-else>
                                                                <img :src="lautsprecherAusIcon" class="icon" alt=""/>
                                                            </v-btn>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p class="ma-0" v-else tabindex="0" style="word-break: break-all" v-html="urlify(event.getContent().body, true)">
                                                </p>
                                            </div>
                                            <p tabindex="0" v-else class="ma-0" style="font-style: italic; width: fit-content">
                                                Diese Nachricht wurde gelöscht.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ScrollAreaMagnifier>

                        <hr class="mx-6 mb-1" style="color: #737373" v-if="selected && viewingRoom"/>

                        <div class="mx-6" v-if="selected && viewingRoom">
                            <!--              <div style="display: flex">-->
                            <!--                <v-tooltip top :open-on-hover="false" v-model="recordingTooltip" style="z-index: 9">-->
                            <!--                  <template v-slot:activator="{ attrs }">-->
                            <!--                    <v-btn v-bind="attrs" class="pa-2 chatButton" v-long-press="100" @long-press-start="startRecording" @long-press-stop="stopRecording"-->
                            <!--                           @keydown.enter="startRecording()" @keyup.enter="stopRecording()"-->
                            <!--                           @click="keyboard ? (toggle ? stopRecording() : startRecording()) : null"-->
                            <!--                           :style="audioCheck? toggle? 'background-color: red;': 'background-color: #8cbd46;': 'background-color: #5c5c5c;'">-->
                            <!--                      <span class="mr-2" style="font-weight: normal; text-transform: none;">Sprachnachricht</span>-->
                            <!--                      <img :src="mikrofonIcon" :height="20" class="iconToWhite" style="margin-bottom: -2px"/>-->
                            <!--                    </v-btn>-->
                            <!--                  </template>-->
                            <!--                  <span>{{recordingTime}}</span>-->
                            <!--                </v-tooltip>-->
                            <!--                <div style="width: 15%" class="pt-1">-->
                            <!--                  <v-btn style="margin-top: 0" @click="audioCheck? readText('Halte das Mikrofon gedrückt und rede. Wenn du das Mikrofon loslässt, schickst du deine Nachricht ab.',$event): readText('Leider können wir dein Mikrofon nicht benutzen.',$event)"-->
                            <!--                    class="speakerButton"-->
                            <!--                    elevation="0"-->
                            <!--                    tile-->
                            <!--                  >-->
                            <!--                    <img :src="lautsprecherIcon" alt="Vorlesen" :height="30" />-->
                            <!--                  </v-btn>-->
                            <!--                </div>-->
                            <!--              </div>-->

                            <!--              <div style="display: flex" class="pt-1">-->
                            <!--                <v-btn-->
                            <!--                    style="background-color: #e6231e!important;"-->
                            <!--                  class="pa-2 chatButton"-->
                            <!--                  @click="sendMessage('Ich habe ein Problem 😖')"-->
                            <!--                >-->
                            <!--                  <span style="text-transform: none !important; font-weight: normal !important; letter-spacing: normal !important;">Ich habe ein Problem 😖</span>-->
                            <!--                </v-btn>-->
                            <!--                <div style="width: 15%" class="pt-1">-->
                            <!--                  <v-btn-->
                            <!--                    @click="readText('Ich habe ein Problem', $event)"-->
                            <!--                    style="margin-top: 0"-->
                            <!--                    class="speakerButton"-->
                            <!--                    elevation="0"-->
                            <!--                    tile-->
                            <!--                    color="#737373"-->
                            <!--                  >-->
                            <!--                    <img :src="lautsprecherIcon" alt="Vorlesen" :height="30" />-->
                            <!--                  </v-btn>-->
                            <!--                </div>-->
                            <!--              </div>-->

                            <div style="display: flex">
                                <v-tooltip top :open-on-hover="false" v-model="recordingTooltip" style="z-index: 9">
                                    <template v-slot:activator="{ attrs }">
                                        <v-btn v-bind="attrs" class="pa-2 chatButton" style="width: 50%" v-long-press="100" @long-press-start="startRecording" @long-press-stop="stopRecording"
                                               @keydown.enter="startRecording()" @keyup.enter="stopRecording()"
                                               @click="keyboard ? (toggle ? stopRecording() : startRecording()) : null"
                                               :style="audioCheck? toggle? 'background-color: red;': 'background-color: #8cbd46;': 'background-color: #5c5c5c;'">
                                            <img :src="mikrofonIcon" :height="20" class="iconToWhite" style="margin-bottom: -2px"/>
                                        </v-btn>
                                    </template>
                                    <span>{{ recordingTime }}</span>
                                </v-tooltip>

                                <v-tooltip :disabled="!showTooltips || !hoeren" top style="z-index: 9">
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" style="background-color: #e6231e!important; width: 50%" class="pa-2 chatButton" @click="sendMessage('Ich habe ein Problem 😖')" aria-label="Ich habe ein Problem 😖">
                                            <span style="text-transform: none !important; font-weight: normal !important; letter-spacing: normal !important;">Problem 😖</span>
                                        </v-btn>
                                    </template>
                                        <img :src="problemMetacom" width="100" style="margin: auto" alt="">
                                        <img :src="meldenMetacom" width="100" style="margin: auto" alt="">
                                </v-tooltip>
                            </div>

                            <div style="display: flex" class="pt-1">
                                <div class="chatButton" style="background-color: white; display: inline-flex">
                                    <v-tooltip bottom style="z-index: 9" :disabled="!showTooltips || !hoeren">
                                        <template v-slot:activator="{ on }">
                                            <v-textarea id="chatTextArea"
                                                        label="Nachricht"
                                                        v-on="on"
                                                        hide-details
                                                        flat
                                                        solo
                                                        auto-grow
                                                        no-resize
                                                        v-model="inputMessage"
                                                        oncontextmenu="return false;"
                                                        @keyup.enter="sendInput"
                                                        placeholder="Nachricht eingeben..."
                                                        rows="1"
                                                        row-height="10"
                                                        class="chat-send-input pa-1"
                                                        style="max-height: 80px; overflow-x: hidden; overflow-y: auto;"
                                                        type="text"
                                            />
                                        </template>
                                        <img :src="tastaturTippenMetacom" width="100" style="margin: auto" alt="">
                                    </v-tooltip>
                                </div>
                                <div style="width: 15%" class="pt-1">
                                    <v-tooltip bottom style="z-index: 9" :disabled="!showTooltips">
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" @click="inputMessage.trim()
                                            || ( person && person.accessibility && person.accessibility.screenreader) ? sendInput() : readText('Tippe eine Nachricht ein um diese an deinen Lehrer zu senden.', $event)" class="speakerButton vertical-center preventColorInvert" elevation="0" tile color="#737373">
                                                <img :src="inputMessage.trim() || person.accessibility.screenreader ? sendenIcon : lautsprecherIcon" :height="30" :alt="inputMessage.trim() ? 'Senden' : 'Vorlesen'"/>
                                            </v-btn>
                                        </template>
                                        <div v-if="inputMessage.trim()
                                        || (person && person.accessibility && person.accessibility.screenreader)"
                                             style="display: flex; flex-direction: column; text-align: center">
                                            <span>Senden</span>
                                            <img v-if="hoeren" :src="sendenMetacom" width="100" style="margin: auto" alt="">
                                        </div>
                                        <span v-else>Vorlesen</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-row>

        <v-dialog overlay-opacity="0.8" v-model="deleteDialog" persistent max-width="400">
            <v-card>
                <v-card-title class="headline">Nachricht löschen?</v-card-title>
                <v-card-text>
                    Wollen Sie diese Nachricht wirklich löschen?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#bdbdbd" style="color: white" @click="deleteDialog = false">Abbrechen</v-btn>
                    <v-btn style="color: white" color="red red-1" @click="() => {this.deleteMessage(selectedEvent);this.deleteDialog = false;}">Löschen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Error Snackbar -->
        <v-snackbar bottom v-model="snackbar" :color="snackbarColor" timeout="5000">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbar = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon" />
            </v-btn>
          </template>
            {{ snackbarText }}
        </v-snackbar>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import vueCustomScrollbar from "vue-custom-scrollbar";
import chatIcon from "../../assets/Icons/chat-alternative-filled-102.svg";
import lehrerIcon from "../../assets/Icons/lehrer-24.svg";
import sonneIcon from "../../assets/Icons/sonne-weiss-46.svg";
import erdkundeIcon from "../../assets/Icons/f-erdkunde-weiss-31.svg";
import religionIcon from "../../assets/Icons/f-religion-weiss-39.svg";
import politikIcon from "../../assets/Icons/f-politik-weiss-34.svg";
import bioIcon from "../../assets/Icons/f-biologie-weiss-35.svg";
import downloadIcon from "../../assets/Icons/download-116.svg";
import playIcon from "../../assets/Icons/Play-114.svg";
import pauseIcon from "../../assets/Icons/Pause-115.svg";
import englischIcon from "../../assets/Icons/f-englisch-30.svg";
import englischIconWeiss from "../../assets/Icons/f-englisch-weiss-30.svg";
import schwarzesBrettIcon from "../../assets/Icons/sw-brett-neu-100.svg";
import lautsprecherIcon from "../../assets/Icons/lautsprecher-14.svg";
import lautsprecherAusIcon from "../../assets/Icons/lautsprecher-aus-90.svg";
import linksIcon from "../../assets/Icons/links-10.svg";
import rechtsIcon from "../../assets/Icons/rechts-11.svg";
import sendenIcon from "../../assets/Icons/senden-23.svg";
import papierkorbIcon from "../../assets/Icons/papierkorb-109.svg";
import mikrofonIcon from "../../assets/Icons/mikrofon-22.svg";
import groupChatIcon from "../../assets/Icons/chat-alternative-102.svg";
import sendenMetacom from '../../assets/METACOM/Chat/senden.png'
import herunterladenMetacom from '../../assets/METACOM/Hausis/speichern.png'
import loeschenMetacom from '../../assets/METACOM/Allgemein/loeschendaten.png'
import meldenMetacom from '../../assets/METACOM/Chat/meldenFB.png'
import problemMetacom from '../../assets/METACOM/Chat/problem.png'
import tastaturTippenMetacom from '../../assets/METACOM/Allgemein/tastaturtippen.png'
import verwaltungMetacom from '../../assets/METACOM/Chat/schule1.png'
import videoPoster from "@/assets/Bilder/videoposter.png";
import abbrechenIcon from "../../assets/Icons/abbrechen-08.svg";

import { getMatrixAddress } from "../../util/getMatrixAddress";
import * as BackendApi from "../../api/backend";
import * as sdk from "matrix-js-sdk";
import Recorder from "js-audio-recorder";
import AntiScroll from "../AntiScroll";

import * as backend from "../../api/backend";
import ScrollAreaMagnifier from "@/components/ScrollAreaMagnifier";

export default {
  name: "BabyChatWidget",
  components: {
      ScrollAreaMagnifier,
  },
  data() {
    return {
      urls: [],
      reload: false,

      inputMessage: "",

      linksIcon,
      rechtsIcon,

      chatIcon,
      abbrechenIcon,
      lehrerIcon,
      lautsprecherIcon,
      lautsprecherAusIcon,
      schwarzesBrettIcon,
      downloadIcon,
      playIcon,
      pauseIcon,
      videoPoster,

      papierkorbIcon,
      mikrofonIcon,
      sendenIcon,
      sendenMetacom,
      herunterladenMetacom,
        loeschenMetacom,
        meldenMetacom,
        problemMetacom,
        tastaturTippenMetacom,
        verwaltungMetacom,

        person: null,
        sehen: false,
        hoeren: false,
        babyView: false,

      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false,
      },
      matrixUsers: [],
      teacherPics: [], //collection of teacher profile pictures
      blackboard: [],

      selected: false,

      //Icon array for dynamically picking the icon via string
      icons: [
        { icon: englischIcon, text: "englischIcon" },
        { icon: sonneIcon, text: "sonneIcon" },
        { icon: englischIconWeiss, text: "englischIconWeiss" },
        { icon: politikIcon, text: "politikIcon" },
        { icon: erdkundeIcon, text: "erdkundeIcon" },
        { icon: religionIcon, text: "religionIcon" },
        { icon: bioIcon, text: "bioIcon" },
      ],

      page: 0,

      rooms: [],
      matrixRooms: [],
      matrixClient: null,
      viewingRoom: null,
      numMessagesToShow: 20,

      audioCheck: false,
      loadedAudios: [],
      recorder: new Recorder(),
      audio: new Audio(),
      audioSrc: "",

      toggle: false,
      chatIds: [], //contains ids that are already have a privte chat

      selectedEvent: null,
      deleteDialog: false,

      notificationArray: [],
      recordingTime: '',
      elapsedTimeIntervalRef: '',
      recordingTooltip: false,

      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
    };
  },

  async mounted() {
    await this.requestAppointments();
    this.requestTeachersProfilePics();
    await this.requestBlackboard();
    // Disabled image previews for now due to too much traffic
    this.loadImagePreviews();
    await this.requestGroups();
    await this.requestTeachers();
    await this.requestMatrixGroups();
    this.requestBabyViewAndAccessibilityMode();

    const matrixUserResponse = await BackendApi.getCurrentMatrixUser();
    const matrixUser = await matrixUserResponse.json();

    // Transform name like @alice-1603048221873-estundenplan-localhost-simon:estundenplan-synapse-development
    // to alice-1603048221873-estundenplan-localhost-simon
    const loginName = matrixUser.name.slice(1).split(":")[0];

    const matrixServerUrl = getMatrixAddress();
    const loginDataResponse = await fetch(
      `${matrixServerUrl}/_matrix/client/r0/login`,
      {
        method: "POST",
        body: JSON.stringify({
          type: "m.login.password",
          user: loginName,
          password: matrixUser.password,
        }),
      }
    );

    const loginData = await loginDataResponse.json();
    const matrixClient = sdk.createClient({
      baseUrl: matrixServerUrl,
      accessToken: loginData.access_token,
      userId: loginData.user_id,
    });
    this.matrixClient = matrixClient;

    matrixClient.on("sync", this.handleMatrixClientOnSync);
    matrixClient.on("Room", this.handleMatrixClientOnRoom);
    matrixClient.on("Room.timeline", this.handleMatrixClientOnRoomTimeline);

    matrixClient.startClient(10);

    this.checkPermission();
  },
  computed: {
    ...mapState("util", ["keyboard"]),
    ...mapState("translation", ["targetLang"]),
    ...mapState("auth", ["token", "account"]),
    ...mapState("magnifier", ["magnifier"]),
    events: function () {
      return this.viewingRoom.timeline;
    },
  },
    props: {
        showTooltips: {required: false, default: true},
    },

  async created() {
    this.notificationArray = [];
    for (let i = 0; i < this.matrixUsers.length; i++) {
      this.notificationArray.push({
        newMessagesCount: 0,
        newMessagesAvailable: false
      });
      const room = this.getRoomsFromId(this.matrixUsers[i].account);
      if (room) {
        await this.checkForNewMessages(room, i);
      }
    }
  },
  watch: {
    rooms: function (newVal) {
      this.notificationArray = [];

      let tempRooms = [];
      newVal.forEach((el, index) => {
        if(!el.name.includes('private Room')) {
          tempRooms.push({
            room: el.roomId,
            lastName: el.name,
            account: 1,
          })
        }
      });

      tempRooms.forEach((el, index) => {
        if (!this.containsGroup(this.matrixUsers, el)) {
          this.matrixUsers.push(el);
        }
      });
      for (let i = 0; i < this.matrixUsers.length; i++) {
        if (!this.matrixUsers[i]._id ) {
          this.matrixUsers[i]._id = i + 1;
        }
        if(this.matrixUsers[i]._id === "0") {
          this.matrixUsers.push(this.matrixUsers.splice(i, 1)[0]);
        }

        this.notificationArray.push({
          newMessagesCount: 0,
          newMessagesAvailable: false
        });

        const room = this.matrixUsers[i].room ? this.rooms.find((el) => el.roomId === this.matrixUsers[i].room) : this.getRoomsFromId(this.matrixUsers[i].account);
        if (room) {
          this.checkForNewMessages(room, i);
        }
      }
    }
  },
  methods: {
    ...mapActions("blackboard", ["getBlackboard"]),
    ...mapActions("appointments", ["getAppointments"]),
    ...mapActions("teachers", ["getProfilePicture"]),
    ...mapActions("matrix", ["getMatrixRoomLockStatus", "getMatrixRoomLockStatusById", "createPrivateRoom"]),
    ...mapActions("translation", ["setTranslatedText", "showTranslation", "translateToTargetLang"]),
    ...mapActions("groups", ['getGroupsInfo']),
    ...mapActions("pupils", ["getMePupil"]),

      async requestBabyViewAndAccessibilityMode() {
          let temp = await this.getMePupil();
          this.person = temp;
          this.babyView = temp.babyView;
          if(temp.accessibility) {
              this.sehen = temp.accessibility.sehen;
              this.hoeren = temp.accessibility.hoeren;
          } else {
              this.sehen = false;
              this.hoeren = false;
          }
          this.loaded = true; //to check if they are babys
          if (this.babyView) {
              this.babyViewTut = true;
          }
      },

    async loadImagePreviews() {
        this.urls = [];
        this.blackboard.forEach((el) => {
            el.uploads.forEach(async (file, index) => {
                if (file.file) {
                    let res;
                    // Try getting a thumbnail
                    res = await backend.getBlackboardUploadThumbnail(file.file);
                    if (res.ok && res.status === 200) {
                        let blob = await res.blob();
                        this.urls[file._id] = window.URL.createObjectURL(blob);
                    } else {
                        // If the thumbnail is not available => load the real file
                        res = await backend.getBlackboardUpload(file.file);
                        if (res.ok && res.status === 200) {
                            let blob = await res.blob();
                            this.urls[file._id] = window.URL.createObjectURL(blob);
                        }
                    }
                    this.reload = !this.reload
                }
            });
        });
    },

    async requestMatrixGroups() {
      this.matrixRooms = await this.getMatrixRoomLockStatus();
    },

    async checkForNewMessages(room, index) {
      const lastReadId = await room.getEventReadUpTo(room.myUserId, false);
      let unreadEventsCount = 0;
      let unreadMessageCount = 0;

      for (let i = 0; i < room.timeline.length; i++) {
        if (lastReadId === room.timeline[i].event.event_id) {
          unreadEventsCount = room.timeline.length - i - 1;
          break;
        }
        unreadEventsCount = room.timeline.length;
      }

      for (let i = 0; i < unreadEventsCount; i++) {
        if(room.timeline[room.timeline.length - 1 - i].event.type === "m.room.message") {
          unreadMessageCount++;
        }
      }

      this.notificationArray[index].newMessagesCount = unreadMessageCount;
      if (this.notificationArray[index].newMessagesCount > 0) {
        this.notificationArray[index].newMessagesAvailable = true;
      }
      this.updateMessageCount();
    },
    updateMessageCount() {
      let count = 0;
      this.notificationArray.forEach((el) => count += el.newMessagesCount);
      count += this.blackboard.filter((el) => !el.seenByMe).length;
      this.$emit('updateMessageCount', count);
    },
    async openPrivateRoom(item, index, focusin) {
        if(!(focusin && item._id === '0')) {
            if (this.selected === item._id) {
                this.selected = false;
                this.page = 0;
                this.viewingRoom = null;
                return;
            }
            this.selected = item._id;
            if (item._id === '0') {
                this.blackboard.forEach((el) => el.seenByMe = true);
                this.updateMessageCount();
            }
            if (this.matrixUsers.length > 4) {
                this.page = this.matrixUsers.findIndex((el) => el._id === item._id);
            }
            this.viewingRoom = null;
            const room = this.rooms.find((el) => {
                return ("" + el.roomId) === item.room
            });
            if (room) {
                const res = await this.getMatrixRoomLockStatusById(room.roomId);
                if (res.status === 401) {
                    // TODO nice to have: change roomstatus cache + Add different color like change [selected] hex value
                    console.log('room is locked');
                    this.snackbarText = 'Der Chat ist momentan geschlossen.';
                    this.snackbarColor = 'error';
                    this.snackbar = true;
                    return;
                }
            }
            if (item._id != 0) {
                if (item.room) {
                    this.notificationArray[index].newMessagesCount = 0;
                    this.notificationArray[index].newMessagesAvailable = false;
                    this.updateMessageCount();
                    await this.matrixClient.joinRoom(room.roomId);
                    await this.matrixClient.sendReceipt(room.timeline[room.timeline.length - 1], "m.read");
                    this.viewingRoom = room;
                    await this.$nextTick();
                    await this.$refs.scrollAreaMagnifier.jumpToLatestInitial();
                }
                if (!this.chatIds.includes(item.account)) {
                    await this.createPrivateRoom({second: item.account});
                    this.setRoomList();
                    let timer = 0;
                    while (!this.chatIds.includes(item.account)) {
                        await this.sleep(500);
                        timer++;
                        if (timer % 5 === 0) {
                            this.setRoomList();
                        }
                    }
                    const room = this.getRoomsFromId(item.account);
                    await this.matrixClient.joinRoom(room.roomId);
                    await this.matrixClient.sendReceipt(room.timeline[room.timeline.length - 1], "m.read");

                    this.viewingRoom = room;
                    await this.$nextTick();
                    await this.$refs.scrollAreaMagnifier.jumpToLatestInitial();
                } else {
                    const roomPrivate = this.getRoomsFromId(item.account);
                    const res = await this.getMatrixRoomLockStatusById(roomPrivate.roomId);
                    if (res.status === 401) {
                        // TODO: change roomstatus cache + Add different color like change [selected] hex value
                        console.log('room is locked');
                        this.snackbarText = 'Der Chat ist momentan geschlossen.';
                        this.snackbarColor = 'error';
                        this.snackbar = true;
                        return;
                    }
                    this.notificationArray[index].newMessagesCount = 0;
                    this.notificationArray[index].newMessagesAvailable = false;
                    this.updateMessageCount();
                    await this.matrixClient.joinRoom(roomPrivate.roomId);
                    await this.matrixClient.sendReceipt(roomPrivate.timeline[roomPrivate.timeline.length - 1], "m.read");
                    this.viewingRoom = roomPrivate;
                    await this.$nextTick();
                    await this.$refs.scrollAreaMagnifier.jumpToLatestInitial();
                }
            }
        }
    },
    getRoomsFromId(id) {
      return this.rooms.find((el) => el.name.includes(id));
    },

    addToChatIds(room) {
      this.chatIds.push(
        room.name
          .replace(this.account._id, "")
          .replace("private Room", "")
          .replace("<", "")
          .replace(">", "")
          .replace(",", "")
          .replace(" ", "")
      );
    },

    // Copy from ChatWidegtGroup Chat

    getUsername(event) {
      /*return event.sender.rawDisplayName.slice(0, event.sender.rawDisplayName.length-1);*/ //TODO and me too
      return event.sender.rawDisplayName;
    },
    //return only the gender char [m, w, d]
    getGender(event) {
      return event.sender.rawDisplayName.slice(
        event.sender.rawDisplayName.length - 1,
        event.sender.rawDisplayName.length
      );
    },
    isMe(event) {
      return this.matrixClient.credentials.userId === event.sender.userId;
    },

    async loadMore() {
      this.matrixClient.scrollback(this.viewingRoom, 1, (err, res) => {
        console.log(err);
      });
    },

    checkPermission() {
      Recorder.getPermission().then(
        () => {
          this.audioCheck = true;
        },
        (error) => {
          console.log(`${error.name} : ${error.message}`);
        }
      );
    },

    async loadFile(file) {
      /*console.log('load')*/
      const res = await BackendApi.getVoiceMessage(file);
      fetch(res.url, {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + this.token,
        }),
      })
        .then((response) => response.blob())
        .then((blob) => {
          /*console.log(file)
                    console.log(blob)*/
          let url = window.URL.createObjectURL(blob);
          this.loadedAudios.push({ file: file, url: url });
        });
    },

    playPause(file) {
      /*console.log(this.loadedAudios)*/
      if (this.audio) {
        this.audio.pause();
        this.audio = null;
        if (this.audioSrc === file) {
          this.audioSrc = "";
          return;
        }
        this.audioSrc = "";
      }
      this.audio = new Audio(
        this.loadedAudios.find((el) => el.file === file).url
      );
      this.audioSrc = file;
      this.audio.play();
      this.audio.addEventListener("ended", () => {
        this.audioSrc = "";
      });
    },
    startRecording() {
      if (this.audioCheck) {
          if(!this.toggle) {
              this.startTimer();
              this.recordingTooltip = true;
              this.toggle = true;
              /*console.log("Start Recording");*/
              this.recorder.startRecord();
              /*console.log(this.recorder);*/
          }
      }
    },
    stopRecording() {
      if (this.audioCheck) {
        this.recordingTooltip = false;
        this.toggle = false;
        this.toggle = false;
        /*console.log("Stop Recording")*/
        this.recorder.stopRecord();
        this.handleRecording(this.recorder.getWAVBlob());
        setTimeout(() => {
          this.stopTimer();
        }, 500);
      }
    },
    handleRecording(blob) {
      const formData = new FormData();
      formData.append(
        "file",
        blob,
        new Date().toLocaleDateString().replaceAll(".", "_") + ".wav"
      );

      this.XmlHttpRequest = BackendApi.postVoiceMessage(blob);

      this.XmlHttpRequest.onerror = (e) => {
        console.error("Private upload error:", e);
        this.XmlHttpRequest = null;
      };

      this.XmlHttpRequest.onabort = (e) => {
        console.warn("Private upload aborted");
        this.XmlHttpRequest = null;
      };

      this.XmlHttpRequest.addEventListener("load", (e) => {
        if (this.XmlHttpRequest.status !== 201) {
          console.error("Private upload failed:", this.XmlHttpRequest.response);
        }
        this.sendMessage(
          "$fileId:" + JSON.parse(this.XmlHttpRequest.response)._id
        );
        this.inputMessage = "";
        this.$refs.scrollAreaMagnifier.jumpToLatest();
        this.XmlHttpRequest = null;
      });
      this.XmlHttpRequest.send(formData);
    },

    sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
    getTime(event) {
      let age = event.getUnsigned().age;
      // TODO: proper format/hide date if message was sent today and always show time
      return new Date(Date.now() - age).toString();
    },

    // end of Copy

    sendInput() {
      if (this.inputMessage.trim()) {
        this.sendMessage(this.inputMessage);
        this.inputMessage = "";
        this.$refs.scrollAreaMagnifier.jumpToLatest();
      }
    },

    async sendMessage(message) {
      const res = await this.getMatrixRoomLockStatusById(this.viewingRoom.roomId);
      if(res.status===401){
        console.log('room is locked, cannot send message');
        this.snackbarText = 'Der Chat ist geschlossen.';
        this.snackbarColor = 'error';
        this.snackbar = true;
        return;
      }
      const content = {
        body: message,
        msgtype: "m.text",
      };
      this.matrixClient.sendEvent(
        this.viewingRoom.roomId,
        "m.room.message",
        content,
        "",
        (err, res) => {
          console.log(err);
        }
      );

      this.$refs.scrollAreaMagnifier.jumpToLatest();
    },

    async deleteMessage(message) {
      const res = await this.getMatrixRoomLockStatusById(this.viewingRoom.roomId);
      if(res.status===401){
        console.log('room is locked, cannot delete message');
        this.snackbarText = 'Der Chat ist geschlossen.';
        this.snackbarColor = 'error';
        this.snackbar = true;
        return;
      }
      this.matrixClient.redactEvent(
        this.viewingRoom.roomId,
        message.event.event_id,
        message._txnId,
        (err, res) => {
          console.log(err);
        }
      );
      if (this.selectedEvent.getContent().body.startsWith("$fileId:")) {
        const res = await BackendApi.deleteVoiceMessage(
          this.selectedEvent.getContent().body.replace("$fileId:", "")
        );
      }
    },
    urlify(text, alternate) {
      // eslint-disable-next-line no-useless-escape
      var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|software|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
      return text.toString().replace(urlRegex, function(url) {
        url = url.includes('http') ? url : 'http://' + url;
        if(alternate) {
            return '<a style="color: white" href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
        }
          return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
      })
      // or alternatively
      // return text.replace(urlRegex, '<a href="$1">$1</a>')
    },
    async requestBlackboard() {
      this.blackboard = await this.getBlackboard();
      this.updateMessageCount();

      this.blackboard.sort((a, b) => {
        let keyA = new Date(a.createdOn),
          keyB = new Date(b.createdOn);
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });
    },
    async requestAppointments() {
      const appointments = await this.getAppointments();
      appointments.forEach((el) => {
        el.teachers.forEach((element) => {
          if (!this.contains(this.matrixUsers, element)) {
            this.matrixUsers.push(element);
          }
        })

      });
      this.matrixUsers.push({ lastName: "Verwaltung", gender: "m", _id: "0" });
    },
    contains(a, obj) {
      let i = a.length;
      while (i--) {
        if (a[i]._id === obj._id) {
          return true;
        }
      }
      return false;
    },
    containsGroup(a, obj) {
      let i = a.length;
      while (i--) {
        if (a[i].room === obj.room) {
          return true;
        }
      }
      return false;
    },
    async requestTeachersProfilePics() {
      await this.setTeachProfilePics();
    },

    //get profile pics from all teachers and save them in teacherPics array
    async setTeachProfilePics() {
      for (let i = 0; i < this.matrixUsers.length; i++) {
        let profilePictureBlob = await this.getProfilePicture(
          this.matrixUsers[i]._id
        );
        const objectURL = URL.createObjectURL(profilePictureBlob);

        this.teacherPics.push([this.matrixUsers[i]._id, objectURL]);
      }
    },
    //get teacher profile pic from teacherPics array
    getTeachPic(user) {
      if (user._id == 0) {
        return schwarzesBrettIcon;
      }
      if (user.room) {
        return groupChatIcon;
      }
      for (let j = 0; j < this.teacherPics.length; j++) {
        let currTeacher = this.teacherPics[j];
        if (currTeacher === undefined) {
          continue;
        } else if (currTeacher[0] === user._id) {
          return currTeacher[1];
        }
      }
      return lehrerIcon;
    },
    //checks if chat opponent has a profile pic
    hasProfilePic(user) {
      for (let j = 0; j < this.teacherPics.length; j++) {
        let currTeacher = this.teacherPics[j];
        if (currTeacher === undefined) {
          continue;
        } else if (currTeacher[0] === user._id) {
          return true;
        }
      }
      return false;
    },

    chooseIcon(iconName) {
      for (let i of this.icons) {
        if (i.text === iconName) {
          return i.icon;
        }
      }
    },

    async readText(text, event) {
        if(this.person.accessibility.screenreader) {
            if ("speechSynthesis" in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
                return;
            }

            let txt;
            if (text) {
                txt = text;
            } else {
                txt = "Dieser Eintrag hat keinen Inhalt.";
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
                event.target.src = lautsprecherIcon;
            } else {
                if (this.targetLang !== "de") {
                    txt = await this.translateToTargetLang({
                        targetLang: this.targetLang, textToTranslate: txt
                    });

                    this.setTranslatedText(txt);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000);
                }

                event.target.src = lautsprecherAusIcon;
                let msg = new SpeechSynthesisUtterance();
                msg.text = txt;
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                } else {
                    event.target.src = lautsprecherIcon;
                }

                msg.onend = function () {
                    event.target.src = lautsprecherIcon;
                };
            }
        }
    },
    isLangPackageAvailable() {
      for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
        if (
          window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)
        ) {
          return true;
        }
      }

      return false;
    },
    expandText(event) {
      //from https://stackoverflow.com/questions/54459816/vue-text-highlight-event
      const isTextHighlighting = window.getSelection().toString().trim() !== "";

      //only expand when no text is being selected
      if (!isTextHighlighting) {
        event.target.classList.toggle("fade");
        event.target.classList.toggle("initHeight");
      }
    },

    // never gets called?
    async handleExitRoom() {
      console.log('handling Exit Room()');
      await this.matrixClient.sendReceipt(
        this.viewingRoom.timeline[this.viewingRoom.timeline.length - 1],
        "m.read"
      );
      this.viewingRoom = null;
    },

    // I am pretty sure this method is c&p and never used, should probably be deleted
    async handleRoomSelected(room) {
      console.log('attempting to enter room(handleRoomSelected): ',room.isOpen);
      if (room.isOpen === false) {
        console.log('room is locked');
      } else {
        await this.matrixClient.joinRoom(room.roomId);
        await this.matrixClient.sendReceipt(
          room.timeline[room.timeline.length - 1],
          "m.read"
        );
        this.viewingRoom = room;
      }
      await this.$nextTick();
    },
    handleMatrixClientOnSync(state, prevState, data) {
      switch (state) {
        case "PREPARED":
          this.setRoomList();
          break;
      }
    },
    handleMatrixClientOnRoom() {
      this.setRoomList();
      if (!this.viewingRoom) {
        // printRoomList();
        // rl.prompt();
      }
    },
    handleMatrixClientOnRoomTimeline(event, room, toStartOfTimeline) {
      if (toStartOfTimeline) {
        return; // don't print paginated results
      }
      if (!this.viewingRoom || this.viewingRoom.roomId !== room.roomId) {
        return; // not viewing a room or viewing the wrong room.
      }
    },
    setRoomList() {
      const roomList = this.matrixClient
        .getRooms()
        .filter((room) => room._selfMembership === "join");
      roomList.sort(function (a, b) {
        // < 0 = a comes first (lower index) - we want high indexes = newer
        var aMsg = a.timeline[a.timeline.length - 1];
        if (!aMsg) {
          return -1;
        }
        var bMsg = b.timeline[b.timeline.length - 1];
        if (!bMsg) {
          return 1;
        }
        if (aMsg.getTs() > bMsg.getTs()) {
          return -1;
        } else if (aMsg.getTs() < bMsg.getTs()) {
          return 1;
        }
        return 0;
      });
      this.rooms = roomList;
      this.chatIds = [];
      this.rooms.forEach((el) => this.addToChatIds(el));
      //adds property isOpen from backend to matrixRoom
      this.rooms = this.rooms.map((room) => {
        const matchingRooms = this.matrixRooms.filter((matrixRoom) => {
          return room.roomId===matrixRoom.roomId;
          })
          if(matchingRooms.length>0){
            room.isOpen = matchingRooms[0].isOpen;
          } else {
            //defaulting to open room if no match is found in backend, this should not happen
            room.isOpen = true;
          }
          return room;
      });
    },
    startTimer() {
      var startTime = new Date();
      this.recordingTime = this.getElapsedTime(startTime);
      this.elapsedTimeIntervalRef = setInterval(() => {
        this.recordingTime = this.getElapsedTime(startTime);
        console.log(this.recordingTime);
      }, 1000);
    },
    stopTimer() {
      if (typeof this.elapsedTimeIntervalRef !== "undefined") {
        clearInterval(this.elapsedTimeIntervalRef);
        this.elapsedTimeIntervalRef = undefined;
      }
      this.recordingTime = '';
    },
    getElapsedTime(startTime) {
      // Record end time
      let endTime = new Date();
      // Compute time difference in milliseconds
      let timeDiff = endTime.getTime() - startTime.getTime();
      // Convert time difference from milliseconds to seconds
      timeDiff = timeDiff / 1000;
      // Extract integer seconds that dont form a minute using %
      let seconds = Math.floor(timeDiff % 60); //ignoring uncomplete seconds (floor)
      // Pad seconds with a zero if neccessary
      let secondsAsString = seconds < 10 ? "0" + seconds : seconds + "";
      // Convert time difference from seconds to minutes using %
      timeDiff = Math.floor(timeDiff / 60);
      // Extract integer minutes that don't form an hour using %
      let minutes = timeDiff % 60; //no need to floor possible incomplete minutes, becase they've been handled as seconds
      // Pad minutes with a zero if neccessary
      let minutesAsString = minutes < 10 ? "0" + minutes : minutes + "";
      // Convert time difference from minutes to hours
      timeDiff = Math.floor(timeDiff / 60);
      // Extract integer hours that don't form a day using %
      let hours = timeDiff % 24; //no need to floor possible incomplete hours, becase they've been handled as seconds
      // Convert time difference from hours to days
      timeDiff = Math.floor(timeDiff / 24);
      // The rest of timeDiff is number of days
      let days = timeDiff;
      let totalHours = hours + (days * 24); // add days to hours
      let totalHoursAsString = totalHours < 10 ? "0" + totalHours : totalHours + "";
      if (totalHoursAsString === "00") {
        return minutesAsString + ":" + secondsAsString;
      } else {
        return totalHoursAsString + ":" + minutesAsString + ":" + secondsAsString;
      }
    },

      async requestGroups() {
          this.groups = await this.getGroupsInfo();
      },

      async requestTeachers() {
          const response = await backend.getTeachers();
          this.teachers = await response.json();
      },

        getTitle(creatorId, groupId, aria) {
          let creator = 'Verwaltung';
          this.teachers.forEach((el) => {
              if (el.account === creatorId) {
                  creator = el.name.slice(0, 1) + '. ' + el.lastName;
                  return creator; //Hope this works like break;
              }
          });

          let group = '';
          this.groups.forEach((el) => {
              if(el._id === groupId) {
                  group = el.name;
                  return group; //Hope this works like break;
              }
          });
            if(!aria) {
                return creator + ' → ' + group;
            } else {
                return 'Nachricht von ' + creator + ' an ' + group;
            }
      },

      setFocusOnFirstChatItem() {
        if(this.viewingRoom || this.selected === 0) {
            let verwaltungsIndex = this.matrixUsers.length - 1;
            document.getElementById('chatPartner-' + verwaltungsIndex).focus();
        }
      },
      handleArrowKeyLeft(index) {
          if(!this.keyboard) {
              if(index > 0) {
                  let id = 'chatPartner-' + (index - 1);
                  document.getElementById(id).focus();
                  this.openPrivateRoom(this.matrixUsers[index - 1], (index - 1 + this.page));
              }
          }
      },
      handleArrowKeyRight(index) {
          if(!this.keyboard) {
              if(index < this.matrixUsers.length - 1) {
                  let id = 'chatPartner-' + (index + 1);
                  document.getElementById(id).focus();
                  this.openPrivateRoom(this.matrixUsers[index + 1], (index + 1 + this.page));
              }
          }
      },
      getAccessibilityTime(time) {
          const charArray = time.split('');

          let spelledOutTime = '';
          if(charArray[0] !== '0') {
              spelledOutTime = spelledOutTime + charArray[0];
          }
          spelledOutTime = spelledOutTime + charArray[1];
          spelledOutTime = spelledOutTime + ' Uhr ';
          if(charArray[3] !== '0') {
              spelledOutTime = spelledOutTime + charArray[3];
          }
          spelledOutTime = spelledOutTime + charArray[4];
          return spelledOutTime;
      },
  },
};
</script>

<style>
  .ql-video {
    max-width: 100%;
  }
</style>

<style lang="scss" scoped>
.chat-body {
  position: relative;
  margin: auto;
  max-height: calc(80vh - 50px);
  height: calc(80vh - 50px);
}

.scroll-area {
  position: relative;
  overflow: hidden;
  max-height: calc(80vh - 179px);
  height: calc(80vh - 179px);
  margin-bottom: 2px;
}

.scroll-area2 {
  position: relative;
  overflow: hidden;
  max-height: calc(80vh - 279px);
  height: calc(80vh - 279px);
  margin-bottom: 2px;
}

.whiteBg {
  background-color: #ffffff;
}

.backgroundContainer {
  min-height: 75vh !important;
  height: auto;
  max-width: 90vw !important;
  width: 400px !important;
  border-radius: 15px;
  box-shadow: 1px 5px 5px silver;
  margin: auto auto 5em;
}

@media only screen and (max-width: 900px) {
  .backgroundContainer {
    width: 90vw !important;
  }
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.font-style {
  font-size: larger;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.roundImage {
  object-fit: cover;
  border-radius: 50%;
  height: 56px;
  width: 56px;
}

.speakerButton {
  background-color: transparent !important;
  border-color: transparent !important;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  margin-left: auto;
  margin-right: 0px;
  min-width: 0px !important;
  display: block;
}

.deleteButton {
  background-color: transparent !important;
  border-color: transparent !important;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  margin-left: 0;
  margin-top: 5px;
  margin-right: auto;
  min-width: 0px !important;
  display: block;
}

.pageButton {
  background-color: #cacaca !important;
  border-color: #cacaca !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 6px;
  margin: 3px;
  min-width: 0px !important;
  display: block;
}

[selected] {
  border: solid 5px #8cbd46 !important;
}

.chat-message-content {
  width: 85%;
  background-color: #6994cc;
  color: white;
  font-size: large;
  border-radius: 15px 15px 15px 0px;
}

.blackboard-message-content {
    width: 85%;
    background-color: #cacaca;
    color: black;
    font-size: large;
    border-radius: 15px 15px 15px 0px;
}

.my-chat-message-content {
  width: 85%;
  margin-left: auto;
  margin-right: 0;
  background-color: #cacaca;
  color: black;
  font-size: large;
  border-radius: 15px 15px 0px 15px;
}

.chat-send-input {
  cursor: pointer;
  background-color: white;
  border-radius: 25px;
  width: 100%;
  font-size: large;
  outline: none;
  border: solid 1px #737373;
}

.chatButton {
  cursor: pointer;
  width: 85%;
  background-color: #cacaca;
  color: white;
  font-size: large;
  border-radius: 15px;
  text-align: center;
}

.icon {
  height: 20px;
}

#micIcon {
  background-image: url("../../assets/Icons/mikrofon-22.svg");
  width: 30px;
  height: 30px;
}

h1 {
  display: inherit;
  font-size: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
  margin-left: inherit;
  margin-right: inherit;
  font-weight: inherit;
}

/* width */
::-webkit-scrollbar {
  width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 6px;
  width: 7px;
}
::-webkit-scrollbar-thumb:hover {
  background: #999;
  border-radius: 6px;
  width: 11px;
}
.vertical-center {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
