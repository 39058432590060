import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';


const state = {
  appointments: [],
};

const getters = {
  appointments: state => state.appointments,
  appointment: state => appId => state.appointments.find(appointment => appointment._id === appId),
};

const mutations = {
  setAppointments: (state, appointments) => {
    state.appointments = appointments;
  },
};

const actions = {
  async createAppointment ({ commit, dispatch }, params) {
    try {
      const res = await backend.postSchoolSubjectAppointment(params);
      await checkResponseStatus(201, res);
      const appointment = await res.json();
      return appointment;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async editAppointment ({ commit, dispatch }, params) {

    try {
      const id = params._id
      delete params._id;
      const res = await backend.patchSchoolSubjectAppointment(id, params);
      if(res.status === 409) {
        return 409;
      }
      const appointment = await res.json();
      return appointment;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async deleteAppointment({ commit, dispatch }, params) {
    let res;
    let id;
    let ruleId;

    try {
      switch (params.type) {
        case 'onlyThis':
          id = params.appointment._id;
          res = await backend.deleteSchoolSubjectAppointment(id);
          break;
        case 'thisAndFollowing':
          id = params.appointment._id;
          ruleId = params.appointment.rule;
          res = await backend.deleteSchoolSubjectAppointmentFromRule(id, ruleId);
          break;
        case 'allOfThem':
          ruleId = params.appointment.rule;
          res = await backend.deleteSchoolSubjectAppointmentRule(ruleId);
          break;
      }

      if(res.status === 409) {
        return 409;
      }
      await checkResponseStatus(204, res);
      return true;
    } catch (e) {
      console.log(e)
      if (res.status) {
        return res.status;
      }
      return false;
    }
  },

  async getAppointmentRuleById ({ commit, dispatch }, params) {
    try {
      const res = await backend.getSchoolSubjectAppointmentRuleFromId(params);
      await checkResponseStatus(200, res);
      const rule = await res.json();
      return rule;
    } catch(err) {
      return err.response.status;
    }
  },

  async getAppointments ({ commit, dispatch }) {

    try {
      const res = await backend.getSchoolSubjectAppointments();
      await checkResponseStatus(200, res);
      const appointments = await res.json();
      commit('setAppointments', appointments)
      return appointments;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async getAppointmentsParent ({ commit, dispatch }, params) {

    try {
      const res = await backend.getSchoolSubjectAppointmentsParent(params);
      await checkResponseStatus(200, res);
      const appointments = await res.json();
      return appointments;
    }
    catch(err) {
      return err.response.status;
    }
  },
  
  async createTeacherUploadViaFileId ({ commit, dispatch }, params) {
    try {
      const appointmentId = params.appointmentId;
      delete params.appointmentId;
      console.log('FileUploadById@param:'+JSON.stringify(params));
      const res = await backend.postTeacherUploadFromFileWidget(appointmentId, params);
      await checkResponseStatus(201, res);
      return true;
    }
    catch(err) {
      return err.response.status;
    }
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
