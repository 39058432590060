import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';


const defaultState = {
  stayLoggedIn: false,
  reloadLoggedIn: false,
  token: "",
  account: {},
};

const mutations = {
  login(state, token) {
    state.token = token;
  },
  setAccount(state, account) {
    state.account = account;
  },
  setStayLoggedIn(state, stay) {
    state.stayLoggedIn = stay;
  },
  setReloadLoggedIn(state, reload) {
    state.reloadLoggedIn = reload;
  },
  logout(state) {
    state.token = "";
    state.account = {};
    state.stayLoggedIn = false;
  },
};

const actions = {
  async loginUser ({ commit, dispatch }, {accountName, password}) {
    let success = true;
    const regexNoWhitespacesAtStartEnd = /^\s+|\s+$/gm;
    password = password.replace(regexNoWhitespacesAtStartEnd, '');
    const res = await backend.loginAccount({accountName, password});
    await checkResponseStatus(200, res).catch((err) => {
      success = false;
    });

    if(success) {
      const { token } = await res.json();
      commit('login', token);
      dispatch('getAccount');
    }

    return success;
  },
  async getAccount ({ commit }) {
    const resCurrentAccount = await backend.getCurrentAccount();
    await checkResponseStatus(200, resCurrentAccount).then(async () => {
      const account = await resCurrentAccount.json();
      console.log('Account: ', account);
      commit('setAccount', account);
    }).catch((er) => {
      commit('setAccount', {});
    });
  },

  async acceptTermsOfService ({ dispatch }) {
    try {
      const res = await backend.acceptTermsOfService();
      await checkResponseStatus(200, res);
      dispatch('getAccount');
    }
    catch(err) {
      return err.response.status;
    }
  },

  async changeStayLoggedIn ({ commit }, stay) {
    commit('setStayLoggedIn', stay);
  },
  autoLogoutUser({ commit, state }) {
    if(!state.stayLoggedIn && !state.reloadLoggedIn) {
      commit('logout');
    }
    commit('setReloadLoggedIn', false);
  },
  async logoutUser({ commit }) {
    commit('logout');
  },
  async reloadDontLogout({ commit }, reload) {
    commit('setReloadLoggedIn', reload);
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
