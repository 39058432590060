const defaultState = {
    focusId: null,
    keyPressed: null,
};

const mutations = {
    changeFocusId(state, value) {
        state.focusId = value;
    },
};

const actions = {
    setFocusId({ commit, state }, id) {
        commit("changeFocusId", id);
    },
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
};
