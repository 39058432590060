var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-start",attrs:{"id":"sideBarContainer"}},[_c('div',{staticClass:"e-row"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3 sidebarButton",attrs:{"aria-label":"Vergrößern","tabindex":"17","elevation":"3","x-small":"","id":"plusZoomButton"},on:{"click":function($event){return _vm.changeZoom(1)}}},on),[_c('p',[_vm._v("+")])])]}}])},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","text-align":"center"}},[_c('span',[_vm._v("Vergrößern")])])])],1),_c('div',{staticClass:"e-row"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3 sidebarButton",attrs:{"aria-label":"Verkleinern","tabindex":"17","elevation":"3","x-small":""},on:{"click":function($event){return _vm.changeZoom(-1)}}},on),[_c('p',[_vm._v("-")])])]}}])},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","text-align":"center"}},[_c('span',[_vm._v("Verkleinern")])])])],1),_c('v-divider',{staticClass:"my-2 mr-3 ml-1"}),_c('div',{staticClass:"e-row"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-menu',{attrs:{"offset-x":"","nudge-top":"130","nudge-right":"10","transition":"scale-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3 sidebarButton",class:{ 'sidebarButtonActive': _vm.memoMenu },attrs:{"aria-label":"Notiz, Sprachmemo","tabindex":"17","elevation":"3","x-small":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('img',{attrs:{"height":"35px","src":_vm.memoMenuIcon}})])]}}],null,true),model:{value:(_vm.memoMenu),callback:function ($$v) {_vm.memoMenu=$$v},expression:"memoMenu"}},[_c('MemoMenu',{on:{"close":function($event){_vm.memoMenu = false},"addAufnahme":function($event){return _vm.spawnOnCanvas('addAudioMessage')},"addNotiz":function (text) { return _vm.spawnOnCanvas('addTextMessage', text); }}})],1)]}}])},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","text-align":"center"}},[_c('span',[_vm._v("Notiz, Sprachmemo")])])])],1),_c('div',{staticClass:"e-row"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-menu',{attrs:{"offset-x":"","nudge-top":"185","nudge-right":"10","transition":"scale-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3 sidebarButton",class:{ 'sidebarButtonActive': _vm.mediaMenu },attrs:{"aria-label":"Audio, Bilder, Video","tabindex":"17","elevation":"3","x-small":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('img',{attrs:{"height":"35px","src":_vm.mediaMenuIcon}})])]}}],null,true),model:{value:(_vm.mediaMenu),callback:function ($$v) {_vm.mediaMenu=$$v},expression:"mediaMenu"}},[_c('MediaMenu',{on:{"close":function($event){_vm.mediaMenu = false},"addImage":function (url, file) { return _vm.$emit('addImageField', url, file); },"addVideo":function (url, file) { return _vm.$emit('addVideoField', url, file); }}})],1)]}}])},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","text-align":"center"}},[_c('span',[_vm._v("Audio, Bilder, Video")])])])],1),_c('div',{staticClass:"e-row"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-menu',{attrs:{"offset-x":"","nudge-top":"240","nudge-right":"10","transition":"scale-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3 sidebarButton",class:{ 'sidebarButtonActive': _vm.formMenu },attrs:{"aria-label":"Formen","tabindex":"17","elevation":"3","x-small":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('img',{attrs:{"height":"35px","src":_vm.formMenuIcon}})])]}}],null,true),model:{value:(_vm.formMenu),callback:function ($$v) {_vm.formMenu=$$v},expression:"formMenu"}},[_c('FormMenu',{on:{"addRectangle":function (color, border) { return _vm.$emit('addRectangle', color, border); },"addLine":function (color) { return _vm.$emit('addLine', color); },"close":function($event){_vm.formMenu = false}}})],1)]}}])},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","text-align":"center"}},[_c('span',[_vm._v("Formen")])])])],1),_c('div',{staticClass:"e-row"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-menu',{attrs:{"offset-x":"","nudge-right":"10","nudge-top":"300","transition":"scale-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3 sidebarButton",class:{ 'sidebarButtonActive': _vm.taskMenu },attrs:{"aria-label":"Interaktive Komponenten","tabindex":"17","elevation":"3","x-small":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('img',{attrs:{"height":"35px","src":_vm.taskMenuIcon}})])]}}],null,true),model:{value:(_vm.taskMenu),callback:function ($$v) {_vm.taskMenu=$$v},expression:"taskMenu"}},[_c('TaskMenu',{on:{"close":function($event){_vm.taskMenu = false},"addTextTask":function($event){return _vm.$emit('addTextTask')},"addAnswerField":function($event){return _vm.$emit('addAnswerField')},"addMoveableText":function (text, size) { return _vm.$emit('addMoveableText', text, size); },"addMultipleChoiceField":function (question, answers, size) {
                            _vm.$emit('addMultipleChoiceField', question, answers, size)
                        }}})],1)]}}])},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","text-align":"center"}},[_c('span',[_vm._v("Interaktive Komponenten")])])])],1),_c('v-spacer'),_c('div',{staticStyle:{"align-items":"flex-end !important"}},[_c('div',{staticClass:"text-justify pr-3"},[_c('p',{attrs:{"id":"pageCount"}},[_vm._v(_vm._s(_vm.currentPageIndex + 1)+"/"+_vm._s(_vm.pageCount))])]),_c('div',{staticClass:"e-row"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var menu = ref.on;
                        var attrs = ref.attrs;
return [_c('v-menu',{attrs:{"offset-x":"","nudge-right":"10","transition":"scale-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3 sidebarButton",class:{ 'sidebarButtonActive': _vm.addPageMenu },attrs:{"aria-label":"Seiten hinzufügen","tabindex":"17","elevation":"3","x-small":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('img',{attrs:{"height":"35px","src":_vm.pageMenuIcon}})])]}}],null,true),model:{value:(_vm.addPageMenu),callback:function ($$v) {_vm.addPageMenu=$$v},expression:"addPageMenu"}},[_c('AddPageMenu',{on:{"close":function($event){_vm.addPageMenu = false},"addAdditionalPage":function (type) { return _vm.$emit('addAdditionalPage', type); }}})],1)]}}])},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","text-align":"center"}},[_c('span',[_vm._v("Seite hinzufügen: Leer, Liniert, Kariert, Haus")])])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }