const setGlobalButtonContrast = (filter, border) => {
  // default filter for accessibility sehen is
  // invert(75%) saturate(200%) brightness(150%)

  let styles = `button:focus { filter: ${ filter }; 
      border: ${ border } }`;
  let styleTag = document.getElementById('accessibilityButtonFocusStyle');

  if (!styleTag) {
    styleTag = document.createElement('style');
    styleTag.id = 'accessibilityButtonFocusStyle';
    styleTag.innerHTML = styles;
    document.head.appendChild(styleTag);
  } else {
    styleTag.innerHTML = styles;
  }
};

export default setGlobalButtonContrast;
