import * as backend from "@/api/backend";

const state = {
  targetLang: 'de',
  translatedText: '',
  show: false,
};

const actions = {
    setTargetLang ({commit}, lang){
        commit('SET_TARGET_LANG', lang);
    },
    setTranslatedText ({commit}, text){
        console.log('setTranslatedText called with text', text);
        commit('SET_TRANSLATED_TEXT', text);
    },
    showTranslation ({commit}, show) {
      commit('SET_SHOW', show);
    },

    async translateToTargetLang({ commit, dispatch }, {targetLang, textToTranslate, textFormat}) {
      try {
        let params;
        if(textFormat) {
          params = {
            targetLang: targetLang,
            textToTranslate: textToTranslate,
            textFormat: textFormat,
          };
        } else {
          params = {
            targetLang: targetLang,
            textToTranslate: textToTranslate,
            textFormat: 'text',
          };
        }

        const res = await backend.translateToTargetLang(params);

        const json = await res.json();
        return json.data.translations[0].translatedText;
      }
      catch(err) {
        return err.response.status;
      }
    }
};

const getters = {
    getTargetLang: state => state.targetLang,
    getTranslatedText: state => state.translatedText,
    isTranslationShown: state => state.show,
};

const mutations = {
    SET_TARGET_LANG: (state, lang) => {
      state.targetLang = lang;
    },
    SET_TRANSLATED_TEXT: (state, text) => {
      state.translatedText = text;
    },
    SET_SHOW: (state, show) => {
        state.show = show;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
