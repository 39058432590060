import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';


const defaultState = {
};

const mutations = {
};

const actions = {

  async getPrivateFolder ({ commit, dispatch }) {

    try {
      const res = await backend.getPrivateUploads();
      await checkResponseStatus(200, res);
      const privateFiles = await res.json();
      return privateFiles;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async deletePrivateFile ({ commit, dispatch }, fileId) {

    try {
      const res = await backend.deletePrivateUpload(fileId);
      await checkResponseStatus(204, res);
    }
    catch(err) {
      return err.response.status;
    }
  },



};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
