import * as backend from '../../api/backend'

const safariArrayBuffer = async (blob) => {
    return new Promise((resolve) => {
        let fr = new FileReader();
        fr.onload = () => {
            resolve(fr.result);
        };
        fr.readAsArrayBuffer(blob);
    })
}

const actions = {
    pullCurrentFile: async ({dispatch, rootGetters}, {userGroup, fileType, fileName, appointmentId, fileId}) => {

        try {
            var headers = new Headers();
            headers.append("Content-Type", "application/json");

            /**
             * PDF Fetch - Dieser fetch braucht dann die richtige URL und die ganzen cors und auth sachen und so
             */
            let response;
            if (userGroup === 'pupil') {
                response = await backend.getTeacherUpload(appointmentId, fileId);
            } else if (userGroup === 'teacher') {
                response = await backend.getPupilUpload(appointmentId, fileId);
            } else if (userGroup === 'private') {
                response = await backend.getPrivateUpload(fileId);
            }

            dispatch('setCurrentFileType', fileType);

            if (fileType === 'pdf') {
                const params = {
                    filename: fileName,
                    blob: await response.blob()
                }
                const newBlob = new Blob([await params.blob], {type: 'application/pdf'});

                let arrayBuffer;
                if (Blob.prototype.arrayBuffer) {
                    arrayBuffer = await newBlob.arrayBuffer();
                } else {
                    arrayBuffer = await safariArrayBuffer(newBlob);
                }
                const pdfJSONArray = new Uint8Array(arrayBuffer);

                const result = {
                  status: response.status,
                  file: pdfJSONArray
                };
                return result;

            } else {

                const params = {
                    filename: fileName,
                    blob: await response.blob()
                }

                let src = "";
                var reader = new FileReader();
                const newImage = new Image();

                reader.onloadend = function() {
                    src = reader.result;
                    newImage.src = src;

                    // dispatch('setCurrentFile', newImage);

                }

                await reader.readAsDataURL(params.blob)

                  const result = {
                    status: response.status,
                    file: newImage
                  };
                  return result;

            }

        } catch (e) {
            if (e.response) {
                return e.response;
            }
            console.error(e);
        }
    },
    safeCurrentFile: async ({commit, dispatch, rootGetters}, {userGroup, data, appointmentId, fileId, fileType, fileName}) => {
        try {

            let newFileName;
            if (userGroup === 'pupil') {
                newFileName = fileName.split('.')[0] + ' bearbeitet.' + fileType;
            } else {
                newFileName = 'Korrektur ' + fileName.split('.')[0] + '.' + fileType;
            }

            let file = null;
            if (fileType === 'pdf') {
                file = new File([data], newFileName, { type: 'application/pdf' });
            } else {
                let fileRes = await fetch(data);
                let fileData = await fileRes.blob();
                file = new File([fileData], newFileName, {type: 'image/png'});
            }

            // const response = await backend.postPupilUpload(file);
            let response;
            if (userGroup === 'pupil') {
                response = await backend.postTeacherUploadEdit(appointmentId, fileId, file);
            } else {
                response = await backend.postPupilUploadEdit(appointmentId, fileId, file);
            }

            return response;
        } catch (e) {
            console.error(e);
        }
    },
    patchCurrentFile: async ({commit, dispatch, rootGetters}, {data, appointmentId, fileId, fileName}) => {
        const formData = new FormData();
        let file = new File([data], fileName, { type: 'application/pdf' });
        formData.append('file', file, fileName);
        let xmlRequest = await backend.patchTeacherUploadFile(appointmentId, fileId, file);
        xmlRequest.send(formData);
        return xmlRequest;
    },
    safeEmptyPage: async ({commit, dispatch, rootGetters}, {data, appointmentId, fileName, params = {}, userGroup = 'pupil'}) => {
      let fileRes = await fetch(data);
      let fileData = await fileRes.blob();
      let file = new File([fileData], fileName, {type: 'image/png'});

      const formData = new FormData();
      formData.append('file', file, `${fileName}.png`);
      if (userGroup !== 'pupil') {
        formData.append('visibleFor', JSON.stringify(params.selectedUser));
        formData.append('startDate', JSON.stringify(params.startDate));
        formData.append('isAssignment', JSON.stringify(params.isAssignment));
      }

      let pupilXmlHttpRequest;
      if(userGroup === 'pupil')
        pupilXmlHttpRequest = backend.postPupilUpload(appointmentId, file);
      else
          pupilXmlHttpRequest = backend.postTeacherUpload(appointmentId, file);
      pupilXmlHttpRequest.send(formData);

      return pupilXmlHttpRequest;
    },
    safeNewPdfFile: async ({commit, dispatch, rootGetters}, {data, appointmentId, fileName, params = {}, userGroup = 'pupil'}) => {
        let file = new File([data], fileName, {type: 'application/pdf'});

        const formData = new FormData();
        formData.append('file', file, `${fileName}.pdf`);
        if (userGroup !== 'pupil') {
            formData.append('visibleFor', JSON.stringify(params.selectedUser));
            formData.append('startDate', JSON.stringify(params.startDate));
            formData.append('isAssignment', JSON.stringify(params.isAssignment));
        }

        let pupilXmlHttpRequest;
        if(userGroup === 'pupil')
            pupilXmlHttpRequest = backend.postPupilUpload(appointmentId, file);
        else
            pupilXmlHttpRequest = backend.postTeacherUpload(appointmentId, file);
        pupilXmlHttpRequest.send(formData);

        return pupilXmlHttpRequest;
    },
    pullSavedWork: async ({ commit }, params) => {
        try {

            const response = await backend.getAutoSave(params.appointmentId, params.uploadId);
            return response;
            // console.log('pullSavedWork');
            // return null;
        } catch (e) {
            console.error(e);
        }
    },
    safeWork: async ({ commit }, { changeHistory, appointmentId, uploadId}) => {
        try {
            commit('SAFE_CURRENT_WORK', changeHistory);
            const params = { changeHistory }
            const response = await backend.patchAutoSave(appointmentId, uploadId, params);
            return response;
        } catch (e) {
            console.error(e);
        }
    },
    safeWorkFromEmptyPage: async ({ commit }, { changeHistory, appointmentId}) => {
      try {
        commit('SAFE_CURRENT_WORK', changeHistory);
        const params = { changeHistory }
        // const response = await backend.patchAutoSave(appointmentId, uploadId, params);
        // return response;

        return {status: 200};
      } catch (e) {
        console.error(e);
      }
    },

    setCurrentFile: ({commit}, file) => {
        commit('SET_CURRENT_FILE', file);
    },

    setCurrentFileUrl: ({commit}, fileUrl) => {
        commit('SET_CURRENT_FILE_URL', fileUrl);
    },

    setCurrentFileType: ({commit}, fileType) => {
        commit('SET_CURRENT_FILE_TYPE', fileType);
    },
    setSnackbar: ({ commit }, data) => {
        commit('SET_SNACKBAR', data);
    },
    setFileToBigSnackbarText: ({ commit }, data) => {
        commit('SET_FILE_TO_BIG_SNACKBAR_TEXT', data);
    },
};

const mutations = {
    SET_CURRENT_FILE: (state, file) => {
        state.currentFile = file;
    },
    SET_CURRENT_FILE_URL: (state, fileUrl) => {
        state.currentFileUrl = fileUrl;
    },
    SET_CURRENT_FILE_TYPE: (state, fileType) => {
        state.currentFileType = fileType;
    },
    SAFE_CURRENT_WORK: (state, work) => {
        state.workedOnFile = work;
    },
    SET_SNACKBAR: (state, data) => {
        state.snackbars[data.type] = data.value;
    },
    SET_FILE_TO_BIG_SNACKBAR_TEXT: (state, data) => {
        state.fileToBigSize = data;
    },
};

const getters = {
    getCurrentFile: state => state.currentFile,
    getCurrentFileUrl: state => state.currentFileUrl,
    getCurrentFileType: state => state.currentFileType,
    getWork: state => state.workedOnFile,
    getSnackbars: state => state.snackbars,
    getFileToBigSnackbarSize: state => state.fileToBigSize,
};

const state = {
    currentFile: null,
    currentFileUrl: null,
    workedOnFile: null,
    currentFileType: 'pdf',
    fileToBigSize: '5 MB',
    snackbars: {
      dataSaved: false,
      dataSubmitted: false,
      fileToBig: false,
      bigFileToBig: false,
      fileDoesntFitInPrivateFolder: false,
    },
};


export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}
