<template>
  <div>
    <div id="btnsContainer" class="d-flex justify-start">
        <!-- bbb for external guests, visible to teacher only -->
        <v-btn v-if="account.role === 'teacher'" @click="popupActive=true"
               :color="externalRoom.isActive ? 'gruen' : 'fgrau'" id="manageRoomExternal"
               style="margin-left: 15px" class="joinRoomBtn" elevation="0" hidden>
            <img :src="videoWeissIcon"/>
            <p>Externe</p>
        </v-btn>
        <!-- Error Snackbar -->
        <v-snackbar bottom v-model="snackbar" :color="snackbarColor" timeout="5000">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbar = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon"/>
            </v-btn>
          </template>
            {{ snackbarText }}
        </v-snackbar>
    </div>
    <!-- Popup Menu -->
    <v-dialog v-model="popupActive" color="" width="400" :scrollable="false" style="padding-top: 50px; z-index: 100; overflow-y: hidden;">
      <v-row class="popUpRow" style="margin: auto; box-shadow: none !important;">
        <v-card id="blackboardCardContrainer" class="rounded-lg" style="margin: auto">
          <!-- Popup-Header -->
          <div class="px-1 align-start"
            style="display: inline-flex; width: 100%; background-color: #707070; font-size: larger; padding-top: 10px; padding-bottom: 14px; color: white;">
            <div class="mt-1" style="margin-left: 10px; display:flex; align-items: center; justify-content: center; width: 100%">
              <img :src="videoWeissIcon" class="mx-2 videoIconPopup" alt="Video-Icon">
              <p class="ma-0" style="display: inline-block; font-size: 20px !important">Raum für externe Gäste</p>
              <v-spacer></v-spacer>
              <v-btn @click="resetPopup()" x-small class="RightTitleText d-flex align-center mr-2 ml-2" style="background-color: #f8f8f880; width: 35px; height: 35px;">
                <img :src="abbrechenIcon" class="boardIconDialog" alt="abbrechen-Icon"/>
              </v-btn>
            </div>
          </div>
          <!-- Popup-Content -->
          <div>
            <v-col>
              <v-card light class="ma-2" style="border-radius: 15px;">
                <v-card-title style="color: #707070; background-color: #EAEAEA; padding-top: 10px; padding-bottom: 10px; display:flex; align-items: center; justify-content: center">
                <p class="mb-0">Raum verwalten:</p>
                </v-card-title>
                  <v-card-text class="pa-2" style="overflow: auto; overflow-x: hidden;">
                    <v-col>
                      <v-row>
                        <v-spacer/>
                        <div v-if="externalRoom.isActive">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn @click="changeStatus(false)" v-bind="attrs" v-on="on" :disabled="!externalRoom.isActive" class="mr-3 mt-2" color="fgrau">
                                <p>Raum schließen</p>
                              </v-btn>
                            </template>
                            <p>Hier kann der aktuelle Raum geschlossen werden.</p>
                            <p>Alle Gäste, die sich noch im Raum befinden, werden hiermit entfernt.</p>
                            <p>Der bestehende Link für Gäste wird ungültig.</p>
                          </v-tooltip>
                        </div>
                        <div v-if="!externalRoom.isActive">
                          <v-row>
                            <v-col>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click="changeStatus(true)" v-bind="attrs" v-on="on" :disabled="externalRoom.isActive" style="width: 100%; margin:auto" color="gruen">
                                    <p>Raum erstellen</p>
                                  </v-btn>
                                </template>
                                <p>Hier kann ein neuer Raum geöffnet werden.</p>
                                <p>Der neu generierte Link muss dann an die Gäste verteilt werden.</p>
                              </v-tooltip>
                              <!-- UI for custom TimeLimit -->
                              <!-- <v-row>
                              <v-text-field class="ma-3 mt-8 font-weight-bold" style="width: 33%;" label="Zeitlimit (min)" readonly light outlined hide-details v-model="roomTimeLimit">
                              </v-text-field>
                              <v-btn @click="addTime(-15)" class="mr-3 mt-10" style="margin: auto" color="fgrau">
                                <p>-15min</p>
                              </v-btn>
                              <v-btn @click="addTime(15)" class="mr-3 mt-10" style="margin: auto" color="fgrau">
                                <p>+15min</p>
                              </v-btn>
                              </v-row> -->
                            </v-col>
                          </v-row>
                        </div>
                        <v-spacer/>
                      </v-row>
                      <div>
                        <v-row>
                          <span style="padding-top: 12px; margin: auto">Nach Öffnung des Raumes sollte die Lehrkraft den Raum direkt betreten und bis zum Ende der Sitzung anwesend bleiben.</span>
                          <span style="padding-top: 4px; margin: auto">Ein leerer Raum wird automatisch geschlossen und die Links werden ungültig. In diesem Fall muss der Raum einfach neu erstellt werden.</span>
                        </v-row>
                      </div>
                    </v-col>
                  </v-card-text>
              </v-card>
              <v-card light class="ma-2" style="border-radius: 15px;">
                <v-card-title style="color: #707070; background-color: #EAEAEA; padding-top: 10px; padding-bottom: 10px; display:flex; align-items: center; justify-content: center">
                  <p class="mb-0">Als Lehrer beitreten:</p>
                </v-card-title>
                <v-card-text class="pa-2" style="overflow: auto; overflow-x: hidden;">
                <v-row>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="joinAsTeacher()" v-on="on" v-bind="attrs" rounded plain style="width: 45%; margin:auto" :color="externalRoom.isActive ? 'gruen' : 'fgrau'">
                        <img :src="videoWeissIcon" style="height:30px"/>
                      </v-btn>
                    </template>
                  <span v-if="externalRoom.isActive">Den Raum als Moderator betreten.</span>
                  <span v-else>Der Raum muss zuerst geöffnet werden.</span>
                  </v-tooltip>
                </v-row>
                </v-card-text>
              </v-card>
              <v-card light class="ma-2" style="border-radius: 15px;">
                <v-card-title style="color: #707070; background-color: #EAEAEA; padding-top: 10px; padding-bottom: 10px; display:flex; align-items: center; justify-content: center">
                  <p class="mb-0">Link zum Teilen für Gäste:</p>
                </v-card-title>
                <v-card-text class="pa-2" style="overflow: auto; overflow-x: hidden;">
                  <v-row>
                    <v-text-field class="ma-3 font-weight-bold" style="width: 100%;" label="Link" readonly light outlined hide-details id="URLBBB" v-model="URLBBB">
                      <template v-slot:append>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon tabindex="0" v-bind="attrs" v-on="on" role="button" color="black" alt="URL zum Teilen für Gäste" :src="infoIcon"
                              @click="copyURL()" style="display: block; height: 45px; width: 45px; margin-top: -13px">
                              mdi-content-copy
                            </v-icon>
                          </template>
                          <p>Kopiert den Link in die Zwischenablage</p>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </div>
        </v-card>
      </v-row>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';

import videoIcon from '@/assets/Icons/kamera-video-72.svg';
import videoWeissIcon from '@/assets/Icons/kamera-video-weiss-72.svg';
import lehrerIcon from '@/assets/Icons/lehrer-24.svg';
import abbrechenIcon from "../assets/Icons/abbrechen-08.svg";
import infoIcon from "../assets/Icons/info-2-weiß-89.svg";
import aktualisierenIcon from "@/assets/Icons/aktualisieren-107.svg";


export default {
    name: "JoinExternalButton",
    data() {
        return {
            videoIcon,
            videoWeissIcon,
            lehrerIcon,
            abbrechenIcon,
            infoIcon,
            aktualisierenIcon,

            snackbar: false,
            snackbarText: '',
            snackbarColor: '',
            windowWidth: window.innerWidth,
            popupActive: false,

            externalRoom: null,
            URLBBB: "",
            roomTimeLimit: 60,
        };
    },
    async beforeMount() {
      // TODO: set State after confirming in backend
      this.externalRoom = await this.getMyExternalRoom();
      const response = await this.getGuestURL();
      this.URLBBB = response.redirectUrl;
    },
    async mounted() {
      this.$nextTick(() => {
          window.addEventListener('resize', this.onResize);
      });
    },
    computed : {
        ...mapState("auth", ["account"]),
    },
    watch: {
    },
    props: {
    },
    methods: {
        ...mapActions('util', [ 'toggleLoading' ]),
        ...mapActions('auth', [ 'reloadDontLogout' ]),
        ...mapActions('teachers', [ 'getMyExternalRoom', 'getJoinExternalRoom', 'getGuestURL', 'postChangeExternalRoomStatus' ]),

        onResize () {
            this.windowWidth = window.innerWidth;
        },

        async changeStatus(newStatus) {
          // TODO: include this.roomTimeLimit for DateEnd (timeLimit) initialization, then uncomment the html components
          await this.postChangeExternalRoomStatus(newStatus);
          const newGuestURL = await this.getGuestURL();
          this.URLBBB = newGuestURL.redirectUrl;
          this.externalRoom.isActive = newStatus;
        },

        onClickOutsideMenu() {
            this.popupActive = false;
        },

        resetPopup() {
          this.popupActive = false;
        },

        async copyURL() {
          let clipTxt = document.querySelector('#URLBBB');
          clipTxt.setAttribute('type', 'text');
          clipTxt.select();
          document.execCommand("copy");
        },

        async joinAsTeacher() {
          if(!this.externalRoom.isActive){
            return;
          }
          const response = await this.getJoinExternalRoom();
          this.reloadDontLogout(true);
          window.location.href = response.redirectUrl;
        },

        async addTime(time) {
          if(this.roomTimeLimit+time>=15 && this.roomTimeLimit+time<=180){
            this.roomTimeLimit += time;
          }
        }
    }
}
</script>

<style scoped lang="scss">
.v-btn {
    border-radius: 8px;
}

.ellipsis {
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.roundIcon {
    border-radius: 50%;
}

.boardIconDialog {
  height: 25px;
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.joinRoomBtn {
    color: white;
    text-transform: inherit;
    height: 50px !important;

    img {
        height: 30px;
        filter: brightness(1000%);
        margin-right: 5px;
    }

    p {
        font-size: large;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 0;
    }
}

.popUpRow {
  width: 95%;
  margin: auto;
}

.videoIconPopup {
  height: 25px;
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

@media only screen and (max-width: 900px){
  .popUpRow {
    margin-left: 33%;
    margin-right: 66%;
  }
}

@media only screen and (max-width: 900px) {
    .joinRoomBtn {
        height: 40px !important;
        width: 144px;
        padding: 8px;
        margin-left: 0;
        margin-bottom: 8px;

        p {
            font-size: small;
        }

        img {
            height: 21px !important;
            margin-right: 8px !important;
        }
    }
}

//when change to mobile nav on bottom
@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    .joinRoomBtn {
        padding: 8px !important;
        margin-left: 0 !important;
        width: 104px;
    }
}
</style>