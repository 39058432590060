<template>
    <div @click="$emit('focusMe')" :style="{'margin-top': account.role === 'teacher' ? '0px' : '32px'}">
        <v-row no-gutters justify="space-between" v-if="account.role === 'teacher'">
            <v-col>
                <img :src="moveIcon" ref="movingIcon" style="cursor: grab" height="25" width="25"/>
            </v-col>
            <v-col cols="auto">
                <img :src="papierkorbIcon" @click="$emit('deleteObject')" style="cursor: pointer" ref="deleteIcon"
                     height="25" width="25" class=""/>
            </v-col>
        </v-row>
        <div
            ref="choiceObject"
            id="choiceObject"
        >
            <p
                 :style="`
                    font-size: ${mData.width === 2 ? '30pt' : mData.width === 1 ? '25pt' : '15pt'};
                    margin-bottom: ${mData.width === 2 ? '5px' : mData.width === 1 ? '10px' : '10px'}
                `"
                 :tabindex="tabIndex"
                 v-if="mData.startTextTask"
                 class="mt-1 font-weight-bold"
            >{{ mData.startTextTask }}
            </p>
            <div
                v-for="(mChoice, index) in mData.multipleChoices"
                :key="`'answerField'${index}`"
            >
                <v-row dense align="center">
                    <v-col cols="auto">
                        <v-btn
                            role="checkbox"
                            :aria-checked="mChoice.checked"
                            @click="() => {propsDisabled ? null : mChoice.checked = !mChoice.checked }"
                            :tabindex="tabIndex"
                            icon
                            style="filter: unset !important; border-color: #22A9FF">
                            <v-icon
                                :color="showResults && mChoice.correct ? 'green' : showResults && !mChoice.correct ? 'red' : ''"
                                :size="mData.width === 2 ? '30' : mData.width === 1 ? '25' : '20'"
                            >{{ mChoice.checked ? 'fas fa-check-square' : 'far fa-square' }}
                            </v-icon>
                        </v-btn>
                    </v-col>
                    <v-col>
                        <p
                            :class="{
                            'green--text': showResults && mChoice.correct,
                            'red--text': showResults && !mChoice.correct,
                            }"
                            :style="`
                                font-size: ${mData.width === 2 ? '30pt' : mData.width === 1 ? '25pt' : '15pt'}
                            `"
                            class="pa-0 ma-0 font-weight-regular" :tabindex="tabIndex">
                            {{ mChoice.text }}
                        </p>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>

import moveIcon from '../../assets/Icons/Temp/move.svg';
import papierkorbIcon from "../../assets/Icons/papierkorb-109.svg";
import {mapActions, mapState} from "vuex";

export default {
    name: "EditorMultipleChoice",
    computed: {
        ...mapState("auth", ["account"]),
    },
    watch: {
        translate() {
            this.toggleTextLanguage();
        },
    },
    props: {
        mData: {type: Object, required: true},
        showResults: {type: Boolean, required: false, default: false},
        propsDisabled: {type: Boolean, required: false, default: false},
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
        targetLang: {type: String, required: false, default: 'de'},
        translate: {type: Boolean, required: false, default: false},
        tabIndex: { type: Number, required: false, default: 19 },
    },
    data: () => ({
        moveIcon,
        papierkorbIcon,

        alreadyTranslated: false, //whether the texts were already translated once
        //store original texts
        untranslatedTask: null,
        untranslatedAnswers: [],
        //store translated texts (so translating is only done once)
        translatedTask: null,
        translatedAnswers: [],
    }),
    mounted() {
        if (this.account.role === 'teacher')
            this.dragElement(this.$refs.movingIcon);
        if (!this.mData.width) {
            this.mData.width = 0;
        }
        this.storeOriginalTexts();
    },
    methods: {
        ...mapActions('translation', [ 'translateToTargetLang' ]),

        dragElement(element) {
            let _this = this;
            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            let lastMove = null;

            element.onmousedown = dragMouseDown;
            element.ontouchstart = dragMouseDown;

            function dragMouseDown(e) {
                _this.$emit('focusMe');
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                lastMove = e;
                document.onmouseup = closeDragElement;
                document.ontouchend = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
                document.ontouchmove = elementDrag;
            }

            const elementDrag = (e) => {
                e = e || window.event;
                lastMove = e;
                if (e.type !== 'touchmove') {
                    e.preventDefault();
                }

                // calculate the new cursor position:
                pos1 = pos3 - (e.clientX ? e.clientX : e.touches[0].clientX);
                pos2 = pos4 - (e.clientY ? e.clientY : e.touches[0].clientY);
                pos3 = (e.clientX ? e.clientX : e.touches[0].clientX);
                pos4 = (e.clientY ? e.clientY : e.touches[0].clientY);
                // set the element's new position:
                const top = (Number.parseFloat(this.mData.y)) - ((pos2 / this.canvasHeight) * 100);
                const left = (Number.parseFloat(this.mData.x)) - ((pos1 / this.canvasWidth) * 100);

                if ((left > -10 && left < 110) && (top > -10 && top < 110)) {
                    this.mData.x = left;
                    this.mData.y = top;
                }
            }

            const closeDragElement = (e) => {
                // stop moving when mouse button is released:
                document.onmouseup = null;
                document.onmousemove = null;
                document.ontouchend = null;
                document.ontouchmove = null;
                try {
                    this.click(e.clientX ? e.clientX : lastMove.touches[0].clientX, e.clientY ? e.clientY : lastMove.touches[0].clientY);
                } catch (e) {
                    // console.log(e);
                }
                lastMove = null;
            }
        },

        storeOriginalTexts() {
            this.untranslatedTask = this.mData.startTextTask;
            for (let i = 0; i < this.mData.multipleChoices.length; i++) {
                this.untranslatedAnswers[i] = this.mData.multipleChoices[i].text;
            }
        },

        async toggleTextLanguage() {
            //translate and replace displayed texts
            if (this.translate && !this.alreadyTranslated) {
                this.translatedTask = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: this.mData.startTextTask
                });
                this.mData.startTextTask = this.translatedTask;

                for (let i = 0; i < this.mData.multipleChoices.length; i++) {
                    this.translatedAnswers[i] = await this.translateToTargetLang({
                        targetLang: this.targetLang,
                        textToTranslate: this.mData.multipleChoices[i].text
                    });
                    this.mData.multipleChoices[i].text = this.translatedAnswers[i];
                }

                this.alreadyTranslated = true;
            }
            //just replace displayed texts with stored translated texts
            else if (this.translate && this.alreadyTranslated) {
                this.mData.startTextTask = this.translatedTask;
                for (let i = 0; i < this.mData.multipleChoices.length; i++) {
                    this.mData.multipleChoices[i].text = this.translatedAnswers[i];
                }
            }
            //set texts to original language
            else {
                this.mData.startTextTask = this.untranslatedTask;
                for (let j = 0; j < this.mData.multipleChoices.length; j++) {
                    this.mData.multipleChoices[j].text = this.untranslatedAnswers[j];
                }
            }
        },
    }
}
</script>

<style scoped>

</style>
