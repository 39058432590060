import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate'; // extract js files inside modules folder


Vue.use(Vuex);
Vue.config.productionTip = false;

// https://dev.to/simo_benhida/tip---import-vuejs-modules-at-once-58dl
// Import all modules at once.
const requireModule = require.context('./modules', false, /\.js$/);
const getModules = (moduleFilenames) => moduleFilenames.reduce((pv, filename) => {
  const moduleName = filename.replace(/(\.\/|\.js)/g, '');
  return {
    ...pv,
    [moduleName]: requireModule(filename).default,
  };
}, {});
const moduleFilenames = requireModule.keys();
const moduleNames = moduleFilenames.map((fileName) => fileName.replace(/(\.\/|\.js)/g, ''));
const modules = getModules(moduleFilenames);

const noPersistenModuls = ['magnifier','speechControl', 'appointments', 'faq', 'copypaste']
// https://github.com/robinvdvleuten/vuex-persistedstate
// Persist and rehydrate your Vuex state between page reloads.
const persistedStatePlugin = createPersistedState({
  paths: moduleNames.filter((el) => !noPersistenModuls.includes(el)), // Modules to be persisted
});

const store = new Vuex.Store({
  plugins: [persistedStatePlugin],
  modules,
  strict: process.env.NODE_ENV !== 'production',
});


export default store;
