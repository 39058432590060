<template>
    <div>
        <progress-bar
            :title="teacherUploadProgress === 100 ? 'Konvertieren' : 'Hochladen'"
            :show-progress="showTeacherUploadProgress"
            :progress="teacherUploadProgress"
            :indeterminate="teacherUploadProgress === 100"
            :abort-progress="teacherXmlHttpRequest ? () => { teacherXmlHttpRequest.abort() }: () => {}"
        />

        <v-dialog
            v-model="visible"
            max-width="500"
        >
            <v-card>
                <v-card-title class="headline">
                    Video in Video
                </v-card-title>
                <v-window v-model="step">
                    <v-window-item :value="1">
                        <v-card-text>
                            <vue-custom-scrollbar class="scroll-area-fachPopup" :settings="scrollSettings">

                            <div>
                                <v-row align="center">
                                    <v-col cols="auto" align-self="center">
                                        <img :src="infoIcon" style="height: 20px; filter: brightness(100%)"/>
                                    </v-col>
                                    <v-col>
                                        Laden Sie ein zweites Video hoch, das anschließend im primären Video abgespielt wird.
                                    </v-col>
                                </v-row>
                                <div class="text-center">
                                    Primäres Video
                                </div>
                                <v-row align="center" justify="center">
                                    <v-col cols="10">
                                        <video
                                            @ended="$refs.videoRecorder.stopRecording()"
                                            v-if="videoBlobs.firstUrl"
                                            :src="videoBlobs.firstUrl"
                                            ref="primaryVideoPlayer"
                                            style="background-color: black; width: 100%"
                                             height="auto">
                                            Your browser does not support the video tag.
                                        </video>
                                        <div v-else>
                                            <v-progress-circular indeterminate></v-progress-circular>
                                        </div>
                                    </v-col>
                                </v-row>
                                <div class="text-center">
                                    Sekundäres Video aufnehmen
                                </div>
                                <v-row align="center" justify="center">
                                    <v-col cols="10">
                                        <video-recorder
                                            :on-record-started="recordStarted"
                                            :on-record-stopped="recordStopped"
                                            :on-video-available="videoRecorded"
                                            ref="videoRecorder"
                                            width="100%"
                                            height="auto"></video-recorder>
                                    </v-col>
                                </v-row>
                                <input
                                    type="file"
                                    ref="fileUpload"
                                    style="display: none"
                                    hidden
                                    name="file" accept=".mp4"
                                    @change="videoSelected"
                                >
                                <v-row justify="center" align="center">
                                    <v-col cols="5" class="px-1">
                                        Stattdessen ein sekundäres Video hochladen
                                    </v-col>
                                    <v-col cols="auto" class="px-1">
                                        <v-btn
                                            @click="$refs.fileUpload.click()"
                                        >
                                            Hochladen
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                            </vue-custom-scrollbar>
                        </v-card-text>
                    </v-window-item>

                    <v-window-item :value="2">
                        <v-card-text>
                            <vue-custom-scrollbar class="scroll-area-fachPopup" :settings="scrollSettings">

                            <div>
                                Video Vorschau
                            </div>
                                <v-row>
                                    <v-col class="mb-10">
                                        <video-in-video
                                            :primary-video-src="videoBlobs.firstUrl"
                                            :secondary-video-src="videoBlobs.secondUrl"
                                            primary-video-width="100%"
                                            secondary-video-width="180"
                                            v-bind:size-controls="false"
                                            ref="videoInVideo"
                                            alt-error="Es scheint, als unterstütze Ihr Browser diese Vorschau möglicherweise nicht, nach dem Hochladen funktioniert die Video in Video Funktion allerdings wie gewohnt."
                                        >

                                        </video-in-video>
                                    </v-col>
                                </v-row>
                            </vue-custom-scrollbar>
                        </v-card-text>
                    </v-window-item>
                </v-window>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="grey darken-1"
                        text
                        @click="() => {
                            if(step === 1){
                                visible = false;
                                this.$refs.videoRecorder.stopPreview();
                            }else{
                                step--;
                                this.$refs.videoInVideo.stop();
                            }
                        }"
                    >
                        {{ step === 1 ? 'Abbrechen' : 'Zurück' }}
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        :disabled="!videoBlobs.secondUrl"
                        @click="() => {
                            if(step === 1)
                                step++;
                            else
                                this.uploadFile();
                        }"
                    >
                        {{ step === 1 ? 'Weiter' : 'Speichern' }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import infoIcon from "@/assets/Icons/info-45.svg";
import hochladenIcon from "@/assets/Icons/hochladen-15.svg";
import VideoRecorder from "@/components/Utils/VideoRecorder";
import * as backend from "@/api/backend";
import {mapState} from "vuex";
import VideoInVideo from "@/components/Utils/VideoInVideo";
import ProgressBar from "@/components/ProgressBar";
import vueCustomScrollbar from "vue-custom-scrollbar";

export default {
    name: "UploadVideoInVideoPopup",
    components: {ProgressBar, VideoInVideo, VideoRecorder, vueCustomScrollbar},
    props: {
        onClose: {
            required: false
        }
    },
    data() {
        return{
            infoIcon,
            hochladenIcon,
            teacherUploadProgress: 0,
            showTeacherUploadProgress: false,
            teacherXmlHttpRequest: null,
            visible: false,
            step: 1,
            primaryFile: null,
            appointment: null,
            videoBlobs: {
                firstBlob: null,
                firstUrl: null,
                secondBlob: null,
                secondUrl: null
            },
            scrollSettings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            }
        }
    },
    computed: {
        ...mapState("auth", ["token"]),
    },
    methods: {
        show(primaryVideo, appointment){
            this.visible = true;
            if(this.$refs.videoRecorder)
              this.$refs.videoRecorder.requestCamera();
            this.videoBlobs.firstBlob = null;
            this.videoBlobs.firstUrl = null;
            this.videoBlobs.secondBlob = null;
            this.videoBlobs.secondUrl = null;
            this.step = 1;
            if(this.$refs && this.$refs.videoRecorder)
                this.$refs.videoRecorder.reset();
            this.getPrimaryVideo(primaryVideo, appointment);
        },
        mounted(){
            if(this.$refs && this.$refs.videoRecorder)
                this.$refs.videoRecorder.reset();
        },
        async getPrimaryVideo(primaryVideo, appointment){
            if(primaryVideo && appointment){
                console.log(primaryVideo)
                console.log(appointment);
                this.primaryFile = primaryVideo;
                this.appointment = appointment;
                const res = await backend.getTeacherUpload(appointment._id, primaryVideo.file);
                fetch(res.url, {
                    method: 'GET',
                    headers: new Headers({
                        "Authorization": "Bearer " + this.token
                    })
                }).then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    this.videoBlobs.firstUrl = url;
                });
            }else
                console.error("Can not open without a primary video");
        },
        recordStarted(){
            if(this.$refs.primaryVideoPlayer) {
                this.$refs.primaryVideoPlayer.currentTime = 0;
                this.$refs.primaryVideoPlayer.play();
            }
        },
        recordStopped() {
            this.$refs.primaryVideoPlayer.pause();
            this.step++;
        },
        videoRecorded(blob){
            var url = window.URL.createObjectURL(blob);
            this.videoBlobs.secondUrl = url;
            this.videoBlobs.secondBlob = blob;
        },
        async setPipReference(pipId){
            const response = await backend.patchTeacherUploadPip(this.appointment._id, this.primaryFile._id, { pipReference: pipId });
            console.log("Final result");
            console.log(response);
            if(response.ok){
                this.visible = false;
                this.$refs.videoRecorder.stopPreview();
                if(this.onClose){
                    this.onClose();
                }
            }
        },
        videoSelected(evt){
            console.log("Video selected");
            console.log(evt);
            let file = this.$refs.fileUpload.files[0];
            console.log(file);
            var url = window.URL.createObjectURL(file);
            console.log(url);
            this.videoBlobs.secondUrl = url;
            this.videoBlobs.secondBlob = file;
            this.step++;
        },
        async uploadFile() {
            if (this.videoBlobs.secondBlob) {
                if (this.videoBlobs.secondBlob > 1000000000) {
                    console.error("Video too big")
                    //this.setSnackbar({ type: 'bigFileToBig', value: 'true' });
                    return;
                }

                const formData = new FormData();
                formData.append("file", this.videoBlobs.secondBlob, `Record_${new Date().toISOString().slice(0,10).replace(/-/g,"_")}.webm`);
                formData.append('visibleFor', JSON.stringify([]));
                this.teacherXmlHttpRequest = backend.postTeacherUpload(
                    this.appointment._id,
                    this.videoBlobs.secondBlob
                );

                this.teacherXmlHttpRequest.onerror = (e) => {
                    console.error("Teacher upload error:", e);
                    this.showTeacherUploadProgress = false;
                    this.teacherXmlHttpRequest = null;
                };

                this.teacherXmlHttpRequest.onabort = (e) => {
                    console.warn("Teacher upload aborted");
                    this.showTeacherUploadProgress = false;
                    this.teacherXmlHttpRequest = null;
                };

                this.teacherXmlHttpRequest.upload.addEventListener("progress", (e) => {
                    this.teacherUploadProgress = (e.loaded / e.total) * 100;
                });

                this.teacherXmlHttpRequest.addEventListener("load", (e) => {
                    if (this.teacherXmlHttpRequest.status !== 201)
                        console.error(
                            "Teacher upload failed:",
                            this.teacherXmlHttpRequest.response
                        );
                    this.showTeacherUploadProgress = false;
                    this.teacherXmlHttpRequest = null;
                    console.log("Upload erfolgreich");
                    console.log(e);
                    if(e && e.target && e.target.response){
                        console.log(e.target.response);
                        let data = JSON.parse(e.target.response);
                        this.setPipReference(data.schoolSubjectAppointmentUploadId);
                    }
                });
                this.teacherUploadProgress = 0.0;
                this.showTeacherUploadProgress = true;
                this.teacherXmlHttpRequest.send(formData);
            }
        },
    }
}
</script>

<style scoped>

.scroll-area-fachPopup {
    /*250px = 59px header, 48px join btn, 70px btmNavBar and paddings,margins*/
    max-height: calc(100vh - 250px);
}

</style>
