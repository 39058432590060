<template>
    <div>
        <v-btn @click="joinLehrerzimmer" id="joinSprechzimmerBtn" color="gruen"
               elevation="0"
        >
            <img alt="Videokonferenz beitreten Symbol" :src="videoIcon"/>
            <p>Lehrer</p>
        </v-btn>

        <!-- Error Snackbar -->
        <v-snackbar bottom v-model="snackbar" color="error" timeout="5000">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbar = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="schliessenIcon" />
            </v-btn>
          </template>
            {{ snackbarText }}
        </v-snackbar>
    </div>
</template>

<script>
import videoIcon from '../../assets/Icons/kamera-video-weiss-72.svg';
import * as backend from '../../api/backend';
import { mapActions }  from 'vuex';

import schliessenIcon from '../../assets/Icons/abbrechen-08.svg';

export default {
    name: "JoinLehrerzimmerButton",
    data() {
        return {
            videoIcon,
            schliessenIcon,
            snackbar: false,
            snackbarText: '',
        };
    },
    methods: {
        ...mapActions('util', [ 'toggleLoading' ]),
        ...mapActions('auth', [ 'reloadDontLogout' ]),
        async joinLehrerzimmer() {
            this.toggleLoading(true);
            const response = await backend.joinTeachersRoomBbbMeetingUrl();

            if (!response.status || response.status === 422 || response.status === 404) {
                this.snackbarText = 'Das Lehrerzimmer konnte nicht geladen werden. Bitte aktualisieren Sie die Seite!';
                this.snackbar = true;
                this.toggleLoading(false);
                return;
            }
            else if(response.status === 409){
              this.snackbarText = 'Das Lehrerzimmer ist aktuell geschlossen!';
              this.snackbar = true;
              this.toggleLoading(false);
              return;
            }

            this.reloadDontLogout(true);

            const {redirectUrl} = await response.json();
            window.location.href = redirectUrl;

            this.toggleLoading(false);
        }
    }
}
</script>

<style scoped lang="scss">
#joinSprechzimmerBtn {
    color: white;
    text-transform: inherit;
    height: 50px;
    border-radius: 8px;

    img {
        height: 30px;
        filter: brightness(1000%);
        margin-right: 5px;
    }

    p {
        font-size: large;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 900px) {
    #joinSprechzimmerBtn {
        height: 40px;
        width: 144px;
        padding: 8px;
        margin-bottom: 8px;

        img {
            height: 21px;
            margin-right: 8px;
        }

        p {
            font-size: small;
        }
    }
}
</style>
