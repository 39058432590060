<template>
        <div id="canvasContainer">
            <canvas ref="editcanvas" id="editcanvas" :tabindex="tabIndex"
                    aria-label="Aufgabenblatt" role="form" @keydown="handleKeyboardInput"></canvas>
            <div
                    v-show="isTextLayerVisible"
                    ref="textLayer"
                    :id="`textLayer${Number.parseInt(page)}`"
                    class="textLayer"
                    :style="{ width: textLayerWidth + 'px', height: textLayerHeight + 'px' }"
            >
            </div>
            <div
                v-show="isTextLayerVisible"
                ref="paragraphLayer"
                :id="`paragraphLayer${Number.parseInt(page)}`"
                class="textLayer"
                :style="{ width: textLayerWidth + 'px', height: textLayerHeight + 'px' }"
                style="pointer-events: none"
            >
                <div class="paragraphContainer"
                    v-for="(paragraph, index) in displayParagraphsList"
                    :key="index"
                    :style="{ left: (paragraph.left - 3) + 'px', top: (paragraph.top - 3) + 'px',
                        width: (paragraph.width + 3) + 'px', height: (paragraph.height + 3) + 'px' }"
                >
                    <v-btn v-if="!person.accessibility.screenreader" x-small elevation="1" style="height: 35px; width: 35px"
                           tabindex="18"
                           :class="{ 'paragraphButtonActive': paragraph.isActive }"
                           @click="readParagraph(paragraph)" class="readParagraphButton">
                        <img :src="paragraph.speakerIcon" class="readParagraphButtonImage" alt="Notiz vorlesen"/>
                    </v-btn>

                    <p tabindex="17" v-else style="height: 35px; width: 35px" class="readParagraphButton visually-hidden">
                        {{ getTextFromParagraph(paragraph) }}
                    </p>
                </div>
            </div>

            <v-snackbar
                    v-model="textToolActiveSnackbar"
                    timeout="10000"
                    color="gruen"
            >
                <template v-slot:action="{ attrs }">
                    <v-btn
                            text
                            v-bind="attrs"
                            small
                            @click="textToolActiveSnackbar = false"
                    >
                        <img style="max-width: 20px; filter: brightness(1000%)" alt="Schließen" :src="abbrechenIcon"/>
                    </v-btn>
                </template>
                Tippe auf die Stelle an der der Text später sein soll.
            </v-snackbar>

        </div>
</template>

<script>
    import {fabric} from "fabric";
    import {mapActions, mapGetters, mapState} from "vuex";
    import messageIcon from '../assets/Icons/lautsprecher-weiss-13.svg'
    import textMessageIcon from '../assets/Icons/bearbeiten-komplimentär-weiß-88.svg'
    import * as pdfjs from "pdfjs-dist";
    import lautsprecherIcon from "../assets/Icons/lautsprecher-13.svg";
    import lautsprecherAusIcon from "../assets/Icons/lautsprecher-aus-89.svg";
    import pdfTextLayer from '../util/pdfTextLayer'
    import abbrechenIcon from '../assets/Icons/abbrechen-08.svg';

    // import vuescroll from 'vuescroll'

    export default {
        name: "EditingCanvas",
        components: {
            // vuescroll,
        },
        data() {
            return {
                localCanvas: null,
                currentActiveTool: 'pen',
                currentColor: '#706f6f',
                currentText: null,
                isObjectSelected: false,
                history: [],
                objects: [],
                textLayerWidth: 0,
                textLayerHeight: 0,
                textObjects: [],
                isMouseDown: false,
                currentBackgroundImg: null,
                currentStempel: null,
                closeIconList: [
                    { color: '#fac837', icon: 'data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%23fac837;\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E'},
                    { color: '#f07d32', icon: 'data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%23f07d32;\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E'},
                    { color: '#e6231e', icon: 'data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%23e6231e;\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E'},
                    { color: '#6996cd', icon: 'data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%236996cd;\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E'},
                    { color: '#505faa', icon: 'data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%23505faa;\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E'},
                    { color: '#8cbe46', icon: 'data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%238cbe46;\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E'},
                    { color: '#706f6f', icon: 'data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%23706f6f;\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E'},
                ],
                closeMessageIcon: 'data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%23e6231e;\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E',
                placeMessageIcon: 'data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%238cbe46;\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'200\' y=\'350\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'125\'/%3E%3Crect x=\'300\' y=\'300\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'230\'/%3E%3C/g%3E%3C/svg%3E',
                scaling: null,
                originalOrientation: null,
                rotationAngle: 0,

                paragraphList: [],
                displayParagraphsList: [],

                // Keyboard Input
                // Movement
                movingInterval: null,
                // Icons
                messageIcon,
                textMessageIcon,
                abbrechenIcon,

                lautsprecherIcon,
                lautsprecherAusIcon,
                // UI Changes
                speakerIcon: [],
                textToolActiveSnackbar: false,

                person: {
                    accessibility: {
                        screenreader: false,
                    }
                }
            }
        },
        props: {
            canvasWidth: {type: Number, required: true},
            canvasHeight: {type: Number, required: true},
            loadingFunction: { type: Function, required: true },
            page: { required: false },
            tabIndex: { required: false, type: Number, default: 21 },
            isTextLayerVisible: { type: Boolean, required: true },
        },
        computed: {
            ...mapState("auth", ["account"]),
            ...mapGetters('files', ['getWork']),
            canvasAsImage() {
                this.$emit('dimensionsChanged',
                    { width: this.localCanvas.getWidth(), height: this.localCanvas.getHeight() });

                return this.localCanvas.toDataURL('image/png', 1);
            },
            filterHistoryForAdded() {
                const result = [];

                const addedArr = this.history.filter(item => item.action === 'added').map(item => item.object);
                const removedArr = this.history.filter(item => item.action === 'removed').map(item => item.object);

                addedArr.forEach(addedItem => {
                    if (!removedArr.includes(addedItem)) {
                        result.push(addedItem);
                    }
                });

                return result;
            }
        },
        methods: {
            ...mapActions('files', ['safeWork']),
            ...mapActions('pupils', ['getMePupil']),

            async requestPerson() {
                if(this.account.role === 'pupil') {
                    this.person = await this.getMePupil();
                    return this.person;
                }
            },

            handleKeyboardInput(event) {
                switch (event.keyCode) {
                    case 107: //plus zoom
                        this.$emit('updateZoom', 'plus');
                        break;
                    case 109: //minus zoom
                        this.$emit('updateZoom', 'minus');
                        break;
                    case 46: // delete
                        for (let i = 0; i < this.localCanvas.getActiveObjects().length; i++) {
                            this.history.push({ action: 'removed', object: this.localCanvas.getActiveObjects()[i] });
                        }

                        for (let i = 0; i < this.localCanvas.getActiveObjects().length; i++) {
                            this.localCanvas.remove(this.localCanvas.getActiveObjects()[i]);
                        }
                        break;
                    case 37: // Arrow left
                    case 38: // Arrow up
                    case 39: // Arrow right
                    case 40: // Arrow down
                        this.arrowKeysPressed(event); break;
                    case 32: // Spacebar
                    case 13: // Enter
                        // WIP
                        if (this.localCanvas.getActiveObject() && this.localCanvas.getActiveObject().type === 'i-text') {
                            const elementObj = this.localCanvas.getActiveObject();
                            const hitPoint = new fabric.Point(elementObj.left, elementObj.top);
                            this.localCanvas.fire('mouse:down', {
                                e: event,
                                absolutePointer: hitPoint,
                                button: 1,
                                isClick: false,
                                subTargets: [],
                                target: elementObj,
                                transform: null,
                            });
                            // this.localCanvas.getActiveObject().fire('mouse:dblclick', { target: elementObj });
                        }
                        break;
                }

            },
            arrowKeysPressed(keyEvent) {
                if (keyEvent.shiftKey) { // switch between items on canvas
                    let wantedObject = null;
                    const filteredHistory = this.filterHistoryForAdded;

                    if (!this.localCanvas.getActiveObject()) { // No canvas obj is active
                        if (keyEvent.keyCode === 40) {
                            wantedObject = filteredHistory[0];
                        } else if (keyEvent.keyCode === 38) {
                            wantedObject = filteredHistory[filteredHistory.length - 1];
                        }
                    } else {
                        const currentActiveObj = this.localCanvas.getActiveObject();
                        const activeObjIndex = filteredHistory.findIndex(item => item === currentActiveObj);

                        let newIndex = 0;
                        if (keyEvent.keyCode === 40) {
                            newIndex = activeObjIndex === filteredHistory.length-1 ? 0 : activeObjIndex + 1;
                        } else if (keyEvent.keyCode === 38) {
                            newIndex = activeObjIndex === 0 ? filteredHistory.length-1 : activeObjIndex - 1;
                        }
                        wantedObject = filteredHistory[newIndex];
                    }

                    this.localCanvas.setActiveObject(wantedObject);
                    this.localCanvas.requestRenderAll();

                } else { // move active item on canvas
                    const currentActiveObj = this.localCanvas.getActiveObject();

                    if (currentActiveObj) {
                        switch (keyEvent.keyCode) {
                            case 37: currentActiveObj.left -= 5; break;
                            case 38: currentActiveObj.top -= 5;  break;
                            case 39: currentActiveObj.left += 5; break;
                            case 40: currentActiveObj.top += 5; break;
                        }
                        this.localCanvas.requestRenderAll();
                    }
                }
            },
            setBackground(img, imgData) {
                try {

                    const element = imgData.path && imgData.path.length > 0 ? imgData.path[0] : imgData.target;

                    const sacale = window.innerWidth * 0.75;
                    let width = element.naturalWidth;
                    let height = element.naturalHeight;

                    const fabricImage = new fabric.Image(img);
                    if (element.naturalWidth > sacale) {
                        const scaleH = sacale / element.naturalWidth;
                        fabricImage.scaleToWidth(sacale);
                        fabricImage.scaleToHeight(element.naturalHeight * scaleH);
                        width = sacale;
                        height = element.naturalHeight * scaleH;
                        // Rescale the text layer
                        this.$refs.textLayer.style.transform = 'scale(' + scaleH + ')';
                        this.$refs.paragraphLayer.style.transform = 'scale(' + scaleH + ')';
                    }

                    this.localCanvas.setDimensions({ width, height });

                    this.localCanvas.setBackgroundImage(fabricImage, this.localCanvas.renderAll.bind(this.localCanvas));
                    this.loadingFunction(false);

                    this.$emit('dimensionsChanged',
                        { width: this.localCanvas.getWidth(), height: this.localCanvas.getHeight() });

                } catch (e) {
                    console.error(e);
                }
            },
            async setBackgroundPDF(pdfPage) {
                const firstPage = pdfPage;
                const viewport = firstPage.getViewport({scale: 1.5});

                const createdCanvas = document.createElement('canvas');
                createdCanvas.width = viewport.width;
                createdCanvas.height = viewport.height;

                const canvasContext = createdCanvas.getContext('2d');
                const renderContext = {canvasContext, viewport, renderInteractiveForms: true};
                const renderTask = firstPage.render(renderContext);
                renderTask.promise.then(() => {
                    const zwischenUrl = createdCanvas.toDataURL('image/png');

                    const zwischenImage = new Image();
                    zwischenImage.onload = (el) => {

                        this.setBackground(zwischenImage, el);

                    }
                    zwischenImage.src = zwischenUrl;
                    firstPage.cleanup();
                });

                // Display pdf text
                if(pdfPage){
                    this.paragraphList = [];
                    const textContent = await pdfPage.getTextContent();
                    this.textLayerHeight = viewport.height;
                    this.textLayerWidth = viewport.width;

                    const response = pdfjs.renderTextLayer({
                        textContent: textContent,
                        container: this.$refs.textLayer,
                        viewport: viewport,
                        textDivs: [],
                    });
                    // Fills list with paragraphs that belong together
                    this.paragraphList = pdfTextLayer.createTextParagraphs(response._textDivs);

                    if (!response._textDivs.length) {
                        this.$emit('textDivsEmpty');
                    }

                    const annotationData = await pdfPage.getAnnotations();
                    // Render the annotation layer
                    pdfjs.AnnotationLayer.render({
                        viewport: viewport.clone({dontFlip: true}),
                        div: this.$refs.textLayer,
                        annotations: annotationData,
                        page: pdfPage,
                        renderInteractiveForms: true,
                    });

                }
            },

            calculateParagraphPositions() {
                // Computes width and height of paragraphs and adds more elements to the paragraph when fitting
                this.displayParagraphsList = pdfTextLayer.calculateParagraphPositions(this.paragraphList);
            },
            readParagraph(paragraph) {

                let text = '';
                for (let i = 0; i < paragraph.listItem.length; i++) {
                    text += paragraph.listItem[i].innerHTML + ' ';
                }
                function changeSpeakerIcon(iconToSet, isActive) {
                    paragraph.speakerIcon = iconToSet;
                    paragraph.isActive = isActive;
                }

                this.$emit('readParagraph', text, changeSpeakerIcon);
            },

            getTextFromParagraph(paragraph) {
                let text = '';
                for (let i = 0; i < paragraph.listItem.length; i++) {
                    text += paragraph.listItem[i].innerHTML + ' ';
                }
                return text;
            },

            async setBackgroundImg(img) {
                if (img) {
                    img.onload = (imgData) => {
                        this.setBackground(img, imgData);
                    };
                }
            },
            async setEmptyBackground(type, width = 0, height = 0) {

                const wantedWidth = (width > 0 ? width : window.innerWidth * 0.75);
                const wantedHeight = (height > 0 ? height : wantedWidth * Math.sqrt(2));

                this.localCanvas.backgroundColor = "white";
                this.localCanvas.setDimensions({ width: wantedWidth, height: wantedHeight });
                this.$emit('dimensionsChanged',
                    { width: this.localCanvas.getWidth(), height: this.localCanvas.getHeight() });

                if (type === 'kariert') {

                    const kantenlaenge = wantedHeight/30;

                    for (let i = 0; i < 30; i++) {

                        // Numbers are start and end Coords, sorted: x1, y1, x2, y2
                        let horizontalLine = new fabric.Line([0, 0, wantedWidth, 0], {
                            left: 0,
                            top: i * kantenlaenge,
                            stroke: '#ddd',
                            selectable: false,
                            evented: false
                        });

                        this.localCanvas.add(horizontalLine);
                    }

                    for (let i = 0; i < wantedWidth / kantenlaenge - 1; i++) {
                        // Numbers are start and end Coords, sorted: x1, y1, x2, y2
                        let verticalLine = new fabric.Line([0, 0, 0, wantedHeight], {
                            left: i * kantenlaenge,
                            top: 0,
                            stroke: '#ddd',
                            selectable: false,
                            evented: false
                        });

                        this.localCanvas.add(verticalLine);
                    }

                    this.localCanvas.renderAll();

                } else if (type === 'liniert') {

                    for (let i = 0; i < 20; i++) {
                        // Numbers are start and end Coords, sorted: x1, y1, x2, y2
                        let line = new fabric.Line([0, 0, wantedWidth, 0], {
                            left: 0,
                            top: i * wantedHeight/20,
                            stroke: '#ddd',
                            selectable: false,
                            evented: false
                        });

                        this.localCanvas.add(line);

                    }

                    this.localCanvas.renderAll();

                } else if (type === 'haus') {

                    const lineHeight = (wantedHeight / 15) * 0.75;

                    for (let i = 1; i < 15; i++) {
                        // Numbers are start and end Coords, sorted: x1, y1, x2, y2

                        let topLine = new fabric.Line([0, 0, wantedWidth * 0.8, 0], {
                            left: wantedWidth * 0.1,
                            top: i * wantedHeight/15,
                            stroke: '#555',
                            selectable: false,
                            evented: false
                        });
                        this.localCanvas.add(topLine);

                        let topMiddleLine = new fabric.Line([0, 0, wantedWidth * 0.8, 0], {
                            left: wantedWidth * 0.1,
                            top: (i * wantedHeight/15) + (lineHeight * 0.3),
                            stroke: '#555',
                            selectable: false,
                            evented: false
                        });
                        this.localCanvas.add(topMiddleLine);

                        let bottomMiddleLine = new fabric.Line([0, 0, wantedWidth * 0.8, 0], {
                            left: wantedWidth * 0.1,
                            top: (i * wantedHeight/15) + (lineHeight * 0.7),
                            stroke: '#555',
                            selectable: false,
                            evented: false
                        });
                        this.localCanvas.add(bottomMiddleLine);

                        let bottomLine = new fabric.Line([0, 0, wantedWidth * 0.8, 0], {
                            left: wantedWidth * 0.1,
                            top: i * wantedHeight/15 + lineHeight,
                            stroke: '#555',
                            selectable: false,
                            evented: false
                        });
                        this.localCanvas.add(bottomLine);

                        let leftVerticalLine = new fabric.Line([0, 0, 0, lineHeight], {
                            left: wantedWidth * 0.1,
                            top: i * wantedHeight/15,
                            stroke: '#555',
                            selectable: false,
                            evented: false
                        });
                        this.localCanvas.add(leftVerticalLine);

                        let rightVerticalLine = new fabric.Line([0, 0, 0, lineHeight], {
                            left: wantedWidth * 0.9,
                            top: i * wantedHeight/15,
                            stroke: '#555',
                            selectable: false,
                            evented: false
                        });
                        this.localCanvas.add(rightVerticalLine);
                    }

                    this.localCanvas.renderAll();
                }

                this.loadingFunction(false);

            },
            safeCurrentWork() {
                let orientation = 'landscape';
                window.orientation === 0 || window.orientation === 180 ? orientation = 'portrait' : orientation = 'landscape';

                if (this.originalOrientation && orientation !== this.originalOrientation) {
                    this.zoomObjects(this.scaling);
                } else {
                    this.localCanvas.getObjects().forEach((obj) => {
                        obj.angle = obj.angle - this.rotationAngle;
                        let oldTop = obj.top;
                        let oldLeft = obj.left;

                        switch (this.rotationAngle % 360) {
                            case 0: break;
                            case 90:
                                obj.top = (this.localCanvas.width * (1/this.scaling)) - oldLeft * (1/this.scaling);
                                obj.left = oldTop * (1/ this.scaling);
                                obj.scale(1 / this.scaling);
                                break;
                            case 180:
                                obj.top = this.localCanvas.height - oldTop;
                                obj.left = this.localCanvas.width - oldLeft;
                                break;
                            case 270:
                                obj.top = oldLeft * (1/this.scaling);
                                obj.left = (this.localCanvas.height * (1/this.scaling)) - oldTop * (1/this.scaling);
                                obj.scale(1 / this.scaling);
                                break;
                        }

                        obj.setCoords();
                    });
                }

                return this.filterHistoryForAdded;

            },
            loadWork(safedWork) {
                for (let i = 0; i < safedWork.length; i++) {
                    const elementObj = new fabric.Object(safedWork[i]);

                    const fabricType = fabric.util.string.camelize(elementObj.type.charAt(0).toUpperCase() + elementObj.type.slice(1));

                    var klass = fabric.util.resolveNamespace('fabric.'+fabricType);
                    if (klass) {
                        klass.fromObject(elementObj, (result) => {
                            this.localCanvas.add(result);
                            this.history.push({ action: 'added', object: result });
                        });
                    }
                }

                this.localCanvas.renderAll();
            },
            addedDrawing(event) {
                if (this.currentActiveTool === 'marker') {
                    event.path.opacity = 0.3;
                }
                this.history.push({ action: 'added', object: event.path });
                this.localCanvas.discardActiveObject();
            },
            spawnText(xCoord, yCoord) {
                try {
                    let usableXCoord = xCoord ? xCoord : this.localCanvas.width / 2;
                    let usableYCoord = yCoord ? yCoord : this.localCanvas.height / 2;

                    var imageCopy = new Image();
                    imageCopy.src = (this.closeIconList.find(item => item.color === this.currentColor)).icon;

                    var itext = new fabric.IText('Doppelt tippen zum Schreiben', {
                        top: usableYCoord,
                        left: usableXCoord,
                        originX: 'center',
                        fill: this.currentColor,
                        fontFamily: 'eKlara Custom',
                        fontSize: 30,
                        padding: 10,
                        borderColor: this.currentColor,
                        cornerColor: this.currentColor,
                        cornerStyle: 'circle',
                        selectionBackgroundColor: '#FFFF00'
                    });

                    var itextClose = new fabric.Control({
                        x: 0.53,
                        y: -1.15,
                        offsetY: 16,
                        cursorStyle: 'pointer',
                        mouseUpHandler: (eventData, target) => {
                            this.localCanvas.remove(target);
                            this.history.push({ action: 'removed', object: target });
                        },
                        render: (ctx, left, top, styleOverride, fabricObject) => {
                            var size = 24;
                            ctx.save();
                            ctx.translate(left, top);
                            ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                            ctx.drawImage(imageCopy, -size/2, -size/2, size, size);
                            ctx.restore();
                        },
                        cornerSize: 24,
                        actionHandler: () => {},
                    })

                    itext.controls = { ...itext.controls, itextClose };

                    this.localCanvas.add(itext);
                    this.history.push({ action: 'added', object: itext });

                } catch (e) {
                    console.log(e);
                }
            },
            spawnAudio() {
                try {
                    const top = this.localCanvas.height / 2;
                    const left = this.localCanvas.width / 2;

                    var iconClose = new Image();
                    iconClose.src = this.closeMessageIcon;

                    var iconPlace = new Image();
                    iconPlace.src = this.placeMessageIcon;

                    fabric.loadSVGFromURL(this.messageIcon, (objects, options) => {
                        var image = fabric.util.groupSVGElements(objects, options);
                        console.log("Image", image);
                        image.set({
                            top: 5,
                            left: 5
                        });
                        image.scaleToWidth(40);
                        image.scaleToHeight(40);

                        var backgroundCircle = new fabric.Circle({
                            radius: 25,
                            fill: '#555'
                        });

                        var imageGroup = new fabric.Group([ backgroundCircle, image ], {
                            top: top - 25,
                            left: left - 25,
                        })

                        var imageClose = new fabric.Control({
                            x: -0.53,
                            y: 0.53,
                            offsetY: 16,
                            cursorStyle: 'pointer',
                            mouseUpHandler: (eventData, target) => {
                                this.localCanvas.remove(target);
                                this.history.push({ action: 'removed', object: target });
                            },
                            render: (ctx, left, top, styleOverride, fabricObject) => {
                                var size = 24;
                                ctx.save();
                                ctx.translate(left, top);
                                ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                                ctx.drawImage(iconClose, -size/2, -size/2, size, size);
                                ctx.restore();
                            },
                            cornerSize: 24,
                            actionHandler: () => {},
                        });
                        var imagePlace = new fabric.Control({
                            x: 0.53,
                            y: 0.53,
                            offsetY: 16,
                            cursorStyle: 'pointer',
                            mouseUpHandler: (eventData, target) => {
                                // Trigger placement of message
                                this.$emit('placeNewEditorMessage',
                                    (target.top + 25) / this.localCanvas.height, (target.left + 25) / this.localCanvas.width);

                                this.localCanvas.remove(target);
                                this.history.push({ action: 'removed', object: target });

                            },
                            render: (ctx, left, top, styleOverride, fabricObject) => {
                                var size = 24;
                                ctx.save();
                                ctx.translate(left, top);
                                ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                                ctx.drawImage(iconPlace, -size/2, -size/2, size, size);
                                ctx.restore();
                            },
                            cornerSize: 24,
                            actionHandler: () => {},
                        });

                        imageGroup.controls = { ...imageGroup.controls, imageClose, imagePlace };

                        this.localCanvas.add(imageGroup);
                        this.history.push({ action: 'added', object: imageGroup });

                    });

                } catch (e) {
                    console.log(e);
                }
            },
            spawnTextMessage() {
              try {
                  const top = this.localCanvas.height / 2;
                  const left = this.localCanvas.width / 2;

                  var iconClose = new Image();
                  iconClose.src = this.closeMessageIcon;

                  var iconPlace = new Image();
                  iconPlace.src = this.placeMessageIcon;

                  fabric.loadSVGFromURL(this.textMessageIcon, (objects, options) => {
                      var image = fabric.util.groupSVGElements(objects, options);

                      image.set({
                          top: 10,
                          left: 10
                      });
                      image.scaleToHeight(30);
                      image.scaleToWidth(30);

                      var backgroundCircle = new fabric.Circle({
                          radius: 25,
                          fill: '#555'
                      });

                      var imageGroup = new fabric.Group([ backgroundCircle, image ], {
                          top: top - 25,
                          left: left - 25,
                      })

                      var imageClose = new fabric.Control({
                          x: -1.05,
                          y: -0.25,
                          offsetY: 16,
                          cursorStyle: 'pointer',
                          mouseUpHandler: (eventData, target) => {
                              this.localCanvas.remove(target);
                              this.history.push({ action: 'removed', object: target });
                          },
                          render: (ctx, left, top, styleOverride, fabricObject) => {
                              var size = 40;
                              ctx.save();
                              ctx.translate(left, top);
                              ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                              ctx.drawImage(iconClose, -size/2, -size/2, size, size);
                              ctx.restore();
                          },
                          cornerSize: 24,
                          actionHandler: () => {},
                      });
                      var imagePlace = new fabric.Control({
                          x: 1,
                          y: -0.25,
                          offsetY: 16,
                          cursorStyle: 'pointer',
                          mouseUpHandler: (eventData, target) => {
                              // Trigger placement of message
                              this.$emit('placeNewEditorTextMessage',
                                  (target.top + 25) / this.localCanvas.height, (target.left + 25) / this.localCanvas.width);

                              this.localCanvas.remove(target);
                              this.history.push({ action: 'removed', object: target });

                          },
                          render: (ctx, left, top, styleOverride, fabricObject) => {
                              var size = 40;
                              ctx.save();
                              ctx.translate(left, top);
                              ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                              ctx.drawImage(iconPlace, -size/2, -size/2, size, size);
                              ctx.restore();
                          },
                          cornerSize: 24,
                          actionHandler: () => {},
                      });

                      imageGroup.controls = { imageClose, imagePlace };

                      this.localCanvas.add(imageGroup);
                      this.history.push({ action: 'added', object: imageGroup });

                  });
              } catch (e) {
                  console.log(e);
              }
            },
            handleMouseInput(event) {
                // Beispiel für ein Close Icon

                if (this.currentActiveTool === 'text' && this.localCanvas.getActiveObject() == null && !this.isObjectSelected) {
                    let pos = event.pointer;

                    this.spawnText(pos.x, pos.y);

                } else if (this.currentActiveTool === 'stempel' && this.localCanvas.getActiveObject() == null && !this.isObjectSelected) {
                    let pos = event.pointer;
                    try {

                        fabric.loadSVGFromURL(this.currentStempel, (objects, options) => {
                            var image = fabric.util.groupSVGElements(objects, options);

                            image.set({
                                left: pos.x - 15,
                                top: pos.y - 15,
                            });
                            image.scaleToWidth(75);
                            image.scaleToHeight(75);

                            this.localCanvas.add(image);
                            this.history.push({ action: 'added', object: image });

                        });
                    } catch (e) {
                        console.log(e);
                    }
                }
            },
            onCanvasObjectSelected(event) {
                this.isObjectSelected = true;

                // handle eraser
                if (this.currentActiveTool === 'eraser') {

                    for (let i = 0; i < this.localCanvas.getActiveObjects().length; i++) {
                        this.history.push({ action: 'removed', object: this.localCanvas.getActiveObjects()[i] });
                    }

                    for (let i = 0; i < this.localCanvas.getActiveObjects().length; i++) {
                        this.localCanvas.remove(this.localCanvas.getActiveObjects()[i]);
                    }

                }
            },
            onBeforeCanvasObjectDeselected(event) {
                this.isObjectSelected = false;
            },
            onCanvasObjectDeselected(event) {
                this.localCanvas.off('mouse:up', this.handleMouseInput);
                this.isObjectSelected = false;
                setTimeout(() => {
                    this.localCanvas.on('mouse:up', this.handleMouseInput);
                }, 250);
            },
            changeColor(newColor) {
                this.localCanvas.freeDrawingBrush.color = newColor;
                this.currentColor = newColor;
            },
            switchTool(newTool) {
                if (newTool === 'eraser') {
                    this.localCanvas.isDrawingMode = false;
                } else if (newTool === 'pen' || newTool === 'marker') {
                    this.localCanvas.isDrawingMode = true;
                    this.localCanvas.freeDrawingBrush.color = this.currentColor;
                } else if (newTool === 'text') {
                    this.textToolActiveSnackbar = true;
                    this.localCanvas.isDrawingMode = false;
                } else if (newTool === 'stempel') {
                    this.localCanvas.isDrawingMode = false;
                }

                this.currentActiveTool = newTool;
            },
            undo() {
                if (this.history.length) {
                    if (this.history[this.history.length - 1].action === 'added') {
                        const objToRemove = this.localCanvas.getObjects()
                            .find(item => item === this.history[this.history.length - 1].object);

                        this.localCanvas.fxRemove(objToRemove);
                        this.history.splice(this.history.length - 1);
                        this.localCanvas.renderTop();
                    } else if (this.history[this.history.length - 1].action === 'removed') {
                        const objToAdd = this.history[this.history.length - 1].object;
                        this.localCanvas.add(objToAdd);

                        this.history.splice(this.history.length - 1);
                    }
                }

            },
            changeSize(pixelCount) {
                if (this.localCanvas.isDrawingMode) {
                    this.localCanvas.freeDrawingBrush.width = parseInt(pixelCount);
                }
            },
            setStempelImg(stempelImg) {
                this.currentStempel = stempelImg;
            },
            rotate() {
                let orientation = 'landscape';
                window.orientation === 0 || window.orientation === 180 ? orientation = 'portrait' : orientation = 'landscape';

                const containerWidth = window.innerWidth * 0.75
                // if (this.localCanvas.getWidth() >= containerWidth) {
                    const width = containerWidth;
                    const height = this.localCanvas.getHeight() * (containerWidth / this.localCanvas.getWidth());

                    const scalingFactor = containerWidth / this.localCanvas.getWidth();

                    this.localCanvas.setDimensions({width, height});
                    if (!this.scaling || (this.originalOrientation && this.originalOrientation !== orientation)) {
                        this.scaling = scalingFactor;
                        this.localCanvas.setZoom( scalingFactor);
                        // this.zoomObjects(scalingFactor);
                    } else {
                        // this.zoomObjects(1/this.scaling);
                        this.localCanvas.setZoom(1);
                    }

                    if (!this.originalOrientation) {
                        orientation === 'portrait'
                            ? this.originalOrientation = 'landscape' : this.originalOrientation = 'portrait'
                        // this.originalOrientation = orientation;
                    }
                // }

            },
            zoomObjects(factor) {
                var objects = this.localCanvas.getObjects();
                for (var i in objects) {
                    var scaleX = objects[i].scaleX;
                    var scaleY = objects[i].scaleY;
                    var left = objects[i].left;
                    var top = objects[i].top;

                    var tempScaleX = scaleX * factor;
                    var tempScaleY = scaleY * factor;
                    var tempLeft = left * factor;
                    var tempTop = top * factor;

                    objects[i].scaleX = tempScaleX;
                    objects[i].scaleY = tempScaleY;
                    objects[i].left = tempLeft;
                    objects[i].top = tempTop;

                    // objects[i].setCoords();
                }
                this.localCanvas.renderAll();
            },
            computeCurrentRotation() {
                return this.localCanvas.backgroundImage.angle % 360;
            },
            rotateEditor() {
                // Rotating the canvas itsef
                let newCanvasWidth = this.localCanvas.getHeight();
                let newCanvasHeight = this.localCanvas.getWidth();
                const scalingFactor = (window.innerWidth * 0.75) / newCanvasWidth;
                this.scaling = scalingFactor;

                newCanvasHeight = newCanvasHeight * scalingFactor;
                newCanvasWidth = window.innerWidth * 0.75;

                this.localCanvas.setDimensions({ width: newCanvasWidth, height: newCanvasHeight });
                this.$emit('dimensionsChanged',
                    { width: this.localCanvas.getWidth(), height: this.localCanvas.getHeight() });

                // Rotating canvas background
                this.localCanvas.backgroundImage.angle += 90;
                this.rotationAngle = this.localCanvas.backgroundImage.angle;
                this.localCanvas.backgroundImage.scaleToWidth(newCanvasWidth);
                this.localCanvas.backgroundImage.scaleToHeight(newCanvasHeight);

                switch (this.localCanvas.backgroundImage.angle % 360) {
                    case 0:
                        this.localCanvas.backgroundImage.top = 0;
                        this.localCanvas.backgroundImage.left = 0;
                        break;
                    case 90:
                        this.localCanvas.backgroundImage.top = 0;
                        this.localCanvas.backgroundImage.left = newCanvasWidth;
                        break;
                    case 180:
                        this.localCanvas.backgroundImage.top = newCanvasHeight;
                        this.localCanvas.backgroundImage.left = newCanvasWidth;
                        break;
                    case 270:
                        this.localCanvas.backgroundImage.top = newCanvasHeight;
                        this.localCanvas.backgroundImage.left = 0;
                        break;
                }
                this.localCanvas.backgroundImage.setCoords();

                // Rotating canvas objects
                this.localCanvas.getObjects().forEach((obj) => {
                    obj.angle += 90 //rotate each object by the same angle
                    let objOrientation = obj.angle;
                    let localScale = Math.abs(objOrientation) % 360 === 0 || Math.abs(objOrientation) % 360 === 180 ? 1 : scalingFactor;
                    // Special case if obj is svg sticker
                    if (obj.svgUid) {
                        const normScale = obj.oldScale ? obj.oldScale : obj.scaleX;
                        obj.oldScale = normScale;
                        localScale = normScale * localScale;
                    }
                    obj.scale(localScale);
                    let oldTop;
                    let oldLeft;
                    oldTop = obj.top;
                    oldLeft = obj.left;

                    obj.top = oldLeft * scalingFactor;
                    obj.left = newCanvasWidth - oldTop * scalingFactor;

                    obj.setCoords();
                });

                this.localCanvas.renderAll()
            },
            enteredEditingText(options) {
                if (options.target.text === 'Doppelt tippen zum Schreiben') {

                    options.target.selectAll();
                    options.target.text = "";
                    if (options.target.hiddenTextarea) {
                        options.target.hiddenTextarea.value = "";
                    }
                    // options.target.backgroundColor = '#FFFF00'

                    this.localCanvas.renderAll();
                }
            },
        },
        async mounted() {
            this.requestPerson();
            this.localCanvas = new fabric.Canvas(this.$refs.editcanvas);
            this.localCanvas.setDimensions({width: this.canvasWidth, height: this.canvasHeight});
            this.localCanvas.backgroundColor = "#fff";

            this.localCanvas.isDrawingMode = false;
            this.localCanvas.on('path:created', this.addedDrawing);
            this.localCanvas.on('selection:created', this.onCanvasObjectSelected);
            this.localCanvas.on('selection:cleared', this.onCanvasObjectDeselected);
            this.localCanvas.on('before:selection:cleared ', this.onBeforeCanvasObjectDeselected);
            this.localCanvas.on('mouse:up', this.handleMouseInput);
            this.localCanvas.on('text:editing:entered', this.enteredEditingText);

            this.localCanvas.freeDrawingBrush.width = 3;

            window.addEventListener("orientationchange", () => {
                setTimeout(() => {this.rotate()}, 250);
            }, false);
        }
    }
</script>

<style lang="scss">

    #canvasContainer {
        border-radius: 5px;
        border: 1px solid grey;
    }
</style>
<style lang="scss">
    .textLayer {
        position: absolute !important;
        transform-origin: 0 0;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: .8;
        line-height: 1.0;

        span {
            display: block;
            position: absolute !important;
            cursor: text;
            color: transparent !important;
            opacity: .4;
            white-space: pre;
            transform-origin: 0% 0%;
        }

        .linkAnnotation > a {
            position: absolute;
            font-size: 1em;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        section {
            color: transparent;
            position: absolute !important;
            white-space: pre;
            cursor: text;
            transform-origin: 0% 0%;

            /* hide the note-icon, since the image is broken */
            img {
                display: none;
            }

            .popup{
                color: black;

                /* Hide the date and time within the popup, since they are incorrectly displayed */
                span{
                    display: none;
                }
            }
        }
    }

    .paragraphContainer {
        position: absolute;
        border: 2px solid lightskyblue;
        pointer-events: none;

        button {
            position: absolute;
            right: -50px;
            pointer-events: all;
        }
    }

    //#annotationLayer {
    //    position: absolute;
    //    left: 0;
    //    top: 0;
    //    right: 0;
    //    bottom: 0;
    //    overflow: hidden;
    //    opacity: 0.7;
    //
    //
    //}

    .readParagraphButton {
        span {
            opacity: 0.8 !important;
        }
    }

    .paragraphButtonActive {
        background-color: var(--v-rot-base) !important;

        img {
            filter: brightness(1000%);
        }
    }

    .readParagraphButtonImage {
        height: 30px;
        filter: brightness(0%);
        color: black;
    }

</style>
