<template>
    <div @click="$emit('focusMe')" :style="{'margin-top': account.role === 'teacher' ? '0px' : '32px'}">
        <v-row no-gutters justify="space-between" v-if="account.role === 'teacher'">
            <v-col>
                <img :src="moveIcon" ref="movingIcon" style="cursor: grab" height="25" width="25"/>
            </v-col>
            <v-col cols="auto">
                <img :src="papierkorbIcon" @click="$emit('deleteObject')" style="cursor: pointer"
                     ref="deleteIcon" height="25" width="25" class=""/>
            </v-col>
        </v-row>
        <div
            ref="videoObjectOuter"
            id="videoObjectOuter"
            @mouseup="resizeEnd"
            :style="`
        resize: ${account.role === 'teacher' ? 'both !important' : 'none'} ;
        overflow: hidden;
        padding: 5px;
        border: ${account.role === 'teacher' ? '1px solid grey' : 'none'};
        width: ${video.width ? video.width + 'px' : '200px'};
        height: ${video.height ? video.height + 'px' : 'auto'}
        `"
        >
            <video
                :class="{ 'moveableItemShadow': account.role === 'teacher' }"
                aria-label="Videoplayer"
                role="application"
                :tabindex="tabIndex"
                v-if="videoUrl"
                :poster="videoPoster"
                controls class="pa-0"
                style="max-width: 100%; max-height: 100%" width="auto" height="auto">
                <source :src="videoUrl" />
            </video>
        </div>
    </div>
</template>

<script>

import moveIcon from '../../assets/Icons/Temp/move.svg';
import papierkorbIcon from "../../assets/Icons/papierkorb-109.svg";
import videoPoster from "../../assets/Bilder/videoposter.png";
import * as backend from "@/api/backend";
import {mapState} from "vuex";

export default {
    name: "EditorVideoObject",
    computed: {
        ...mapState("auth", ["account"]),
    },
    props: {
        video: {type: Object, required: true},
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
        tabIndex: { type: Number, required: false, default: 19 },
    },
    data: () => ({
        videoUrl: null,
        moveIcon,
        papierkorbIcon,
        videoPoster
    }),
    mounted() {
        console.log("Video is: ", this.video);
        if(this.account.role === 'teacher')
            this.dragElement(this.$refs.movingIcon, this.$refs.videoObjectOuter);
        if(!this.video.fileUrl || !this.video.fileBlob)
            this.loadVideo();
        else
            this.videoUrl = this.video.fileUrl;
    },
    methods: {
        resizeEnd(event) {
            console.log("Resize End: ", event);
            if (event.dataTransfer) {
                this.dragEnd(event);
            }

            const width = this.$refs.videoObjectOuter.style.width.substring(
                0,
                this.$refs.videoObjectOuter.style.width.length - 2
            );
            const height = this.$refs.videoObjectOuter.style.height.substring(
                0,
                this.$refs.videoObjectOuter.style.height.length - 2
            );
            console.log("New", width, height);
            this.video.width = width;
            this.video.height = height;
            //this.$emit("dimensionsChanged", { width, height });
        },
        async loadVideo() {
            // fileUrl: url, fileBlob: blob,
            const res = await backend.getUploadMessage(this.video.start);
            const blob = await res.blob();
            this.video.fileBlob = blob;
            this.video.fileUrl = window.URL.createObjectURL(blob);
            this.videoUrl = this.video.fileUrl;
        },
        dragElement(element, elementToMove) {
            let _this = this;
            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            let lastMove = null;

            element.onmousedown = dragMouseDown;
            element.ontouchstart = dragMouseDown;

            function dragMouseDown(e) {
                _this.$emit('focusMe');
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                lastMove = e;
                document.onmouseup = closeDragElement;
                document.ontouchend = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
                document.ontouchmove = elementDrag;
            }

            const elementDrag = (e) => {
                e = e || window.event;
                lastMove = e;
                if (e.type !== 'touchmove') {
                    e.preventDefault();
                }

                // calculate the new cursor position:
                pos1 = pos3 - (e.clientX ? e.clientX : e.touches[0].clientX);
                pos2 = pos4 - (e.clientY ? e.clientY : e.touches[0].clientY);
                pos3 = (e.clientX ? e.clientX : e.touches[0].clientX);
                pos4 = (e.clientY ? e.clientY : e.touches[0].clientY);
                // set the element's new position:
                const top = (Number.parseFloat(this.video.y)) - ((pos2 / this.canvasHeight) * 100);
                const left = (Number.parseFloat(this.video.x)) - ((pos1 / this.canvasWidth) * 100);

                if ((left > -10 && left < 110) && (top > -10 && top < 110)) {
                    this.video.x = left;
                    this.video.y = top;
                }
            }

            const closeDragElement = (e) => {
                // stop moving when mouse button is released:
                document.onmouseup = null;
                document.onmousemove = null;
                document.ontouchend = null;
                document.ontouchmove = null;
                try {
                    this.click(e.clientX ? e.clientX : lastMove.touches[0].clientX, e.clientY ? e.clientY : lastMove.touches[0].clientY);
                } catch (e) {
                    // console.log(e);
                }
                lastMove = null;
            }
        },
    }
}
</script>

<style scoped>

</style>
