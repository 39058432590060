const parseHtml = (text, width = 350, height = 200) => {
  if(text){
    let html = "<div style='max-width: 100%; overflow: auto; position: relative; display: flex; justify-content: center;'>";
    let data = "";

    if(text.includes("<iframe")) {
      data = text.substring(text.indexOf("<iframe"), text.indexOf("</iframe>") + 9);
      // Set max width to 100%, otherwise the iFrame overflows on small devices
      data = data.replace("<iframe", "<iframe width='" + width + "px' height='" + height +"px' style='max-width: 100%;'");
    } else if(text.includes("<img")) {
      data = text.substring(text.indexOf("<img"), text.indexOf(">", text.indexOf("<img")) + 1)
      // Set max width of the image to 100%, otherwise it overflows
      data = data.replace("<img", "<img style='max-width: 100%;'");
    }
    html += data + "</div>";
    var regex = /<iframe/gi, result, count = 0;
    while ((result = regex.exec(text))) {
      count++;
    }
    regex = /<img/gi;
    while ((result = regex.exec(text))) {
      count++;
    }
    if(--count >= 1){
      html += "<div style='display: flex; justify-content: center; align-content: center;'><div class='caption' style='max-width: 100%; padding: 2px 6px 2px 6px; margin-top: 7px; overflow: auto; background-color: gray; border: none; border-radius: 20px; color: white;'>+ " + count + " weitere</div></div>";
    }
    return html;
  }
  return "";
}

export { parseHtml };
