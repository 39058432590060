<template>
    <div style="border-radius: 5px !important; background-color: #F8F8F8">
        <v-row :style="{ backgroundColor: groupColor, height: '80px' }" class="ma-auto mb-1 pa-2" align="center">

            <v-col :cols="windowWidth < 770 ? (windowWidth < 380 ? 10 : 9) : 7">

            <v-menu style="border-radius: 5px !important;" :close-on-content-click="false"
                    offset-y nudge-left="54" nudge-bottom="5">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            tabindex="1"
                            :class="{ activeTool: currentActiveMethod === 'pen' }"
                            v-bind="attrs"
                            v-on="on"
                            x-small class="mr-3 toolSelector"
                            @click="setTool('pen')"
                    >
                        <v-icon v-if="currentActiveMethod === 'marker'" style="font-size: 22px !important;">
                            fas fa-highlighter
                        </v-icon>
                        <img v-else alt="Stift" :src="stiftIcon" style="max-width: 25px; max-height: 25px"/>
                    </v-btn>
                </template>

                <div style="width: 200px; height: 25px; display: inline; border-radius: 0 !important; background-color: #EAEAEA; ">
                    <v-btn text x-small
                           tabindex="2"
                           @click="changeSize(3)"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           aria-label="Stiftgröße klein"
                    >
                        <v-icon style="font-size: 6px !important;">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn text x-small
                           tabindex="3"
                           @click="changeSize(13)"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           class="pa-0"
                           aria-label="Stiftgröße mittel">
                        <v-icon style="font-size: 13px !important;">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn text x-small
                           tabindex="4"
                           @click="changeSize(22)"
                           @focusout="stiftSizeMenu = false"
                           style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                           class="pa-0"
                           aria-label="Stiftgröße groß">
                        <v-icon style="font-size: 22px !important;">fas fa-circle</v-icon>
                    </v-btn>
                    <v-btn
                            tabindex="5"
                            :class="{ markerActive: currentActiveMethod === 'marker' }"
                            x-small text
                            style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                            class="pa-0"
                            aria-label="Stifttyp Textmarker"
                            @click="setTool('marker')"
                    >
                        <v-icon style="font-size: 22px !important;">fas fa-highlighter</v-icon>
                    </v-btn>
                </div>
            </v-menu>

                <v-btn x-small class="mr-4 toolSelector" :class="{ activeTool: currentActiveMethod === 'text' }"
                       @click="() => { setTool('text'); /*spawnTextOnCanvas();*/ }">
                    <img :src="textIcon" style="max-width: 25px; max-height: 25px"/>
                </v-btn>

                <!-- Eraser Button -->
                <v-btn x-small :class="{activeTool: currentActiveMethod === 'eraser', 'mr-1': windowWidth < 600, 'mr-3': windowWidth > 600 }"
                       class="toolSelector" @click="setTool('eraser')">
                    <img :src="radiererIcon" style="max-width: 25px; max-height: 25px"/>
                </v-btn>
                <!--            -->

                <v-btn v-if="false" style="width: 49px; height: 49px;" x-small text class="mr-3" @click="() => { displayOtherButtons = !displayOtherButtons }">
                    <p style="font-size: 30px; color: white">...</p>
                </v-btn>


<!--            Color palette    -->
                <v-menu v-if="displayOtherButtons" style="border-radius: 5px !important;" offset-y nudge-left="162" nudge-bottom="5">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                v-bind="attrs"
                                v-on="on"
                                x-small class="mr-3 toolSelector"
                        >
                            <v-icon style="font-size: 22px !important;" :color="color">fas fa-circle</v-icon>
                        </v-btn>
                    </template>

                    <div style="width: 200px; height: 25px; display: inline; border-radius: 0 !important; background-color: #EAEAEA; ">
                        <v-btn text x-small
                               style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px">
                            <v-icon @click="changeColor('#fac837')" style="font-size: 22px !important; color: #fac837">fas fa-circle</v-icon>
                        </v-btn>
                        <v-btn text x-small
                               style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                               class="pa-0">
                            <v-icon @click="changeColor('#f07d32')" style="font-size: 22px !important; color: #f07d32">fas fa-circle</v-icon>
                        </v-btn>
                        <v-btn text x-small
                               style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                               class="pa-0">
                            <v-icon @click="changeColor('#e6231e')" style="font-size: 22px !important; color: #e6231e">fas fa-circle</v-icon>
                        </v-btn>
                        <v-btn text x-small
                               style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                               class="pa-0">
                            <v-icon @click="changeColor('#6996cd')" style="font-size: 22px !important; color: #6996cd">fas fa-circle</v-icon>
                        </v-btn>
                        <v-btn text x-small
                               style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                               class="pa-0">
                            <v-icon @click="changeColor('#505faa')" style="font-size: 22px !important; color: #505faa">fas fa-circle</v-icon>
                        </v-btn>
                        <v-btn text x-small
                               style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                               class="pa-0">
                            <v-icon @click="changeColor('#8cbe46')" style="font-size: 22px !important; color: #8cbe46">fas fa-circle</v-icon>
                        </v-btn>
                        <v-btn text x-small
                               style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                               class="pa-0">
                            <v-icon @click="changeColor('#706f6f')" style="font-size: 22px !important; color: #706f6f">fas fa-circle</v-icon>
                        </v-btn>
                    </div>
                </v-menu>

                <v-btn v-if="false" style="background-color: #f8f8f8; width: 49px; height: 49px" x-small class="mr-3" @click="undo">
                    <img :src="undoIcon" style="max-width: 25px; max-height: 25px"/>
                </v-btn>

                <v-btn v-if="displayOtherButtons" x-small class="mr-3 toolSelector" @click="rotateEditor">
                    <img :src="drehIcon" style="max-width: 25px; max-height: 25px"/>
                </v-btn>

<!--            Stempel List    -->
                <v-menu v-if="displayOtherButtons" style="border-radius: 5px !important;" offset-y nudge-left="270" nudge-bottom="5">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                v-bind="attrs"
                                v-on="on"
                                x-small
                                :class="{ activeTool: currentActiveMethod === 'stempel' }"
                                class="toolSelector mr-3"
                                @click="setTool('stempel')"
                        >
                            <img :src="currentStempel" style="max-width: 25px; max-height: 25px; filter: none !important;" />
                        </v-btn>
                    </template>

                    <div style="width: 200px; height: 25px; display: inline; border-radius: 0 !important; background-color: #EAEAEA; ">
                        <v-btn text x-small v-for="(item, index) in stempelList" class="stempelButton" :key="index" @click="setStempel(item)">
                            <img :src="item" style="max-width: 25px; max-height: 25px" />
                        </v-btn>
                    </div>
                </v-menu>

                <v-menu offset-y v-if="windowWidth < 600">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn style="width: 49px; height: 49px;"
                               id="smallDisplayMoreButtonsTrigger"
                               x-small text class="mr-3" v-bind="attrs"
                               v-on="on" >
                            <p style="font-size: 30px; color: white">...</p>
                        </v-btn>
                    </template>
                    <div id="smallDisplayMoreButtons">
                        <v-btn x-small class="mr-3 toolSelector" @click="rotateEditor">
                            <img :src="drehIcon" style="max-width: 25px; max-height: 25px"/>
                        </v-btn>

                        <!--            Color palette    -->
                        <v-menu style="border-radius: 5px !important;" offset-y nudge-left="162" nudge-bottom="5">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        style="background-color: #f8f8f8; width: 49px; height: 49px"
                                        x-small class="mr-3"
                                >
                                    <v-icon style="font-size: 22px !important;" :color="color">fas fa-circle</v-icon>
                                </v-btn>
                            </template>

                            <div class="colorPickerMenu">
                                <v-btn text x-small
                                       style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px">
                                    <v-icon @click="changeColor('#fac837')" style="font-size: 22px !important; color: #fac837">fas fa-circle</v-icon>
                                </v-btn>
                                <v-btn text x-small
                                       style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                       class="pa-0">
                                    <v-icon @click="changeColor('#f07d32')" style="font-size: 22px !important; color: #f07d32">fas fa-circle</v-icon>
                                </v-btn>
                                <v-btn text x-small
                                       style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                       class="pa-0">
                                    <v-icon @click="changeColor('#e6231e')" style="font-size: 22px !important; color: #e6231e">fas fa-circle</v-icon>
                                </v-btn>
                                <v-btn text x-small
                                       style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                       class="pa-0">
                                    <v-icon @click="changeColor('#6996cd')" style="font-size: 22px !important; color: #6996cd">fas fa-circle</v-icon>
                                </v-btn>
                                <v-btn text x-small
                                       style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                       class="pa-0">
                                    <v-icon @click="changeColor('#505faa')" style="font-size: 22px !important; color: #505faa">fas fa-circle</v-icon>
                                </v-btn>
                                <v-btn text x-small
                                       style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                       class="pa-0">
                                    <v-icon @click="changeColor('#8cbe46')" style="font-size: 22px !important; color: #8cbe46">fas fa-circle</v-icon>
                                </v-btn>
                                <v-btn text x-small
                                       style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                       class="pa-0">
                                    <v-icon @click="changeColor('#706f6f')" style="font-size: 22px !important; color: #706f6f">fas fa-circle</v-icon>
                                </v-btn>
                            </div>
                        </v-menu>

                        <!-- Stempel list -->
                        <v-menu style="border-radius: 5px !important;" offset-y nudge-left="270" nudge-bottom="5">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        :class="{ activeTool: currentActiveMethod === 'stempel' }"
                                        class="mr-3"
                                        style="background-color: #f8f8f8; width: 49px; height: 49px"
                                        @click="setTool('stempel')"
                                >
                                    <img :src="currentStempel" style="max-width: 25px; max-height: 25px" />
                                </v-btn>
                            </template>

                            <div style="width: 200px; height: 25px; display: inline; border-radius: 0 !important; background-color: #EAEAEA; ">
                                <v-btn text x-small v-for="(item, index) in stempelList" class="stempelButton" :key="index" @click="setStempel(item)">
                                    <img :src="item" style="max-width: 25px; max-height: 25px" />
                                </v-btn>
                            </div>
                        </v-menu>

                    </div>
                </v-menu>
            </v-col>

            <v-col :cols="windowWidth < 770 ? (windowWidth < 380 ? 2 : 3) : 5" style="display: flex; justify-content: flex-end;">
                <editor-tools
                        @addTextMessage="spawnTextObject"
                        @addAudioMessage="spawnAudioObject"
                        @addImageField="placeNewImage"
                        @addAdditionalPage="addNewPage"
                        @addMoveableText="addMoveableText"
                        @addRectangle="addRectangle"
                />

                <v-btn class="save-image-btn ml-4 mr-3" elevation="3" dark small @click="saveDialog = true">
                    <img :src="fertigIcon" class="mr-2"/>
                    <p class="mb-0 pa-1">Fertig</p>
                </v-btn>


                <v-btn @click="saveProgressDialog = true" x-small class="mr-4" elevation="3"
                       id="closeBtn">
                    <img :src="closeIcon" style="height: 40px; filter: brightness(1000%)"/>
                </v-btn>
            </v-col>

        </v-row>
        <vuescroll :ops="ops" style="height: calc(100vh - 85px); background-color: #EAEAEA" ref="scrollbar">

            <!-- Editing row -->
            <v-row class="ma-0" align="center" justify="center">
                <div id="pageView" v-if="false">
                    <p class="mb-0 mt-4 mx-auto">Seite: 1 / 3</p>
                    <div style="position:relative; display: block">
                        <v-btn x-small text v-for="(page, index) in pages" :key="index"
                               @click="jumpToPage(index)" height="60px" class="mx-auto" width="100px"
                        >
                            <img :src="seitenIcon" height="50px"/>
                        </v-btn>
                    </div>
                </div>

<!--                Stored for safekeeping-->
<!--                :class="{'multiplePagesZoomControl': pages.length && windowWidth > 770}"-->
                <v-btn elevation="3" x-small class="mr-3 zoomControl" @click="changeZoom('minus')">
                    <p>-</p>
                </v-btn>

                <v-btn elevation="3" x-small class="mr-3 zoomControl"  style="margin-left: 63px" @click="changeZoom('plus')">
                    <p>+</p>
                </v-btn>

                <span tabindex="17" class="visually-hidden"> Um den vollen Funktionsumfang des Editor nutzen zu können,
                        bitte bedienen Sie diesen über die Tabulator-Taste. </span>

                <div style="position: relative">
                    <EditingCanvas
                            v-for="(page, index) in pages" :key="index"
                            :canvas-height="canvasHeight"
                            :canvas-width="canvasWidth"
                            :loading-function="setLoading"
                            :tab-index="21 + tabOrderArray.length"
                            :is-text-layer-visible="false"
                            @placeNewEditorMessage="(top, left) => { placeNewEditorMessage(index, top, left); }"
                            @placeNewEditorTextMessage="(top, left) => { placeNewEditorTextMessage(index, top, left) }"
                            @dimensionsChanged="(data) => {
                                canvasWidth = data.width;
                                canvasHeight = data.height;
                            }"
                            :ref="`editingcanvas${index}`"
                            style="position: relative">
                    </EditingCanvas>
                    <EditorRectangle
                        v-for="(rect, index) in rectangles"
                        :key="'rectangle' + index"
                        :rect="rect"
                        :canvas-width="canvasWidth"
                        :canvas-height="canvasHeight"
                        @deleteObject="() => { rectangles.splice(index, 1) }"
                        :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                            top: ${audioMessageYAxis(rect)}%; left: ${ rect.x * (1 + currentZoom / 5) }%; transform: scale(${1 + currentZoom / 5}, ${ 1 + currentZoom / 5 }`"
                    />
                    <EditorLine
                        v-for="(line, index) in lines"
                        :key="'line' + index"
                        :line="line"
                        :canvas-width="canvasWidth"
                        :canvas-height="canvasHeight"
                        @deleteObject="() => { lines.splice(index, 1) }"
                        :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                            top: ${audioMessageYAxis(line)}%; left: ${ line.x * (1 + currentZoom / 5) }%; transform: scale(${1 + currentZoom / 5}, ${ 1 + currentZoom / 5 }`"
                    />
                    <!-- Images should probably be the lowest element, so people can place stuff on top of them -->
                    <EditorImageObject
                        v-for="(img, i) in imageList"
                        :key="'imgFile'+i"
                        :img="img"
                        :tab-index="19 + img.tabIndex"
                        :canvas-width="canvasWidth"
                        :canvas-height="canvasHeight"
                        @deleteObject="() => imageList.splice(i, 1)"
                        :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                            top: ${audioMessageYAxis(img)}%; left: ${img.x * (1 + (currentZoom / 5))}%;
                            transform: scale(${1 + (currentZoom / 5)}, ${1 + (currentZoom / 5)}
                            `"
                    />
                    <EditorVideoObject
                        v-for="(video, i) in videoList"
                        :key="'videoFile'+i"
                        :video="video"
                        :tab-index="19 + video.tabIndex"
                        :canvas-width="canvasWidth"
                        :canvas-height="canvasHeight"
                        @deleteObject="() => videoList.splice(i, 1)"
                        :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                            top: ${audioMessageYAxis(video)}%; left: ${video.x * (1 + (currentZoom / 5))}%;
                            transform: scale(${1 + (currentZoom / 5)}, ${1 + (currentZoom / 5)}
                            `"
                    />
                    <EditorAudioMessage
                            v-for="(message, index) in messageList"
                            :key="'audio'+index"
                            :tab-index="19 + message.tabIndex"
                            :state="'correction'"
                            :style="`position: absolute; top: ${audioMessageYAxis(message)}%; left: ${message.x}%;`"
                            :uploadMessage="message" :index="index"/>
                    <EditorTextMessage
                            v-for="(message, index) in textMessageList"
                            :tab-index="19 + message.tabIndex"
                            :key="'text'+index"
                            :message="message" :index="index"
                            @deleteNote="() => deleteTextNote(index)"
                            :style="`position: absolute; top: ${audioMessageYAxis(message)}%; left: ${message.x}%;`" />
                    <EditorTextTask
                            v-for="(task, index) in textTaskList"
                            :key="'texttask'+index"
                            :task="task"
                            :tab-index="19 + task.tabIndex"
                            :canvas-height="canvasHeight"
                            :canvas-width="canvasWidth"
                            :style="`position: absolute; transform-origin: top left;
                                top: ${audioMessageYAxis(task)}%; left: ${task.x * (1 + (currentZoom / 5))}%;
                                width: ${canvasWidth * task.width}px; height: ${canvasHeight * task.height}px;
                                transform: scale(${1 + (currentZoom / 5)}, ${1 + (currentZoom / 5)}
                                `"
                            :state="task.done ? 'corrected' : 'answer'"
                            :teachers="teacherIdArray"
                            @coordsChanged="coords => updateTextTaskPosition(task, coords)"
                            @dimensionsChanged="dimensions => updateTextTaskDimension(task, dimensions)"
                    />
                    <EditorAnswerField
                        v-for="(answerEntry, index) in textAnswerList"
                        :key="'answerEntry'+index"
                        :answer-entry="answerEntry"
                        :canvas-height="canvasHeight"
                        :canvas-width="canvasWidth"
                        :tab-index="19 + answerEntry.tabIndex"
                        @deleteAnswerField="() => deleteAnswerField(index)"
                        :style="`position: absolute; transform-origin: top left;
                            top: ${audioMessageYAxis(answerEntry)}%; left: ${answerEntry.x * (1 + (currentZoom / 5))}%;
                            transform: scale(${1 + (currentZoom / 5)}, ${1 + (currentZoom / 5)}
                            `"
                    />
                    <EditorMultipleChoice
                        v-for="(mChoices, index) in multipleChoices"
                        :key="'multipleChoice' + index"
                        :m-data="mChoices"
                        :props-disabled="true"
                        :tab-index="19 + mChoices.tabIndex"
                        :show-results="true"
                        :canvas-width="canvasWidth"
                        :canvas-height="canvasHeight"
                        @deleteObject="multipleChoices.splice(index, 1);"
                        :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                            top: ${audioMessageYAxis(mChoices)}%; left: ${ mChoices.x * (1 + currentZoom / 5) }%; transform: scale(${1 + currentZoom / 5}, ${ 1 + currentZoom / 5 }`"
                    />
                    <EditorMoveableText
                        v-for="(text, index) in moveableTexts"
                        :key="'moveableText' + index"
                        :text="text"
                        :canvas-width="canvasWidth"
                        :tab-index="19 + text.tabIndex"
                        :canvas-height="canvasHeight"
                        :class="{ focusMe: focusedElement === ('moveableText' + index) }"
                        @focusMe="() => { focusedElement = ('moveableText' + index) }"
                        @deleteObject="() => { moveableTexts.splice(index, 1) }"
                        :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                            top: ${audioMessageYAxis(text)}%; left: ${ text.x * (1 + currentZoom / 5) }%; transform: scale(${1 + currentZoom / 5}, ${ 1 + currentZoom / 5 }`"
                    />
                </div>
            </v-row>
        </vuescroll>

        <!-- Popup land -->
        <v-dialog v-model="loadingActive" hide-overlay persistent width="150">
            <v-card :color="groupColor">
                <v-card-text class="pa-2">
                    <v-progress-circular indeterminate class="ma-auto" style="display: block"/>
                    <br/>
                    <p class="ma-auto" style="text-align: center">{{ loadingMessage }}</p>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="saveDialog" max-width="350px">
            <v-card>
                <v-card-title>
                    Speichern bestätigen
                </v-card-title>

                <v-card-text>
                    Wollen Sie die Bearbeitung wirklich endgültig abschließen?
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn @click="saveDialog = false" class="mr-2 text-capitalize" color="rot" dark>
                        <img :src="closeIcon" style="height: 20px; filter: brightness(1000%)" class="mr-2">
                        Abbrechen
                    </v-btn>

                    <v-btn @click="saveImage(); saveDialog = false;" class="text-capitalize" color="gruen" dark>
                        <img :src="fertigIcon" style="height: 20px; filter: brightness(1000%)" class="mr-2">
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="saveProgressDialog" max-width="350px">
            <v-card>
                <v-card-title>
                    Abbruch bestätigen
                </v-card-title>

                <v-card-text>
                    Möchten Sie Ihren Fortschritt zwischenspeichern oder verwerfen?
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn @click="close(); saveProgressDialog = false" class="mr-2 text-capitalize" color="rot" dark>
                        <img :src="closeIcon" style="height: 20px; filter: brightness(1000%)" class="mr-2">
                        Verwerfen
                    </v-btn>

                    <v-btn @click="zwischenspeichern(); saveProgressDialog = false;" class="text-capitalize" color="gruen" dark>
                        <img :src="fertigIcon" style="height: 20px; filter: brightness(1000%)" class="mr-2">
                        <p v-if="windowWidth > 400" class="mb-0">Zwischenspeichern</p>
                        <p v-else class="mb-0">Speichern</p>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
                v-model="snackbars.fileNotAllowed"
                timeout="5000"
                color="error"
        >
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbars.fileNotAllowed = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
            </v-btn>
          </template>
            Sie haben keinen Zugriff auf diese Datei. Bitte kontaktieren Sie den Autor der Datei.
        </v-snackbar>
        <v-snackbar
                v-model="snackbars.fileNotAvailable"
                timeout="5000"
                color="error"
        >
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbars.fileNotAvailable = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
            </v-btn>
          </template>
            Die Datei konnte nicht gefunden werden. Bitte laden Sie die Datei erneut hoch, oder kontaktieren Sie den Support.
        </v-snackbar>
        <v-snackbar
                v-model="snackbars.dataCouldntBeSaved"
                timeout="5000"
                color="error"
        >
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbars.dataCouldntBeSaved = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
            </v-btn>
          </template>
            Ergebnisse konnten nicht gespeichert werden. Bitte versuche es später nochmal.
        </v-snackbar>
        <v-snackbar
                v-model="snackbars.genericError"
                timeout="5000"
                color="error"
        >
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbars.genericError = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="closeIcon" />
            </v-btn>
          </template>
            Etwas ist schief gelaufen - Bitte aktualisieren Sie die Seite!
        </v-snackbar>
    </div>
</template>

<script>
    import EditingCanvas from "../EditingCanvas";
    import vuescroll from 'vuescroll';
    import {mapActions, mapGetters} from 'vuex';
    import stiftIcon from '../../assets/Icons/bearbeiten-komplimentär-weiß-88.svg'
    import undoIcon from '../../assets/Editing/rückgänig.svg'
    import closeIcon from '../../assets/Icons/abbrechen-08.svg'
    import textIcon from '../../assets/Icons/Textfunktion-67.svg'
    import drehIcon from '../../assets/Icons/90-grad-drehung.svg'
    import speichernIcon from '../../assets/Icons/speichern-64.svg'
    import radiererIcon from '../../assets/Icons/radierer-73.svg'
    import fertigIcon from '../../assets/Icons/fertig-74.svg'
    import seitenIcon from '../../assets/Icons/kein-dokument-68.svg'
    import stempels from '../../util/editorStempelList'
    import scrollbarStyling from "../../util/scrollbarStyling";
    import EditorAudioMessage from "../Editortools/EditorAudioMessage";
    import EditorTools from "../Editortools/Toolbar/EditorTools";
    import EditorTextMessage from "../Editortools/EditorTextMessage";
    import EditorTextTask from "../Editortools/EditorTextTask";
    import * as backend from "../../api/backend";
    import {PDFDocument} from "pdf-lib";
    import EditorAnswerField from "../Editortools/EditorAnswerField";
    import pdfjs from '../../util/pdfjs'
    import EditorImageObject from "@/components/Editortools/EditorImageObject";
    import EditorVideoObject from "@/components/Editortools/EditorVideoObject";
    import EditorMultipleChoice from "@/components/Editortools/EditorMultipleChoice";
    import EditorMoveableText from "@/components/Editortools/EditorMoveableText";
    import EditorRectangle from "@/components/Editortools/EditorRectangle";
    import EditorLine from "@/components/Editortools/EditorLine";

    export default {
        name: 'EditLehrer',
        components: {
            EditorLine,
            EditorRectangle,
            EditorMoveableText,
            EditorMultipleChoice,
            EditorVideoObject,
            EditorImageObject,
            EditorAnswerField,
            EditingCanvas,
            vuescroll,
            EditorAudioMessage,
            EditorTools,
            EditorTextMessage,
            EditorTextTask
        },
        data() {
            return {
                windowWidth: window.innerWidth,
                currentActiveMethod: 'none',
                params: {},
                color: "black",
                imageUrl: null,
                croppedImage: false,
                canvasWidth: 300,
                canvasHeight: 600,
                componentKey: 0,
                currentFile: null,
                groupName: 'Deutsch',
                groupColor: 'blue',
                assignmentName: 'Aufgabe 1',
                appointmentId: '',
                fileId: '',
                uploadId: '',
                pages: [0],
                loadingActive: false,
                focusedElement: '',
                loadingMessage: 'Dokument wird geladen...',
                snackbars: {
                    dataSaved: false,
                    dataSubmitted: false,
                    fileNotAllowed: false,
                    fileNotAvailable: false,
                    dataCouldntBeSaved: false,
                    genericError: false,
                },
                ops: {
                    rail: {
                        opacity: '0',
                        background: undefined,
                        border: 'none',
                        size: '60px'
                    },
                    bar: {
                        background: '#55555580',
                        keepShow: true,
                        size: '45px',
                        minSize: 0.1
                    },
                    scrollButton: {
                        enable: true,
                        background: '#cecece'
                    },
                    scrollPanel: {
                        easing: 'easeInQuad',
                        speed: 800
                    },
                    vuescroll: {
                        wheelScrollDuration: 0,
                        wheelDirectionReverse: false,
                        sizeStrategy: 'percent',
                        detectResize: true
                    }
                },
                scrollbarStyling,
                currentZoom: 0,
                displayOtherButtons: false,
                isHorizontalScrollbarStyled: false,
                isVerticalScrollbarStyled: false,
                stempelList: stempels,
                currentStempel: stempels[5],
                saveDialog: false,
                saveProgressDialog: false,

                // Editor Messages
                messageList: null,
                textMessageList: [],
                textTaskList: [],
                textAnswerList: [],
                imageList: [],
                videoList: [],
                multipleChoices: [],
                moveableTexts: [],
                rectangles: [],
                lines: [],
                tabOrderArray: [],

                //Icons
                stiftIcon,
                undoIcon,
                closeIcon,
                textIcon,
                speichernIcon,
                radiererIcon,
                fertigIcon,
                drehIcon,
            seitenIcon,
            }
        },
        props: {},
        computed: {
            ...mapGetters('files', ['getCurrentFileUrl', 'getCurrentFile', 'getCurrentFileType', 'getWork']),
            ...mapGetters('appointments', [ 'appointment' ]),
            teacherIdArray() {
                let currentAppointment = this.appointment(this.appointmentId);
                if (!currentAppointment) {
                    currentAppointment = this.getAppointments().find(item => item._id === this.appointmentId);
                }
                const resultArray = [];

                for (let i = 0; i < currentAppointment.teachers.length; i++) {
                    resultArray.push(currentAppointment.teachers[i].account);
                }
                return resultArray;
            },
        },
        methods: {
            ...mapActions('files', ['pullCurrentFile', 'safeCurrentFile', 'setCurrentFile', 'safeWork', 'pullSavedWork', 'setSnackbar', 'safeNewPdfFile']),
            ...mapActions('uploadMessage', [ 'getUploadMessages', 'createUploadMessages' ]),
            ...mapActions('appointments', [ 'getAppointments' ]),
            changeColor(colorHex, dontSave) {
                this.color = colorHex;
                if(!dontSave) {
                    localStorage.setItem('editColor', colorHex);
                }

                for (let i = 0; i < this.pages.length; i++) {
                    this.$refs[`editingcanvas${i}`][0].changeColor(colorHex);
                }
            },
            async zwischenspeichern() {
                try {
                    this.setLoading(true, 'Warten auf Speichern...');
                    const canvasHistory = [];
                    for (let i = 0; i < this.pages.length; i++) {
                        canvasHistory.push(this.$refs[`editingcanvas${i}`][0].safeCurrentWork());
                    }
                    const response = await this.safeWork({
                        appointmentId: this.appointmentId,
                        uploadId: this.uploadId,
                        changeHistory: canvasHistory,
                    });

                    if (response.status === 200 ) {
                        if (canvasHistory.length > 0) {
                            this.setSnackbar({type: 'dataSaved', value: true });
                        }

                        await this.close();
                    } else {
                        this.snackbars.dataCouldntBeSaved = true;
                    }

                    this.setLoading(false);
                } catch (e) {
                    this.snackbars.dataCouldntBeSaved = true;
                    this.setLoading(false);
                }
            },
            loadWork(savedWork) {
                if (savedWork.length) {
                    for (let i = 0; i < this.pages.length; i++) {
                        this.$refs[`editingcanvas${i}`][0].loadWork(savedWork[i]);
                    }
                } else {
                    this.$refs[`editingcanvas0`][0].loadWork(savedWork);
                }
            },
            async saveImage() {
                this.setLoading(true, 'Warten auf Abgabe...');
                if (this.fileId) {
                    const images = [];
                    for (let i = 0; i < this.pages.length; i++) {
                        images.push(this.$refs[`editingcanvas${i}`][0].canvasAsImage);
                    }
                    await this.saveImageAsPDF(images);
                } else if (this.emptyPageType) {
                    const images = [];
                    for (let i = 0; i < this.pages.length; i++) {
                        images.push(this.$refs[`editingcanvas${i}`][0].canvasAsImage);
                    }
                    await this.saveNewPage(images);
                }
            },
            async createPdfFromImages(images){
                const pdfDoc = await PDFDocument.create();
                for (let i = 0; i < images.length; i++) {
                    const embeddableImage = await fetch(images[i]).then(res => res.arrayBuffer());
                    const page = pdfDoc.addPage([this.canvasWidth, this.canvasHeight]);
                    const pngImage = await pdfDoc.embedPng(embeddableImage);
                    page.drawImage(pngImage, {
                        x: 0,
                        y: 0,
                        width: this.canvasWidth,
                        height: this.canvasHeight,
                    });
                }
                return await pdfDoc.save();
            },
            async saveNewPage(base64) {
                try {
                    const pdfData = await this.createPdfFromImages(base64);
                    const response = await this.safeNewPdfFile({
                        data: pdfData,
                        appointmentId: this.appointmentId,
                        fileName: this.assignmentName,
                        userGroup: 'pupil'
                    });

                    response.addEventListener('load', (e) => {
                        if (response.status === 200
                            || response.status === 201
                            || response.status === 202
                            || response.status === 204) {
                            this.setSnackbar({type: 'dataSubmitted', value: true});
                            this.close();
                        } else {
                            this.snackbars.dataCouldntBeSaved = true;
                            this.setLoading(false);
                        }
                    });
                } catch (e) {
                    this.snackbars.dataCouldntBeSaved = true;
                    this.setLoading(false);
                }
            },
            // async saveImageAsFile(base64) {
            //     try {
            //         const response = await this.safeCurrentFile({
            //             userGroup: 'teacher',
            //             data: base64,
            //             fileType: 'png',
            //             appointmentId: this.appointmentId,
            //             fileId: this.fileId,
            //             fileName: this.assignmentName
            //         });
            //
            //         if (response.status === 200
            //             || response.status === 201
            //             || response.status === 202
            //             || response.status === 204) {
            //             this.setSnackbar({type: 'dataSubmitted', value: true});
            //             const responseJson = await response.json();
            //             await this.safeAllEditorMessages(responseJson._id);
            //         } else {
            //             this.snackbars.dataCouldntBeSaved = true;
            //             this.setLoading(false);
            //         }
            //     } catch (e) {
            //         this.snackbars.dataCouldntBeSaved = true;
            //         this.setLoading(false);
            //     }
            // },
            async saveImageAsPDF(base64strings) {
                try {

                    const pdfBytes = await this.createPdfFromImages(base64strings);

                    const response = await this.safeCurrentFile({
                        userGroup: 'teacher',
                        data: pdfBytes,
                        fileType: 'pdf',
                        appointmentId: this.appointmentId,
                        fileId: this.fileId,
                        fileName: this.assignmentName,
                    });

                    if (response.status === 200
                        || response.status === 201
                        || response.status === 202
                        || response.status === 204) {
                        this.setSnackbar({type: 'dataSubmitted', value: true});
                        const responseJson = await response.json();
                        await this.safeAllEditorMessages(responseJson._id);
                    } else {
                        this.snackbars.dataCouldntBeSaved = true;
                        this.setLoading(false);
                    }
                } catch (e) {
                    this.snackbars.dataCouldntBeSaved = true;
                    this.setLoading(false);
                }

            },
            changeSize(pixelCount) {
                localStorage.setItem('editSize', pixelCount);
                for (let i = 0; i < this.pages.length; i++) {
                    this.$refs[`editingcanvas${i}`][0].changeSize(pixelCount);
                }
            },
            changeZoom(direction) {
                for (let i = 0; i < this.pages.length; i++) {
                    this.$refs[`editingcanvas${i}`][0].$el.style.transform = '';
                }

                if (direction === 'minus' && this.currentZoom > 0) {
                    this.currentZoom--;
                } else if (direction === 'plus' && this.currentZoom < 10) {
                    this.currentZoom++;
                }

                setTimeout(() => {

                    for (let i = 0; i < this.pages.length; i++) {
                        this.$refs[`editingcanvas${i}`][0].$el.style.transform = `scale(${1 + (this.currentZoom / 5)}, ${1 + (this.currentZoom / 5)})`;
                        this.$refs[`editingcanvas${i}`][0].$el.style.transformOrigin = `left top`;
                    }

                    this.$nextTick(this.$refs.scrollbar.refresh());
                    this.$nextTick(this.$refs.scrollbar.refreshInternalStatus());
                }, 150);

            },
            setTool(type) {
                if (this.currentActiveMethod !== type) {
                    if (this.currentActiveMethod === 'marker' && type === 'pen') {
                        return;
                    }
                    this.currentActiveMethod = type;
                    localStorage.setItem('editType', type);
                    for (let i = 0; i < this.pages.length; i++) {
                        this.$refs[`editingcanvas${i}`][0].switchTool(type);
                    }
                } else {
                    this.currentActiveMethod = 'none';
                    for (let i = 0; i < this.pages.length; i++) {
                        this.$refs[`editingcanvas${i}`][0].switchTool('none');
                    }
                }
            },
            undo() {
                if (this.pages.length === 1) {
                    this.$refs[`editingcanvas0`][0].undo();
                } else {
                    const scrolledDistance = this.$refs.scrollbar.getPosition().scrollTop;
                    const canvasIndex = (scrolledDistance - (scrolledDistance % this.canvasHeight)) / this.canvasHeight;
                    this.$refs[`editingcanvas${canvasIndex}`][0].undo();
                }
            },
            async close() {
                await this.$router.replace({
                    query: {
                        appointment: this.appointmentId,
                    }
                });
                this.setCurrentFile(null);
                this.$router.back();
                this.setLoading(false);
            },
            setLoading(status, msg) {
                this.loadingMessage = msg ? msg : 'Dokument wird geladen...';
                this.loadingActive = status;
            },
            setStempel(stempel) {
                this.currentStempel = stempel;
                localStorage.setItem('editStempel', stempel);
                if (this.pages.length === 1) {
                    this.$refs[`editingcanvas0`][0].setStempelImg(stempel);
                } else {
                    for (let i = 0; i < this.pages.length; i++) {
                        this.$refs[`editingcanvas${i}`][0].setStempelImg(stempel);
                    }
                }
                if (this.currentActiveMethod !== 'stempel'){
                    this.setTool('stempel');
                }
            },
            spawnTextOnCanvas() {
                if (this.pages.length === 1) {
                    this.$refs[`editingcanvas0`][0].spawnText(null, null);
                } else {
                    const scrolledDistance = this.$refs.scrollbar.getPosition().scrollTop;
                    const canvasIndex = (scrolledDistance - (scrolledDistance % this.canvasHeight)) / this.canvasHeight;
                    this.$refs[`editingcanvas${canvasIndex}`][0].spawnText(null, null);
                }
            },
            checkForScrollbarStylingUpdates(scrollbarParent) {
                if (scrollbarParent.classList.contains('hasVBar') ) {
                    if (!this.isVerticalScrollbarStyled) {
                        this.scrollbarStyling.styleVerticalScrollbar();
                        this.isVerticalScrollbarStyled = true;
                    }
                } else {
                    this.isVerticalScrollbarStyled = false;
                }

                if (scrollbarParent.classList.contains('hasHBar') ) {
                    if (!this.isHorizontalScrollbarStyled) {
                        this.scrollbarStyling.styleHorizontalScrollbar();
                        this.isHorizontalScrollbarStyled = true;
                    }
                } else {
                    this.isHorizontalScrollbarStyled = false;
                }
            },
            async requestFileForEditing(fileType, userGroup) {
                const response = await this.pullCurrentFile({
                    userGroup,
                    fileType,
                    fileName: this.assignmentName,
                    appointmentId: this.appointmentId,
                    fileId: this.fileId
                });

                if (response && (response.status === 200
                    || response.status === 201
                    || response.status === 204)) {
                    if (response.file) {
                        this.currentFile = response.file;
                        if (this.getCurrentFileType === 'pdf') {
                            await this.computeLoadedPDF(this.currentFile);
                        } else {
                            await this.$refs[`editingcanvas0`][0].setBackgroundImg(this.currentFile);
                        }
                    } else {
                        if (response.error) {
                            this.snackbars.genericError = true;
                        }
                        this.setLoading(false);
                    }
                } else {
                    if (response.status === 401 || response.status === 403 ) {
                        this.snackbars.fileNotAllowed = true;
                    } else if (response.status === 404) {
                        this.snackbars.fileNotAvailable = true;
                    } else {
                        this.snackbars.genericError = true;
                    }
                    this.setLoading(false);
                }
                this.changeColor('#e6231e', true)
            },
            async computeLoadedPDF(pdfFile) {
                const pdfDocumentLoadingTask =pdfjs.getDocument({data: pdfFile});
                const pdfDocument = await pdfDocumentLoadingTask.promise;

                const pagePromises = Array.from(Array(pdfDocument.numPages).keys()) // Create array from [0 ... countPages]
                    .map(element => element + 1) // Add 1 to each element to get all page numbers
                    .map(page => pdfDocument.getPage(page));
                console.log(resolvedPages);
                const resolvedPages = await Promise.all(pagePromises)
                this.pages = resolvedPages;

                for (let i = 0; i < resolvedPages.length; i++) {
                    const nameString = `editingcanvas${i}`.toString()
                    await this.$refs[nameString][0].setBackgroundPDF(resolvedPages[i])
                }
                this.changeColor('#e6231e',true)
            },
            rotateEditor() {
                this.messageList.forEach((el) => {
                    let temp = JSON.parse(JSON.stringify(el.x));
                    el.x = el.y;
                    el.y = temp;
                    el.x = 100 - el.x;
                });
                for (let i = 0; i < this.pages.length; i++) {
                    this.$refs[`editingcanvas${i}`][0].rotateEditor();
                }
            },
            scrolledEvent(vertical, horizontal, nativeEvent){

            },
            jumpToPage(index) {
                this.$refs.scrollbar.scrollTo({ y: this.canvasHeight * index + 10 });
            },

            /**
             * Region Editor Messages (Audio, Video, Text)
             */
            audioMessageYAxis(message) {
                if (this.pages.length === 1) {
                    return message.y * (1 + (this.currentZoom / 5));
                } else {
                    return (message.page / this.pages.length) * 100 + (message.y * (1 / this.pages.length));
                }
            },
            spawnAudioObject() {
                if (this.pages.length === 1) {
                    this.$refs[`editingcanvas0`][0].spawnAudio();
                } else {
                    const scrolledDistance = this.$refs.scrollbar.getPosition().scrollTop;
                    const canvasIndex = (scrolledDistance - (scrolledDistance % this.canvasHeight)) / this.canvasHeight;
                    this.$refs[`editingcanvas${canvasIndex}`][0].spawnAudio();
                }
            },
            spawnTextObject(text) {
                console.log('spawnTextObject called with text', text)
                this.tempTextMessage = text;
                if (this.pages.length === 1) {
                    this.$refs[`editingcanvas0`][0].spawnTextMessage();
                } else {
                    const scrolledDistance = this.$refs.scrollbar.getPosition().scrollTop;
                    const canvasIndex = (scrolledDistance - (scrolledDistance % this.canvasHeight)) / this.canvasHeight;
                    this.$refs[`editingcanvas${canvasIndex}`][0].spawnTextMessage();
                }
            },
            placeNewEditorMessage(page, top, left) {
                this.messageList.push({x: left * 100, y: top * 100, page, tabIndex: this.tabOrderArray.length});
                this.tabOrderArray.push(this.messageList[this.messageList.length - 1]);
            },
            placeNewEditorTextMessage(page, top, left) {
                this.textMessageList.push({
                    x: left * 100,
                    y: top * 100,
                    page,
                    text: this.tempTextMessage,
                    tabIndex: this.tabOrderArray.length,
                });
                this.tabOrderArray.push(this.textMessageList[this.textMessageList.length - 1]);
            },
            placeNewImage(url, blob) {
                console.log("Spawning Image url: ", url);
                let canvasIndex = 0;
                if (this.pages.length > 1) {
                    const scrolledDistance = this.$refs.scrollbar.getPosition().scrollTop;
                    canvasIndex = (scrolledDistance - (scrolledDistance % this.canvasHeight)) / this.canvasHeight;
                }
                this.imageList.push({
                    x: 50,
                    y: 25,
                    page: canvasIndex,
                    fileUrl: url,
                    fileBlob: blob,
                    text: blob.name,
                    width: '300',
                    height: '200',
                    tabIndex: this.tabOrderArray.length
                });
                this.tabOrderArray.push(this.imageList[this.imageList.length - 1]);            },
            async addNewPage(type){
                const index = this.pages.push(this.pages.length) - 1;
                // Wait for the next tick, so the page has been added and a new canvas is created
                this.$nextTick(async () => {
                    await this.$refs[`editingcanvas${index}`][0].setEmptyBackground(type);
                    this.$refs[`editingcanvas${index}`][0].changeColor(this.color);
                });
            },
            addMoveableText(value, size){
                let canvasIndex = 0;
                if (this.pages.length > 1) {
                    const scrolledDistance = this.$refs.scrollbar.getPosition().scrollTop;
                    canvasIndex = (scrolledDistance - (scrolledDistance % this.canvasHeight)) / this.canvasHeight;
                }
                this.moveableTexts.push({
                    x: 50,
                    y: 50,
                    width: size,
                    page: canvasIndex,
                    text: value,
                    tabIndex: this.tabOrderArray.length
                });
                this.tabOrderArray.push(this.moveableTexts[this.moveableTexts.length - 1]);
            },
            addRectangle(color, border){
                let canvasIndex = 0;
                if (this.pages.length > 1) {
                    const scrolledDistance = this.$refs.scrollbar.getPosition().scrollTop;
                    canvasIndex = (scrolledDistance - (scrolledDistance % this.canvasHeight)) / this.canvasHeight;
                }
                this.rectangles.push({
                    x: 50,
                    y: 50,
                    width: 100 / this.canvasWidth,
                    height: 40 / this.canvasHeight,
                    text: color,
                    startTextTask: (border ? border : ''),
                    page: canvasIndex
                });
            },
            updateTextTaskPosition(task, coords) {
                const newX = task.x + ((coords.left / this.canvasWidth) * 100);
                const newY = task.y + ((coords.top / this.canvasHeight) * 100);

                if ((newX > 0 && newX < 100) && (newY > 0 && newY < 100)) {
                    task.x = newX;
                    task.y = newY;
                }
            },
            updateTextTaskDimension(task, dimensions) {
                const widthPercentage = dimensions.width/this.canvasWidth;
                const heightPercentage = dimensions.height/this.canvasHeight;

                task.width = widthPercentage;
                task.height = heightPercentage;
            },
            async safeAllEditorMessages(newFileId) {
                console.log('Speichern: ', this.tabOrderArray);

                try {
                    const messages = [];
                    for (let i = 0; i < this.messageList.length; i++) {
                        const message = {
                            x: this.messageList[i].x,
                            y: this.messageList[i].y,
                            page: this.messageList[i].page,
                            start: this.messageList[i].start,
                            answer: this.messageList[i].answer,
                            correction: null,
                            messageType: 'audio',
                            tabIndex: this.messageList[i].tabIndex,
                        };

                        if (this.messageList[i].correctionFile) {
                            this.messageList[i].correction = await this.uploadRecordedMessage(this.messageList[i].correctionFile, newFileId + '.wav');
                            message.correction = this.messageList[i].correction._id;
                        }


                        messages.push(message)
                    }

                    for (let i = 0; i < this.textMessageList.length; i++) {
                        const message = {
                            x: this.textMessageList[i].x,
                            y: this.textMessageList[i].y,
                            page: this.textMessageList[i].page,
                            messageType: 'text',
                            text: this.textMessageList[i].text,
                            tabIndex: this.textMessageList[i].tabIndex,
                        }
                        messages.push(message)
                    }

                    for (let i = 0; i < this.textTaskList.length; i++) {
                        const message = {
                            x: this.textTaskList[i].x,
                            y: this.textTaskList[i].y,
                            page: this.textTaskList[i].page,
                            messageType: 'texttask',
                            width: this.textTaskList[i].width,
                            height: this.textTaskList[i].height,
                            tabIndex: this.textTaskList[i].tabIndex,
                            done: this.textTaskList[i].done,
                            startTextTask: this.textTaskList[i].startTextTask,
                            answersTextTask: this.textTaskList[i].answersTextTask
                        }
                        messages.push(message)
                    }

                    for (let i = 0; i < this.textAnswerList.length; i++) {
                        const message = {
                            x: this.textAnswerList[i].x,
                            y: this.textAnswerList[i].y,
                            page: this.textAnswerList[i].page,
                            messageType: 'answerField',
                            author: this.textAnswerList[i].author,
                            text: this.textAnswerList[i].text,
                            tabIndex: this.textAnswerList[i].tabIndex,
                        }
                        messages.push(message)
                    }

                    // Add the images
                    for (const img of this.imageList) {
                        const message = {
                            x: img.x,
                            y: img.y,
                            width: img.width,
                            height: img.height,
                            page: img.page,
                            start: img.start,
                            text: img.text,
                            tabIndex: img.tabIndex,
                            messageType: 'imageObject',
                        };
                        // Image is new and needs to be uploaded to the server
                        if (!message.start) {
                            let res = await backend.postUploadMessage(img.fileBlob);
                            res = await res.json();
                            message.start = res._id;
                        }
                        messages.push(message);
                    }

                    // Add the videos
                    for (const video of this.videoList) {
                        const message = {
                            x: video.x,
                            y: video.y,
                            width: video.width,
                            height: video.height,
                            page: video.page,
                            start: video.start,
                            tabIndex: video.tabIndex,
                            messageType: 'videoObject',
                        };
                        // Video is new and needs to be uploaded to the server
                        if (!message.start) {
                            let res = await backend.postUploadMessage(video.fileBlob);
                            res = await res.json();
                            message.start = res._id;
                        }
                        messages.push(message);
                    }
                    // Add the multiple choices
                    for (const mChoice of this.multipleChoices) {
                        const message = {
                            x: mChoice.x,
                            y: mChoice.y,
                            width: mChoice.width,
                            page: mChoice.page,
                            startTextTask: mChoice.startTextTask,
                            multipleChoices: mChoice.multipleChoices,
                            tabIndex: mChoice.tabIndex,
                            messageType: 'multipleChoice',
                        };
                        messages.push(message);
                    }
                    // Add the moveable texts
                    for (const text of this.moveableTexts) {
                        const message = {
                            x: text.x,
                            y: text.y,
                            width: text.width,
                            page: text.page,
                            text: text.text,
                            tabIndex: text.tabIndex,
                            messageType: 'moveableText',
                        };
                        messages.push(message);
                    }
                    // Add the rectangles
                    for (const rect of this.rectangles) {
                        const message = {
                            x: rect.x,
                            y: rect.y,
                            width: rect.width,
                            height: rect.height,
                            page: rect.page,
                            text: rect.text,
                            startTextTask: rect.startTextTask,
                            messageType: 'rectangle',
                        };
                        messages.push(message);
                    }
                    // Add the lines
                    for (const line of this.lines) {
                        const message = {
                            x: line.x,
                            y: line.y,
                            width: line.width,
                            height: line.height,
                            page: line.page,
                            text: line.text,
                            rotation: line.rotation,
                            messageType: 'line',
                        };
                        messages.push(message);
                    }

                    if (messages.length) {
                        this.createUploadMessages({file: newFileId, points: messages});
                    }
                } catch (e) {
                    console.error(e);
                    this.snackbars.dataCouldntBeSaved = true;
                    this.setLoading(false);
                    return;
                }
                await this.close();
            },
            sortIncomingMessages(messages) {
                // Fill tab order array
                this.tabOrderArray = [];
                const indexedMessages = messages.filter(item => item.tabIndex);
                for (let i = 0; i < indexedMessages.length; i++) {
                    this.tabOrderArray[indexedMessages[i].tabIndex] = indexedMessages[i];
                }
                console.log(this.tabOrderArray);

                // console.log('messages', messages);
                this.messageList = messages.filter(item => item.messageType === 'audio' || !item.messageType);
                // console.log('messageList', this.messageList);
                this.textMessageList = messages.filter(item => item.messageType === 'text');
                // console.log('textMessageList', this.textMessageList);
                this.textTaskList = messages.filter(item => item.messageType === 'texttask');
                // console.log('textTaskList', this.textTaskList);
                this.textAnswerList = messages.filter(item => item.messageType === 'answerField');
                // console.log('textAnswerList', this.textAnswerList);
                this.imageList = messages.filter(item => item.messageType === 'imageObject');
                // console.log('imageList', this.imageList);
                this.videoList = messages.filter(item => item.messageType === 'videoObject');
                // console.log('videoList', this.videoList);
                this.multipleChoices = messages.filter(item => item.messageType === 'multipleChoice');
                // console.log('videoList', this.videoList);
                this.moveableTexts = messages.filter(item => item.messageType === 'moveableText');
                this.rectangles = messages.filter(item => item.messageType === 'rectangle');

                // Bugfix: Checks width and height of old rectangles > ONLY NEEDED DURING TRANSITION
                for (let i = 0; i < this.rectangles.length; i++) {
                    if (this.rectangles[i].width > 2) {
                        this.rectangles[i].width = this.rectangles[i].width / this.canvasWidth;
                    }
                    if (this.rectangles[i].height > 2) {
                        this.rectangles[i].height = this.rectangles[i].height / this.canvasHeight;
                    }
                }

                this.lines = messages.filter(item => item.messageType === 'line');
            },
            async uploadRecordedMessage( file, nameForCompleteness) {
                if (file) {
                    let file2 = new File([file], nameForCompleteness, {type: 'audio/wav'});
                    const res = await backend.postUploadMessage(file2);
                    return await res.json();
                }
            },
            deleteTextNote(index) {
                this.textMessageList.splice(index, 1);
            },
            deleteAnswerField(index) {
                this.textAnswerList.splice(index, 1);
            },
        },
        async mounted() {
            let fileType = '';
            let localUploadId = '';
            let localAppointmentId = '';
            if (this.$route.query.group) {
                this.groupName = this.$route.query.group;
            }
            if (this.$route.query.color) {
                this.groupColor = this.$route.query.color;
            }
            if (this.$route.query.aId) {
                this.appointmentId = this.$route.query.aId;
                localAppointmentId = this.$route.query.aId;
            }
            if (this.$route.query.fId) {
                this.fileId = this.$route.query.fId;
            }
            if (this.$route.query.title) {
                this.assignmentName = this.$route.query.title;
                const assignmentNameParts = this.assignmentName.split('.')

                fileType = assignmentNameParts[assignmentNameParts.length - 1];
            }
            if (this.$route.query.uploadid) {
                this.uploadId = this.$route.query.uploadid;
                localUploadId = this.$route.query.uploadid;
            }

            this.setLoading(true);
            try {
                await this.requestFileForEditing(fileType, 'teacher');
            } catch (e) {
                console.error(e);
            }

            // Request messages
            try {
                const response = await this.getUploadMessages(this.fileId);
                console.log(response);
                if (Number.isInteger(response)) {
                    // show error
                } else {
                    // this.messageList = response && response.points ? response.points : [];
                    this.sortIncomingMessages(response && response.points ? response.points : []);
                }
            } catch (e) {
                console.log(e);
            }

            // Editor Autosave parts

            const params = {
                appointmentId: localAppointmentId,
                uploadId: localUploadId
            };
            try {

                const bufferResponse = await this.pullSavedWork(params);

                if (bufferResponse) {
                    const responseJson = await bufferResponse.json();
                    this.loadWork(responseJson.changeHistory);
                }
            } catch (e) {
                // Buffer is probably empty, should it be empty tho?
                if (e.name !== 'SyntaxError') {
                    console.error(e);
                }
            }

            this.checkForScrollbarStylingUpdates(document.getElementsByClassName('__vuescroll')[0]);

            const observer = new MutationObserver((mutationsList, observer) => {
                for(const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        this.checkForScrollbarStylingUpdates(document.getElementsByClassName('__vuescroll')[0]);
                    }
                }
            });

            // Start observing the target node for configured mutations
            observer.observe(
                document.getElementsByClassName('__vuescroll')[0],
                {attributes: false, childList: true, subtree: false }
            );

            if (this.windowWidth > 600) {
                this.displayOtherButtons = true;
            }

            //Get selected Tools
            const color = localStorage.getItem('editColor');

            const type = localStorage.getItem('editType');

            const size = localStorage.getItem('editSize');

            const stempel = localStorage.getItem('editStempel');

            if(color) {
                this.changeColor(color);
            }

            if(stempel) {
                this.setStempel(stempel);
            }

            if(type) {
                this.setTool(type);
            }

            if(size) {
                this.changeSize(size);
            }

        },
    }
</script>

<style scoped lang="scss">
    @import "~@/styles/globals.scss";

    .save-image-btn {
        margin-left: 5px;
        height: 49px !important;
        background-color: #8CBD46 !important;

        @media (max-width: 380px) {
            width: 40px !important;
            min-width: 40px !important;
            height: 40px !important;
        }

        & img {
            @include zwischenspeichernIconSize();
            filter: brightness(1000%);
        }

        & p {
            @include zwischenspeichernFontSize();
        }
    }

    .toolSelector {
        background-color: #f8f8f8 !important;
        width: 49px !important;
        height: 49px !important;

        img {
            filter: brightness(0%);
        }

        @media (max-width: 380px) {
            width: 40px !important;
            height: 40px !important;
        }
    }

    #closeBtn {
        background-color: #e6221e80;
        width: 49px;
        height: 49px;

        @media (max-width: 380px) {
            width: 40px !important;
            height: 40px !important;
            margin-right: 0 !important;
        }
    }

    .stempelButton {
        background-color: #EAEAEA;
        border-radius: 0 !important;
        width: 54px;
        height: 49px !important;
    }

    .activeTool {
        background-color: #d8d8d8 !important;
    }

    .markerActive {
        border: 2px solid rgb(234, 234, 234);
        background-color: #707070 !important;
        color: white;
        padding: 10px !important;
    }

    #pageView {
        position: fixed;
        left: 0;
        top: 81px;
        height: 100vh;
        width: 100px;
        background-color: white;

        p {
            text-align: center;
        }
    }

    .multiplePagesZoomControl {
        left: 150px !important;
    }

    .zoomControl {
        position: fixed;
        z-index: 8 !important;
        top: 95px;
        left: 20px;
        background-color: #f8f8f8 !important;
        width: 49px !important;
        height: 49px !important;
        border-radius: 8px;

        p {
            line-height: 0;
            font-size: 60px;
            margin-bottom: 0;
            font-weight: 300;
            color: #333;
        }

        @media (max-width: 380px) {
            width: 40px !important;
            height: 40px !important;

            p {
                font-size: 50px;
            }
        }
    }

    #smallDisplayMoreButtons {
        display: block;
        width: 50px;

        button {
            border-radius: 0 !important;
        }
    }

    #smallDisplayMoreButtonsTrigger {
        width: 49px !important;
        height: 49px !important;
        padding-left: 2px !important;

        @media (max-width: 380px) {
            width: 40px !important;
            height: 40px !important;
            padding-left: 0 !important;
        }
    }

    .colorPickerMenu {
        width: 216px !important;
        height: 25px;
        display: inline;
        border-radius: 0 !important;
        background-color: #EAEAEA;
    }

    .v-snack.v-snack--top {
        bottom: initial; /* or auto */
    }

    .v-snack.v-snack--bottom {
        top: initial; /* or auto */
    }
</style>
