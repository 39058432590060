<template>
    <div>
<!--        <div id="versionNumber">Version: 1.0.0f {{ versionNumber }}</div>-->
        <router-view></router-view>

        <DatenschutzAbfrage
                v-if="displayAGB"
                :hasAcceptedAGB="hasAcceptedAGB"
                @declinedAGB="() => { displayAGB = false }"
        />

      <cookie-law/>

    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from "vuex";
    import DatenschutzAbfrage from "@/components/DatenschutzAbfrage";
    import setGlobalButtonContrast from "../util/accessibilityButtonContrast";
    import CookieLaw from "@/components/CookieLaw";


    export default {
        name: 'Main',
        components: {
          CookieLaw,
            // eslint-disable-next-line vue/no-unused-components
            DatenschutzAbfrage
        },
        data() {
            return {
                hasAcceptedAGB: false,
                displayAGB: false,
                setGlobalButtonContrast,
            };
        },
        computed: {
            ...mapState("auth", ["account"]),
            ...mapState("util", ["versionNumber"]),
        },
        methods: {
            ...mapActions("auth", ["getAccount"]),
            ...mapActions('util', [ 'loadServerTime', 'requestVersionNumber' ]),



            rerouteAfterLogin(accountValue) {
                switch (accountValue.role) {
                    case 'maintainer':
                        if(!this.$route.name.includes('verwaltung')) {
                            this.$router.replace({ name: 'verwaltung', query: this.$router.params }).catch(err => {});
                        }
                        break;
                    case 'pupil':
                        if(!this.$route.name.includes('schueler')) {
                            this.$router.replace({ name: 'schueler', query: this.$router.params}).catch(err => {});
                        }
                        break;
                    case 'parent':
                        if(!this.$route.name.includes('parent')) {
                            this.$router.replace({ name: 'eltern', query: this.$router.params}).catch(err => {});
                        }
                        break;
                    case 'teacher':
                        if(!this.$route.name.includes('lehrer')) {
                            this.$router.replace({ name: 'lehrer', query: this.$router.params}).catch(err => {});
                        }
                        break;
                    default:
                        this.$router.replace({ name: 'main' }).catch(err => {});
                        break;
                }
            },
        },
        watch: {
            account(newValue) {
                this.setGlobalButtonContrast('none', 'none');
                if (newValue.accountName) {
                    this.loadServerTime();
                    this.requestVersionNumber();

                    // Display Terms of Service or directly login
                    this.hasAcceptedAGB = newValue.hasAcceptedToS;
                    if (newValue.hasAcceptedToS) {
                        this.rerouteAfterLogin(newValue);
                    } else {
                        this.displayAGB = true;
                    }
                    return;
                }
                this.rerouteAfterLogin(newValue);
            },
        },
        mounted() {
            this.rerouteAfterLogin(this.account);
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#versionNumber {
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 5px;
    left: 5px;
    z-index: 1;
}
</style>
