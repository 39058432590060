<template>
    <div>

        <progress-bar
            title="Hochladen"
            :show-progress="showPrivateUploadProgress"
            :progress="privateUploadProgress"
            :abort-progress="privateXmlHttpRequest ? () => { privateXmlHttpRequest.abort() }: () => {}"
        />

        <progress-bar
            title="Hochladen"
            :show-progress="showSubjectUploadProgress"
            :progress="subjectUploadProgress"
            :abort-progress="subjectXmlHttpRequest ? () => { subjectXmlHttpRequest.abort() }: () => {}"
        />

        <name-file
            v-model="showNameFile"
            :original-name="uploadFileName"
            :callback="fileNameCallback"
        />

        <v-row class="mx-0" style="width: 100%;">
            <div class="whiteBg" style="height: auto; width: 90%; min-height: 80vh; border-radius: 15px; box-shadow: 1px 5px 5px silver; margin: auto auto 5em;">

                <!--Header of Dateien-->
                <div style="background-color: #F49F31; border-radius: 15px 15px 0 0; padding-top: 10px; padding-bottom: 10px; color: white;">
                    <v-row style="max-width: 100%; display: flex; align-items: center; height: 35px" class="mx-2">
                        <v-col cols="9" md="8" lg="9" class="pa-0 pl-2 d-flex align-center" style="font-size: larger;">
                            <img v-if="!fach &&  !day && !month && !privateFolder && !teacherFolder" :src="fileIcon" class="iconToWhite mr-1" style="height: 20px;"/>
                            <v-icon aria-label="Zurück" tabindex="-1" v-else :size="20" @click="teacherFolder ? teacherFolder= false : (privateFolder ? privateFolder = false : (subjectFolder ? subjectFolder = false :(day ? day = null : (week ? week= null : (month ? month= null : fach = null)))))" color="white" class="iconToWhite mr-1" style="height: 20px; width: 20px">mdi-arrow-left</v-icon>
                            <h1 id="dateienHeading" @click="readWidgetInfoText()" tabindex="0" class="mb-0">Dateien</h1>
                        </v-col>
                        <div @click="readWidgetInfoText()" style="position: absolute; width: 20%; height: 55px; margin-left: 100px" class=""></div>
                        <v-col @click="readWidgetInfoText()" cols="3" md="4" lg="3" class="pa-0 pr-2 d-flex align-center justify-end">
                            <img :src="infoRoundIcon" style="height: 15px;">
                        </v-col>
                    </v-row>
                </div>

                <div class="pa-3 my-1 mx-auto d-flex" v-if="!privateFolder && !teacherFolder" @click="privateFolder = true" :style="'position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'">
                    <img :src="schlossIcon" class="iconSelect" style="background-color: #F49F31">
                    <div class="pl-1" style="height: 24px">
                        <p tabindex="0" class="mb-0" style="width: fit-content;" @keypress.enter="privateFolder = true">Privater Ordner</p>
                    </div>
                </div>

                <div class="pa-3 my-1 mx-auto d-flex" v-if="!privateFolder && !teacherFolder && account.role == 'teacher'" @click="teacherFolder = true" :style="'position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'">
                    <img :src="schlossIcon" class="iconSelect" style="background-color: #F49F31">
                    <div class="pl-1" style="height: 24px">
                        <p tabindex="0" class="mb-0" style="width: fit-content; height: fit-content;">Geteilter Lehrer Ordner</p>
                    </div>
                </div>

                <!--directory tree-->
                <div style="margin-left: 5%" v-if="!privateFolder && !teacherFolder" class="pt-2 grey--text font-weight-bold">
                    <div @click="week = null; month = null; fach = null; day = null; subjectFolder = false" style="display: inline-flex; cursor: pointer">
                        <p :id="(!fach && !month && !week && !day) ? 'currentPath' : ''" tabindex="0" aria-label="Dateipfad: Fächer " class="mb-0" style="width: fit-content" @keypress.enter="week = null; month = null; fach = null; day = null; subjectFolder = false">Fächer/</p>
                    </div>

                    <div v-if="fach" @click="week = null; month = null; day = null" style="display: inline-flex; cursor: pointer">
                        <p :id="(fach && !month && !week && !day)  ? 'currentPath' : ''" tabindex="0"
                           :aria-label="'Aktueller Dateipfad: ' + 'Fach: ' + fach[0].schoolSubject.name"
                           class="mb-0" style="width: fit-content" @keypress.enter="week = null; month = null; day = null">{{fach[0].schoolSubject.name}}/
                        </p>
                    </div>

                    <div v-if="month" @click="week = null; day = null" style="display: inline-flex; cursor: pointer">
                        <p :id="(fach && month && !week && !day) ? 'currentPath' : ''" tabindex="0"
                           :aria-label="'Aktueller Dateipfad: ' + 'Fach: ' + fach[0].schoolSubject.name + ', Monat:  ' + monthNames[new Date(month[0].day).getMonth()]"
                           class="mb-0" style="width: fit-content" @keypress.enter=" week = null; day = null">{{monthNames[new Date(month[0].day).getMonth()] }}/</p>
                    </div>

                    <div v-if="week" @click="day = null" style="display: inline-flex; cursor: pointer">
                        <p :id="(fach && month && week && !day) ? 'currentPath' : ''" tabindex="0"
                           :aria-label="'Aktueller Dateipfad: ' + 'Fach: ' + fach[0].schoolSubject.name + ', Monat:  ' + monthNames[new Date(month[0].day).getMonth()] + ', Kalenderwoche ' + new Date(week[0].day).getWeek()"
                           class="mb-0" style="width: fit-content" @keypress.enter="day = null">{{new Date(week[0].day).getWeek() }}/</p>
                    </div>

                    <div v-if="day" style="display: inline-flex; cursor: pointer" >
                        <p :id="(fach && month && week && day) ? 'currentPath' : ''" tabindex="0"
                           :aria-label="'Aktueller Dateipfad: ' + 'Fach: ' + fach[0].schoolSubject.name + ', Monat:  ' + monthNames[new Date(month[0].day).getMonth()] + ', Kalenderwoche ' + new Date(week[0].day).getWeek() + ', Datum: ' + new Date(day[0].day).toLocaleDateString()"
                           class="mb-0" style="width: fit-content">{{new Date(day[0].day).toLocaleDateString()}}</p>
                    </div>
                </div>

                <!--body of Dateien-->
                <vue-custom-scrollbar class="scroll-area" :settings="settings">
                    <!--Fach folder-->
                    <div v-if="!fach && !privateFolder && !teacherFolder">
                        <div v-for="(el, index) in schoolSubjectGroups" :key="el[0]._id + index + 'subject'">
                            <v-badge
                                color="red"
                                :content="count(el)"
                                :value="count(el)"
                                overlap
                                right
                                offset-x="40"
                                offset-y="20"
                                style="z-index: 8; width: 100%;"
                            >
                                <div class="pa-3 my-1 mx-auto d-flex" @click="selectSubject(el)" :style="'position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'">
                                    <img v-if="getSrc(el[0].schoolSubject.icon)" alt="" class="iconSelect" :style="{ 'background-color': el[0].schoolSubject.color}" :src="getSrc(el[0].schoolSubject.icon)">
                                    <div class="pl-1 d-flex align-center" style="height: 24px">
                                        <p role="button" tabindex="0" class="mb-0" style="width: fit-content" @keypress.enter="selectSubject(el);">{{el[0].schoolSubject.name}}</p>
                                    </div>
                                </div>
                            </v-badge>
                        </div>
                    </div>

                    <!--inside Fach folder-->
                    <div v-else-if="!privateFolder && !teacherFolder">
                        <div v-if="!day && !subjectFolder">
                            <!--Allgemein folder-->
                            <div v-for="(el) in getFachByTeacher()" :key="el[0]._id + 'general'" @click="selectSubjectFolder(el[0].teachers[0]._id)" class="pa-3 my-1 mx-auto d-flex" :style="'position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'">
                                <img v-if="getSrc(fach[0].schoolSubject.icon)" alt="" class="iconSelect" :style="{ 'background-color': fach[0].schoolSubject.color}" :src="getSrc(fach[0].schoolSubject.icon)">
                                <div class="pl-1" style="height: 24px">
                                    <p role="button" tabindex="0" class="mb-0" style="width: fit-content" @keypress.enter="selectSubjectFolder(el[0].teachers[0]._id)">Allgemein {{ getTeacherDisplayName(el[0].teachers) }}</p>
                                </div>
                            </div>

                            <!--Appointment folders-->
                            <div v-if="month">
                                <div v-if="week">
                                    <!-- select specific appointment (date + timeslot) -->
                                    <div v-for="(el, index) in subjectDateGroups" :key="el[0]._id + index + 'Aptmnt'">
                                        <v-badge
                                                color="red"
                                                :content="count(el)"
                                                :value="count(el)"
                                                overlap
                                                right
                                                offset-x="40"
                                                offset-y="20"
                                                style="z-index: 8; width: 100%;"
                                        >
                                            <div class="pa-3 my-1 mx-auto d-flex" @click="selectDay(el)" :style="'position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'">
                                                <img v-if="getSrc(fach[0].schoolSubject.icon)" alt="" class="iconSelect" :style="{ 'background-color': fach[0].schoolSubject.color}" :src="getSrc(fach[0].schoolSubject.icon)">
                                                <div v-if="account.role === 'pupil'" class="pl-1 d-flex align-center" style="height: 24px">
                                                    <p role="button" tabindex="0" class="mb-0" style="width: fit-content" @keypress.enter="selectDay(el)">{{new Date(el[0].day).toLocaleDateString() + ' ' +  getTeacherDisplayName(el[0].teachers) }}</p>
                                                </div>
                                                <div v-else class="pl-1 d-flex align-center" style="height: 24px">
                                                    <p role="button" tabindex="0" class="mb-0" style="width: fit-content" @keypress.enter="selectDay(el)">{{new Date(el[0].day).toLocaleDateString() + ' ' + getSlotTime(el[0].timeslot) }}</p>
                                                </div>
                                            </div>
                                        </v-badge>
                                    </div>
                                </div>
                                <!-- select calendar week -->
                                <div v-else>
                                    <div v-for="(el, index) in subjectWeekGroups" :key="el[0]._id + index + 'week'">
                                        <v-badge
                                                color="red"
                                                :content="count(el)"
                                                :value="count(el)"
                                                overlap
                                                right
                                                offset-x="40"
                                                offset-y="20"
                                                style="z-index: 8; width: 100%;"
                                        >
                                            <div class="pa-3 my-1 mx-auto d-flex" @click="selectWeek(el)" :style="'position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'">
                                                <img v-if="getSrc(fach[0].schoolSubject.icon)" alt="" class="iconSelect" :style="{ 'background-color': fach[0].schoolSubject.color}" :src="getSrc(fach[0].schoolSubject.icon)">
                                                <div class="pl-1 d-flex align-center" style="height: 24px">
                                                    <p role="button" tabindex="0" class="mb-0" style="width: fit-content" @keypress.enter="selectWeek(el)">Woche {{new Date(el[0].day).getWeek()}}</p>
                                                </div>
                                            </div>
                                        </v-badge>
                                    </div>
                                </div>
                            </div>
                            <!-- select month -->
                            <div v-else>
                                <div v-for="(el, index) in subjectMonthGroups" :key="el[0]._id + index + 'month'">
                                    <v-badge
                                            color="red"
                                            :content="count(el)"
                                            :value="count(el)"
                                            overlap
                                            right
                                            offset-x="40"
                                            offset-y="20"
                                            style="z-index: 8; width: 100%;"
                                    >
                                        <div class="pa-3 my-1 mx-auto d-flex" @click="selectMonth(el)" :style="'position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'">
                                            <img v-if="getSrc(fach[0].schoolSubject.icon)" alt="" class="iconSelect" :style="{ 'background-color': fach[0].schoolSubject.color}" :src="getSrc(fach[0].schoolSubject.icon)">
                                            <div class="pl-1 d-flex align-center" style="height: 24px">
                                                <p role="button" tabindex="0" class="mb-0" style="width: fit-content" @keypress.enter="selectMonth(el)">{{monthNames[new Date(el[0].day).getMonth()]}}</p>
                                            </div>
                                        </div>
                                    </v-badge>
                                </div>
                            </div>
                        </div>

                        <!--inside Appointment folder-->
                        <div v-else-if="!subjectFolder">
                            <div style="margin-left: 5%" class="pt-2 font-weight-bold greyText">
                                <p role="button" tabindex="0" class="mb-0" style="width: fit-content">Material:</p>
                            </div>
                            <div v-for="(el, index) in teacherUploads" :key="el[0]._id + index + 'lesson'">
                                <v-badge
                                    color="red"
                                    content="1"
                                    :value="!el.seenByMe && account.role == 'pupil'"
                                    overlap
                                    offset-x="40"
                                    offset-y="20"
                                    style="z-index: 8; width: 100%;"
                                >
                                    <div class="pa-3 my-1 mx-auto" :style="'display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '">
                                        <img @click="() => clickDownloadTeacherUpload(el)" v-if="getSrc(fach[0].schoolSubject.icon)" alt="" class="iconSelect" :style=" 'cursor: pointer; background-color:' + fach[0].schoolSubject.color" :src="getSrc(fach[0].schoolSubject.icon)">
                                        <div @click="() => clickDownloadTeacherUpload(el)" class="align-center pl-1 materialTitle">
                                            <p tabindex="0" class="mb-0" style="width: fit-content">{{el.title}}</p>
                                        </div>
                                        <div v-if="account.role == 'pupil'">
                                            <v-tooltip v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop())" :disabled="!showTooltips" top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" @click="() => play(el)" x-small class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                        <img :src="rechtsIcon" alt="Video abspielen" style="height: 24px;"/>
                                                    </v-btn>
                                                </template>
                                                <span>Video abspielen</span>
                                            </v-tooltip>

                                            <v-tooltip v-else-if="['wav', 'mp3', 'wma', 'm4a' , 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())" :disabled="!showTooltips" top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" @click="() => {playAudio(el, index); menu = false}" x-small class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                        <img :src="rechtsIcon" alt="Audio abspielen" style="height: 24px;"/>
                                                    </v-btn>
                                                </template>
                                                <span>Audio abspielen</span>
                                            </v-tooltip>

                                            <v-tooltip v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())" :disabled="!showTooltips" top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn aria-label="Vorschau" v-on="on" @click="openAnsicht(el.title, el.file, el._id, el.appointment)" x-small
                                                           class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                        <v-icon style="width: 20px; color: #939393">fas fa-eye</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Vorschau</span>
                                            </v-tooltip>

                                            <v-tooltip v-else :disabled="!showTooltips" top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" @click="fileNotSupported(el)" x-small class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                        <img :src="infoIcon" alt="Dateiformat nicht unterstützt" style="height: 24px;"/>
                                                    </v-btn>
                                                </template>
                                                <span>Dateiformat nicht unterstützt</span>
                                            </v-tooltip>
                                        </div>

                                        <div v-else-if="account.role == 'teacher'">
                                            <v-btn
                                                    v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop())"
                                                    @click="() => play(el)" x-small class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                <img :src="rechtsIcon" style="height: 20px;"/>
                                            </v-btn>
                                            <v-btn
                                                    v-else-if="['wav', 'mp3', 'wma', 'm4a' , 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                                    @click="() => {playAudio(el, index); menu = false}" x-small class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                <img :src="rechtsIcon" style="height: 24px;"/>
                                            </v-btn>
                                            <v-btn v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())" @click="(event) => {event.stopPropagation(); openAnsicht(el.title, el.file, el._id, el.appointment,'pupil' )}" x-small
                                                   class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                <v-icon style="width: 20px; color: #939393">fas fa-eye</v-icon>
                                            </v-btn>
                                            <v-btn
                                                    v-else
                                                    @click="fileNotSupported(el)" x-small class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                <img :src="infoIcon" style="height: 24px;"/>
                                            </v-btn>


                                            <v-btn
                                                @click="openDeleteDialog(el, 'teacher');" x-small
                                                class="ml-2 pa-0" elevation="0" style="height: 24px"
                                            >
                                                <img :src="papierkorbIcon" style="height: 20px">
                                            </v-btn>
                                        </div>

                                        <div>
                                            <v-tooltip :disabled="!showTooltips" top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" @click="() => clickDownloadTeacherUpload(el)" x-small elevation="0"
                                                           class="ml-2 pa-0" style="height: 24px">
                                                        <img :src="runterladenIcon" alt="Herunterladen" style="height: 20px;"/>
                                                    </v-btn>
                                                </template>
                                                <span>Herunterladen</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                </v-badge>
                            </div>

                            <div v-if="account.role == 'teacher'">
                                <div style="margin-left: 5%" class="pt-2 font-weight-bold greyText">
                                    Korrigiert:
                                </div>
                                <div v-for="(el, index) in pupileUploads.filter((element) => isCorrectionAvailable(element))" :key="el._id + index + 'correction'">
                                    <div class="pa-3 my-1 mx-auto" :style="'display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '">
                                        <img @click="() => clickDownloadPupilCorrection(el)" v-if="getSrc(fach[0].schoolSubject.icon)" class="iconSelect" :style="'cursor: pointer;background-color:' + fach[0].schoolSubject.color" :src="getSrc(fach[0].schoolSubject.icon)">
                                        <div @click="() => clickDownloadPupilCorrection(el)" class="pl-1" :style="'cursor: pointer;width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; '">
                                            {{el.title}}
                                        </div>

                                        <div>
                                            <v-btn v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                    dark @click="fileNotSupported(el)" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;" >
                                                <img :src="infoIcon" style="height: 20px; filter: brightness(1000%)"/>
                                            </v-btn>
                                            <v-btn v-else @click="openAnsichtTeacher(el.title, el.file, el._id, el.appointment, 'teacher')" x-small
                                                   class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                <v-icon style="width: 20px; color: #939393">fas fa-eye</v-icon>
                                            </v-btn>
                                        </div>

                                        <div>
                                            <v-btn
                                                   @click="clickDownloadPupilCorrection(el)" elevation="0" x-small
                                                   class="ml-2 pa-0" style="width: 20px; height: 24px">
                                                <img :src="bearbeitetIcon" style="height: 20px;"/>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                                <div style="margin-left: 5%" class="pt-2 font-weight-bold greyText">
                                    Unkorrigiert:
                                </div>
                                <div v-for="(el, index) in pupileUploads.filter((element) => !isCorrectionAvailable(element))" :key="el._id + index" >
                                    <v-badge
                                            color="red"
                                            content="1"
                                            :value="!el.seenByMe"
                                            overlap
                                            offset-x="40"
                                            offset-y="20"
                                            style="z-index: 8; width: 100%;"
                                    >
                                        <div class="pa-3 my-1 mx-auto" :style="'display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '">
                                            <img @click="() => clickDownloadPupilUpload(el)" v-if="getSrc(fach[0].schoolSubject.icon)" class="iconSelect" :style=" 'cursor:pointer; background-color:' + fach[0].schoolSubject.color" :src="getSrc(fach[0].schoolSubject.icon)">
                                            <div @click="() => clickDownloadPupilUpload(el)" style="height: 24px" class="d-flex align-center pl-1" :style="'cursor:pointer; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; '">
                                                {{el.title}}
                                            </div>

                                            <div>
                                                <v-btn
                                                        v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop())"
                                                        @click="() => playPupil(el)" x-small class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                    <img :src="rechtsIcon" style="height: 20px;"/>
                                                </v-btn>
                                                <v-btn
                                                        v-else-if="['wav', 'mp3', 'wma', 'm4a' , 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())"
                                                        @click="() => {playAudioPupil(el, index); menu = false}" x-small class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                    <img :src="rechtsIcon" style="height: 24px;"/>
                                                </v-btn>
                                                <v-btn v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                       @click="openAnsichtTeacher(el.title, el.file, el._id, el.appointment, 'teacher')" x-small
                                                       class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                    <v-icon style="width: 20px; color: #939393">fas fa-eye</v-icon>
                                                </v-btn>
                                                <v-btn
                                                        v-else
                                                        @click="fileNotSupported(el)" x-small class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                    <img :src="infoIcon" style="height: 24px;"/>
                                                </v-btn>
                                            </div>

                                            <div>
                                                <v-btn x-small elevation="0" class="ml-2 pa-0" style="width: 20px; height: 24px"
                                                       @click="() => clickDownloadPupilUpload(el)"
                                                >
                                                    <img
                                                        :src="offenIcon"
                                                        style="height: 20px"
                                                    />
                                                </v-btn>
                                            </div>
                                            <div>
                                                <v-btn @click="openDeleteDialog(el, 'pupil');" x-small elevation="0" class="ml-2 pa-0" style="width: 20px; height: 24px">
                                                    <img
                                                        :src="papierkorbIcon"
                                                        style="height: 20px"/>
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-badge>
                                </div>
                            </div>

                            <div v-else>
                                <div style="margin-left: 5%" class="pt-2 font-weight-bold greyText">
                                    <p tabindex="0" class="mb-0" style="width: fit-content;">Deine Abgaben:</p>
                                </div>
                                <div v-for="(el, index) in pupileUploads" :key="el._id + index + 'hand in'">
                                    <div class="pa-3 my-3 mx-auto" :style="'display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '">
                                        <img @click="() => clickDownloadPupilUpload(el)" v-if="getSrc(fach[0].schoolSubject.icon)" class="iconSelect" :style=" 'cursor:pointer; background-color:' + fach[0].schoolSubject.color" :src="getSrc(fach[0].schoolSubject.icon)">
                                        <div @click="() => clickDownloadPupilUpload(el)" class="pl-1" :style="'cursor:pointer; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; '">
                                            <p tabindex="0" class="mb-0" style="width: fit-content;">{{el.title}}</p>
                                        </div>
                                        <div style="display: inline-block">

                                            <v-tooltip v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop())" :disabled="!showTooltips" top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on=on @click="() => playPupil(el)" x-small class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                        <img :src="rechtsIcon" alt="Video abspielen" style="height: 24px;"/>
                                                    </v-btn>
                                                </template>
                                                <span>Video abspielen</span>
                                            </v-tooltip>

                                            <v-tooltip v-else-if="['wav', 'mp3', 'wma', 'm4a' , 'acc', 'ogg'].includes(el.title.split('.').pop().toLowerCase())" :disabled="!showTooltips" top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" @click="() => {playAudioPupil(el, index); menu = false}" x-small class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                        <img :src="rechtsIcon" alt="Audio abspielen" style="height: 24px;"/>
                                                    </v-btn>
                                                </template>
                                                <span>Audio abspielen</span>
                                            </v-tooltip>

                                            <v-tooltip v-else-if="['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(el.title.split('.').pop().toLowerCase())" :disabled="!showTooltips" top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn aria-label="Vorschau" v-on="on" @click="(event) => {event.stopPropagation(); openAnsicht(el.title, el.file, el._id, el.appointment,'teacher' )}"
                                                           class="btnStyle" x-small elevation="0" color="rgba(0,0,0,0)">
                                                        <!--                                                            <img :src="papierkorbIcon" style="height: 20px;"/>-->
                                                        <v-icon style="width: 24px; color: #939393">fas fa-eye</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Vorschau</span>
                                            </v-tooltip>

                                            <v-tooltip v-else :disabled="!showTooltips" top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" @click="fileNotSupported(el)" x-small class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                        <img :src="infoIcon" alt="Dateiformat nicht unterstützt" style="height: 24px;"/>
                                                    </v-btn>
                                                </template>
                                                <span>Dateiformat nicht unterstützt</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--inside Allgemein folder-->
                        <div v-else>
                            <input
                                    @change="() => subjectUploadInputChange()"
                                    id="subjectUploadInput"
                                    type="file"
                                    hidden
                                    ref="subjectUploadInput"
                            />

                            <div class="d-flex" style="justify-content: center" v-if="account.role == 'teacher'">
                                <v-btn @click="clickSubjectUploadButton" style="padding-left: 50px; padding-right: 50px; color: #707070; border-radius: 10px" class="text-capitalize" elevation="0">
                                    <img :src="hochladenIcon" style="height: 20px;" class="mr-2"/>
                                    Hochladen
                                </v-btn>
                            </div>

                            <div v-if="subjectFiles.length > 0">
                                <div v-for="(el, index) in subjectFiles" :key="el._id + index + 'comm'">
                                    <div class="pa-3 my-3 mx-auto" :style="'display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '">
                                        <div @click="() => clickDownloadSubjectUpload(el)" style="height: 24px" class="d-flex align-center" :style="'cursor: pointer; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; '">
                                            <p tabindex="0" class="mb-0" style="width: fit-content;">{{el.uploadedName}}</p>
                                        </div>

                                        <div style="height: 24px">
                                            <v-btn v-if="account.role == 'teacher'" @click="openDeleteDialog(el, 'subject');" x-small elevation="0"
                                                   class="ml-2 pa-0" style="width: 20px; height: 24px">
                                                <img :src="papierkorbIcon" style="height: 20px;"/>
                                            </v-btn>

                                            <v-tooltip :disabled="!showTooltips" top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" @click="() => clickDownloadSubjectUpload(el)" x-small elevation="0"
                                                           class="ml-2 pa-0" style="width: 20px; height: 24px">
                                                        <img :src="runterladenIcon" alt="Herunterladen" style="height: 20px;"/>
                                                    </v-btn>
                                                </template>
                                                <span>Herunterladen</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="px-4 my-3 mx-auto" style="color: #737373">
                                <p tabindex="0" class="mb-0" style="width: fit-content;">Hier gibts noch keine Dateien</p>
                            </div>

                        </div>
                    </div>

                    <!--inside Privater folder-->
                    <div v-else-if="!teacherFolder" class="my-3">
                        <input
                                @change="() => privateUploadInputChange()"
                                id="privateUploadInput"
                                type="file"
                                hidden
                                ref="privateUploadInput"
                        />

                        <div class="d-flex justify-center">
                            <v-progress-linear
                                    id="progressbar"
                                    :value="privateFileProgress"
                                    height="20px"
                                    style="width: 90%; border-radius: 10px"
                                    class="mx-3 my-3"
                                    color="primary"
                            >
                                {{ Math.ceil(privateFileProgress * (account.role == 'teacher' ? 10 : 1)) }}MiB / {{account.role == 'teacher' ? '1000MiB' : '100MiB'}}
                            </v-progress-linear>
                        </div>

                        <div class="d-flex" style="justify-content: center">
                            <v-btn @click="clickPrivateUploadButton" style="padding-left: 50px; padding-right: 50px; color: #707070; border-radius: 10px;" class="text-capitalize" elevation="0">
                                <img :src="hochladenIcon" alt="" style="height: 20px;" class="mr-2"/>
                                Hochladen
                            </v-btn>
                        </div>

                        <div v-for="(el, index) in privateFiles" :key="el._id + index + 'private'" >
                            <div class="pa-3 my-3 mx-auto" :style="'display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '">
                                <div @click="() => clickDownloadPrivateUpload(el)" :style="'cursor: pointer; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; '">
                                    <p tabindex="0" class="mb-0" style="width: fit-content">{{el.uploadedName}}</p>
                                </div>

                                <div>
                                    <v-btn v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.uploadedName.split('.').pop().toLowerCase())"
                                            dark @click="fileNotSupported(el)" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;">
                                        <img :src="infoIcon" style="height: 20px; filter: brightness(1000%)"/>
                                    </v-btn>
                                    <v-tooltip v-else :disabled="!showTooltips" top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn aria-label="Vorschau" v-on="on" @click="(event) => {event.stopPropagation(); openPrivateAnsicht(el)}" class="btnStyle" x-small elevation="0" color="rgba(0,0,0,0)">
                                                <!--                                                            <img :src="papierkorbIcon" style="height: 20px;"/>-->
                                                <v-icon style="width: 20px; color: #939393">fas fa-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Vorschau</span>
                                    </v-tooltip>

                                    <v-tooltip :disabled="!showTooltips" top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" @click="openDeleteDialog(el, 'private');" x-small elevation="0"
                                                   class="ml-2 pa-0" style="width: 20px; height: 20px">
                                                <img :src="papierkorbIcon" alt="Löschen" style="height: 20px;"/>
                                            </v-btn>
                                        </template>
                                        <span>Löschen</span>
                                    </v-tooltip>

                                    <v-tooltip :disabled="!showTooltips" top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" @click="() => clickDownloadPrivateUpload(el)" x-small elevation="0"
                                                   class="ml-2 pa-0" style="width: 20px; height: 20px">
                                                <img :src="runterladenIcon" alt="Herunterladen" style="height: 20px;"/>
                                            </v-btn>
                                        </template>
                                        <span>Herunterladen</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--inside Teacher folder-->
                    <div v-else class="my-3">
                        <div class="d-flex" style="justify-content: center">
                            <v-btn @click="clickTeacherFolderUploadButton" style="padding-left: 50px; padding-right: 50px; color: #707070; border-radius: 10px;" class="text-capitalize" elevation="0">
                                <img :src="hochladenIcon" style="height: 20px;" class="mr-2"/>
                                Hochladen
                            </v-btn>
                        </div>

                        <div v-for="(el, index) in teacherFiles" :key="el._id + index + 'teacher'">
                            <div class="pa-3 my-3 mx-auto" :style="'display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '">
                                <div @click="() => clickDownloadTeacherFolderUpload(el.file)" :style="'cursor: pointer; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; '">
                                    {{el.file.uploadedName}}
                                </div>

                                <div>
                                    <v-btn @click="() => clickDeleteTeacherFolderUpload(el.file)" x-small elevation="0"
                                           class="ml-2 pa-0" style="width: 20px; height: 20px">
                                        <img :src="papierkorbIcon" style="height: 20px;"/>
                                    </v-btn>
                                    <v-btn @click="() => clickDownloadTeacherFolderUpload(el.file)" x-small elevation="0"
                                           class="ml-2 pa-0" style="width: 20px; height: 20px">
                                        <img :src="runterladenIcon" style="height: 20px;"/>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-custom-scrollbar>
            </div>
        </v-row>

        <!-- popups, overlays and snackbars -->
        <v-dialog v-model="deleteDialog" max-width="350px">
            <v-card>
                <v-card-title>
                    Löschen bestätigen
                </v-card-title>

                <v-card-text>
                    Wollen Sie die Datei wirklich <strong>unwiderruflich</strong> löschen?
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn @click="confirmDelete" class="mr-2 text-capitalize" color="rot" dark>
                        <img :src="papierkorbIcon" style="height: 20px; filter: brightness(1000%)" class="mr-2">
                        Löschen
                    </v-btn>

                    <v-btn @click="deleteDialog = false" class="mr-2 text-capitalize" color="gruen" dark>
                        <img :src="schliessenIcon" style="height: 20px; filter: brightness(1000%)" class="mr-2">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay opacity="0.95" v-if="videoUrl" z-index="95">
            <v-icon large style="position: fixed; top: 50px; right: 50px; z-index: 99" @click="videoUrl = false" dark>
                fas fa-times
            </v-icon>
            <video :src="videoUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"
                   :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">
                Your browser does not support the video tag.
            </video>
        </v-overlay>

        <v-overlay opacity="0.95" v-if="audioUrl" z-index="95">
            <v-icon large style="position: fixed; top: 50px; right: 50px; z-index: 99" @click="audioUrl = false" dark>
                fas fa-times
            </v-icon>
            <audio :src="audioUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"
                   :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">
                Your browser does not support the video tag.
            </audio>
        </v-overlay>

        <v-snackbar bottom v-model="snackbar" color="error" timeout="3000">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbar = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon" />
            </v-btn>
          </template>
            {{snackbarText}}
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarDelete" :color="snackbarDeleteColor" timeout="3000">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbarDelete = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon" />
            </v-btn>
          </template>
            {{ snackbarDeleteText }}
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarFileNotSupported" color="grey" timeout="5000" style="z-index: 9999 !important">
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                small
                @click="snackbarFileNotSupported = false"
            >
              <img style="max-width: 20px" alt="Schließen" :src="abbrechenIcon" />
            </v-btn>
          </template>
            Dieses Dateiformat kann in eKlara nicht bearbeitet werden. Bitte lade die Datei herunter.
        </v-snackbar>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import * as backend from "../api/backend";
import vueCustomScrollbar from "vue-custom-scrollbar";

import fileIcon from '../assets/Icons/leere-korrektur-78.svg'
import bearbeitenIcon from '../assets/Icons/bearbeiten-normal-41.svg'
import rechtsIcon from '../assets/Icons/rechts-11.svg'
import offenIcon from "../assets/Icons/offen-18.svg";
import hochladenIcon from "../assets/Icons/hochladen-15.svg";
import bearbeitetIcon from '../assets/Icons/bearbeitet-17.svg'
import abbrechenIcon from "../assets/Icons/abbrechen-08.svg";
import runterladenIcon from '../assets/Icons/runterladen-14.svg'
import schlossIcon from '../assets/Icons/passwort-weiß-02.svg'
import papierkorbIcon from '../assets/Icons/papierkorb-110.svg'
import infoIcon from "../assets/Icons/info-45.svg";
import infoRoundIcon from "../assets/Icons/info-2-weiß-89.svg";
import schliessenIcon from "../assets/Icons/abbrechen-08.svg";

import bioIcon from '../assets/Icons/f-biologie-weiss-35.svg'
import chemieIcon from '../assets/Icons/f-chemie-weiss-37.svg'
import deutschIcon from '../assets/Icons/f-deutsch-weiss-29.svg'
import englischIcon from '../assets/Icons/f-englisch-weiss-30.svg'
import erdkundeIcon from '../assets/Icons/f-erdkunde-weiss-31.svg'
import geschichteIcon from '../assets/Icons/f-geschichte-weiss-40.svg'
import matheIcon from '../assets/Icons/f-mathe-weiss-32.svg'
import musikIcon from '../assets/Icons/f-musik-weiss-82.svg'
import physikIcon from '../assets/Icons/f-physik-weiss-36.svg'
import politikIcon from '../assets/Icons/f-politik-weiss-34.svg'
import religionIcon from '../assets/Icons/f-religion-weiss-39.svg'
import sportIcon from '../assets/Icons/f-sport-weiss-38.svg'
import wirtschaftIcon from '../assets/Icons/f-wirtschaft-weiss-33.svg'

import ProgressBar from "@/components/ProgressBar";
import NameFile from "@/components/NameFile";
import account from "../store/modules/account";

export default {
name: "FileWidget",
    components: {
        ProgressBar,
        NameFile,
        vueCustomScrollbar,
    },
    computed: {
        ...mapState("auth", [ "token", "account"]),
        ...mapGetters('util', [ 'currentUploadGroup' ]),
        ...mapState('translation', ['targetLang',]),
    },
    watch: {
        currentUploadGroup(newVal, oldVal) {
          if (!newVal && oldVal) {
              if (oldVal === 'subject') {
                  this.requestSubjectFolder();
              }

              if (oldVal === 'private') {
                  this.requestPrivateFolder();
              }

              if (oldVal === 'teacherFolder') {
                  this.requestTeacherFolder();
              }
          }
        },
    },
    props: {
        showTooltips: {required: false, default: true},
    },
    data() {
        return {
            monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni",
                "Juli", "August", "September", "Oktober", "November", "Dezember"
            ],

            person: {
                accessibility: {
                    screenreader: false,
                }
            },

            windowWidth: window.innerWidth,

            appointments: [],

            schoolSubjectGroups: [],

            subjectMonthGroups: [],
            subjectWeekGroups: [],
            subjectDateGroups: [],
            teacherUploads: [],
            pupileUploads: [],

            fach: null,

            privateFolder: false,
            teacherFolder: false,
            subjectFolder: false,

            videoUrl: null,
            audioUrl: null,

            day: null,

            month: null,

            week: null,
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false
            },

            snackbar: false,
            snackbarText: '',
            snackbarDelete: false,
            snackbarDeleteText: '',
            snackbarDeleteColor: 'success',
            snackbarFileNotSupported: false,

            deleteDialog: false,
            elementToDelete: null,
            deleteMethod: '',

            fileIcon,
            bearbeitenIcon,
            rechtsIcon,
            runterladenIcon,
            bearbeitetIcon,
            abbrechenIcon,
            offenIcon,
            hochladenIcon,
            schlossIcon,
            papierkorbIcon,
            infoIcon,
            infoRoundIcon,
            schliessenIcon,

            bioIcon,
            chemieIcon,
            deutschIcon,
            englischIcon,
            erdkundeIcon,
            geschichteIcon,
            matheIcon,
            musikIcon,
            physikIcon,
            politikIcon,
            religionIcon,
            sportIcon,
            wirtschaftIcon,

            showPrivateUploadProgress: false,
            privateUploadProgress: 0.0,
            privateXmlHttpRequest: null,

            showSubjectUploadProgress: false,
            subjectUploadProgress: 0.0,
            subjectXmlHttpRequest: null,

            showNameFile: false,
            uploadFileName: "",
            privateFiles: [],
            privateFileSize: 0,
            privateFileProgress : 0,
            teacherFiles: [],
            fileNameCallback: () => {},
            teacherId: '',
            subjectFiles: [],
            slots: [
            ],
        };
    },
    beforeCreate() {
        Date.prototype.getWeek = function() {
            var date = new Date(this.getTime());
            date.setHours(0, 0, 0, 0);
            // Thursday in current week decides the year.
            date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
            // January 4 is always in week 1.
            var week1 = new Date(date.getFullYear(), 0, 4);
            // Adjust to Thursday in week 1 and count number of weeks from date to week1.
            return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                - 3 + (week1.getDay() + 6) % 7) / 7);
        }
    },
    async mounted() {
        this.requestAppointments();
        this.requestPrivateFolder();
        this.requestTeacherFolder();
        this.requestSlots();
        this.requestPerson();
    },
    methods: {
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions("timeslots", ["getSlots"]),
        ...mapActions("privateFolder", ["getPrivateFolder", "deletePrivateFile"]),
        ...mapActions("teacherFolder", ["getTeacherFolder", "deleteTeacherFile"]),
        ...mapActions("subjectFolder", ["getSubjectFolder", "deleteSubjectFile"]),
        ...mapActions("util", ['toggleLoading', 'toggleOpenAppointment', 'toggleCurrentUploadGroup']),
        ...mapActions('files', [ 'setSnackbar' ]),
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation', 'translateToTargetLang' ]),
        ...mapActions('account', [ 'getCurrentAccount' ]),
        ...mapActions('pupils', [ 'getMePupil' ]),

        async requestPerson() {
            if(this.account.role === 'pupil') {
                this.person = await this.getMePupil();
            }
        },

        getTeacherDisplayName(teacher) {
            let prefix, name, lastName, wholeName, nameList = '';

            for (let i = 0; i < teacher.length; i++) {

                if (teacher[i].gender === 'm') {
                    prefix = 'Hr. ';
                } else if (teacher[i].gender === 'w') {
                    prefix = 'Fr. ';
                }

                if (teacher[i].name) {
                    name = teacher[i].name.slice(0, 1) + '. ';
                }

                lastName = teacher[i].lastName;
                wholeName = prefix + name + lastName;

                if (teacher.length > 1 && i > 0) {
                    nameList = nameList + ', ' + wholeName;
                }
                else {
                    nameList = wholeName;
                }
            }

            return nameList;
        },
        async requestPrivateFolder() {
            this.privateFiles = await this.getPrivateFolder();
            this.privateFileSize = 0;
            this.privateFiles.forEach((el) => {
                this.privateFileSize += Number(el.size);
            })
            if(this.account.role == 'teacher') {
                this.privateFileProgress = this.privateFileSize / 1048576000 * 100;
            } else {
                this.privateFileProgress = this.privateFileSize / 104857600 * 100;
            }
        },
        async requestTeacherFolder() {
            this.teacherFiles = await this.getTeacherFolder();
            console.log(this.teacherFiles)
        },
        async requestSlots() {
            this.slots = await this.getSlots();
        },

        getSlotTime(slotId) {
            const temp = this.slots.find((el) => el._id === slotId)
            return ("0" + new Date(temp.start).getHours()).slice(-2) + ":" + ("0" + new Date(temp.start).getMinutes()).slice(-2) + '-' +("0" + new Date(temp.end).getHours()).slice(-2) + ":" + ("0" + new Date(temp.end).getMinutes()).slice(-2);
        },
        selectSubjectFolder(teacherId){
            this.setFocusOn('currentPath');
            this.teacherId = teacherId;
            this.subjectFolder = true;
            this.requestSubjectFolder();
        },
        async requestSubjectFolder() {
            this.subjectFiles = await this.getSubjectFolder({
                subjectId: this.fach[0].schoolSubject._id,
                teacherId: this.teacherId
            });
        },

        getSrc(icon) {
            return this[icon];
        },
        fileNotSupported(el) {
            this.snackbarFileNotSupported = true;
        },
        count(el) {
            let returnValue = 0;
            if(this.account.role == 'teacher') {
                el.forEach((element) => {
                    returnValue += element.pupilUpload.filter((file) => !file.seenByMe).length;
                });
            } else {
                el.forEach((element) => {
                    returnValue += element.teacherUploads.filter((file) => !file.seenByMe).length
                });
            }
            return returnValue;
        },

        getColor(){
          return this.thisSubject ? this['color' + this.thisSubject.color.substring(1)] : (this.thisAppointment ? this['color' + this.thisAppointment.schoolSubject.color.substring(1)] : this.colorb2b2b2)
        },

        async clickDownloadTeacherUpload(file) {
            try {
                file.seenByMe = true;
                const res = await backend.getTeacherUpload(file.appointment, file.file);
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = file.title;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            }catch (e) {
                console.log(e);
            }
        },

        async clickDeleteUpload(subject) {
            let file = this.elementToDelete;
            const res = await backend.deleteUpload((file._id).toString());

            if (res.status === 204) {
                this.snackbarDeleteText = "Datei " + file.title + " erfolgreich gelöscht"
                this.snackbarDelete = true;

                //if delete pupil upload
                if (subject === 'pupil') {
                    let pos = this.pupileUploads.indexOf(file);
                    this.pupileUploads.splice(pos, 1);
                } else if (subject === 'teacher'){ //else delete teacher upload
                    let pos = this.teacherUploads.indexOf(file);
                    this.teacherUploads.splice(pos, 1);
                } else {
                  console.log('something went wrong in clickDeleteUpload');
                }

                await this.requestAppointments();
                await this.selectSubject(this.schoolSubjectGroups.find((el) => {
                    return el[0].schoolSubject._id == this.fach[0].schoolSubject._id
                }));
            } else {
                this.snackbarDeleteText = "Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!"
                this.snackbarDeleteColor = 'error';
                this.snackbarDelete = true;
            }
        },

        async clickDownloadPupilUpload(file) {
            try {
                file.seenByMe = true;
                const res = await backend.getPupilUpload(
                    file.appointment, file.file
                );
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = url;
                a.download = file.title;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove(); //afterwards we remove the element again
            }catch (e) {
                console.log(e);
            }
        },

        async clickDownloadPupilCorrection(file) {
            let teacherCorrection = null;

            for (let i = 0; i < this.teacherUploads.length; i++) {
                if (this.teacherUploads[i].editParent) {
                    const index = file.editLeafs.indexOf(this.teacherUploads[i]._id);
                    if (index !== -1) {
                        teacherCorrection = this.teacherUploads[i];
                    }
                }
            }

            if (teacherCorrection) {
                teacherCorrection.seenByMe = true;

                const res = await backend.getTeacherUpload(file.appointment, teacherCorrection.file);

                fetch(res.url, {
                    method: 'GET',
                    headers: new Headers({
                        "Authorization": "Bearer " + this.token
                    })
                })
                    .then(response => response.blob())
                    .then(blob => {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.href = url;
                        a.download = teacherCorrection.title;
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click();
                        a.remove();  //afterwards we remove the element again
                    });

            }
        },

        async requestAppointments() {
            this.appointments = await this.getAppointments();
            this.schoolSubjectGroups = this.groupBy(this.appointments, 'schoolSubject');
        },

        async selectSubject(subject) {
            this.setFocusOn('currentPath');
            this.fach = subject;
            this.subjectMonthGroups = this.groupByMonth(this.fach);


            this.subjectMonthGroups.sort((a, b) => {
                let keyA = new Date(a[0].day),
                    keyB = new Date(b[0].day);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
        },

        async selectMonth(month) {
            this.setFocusOn('currentPath');
            this.month = month;

            this.subjectWeekGroups = this.groupByWeek(this.month);

            this.subjectWeekGroups.sort((a, b) => {
                let keyA = new Date(a[0].day),
                    keyB = new Date(b[0].day);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
        },

        async selectWeek(week) {
            this.setFocusOn('currentPath');
            this.week = week;

            this.subjectDateGroups = this.groupBy(this.week, 'day');

            this.subjectDateGroups.sort((a, b) => {
                let keyA = new Date(a[0].day),
                    keyB = new Date(b[0].day);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
        },

        async selectDay(day) {
            this.setFocusOn('currentPath');
            this.day = day;
            this.teacherUploads = [];
            this.pupileUploads = [];
            day.forEach((el) => {
                el.teacherUploads.forEach((element) => {
                    element['appointment'] = el._id
                });
                el.pupilUpload.forEach((element) => {
                    element['appointment'] = el._id
                });
                this.teacherUploads = this.teacherUploads.concat(el.teacherUploads);
                this.pupileUploads = this.pupileUploads.concat(el.pupilUpload);
            });
        },

        openEdit(name, id, uploadId, appointmentId) {
            const group = this.fach[0].schoolSubject.name;
            const color = this.fach[0].schoolSubject.color;
            const fileTitle = name;
            const fileId = id;

            this.$router.push({
                name: 'schueler.edit',
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: fileTitle,
                        fId: fileId,
                        uploadid: uploadId
                    }
            });
        },

        openAnsicht(name, id, uploadId, appointmentId, userGroup) {
            const group = this.fach[0].schoolSubject.name;
            const color = this.fach[0].schoolSubject.color;
            const fileTitle = name;
            const fileId = id;

            this.$router.push({
                name: 'schueler.view',
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: fileTitle,
                        fId: fileId,
                        uploadid: uploadId,
                        ug: userGroup,
                        correction: true,
                    }
            });
        },

        openEditTeacher(name, id, uploadId, appointmentId) {
            const group = this.fach[0].schoolSubject.name;
            const color = this.fach[0].schoolSubject.color;
            const fileTitle = name;
            const fileId = id;

            this.$router.push({
                name: 'lehrer.edit',
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: fileTitle,
                        fId: fileId,
                        uploadid: uploadId
                    }
            });
        },

        openAnsichtTeacher(name, id, uploadId, appointmentId, ug) {
            const group = this.fach[0].schoolSubject.name;
            const color = this.fach[0].schoolSubject.color;
            const fileTitle = name;
            const fileId = id;
            this.$router.push({
                name: 'lehrer.view',
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: fileTitle,
                        fId: fileId,
                        uploadid: uploadId,
                        correction: true,
                        ug: ug
                    }
            });
        },

        isCorrectionAvailable(pupilFile) {
            for (let i = 0; i < this.teacherUploads.length; i++) {
                if (this.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(
                        this.teacherUploads[i]._id
                    );
                    if (index !== -1) {
                        return true;
                    }
                }
            }
            return false;
        },

        getCurrentCorrection(pupilFile) {
            for (let i = 0; i < this.teacherUploads.length; i++) {
                if (this.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(this.teacherUploads[i]._id);
                    if (index !== -1) {
                        return this.teacherUploads[i];
                    }
                }
            }
            return null;
        },


        async play(file) {
            file.seenByMe = true;
            const res = await backend.getTeacherUpload(file.appointment, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    this.videoUrl = url;

                });
        },

        async playPupil(file) {
            file.seenByMe = true;
            const res = await backend.getPupilUpload(file.appointment, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    this.videoUrl = url;

                });
        },

        async playAudio(file) {
            file.seenByMe = true;
            const res = await backend.getTeacherUpload(file.appointment, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    this.audioUrl = url;

                });
        },

        async playAudioPupil(file) {
            file.seenByMe = true;
            const res = await backend.getPupilUpload(file.appointment, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    this.audioUrl = url;

                });
        },

        getFachByTeacher() {
            console.log(this.account.role === 'teacher')
            const temp = this.groupBy(this.fach, 'teacher');
            let returnValue = [];
            for(let i = 0; i< temp.length; i++) {
                if(temp[i][0].teachers.length === 1) {
                    if((this.account.role !== 'teacher' || temp[i][0].teachers[0].account === this.account._id) && !this.contains(returnValue, temp[i])) {
                        returnValue.push(temp[i]);
                    }
                } else {
                    temp[i][0].teachers.forEach((el, index) => {
                        let temp2 = JSON.parse(JSON.stringify(temp[i]));
                        temp2[0].teachers = [temp2[0].teachers[index]];
                        if((this.account.role !== 'teacher' || temp2[0].teachers[0].account === this.account._id) && !this.contains(returnValue, temp2)) {
                            returnValue.push(temp2);
                        }
                    })
                }
            }
            return returnValue;
        },
        contains(a, obj) {
            let i = a.length;
            while (i--) {
                if (a[i][0].teachers[0].account === obj[0].teachers[0].account) {
                    return true;
                }
            }
            return false;
        },
        groupBy(collection, prop) {
            let i = 0, val, index,
                values = [], result = [];
            for (; i < collection.length; i++) {
                val = collection[i][prop];
                index = values.findIndex((el) => {
                    return JSON.stringify(el) === JSON.stringify(val)
                });
                if (index > -1)
                    result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        },

        groupByMonth(collection) {
            let i = 0, val, index,
                values = [], result = [];
            for (; i < collection.length; i++) {
                val = collection[i]['day'];
                index = values.findIndex((el) => {
                    return JSON.stringify(new Date(el).getMonth()) === JSON.stringify(new Date(val).getMonth())
                });
                if (index > -1)
                    result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        },

        groupByWeek(collection) {
            let i = 0, val, index,
                values = [], result = [];
            for (; i < collection.length; i++) {
                val = collection[i]['day'];
                index = values.findIndex((el) => {
                    return JSON.stringify(new Date(el).getWeek()) === JSON.stringify(new Date(val).getWeek())
                });
                if (index > -1)
                    result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        },

        async clickDownloadTeacherFolderUpload(file) {
            const res = await backend.getTeacherFolderUpload(
                file._id
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = file.uploadedName;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove(); //afterwards we remove the element again
                });
        },

        async clickDownloadPrivateUpload(file) {
            const res = await backend.getPrivateUpload(
                file._id
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = file.uploadedName;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove(); //afterwards we remove the element again
                });
        },

        openPrivateAnsicht(file) {
            console.log('openPrivateAnsicht called with file', file);

            const fileTitle = file.uploadedName;
            const fileId = file._id;
            const userGroup = 'private';

            let route = 'lehrer.view';
            if (this.account.role === 'pupil') {
                route = 'schueler.view';
            }

            this.$router.push({
                name: route,
                query:
                    {
                        color: '#6996cd',
                        title: fileTitle,
                        fId: fileId,
                        ug: userGroup
                    }
            });
        },

        async clickDeletePrivateUpload() {
            let file = this.elementToDelete;
            await this.deletePrivateFile(file._id)
            this.requestPrivateFolder();
        },

        async clickDeleteTeacherFolderUpload(file) {
            await this.deleteTeacherFile(file._id)
            this.requestTeacherFolder();
        },

        clickPrivateUploadButton() {
            this.toggleOpenAppointment(null);
            this.toggleCurrentUploadGroup('private');
            document.getElementById("uploadInput").click();
        },
        clickTeacherFolderUploadButton() {
            this.toggleOpenAppointment(null);
            this.toggleCurrentUploadGroup('teacherFolder');
            document.getElementById("uploadInput").click();
        },

        async privateUploadInputChange() {
            const input = this.$refs.privateUploadInput;
            const file = input.files[0];
            if (file) {
                const name = file.name;
                const lastDot = name.lastIndexOf(".");
                const fileName = name.substring(0, lastDot);
                const fileExtension = name.substring(lastDot + 1);
                this.uploadFileName = fileName;
                this.fileNameCallback = (newFileName) => {
                    this.showNameFile = false;
                    this.uploadFile( newFileName, fileExtension);
                };
                this.showNameFile = true;
            }
        },

        async uploadFile( newFileName, fileExtension) {
            const input = this.$refs.privateUploadInput;
            const file = input.files[0];
            if (file) {

                if (file.size > 1000000000) {
                    this.setSnackbar({ type: 'bigFileToBig', value: 'true' });
                    return;
                }

                if ((this.account.role === 'teacher' && (file.size + this.privateFileProgress) > 1000000000)
                    || (this.account.role === 'pupil' && (file.size + this.privateFileProgress) > 100000000)) {
                    this.setSnackbar({ type: 'fileDoesntFitInPrivateFolder', value: 'true' });
                    return;
                }

                const formData = new FormData();
                formData.append("file", file, `${newFileName}.${fileExtension}`);

                this.privateXmlHttpRequest = backend.postPrivateUpload(
                    file
                );

                this.privateXmlHttpRequest.onerror = (e) => {
                    console.error("Private upload error:", e);
                    this.showPrivateUploadProgress = false;
                    this.privateXmlHttpRequest = null;
                    this.$refs.privateUploadInput.value = "";
                };

                this.privateXmlHttpRequest.onabort = (e) => {
                    console.warn("Private upload aborted");
                    this.showPrivateUploadProgress = false;
                    this.privateXmlHttpRequest = null;
                    this.$refs.privateUploadInput.value = "";
                };

                this.privateXmlHttpRequest.upload.addEventListener("progress", (e) => {
                    this.privateUploadProgress = (e.loaded / e.total) * 100;
                });

                this.privateXmlHttpRequest.addEventListener("load", (e) => {
                    if (this.privateXmlHttpRequest.status !== 201) {
                        if(this.privateXmlHttpRequest.status === 409) {
                            this.snackbarText = 'Speicher überschritten. Datei konnte nicht gespeichert werden!';
                            this.snackbar = true;
                        }

                        console.error(
                            "Private upload failed:",
                            this.privateXmlHttpRequest.response
                        );
                    }

                    this.showPrivateUploadProgress = false;
                    this.snackbarSave = true;
                    this.requestPrivateFolder();
                    this.privateXmlHttpRequest = null;
                    this.$refs.privateUploadInput.value = "";
                });
                this.privateUploadProgress = 0.0;
                this.showPrivateUploadProgress = true;
                this.privateXmlHttpRequest.send(formData);
            }
        },

        async clickDownloadSubjectUpload(file) {
            const res = await backend.getSubjectFolderUpload(
                file._id
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = file.uploadedName;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove(); //afterwards we remove the element again
                });
        },

        async clickDeleteSubjectUpload() {
            let file = this.elementToDelete;
            await this.deleteSubjectFile({
                subjectId: this.fach[0].schoolSubject._id,
                teacherId: this.teacherId,
                fileId: file._id
            })
            this.requestSubjectFolder();
        },

        clickSubjectUploadButton() {
            this.toggleOpenAppointment(this.fach[0].schoolSubject);
            this.toggleCurrentUploadGroup('subject');
            document.getElementById("uploadInput").click();
        },

        async subjectUploadInputChange() {
            const input = this.$refs.subjectUploadInput;
            const file = input.files[0];
            if (file) {
                const name = file.name;
                const lastDot = name.lastIndexOf(".");
                const fileName = name.substring(0, lastDot);
                const fileExtension = name.substring(lastDot + 1);
                this.uploadFileName = fileName;
                this.fileNameCallback = (newFileName) => {
                    this.showNameFile = false;
                    this.uploadSubjectFile( newFileName, fileExtension);
                };
                this.showNameFile = true;
            }
        },

        async uploadSubjectFile( newFileName, fileExtension) {
            const input = this.$refs.subjectUploadInput;
            const file = input.files[0];
            if (file) {

                if (file.size > 1000000000) {
                    this.setSnackbar({ type: 'bigFileToBig', value: 'true' });
                    return;
                }

                const formData = new FormData();
                formData.append("file", file, `${newFileName}.${fileExtension}`);

                this.subjectXmlHttpRequest = backend.postSubjectFolderUpload(
                    this.fach[0].schoolSubject._id,
                    file
                );

                this.subjectXmlHttpRequest.onerror = (e) => {
                    console.error("Private upload error:", e);
                    this.showSubjectUploadProgress = false;
                    this.subjectXmlHttpRequest = null;
                    this.$refs.subjectUploadInput.value = "";
                };

                this.subjectXmlHttpRequest.onabort = (e) => {
                    console.warn("Private upload aborted");
                    this.showSubjectUploadProgress = false;
                    this.subjectXmlHttpRequest = null;
                    this.$refs.subjectUploadInput.value = "";
                };

                this.subjectXmlHttpRequest.upload.addEventListener("progress", (e) => {
                    this.subjectUploadProgress = (e.loaded / e.total) * 100;
                });

                this.subjectXmlHttpRequest.addEventListener("load", (e) => {
                    if (this.subjectXmlHttpRequest.status !== 201) {
                        if(this.subjectXmlHttpRequest.status === 409) {
                            this.snackbarText = 'Speicher überschritten. Datei konnte nicht gespeichert werden!';
                            this.snackbar = true;
                        }

                        console.error(
                            "Private upload failed:",
                            this.subjectXmlHttpRequest.response
                        );
                    }

                    this.showSubjectUploadProgress = false;
                    this.snackbarSave = true;
                    this.requestSubjectFolder();
                    this.subjectXmlHttpRequest = null;
                    this.$refs.subjectUploadInput.value = "";
                });
                this.subjectUploadProgress = 0.0;
                this.showSubjectUploadProgress = true;
                this.subjectXmlHttpRequest.send(formData);
            }
        },

        async readWidgetInfoText() {
            if(!this.person.accessibility.screenreader) {
                if ('speechSynthesis' in window) {
                    // Speech Synthesis supported 🎉
                } else {
                    // Speech Synthesis Not Supported 😣
                    alert("Sorry, your browser doesn't support text to speech!");
                    return
                }

                let acc = await this.getCurrentAccount();
                let text;
                if (acc.role === 'pupil') {
                    text = 'Im Dokumentenspeicher werden alle Dokumente von dir und deinem Lehrer gespeichert.';
                } else {
                    text = 'Im Dokumentenspeicher werden alle Dokumente von Ihnen und Ihren Schülern gespeichert.';
                }

                if (window.speechSynthesis.speaking) {
                    window.speechSynthesis.cancel();
                    this.showTranslation(false);
                } else {
                    if (this.targetLang !== 'de') {
                        text = await this.translateToTargetLang({
                            targetLang: this.targetLang, textToTranslate: text
                        });
                    }

                    this.setTranslatedText(text);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000)

                    let msg = new SpeechSynthesisUtterance();
                    msg.text = text;
                    if (this.isLangPackageAvailable()) {
                        msg.lang = this.targetLang;
                        window.speechSynthesis.speak(msg);
                    }
                }
            }
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },

        setFocusOn(id) {
            setTimeout(() => {
                console.log("SetFocus");
                document.getElementById(id).focus();
            }, 10);
        },

        // open delete dialog but also save which file to delete and with which method
        openDeleteDialog(file, method) {
            this.deleteDialog = true;
            this.elementToDelete = file;
            this.deleteMethod = method;
        },

        confirmDelete() {
            switch (this.deleteMethod) {
                case 'private':
                    this.clickDeletePrivateUpload();
                    break;
                case 'subject':
                    this.clickDeleteSubjectUpload();
                    break;
                case 'pupil':
                    this.clickDeleteUpload('pupil');
                    break;
                case 'teacher':
                    this.clickDeleteUpload('teacher');
                    break;
                default: console.log('something went wrong in the confirmDelete method');
            }

            this.deleteDialog = false;
            this.elementToDelete = null;
            this.deleteMethod = '';
        },
    }
}
</script>

<style lang="scss" scoped>

.whiteBg {
    background-color: #ffffff;
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 75px);
}

h1 {
  display: inherit;
  font-size: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
  margin-left: inherit;
  margin-right: inherit;
  font-weight: inherit;
}

.iconSelect {
    display: inline-flex;
    width: 24px;
    height: 24px;
    padding: 2px;
    margin-bottom: -6px;
    border-radius: 5px;
    margin-right: 2px;
    background-color: rgba(234, 234, 234, 0.70);
}

.greyText {
    color: #707070;
}

/*.btnStyle {
    min-width: 36px;
    min-height: 36px;
    margin-top: -3px;
}*/

.materialTitle {
    cursor: pointer;
    height: 24px !important;
    width: 70%;
    padding-top: 4px;
    padding-right: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
</style>
