<template>
    <div>
        <v-overlay v-if="chatOpen" opacity="0.80" class="overlay"></v-overlay>
        <div id="container">
            <v-badge
                    color="red"
                    :content="newMessagesCount"
                    :value="newMessagesAvailable > 10 ? '10+': newMessagesAvailable"
                    overlap
                    style="z-index: 9; width: 100%"
            >
                <v-tooltip :value="shortcutTooltips" :disabled="!showTooltips" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                                id="ChatWidgetButton"
                                v-on="on"

                                @click="chatOpen = !chatOpen"
                                style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                                x-small elevation="0"
                                class="ml-4"
                                :class="{chatClicked: chatOpen }"
                        >
                            <img :src="chatIconGruen" style="max-width: 25px; max-height: 25px;" :class="{iconToWhite: chatOpen}" :alt="chatOpen ? 'Chat schließen' : 'Chat öffnen'"/>
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span><span style="text-decoration: underline white">C</span>hat (Alt + C)</span>
                        <img v-if="hoeren" :src="chatMetacom" width="100" style="margin: auto">
                    </div>
                </v-tooltip>
                <BabyChat @updateMessageCount="updateMessageCount" id="ChatWidget" v-show="chatOpen"></BabyChat>
            </v-badge>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

import vueCustomScrollbar from "vue-custom-scrollbar";
import chatIcon from '../../assets/Icons/chat-alternative-filled-102.svg';
import chatIconGruen from '../../assets/Icons/chat-alternative-gruen-102.svg';
import chatMetacom from '../../assets/METACOM/Chat/chatchatten.png'

import BabyChat from "@/components/Schueler/BabyChatWidget";

export default {
name: "BabyChatDropDown",
    components: {
        BabyChat,
    },
    data() {
        return {
            chatOpen: false,
            sehen: false,
            hoeren: false,
            babyView: false,
            chatIcon,
            chatIconGruen,
            chatMetacom,
            newMessagesAvailable: false,
            newMessagesCount: 0,
        };
    },
    props: {
        closeChat: {required: false, default: false},
    },
    mounted() {
        this.requestBabyViewAndAccessibilityMode();
        window.addEventListener("keyup", event => {
            if (event.altKey && event.key === 'c') {
                this.toggleChatOpen();
                this.triggerShortcutTooltips();
            }
        });
    },
    watch: {
        word(newVal) {
            if(newVal === 'öffne chat') {
                this.chatOpen = true;
            }
            if(newVal === 'schließe chat') {
                this.chatOpen = false;
            }
        },
        closeChat() {
            console.log('toggled');
            if(this.$props.closeChat) {
                this.chatOpen = !this.$props.closeChat;
            }
            setTimeout(() => {
                this.$emit('closedChat', true);
            }, 10);
        },
        gamepadYButtonPressed(newVal, oldVal) {
            // on keyup of gamepadYButton
            if(oldVal === true && newVal === false) {
                this.toggleChatOpen();
            }
        }
    },
    computed : {
        ...mapState("speechControl", ["word"]),
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),
        ...mapGetters("gamepad", { gamepadYButtonPressed: "yButtonPressed"}),
    },
    methods: {
        ...mapActions("tooltips", ["triggerShortcutTooltips"]),
        ...mapActions("pupils", ["getMePupil"]),

        async requestBabyViewAndAccessibilityMode() {
            let temp = await this.getMePupil();
            this.babyView = temp.babyView;
            if(temp.accessibility) {
                this.sehen = temp.accessibility.sehen;
                this.hoeren = temp.accessibility.hoeren;
            } else {
                this.sehen = false;
                this.hoeren = false;
            }
            //to check if they are babys
            if (this.babyView) {
                this.babyViewTut = true;
            }
        },
        toggleChatOpen() {
             if(!this.chatOpen) {
                    document.getElementById("ChatWidgetButton").focus();
                }
                this.chatOpen = !this.chatOpen;
        },
        updateMessageCount(count) {
            this.newMessagesCount = count;
            if(count>0) {
                this.newMessagesAvailable = true;
            } else {
                this.newMessagesAvailable = false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
    .overlay {
        z-index: 8!important;
    }
    #container {
        position: relative;
    }

    #ChatWidget {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 1;
    }


    .animateIcon {
        overflow: hidden;
    }

    .animateIcon:after {
        animation: shine 60s ease-in-out infinite;
        animation-fill-mode: forwards;
        content: "";
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);

        background: rgba(255, 255, 255, 0.0);
        background: linear-gradient(
                        to right,
                        rgba(112, 112, 112, 0.2) 0%,
                        rgba(112, 112, 112, 0.2) 77%,
                        rgba(112, 112, 112, 0.5) 92%,
                        rgba(112, 112, 112, 0.0) 100%
        );
    }

    /* Hover state - trigger effect */

    /* Active state */
    .animateIcon:active:after {
        opacity: 0;
    }

    @keyframes shine{
        1% {
            opacity: 1;
            top: -30%;
            left: -30%;
            transition-property: left, top, opacity;
            transition-duration: 0.7s, 0.7s, 0.15s;
            transition-timing-function: ease;
        }
        5% {
            opacity: 0;
            top: -30%;
            left: -30%;
            transition-property: left, top, opacity;
        }
    }

    //filter generated with https://codepen.io/sosuke/pen/Pjoqqp
    .iconToWhite {
        filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
    }

    .chatClicked {
        background-color: #8cbd46 !important;
    }
</style>
