import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify';
import store from './store';
import '@fortawesome/fontawesome-free/css/all.css'
import LongPress from 'vue-directive-long-press';
import VueQuillEditor from 'vue-quill-editor'
import vuescroll from 'vuescroll';
import './registerServiceWorker'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme


Vue.use(VueQuillEditor, /* { default global options } */)

Vue.directive('long-press', LongPress);

Vue.config.productionTip = false;


Vue.use(VueRouter);
const routes = [
  { path: '', redirect: '/main' },
  { path: '*', redirect: "/"},
  { path: '*\\.map'}, // Exclude Javascript maps from vue router https://github.com/vuejs/vue-router/issues/927#issuecomment-350221829
  { path: '*\\.js'}, // Exclude js from vue router https://github.com/vuejs/vue-router/issues/927#issuecomment-350221829
  { path: '*\\.css'}, // Exclude css from vue router https://github.com/vuejs/vue-router/issues/927#issuecomment-350221829
  { path: '/version', name: 'version', component: () => import(/* webpackChunkName: "Main"*/ "./components/Version"), protected: false},
  {
    path: '/main',
    component:  () => import(/* webpackChunkName: "Main" */"./views/Main"),
    children: [
      {
        path: '',
        name: 'main',
        redirect: '/main/login'
      },
      {
        path: 'login',
        name: 'main.login',
        component: () => import(/* webpackChunkName: "Main" */"./components/Login"),
        protected: true,
      },
    ],
  },
  {
    path: '/schueler',
    component: () => import(/* webpackChunkName: "Schueler" */"./views/Main"),
    children: [
      {
        path: '',
        name: 'schueler',
        redirect: '/schueler/plan'
      },
      {
        path: 'plan',
        name: 'schueler.plan',
        component: () => import(/* webpackChunkName: "Schueler" */"./components/Schueler/PlanSchueler"),
        protected: true,
      },
      {
        path: 'edit',
        name: 'schueler.edit',
        component: () => import(/* webpackChunkName: "Schueler" */ "./components/Schueler/EditSchueler"),
        protected: true,
      },
      {
        path: 'view',
        name: 'schueler.view',
        component: () => import(/* webpackChunkName: "Schueler" */ "./components/FileCreator"),
        protected: true,
      },

    ],
  },
  {
    path: '/eltern',
    component: () => import(/* webpackChunkName: "Eltern" */ "./views/Main"),
    children: [
      {
        path: '',
        name: 'eltern',
        redirect: '/eltern/plan'
      },
      {
        path: 'plan',
        name: 'eltern.plan',
        component: () => import(/* webpackChunkName: "Eltern" */ "./components/Eltern/PlanEltern"),
        protected: true,
      },
    ]
  },
  {
    path: '/lehrer',
    component: () => import(/* webpackChunkName: "Lehrer" */ "./views/Main"),
    children: [
      {
        path: '',
        name: 'lehrer',
        redirect: '/lehrer/plan'
      },
      {
        path: 'plan',
        name: 'lehrer.plan',
        component: () => import(/* webpackChunkName: "Lehrer" */ "./components/Lehrer/PlanLehrer"),
        protected: true,
      },
      {
        path: 'edit',
        name: 'lehrer.edit',
        component: () => import(/* webpackChunkName: "Lehrer" */ "./components/Lehrer/EditLehrer"),
        protected: true,
      },
      {
        path: 'view',
        name: 'lehrer.view',
        component: () => import(/* webpackChunkName: "Lehrer" */ "./components/FileCreator"),
        protected: true,
      },
    ],
  },
  {
    path: '/verwaltung',
    component: () => import(/* webpackChunkName: "Verwaltung" */ "./views/Verwaltung"),
    children: [
      {
        path: '',
        name: 'verwaltung',
        redirect: '/verwaltung/plan'
      },
      {
        path: 'plan',
        name: 'verwaltung.plan',
        component: () => import(/* webpackChunkName: "Verwaltung" */ "./components/Verwaltung/Plan"),
        protected: true,
      },
      {
        path: 'schwarzes-brett',
        name: 'verwaltung.blackboard',
        component: () => import(/* webpackChunkName: "Verwaltung" */ "./components/Verwaltung/SchwarzesBrett"),
        protected: true,
      },
      {
        path: 'groups',
        name: 'verwaltung.groups',
        component: () => import(/* webpackChunkName: "Verwaltung" */ "./components/Verwaltung/Groups"),
        protected: true,
      },
      {
        path: 'faecher',
        name: 'verwaltung.faecher',
        component: () => import(/* webpackChunkName: "Verwaltung" */ "./components/Verwaltung/Faecher"),
        protected: true,
      },
      {
        path: 'lehrer',
        name: 'verwaltung.lehrer',
        component: () => import(/* webpackChunkName: "Verwaltung" */ "./components/Verwaltung/Lehrer"),
        protected: true,
      },
      {
        path: 'schueler',
        name: 'verwaltung.schueler',
        component: () => import(/* webpackChunkName: "Verwaltung" */ "./components/Verwaltung/Schueler"),
        protected: true,
      },
      {
        path: 'zeitslots',
        name: 'verwaltung.zeitslots',
        component: () => import(/* webpackChunkName: "Verwaltung" */ "./components/Verwaltung/Zeitslots"),
        protected: true,
      },
      {
        path: 'tutorial',
        name: 'verwaltung.tutorial',
        component: () => import(/* webpackChunkName: "Verwaltung" */ "./components/Verwaltung/TutorialVerwaltung" ),
        protected: true,
      },
      {
        path: 'chat-verwaltung',
        name: 'verwaltung.chatWidget',
        component: () => import(/* webpackChunkName: "Verwaltung" */ "./components/Verwaltung/ChatVerwaltung"),
        protected: true,
      },
      {
        path: 'faq',
        name: 'verwaltung.Faq',
        component: () => import(/* webpackChunkName: "Main" */ "./components/Faq"),
        protected: true,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

// You can set global config here.
Vue.use(vuescroll);

Vue.prototype.$vuescrollConfig = {
  bar: {
    background: '#0f0'
  }
};

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  if(!hasQueryParams(to) && hasQueryParams(from)){
    next({name: to.name, query: from.query});
  } else {
    next()
  }
});

new Vue({
  store,
  router,
  vuetify,
  icons: {
    iconfont: 'fa',
  },
  render: h => h(App)
}).$mount('#app');

