<template>
    <div>
        <EditorBaseComponent
            ref="editorBaseComponent"
            :mode="'creator'"
            :save-function="saveCreatorDocument"
            :loading-active="loadingActive"
            :loading-message="loadingMessage"
        />

        <!-- Name File for those empty pages that can be uploaded -->
        <NameFile
            v-model="showNameFile"
            :original-name="uploadFileName"
            :callback="fileNameCallback"
            :group="currentlyOpenAppointment ? currentlyOpenAppointment.group : null"
        />
    </div>
</template>

<script>
import EditingCanvas from "./EditingCanvas";
import {mapActions, mapGetters, mapState} from "vuex";
import closeIcon from "../assets/Icons/abbrechen-08.svg";
import abbrechenIcon from "../assets/Icons/abbrechen-08.svg";
import mikrofonIcon from "../assets/Icons/mikrofon-22.svg";
import speichernIcon from "../assets/Icons/speichern-64.svg";
import plusIcon from "../assets/Icons/hinzufuegen-07.svg";
import vuescroll from "vuescroll";
import scrollbarStyling from "../util/scrollbarStyling";
import * as backend from "../api/backend";
import EditorAudioMessage from "./Editortools/EditorAudioMessage";
import EditorTextMessage from "./Editortools/EditorTextMessage";
import EditorTools from "./Editortools/Toolbar/EditorTools";
import EditorTextTask from "./Editortools/EditorTextTask";
import EditorAnswerField from "./Editortools/EditorAnswerField";
import EditorImageObject from "@/components/Editortools/EditorImageObject";
import pdfjs from "../util/pdfjs";
import EditorVideoObject from "@/components/Editortools/EditorVideoObject";
import EditorMultipleChoice from "@/components/Editortools/EditorMultipleChoice";
import NameFile from "./NameFile";
import { PDFDocument } from "pdf-lib";
import EditorMoveableText from "@/components/Editortools/EditorMoveableText";
import lautsprecherIcon from "../assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "../assets/Icons/lautsprecher-aus-89.svg";
import deFlagIcon from "../assets/Icons/deutsch-59.svg";
import frFlagIcon from "../assets/Icons/französisch-60.svg";
import trFlagIcon from "../assets/Icons/türkisch-97.svg";
import arFlagIcon from "../assets/Icons/syrisch-98.svg";
import enFlagIcon from "../assets/Icons/englisch-61.svg";
import ruFlagIcon from "../assets/Icons/russisch.svg";
import textIcon from '../assets/Icons/Textfunktion-67.svg'
import EditorBaseComponent from "./Editor/EditorBaseComponent";

export default {
    name: 'FilePreview',
    components: {
        EditorBaseComponent,
        NameFile
    },
    data: () => ({
        loadingActive: false,
        loadingMessage: 'Dokument wird geladen...',

        // Name File Upload Items
        showNameFile: false,
        uploadFileName: null,
        fileNameCallback: () => {},
    }),
    computed: {
        ...mapGetters('util', [ 'currentlyOpenAppointment' ])
    },
    methods: {
        ...mapActions('files', ['patchCurrentFile', 'safeNewPdfFile', ]),

        /**
         * Method to toggle Loading Animation in Base Component
         */
        setLoading(loadingActive, loadingMessage) {
            this.loadingActive = loadingActive;
            this.loadingMessage = loadingMessage;
        },

        /**
         * Method given to EditorBaseComponent to handle Saving Communication with outside
         * This includes the NameFile Stuff, calling appropriate Actions and triggering UI Feedback in Base Component
         * @param fileData base64 data from canvas pages
         * @param appointmentId read from URL
         * @param fileId if there is one, also read from URL
         * @param fileName to keep correct name or set a new one if file isn't set
         */
        async saveCreatorDocument(fileData, appointmentId, fileId, fileName, redrawFile) {
            console.log('saveCreatorDocument called with', appointmentId, fileId, fileName);

            if (!fileId) {
                this.setLoading(true, 'Warten auf Speichern...');
                this.showNameFile = true;
                this.uploadFileName = fileName;
                this.fileNameCallback = (newFileName, selectedUser, startDate, isAssignment) => {
                    this.showNameFile = false;
                    this.saveNewPage(appointmentId, newFileName, selectedUser, startDate, isAssignment, fileData);
                    // this.assignmentName = newFileName;
                };
                this.setLoading(false);
            } else if (redrawFile) {
                this.setLoading(true, 'Speichern...');
                fileName = fileName.split('.')[0] + ".pdf";

                const req = await this.patchCurrentFile({
                    data: fileData,
                    appointmentId: appointmentId,
                    fileId: fileId,
                    fileName: fileName
                });

                req.onerror = (e) => {
                    console.error('File patch error:', e)
                    this.snackbars.genericError = true;
                };

                req.onabort = (e) => {
                    console.warn('File patch aborted');
                    this.snackbars.genericError = true;
                }

                req.upload.addEventListener('progress', (e) => {
                    console.log("Progress", (e.loaded / e.total) * 100);
                });

                req.addEventListener('load', async (e) => {
                    if (req.status !== 201) {
                        console.error('File patch failed:', req.response);
                        this.snackbars.genericError = true;
                    } else {
                        await this.$refs.editorBaseComponent.safeAllEditorMessages(fileId);
                        this.loadingActive = false;
                        this.loadingMessage = '';
                    }
                });
            } else {
                this.loadingActive = true;
                this.loadingMessage = "Speichern";
                await this.$refs.editorBaseComponent.safeAllEditorMessages(fileId);
                this.loadingActive = false;
                this.loadingMessage = '';
            }
        },

        /**
         * Method called when Creation was an empty Page
         * @param newFileName came from NameFile
         * @param selectedUser array from NameFile
         * @param startDate from NameFile
         * @param isAssignment Checkbox from NameFile
         * @param base64 FileData for Single Page
         * @returns {Promise<void>}
         */
        async saveNewPage(appointmentId, newFileName, selectedUser, startDate, isAssignment, base64) {
            try {
                const response = await this.safeNewPdfFile({
                    data: base64,
                    appointmentId,
                    fileName: newFileName,
                    params: { selectedUser, startDate, isAssignment },
                    userGroup: 'teacher'
                });
                console.log("Status", response);

                response.addEventListener('load', async (e) => {
                    if (response.status === 200
                        || response.status === 201
                        || response.status === 202
                        || response.status === 204) {
                        const responseJson = JSON.parse(response.response);
                        await this.$refs.editorBaseComponent.safeAllEditorMessages(responseJson._id);
                        this.$refs.editorBaseComponent.setNewValuesForEmptyPage(newFileName, responseJson._id);
                    } else {
                        this.snackbars.dataCouldntBeSaved = true;
                        this.setLoading(false);
                    }
                });
            } catch (e) {
                console.error(e);
                this.snackbars.dataCouldntBeSaved = true;
                this.setLoading(false);
            }
        },
    }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/globals.scss";

#headerText {
    color: white;
    font-size: x-large;
    font-weight: bold;

    /*max-width: calc(100% - 70px);*/
    /*min-width: calc(100% - 70px);*/
    width: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    float: left !important;
}

.headerTeacherText {
    /*max-width: calc(100% - 325px) !important;*/
    /*min-width: calc(100% - 325px) !important;*/
}

#closeBtn {
    background-color: #e6221e80;
    width: 49px;
    height: 49px;

    @media (max-width: 380px) {
        width: 40px !important;
        height: 40px !important;
        margin-right: 0 !important;
    }
}

.toolBtn {
    background-color: #555555;
    height: 49px !important;

    @media (max-width: 380px) {
        height: 40px !important;
        margin-right: 0 !important;

        p {
            display: none;
        }
    }
}

.greenBtn {
    background-color: #8cbd46;
    color: white;

    img {
        filter: brightness(1000%);
    }
}

.toolSelector {
    background-color: #f8f8f880;
    width: 49px !important;
    height: 49px !important;

    @media (max-width: 380px) {
        width: 40px !important;
        height: 40px !important;
    }
}

.zoomControl {
    position: fixed;
    z-index: 8 !important;
    top: 95px;
    left: 20px;
    background-color: #f8f8f8 !important;
    width: 49px !important;
    height: 49px !important;
    border-radius: 8px;

    p {
        line-height: 0;
        font-size: 60px;
        margin-bottom: 0;
        font-weight: 300;
        color: #333;
    }

    @media (max-width: 380px) {
        width: 40px !important;
        height: 40px !important;

        p {
            font-size: 50px;
        }
    }
}

#textLayerButton {

    img {
        filter: brightness(0);
    }
}

.textLayerActive {
    filter: invert(0.8);
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}

.focusMe {
    border: 2px solid red;
}

.notesButtonsImage {
    height: 30px;
    filter: brightness(0%);
}
</style>
